import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid } from '@mui/material';
import { DynamicFontTypography } from 'components/Typography';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

import AnimalsStyles from '../../views/Landing/components/Animals/animals.module.scss';
import LottieComponent from '../../views/Landing/components/Animals/LottieComponent';
import Boar from '../../views/Landing/components/asset/2024_boar.json';
import Horse from '../../views/Landing/components/asset/2024_horse.json';
import Elephant from '../../views/Landing/components/asset/2024_elephant.json';

const useStyles = makeStyles(theme => ({
  root: {},
  heading: {
    fontSize: 'clamp(24px, 3.0vw, 32px)',
    lineHeight: 'clamp(32px, 3.4vw, 40px)',
    fontWeight: 500,
    maxWidth: '600px',
    margin: 'min(16px, 1.0vw) auto'
  },
  sub: {
    fontSize: 'clamp(16px, 1.3vw, 22px)',
    lineHeight: 'clamp(22px, 1.5vw, 30px)',
    fontWeight: 300,
    marginBottom: 'min(36px, 3.6vw)'
  },
  section: {
    padding: 'min(0px, 0vw) min(16px, 1.0vw) ',
    minWidth: '300px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      height: '60vh'
    }
  },
  sectionGrid: {
    maxWidth: '1200px',
    margin: '0 auto',
    [theme.breakpoints.down('md')]: {}
  },
  sectionTextGrid: {
    height: 'clamp(100px, 30vh, 360px)',
    [theme.breakpoints.up('md')]: {
      height: 'clamp(100px, 60vh, 360px)'
    }
  },
  sectionHead: {
    maxWidth: '600px',
    fontWeight: 500,
    fontSize: 'clamp(24px, 3.0vw, 32px)',
    lineHeight: 'clamp(32px, 3.4vw, 40px)',
    padding: 'clamp(28px, 3.0vw, 36px)',
    margin: 'min(16px, 1.0vw) auto',
    color: '#444444',
    textAlign: 'left',
    [theme.breakpoints.down('lg')]: {
      padding: 'clamp(22px, 2.4vw, 28px)',
      textAlign: 'center'
    }
  },
  sectionSub: {
    fontWeight: 300,
    fontSize: 'clamp(16px, 1.3vw, 22px)',
    lineHeight: 'clamp(22px, 1.5vw, 30px)',
    maxHeight: 'clamp(60px, 6.0vw, 100px)',
    overflowY: 'visible',
    padding: '0 clamp(24px, 2.8vw, 32px)',
    marginBottom: 'min(36px, 3.6vw)',
    color: '#999999',
    textAlign: 'left'
  },
  sectionImageRight: {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    content: '',
    width: '100%',
    height: 'clamp(160px, 32vh, 460px)',
    [theme.breakpoints.up('md')]: {
      height: 'clamp(200px, 60vh, 460px)'
    }
  },
  sectionImageLeft: {
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    content: '',
    width: '100%',
    height: 'clamp(160px, 32vh, 460px)',
    [theme.breakpoints.up('md')]: {
      //      marginTop:theme.spacing(3.0),
      height: 'clamp(200px, 60vh, 460px)'
    }
  },
  image: {
    [theme.breakpoints.up('xs')]: {
      width: '100%',
      height: '240px'
    },
    [theme.breakpoints.up('sm')]: {
      width: '300px',
      height: 'auto'
    },
    [theme.breakpoints.up('md')]: {
      width: '340px',
      height: 'auto'
    },
    [theme.breakpoints.up('lg')]: {
      width: '350px',
      height: '350px'
    }
  }
}));

export default function Features() {
  const { t } = useTranslation('page');

  // const featuresList = [
  //   {
  //     title: t('Features.title1'),
  //     body: t('Features.desc1'),
  //     img: '/images/landing/landing1_pc2.png',
  //     bgcolor: '#FEFCF5'
  //   },
  //   {
  //     title: t('Features.title2'),
  //     body: t('Features.desc2'),
  //     img: '/images/landing/landing2_pc2.png',
  //     bgcolor: '#BBDEFB'
  //   },
  //   {
  //     title: t('Features.title3'),
  //     body: t('Features.desc3'),
  //     img: '/images/landing/landing3_pc2.png',
  //     bgcolor: '#F3F9FE'
  //   }
  // ];

  const classes = useStyles();

  return (
    <Box
      id="features"
      width="100%"
      maxWidth="1200px"
      margin="0 auto"
      padding={{ md: '0 24px', lg: 0 }}>
      <div
        className={clsx(classes.section, classes.section)}
        style={{ background: '#FFFCF5' }}>
        <Grid
          container
          className={classes.sectionGrid}
          alignItems="center"
          spacing={0}
          style={{ flexDirection: 'row-reverse' }}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.sectionTextGrid}>
            <DynamicFontTypography
              component="div"
              className={classes.sectionHead}>
              {t('Landing.section1_head')}
            </DynamicFontTypography>
            <DynamicFontTypography
              component="div"
              className={classes.sectionSub}>
              {t('Landing.section1_sub')}
            </DynamicFontTypography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div
              className={classes.sectionImageRight}
              style={{
                backgroundImage: 'url(/images/landing/landing1_pc2.png)',
                position: 'relative'
              }}>
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  bottom: '12px',
                  justifyContent: 'center',
                  width: '100%'
                }}>
                <LottieComponent
                  animationData={Elephant}
                  className={AnimalsStyles.elephantLanding}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        className={clsx(classes.section, classes.section)}
        style={{ background: '#ffffff' }}>
        <Grid
          container
          className={classes.sectionGrid}
          alignItems="center"
          spacing={0}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.sectionTextGrid}>
            <DynamicFontTypography
              component="div"
              className={classes.sectionHead}>
              {t('Landing.section2_head')}
            </DynamicFontTypography>
            <DynamicFontTypography
              component="div"
              className={classes.sectionSub}>
              {t('Landing.section2_sub')}
            </DynamicFontTypography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div
              className={classes.sectionImageLeft}
              style={{
                backgroundImage: 'url(/images/landing/landing2_pc2.png)',
                position: 'relative'
              }}>
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  bottom: '12px',
                  justifyContent: 'center',
                  width: '100%'
                }}>
                <LottieComponent
                  animationData={Horse}
                  className={AnimalsStyles.horseLanding}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div
        className={clsx(classes.section, classes.section)}
        style={{ background: '#F3F9FF' }}>
        <Grid
          container
          className={classes.sectionGrid}
          alignItems="center"
          spacing={0}
          style={{ flexDirection: 'row-reverse' }}>
          <Grid
            item
            xl={6}
            lg={6}
            md={6}
            sm={12}
            xs={12}
            className={classes.sectionTextGrid}>
            <DynamicFontTypography
              component="div"
              className={classes.sectionHead}>
              {t('Landing.section3_head')}
            </DynamicFontTypography>
            <DynamicFontTypography
              component="div"
              className={classes.sectionSub}>
              {t('Landing.section3_sub')}
            </DynamicFontTypography>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div
              className={classes.sectionImageRight}
              style={{
                backgroundImage: 'url(/images/landing/landing3_pc2.png)',
                position: 'relative'
              }}>
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  bottom: '12px',
                  justifyContent: 'center',
                  width: '100%'
                }}>
                <LottieComponent
                  animationData={Boar}
                  className={AnimalsStyles.boarLanding}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
}
