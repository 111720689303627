import React, { useState, useEffect } from 'react';
import {
  Link as RouterLink,
  withRouter,
  useParams,
  useHistory,
  useLocation
} from 'react-router-dom';
//
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
//
import { fetchPelotonURI } from 'utils/clientApis';
import { formatNumber } from 'utils/helper';
import { useCopyLink } from 'utils/useCopyLink';
import useAutoSize from 'utils/useAutoSize';
import { imageSize } from 'utils/helper';
//
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  Box,
  Button,
  Typography,
  Tab,
  Tabs
} from '@mui/material';
//
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import ShareIcon from '@mui/icons-material/Share';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import CustomCircularProgress from '../Common/CustomCircularProgress';
//
import Stream from './Menu/StreamList/Stream';
import Members from './Menu/Members/Members';
import Board from './Menu/PostList/PostList';
import Ranks from './Menu/Ranks/Ranks';
import Events from './Menu/EventList/EventList';
import MemberModal from '../Common/MemberModal';
import PelotonLayout from '../Common/PelotonLayout';
import LinkCopyDialog from '../Common/LinkCopyDialog';

//
const useStyles = makeStyles(theme => ({
  root: {},
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  canvas: {
    backgroundColor: theme.palette.background.paper,
    padding: '16px 16px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    marginTop: '12px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  },
  card: {
    flexGrow: 1,
    display: 'flex',
    marginBottom: 16,
    flexDirection: 'column',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    },
    position: 'relative' // 상대적 위치 지정
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingTop: 'calc(100% * (1/3))',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 'calc(100% * (9/16))'
    }
  },
  image: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  overlayButton: {
    position: 'absolute',
    top: theme.spacing(2), // 상단 여백 조절
    right: theme.spacing(2), // 오른쪽 여백 조절
    zIndex: 999 // 다른 요소 위에 배치
  },
  chapter: {
    height: 'auto'
  },
  info: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: '16px',
    '& > *': {
      marginRight: '4px'
    }
  },
  cards: {
    marginBottom: '16px'
  }
}));

function Club() {
  const classes = useStyles();
  const { t } = useTranslation(['page']);
  const history = useHistory();
  const location = useLocation();
  const { uriName } = useParams();
  const parsedData = JSON.parse(localStorage.getItem('tab'));
  const tabName = parsedData?.tabName;

  const [data, setData] = useState();
  const [imgData, setImgData] = useState([]);
  const [userState, setUserState] = useState();
  const [isMember, setIsMember] = useState(false);
  const [selectedTab, setSelectedTab] = useState('stream');
  const [isUserActivated, setIsUserActivated] = useState(false);

  const [linkCopyDialogOpen, setLinkCopyDialogOpen] = useState(false);
  const { copyLinkToClipboard } = useCopyLink();
  const { selectedImages } = useAutoSize(imgData, imageSize.LG);

  const tabPaths = {
    stream: `/peloton/${uriName}/stream`,
    event: `/peloton/${uriName}/event`,
    member: `/peloton/${uriName}/member`,
    post: `/peloton/${uriName}/post`,
    ranking: `/peloton/${uriName}/ranking`
  };

  // 마지막 방문한 Tab을 저장하는 로직
  useEffect(() => {
    if (tabName) {
      setSelectedTab(tabName);
    } else {
      setSelectedTab('stream');
    }
    // 첫 마운트에서는 클럽 프로필을 보여줍니다
    setIsUserActivated(false);
    handleFetchPelotonInfo();
  }, []);

  // 사용자가 탭을 클릭했을 경우, localStorage에 저장
  useEffect(() => {
    const data = { uri: uriName, tabName: selectedTab };
    localStorage.setItem('tab', JSON.stringify(data));
  }, [selectedTab, uriName]);

  // 페이지 URL이 변경될 때마다 선택된 탭과 search param을 업데이트합니다.
  useEffect(() => {
    const pathname = location.pathname;
    const tabNameFromUrl = pathname.split('/').pop();
    if (pathname !== `/peloton/${uriName}`) {
      // 클럽 페이지 랜딩이 아닐 경우, 언제나 클럽 프로필 hidden
      setIsUserActivated(true);
    }

    if (
      tabNameFromUrl === 'stream' ||
      tabNameFromUrl === 'event' ||
      tabNameFromUrl === 'ranking' ||
      tabNameFromUrl === 'post' ||
      tabNameFromUrl === 'member'
    )
      setSelectedTab(tabNameFromUrl);
    else setSelectedTab('stream');
  }, [location.pathname, location.search]);

  // 클럽 정보를 가져오는 로직
  const handleFetchPelotonInfo = async () => {
    try {
      const response = await fetchPelotonURI(uriName);
      setData(response);
      setImgData(response.clubThumbFiles);
      setUserState(response.myClubUserResponse);

      if (
        response.myClubUserResponse?.join !== false &&
        response?.myClubUserResponse !== null &&
        (response.myClubUserResponse.clubRole === 'MEMBER' ||
          response.myClubUserResponse.clubRole === 'ADMIN' ||
          response.myClubUserResponse.clubRole === 'MANAGER')
      ) {
        setIsMember(true);
      } else {
        setIsMember(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const tabSelect = (event, newValue) => {
    // 사용자가 직접 tab을 조종할 경우 클럽 프로필 hidden
    setIsUserActivated(true);
    // 게시판 탭에서 다른 탭으로 이동할 경우, search param을 제거합니다.
    const newUrl = tabPaths[newValue];
    const currentUrlParams = new URLSearchParams(location.search);

    if (newValue !== 'post' && currentUrlParams.has('page')) {
      currentUrlParams.delete('page');
      history.push(`${newUrl}?${currentUrlParams.toString()}`);
    } else {
      history.push(newUrl);
    }
    setSelectedTab(newValue);
  };

  /**
   * @return: Modal Controller
   */
  const [open, setOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState('');

  const handleClickOpen = label => {
    setSelectedModal(label);
    setOpen(true);
  };

  const handleCopyLinker = () => {
    const result = copyLinkToClipboard();
    setLinkCopyDialogOpen(result);
  };

  return (
    <PelotonLayout clubName={data?.name}>
      <>
        {data ? (
          <Box className={classes.canvas}>
            <Card key={data.id} className={classes.card}>
              {/* 상단 이미지 */}
              {!isUserActivated && selectedImages.length > 0 ? (
                <Box className={classes.imageContainer}>
                  <img
                    className={classes.image}
                    src={selectedImages[0]}
                    alt="Club"
                  />
                </Box>
              ) : (
                <Box
                  className={classes.imageContainer}
                  style={{ display: isUserActivated ? 'none' : 'block' }}>
                  <img
                    className={classes.image}
                    src="/images/dummy_box.png"
                    alt="Dummy Image2"
                  />
                </Box>
              )}

              {/* 가입 신청 버튼 */}
              {!isUserActivated && (userState === null || undefined) && (
                <Button
                  className={classes.overlayButton}
                  size="small"
                  color="primary"
                  variant="contained"
                  onClick={() => handleClickOpen('newbie')}>
                  가입신청
                </Button>
              )}

              {/* 펠로톤 수정 버튼 */}
              {!isUserActivated &&
                userState &&
                userState?.clubRole === 'ADMIN' && (
                  <RouterLink
                    size="small"
                    to={{
                      pathname: `/peloton/${data?.id}/edit`,
                      state: {
                        pelotonName: data?.name,
                        uriName: data?.uriName,
                        description: data?.description,
                        guideText: data?.guideText,
                        images: data?.clubThumbFiles
                      }
                    }}>
                    {/* 소유주 / 관리자 */}
                    <Button
                      className={classes.overlayButton}
                      size="small"
                      color="primary"
                      variant="contained">
                      수정
                    </Button>
                  </RouterLink>
                )}

              {/* 가입 대기중 버튼 */}
              {!isUserActivated &&
                userState &&
                (!userState.join && userState.clubRole === 'MEMBER') && (
                  <Button
                    className={classes.overlayButton}
                    size="small"
                    color="white"
                    variant="contained"
                    onClick={() => handleClickOpen('wait')}>
                    가입 대기중
                  </Button>
                )}

              {/* 펠로톤 정보 (나라, 회원수, 설명글) */}
              {!isUserActivated && (
                <CardContent>
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '16px'
                    }}>
                    <Typography variant="h4">{data.name}</Typography>
                    <ShareIcon
                      onClick={handleCopyLinker}
                      style={{ fontSize: '19px', cursor: 'pointer' }}
                    />
                  </Box>
                  <div className={classes.info}>
                    <LocationOnIcon />
                    <Typography variant="body1" component="div">
                      {data.country}
                    </Typography>
                    <PersonIcon />
                    <Typography variant="body1" component="div">
                      {formatNumber(data.userCount)}
                      {/* 회원 수 */}
                    </Typography>
                  </div>
                  <Typography variant="body2" color="textSecondary">
                    {data.description}
                  </Typography>
                </CardContent>
              )}

              {/* 탭 */}
              <Box>
                <Tabs
                  value={selectedTab}
                  onChange={tabSelect}
                  aria-label="tabs"
                  textColor="primary"
                  indicatorColor="primary"
                  variant="scrollable"
                  scrollButtons="auto">
                  <Tab value={'stream'} label="활동 스트림" />
                  <Tab value={'event'} label="이벤트" />
                  <Tab value={'ranking'} label="랭킹" />
                  <Tab value={'post'} label="게시판" />
                  <Tab value={'member'} label="멤버목록" />
                </Tabs>
              </Box>
            </Card>

            {/*  */}
            {data && isMember && !userState.blocked ? (
              <Box className={classes.chapter}>
                {selectedTab === 'stream' && <Stream data={data} />}
                {selectedTab === 'event' && <Events data={data} />}
                {selectedTab === 'member' && <Members data={data} />}
                {selectedTab === 'post' && <Board data={data} />}
                {selectedTab === 'ranking' && <Ranks data={data} />}
              </Box>
            ) : (
              <Box
                style={{
                  backgroundColor: 'white',
                  padding: '8px',
                  height: '250px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}>
                <NewReleasesIcon
                  color="primary"
                  style={{ marginBottom: '8px', fontSize: '50px' }}
                />
                <Typography
                  color="textPrimary"
                  variant="h6"
                  style={{ marginBottom: '16px' }}>
                  펠로톤 회원에게만 공개됩니다.
                </Typography>
                {userState && (!userState.join || userState.blocked) ? (
                  <></>
                ) : (
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={() => handleClickOpen('newbie')}>
                    가입신청 하기
                  </Button>
                )}
              </Box>
            )}
          </Box>
        ) : (
          <CustomCircularProgress />
        )}
        {/* Modal 섹션 */}
        {selectedModal !== '' && open && (
          <MemberModal
            label={selectedModal}
            openState={open}
            setOpen={setOpen}
            pelotonId={data.id}
            guideText={data.guideText}
            uriName={uriName}
          />
        )}
        {/* 링크 복사 모달  */}
        {linkCopyDialogOpen && (
          <LinkCopyDialog
            copyModalOpen={linkCopyDialogOpen}
            handleCloseCopyModal={() => setLinkCopyDialogOpen(false)}
          />
        )}
      </>
    </PelotonLayout>
  );
}

Club.propTypes = {
  history: PropTypes.object
};
export default withRouter(Club);

// dummy
// const user = {
//     "success": true,
//     "message": "",
//     "code": "",
//     "response": {
//         "id": 2,
//         "regDate": "2024-02-28T04:58:26Z",
//         "modDate": "2024-02-28T04:58:26Z",
//         "clubId": 2,
//         "clubName": "한강 야경 투어",
//         "userId": 46,
//         "join": false,
//         "displayName": "hwanest",
//         "clubRole": "MEMBER",
//         "profileImage": null,
//         "stravaId": 0,
//         "ftp": 0.0,
//         "tte": 0,
//         "wftp": 0,
//         "wtte": 0,
//         "vo2max": 0.0,
//         "lastWeekTss": 0,
//         "minWeekTss": 0,
//         "weight": null,
//         "gender": null,
//         "wkg": 0.0,
//         "weekTss": 0
//     }
// }
