function organizeImagesByOriginId(images) {
  if (!images || images.length === 0) {
    return [];
  }
  const organizedData = {};
  // 이미지 데이터를 originId를 기준으로 정리
  images.forEach(image => {
    const { originId, id, filePath, fileType, thumbnailSize } = image;
    if (!organizedData[originId]) {
      organizedData[originId] = [];
    }
    organizedData[originId].push({ id, filePath, fileType, thumbnailSize });
  });
  // 정리된 데이터를 배열로 변환하여 반환
  const organizedArray = Object.keys(organizedData).map(key => ({
    originId: parseInt(key),
    images: organizedData[key]
  }));
  return organizedArray;
}

export default organizeImagesByOriginId;
