import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  AppBar,
  Box,
  CircularProgress,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  MenuItem,
  Tab,
  Tabs,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  IconButton,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Slide,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList,
  useMediaQuery
} from '@mui/material';

import { ViewState } from '@devexpress/dx-react-scheduler';

import {
  Scheduler,
  MonthView,
  Toolbar,
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  TodayButton
} from '@devexpress/dx-react-scheduler-material-ui';

import html2canvas from 'html2canvas';
import saveAs from 'file-saver';

import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import RouteIcon from '@mui/icons-material/Route';
import MovingIcon from '@mui/icons-material/Moving';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { Icon } from '@iconify/react';
import StravaIcon from '@iconify/icons-mdi/strava';

import $ from 'jquery';
import _ from 'lodash';
import axios from 'axios';
import auth from '../../../../utils/auth';
import { useTranslation } from 'react-i18next';

import {
  ActivityStream,
  PowerCurve,
  PowerZone,
  HeartrateZone,
  Interval,
  Lap,
  Metabolism
} from '../../Chart';
import { PolylineToSVG } from 'utils/polyline';
import CalendarSmall from './CalendarSmall';
import CalendarLarge from './CalendarLarge';
import { wattsToWPerKg } from 'utils/unitCalculation';
import ListSmall from './ListSmall';

//import $ from "jquery";
//var CanvasJS = window.CanvasJS;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px 16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  },
  timeTableCell: {
    height: '100px',
    [theme.breakpoints.up('sm')]: {
      height: '160px'
    }
  },
  tableContainer: {
    background: theme.palette.background.paper,
    width: 'auto',
    height: 'auto',
    [theme.breakpoints.down('xl')]: {
      marginTop: '-2px',
      height: 'auto'
    }
  },
  tableRow: {
    '&$selected:selected': {
      background: '#d8efed'
    }
  },
  tableHeadCell: {
    backgroundColor: theme.palette.background.colored.light,
    padding: '12px 8px',
    color: '#4db6ac',
    fontSize: '13px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 6px',
      fontSize: '11px'
    }
  },
  tableCell: {
    fontSize: '13px',
    padding: '12px 8px',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 6px',
      fontSize: '12px'
    }
  },
  tableCellActivity: {
    display: 'inline-block',
    fontSize: '13px',
    padding: '12px 8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'fill-available',
    overflow: 'hidden',
    maxWidth: '400px',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 6px',
      fontSize: '12px',
      maxWidth: '240px'
    }
  },
  dialogActivityName: {
    width: '100%',
    textAlign: 'left',
    fontSize: '18px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px'
    }
  },
  dialogTabs: {
    background: theme.palette.background.colored.main,
    borderRadius: '4px'
  },
  smallButton: {
    minWidth: '30px',
    padding: '0px',
    fontSize: '13px',
    color: '#cccccc'
  },
  stravaButton: {
    minWidth: 0,
    margin: '2px 2px 2px 0'
  },
  stravaIcon: {
    color: '#FC4C02',
    fontSize: '16px',
    opacity: '0.7',
    marginTop: '-2px'
  },
  stravaLink: {
    color: '#FC4C02'
  },
  modifyLink: {
    color: '#888888',
    marginRight: '16px',
    cursor: 'pointer'
  },
  descTitle: {
    fontSize: '12px',
    marginTop: '10px',
    textAlign: 'center'
  },
  paginationSpacer: {
    flex: '1 1 0%',
    padding: '0 8px 8px 8px'
  },
  modifyButton: {
    border: '1px #dddddd solid',
    borderRadius: '4px',
    color: '#4db6ac',
    margin: '8px 0'
  },
  activityTitleBlock: {
    position: 'relative',
    zIndex: 10,
    width: 'calc(100% + 16px)',
    flexGrow: 1,
    height: '36px',
    margin: '0px 0 -12px -2px'
  },
  activityTitleCloseButton: {
    width: '32px',
    height: '32px',
    marginLeft: '8px',
    padding: '4px'
  },
  activityTitleButton: {
    display: 'inline-block',
    width: 'calc(100% - 40px)',
    boxSizing: 'border-box',
    border: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '15px',
    // color: '#455a64',
    padding: '4px 0 6px 0',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'left',
      fontSize: '13px',
      height: '32px',
      padding: '4px 0 6px 0'
    }
  },
  dialogAction: {
    background: '#f5f5f5'
  },
  dateItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    textAlign: 'center'
  },
  dateItemTitleContainer: {
    width: '100%'
  },
  dateItemTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  dateItemCount: {
    color: theme.palette.primary.main
  },
  monthViewTableLayout: {
    minWidth: '300px'
  },
  weekAccumulateInfoBoxSm: {
    padding: '8px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.colored.light
  },
  weekAccumulateInfoSm: {
    float: 'left',
    display: 'flex',
    marginRight: '8px'
  },
  weekAccumulateIconSm: {
    fill: `${theme.palette.text.forth} !important`,
    marginRight: '2px'
  },
  weekAccumulateInfoTitleBox: {
    padding: '8px',
    paddingBottom: '0',
    textAlign: 'center',
    backgroundColor: theme.palette.background.colored.light
  },
  weekAccumulateInfoTitle: {
    color: '#90a4ae',
    fontWeight: 'bold',
    fontSize: '11px'
  },
  weekAccumulateInfo: {
    margin: '4px 0',
    display: 'flex',
    alignItems: 'center'
  },
  weekAccumulateInfoIcon: {
    fill: `${theme.palette.text.forth} !important`,
    marginRight: '2px'
  },
  weekAccumulateInfoText: {
    fontSize: '13px'
  },
  weekAccumulateInfoBox: {
    height: '160px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '8px',
    backgroundColor: theme.palette.background.colored.light
  },
  monthViewTimeTableRow: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  weekAccumulateInfoTextSm: {
    fontWeight: 'bold',
    fontSize: '12px'
  },
  screenShotText: { color: 'white', fontWeight: '500' },
  screenShotIcon: { color: 'white', marginRight: '8px' }
}));

const Activities = props => {
  //다국어적용 {t('page.key')}
  //특수한 훈련을 했을 때, 상세분석탭이 추가로 뜨게 된다.
  //FTP테스트분석
  //FATMAX 최적화 훈련
  //로우케이던스 훈련
  //고강도 최적화 훈련 = 사일러이펙트
  //Break Away 훈련 = 성냥개비
  //에어로분석 (ITT) = 공기저항값
  //힐리핏트레이닝
  //구간PR시도

  const { t } = useTranslation(['page']);
  const theme = useTheme();

  const extraInfo = auth.getExtraInfo();

  //unit default
  const unit = extraInfo.unit ? extraInfo.unit : 'metric';
  const kmToMile = 0.621371;
  const mToFeet = 3.28084;
  const kgToLbs = 2.20462;

  const columns = [
    { id: 'activity_name', label: t('Dashboard.activity'), minWidth: 220 },
    { id: 'average_watts', label: t('Dashboard.avgPower'), minWidth: 95 },

    { id: 'distance', label: t('Dashboard.distance'), minWidth: 74 },
    { id: 'moving_time', label: t('Dashboard.duration'), minWidth: 74 },
    { id: 'ride_data', label: t('Dashboard.date'), minWidth: 110 },
    { id: 'tss_text', label: t('Dashboard.score3'), minWidth: 170 },
    { id: 'activity_update', label: '', minWidth: 175, align: 'right' },
    { id: 'activity_url', label: '', minWidth: 0, display: 'none' },
    { id: 'activity_id', label: '', minWidth: 0, display: 'none' },
    { id: 'activity_desc', label: '', minWidth: 0, display: 'none' }
  ];

  const { dashboardData, wkgSelect, pdcData, locale } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [activityUpdateOpen, setActivityUpdateOpen] = React.useState(false);

  const [activityId, setActivityId] = React.useState(props.activityId);
  const [activityName, setActivityName] = React.useState('');

  const [chartType, setChartType] = React.useState('');
  const [dialogActivityId, setDialogActivityId] = React.useState(0);
  const [dialogActivityName, setDialogActivityName] = React.useState('');
  const [activityReload, setActivityReload] = React.useState('stop');
  const [activityDialog, setActivityDialog] = React.useState(false);
  const [activityDesc, setActivityDesc] = React.useState(null);
  const [streamDataJson, setStreamDataJson] = useState([]);
  const [circle, setCircle] = useState(false);

  const isWindowWidthSm = useMediaQuery(theme.breakpoints.down('md'));

  const activityData = dashboardData.activityData;
  const weight = dashboardData.weight;

  const [tabSelect, setTabSelect] = React.useState(0);

  const handleTabList = (event, newTab) => {
    setTabSelect(newTab);
  };

  const handleOpenActivityStatus = (activityId, title) => {
    setChartType('stream');
    setActivityDialog(true);
    setActivityId(activityId);
    setActivityName(title);
  };

  function TabList(props) {
    return (
      <Tabs
        value={tabSelect}
        onChange={handleTabList}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto">
        <Tab key={0} label={'LIST'} />
        <Tab key={1} label={'CALENDAR'} />
      </Tabs>
    );
  }

  /**********************************************************/
  /**********************************************************/
  /**********************************************************/
  /**********************************************************/
  //scheduler
  /**********************************************************/
  /**********************************************************/
  /**********************************************************/
  /**********************************************************/

  const [schedulerData, setSchedulerData] = useState([]);
  const currentDate = Date('Y-m-d');
  const [monthCalendarDate, setMonthCalendarDate] = useState(new Date());
  const [monthData, setMonthData] = useState();

  const rideIntensity = 'rideIntensity';
  const powerZoneActivities = dashboardData.hasOwnProperty('powerzoneData')
    ? dashboardData.powerzoneData.powerzone_activities
    : null;

  const powerZoneResources = [
    {
      fieldName: 'rideIntensity',
      title: 'RideIntensity',
      allowMultiple: false,
      isMain: true,
      instances: [
        { id: 'ALLOUT', text: t('Animals.type.allout'), color: '#ec3372' },
        { id: 'SPT', text: t('Animals.type.spt'), color: '#f06292' },
        { id: 'HIIT', text: t('Animals.type.hiit'), color: '#ff7e75' },
        { id: 'VO2MAX', text: t('Animals.type.vo2max'), color: '#ffa75c' },
        { id: 'RPT', text: t('Animals.type.rpt'), color: '#ffd357' },
        { id: 'SST', text: t('Animals.type.sst'), color: '#b0d36d' },
        { id: 'TEMPO', text: t('Animals.type.tempo'), color: '#b0d36d' },
        { id: 'HVLI', text: t('Animals.type.hvli'), color: '#76b5e8' },
        { id: 'BASE', text: t('Animals.type.base'), color: '#76b5e8' },
        { id: 'POL', text: t('Animals.type.pol'), color: '#76b5e8' },
        { id: 'PRMD', text: t('Animals.type.prmd'), color: '#b0d36d' },
        { id: 'RECV', text: t('Animals.type.recv'), color: '#aaaaaa' },
        { id: 'ETC', text: t('Animals.type.etc'), color: '#aaaaaa' },
        { id: 'BAL', text: t('Animals.type.bal'), color: '#4db6ac' }
      ]
    }
  ];

  /* 날짜칸 */
  const TimeTableCell = props => {
    const classes = useStyles();

    //		console.log(props.startDate);

    return (
      <>
        <MonthView.TimeTableCell {...props} className={classes.timeTableCell} />
      </>
    );
  };

  /* 날짜 아이템 */
  const Appointment = props => {
    const { title, items } = props.data;

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleOpenActivityStatus = item => {
      setChartType('stream');
      setActivityDialog(true);
      setActivityId(item.activityId);
      setActivityName(item.title);
    };

    const handleToggle = item => {
      if (items.length === 1) {
        handleOpenActivityStatus(items[0]);
      } else if (items.length > 1) {
        setOpen(prevOpen => !prevOpen);
      }
    };

    const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);

    return (
      <>
        <Box
          className={classes.dateItem}
          onClick={handleToggle}
          ref={anchorRef}>
          <Box
            style={{
              display: 'flex',
              position: 'relative',
              width:
                window.innerWidth > theme.breakpoints.values['sm'] ? 60 : 40,
              height:
                window.innerWidth > theme.breakpoints.values['sm'] ? 60 : 40
            }}>
            {items.length > 1 ? (
              items.slice(0, 2).map((item, index) => (
                <PolylineToSVG
                  key={item.id}
                  width={
                    window.innerWidth > theme.breakpoints.values['sm']
                      ? 60 - 7
                      : 40 - 7
                  }
                  height={
                    window.innerWidth > theme.breakpoints.values['sm']
                      ? 60 - 7
                      : 40 - 7
                  }
                  polyline={item.summaryPolyline}
                  backgroundColor={item.backgroundColor}
                  style={{
                    position: 'absolute',
                    border: '1px solid white',
                    borderRadius: '5px',
                    top: `${6 * index}px`,
                    left: `${6 * index}px`,
                    zIndex: 100 - index
                  }}
                />
              ))
            ) : (
              <PolylineToSVG
                width={
                  window.innerWidth > theme.breakpoints.values['sm'] ? 60 : 40
                }
                height={
                  window.innerWidth > theme.breakpoints.values['sm'] ? 60 : 40
                }
                polyline={items[0].summaryPolyline}
                backgroundColor={items[0].backgroundColor}
              />
            )}
          </Box>

          <Box className={classes.dateItemTitleContainer}>
            {window.innerWidth > theme.breakpoints.values['sm'] ? (
              <Typography className={classes.dateItemTitle}>{title}</Typography>
            ) : null}
            {window.innerWidth > theme.breakpoints.values['sm'] &&
            items.length > 1 ? (
              <Typography className={classes.dateItemCount}>{`+${
                items.length === 1 ? null : items.length - 1
              }`}</Typography>
            ) : null}
            {window.innerWidth <= theme.breakpoints.values['sm']
              ? items.slice(0, 3).map(({ rideAnimal }) => {
                  return <span style={{ fontSize: '9px' }}>{rideAnimal}</span>;
                })
              : null}
            {window.innerWidth <= theme.breakpoints.values['sm'] &&
            items.length > 3 ? (
              <span
                className={classes.dateItemCount}
                style={{ fontSize: '9px' }}>
                +{items.length - 3}
              </span>
            ) : null}
          </Box>
        </Box>
        {items.length > 1 ? (
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            // disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                  marginTop: '4px'
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}>
                      {items.map(item => (
                        <MenuItem
                          key={item.activityId}
                          onClick={() => handleOpenActivityStatus(item)}>
                          <PolylineToSVG
                            width={24}
                            height={24}
                            polyline={item.summaryPolyline}
                            backgroundColor={item.backgroundColor}
                          />
                          <span style={{ marginLeft: '8px' }}>
                            {item.title}
                          </span>
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        ) : null}
      </>
    );
  };

  const AppointmentContent = props => {
    const classes = useStyles();
    const tssText = props.appointmentData.tss_text;
    const activityName = props.appointmentData.activity_name;
    const avgPower = props.appointmentData.average_watts;
    const distance = props.appointmentData.distance;
    const movingTime = props.appointmentData.moving_time;
    const rideType = props.appointmentData.ride_type;

    return (
      <AppointmentTooltip.Content
        {...props}
        className={classes.appointmentContent}>
        <div>{tssText}</div>
        <div>{avgPower}</div>
        <div>{distance}</div>
        <div>{movingTime}</div>
      </AppointmentTooltip.Content>
    );
  };

  const countMonthDays = date => {
    const targetDate = new Date(date);
    targetDate.setDate(1);
    targetDate.setMonth(targetDate.getMonth() + 1);
    targetDate.setDate(0);

    return targetDate.getDate();
  };

  /**
   * 월 달력에 몇 주 있는지 체크
   * (월요일이 한 주의 시작)
   * */
  const countWeeksInMonth = date => {
    const target = new Date(date);

    target.setDate(1);
    const dayOfFirstDate = target.getDay();

    const isDayOfFirstDateSunday = dayOfFirstDate === 0 ? true : false;

    const currentMonthDaysCount = countMonthDays(target);

    return (
      Math.ceil((currentMonthDaysCount + dayOfFirstDate - 1) / 7) +
      (isDayOfFirstDateSunday ? 1 : 0)
    );
  };

  /**
   * 해당 달의 몇번째 주인지 확인 (첫번째 주의 경우 값은 0, 월요일이 주의 시작일)
   */
  const getWeekOfMonth = date => {
    const target = new Date(date);

    target.setDate(1);
    const dayOfFirstDate = target.getDay();
    const countDates =
      dayOfFirstDate === 0
        ? 6 + date.getDate() - 1
        : dayOfFirstDate - 1 + date.getDate() - 1;

    return Math.floor(countDates / 7);
  };

  /**
   * 해당 날짜의 주가 지난 달의 마지막 주 혹은 다음달의 첫째 주와 겹치는지 확인
   */
  const isOverlapMonths = date => {
    // 첫번째 주에 존재함
    if (getWeekOfMonth(date) === 0) {
      const target = new Date(date);

      target.setDate(1);
      const dayOfFirstDate = target.getDay();

      if (dayOfFirstDate === 1) {
        return { overlap: false };
      } else {
        // 첫째 주가 지난달의 마지막 주와 겹침
        target.setDate(0);
        const year = target.getFullYear();
        const month = target.getMonth();
        const week = getWeekOfMonth(target);

        return { overlap: true, year, month, week };
      }
      // 마지막 주에 존재함
    } else if (getWeekOfMonth(date) === countWeeksInMonth(date) - 1) {
      const target = new Date(date);

      target.setDate(1);
      const currentMonth = target.getMonth();
      target.setMonth(currentMonth + 1);
      target.setDate(0);

      const dayOfLastDate = target.getDay();

      if (dayOfLastDate === 0) {
        return { overlap: false };
      } else {
        // 마지막 주가 다음달의 첫째 주와 겹침
        target.setDate(1);
        target.setMonth(currentMonth + 1);

        const year = target.getFullYear();
        const month = target.getMonth();
        const week = getWeekOfMonth(target);

        return { overlap: true, year, month, week };
      }
    } else {
      return { overlap: false };
    }
  };

  /* 달력 아이템 리스트 */
  useEffect(() => {
    if (!activityData) return;
    // if (!weatherData) return;

    const activityDateMap = new Map();
    const activityMonthMap = new Map();
    const schedulerItemArray = [];

    // 각 activityData를 순회하며 날짜가 같을 시 activityDateMap에 같은 배열로 저장
    // 각 activityData를 순회하며 월이 같을시 activityMonthMap에 전체거리, 획득고도, 활동시간 누적값 및 각 주별 전체거리, 획득고도, 활동시간 누적값을 저장
    activityData.forEach(item => {
      const {
        activity_id,
        activity_name,
        timestamp,
        elapsed_time,
        distance,
        total_elevation_gain,
        summary_polyline,
        moving_time,
        average_watts,
        intensity,
        tss,
        average_cadence,
        average_heartrate,
        np,
        weight
      } = item;

      const wPerKg = wattsToWPerKg(average_watts, weight);

      const startDate = new Date(Number(timestamp) * 1000);
      const endDate = new Date(
        Number(timestamp) * 1000 + Number(elapsed_time) * 1000
      );

      const sYear = startDate.getFullYear();
      const sMonth = startDate.getMonth();
      const sDate = startDate.getDate();
      const targetDate = new Date(sYear, sMonth, sDate);
      const sIsoString = targetDate.toISOString();

      // activityDateMap
      //powermeter 체크
      let rideIntensity = 'ETC';
      let rideAnimal = '🦆';
      let backgroundColor = '#aaaaaa';

      if (
        Number(item.max_watts) > 0 &&
        powerZoneActivities.hasOwnProperty(String(item.activity_id))
      ) {
        let type = powerZoneActivities[String(item.activity_id)].type;
        rideIntensity = type;

        if (type === 'SPT') {
          rideAnimal = '🦁';
          backgroundColor = '#f06292';
        } else if (type === 'HIIT') {
          rideAnimal = '🐆';
          backgroundColor = '#ff7e75';
        } else if (type === 'VO2MAX') {
          rideAnimal = '🐺';
          backgroundColor = '#ffa75c';
        } else if (type === 'RPT') {
          rideAnimal = '🐴';
          backgroundColor = '#ffd357';
        } else if (type === 'SST') {
          rideAnimal = '🐗';
          backgroundColor = '#b0d36d';
        } else if (type === 'TEMPO') {
          rideAnimal = '🐷';
          backgroundColor = '#b0d36d';
        } else if (type === 'BAL') {
          rideAnimal = '🐶';
          backgroundColor = '#4db6ac';
        } else if (type === 'HVLI') {
          rideAnimal = '🐫';
          backgroundColor = '#76b5e8';
        } else if (type === 'BASE') {
          rideAnimal = '🐮';
          backgroundColor = '#76b5e8';
        } else if (type === 'POL') {
          rideAnimal = '🐘';
          backgroundColor = '#76b5e8';
        } else if (type === 'PRMD') {
          rideAnimal = '🦌';
          backgroundColor = '#b0d36d';
        } else if (type === 'RECV') {
          rideAnimal = '🐢';
          backgroundColor = '#aaaaaa';
        } else {
          rideIntensity = 'ETC';
          rideAnimal = '🦆';
          backgroundColor = '#aaaaaa';
        }

        if (
          item.etc === 'ramptest20' ||
          item.etc === 'maptest150' ||
          item.etc === 'ftptest'
        ) {
          rideIntensity = type = 'ALLOUT';
          rideAnimal = '🎯';
        }
      }

      const newItem = {
        startDate,
        endDate,
        id: activity_id,
        title: rideAnimal + ' ' + activity_name,
        rideAnimal,
        rideIntensity,
        activityId: activity_id,
        summaryPolyline: summary_polyline,
        backgroundColor,
        movingTime: moving_time,
        intensity,
        tss,
        averageWatts: average_watts,
        distance,
        totalElevationGain: total_elevation_gain,
        averageCadence: average_cadence,
        averageHeartrate: average_heartrate,
        np,
        wPerKg
      };

      if (activityDateMap.has(sIsoString)) {
        const array = activityDateMap.get(sIsoString);
        array.push(newItem);
      } else {
        activityDateMap.set(sIsoString, [newItem]);
      }

      // activityMonthMap
      const monthMapKey = `${sYear}-${sMonth}`;

      if (!activityMonthMap.has(monthMapKey)) {
        // 초기화

        // 한달에 몇주가 존재하나요?
        const weeksInMonth = countWeeksInMonth(startDate);
        const weeks = Array.from({ length: weeksInMonth }, () => ({
          distance: 0,
          movingTime: 0,
          elevation: 0,
          tss: 0
        }));

        // 현재 나는 해당달의 몇번째 주인가요?
        const weekOfMonth = getWeekOfMonth(startDate);
        weeks[weekOfMonth].distance = Number(distance);
        weeks[weekOfMonth].movingTime = Number(moving_time);
        weeks[weekOfMonth].elevation = Number(total_elevation_gain);

        activityMonthMap.set(monthMapKey, {
          distance: Number(distance),
          movingTime: Number(moving_time),
          elevation: Number(total_elevation_gain),
          tss: Number(tss),
          weeks
        });
      } else {
        const monthData = activityMonthMap.get(monthMapKey);
        const weekOfMonth = getWeekOfMonth(startDate);

        monthData.distance += Number(distance);
        monthData.movingTime += Number(moving_time);
        monthData.elevation += Number(total_elevation_gain);
        monthData.tss += Number(tss);

        monthData.weeks[weekOfMonth].distance += Number(distance);
        monthData.weeks[weekOfMonth].movingTime += Number(moving_time);
        monthData.weeks[weekOfMonth].elevation += Number(total_elevation_gain);
        monthData.weeks[weekOfMonth].tss += Number(tss);
      }

      // 두개의 달에 겹치는 경우 두 달 모두 같은 데이터 받을 수 있도록 함.
      const { overlap, year, month, week } = isOverlapMonths(startDate);
      if (overlap) {
        const overlapMonthKey = `${year}-${month}`;

        if (!activityMonthMap.has(overlapMonthKey)) {
          // 초기화
          const weeksInMonth = countWeeksInMonth(new Date(year, month, 1));
          const weeks = Array.from({ length: weeksInMonth }, () => ({
            distance: 0,
            movingTime: 0,
            elevation: 0,
            tss: 0
          }));

          // 현재 나는 해당달의 몇번째 주인가요?
          weeks[week].distance = Number(distance);
          weeks[week].movingTime = Number(moving_time);
          weeks[week].elevation = Number(total_elevation_gain);
          weeks[week].tss = Number(tss);

          activityMonthMap.set(overlapMonthKey, {
            distance: Number(distance),
            movingTime: Number(moving_time),
            elevation: Number(total_elevation_gain),
            tss: Number(tss),
            weeks
          });
        } else {
          const monthData = activityMonthMap.get(overlapMonthKey);

          monthData.weeks[week].distance += Number(distance);
          monthData.weeks[week].movingTime += Number(moving_time);
          monthData.weeks[week].elevation += Number(total_elevation_gain);
          monthData.weeks[week].tss += Number(tss);
        }
      }
    });

    // activityDateMap 가공
    activityDateMap.forEach((items, key) => {
      const startDateIsoString = key;
      const startDate = new Date(startDateIsoString);

      const sYear = startDate.getFullYear();
      const sMonth = startDate.getMonth();
      const sDate = startDate.getDate();

      const endDate = new Date(sYear, sMonth, sDate + 1);

      items.sort((a, b) => b.movingTime - a.movingTime);

      const title = items[0].title;

      const item = {
        startDate,
        title,
        items,
        endDate
      };

      schedulerItemArray.push(item);
    });

    // activityMonthMap
    setSchedulerData(schedulerItemArray);
    setMonthData(activityMonthMap);
  }, [activityData, powerZoneActivities]);

  /**********************************************************/
  /**********************************************************/
  /**********************************************************/
  /**********************************************************/
  //table
  /**********************************************************/
  /**********************************************************/
  /**********************************************************/
  /**********************************************************/

  let exclude_arr = Array();
  let dst_arr = Array();
  let tss_arr = Array();
  let if_arr = Array();

  let rows = Array();
  let selectedRows = Array();

  const timeout = delay => {
    return new Promise(res => setTimeout(res, delay));
  };

  useEffect(() => {
    const getActivityStream = () => {
      const auth_str = 'Bearer '.concat(auth.getToken().token);

      setCircle(true);
      setStreamDataJson([]);

      let url = `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-activity-stream.php`;
      let user = auth.getUserInfo();

      if (user.ID === 2) {
        axios
          .get(url, {
            headers: { Authorization: auth_str },
            params: {
              order: 'get_stream',
              activity_id: activityId
            }
          })
          .then(async function(response) {
            setCircle(false);

            const data = response.data;
            if (data) {
              setStreamDataJson(data);
            }
          });
      } else {
        axios
          .get(url, {
            headers: { Authorization: auth_str },
            params: {
              order: 'get_stream',
              activity_id: activityId
            }
          })
          .then(async function(response) {
            setCircle(false);

            const data = response.data;
            if (data) {
              setStreamDataJson(data);
            }
          });
      }
    };

    if (activityId > 0 && chartType === 'stream') {
      getActivityStream();
    }
  }, [activityId]);

  if (activityData)
    _.each(activityData, function(item, i) {
      let watts_str = item.average_watts + ' w ';
      if (wkgSelect)
        watts_str =
          Math.round((item.average_watts / item.weight) * 10) / 10 + ' w/kg ';

      if (item.max_watts > 0 && item.exclude == 0) {
        watts_str += '⚡';
      } else if (item.max_watts > 0 && item.exclude == 1) {
        watts_str += '❔';
        exclude_arr.push(Number(item.activity_id));
      }

      let activity_name = item.activity_name;
      let average_watts = watts_str;
      let distance =
        unit === 'metric'
          ? item.distance + ' km'
          : Math.round(item.distance * kmToMile * 100) / 100 + ' mi';
      let distance_number = item.distance;
      let moving_time = secondsToHms(item.moving_time);
      let ride_data = item.start_date;
      let activity_url =
        'https://www.strava.com/activities/' + item.activity_id;
      let activity_id = item.activity_id;
      let timestamp = item.timestamp;
      let updated_at = item.updated_at;
      let ftp = item.ftp;
      let np = item.np;
      let intensity = item.intensity;
      let tss = item.tss == 0 ? 1 : item.tss;

      let tss_text =
        '📊 ' +
        t('Dashboard.np') +
        ' ' +
        np +
        ', ' +
        t('Dashboard.if') +
        ' ' +
        intensity +
        ', ' +
        t('Dashboard.tss') +
        ' ' +
        tss;
      let synopsis_text = item.synopsis_text;
      let anaerobic_text = item.anaerobic_text;
      let aerobic_text = item.aerobic_text;
      let power_text = item.power_text;
      let activity_desc = {
        synopsis_text: synopsis_text,
        anaerobic_text: anaerobic_text,
        aerobic_text: aerobic_text,
        power_text: power_text
      };

      //일주일이 지났으면
      let updated_check =
        updated_at < parseInt(Date.now() / 1000) - 60 * 60 * 6 * 1;
      let activity_update = updated_check ? true : false;

      let d = {
        activity_name,
        average_watts,
        distance,
        moving_time,
        ride_data,
        tss_text,
        activity_update,
        activity_url,
        activity_id,
        activity_desc
      };
      rows.push(d);

      dst_arr.push([i, distance_number]);
      tss_arr.push([i, tss]);
      if_arr.push([i, intensity * 100]);

      selectedRows.push({ activity_id: false });
    });

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  /*
	const handleClickTableAway = () => {
		setActivityName('');
		setActivityId(0);
  };
  */

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + 'h ' : '';
      let md = m > 0 ? m + 'm' : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + 'm ' : '';
      let sd = s > 0 ? s + 's' : '';

      return md + sd;
    }
  }

  function secondsToHmsLabel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '';
    } else if (h > 0) {
      let hd = h > 0 ? h + ':' : '00';
      let md = m > 0 ? m : '00';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + ':' : '00';
      let sd = s > 0 ? s : '00';

      return md + sd;
    }
  }

  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = event => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          size="large">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
          size="large">
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  function handleDialogClose() {
    setActivityUpdateOpen(false);
  }

  function handleDialogOpen(e, id, name) {
    e.stopPropagation();
    setActivityId(0);
    setActivityName('');
    setDialogActivityId(id);
    setDialogActivityName(name);
    setActivityUpdateOpen(true);
  }

  const setActivity = (activity_id, order) => {
    setActivityReload('loading');
    const auth_str = 'Bearer '.concat(auth.getToken().token);

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-activity.php`,
        { order: order, activity_id: activity_id },
        { headers: { Authorization: auth_str } }
      )
      .then(function(response) {
        let res = response.data;
        if (res.success === true && res.activity_id === activity_id) {
          props.history.push({
            pathname: '/'
          });
        } else {
          alert('Error');
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  function Row(props) {
    const { activity_id, row, i } = props;
    const classes = useStyles();

    const isSelected = activityId == activity_id ? true : false;

    return (
      <React.Fragment>
        <TableRow
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.activity_id}
          selected={isSelected}
          classes={{ selected: classes.selected }}
          className={classes.tableRow}
          onClick={() => {
            setChartType('stream');
            setActivityDialog(true);
            setActivityDesc(row.activity_desc.synopsis_text);
            setActivityId(row.activity_id);
            setActivityName(row.activity_name);
          }}>
          {columns.map(column => {
            const value = row[column.id];

            if (column.id === 'activity_name') {
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableCellActivity}>
                  <Button
                    color="secondary"
                    size="small"
                    variant="text"
                    target="_blank"
                    href={row.activity_url}
                    className={classes.stravaButton}>
                    <Icon icon={StravaIcon} className={classes.stravaIcon} />
                  </Button>
                  {column.format && typeof value === 'number'
                    ? column.format(value)
                    : value}
                </TableCell>
              );
            } else if (column.id === 'activity_update') {
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableCell}>
                  {value === true ? (
                    <a
                      className={classes.modifyLink}
                      onClick={e =>
                        handleDialogOpen(e, row.activity_id, row.activity_name)
                      }>
                      Modify
                    </a>
                  ) : (
                    ''
                  )}

                  <a
                    color="secondary"
                    size="small"
                    variant="text"
                    target="_blank"
                    href={row.activity_url}
                    className={classes.stravaLink}>
                    View on Strava
                  </a>
                </TableCell>
              );
            } else if (column.display != 'none') {
              return (
                <TableCell
                  key={column.id}
                  align={column.align}
                  className={classes.tableCell}>
                  {column.format && typeof value === 'number'
                    ? column.format(value)
                    : value}
                </TableCell>
              );
            }
          })}
        </TableRow>
      </React.Fragment>
    );
  }

  const handleClickOpen = () => {
    setActivityDialog(true);
  };

  const handleClose = () => {
    setActivityDialog(false);
  };

  //자식으로부터 activity_id 전달받기
  const handlePeakActivity = id => {
    //검색해야지.
    if (activityData)
      _.each(activityData, function(item, i) {
        if (Number(item.activity_id) === Number(id)) {
          setActivityDesc(item.synopsis_text);
          setActivityId(item.activity_id);
          setActivityName(item.activity_name);

          setPage(Math.floor(i / rowsPerPage));

          return true;
        }
      });
    return false;
  };

  function ChartContent() {
    $('#chartContainer').remove();

    if (circle === true) {
      //stream 불러올 때 로딩써클
      return (
        <React.Fragment>
          <Box
            style={{
              minHeight: '436px',
              textAlign: 'center',
              padding: '100px'
            }}>
            <CircularProgress size={128} color="secondary" />
          </Box>
        </React.Fragment>
      );
    } else if (chartType === 'powercurve') {
      if (0) {
        return <React.Fragment></React.Fragment>;
      } else {
        return (
          <React.Fragment>
            <PowerCurve
              activity={activityData.find(
                activity => activity.activity_id === activityId
              )}
              extraInfo={auth.getExtraInfo()}
              activityId={activityId}
              activityName={activityName}
              activityDesc={activityDesc}
              handlePeakActivity={handlePeakActivity}
              ftp={Math.round(dashboardData.ftp)}
              wPrime={Math.round(dashboardData.wPrime)}
              forte={dashboardData.forte}
              pdcData={dashboardData.pdcData}
              activityData={dashboardData.activityData}
              streamDataJson={streamDataJson}
              yScaleMax={dashboardData.yScale}
              lastTs={dashboardData.lastTs}
              prevTs={dashboardData.prevTs}
              wkg={wkgSelect}
              weight={dashboardData.weight}
            />
          </React.Fragment>
        );
      }
    } else if (chartType === 'powerzone') {
      return (
        <React.Fragment>
          <PowerZone
            activity={activityData.find(
              activity => activity.activity_id === activityId
            )}
            extraInfo={auth.getExtraInfo()}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(dashboardData.ftp)}
            activityData={dashboardData.activityData}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={dashboardData.weight}
            powerzoneData={dashboardData.powerzoneData}
          />
        </React.Fragment>
      );
    } else if (chartType === 'hrzone') {
      return (
        <React.Fragment>
          <HeartrateZone
            activity={activityData.find(
              activity => activity.activity_id === activityId
            )}
            extraInfo={auth.getExtraInfo()}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(dashboardData.ftp)}
            activityData={dashboardData.activityData}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={dashboardData.weight}
            maxHr={dashboardData.maxHr}
            powerzoneData={dashboardData.powerzoneData}
          />
        </React.Fragment>
      );
    } else if (chartType === 'interval') {
      return (
        <React.Fragment>
          <Interval
            extraInfo={auth.getExtraInfo()}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(dashboardData.ftp)}
            activityData={dashboardData.activityData}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={dashboardData.weight}
            maxHr={dashboardData.maxHr}
            powerzoneData={dashboardData.powerzoneData}
          />
        </React.Fragment>
      );
    } else if (chartType === 'lap') {
      return (
        <React.Fragment>
          <Lap
            extraInfo={auth.getExtraInfo()}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(dashboardData.ftp)}
            activityData={dashboardData.activityData}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={dashboardData.weight}
            maxHr={dashboardData.maxHr}
          />
        </React.Fragment>
      );
    } else if (chartType === 'metabolism') {
      return (
        <React.Fragment>
          <Metabolism
            extraInfo={auth.getExtraInfo()}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(dashboardData.ftp)}
            activityData={dashboardData.activityData}
            streamDataJson={streamDataJson}
            metabolismArray={dashboardData.metabolismArray}
            wkg={wkgSelect}
            weight={dashboardData.weight}
            maxHr={dashboardData.maxHr}
          />
        </React.Fragment>
      );
    } else {
      //activity stream
      return (
        <React.Fragment>
          <ActivityStream
            activity={activityData.find(
              activity => activity.activity_id === activityId
            )}
            extraInfo={auth.getExtraInfo()}
            ftp={Math.round(dashboardData.ftp)}
            wPrime={Math.round(dashboardData.wPrime)}
            activityData={dashboardData.activityData}
            lastTs={dashboardData.lastTs}
            prevTs={dashboardData.prevTs}
            summaryData={dashboardData.summaryData}
            streamDataJson={streamDataJson}
            pdcData={dashboardData.pdcData}
            powerzoneData={dashboardData.powerzoneData}
            wkg={wkgSelect}
            weight={dashboardData.weight}
          />
        </React.Fragment>
      );
    }
  }

  const handleTabChart = (event, value) => {
    setChartType(value);
  };

  const TimeTableLayout = props => {
    return (
      <MonthView.TimeTableLayout
        {...props}
        className={classes.monthViewTableLayout}
      />
    );
  };

  const TimeTableRowSm = props => {
    const target = new Date(props.children[0].props.startDate);

    const [weekData, setWeekData] = useState();

    useEffect(() => {
      if (!monthData) return;

      if (monthData.has(`${target.getFullYear()}-${target.getMonth()}`))
        setWeekData(
          monthData.get(`${target.getFullYear()}-${target.getMonth()}`).weeks[
            getWeekOfMonth(target)
          ]
        );
    }, [target]);

    return (
      <>
        <MonthView.TimeTableRow {...props} />
        <tr>
          {weekData && weekData.movingTime > 0 ? (
            <>
              <td colSpan={7} className={classes.weekAccumulateInfoBoxSm}>
                <Box className={classes.weekAccumulateInfoSm}>
                  <RouteIcon
                    className={classes.weekAccumulateIconSm}
                    fontSize="small"
                  />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.weekAccumulateInfoTextSm}>
                    {`${weekData.distance.toFixed(2)}km`}
                  </Typography>
                </Box>

                <Box className={classes.weekAccumulateInfoSm}>
                  <AccessTimeIcon
                    className={classes.weekAccumulateIconSm}
                    fontSize="small"
                  />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.weekAccumulateInfoTextSm}>
                    {`${Math.floor(weekData.movingTime / 60)}min`}
                  </Typography>
                </Box>

                <Box className={classes.weekAccumulateInfoSm}>
                  <MovingIcon
                    className={classes.weekAccumulateIconSm}
                    fontSize="small"
                  />
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.weekAccumulateInfoTextSm}>
                    {`${weekData.elevation}m`}
                  </Typography>
                </Box>
              </td>
            </>
          ) : null}
        </tr>
      </>
    );
  };
  const DayScaleLayout = props => {
    return (
      <MonthView.DayScaleLayout
        {...props}
        className={classes.monthViewTableLayout}
      />
    );
  };

  const TimeTableRow = ({ children }) => {
    const target = new Date(children[0].props.startDate);

    const [weekData, setWeekData] = useState();

    useEffect(() => {
      if (!monthData) return;

      if (monthData.has(`${target.getFullYear()}-${target.getMonth()}`))
        setWeekData(
          monthData.get(`${target.getFullYear()}-${target.getMonth()}`).weeks[
            getWeekOfMonth(target)
          ]
        );
    }, [target]);

    return (
      <MonthView.TimeTableRow className={classes.monthViewTimeTableRow}>
        {children}
        <div className={classes.weekAccumulateInfoBox}>
          {weekData && weekData.movingTime > 0 ? (
            <>
              <Box className={classes.weekAccumulateInfo}>
                <RouteIcon
                  fontSize="small"
                  className={classes.weekAccumulateInfoIcon}
                />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.weekAccumulateInfoText}>
                  {`${weekData.distance.toFixed(0)} km`}
                </Typography>
              </Box>

              <Box className={classes.weekAccumulateInfo}>
                <AccessTimeIcon
                  fontSize="small"
                  className={classes.weekAccumulateInfoIcon}
                />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.weekAccumulateInfoText}>
                  {`${Math.floor(weekData.movingTime / 60)} min`}
                </Typography>
              </Box>

              <Box className={classes.weekAccumulateInfo}>
                <MovingIcon
                  fontSize="small"
                  className={classes.weekAccumulateInfoIcon}
                />
                <Typography
                  variant="body1"
                  color="textPrimary"
                  className={classes.weekAccumulateInfoText}>
                  {`${weekData.elevation} m`}
                </Typography>
              </Box>
            </>
          ) : null}
        </div>
      </MonthView.TimeTableRow>
    );
  };

  const DayScaleRow = ({ children }) => {
    return (
      <MonthView.DayScaleRow>
        {children}
        <td className={classes.weekAccumulateInfoTitleBox}>
          <Typography className={classes.weekAccumulateInfoTitle}>
            {t('Dashboard.weeklyTotal')}
          </Typography>
        </td>
      </MonthView.DayScaleRow>
    );
  };

  // 스크린샷 기능 출처: https://yong-nyong.tistory.com/53
  const calendarRef = useRef(null);

  const handleDownloadScreenshot = async () => {
    if (!calendarRef.current) return;

    try {
      const calendar = calendarRef.current;
      const canvas = await html2canvas(calendar, { scale: 2 });
      canvas.toBlob(blob => {
        if (blob !== null) {
          saveAs(
            blob,
            `${monthCalendarDate.getFullYear()}-${monthCalendarDate.getMonth() +
              1}-RiduckMonthTotal.png`
          );
        }
      });
    } catch (error) {
      console.error('Error converting div to image:', error);
    }
  };

  const isWidthSmall = useMediaQuery(theme.breakpoints.down('sm'));

  function handleChartOpen(id, name, description) {
    setChartType('stream');
    setActivityDialog(true);
    setActivityDesc(description);
    setActivityId(id);
    setActivityName(name);
  }

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.tab}>
        <TabList />
      </AppBar>

      <Box className={classes.tabPanel}>
        {tabSelect === 1 ? (
          <Box>
            {/* 스크린샷 버튼 */}
            <Button
              color="primary"
              size="small"
              variant="contained"
              style={{ width: '100%', margin: '8px 0' }}
              onClick={handleDownloadScreenshot}>
              <PhotoCameraIcon className={classes.screenShotIcon} />
              <Typography variant="body1" className={classes.screenShotText}>
                {t('Dashboard.screenShot')}
              </Typography>
            </Button>

            {/* 달력 */}
            {/* 스크린샷 버튼 */}
            <Box ref={calendarRef}>
              {isWindowWidthSm ? (
                <CalendarSmall
                  locale={locale}
                  monthData={monthData}
                  schedulerData={schedulerData}
                  currentDate={currentDate}
                  setMonthCalendarDate={setMonthCalendarDate}
                  isWkg={wkgSelect}
                  handleOpenActivityStatus={handleOpenActivityStatus}
                />
              ) : (
                <CalendarLarge
                  schedulerData={schedulerData}
                  locale={locale}
                  currentDate={currentDate}
                  monthData={monthData}
                  setMonthCalendarDate={setMonthCalendarDate}
                  handleOpenActivityStatus={handleOpenActivityStatus}
                  isWkg={wkgSelect}
                />
              )}
            </Box>
          </Box>
        ) : isWidthSmall ? (
          <ListSmall
            rows={rows}
            handleDialogOpen={handleDialogOpen}
            handleChartOpen={handleChartOpen}
          />
        ) : (
          <React.Fragment>
            <TableContainer className={classes.tableContainer}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map(column => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        className={classes.tableHeadCell}
                        style={{
                          minWidth: column.minWidth,
                          width: column.width,
                          display: column.display
                        }}>
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, i) => (
                      <Row
                        key={row.activity_id}
                        activity_id={row.activity_id}
                        row={row}
                        i={i}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              classes={{ spacer: classes.paginationSpacer }}
              rowsPerPageOptions={[]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </React.Fragment>
        )}
      </Box>

      <Dialog open={activityUpdateOpen} onClose={handleDialogClose}>
        <DialogTitle className={classes.dialogActivityName}>
          {"'" + dialogActivityName + "' " + t('Dashboard.modifyActivity')}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {activityReload === 'loading' ? (
            <React.Fragment>
              <Box
                className={classes.circularProgress}
                style={{
                  minWidth: '300px',
                  padding: '100px 0 100px 0',
                  textAlign: 'center'
                }}>
                <CircularProgress size={64} />
              </Box>
            </React.Fragment>
          ) : (
            <List>
              <ListItem
                className={classes.modifyButton}
                button
                onClick={() => setActivity(dialogActivityId, 'strava_update')}
                key={1}>
                <ListItemIcon>
                  <Icon
                    icon={StravaIcon}
                    style={{ color: '#4db6ac', fontSize: 40 }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t('Dashboard.stravaUpdate')}
                  secondary={t('Dashboard.stravaUpdateDesc')}
                />
              </ListItem>
              <ListItem
                className={classes.modifyButton}
                button
                onClick={() => setActivity(dialogActivityId, 'reload')}
                key={1}>
                <ListItemIcon>
                  <PermDataSettingIcon
                    style={{ color: '#4db6ac', fontSize: 36 }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t('Dashboard.recalculateActivity')}
                  secondary={t('Dashboard.recalculateActivityDesc')}
                />
              </ListItem>
              {exclude_arr.indexOf(Number(dialogActivityId)) === -1 ? (
                <ListItem
                  className={classes.modifyButton}
                  button
                  onClick={() => setActivity(dialogActivityId, 'deactivate')}
                  key={2}>
                  <ListItemIcon>
                    <RemoveCircleIcon
                      style={{ color: '#4db6ac', fontSize: 36 }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('Dashboard.excludeActivity')}
                    secondary={t('Dashboard.excludeActivityDesc')}
                  />
                </ListItem>
              ) : (
                ''
              )}
              <ListItem
                className={classes.modifyButton}
                button
                onClick={() => setActivity(dialogActivityId, 'delete_activity')}
                key={3}>
                <ListItemIcon>
                  <DeleteForeverIcon
                    style={{ color: '#4db6ac', fontSize: 36 }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t('Dashboard.deleteActivity')}
                  secondary={t('Dashboard.deleteActivityDesc')}
                />
              </ListItem>
            </List>
          )}
          <Typography
            style={{
              textAlign: 'center',
              marginBottom: '12px',
              color: '#999999'
            }}>
            {t('Dashboard.modifyActivityTerm')}
          </Typography>
        </DialogContent>
      </Dialog>

      <Dialog
        fullScreen={window.innerWidth < 960 ? true : false}
        fullWidth={true}
        maxWidth={'xl'}
        open={activityDialog}
        onClose={handleClose}
        scroll={'body'}
        PaperProps={{
          style: {
            padding: '0px'
          }
        }}>
        <DialogTitle style={{ padding: '8px' }}>
          <Box>
            <Button className={classes.activityTitleButton}>
              {'📝 ' + activityName}
            </Button>
            <IconButton
              className={classes.activityTitleCloseButton}
              onClick={handleClose}
              size="large">
              <CancelIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Divider />

        <DialogContent
          style={
            window.innerWidth < 960 ? { padding: '8px' } : { padding: '16px' }
          }>
          <Tabs
            className={classes.dialogTabs}
            value={chartType}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChart}>
            <Tab
              style={{ minWidth: '0px' }}
              label={t('Activity.summary')}
              value={'stream'}
            />

            {/* 조건에 따라 차트 메뉴들이 보이고 안보이도록  */}
            {streamDataJson.watts_5 > 1 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.powercurve')}
                value={'powercurve'}
              />
            ) : (
              ''
            )}
            {streamDataJson.watts_5 > 1 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.powerzone')}
                value={'powerzone'}
              />
            ) : (
              ''
            )}
            {streamDataJson.max_hr > 50 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.hrzone')}
                value={'hrzone'}
              />
            ) : (
              ''
            )}
            {streamDataJson.watts_60 > streamDataJson.ftp ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.interval')}
                value={'interval'}
              />
            ) : (
              ''
            )}
            {streamDataJson.lapl ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.lap')}
                value={'lap'}
              />
            ) : (
              ''
            )}
            {streamDataJson.watts_5 < 0 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.metabolism')}
                value={'metabolism'}
              />
            ) : (
              ''
            )}
          </Tabs>
          <ChartContent />
        </DialogContent>
      </Dialog>
    </div>
  );
};

Activities.propTypes = {
  className: PropTypes.string
};

export default withRouter(Activities);
