import React, { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Divider, Stack, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';

import { ModeContext } from 'context/modeProvider';
import LanguageDialog from './LanguageDialog';
import Logo from 'components/Header/Logo';
import { DynamicFontTypography } from 'components/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  xsFooter: {
    [theme.breakpoints.between(0, 420)]: {
      // width: '100%',
      // display: 'flex',
      // justifyContent: 'space-between'
    }
  }
}));

export default function Footer() {
  const { mode, handleMode } = useContext(ModeContext);

  const location = useLocation();
  const { t } = useTranslation('landing');

  const theme = useTheme();
  const classes = useStyles();

  const FooterNav = [
    // {
    //   category: t('Footer.company'),
    //   menu: [
    //     { name: t('Footer.aboutUs'), url: '/' },
    //     { name: t('Footer.news'), url: '/news' }
    //     // {
    //     //   name: t('Footer.career'),
    //     //   url: 'https://team.riduck.com/9c8bcdd4-5613-4a8d-83ae-24fdd0d4f716',
    //     // },
    //   ]
    // },
    {
      category: t('Footer.resources'),
      menu: [
        { name: t('Footer.news'), url: '/news' },
        { name: t('Footer.blog'), url: '/blog' },
        {
          name: t('Footer.guide'),
          url: 'https://riduck.gitbook.io/riduck-guide/english'
        }
        // { name: t('Footer.pricing'), url: '/' },
      ]
    },
    {
      category: t('Footer.followUs'),
      menu: [
        {
          name: t('Footer.strava'),
          url: 'https://www.strava.com/clubs/959584'
        },
        {
          name: t('Footer.linkedin'),
          url: 'https://kr.linkedin.com/company/riduck'
        },
        {
          name: t('Footer.instagram'),
          url: 'https://www.instagram.com/riduck.official/'
        }
        // {
        //   name: t('Footer.youtube'),
        //   url: 'https://www.youtube.com/@%EB%9D%BC%EC%9D%B4%EB%8D%95',
        // },
      ]
    }
  ];

  const FooterLinks = [
    { name: t('Footer.terms'), url: '/terms' },
    { name: t('Footer.privacy'), url: '/privacy' }
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location.pathname]);

  return (
    <Stack
      component="footer"
      direction="column"
      justifyContent="space-between"
      width="100%"
      height="fit-content"
      padding="16px 24px 8px"
      margin="0px auto"
      sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.boxShadow.secondary,
        scrollSnapAlign: 'end'
      }}>
      {/* Layout */}
      <Box
        width="100%"
        maxWidth="1200px"
        display="flex"
        marginX="auto"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center">
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap={2} width="120px">
            <Logo />
            <DynamicFontTypography sx={{ whiteSpace: 'pre-wrap' }}>
              {t('Hero.message1')}
            </DynamicFontTypography>
          </Box>
          <LanguageDialog />
        </Box>
        <Box
          className={classes.xsMenu}
          width="fit-content"
          display="flex"
          gap={{ xs: 2, md: 4 }}
          flexDirection="row"
          justifyContent="center"
          alignItems="center">
          {FooterNav.map(({ category, menu }, index) => (
            <Stack key={index} gap={2} direction="column">
              <DynamicFontTypography
                fontWeight={700}
                sx={{ fontSize: { xs: '12px', md: '14px' } }}>
                {category}
              </DynamicFontTypography>
              {menu.map(({ name, url }, index) => (
                <Box
                  key={index}
                  component="a"
                  href={url}
                  rel="noopener noreferrer"
                  target={url.startsWith('http') ? '_blank' : '_self'} // Open external links in a new tab
                  style={{
                    textDecoration: 'none',
                    color: 'inherit'
                  }}>
                  <DynamicFontTypography
                    lineHeight="1.4em"
                    sx={{ fontSize: { xs: '12px', md: '14px' } }}>
                    {name}
                  </DynamicFontTypography>
                </Box>
              ))}
            </Stack>
          ))}
        </Box>
      </Box>

      {/* Divider */}
      <Divider
        sx={{
          width: '100%',
          maxWidth: '1200px',
          backgroundColor: '#a8c3c1',
          margin: '16px auto'
        }}
      />

      {/* Bottom Section */}
      <Stack
        gap={{ xs: 0, md: 3 }}
        direction="row"
        width="100%"
        maxWidth="1200px"
        marginX="auto"
        mb="8px"
        justifyContent="space-between"
        textAlign="center">
        <Stack
          className={classes.xsMenu}
          gap={{ xs: 1, lg: 2 }}
          // width="100%"
          display="flex"
          direction="row"
          alignItems="center">
          {FooterLinks.map(({ name, url }, index) => (
            <Box
              key={index}
              component="a"
              href={url}
              target={url.startsWith('http') ? '_blank' : '_self'}
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', flexGrow: 1 }}>
              <DynamicFontTypography
                fontSize={{ xs: '10px', sm: '12px' }}
                color="theme.primary">
                {name}
              </DynamicFontTypography>
            </Box>
          ))}
        </Stack>
        <Stack
          className={classes.xsFooter}
          gap={2}
          direction="row"
          alignItems="center">
          <IconButton onClick={handleMode} size="medium">
            {mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
          </IconButton>
          <DynamicFontTypography fontSize="12px">
            &copy; 2025 Riduck
          </DynamicFontTypography>
        </Stack>
      </Stack>
    </Stack>
  );
}
