import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DynamicFontTypography } from 'components/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  heading: {
    fontSize: 'clamp(16px, 3.0vw, 24px)',
    lineHeight: 'clamp(24px, 3.4vw, 32px)',
    fontWeight: 500
  },
  sub: {
    fontSize: 'clamp(14px, 1.3vw, 16px)',
    lineHeight: 'clamp(22px, 1.5vw, 24px)',
    fontWeight: 300
  }
}));

export default function NewsArticleContainer({ title, desc, url, img }) {
  const theme = useTheme();
  const { t } = useTranslation('landing');
  const classes = useStyles();

  return (
    <Stack
      gap={{ xs: 1, md: 2 }}
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={{ xs: 'center', md: 'start' }}
      alignItems="center"
      width="100%"
      height={{ xs: 'min-content', md: '252px' }}
      maxWidth={{ lg: '1200px' }}
      borderRadius="4px"
      border="1px solid rgba(0, 0, 0, 0.1)"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
      bgcolor={theme.palette.background.paper}
      overflow="hidden">
      <Box
        width={{ xs: '100%', md: '30%' }}
        height="100%"
        overflow="hidden"
        borderRadius="4px 0px 0px 4px"
        display="flex">
        <Box
          component="img"
          src={img}
          width="100%"
          height="100%"
          minHeight={{ xs: '150px' }}
          sx={{
            objectFit: 'cover',
            objectPosition: 'center',
            padding: { xs: '8px', md: 0 }
          }}
        />
      </Box>
      <Stack
        gap={1}
        display="flex"
        width={{ xs: '100%', md: '60%' }}
        direction="column"
        justifyContent="center"
        alignItems={{ xs: 'center', md: 'start' }}
        padding={{ xs: '12px', md: '16px' }}>
        <DynamicFontTypography
          className={classes.heading}
          fontWeight={500}
          textAlign={{ xs: 'center', md: 'start' }}>
          {title}
        </DynamicFontTypography>
        <DynamicFontTypography
          className={classes.sub}
          textAlign={{ xs: 'center', md: 'start' }}>
          {desc}
        </DynamicFontTypography>
        <Button
          component={Link}
          to={url}
          variant="contained"
          size="small"
          sx={{
            marginTop: '10px',
            bgcolor: '#333333',
            '&:hover': { bgcolor: '#009688' }
          }}>
          <DynamicFontTypography
            color="#FFFFFF"
            sx={{
              fontSize: '14px'
            }}>
            {t('Buttons.readMore')}
          </DynamicFontTypography>
        </Button>
      </Stack>
    </Stack>
  );
}
