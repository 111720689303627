import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Box,
  CircularProgress,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tab,
  Tabs,
  Button,
  IconButton,
  Slide
} from '@mui/material';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import $ from 'jquery';
import axios from 'axios';
import auth from '../../../../utils/auth';
import { useTranslation } from 'react-i18next';

import {
  ActivityStream,
  PowerCurve,
  PowerZone,
  HeartrateZone,
  Interval,
  Lap,
  Metabolism
} from '../../Chart';

//import $ from "jquery";
//var CanvasJS = window.CanvasJS;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px 16px',
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  },
  timeTableCell: {
    height: '150px',
    [theme.breakpoints.down('lg')]: {
      height: '100px'
    }
  },
  appointment: {
    color: '#ffffff',
    fontSize: '14px',
    lineHeight: '18px',
    borderRadius: '4px',
    padding: '6px 8px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      borderRadius: '2px'
    }
  },
  tableContainer: {
    width: 'auto',
    height: 'auto',
    [theme.breakpoints.down('xl')]: {
      marginTop: '-2px',
      height: 'auto'
    }
  },
  tableRow: {
    '&$selected:selected': {
      background: '#d8efed'
    }
  },
  tableHeadCell: {
    padding: '10px 16px',
    color: '#4db6ac',
    fontSize: '13px',
    fontWeight: '400',
    backgroundColor: '#f9f9f9',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px',
      fontSize: '11px'
    }
  },
  tableCell: {
    fontSize: '13px',
    padding: '10px 16px',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px',
      fontSize: '12px'
    }
  },
  tableCellActivity: {
    display: 'inline-block',
    fontSize: '13px',
    padding: '10px 16px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'fill-available',
    overflow: 'hidden',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px',
      fontSize: '12px',
      maxWidth: '650px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 8px',
      fontSize: '12px',
      maxWidth: '240px'
    }
  },
  dialogActivityName: {
    width: '100%',
    textAlign: 'left',
    fontSize: '18px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px'
    }
  },
  dialogTabs: {
    background: '#f5f5f5',
    borderRadius: '4px'
  },
  smallButton: {
    minWidth: '30px',
    padding: '0px',
    fontSize: '13px',
    color: '#cccccc'
  },
  stravaButton: {
    minWidth: 0,
    margin: '2px 2px 2px 0'
  },
  stravaIcon: {
    color: '#FC4C02',
    fontSize: '16px',
    opacity: '0.7',
    marginTop: '-2px'
  },
  descTitle: {
    fontSize: '12px',
    marginTop: '10px',
    textAlign: 'center'
  },
  paginationSpacer: {
    flex: '1 1 0%',
    padding: '0 8px 8px 8px'
  },
  modifyButton: {
    border: '1px #dddddd solid',
    borderRadius: '4px',
    color: '#4db6ac',
    margin: '8px 0'
  },
  activityTitleBlock: {
    position: 'relative',
    zIndex: 10,
    width: 'calc(100% + 16px)',
    flexGrow: 1,
    height: '36px',
    margin: '0px 0 -12px -2px'
  },
  activityTitleCloseButton: {
    width: '32px',
    height: '32px',
    marginLeft: '8px',
    padding: '4px'
  },
  activityTitleButton: {
    display: 'inline-block',
    width: 'calc(100% - 40px)',
    boxSizing: 'border-box',
    border: 'none',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '15px',
    color: '#455a64',
    padding: '4px 0 6px 0',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'left',
      fontSize: '13px',
      height: '32px',
      padding: '4px 0 6px 0'
    }
  },
  dialogAction: {
    background: '#f5f5f5'
  }
}));

const ActivityUrl = props => {
  //다국어적용 {t('page.key')}
  //특수한 훈련을 했을 때, 상세분석탭이 추가로 뜨게 된다.
  //FTP테스트분석
  //FATMAX 최적화 훈련
  //로우케이던스 훈련
  //고강도 최적화 훈련 = 사일러이펙트
  //Break Away 훈련 = 성냥개비
  //에어로분석 (ITT) = 공기저항값
  //힐리핏트레이닝
  //구간PR시도

  const { t } = useTranslation(['page']);

  //unit default
  const unit = 'metric';
  const kmToMile = 0.621371;
  const mToFeet = 3.28084;
  const kgToLbs = 2.20462;

  const columns = [
    { id: 'activity_name', label: t('Dashboard.activity'), minWidth: 220 },
    { id: 'average_watts', label: t('Dashboard.avgPower'), minWidth: 90 },
    { id: 'distance', label: t('Dashboard.distance'), minWidth: 74 },
    { id: 'moving_time', label: t('Dashboard.duration'), minWidth: 74 },
    { id: 'ride_data', label: t('Dashboard.date'), minWidth: 110 },
    { id: 'tss_text', label: t('Dashboard.score3'), minWidth: 170 },
    { id: 'activity_update', label: '', minWidth: 20, align: 'right' },
    { id: 'activity_url', label: '', minWidth: 0, display: 'none' },
    { id: 'activity_id', label: '', minWidth: 0, display: 'none' },
    { id: 'activity_desc', label: '', minWidth: 0, display: 'none' }
  ];

  const { wkgSelect, locale } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(20);
  const [activityUpdateOpen, setActivityUpdateOpen] = React.useState(false);

  const [activityId, setActivityId] = React.useState(props.activityId);
  const [activityName, setActivityName] = React.useState('');

  const [publicExtraInfo, setPublicExtraInfo] = React.useState('');

  const [chartType, setChartType] = React.useState('stream');
  const [dialogActivityId, setDialogActivityId] = React.useState(0);
  const [dialogActivityName, setDialogActivityName] = React.useState('');
  const [activityDialog, setActivityDialog] = React.useState(true);
  const [activityDesc, setActivityDesc] = React.useState(null);
  const [streamDataJson, setStreamDataJson] = useState([]);
  const [circle, setCircle] = useState(true);

  const [tabSelect, setTabSelect] = React.useState(0);

  const handleTabList = (event, newTab) => {
    setTabSelect(newTab);
  };

  useEffect(() => {
    const getActivityStream = () => {
      setCircle(true);
      setStreamDataJson([]);

      let url = `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-activity-url-stream`;

      let params = {
        order: 'get_stream',
        activity_id: activityId
      };

      if (auth.getUserInfo() !== null) {
        params = {
          order: 'get_stream',
          activity_id: activityId,
          user_id: auth.getUserInfo().ID
        };
      }

      axios
        .get(url, {
          params: params
        })
        .then(async function(response) {
          setCircle(false);

          const data = response.data;
          if (data != false) {
            setStreamDataJson(data);
            setActivityName(data.activity_name);
          } else {
            setStreamDataJson('false');
            setActivityName('접근불가능');
          }
        });
    };

    if (activityId > 0 && chartType === 'stream') {
      getActivityStream();
    }

    console.log(activityId);
  }, [activityId]);

  const classes = useStyles();

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + 'h ' : '';
      let md = m > 0 ? m + 'm' : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + 'm ' : '';
      let sd = s > 0 ? s + 's' : '';

      return md + sd;
    }
  }

  function secondsToHmsLabel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '';
    } else if (h > 0) {
      let hd = h > 0 ? h + ':' : '00';
      let md = m > 0 ? m : '00';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + ':' : '00';
      let sd = s > 0 ? s : '00';

      return md + sd;
    }
  }

  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          size="large">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
          size="large">
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  function handleDialogClose() {
    setActivityUpdateOpen(false);
  }

  function handleDialogOpen(e, id, name) {
    e.stopPropagation();
    setActivityId(0);
    setActivityName('');
    setDialogActivityId(id);
    setDialogActivityName(name);
    setActivityUpdateOpen(true);
  }

  function ChartContent(props) {
    const streamDataJson = props.streamDataJson;

    $('#chartContainer').remove();

    //		console.log(streamDataJson.hasOwnProperty('powerzone_data') + " " + chartType);

    if (streamDataJson.length === 0) {
      //stream 불러올 때 로딩써클
      return (
        <React.Fragment>
          <Box
            style={{
              minHeight: '436px',
              textAlign: 'center',
              padding: '100px'
            }}>
            <CircularProgress size={128} color="secondary" />
          </Box>
        </React.Fragment>
      );
    } else if (streamDataJson === 'false') {
      //stream 실패시

      return (
        <React.Fragment>
          <Box
            style={{ minHeight: '20px', textAlign: 'center', padding: '20px' }}>
            {t('Dashboard.noActivities')}
          </Box>
        </React.Fragment>
      );
    } else if (chartType === 'powercurve') {
      if (0) {
        return <React.Fragment></React.Fragment>;
      } else {
        return (
          <React.Fragment>
            <PowerCurve
              extraInfo={publicExtraInfo}
              activityId={activityId}
              activityName={activityName}
              activityDesc={activityDesc}
              ftp={Math.round(streamDataJson.ftp)}
              pdcData={streamDataJson.pdc_list}
              streamDataJson={streamDataJson}
              wkg={wkgSelect}
              weight={streamDataJson.weight}
            />
          </React.Fragment>
        );
      }
    } else if (chartType === 'powerzone') {
      return (
        <React.Fragment>
          <PowerZone
            extraInfo={publicExtraInfo}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(streamDataJson.ftp)}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
            powerzoneData={streamDataJson.powerzone_data}
          />
        </React.Fragment>
      );
    } else if (chartType === 'hrzone') {
      return (
        <React.Fragment>
          <HeartrateZone
            extraInfo={publicExtraInfo}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(streamDataJson.ftp)}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
            maxHr={streamDataJson.mean_max_hr}
            powerzoneData={streamDataJson.powerzone_data}
          />
        </React.Fragment>
      );
    } else if (chartType === 'interval') {
      return (
        <React.Fragment>
          <Interval
            extraInfo={publicExtraInfo}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(streamDataJson.ftp)}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
            maxHr={streamDataJson.mean_max_hr}
            powerzoneData={streamDataJson.powerzone_data}
          />
        </React.Fragment>
      );
    } else if (chartType === 'lap') {
      return (
        <React.Fragment>
          <Lap
            extraInfo={publicExtraInfo}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(streamDataJson.ftp)}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
            maxHr={streamDataJson.mean_max_hr}
          />
        </React.Fragment>
      );
    } else if (chartType === 'metabolism') {
      return (
        <React.Fragment>
          <Metabolism
            extraInfo={publicExtraInfo}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(streamDataJson.ftp)}
            streamDataJson={streamDataJson}
            metabolismArray={streamDataJson.metabolism_array}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
            maxHr={streamDataJson.mean_max_hr}
          />
        </React.Fragment>
      );
    } else {
      //activity stream
      return (
        <React.Fragment>
          <ActivityStream
            extraInfo={publicExtraInfo}
            ftp={Math.round(streamDataJson.ftp)}
            summaryData={streamDataJson.summary_data}
            streamDataJson={streamDataJson}
            pdcData={streamDataJson.pdc_data}
            powerzoneData={streamDataJson.powerzone_data}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
          />
        </React.Fragment>
      );
    }
  }

  const handleTabChart = (event, value) => {
    setChartType(value);
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullScreen={true}
        fullWidth={true}
        maxWidth={'xl'}
        open={activityDialog}
        scroll={'body'}
        PaperProps={{
          style: {
            padding: '0px'
          }
        }}>
        <DialogTitle style={{ padding: '8px' }}>
          <Box>
            <Button className={classes.activityTitleButton}>
              {'🚴 ' + activityName}
            </Button>
          </Box>
        </DialogTitle>
        <Divider />

        <DialogContent
          style={
            window.innerWidth < 960 ? { padding: '8px' } : { padding: '16px' }
          }>
          <Tabs
            className={classes.dialogTabs}
            value={chartType}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChart}>
            {/* 조건에 따라 차트 메뉴들이 보이고 안보이도록  */}
            {streamDataJson.activity_id > 0 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.summary')}
                value={'stream'}
              />
            ) : (
              ''
            )}
            {streamDataJson.watts_5 > 1 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.powercurve')}
                value={'powercurve'}
              />
            ) : (
              ''
            )}
            {streamDataJson.watts_5 > 1 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.powerzone')}
                value={'powerzone'}
              />
            ) : (
              ''
            )}
            {streamDataJson.max_hr > 50 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.hrzone')}
                value={'hrzone'}
              />
            ) : (
              ''
            )}
            {streamDataJson.watts_60 > streamDataJson.ftp ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.interval')}
                value={'interval'}
              />
            ) : (
              ''
            )}
            {
              //(streamDataJson.lap !== null) ? <Tab style={{minWidth:'0px'}} label={t('Activity.lap')} value={'lap'} /> : ""
            }
            {streamDataJson.watts_5 > 10000 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.metabolism')}
                value={'metabolism'}
              />
            ) : (
              ''
            )}
          </Tabs>
          <ChartContent streamDataJson={streamDataJson} />
        </DialogContent>
        <DialogActions style={{ display: 'block', textAlign: 'center' }}>
          <Button
            size="large"
            variant="contained"
            color="primary"
            href="../dashboard">
            {t('SignIn.toFirst')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

ActivityUrl.propTypes = {
  className: PropTypes.string
};

export default withRouter(ActivityUrl);
