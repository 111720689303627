import React, { useEffect, useState, useRef } from 'react';
import MapGL, {
  Source,
  Layer,
  NavigationControl,
  GeolocateControl,
  Marker,
  WebMercatorViewport
} from 'react-map-gl';

import { useTheme } from '@mui/styles';
import { Box } from '@mui/material';

import RoomIcon from '@mui/icons-material/Room'; // 이 예시에서 마커 아이콘으로 사용합니다.
import * as turf from '@turf/turf';
import AreaChart from '../Chart/AreaChart';

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const applyToArray = (func, array) => func.apply(Math, array);

/**
 * 지도 내 geoJson 모두 포함하도록 longitude, latitude, zoom 계산
 * 참고: https://gist.github.com/tomsoderlund/a2040d659aafe4064e4060f561aca6d1
 */
const getBoundsForPoints = (points, clientWidth, clientHeight) => {
  // Calculate corner values of bounds
  const pointsLong = points.map(point => point[0]);
  const pointsLat = points.map(point => point[1]);
  const cornersLongLat = [
    [applyToArray(Math.min, pointsLong), applyToArray(Math.min, pointsLat)],
    [applyToArray(Math.max, pointsLong), applyToArray(Math.max, pointsLat)]
  ];

  // Use WebMercatorViewport to get center longitude/latitude and zoom
  const viewport = new WebMercatorViewport({
    width: clientWidth,
    height: clientHeight
  }).fitBounds(cornersLongLat); // Can also use option: offset: [0, -100]

  const { longitude, latitude, zoom } = viewport;
  return { longitude, latitude, zoom: zoom - 1 };
};

function CourseMapWithMarker({ geoJson, initialMarker, markerDesc }) {
  const theme = useTheme();
  const [viewport, setViewport] = useState({
    latitude: initialMarker.latitude,
    longitude: initialMarker.longitude,
    zoom: 11,
    bearing: 0,
    pitch: 0
  });

  // geoJson 데이터가 존재하는 경우 coordinate 요소에 따라 누적거리와 고도를 기록합니다.
  const [coordinateIndex, setCoordinateIndex] = useState(-1);
  const [coordinates, setCoordinates] = useState([]);
  const [prefixSumDistance, setPrefixSumDistance] = useState([]);
  const [altitude, setAltitude] = useState([]);
  const mapRef = useRef(null);

  useEffect(() => {
    if (!geoJson) return;

    const coordinatesInit = geoJson.features[0].geometry.coordinates;
    const prefixSumDistanceInit = [0];
    const altitudeInit = [coordinatesInit[0][2]];
    let accDistance = 0;

    // altitude 배열과 prefixSumDistance 배열을 coordinates 배열에 맞춰 생성
    for (let i = 0; i < coordinatesInit.length - 1; i++) {
      const from = turf.point(coordinatesInit[i].slice(0, 2));
      const to = turf.point(coordinatesInit[i + 1].slice(0, 2));
      const options = { units: 'kilometers' };
      const distance = turf.distance(from, to, options);
      accDistance += distance;

      prefixSumDistanceInit.push(accDistance);
      altitudeInit.push(coordinatesInit[i + 1][2]);
    }

    setCoordinates(coordinatesInit);
    setPrefixSumDistance(prefixSumDistanceInit);
    setAltitude(altitudeInit);

    let clientWidth = 900;
    let clientHeight = 300;
    if (mapRef.current) {
      clientWidth = mapRef.current.clientWidth;
      clientHeight = mapRef.current.clientHeight;
    }

    // zoom, long, lat 새롭게 설정
    const bounds = getBoundsForPoints(
      [...coordinatesInit, [initialMarker.longitude, initialMarker.latitude]],
      clientWidth,
      clientHeight
    );
    setViewport(prev => ({ ...prev, ...bounds }));
  }, [geoJson, initialMarker]);

  const layerStyle = {
    id: 'Afreeca',
    type: 'line',
    paint: {
      'line-color': theme.palette.secondary.main,
      'line-width': 5,
      'line-opacity': 1
    },
    'line-cap': 'round',
    visibility: 'visible'
  };

  return (
    <>
      <div style={{ borderRadius: '8px', overflow: 'hidden' }} ref={mapRef}>
        <MapGL
          {...viewport}
          width="100%"
          height="300px"
          mapStyle="mapbox://styles/mapbox/streets-v12"
          onViewportChange={setViewport}
          mapboxApiAccessToken={MAPBOX_TOKEN}
          touchZoom={false}
          scrollZoom={false}>
          {geoJson ? (
            <Source id="my-data" type="geojson" data={geoJson}>
              <Layer {...layerStyle} />
            </Source>
          ) : null}
          {coordinateIndex >= 0 ? (
            <Marker
              longitude={coordinates[coordinateIndex][0]}
              latitude={coordinates[coordinateIndex][1]}
              style={{ zIndex: 10 }}>
              <div
                style={{
                  width: 10,
                  height: 10,
                  backgroundColor: '#ffffff',
                  transform: 'translate(-50%, calc(-100% + 5px))',
                  borderRadius: '50%',
                  border: `2px solid ${theme.palette.secondary.main}`
                }}
              />
              <Box
                style={{
                  color: '#000000',
                  backgroundColor: '#FFFFFF',
                  transform: 'translate(-50%, calc(-100% - 20px))',
                  fontSize: 12,
                  padding: 5,
                  border: '1px solid #455a64',
                  borderRadius: 5
                }}>
                <Box>고도: {coordinates[coordinateIndex][2].toFixed(0)} m</Box>
                <Box>
                  거리: {prefixSumDistance[coordinateIndex].toFixed(2)} km
                </Box>
              </Box>
            </Marker>
          ) : null}

          {initialMarker && ( // 마커 상태가 있으면 마커 표시
            <Marker
              latitude={initialMarker.latitude}
              longitude={initialMarker.longitude}>
              <RoomIcon
                style={{
                  color: theme.palette.primary.main,
                  transform: 'translate(-50%, -100%)'
                }}
              />
              {markerDesc && (
                <Box
                  style={{
                    color: '#000000',
                    backgroundColor: '#FFFFFF',
                    transform: 'translate(-50%, calc(-100% - 55px))',
                    fontSize: 12,
                    padding: 5,
                    border: '1px solid #455a64',
                    borderRadius: 5
                  }}>
                  {`집결지: ${markerDesc}`}
                </Box>
              )}
            </Marker>
          )}

          <div className="navi-control">
            <NavigationControl
              style={{ position: 'absolute', top: '6px', left: '6px' }}
            />
          </div>
          <div className="current-location-control">
            <GeolocateControl
              style={{ position: 'absolute', top: '6px', right: '6px' }}
            />
          </div>
        </MapGL>

        {prefixSumDistance.length && altitude.length ? (
          <AreaChart
            prefixSumDistance={prefixSumDistance}
            altitude={altitude}
            setCoordinateIndex={setCoordinateIndex}
          />
        ) : null}
      </div>
    </>
  );
}

export default CourseMapWithMarker;
