import React from 'react';
import { IconButton } from '@mui/material';
import BurgerMenuIcon from './BurgerMenuIcon';
import BurgerCloseIcon from './BurgerCloseIcon';

export default function MobileMenu({ isOpen, setIsOpen }) {
  return (
    <IconButton
      disableRipple
      onClick={() => setIsOpen(!isOpen)}
      sx={{ padding: 0, margin: 0 }}>
      {isOpen ? <BurgerCloseIcon /> : <BurgerMenuIcon />}
    </IconButton>
  );
}
