import React from 'react';
import { Stack } from '@mui/material';
import { DynamicFontTypography } from 'components/Typography';

export default function Section({ id, title, content }) {
  return (
    <Stack
      component="section"
      id={id}
      gap={{ xs: 4, lg: 5 }}
      width="100%"
      height="100%"
      minHeight="min-content"
      flexDirection="column"
      justifyContent="start"
      alignItems="center"
      padding={{ xs: '20px 0px 20px', md: '50px 0px 48px' }}>
      <DynamicFontTypography
        sx={{
          fontWeight: 600,
          fontSize: { xs: '24px', sm: '28px', md: '36px', lg: '44px' }
        }}>
        {title}
      </DynamicFontTypography>
      {content}
    </Stack>
  );
}
