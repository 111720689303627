import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';

import { makeStyles, useTheme } from '@mui/styles';

import {
  Avatar,
  Button,
  IconButton,
  Menu,
  Box,
  MenuItem,
  Typography,
  Container,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ShareIcon from '@mui/icons-material/Share';

import organizeImagesByOriginId from 'views/Peloton/Utils/organizeImagesByOriginId';

import { ProfilePop } from 'views/Peloton/Common/ProfilePopModal';
import DeleteModal from 'views/Peloton/Common/DeleteModal';

import { useFormatDate } from 'utils/useFormatDate';
import { getImageUrl } from 'utils/getImageUrl';
import { imageSize } from 'utils/helper';
import { useCopyLink } from 'utils/useCopyLink';

import { deletePostApi, getUserInfoApi } from 'utils/clientApis';
import PopUpDialog from 'views/Peloton/Common/PopUpDialogLayout';
import EditPost from '../Edit/EditPost';

function Post({ post, userInfoNow, uriName, pelotonId, handleFetchPost }) {
  const ITEM_HEIGHT = 48;

  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();

  const { formatDateWithTime } = useFormatDate();
  const formattedDateForPost = formatDateWithTime(post?.regDate, 'Asia/Seoul');

  const [anchorEl, setAnchorEl] = useState(null);
  const [openCopy, setOpenCopy] = useState(false);
  const [profileOpen, setProfileOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [openEditPop, setOpenEditPop] = useState(false);

  const { copied, copyLinkToClipboard } = useCopyLink();
  // 링크 공유하기
  const handleCopyLinker = () => {
    copyLinkToClipboard();
  };

  useEffect(() => {
    if (copied) {
      setOpenCopy(true);
    }
  }, [copied]);

  const postOpen = Boolean(anchorEl);

  // 게시글 메뉴 클릭 핸들러
  const handleAnchorClick = event => {
    setAnchorEl(event.currentTarget);
  };

  // 게시글 메뉴 닫기 핸들러
  const handleAnchorClose = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = () => {
    setProfileOpen(true);
  };

  const handleClickDeleteModalOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteSession = async () => {
    const response = await deletePostApi(pelotonId, post.id);
    if (response) {
      setDeleteOpen(false);
      history.push(`/peloton/${uriName}`);
    }
  };

  const handleOpenEditPop = () => {
    setOpenEditPop(true);
  };

  const handleCloseEditPop = () => {
    setOpenEditPop(false);
  };

  const getUserInfo = () => {
    return getUserInfoApi(pelotonId, post.clubUserResponse?.id);
  };

  // 함수 호출과 로그 출력
  const organizedData = organizeImagesByOriginId(post.clubPostFiles);

  const isWriter = userInfoNow.nickname === post.clubUserResponse?.displayName;
  const isAdminOrManager =
    userInfoNow.role === 'MANAGER' || userInfoNow.role === 'ADMIN';

  return <>
    <Container className={classes.header}>
      <Box className={classes.writerInfoBox}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: ' 100%',
            alignItems: 'center'
          }}>
          {/* 게시글 보조정보 (아바타, 작성자, 작성시간) */}
          <div style={{ display: 'flex' }}>
            <Avatar
              src={post.clubUserResponse?.profileImage}
              variant="circular"
              style={{
                width: '40px',
                height: '40px',
                marginRight: '8px',
                cursor: 'pointer'
              }}
              onClick={() => handleClickProfile()}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: theme.spacing(0.5)
              }}>
              {post?.clubUserResponse && (
                <Typography variant="h6" color="textPrimary">
                  {!post?.clubUserResponse
                    ? '탈퇴한 회원'
                    : post.clubUserResponse?.displayName}
                </Typography>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ cursor: 'default' }}>
                {formattedDateForPost}
              </Typography>
            </div>
          </div>

          {/* 아이콘 (복사 및 삭제) */}
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              style={{ paddingRight: '0px' }}
              onClick={handleCopyLinker}
              size="large">
              <ShareIcon style={{ width: '19px', height: '19px' }} />
            </IconButton>
            {/* 점세개 아이콘으로 메뉴 열기 & 사용 권한 통제부 */}
            {(isWriter || isAdminOrManager) && (
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={event => handleAnchorClick(event)}
                size="large">
                <MoreVertIcon />
              </IconButton>
            )}
          </div>
        </div>
      </Box>

      {/* 삭제 버튼 클릭시 드랍다운 */}
      <Box style={{ width: '100%' }}>
        <Menu
          id="post-long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={postOpen}
          onClose={handleAnchorClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
              borderRadius: '16px'
            }
          }}>
          {/* 작성자인 경우 수정 메뉴 아이템 */}
          {isWriter ? (
            <MenuItem
              onClick={() => {
                handleOpenEditPop();
                handleAnchorClose();
              }}>
              수정
            </MenuItem>
          ) : null}

          {/* 작성자인 경우 삭제 메뉴 아이템, 관리자인 경우 삭제(관리자) 메뉴 아이템 */}
          {isWriter ? (
            <MenuItem onClick={() => handleClickDeleteModalOpen()}>
              삭제
            </MenuItem>
          ) : isAdminOrManager ? (
            <MenuItem onClick={() => handleClickDeleteModalOpen()}>
              {'삭제 (관리자)'}
            </MenuItem>
          ) : null}
        </Menu>
      </Box>

      {/* 게시글 제목 */}
      <Typography color="textPrimary" className={classes.postTitle}>
        {post.title}
      </Typography>

      <Box style={{ width: '100%' }}>
        {/* 이미지 캐러셀 */}
        {organizedData.length
          ? organizedData.map((image, index) => (
              <Box className={classes.imageContainer} key={index}>
                <img
                  className={classes.image}
                  src={getImageUrl(image.images, imageSize.LG)}
                  alt={`게시글 이미지 ${index + 1}`}
                />
              </Box>
            ))
          : null}

        {/* 게시글 */}
        <Box className={classes.contentBox}>
          <Typography variant="body1" color="textPrimary">
            {post.content?.split('\n').map((paragraph, index) => (
              <React.Fragment key={index}>
                {paragraph}
                <br />
              </React.Fragment>
            ))}
          </Typography>
        </Box>
      </Box>

      {profileOpen && (
        <ProfilePop
          openState={profileOpen}
          setOpen={setProfileOpen}
          getUserInfo={getUserInfo}
        />
      )}
      {openCopy && (
        <Dialog
          open={openCopy}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <>
            <DialogTitle id="alert-dialog-title" text="h6">
              링크 공유하기
            </DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                text="body1"
                color="textPrimary">
                주소가 성공적으로 복사 되었습니다!
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenCopy(false)} color="primary">
                확인
              </Button>
            </DialogActions>
          </>
        </Dialog>
      )}
      {deleteOpen && (
        <DeleteModal
          handleDeleteSession={handleDeleteSession}
          openState={deleteOpen}
          contentText={'정말로 게시글을 삭제 하시겠습니까?'}
          setOpen={setDeleteOpen}
        />
      )}
      {openEditPop && (
        <PopUpDialog
          openPop={openEditPop}
          title="이벤트 수정하기"
          handleClose={handleCloseEditPop}>
          <EditPost
            handleClosePop={handleCloseEditPop}
            title={post.title}
            content={post.content}
            clubPostFiles={post.clubPostFiles}
            pelotonId={pelotonId}
            postId={post.id}
            handleFetchPost={handleFetchPost}
          />
        </PopUpDialog>
      )}
    </Container>
  </>;
}

const useStyles = makeStyles(theme => ({
  header: {
    width: '100%',
    background: theme.palette.background.colored.light,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '8px 12px',
    borderRadius: '4px',
    '& > *': {
      marginRight: '16px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '16px 24px',
      fontSize: '16px'
    }
  },
  imageContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '16px'
  },
  image: {
    width: '100%',
    objectFit: 'cover'
  },
  contentBox: {
    minHeight: '100px'
  },
  writerInfoBox: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    fontSize: '16px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px'
    }
  },
  postTitle: {
    margin: '8px 0 0 0',
    borderTop: '1px #dddddd solid',
    padding: '12px 0 12px 0',
    fontSize: '16px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px'
    }
  }
}));

export default Post;
