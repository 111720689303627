import React from 'react';
import { Box } from '@mui/material';
import { DynamicFontTypography } from 'components/Typography';

function extractFirstImage(html) {
  const imgMatch = html.match(/<img[^>]+src=["']([^"'>]+)["']/i);
  return imgMatch ? imgMatch[1] : '/images/cover.png';
}

function decodeHTMLEntities(text) {
  const txt = document.createElement('textarea');
  txt.innerHTML = text;
  return txt.value;
}

function convertHtmlToMuiComponents(html) {
  const nodes = [];
  const paragraphRegex = /<p[^>]*>([\s\S]*?)<\/p>/gi;
  let match;

  while ((match = paragraphRegex.exec(html)) !== null) {
    let content = match[1];

    // Replace <br> tags with actual newlines
    content = content
      .replace(/<br\s*\/?>/gi, '\u200B')
      .replace(/&nbsp;/gi, ' ');

    // Check for img inside <p>
    const imgMatch = content.match(/<img[^>]+src=["']([^"'>]+)["'][^>]*>/i);
    if (imgMatch) {
      const src = imgMatch[1];
      const altMatch = content.match(/alt=["']([^"']*)["']/i);
      const alt = altMatch ? altMatch[1] : '';

      nodes.push(
        <Box
          key={src}
          width="100%"
          height="auto"
          overflow="hidden"
          borderRadius="4px"
          marginBottom={4}>
          <Box
            component="img"
            src={src.trim()}
            alt={alt.trim()}
            width="100%"
            height="100%"
            sx={{ objectPosition: 'center', objectFit: 'cover' }}
          />
        </Box>
      );
    } else {
      nodes.push(
        <DynamicFontTypography
          key={match.index}
          sx={{
            fontSize: '16px',
            lineHeight: '28px',
            whiteSpace: 'pre-line',
            mb: 3
          }}>
          {decodeHTMLEntities(content.trim())}
        </DynamicFontTypography>
      );
    }
  }

  return nodes;
}

function formatDateTime(datetime) {
  const date = new Date(datetime);
  return date.toLocaleString(undefined, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false
  });
}

export function transformApiData(writes, boardType) {
  const baseUrl = boardType === 'free' ? '/blog/' : '/news/';

  return writes.map(write => {
    const firstImage = extractFirstImage(write.wr_content);

    return {
      id: write.wr_id,
      title: write.wr_subject.trim(),
      content: convertHtmlToMuiComponents(write.wr_content.trim()),
      date: formatDateTime(write.wr_datetime),
      userId: write.mb_id.trim(),
      username: write.wr_name.trim(),
      img: firstImage || '/cover.png',
      commentCount: write.comments.length,
      comments: write.comments.map(comment => ({
        id: String(comment.wr_id),
        userId: comment.mb_id.trim(),
        username: comment.wr_name.trim(),
        comment: comment.save_content.trim(),
        createdAt: formatDateTime(comment.wr_datetime),
        parentId: comment.wr_parent ? String(comment.wr_parent) : null,
        likes: 0,
        isAuthor: comment.mb_id === write.mb_id,
        status: 'approved'
      })),
      slug: String(write.wr_id),
      url: `${baseUrl}${write.wr_id}`,
      desc:
        write.wr_content
          .replace(
            /<a [^>]*href=["']mailto:([^"'>]+)["'][^>]*>(.*?)<\/a>/gi,
            '$1'
          )
          .replace(/<br\s*\/?>/gi, ' ')
          .replace(/\r?\n|\r/g, ' ')
          .replace(/<[^>]+>/g, '')
          .replace(/\s+/g, ' ')
          .trim()
          .slice(0, 40) + '...',
      featured: 'false'
    };
  });
}
