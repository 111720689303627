import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Box,
  ButtonGroup,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Popover,
  IconButton,
  useMediaQuery,
  Stack
} from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BackspaceIcon from '@mui/icons-material/Backspace';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ApexChart from 'react-apexcharts';
import cloneDeep from 'lodash/cloneDeep';
import ChartOptions from './Options/OptionActivityStream.js';

//jQuery 추가
import $ from 'jquery';

//Chart 추가
import CanvasJSReact from '../../../utils/canvasjs.stock.react';
import {
  kilometerToMile,
  kiloPerHourToMilePerHour,
  meterToFeet,
  secondsToHms,
  wattsToWPerKg
} from 'utils/unitCalculation.js';
import { fillMissingData } from 'utils/activity.js';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  root: {},
  smallButton: {
    minWidth: '30px',
    padding: '0px',
    fontSize: '13px',
    color: '#cccccc'
  },
  stravaButton: {
    minWidth: 0,
    margin: '2px 2px 2px 0'
  },
  summaryContainer: {
    height: 436,
    position: 'relative',
    overflow: 'hidden',
    margin: '0px -4px 0px -14px',
    padding: '10px 0 24px 0',
    [theme.breakpoints.down('xl')]: {
      height: 368
    },
    [theme.breakpoints.down('lg')]: {
      height: 276
    },
    [theme.breakpoints.down('sm')]: {
      height: 256
    }
  },
  ToolBarBlock: {
    position: 'relative',
    zIndex: 10,
    flexGrow: 1,
    height: '36px'
  },
  ToolBarLeftButton: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
    padding: '4px'
  },
  ToolBarButton: {
    width: 'calc(100% - 40px)',
    boxSizing: 'border-box',
    fontSize: '15px',
    color: '#455a64',
    background: '#f5f5f5',
    opacity: '0.9',
    padding: '4px 0 6px 0',
    textAlign: 'left',
    borderRadius: '6px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      borderRadius: '4px',
      height: '32px',
      padding: '4px 0 6px 0'
    }
  },
  tssTextBold: {
    fontSize: '15px',
    margin: '0 16px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 12px 0 3px'
    }
  },
  streamWrap: {
    position: 'relative',
    textAlign: 'left',
    padding: '8px 4px',
    marginBottom: '12px',
    borderBottom: '1px #dddddd solid'
  },
  chip: {
    //border:'1px #dddddd solid',
    //borderRadius:'4px',
    marginRight: '4px',
    minWidth: '40px',
    padding: '5px 8px',
    fontSize: '12px',
    fontWeight: 400,
    height: '24px'
  },
  chartContainer: {
    minHeight: 388,
    position: 'relative',
    overflow: 'hidden',
    margin: '12px -24px 0 -8px',
    [theme.breakpoints.down('xl')]: {
      minHeight: 320
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: 'auto'
    }
  },
  chartContentWrap: {
    margin: '-8px -8px 0 0',
    overflow: 'hidden',
    height: '100px',
    [theme.breakpoints.down('xl')]: {
      height: '90px'
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: '100px'
    }
  },
  chartAltitudeWrap: {
    margin: '-32px -8px 0 0',
    height: '120px',
    [theme.breakpoints.down('xl')]: {
      height: '92px'
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: '110px'
    }
  },
  chartContent: {
    height: '135px',
    margin: '-20px 0 0 0'
  },
  chartButton: {
    fontSize: '15px',
    marginTop: '-6px',
    textTransform: 'none',
    padding: '0px',
    color: '#90a4ae'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tooltip: {
    fontSize: '13px',
    color: '#ffffff',
    padding: '8px 16px',
    backgroundColor: '#333333',
    borderRadius: '3px',
    opacity: '0.8'
  },
  tabs: {
    width: '300px',
    border: '1px #dddddd solid',
    borderRadius: '4px 4px 0 0'
  },
  buttonActivityName: {
    width: '100%',
    textAlign: 'center',
    fontSize: '15px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px'
    }
  },
  tabContent: {
    width: '300px',
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px'
  },
  listItem: {
    padding: '2px 12px'
  },
  tabTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  tsbDesc: {
    margin: '0px 10px 8px 10px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left'
  },
  cardContent: {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  },
  legendText: {
    marginLeft: '10px',
    paddingTop: '6px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
      marginLeft: '6px',
      marginTop: '-3px',
      paddingTop: '0px'
    }
  },
  tssEmoji: {
    backgroundColor: '#eeeeee',
    height: 40,
    width: 40,
    marginTop: '2px'
  },
  icon: {
    height: 40,
    width: 40,
    [theme.breakpoints.down('md')]: {
      height: 32,
      width: 32
    }
  },
  streamSummary: {
    background: '#f5f5f5',
    borderRadius: '4px',
    padding: '6px 12px'
  },
  summaryTitle: {
    lineHeight: '32px',
    fontWeight: 500,
    fontSize: '15px'
  },
  stravaDesc: {
    minWidth: '240px',
    padding: '8px',
    fontSize: '13px',
    borderBottom: '1px #eeeeee solid'
  },
  xaxisLabel: {
    color: '#888888',
    fontSize: '11px'
  },
  gridContainer: {
    color: '#999999',
    fontWeight: 300,
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    }
  },
  summaryText: {
    color: '#333333',
    fontSize: '13px',
    marginLeft: '8px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    }
  },
  infoContainer: { padding: '8px 12px' },
  columnHeader: { fontWeight: 500, fontSize: '13px' },
  dataField: { fontSize: '12px' },
  chartDivider: {
    padding: '4px 4px',
    borderBottom: '1px #dddddd solid',
    marginBottom: '12px'
  },
  stravaDescButtonContainer: { justifyContent: 'center' },
  copyText: { margin: '12px' }
}));

const ActivityStream = props => {
  //다국어적용 {t('page.key')}

  const { t } = useTranslation(['page']);
  const theme = useTheme();

  const extraInfo = props.extraInfo;

  //unit default
  const unit = extraInfo.unit ? extraInfo.unit : 'metric';
  const kmToMile = 0.621371;
  const mToFeet = 3.28084;
  const kgToLbs = 2.20462;

  const {
    activity,
    ftp,
    streamDataJson,
    pdcData,
    powerzoneData,
    wkg,
    weight,
    wPrime
  } = props;
  const [activityId, setActivityId] = useState(streamDataJson.activity_id);
  const activityFTP = streamDataJson.ftp;

  const activityName = streamDataJson.activity_name;
  const streamOrg = streamDataJson.stream;
  const streamData = streamDataJson.stream_json;
  const altitudeMax = streamDataJson.altitude_max;
  const stravaDescription = streamDataJson.strava_description;
  const streamLength = streamDataJson.obj_length;
  const movingDistance = streamDataJson.distance;
  const movingTime = streamDataJson.moving_time;
  const rideType = streamDataJson.ride_type;

  const activityPowerZone =
    powerzoneData.powerzone_activities.length > 0
      ? powerzoneData.powerzone_activities[String(activityId)]
      : null;

  //ref로 잡기 위함
  let chart = null;

  //인도어의 경우 distance가 시간임
  //console.log(streamDataJson.distance + " " + streamDataJson.moving_time);

  const toolTip = {
      shared: true,
      contentFormatter: function(e) {
        let content = ' ';
        for (let i = 0; i < e.entries.length; i++) {
          content +=
            e.entries[i].dataSeries.name +
            ' ' +
            '<strong>' +
            e.entries[i].dataPoint.y +
            '</strong>';
          content += '<br/>';
        }
        return content;
      }
    },
    legend = {
      cursor: 'pointer',
      itemclick: function(e) {
        if (
          typeof e.dataSeries.visible === 'undefined' ||
          e.dataSeries.visible
        ) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        e.chart.render();
      }
    };

  //temperatureArray
  //awcArray
  //cdaArray
  //headwindArray
  //energy metabolism Array
  //kCal Array
  //let stripLength = 0;

  function addStripline() {
    //		console.log(chart.charts[1]);

    let charts = chart.charts;

    for (let i = 0; i < charts.length; i++) {
      chart.charts[i].axisX[0].addTo(
        'stripLines',
        {
          startValue: 100,
          endValue: 1000,
          //				color:"#f06292",
          color: '#eeeeee',
          opacity: 1.0
        },
        2
      );
    }

    //chart.charts[1].axisX[0].stripLines[stripLength-1].remove();
    //stripLength = chart.charts[1].axisX[0].stripLines.length;
  }

  /*
  z1: {backgroundColor:'#aaaaaa',color:'#ffffff'},
  z2: {backgroundColor:'#76b5e8',color:'#ffffff'},
  z3: {backgroundColor:'#b0d36d',color:'#ffffff'},
  z4: {backgroundColor:'#ffd357',color:'#ffffff'},
  z5: {backgroundColor:'#ffa75c',color:'#ffffff'},
  z6: {backgroundColor:'#ff7e75',color:'#ffffff'},
  z7: {backgroundColor:'#f06292',color:'#ffffff'},
*/
  useEffect(() => {
    //setSearches([query].concat(searches)) // prepend to React State
    /*
		axisX:{
			stripLines:[
			{                
				startValue:1935,
				endValue:1945,                
				color:"#d8d8d8"                      
			}
			],
			valueFormatString: "####"
		},

    if (chart != null) {
      chart.container.addEventListener('click', handleClick);
      return function () {
        chart.container.removeEventListener('click', handleClick);
      };
    }
*/
  }, [activityId]);

  const classes = useStyles();

  function secondsToHmsLabel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '00:00';
    } else if (h > 0) {
      let hd = h > 0 ? (h < 10 ? '0' + h + ':' : h + ':') : '00';
      let md = m > 0 ? (m < 10 ? '0' + m + ':' : m + ':') : '00:';
      let sd = s > 0 ? (s < 10 ? '0' + s : s) : '00';

      return hd + md + sd;
    } else if (h <= 0 && m > 0) {
      let md = m > 0 ? (m < 10 ? '0' + m + ':' : m + ':') : '00';
      let sd = s > 0 ? (s < 10 ? '0' + s : s) : '00';

      return md + sd;
    } else if (m <= 0 && s > 0) {
      let md = '00:';
      let sd = s > 0 ? (s < 10 ? '0' + s : s) : '00';

      return md + sd;
    } else {
      return '00:00';
    }
  }

  //Activity Stream 상세차트보기
  function ChartContainer(props) {
    const movingDistance = props.movingDistance;
    const movingTime = props.movingTime;

    const streamArray = [];

    const timeArray = streamOrg.time;
    const distanceArray = streamOrg.distance;
    const velocityArray = streamOrg.velocity_smooth;
    const wattsArray = streamOrg.watts;
    const watts0Array = streamOrg.watts0;
    const gradeArray = streamOrg.grade_smooth;
    const awcArray = streamOrg.awc;
    const cadenceArray = streamOrg.cadence;
    const heartrateArray = streamOrg.heartrate;
    const altitudeArray = streamOrg.altitude;

    const durationPoints = streamData.duration;
    const distancePoints = streamData.distance;
    const velocityPoints = streamData.velocity;
    const wattsPoints = streamData.watts;
    const awcPoints = streamData.awc;
    const cadencePoints = streamData.cadence;
    const heartratePoints = streamData.heartrate;
    const altitudePoints = streamData.altitude;

    // max
    const wattsMax = streamData.watts_max,
      wattsMaxIndex = streamData.watts_max_index;
    const cadenceMax = streamData.cadence_max,
      cadenceMaxIndex = streamData.cadence_max_index;
    const velocityMax = streamData.velocity_max,
      velocityMaxIndex = streamData.velocity_max_index;
    const heartrateMax = streamData.heartrate_max,
      heartrateMaxIndex = streamData.heartrate_max_index;

    const ArrayAvg = arr => arr.reduce((p, c) => p + c) / arr.length;

    const wattsAvg = wattsArray
      ? Math.round(ArrayAvg(wattsArray) * 10) / 10
      : 0;
    const cadenceAvg = cadenceArray
      ? Math.round(ArrayAvg(cadenceArray) * 10) / 10
      : 0;
    const velocityAvg = velocityArray
      ? Math.round(ArrayAvg(velocityArray) * 36) / 10
      : 0;
    const heartrateAvg = heartrateArray
      ? Math.round(ArrayAvg(heartrateArray) * 10) / 10
      : 0;

    //StripLine
    const [wattsChartStripLines, setWattsChartStripLines] = useState([]);

    //x축 기준 정하기
    const xAxisArray = rideType === 'Ride' ? distanceArray : timeArray;
    const xPoints = rideType === 'Ride' ? durationPoints : durationPoints;

    const streamOptions = {
      animationEnabled: false,
      theme: 'light2',
      title: false,
      toolTip: toolTip,
      axisX: {
        gridThickness: 0,
        tickLength: 0,
        lineThickness: 1,
        lineColor: '#dddddd',
        labelFontColor: '#ffffff',
        valueFormatString: '.',
        labelAngle: 0,
        crosshair: {
          enabled: true,
          lineColor: '#999999',
          labelFormatter: function(e) {
            if (rideType === 'Ride') {
              let distance =
                unit === 'metric'
                  ? Math.round(e.value / 100) / 10 + 'km'
                  : Math.round((e.value * kmToMile) / 100) / 10 + ' mi';

              return distance;
            } else if (rideType === 'VirtualRide') {
              return secondsToHmsLabel(e.value);
            }
          }
        }
      },
      axisY: {
        labelPlacement: 'inside',
        gridThickness: 0,
        lineColor: theme.palette.chart.primary.axis.line,
        tickColor: theme.palette.chart.primary.axis.tick,
        gridColor: theme.palette.chart.primary.axis.grid,
        labelFontColor: theme.palette.chart.primary.axis.fontColor,
        minimum: 1
      },
      legend: legend
    };

    let distanceTitle =
      unit === 'metric'
        ? Math.round(movingDistance / 100) / 10 + 'km, '
        : Math.round((movingDistance * kmToMile) / 100) / 10 + 'mi, ';

    const distanceChartOptions = {
      ...cloneDeep(streamOptions),
      type: 'distance',
      subtitles: [
        {
          text:
            t('Dashboard.distance') +
            ' ' +
            distanceTitle +
            t('Dashboard.duration') +
            ' ' +
            secondsToHmsLabel(movingTime) +
            '',
          fontFamily: 'tahoma',
          fontSize: 12,
          fontColor: theme.palette.chart.text.primary,
          backgroundColor: theme.palette.chart.text.background,
          margin: 0
        }
      ],
      data: [
        {
          type: 'area',
          fillOpacity: 0.4,
          name: t('Dashboard.altitude'),
          lineThickness: 1,
          color: '#4db6ac',
          legendMarkerType: 'square',
          dataPoints: altitudePoints
        },
        {
          visible: false,
          type: 'line',
          fillOpacity: 0.4,
          name: t('Dashboard.duration'),
          lineThickness: 1,
          color: '#4db6ac',
          legendMarkerType: 'square',
          dataPoints: xPoints
        }
      ]
    };

    const alphaVelocityPoints = streamData.alpha_velocity;

    //속도 변환 km to mile
    let velocityAvgStr =
      unit === 'metric'
        ? velocityAvg + ' kph'
        : Math.round(velocityAvg * kmToMile * 10) / 10 + ' mph';

    const velocityChartOptions = {
      ...cloneDeep(streamOptions),
      type: 'velocity',
      subtitles: [
        {
          text:
            t('Dashboard.velocity') +
            ' ' +
            t('Dashboard.avg') +
            ' ' +
            velocityAvgStr,
          fontFamily: 'tahoma',
          fontSize: 12,
          fontColor: theme.palette.chart.text.primary,
          backgroundColor: theme.palette.chart.text.background,
          margin: 0
        }
      ],
      data: [
        {
          type: 'area',
          fillOpacity: 0.4,
          name: t('Dashboard.velocity'),
          lineThickness: 1,
          color: '#ffd357',
          legendMarkerType: 'square',
          dataPoints:
            streamDataJson.ride_type === 'Ride'
              ? velocityPoints
              : fillMissingData(velocityPoints || [])
        }
        /*
				//설악 테스트
				{
					type: "area", 
					fillOpacity: .4,
					name: t('Dashboard.velocity'),
					yValueFormatString: "# km/h",
					lineThickness: 1,
					color: "#000000", 
					legendMarkerType: "square",					
					dataPoints: alphaVelocityPoints
				}
*/
      ]
    };

    const wattsChartOptions = {
      ...cloneDeep(streamOptions),
      type: 'watts',
      subtitles: [
        {
          text:
            t('Dashboard.watts') +
            ' ' +
            t('Dashboard.avg') +
            ' ' +
            wattsAvg +
            'w',
          fontFamily: 'tahoma',
          fontSize: 12,
          fontColor: theme.palette.chart.text.primary,
          backgroundColor: theme.palette.chart.text.background,
          margin: 0
        }
      ],
      data: [
        {
          type: 'area',
          fillOpacity: 0.4,
          name: t('Dashboard.watts'),
          yValueFormatString: '# W',
          lineThickness: 1,
          color: '#f06292',
          legendMarkerType: 'square',
          dataPoints:
            streamDataJson.ride_type === 'Ride'
              ? wattsPoints
              : fillMissingData(wattsPoints || [])
        },
        {
          type: 'area',
          fillOpacity: 0.2,
          name: t('Dashboard.battery'),
          yValueFormatString: '# %',
          lineThickness: 1,
          color: '#dddddd',
          legendMarkerType: 'square',
          dataPoints: awcPoints
        } /*
				설악
				,{
					type: "area", 
					fillOpacity: .8,
					name: t('Dashboard.battery'),
					yValueFormatString: "# %",
					lineThickness: 1,
					color: "#8cc1ec",
					legendMarkerType: "square",
					dataPoints: awcPoints
				}*/
      ]
    };

    const cadenceChartOptions = {
      ...cloneDeep(streamOptions),
      type: 'cadence',
      subtitles: [
        {
          text:
            t('Dashboard.rpm') +
            ' ' +
            t('Dashboard.avg') +
            ' ' +
            cadenceAvg +
            'rpm',
          fontFamily: 'tahoma',
          fontSize: 12,
          fontColor: theme.palette.chart.text.primary,
          backgroundColor: theme.palette.chart.text.background,
          margin: 0
        }
      ],
      data: [
        {
          type: 'area',
          fillOpacity: 0.4,
          name: t('Dashboard.rpm'),
          color: '#8cc1ec',
          lineThickness: 1,
          legendMarkerType: 'square',
          dataPoints:
            streamDataJson.ride_type === 'Ride'
              ? cadencePoints
              : fillMissingData(cadencePoints || [])
        }
      ]
    };

    const heartrateChartOptions = {
      ...cloneDeep(streamOptions),
      type: 'heartrate',
      subtitles: [
        {
          text:
            t('Dashboard.hrm') +
            ' ' +
            t('Dashboard.avg') +
            ' ' +
            heartrateAvg +
            'bpm',
          fontFamily: 'tahoma',
          fontSize: 12,
          fontColor: theme.palette.chart.text.primary,
          backgroundColor: theme.palette.chart.text.background,
          margin: 0
        }
      ],
      data: [
        {
          type: 'area',
          fillOpacity: 0.4,
          name: t('Dashboard.hrm'),
          color: '#ffa75c',
          lineThickness: 1,
          legendMarkerType: 'square',
          dataPoints: heartratePoints
        }
      ]
    };

    if (velocityPoints) {
      velocityChartOptions.axisY.maximum = Math.round(velocityMax);

      velocityChartOptions.axisX.stripLines = [
        {
          value: velocityPoints[velocityMaxIndex].x,
          label: velocityMax,
          thickness: 1,
          color: '#ffd357',
          labelAlign: 'near',
          lineDashType: 'dash',
          labelPlacement: 'inside',
          labelFormatter: function(e) {
            return '';
          }
        }
      ];
      streamArray.push(velocityChartOptions);

      let toolTipVelocity = {
        shared: true,
        contentFormatter: function(e) {
          let content = ' ';
          for (let i = 0; i < e.entries.length; i++) {
            if (i == 0) {
              //속도 변환 km to mile
              let velocityAvgStr =
                unit === 'metric'
                  ? '<strong>' + e.entries[i].dataPoint.y + '</strong>kph'
                  : '<strong>' +
                    Math.round(e.entries[i].dataPoint.y * kmToMile * 10) / 10 +
                    '</strong>mph';

              content += e.entries[i].dataSeries.name + ' ' + velocityAvgStr;
              content += '<br/>';
            }
          }
          return content;
        }
      };

      velocityChartOptions.toolTip = toolTipVelocity;
    }

    if (wattsPoints) {
      wattsChartOptions.axisY.maximum = Math.round(wattsMax);
      wattsChartOptions.axisY.stripLines = [
        {
          value: activityFTP,
          label: 'FTP ' + activityFTP + ' W',
          labelPlacement: 'inside',
          labelFontSize: 11,
          labelAlign: 'far',
          labelFontColor: '#f06292',
          thickness: 1,
          color: '#f06292',
          lineDashType: 'dash'
        }
      ];

      wattsChartOptions.axisX.stripLines = [
        {
          value: wattsPoints[wattsMaxIndex].x,
          label: wattsMax,
          thickness: 1,
          color: '#f06292',
          labelAlign: 'near',
          lineDashType: 'dash',
          labelFormatter: function(e) {
            return '';
          }
        }
      ];

      //awc % 포함하기
      let toolTipWatts = {
        shared: true,
        contentFormatter: function(e) {
          let content = ' ';
          for (let i = 0; i < e.entries.length; i++) {
            if (i == 0) {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                e.entries[i].dataPoint.y +
                '</strong>w';
              content += '<br/>';
            } else {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                Math.round((1000 * e.entries[i].dataPoint.y) / wattsMax) / 10 +
                '</strong>%';
              content += '<br/>';
              /*
설악
	content += e.entries[i].dataSeries.name + " " + "<strong>" + e.entries[i].dataPoint.y + "</strong>w";
	content += "<br/>";
*/
            }
          }
          return content;
        }
      };

      wattsChartOptions.toolTip = toolTipWatts;
      streamArray.push(wattsChartOptions);
    }

    if (cadencePoints) {
      cadenceChartOptions.axisY.maximum = Math.round(cadenceMax);
      streamArray.push(cadenceChartOptions);
    }

    if (heartratePoints) {
      heartrateChartOptions.axisY.maximum = Math.round(heartrateMax);
      heartrateChartOptions.axisY.minimum = 60;
      heartrateChartOptions.axisX.stripLines = [
        {
          value: heartratePoints[heartrateMaxIndex].x,
          label: heartrateMax,
          thickness: 1,
          color: '#ffa75c',
          labelAlign: 'near',
          lineDashType: 'dash',
          labelFormatter: function(e) {
            return '';
          }
        }
      ];

      streamArray.push(heartrateChartOptions);
    }

    if (distancePoints && altitudePoints) {
      //			distanceChartOptions.axisY.maximum = Math.round(altitudeMax);
      distanceChartOptions.axisX.margin = 10;
      streamArray.push(distanceChartOptions);

      let toolTipAltitude = {
        shared: true,
        contentFormatter: function(e) {
          let content = ' ';
          for (let i = 0; i < e.entries.length; i++) {
            if (i == 0) {
              //unit 변환 km to mile
              let elevationStr =
                unit === 'metric'
                  ? '<strong>' + e.entries[i].dataPoint.y + '</strong>m'
                  : '<strong>' +
                    Math.round(e.entries[i].dataPoint.y * mToFeet * 10) / 10 +
                    '</strong>ft';

              content += e.entries[i].dataSeries.name + ' ' + elevationStr;
              content += '<br/>';

              if (rideType === 'Ride') {
                //unit 변환 km to mile
                let movingDstStr =
                  unit === 'metric'
                    ? '<strong>' +
                      Math.round(e.entries[i].dataPoint.x / 100) / 10 +
                      '</strong>km'
                    : '<strong>' +
                      Math.round((e.entries[i].dataPoint.x * kmToMile) / 100) /
                        10 +
                      '</strong>mi';

                content += t('Dashboard.distance') + ' ' + movingDstStr;
                content += '<br/>';
              } else {
                //unit 변환 km to mile
                let movingDstStr =
                  unit === 'metric'
                    ? '<strong>' +
                      Math.round(
                        distanceArray[e.entries[i].dataPoint.y] / 100
                      ) /
                        10 +
                      '</strong>km'
                    : '<strong>' +
                      Math.round(
                        (distanceArray[e.entries[i].dataPoint.y] * kmToMile) /
                          100
                      ) /
                        10 +
                      '</strong>mi';

                content += t('Dashboard.distance') + ' ' + movingDstStr;
                content += '<br/>';
              }
            } else {
              //시간
              if (rideType === 'Ride') {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  secondsToHmsLabel(e.entries[i].dataPoint.y) +
                  '</strong>';
              } else {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  secondsToHmsLabel(e.entries[i].dataPoint.x) +
                  '</strong>';
              }
              content += '<br/>';
            }
          }
          return content;
        }
      };

      distanceChartOptions.toolTip = toolTipAltitude;
    } else {
      //altitude
      let altitudeMin = 0,
        altitudeMax = 50;
      distanceChartOptions.axisY.minimum = Math.round(altitudeMin);
      distanceChartOptions.axisY.maximum = Math.round(altitudeMax);
      distanceChartOptions.axisX.margin = 10;
      streamArray.push(distanceChartOptions);

      let toolTipAltitude = {
        shared: true,
        contentFormatter: function(e) {
          let content = ' ';
          for (let i = 0; i < e.entries.length; i++) {
            if (i == 0) {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                e.entries[i].dataPoint.y +
                '</strong>m';
              content += '<br/>';
            }
          }
          return content;
        }
      };

      distanceChartOptions.toolTip = toolTipAltitude;
    }

    /***************** container Props ***************/

    const containerProps = {
      width: '100%',
      height: '600px',
      margin: '0 0 12px 0'
    };

    //구간합 공식
    function prefixSum(l, r, arr) {
      let sum = 0;
      const p = [0];

      for (let i = 0; i < arr.length; i++) {
        sum += arr[i];
        p.push(sum);
      }

      return p[r] - p[l - 1];
    }

    /**** stream Chart obj ****/
    const streamObj = {
      title: { text: false },
      backgroundColor: theme.palette.chart.background,
      charts: [...streamArray],
      rangeChanged: function(e) {
        //				console.log(streamArray);
        let min = Math.round(e.minimum);
        let max = Math.round(e.maximum);

        let min_index = 0;
        let max_index = 0;

        /* 앞 인덱스 */
        for (let l = 0; l < xAxisArray.length; l++) {
          if (xAxisArray[l] > min) {
            min_index = l;
            break;
          }
        }

        /* 뒤 인덱스 */
        for (let m = xAxisArray.length; m > 0; m--) {
          if (xAxisArray[m] < max) {
            max_index = m;
            break;
          }
        }

        /* 평균값 */
        function streamAvg(l, r, arr) {
          let sum = 0;
          let cnt = 0;

          for (let i = l; i < r; i++) {
            sum += arr[i];
            cnt++;
          }

          return sum / cnt;
        }

        //NP 구하기
        function NormalizedPower(powerStream) {
          let streamSize = powerStream.length;

          let moving_30_arr = null;
          let sum_pow4 = 0;

          for (let i = 0; i < streamSize - 30; i++) {
            let avg30 = 0;

            if (i < streamSize - 30) {
              let sum30 = 0;
              for (let j = i; j < i + 30; j++) {
                sum30 += powerStream[j];
              }

              avg30 = Math.round(sum30 / 30);
            } else {
              let remain = streamSize - i;
              let sum_remain = 0;
              for (let j = i; j < i + remain; j++) {
                sum_remain += powerStream[j];
              }

              avg30 = Math.round(sum_remain / remain);
            }

            sum_pow4 += Math.pow(avg30, 4);
          }

          let avg_pow4 = Math.round(sum_pow4 / streamSize);
          let np = Math.pow(avg_pow4, 1 / 4);

          return Math.round(np, 2);
        }

        //				let intervalCnt = max_index - min_index;
        //				console.log(wattsArray.slice(min_index, max_index));

        //NP 계산시 실외는 ratss 제거

        //				console.log(e.stockChart.charts);

        for (let i = 0; i < e.stockChart.charts.length; i++) {
          let type = e.stockChart.charts[i].options.type;

          if (type === 'velocity') {
            //unit 변환 km to mile
            let velocityIntervalAvgStr =
              unit === 'metric'
                ? Math.round(
                    10 * streamAvg(min_index, max_index, velocityArray) * 3.6
                  ) /
                    10 +
                  ' kph'
                : Math.round(
                    10 *
                      streamAvg(min_index, max_index, velocityArray) *
                      3.6 *
                      kmToMile
                  ) /
                    10 +
                  ' mph';

            e.stockChart.charts[i].subtitles[0].set(
              'text',
              t('Dashboard.velocity') +
                ' ' +
                t('Dashboard.intervalAvg') +
                ' ' +
                velocityIntervalAvgStr
            );
          } else if (type === 'watts') {
            let intervalArray = wattsArray.slice(min_index, max_index);
            let _rp = NormalizedPower(intervalArray);
            let _if = Math.round((100 * _rp) / ftp) / 100;
            let _tss = Math.round((intervalArray.length * _if * _if) / 36);

            e.stockChart.charts[i].subtitles[0].set(
              'text',
              t('Dashboard.watts') +
                ' ' +
                t('Dashboard.intervalAvg') +
                ' ' +
                Math.round(10 * streamAvg(min_index, max_index, wattsArray)) /
                  10 +
                ' w, ' +
                'RP ' +
                _rp +
                ', Int ' +
                _if +
                ', Vol ' +
                _tss +
                '(raTSS)'
            );
          } else if (type === 'cadence') {
            e.stockChart.charts[i].subtitles[0].set(
              'text',
              t('Dashboard.rpm') +
                ' ' +
                t('Dashboard.intervalAvg') +
                ' ' +
                Math.round(streamAvg(min_index, max_index, cadenceArray)) +
                ' rpm'
            );
          } else if (type === 'heartrate') {
            e.stockChart.charts[i].subtitles[0].set(
              'text',
              t('Dashboard.hrm') +
                ' ' +
                t('Dashboard.intervalAvg') +
                ' ' +
                Math.round(streamAvg(min_index, max_index, heartrateArray)) +
                ' bpm'
            );
          } else if (type === 'distance') {
            //unit 변환 km to mile
            let segmentVelocityAvg =
              unit === 'metric'
                ? Math.round(
                    (distanceArray[max_index] - distanceArray[min_index]) / 100
                  ) /
                    10 +
                  ' km, '
                : Math.round(
                    ((distanceArray[max_index] - distanceArray[min_index]) *
                      kmToMile) /
                      100
                  ) /
                    10 +
                  ' mi, ';

            e.stockChart.charts[i].subtitles[0].set(
              'text',
              t('Dashboard.distance') +
                ' ' +
                segmentVelocityAvg +
                t('Dashboard.duration') +
                ' ' +
                secondsToHmsLabel(max_index - min_index) +
                ''
            );
          }
        }

        //				console.log(timeArray[min_index]);
        //				console.log();
        //				console.log(streamAvg(min_index, max_index, velocityArray));
        //				console.log(streamAvg(min_index, max_index, cadenceArray));
        //				console.log(streamAvg(min_index, max_index, heartrateArray));
      },
      navigator: {
        backgroundColor: '#f5f5f5',
        axisX: {
          labelFontColor: '#666666',
          lineColor: '#dddddd',
          labelFormatter: function(e) {
            if (rideType === 'Ride') {
              let navDistance =
                unit === 'metric'
                  ? Math.round(e.value / 100) / 10 + 'km'
                  : Math.round((e.value / 100) * kmToMile) / 10 + 'mi';

              return navDistance;
            } else if (rideType === 'VirtualRide') {
              return secondsToHmsLabel(e.value);
            }
          },
          labelPlacement: 'inside',
          labelAngle: 0
        },
        axisY: {
          labelPlacement: 'inside',
          lineColor: '#dddddd'
        },
        margin: 30,
        height: '80px',
        data: [
          {
            type: 'area',
            color: '#cccccc',
            fillOpacity: 0.4,
            lineThickness: 1,
            dataPoints: distancePoints
          }
        ],
        slider: {
          handleWidth: 16,
          handleHeight: 40,
          handleBorderColor: '#4db6ac',
          handleBorderThickness: 2,
          outlineThickness: 2,
          outlineColor: '#4db6ac'
        }
      },
      rangeSelector: {
        enabled: false
      }
    };

    $('#chartContainer').remove();

    //	console.log(streamDataJson);

    /*
		console.log(streamDataJson.altitude_max)	
		console.log(streamDataJson.average_watts);
		console.log(streamDataJson.distance);
		console.log(streamDataJson.ftp);
		console.log(streamDataJson.weight);
		console.log(streamDataJson.max_watts);

		console.log(streamDataJson.moving_time);
		console.log(streamDataJson.start_date);
		console.log(streamDataJson.total_elevation_gain);
		*/

    const ActivitySummary = props => {
      //	console.log(activityPowerZone);

      const calories = activityPowerZone
        ? activityPowerZone.calorie.calories
        : 0;
      const fatPer = activityPowerZone ? activityPowerZone.calorie.fat_per : 0;
      const carbPer = activityPowerZone
        ? activityPowerZone.calorie.carb_per
        : 0;
      const workoutTime = activityPowerZone ? activityPowerZone.total : 0;
      const trainingType = activityPowerZone ? activityPowerZone.type : 0;

      const movingDist = streamDataJson.distance / 1000;
      const movingTime = streamDataJson.moving_time;

      const avgVelo =
        Math.round(
          (36 * streamDataJson.distance) / streamDataJson.elapsed_time
        ) / 10;
      const avgMoving =
        Math.round(
          (36 * streamDataJson.distance) / streamDataJson.moving_time
        ) / 10;

      const totalEl = streamDataJson.total_elevation_gain;

      return (
        <Box className={classes.streamSummary}>
          <Typography className={classes.summaryTitle}>
            📃 {t('Activity.summary')}
          </Typography>
          <Divider />
          <Grid container spacing={1} className={classes.gridContainer}>
            <Grid container item xs={12}>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.rideType')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {trainingType}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.aerobicRate')}
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.anaerobicRate')}
              </Grid>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid container item xs={12}>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.movingDist')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {movingDist}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.movingTime')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {movingTime}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.workoutTime')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {workoutTime}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.avgVelo')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {avgVelo}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.avgMoving')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {avgMoving}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.maxVelo')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {Math.round(
                    (36 * streamDataJson.distance) / streamDataJson.moving_time
                  ) / 10}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.totalEl')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {totalEl}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.lowEl')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.total_elevation}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.highEl')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.total_elevation}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                VAM
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.total_elevation}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid container item xs={12}>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.avgPower')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.watts}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.maxPower')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.max_watts}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                FTP
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.ftp}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                NP
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.ftp}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                IF
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.ftp}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                TSS
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.ftp}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                kJ
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.ftp}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                Cal
                <Typography variant="inherit" className={classes.summaryText}>
                  {calories}
                </Typography>
              </Grid>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid container item xs={12}>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.avgHr')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.heartrate}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.maxHr')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.heartrate_max}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.minHr')}
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                hr Intensity
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                hr Tss
              </Grid>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid container item xs={12}>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.avgCad')}
                <Typography variant="inherit" className={classes.summaryText}>
                  {streamDataJson.cadence}
                </Typography>
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.maxCad')}
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.avgTorque')}
              </Grid>
            </Grid>
            <Divider style={{ width: '100%' }} />
            <Grid container item xs={12}>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.temperature')}
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.weather')}
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.bikeType')}
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.bikeWeight')}
              </Grid>
              <Grid item xl={4} md={3} sm={4}>
                {t('Activity.bodyWeight')}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      );
    };

    return (
      /*			
			"rideType" : "운동종류", 
			"aerobicRate" : "유산소비율", 
			"anaerobicRate" : "무산소비율", 
			"movingDist" : "이동거리", 
			"totalTime" : "총시간", 
			"movingTime" : "이동시간", 
			"workoutTime" : "운동시간", 
			"avgVelo" : "평균속도", 
			"avgMov" : "평균이동속도", 
			"maxVelo" : "최대속도",

			"totalEl" : "상승고도", 
			"lowEl" : "최저고도", 
			"highEl" : "최대고도", 
			
			"avgPower" : "평균파워", 
			"maxPower" : "최대파워", 

			"avgHr" : "평균심박", 
			"maxHr" : "최대심박", 
			"minHr" : "안정심박", 
			"avgCad" : "평균케이던스", 
			"maxCad" : "최대케이던스", 
			"avgTorque" : "평균토크",
			"temperature" : "온도", 
			"weather" : "날씨", 
			"bikeType" : "자전거종류", 
			"bikeWeight" : "자전거무게", 
			"bodyWeight" : "체중"       
							<ActivitySummary />
*/
      <React.Fragment>
        <CanvasJSStockChart
          containerProps={containerProps}
          options={streamObj}
          onRef={ref => (chart = ref)}
        />
        <Box style={{ display: 'none' }}>
          <ButtonGroup
            color="primary"
            aria-label="outlined primary button group">
            <Button>Z1</Button>
            <Button onClick={() => addStripline()} style={{ color: '#ffbb00' }}>
              Z2
            </Button>
            <Button>Z3</Button>
            <Button>Z4</Button>
            <Button>Z5</Button>
            <Button>Z6</Button>
            <Button>Z7</Button>
          </ButtonGroup>
          <Typography>
            하이라이트 - awc가 가장 많이 소진되어 있는 구간
          </Typography>
          <Typography>구간정보 - 거리/시간/획득고도</Typography>
          <Typography>평균속도, 평균파워, 평균심박, 평균케이던스</Typography>
          <Typography>
            파워 고강도 %, 중강도 %, 저강도 %, 존1,존2,존3,존4,존5,존6,존7
          </Typography>
          <Typography>
            심박 고강도 %, 중강도 %, 저강도 %, 존1,존2,존3,존4,존5,존6,존7
          </Typography>
        </Box>
      </React.Fragment>
    );
  }

  function StreamDialog(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [stravaDescriptionDialog, setStravaDescriptionDialog] = useState(
      false
    );

    const handlePopoverOpen = event => {
      setAnchorEl(event.currentTarget);
      navigator.clipboard.writeText(stravaDescription);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    function handleStravaDescriptionDialogOpen() {
      setStravaDescriptionDialog(true);
    }

    function handleStravaDescriptionDialogClose() {
      setStravaDescriptionDialog(false);
    }

    const popoverOpen = Boolean(anchorEl);

    return (
      <div className={classes.streamWrap}>
        <Dialog
          open={stravaDescriptionDialog}
          onClose={handleStravaDescriptionDialogClose}>
          <DialogTitle>{t('Dashboard.stravaDesc')}</DialogTitle>
          <Divider />
          <DialogContent className={classes.stravaDesc}>
            <div>
              <pre>{stravaDescription}</pre>
            </div>
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <IconButton onClick={handlePopoverOpen} size="large">
              <FileCopyOutlinedIcon />
            </IconButton>
          </DialogActions>

          {/*	스트라바 디스크립션 팝오버	*/}
          <Popover
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <Typography style={{ margin: '12px' }}>
              {t('Dashboard.stravaDescCopy')}
            </Typography>
          </Popover>
        </Dialog>
      </div>
    );
  }

  const isWidthLargeDown = useMediaQuery(theme.breakpoints.down('lg'));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const popoverOpen = Boolean(anchorEl);

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
    navigator.clipboard.writeText(stravaDescription);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const [stravaDescriptionDialog, setStravaDescriptionDialog] = useState(false);

  function handleStravaDescriptionDialogOpen() {
    setStravaDescriptionDialog(true);
  }

  function handleStravaDescriptionDialogClose() {
    setStravaDescriptionDialog(false);
  }

  const animalType = powerzoneData.powerzone_activities[activityId]?.type;

  function getAnimalType(type) {
    if (type === 'SPT') return '🦁';
    else if (type === 'HIIT') return '🐆';
    else if (type === 'VO2MAX') return '🐺';
    else if (type === 'RPT') return '🐴';
    else if (type === 'SST') return '🐗';
    else if (type === 'TEMPO') return '🐷';
    else if (type === 'BASE') return '🐮';
    else if (type === 'HVLI') return '🐫';
    else if (type === 'POL') return '🐘';
    else if (type === 'PRMD') return '🦌';
    else if (type === 'BAL') return '🐶';
    else if (type === 'RECV') return '🐢';

    return '🦆';
  }

  const kmPerHour =
    activity.distance / (activity.moving_time * (1 / 60) * (1 / 60));
  const activitySummary = [
    {
      key: t('Activity.summary'),
      value: `${getAnimalType(animalType)} ${animalType ? animalType : ''}`
    },
    {
      key: t('Activity.movingDist'),
      value:
        unit === 'metric'
          ? `${activity.distance}km`
          : `${kilometerToMile(activity.distance).toFixed(1)}mi`
    },
    {
      key: t('Activity.movingTime'),
      value: secondsToHms(activity.moving_time)
    },
    {
      key: t('Activity.elevation'),
      value:
        unit === 'metric'
          ? `${activity.total_elevation_gain}m`
          : `${meterToFeet(activity.total_elevation_gain).toFixed(1)}ft`
    },
    { key: t('Activity.avgHr'), value: `${activity.average_heartrate}bpm` },
    {
      key: t('Activity.avgVelo'),
      value:
        unit === 'metric'
          ? `${kmPerHour.toFixed(2)}kph`
          : `${kiloPerHourToMilePerHour(kmPerHour).toFixed(1)}mph`
    },
    { key: t('Activity.avgCad'), value: `${activity.average_cadence}rpm` },
    {
      key: t('Activity.avgPower'),
      value: wkg
        ? `${wattsToWPerKg(activity.average_watts, weight)}w/kg`
        : `${activity.average_watts}w`
    },
    {
      key: 'RP',
      value: wkg
        ? `${wattsToWPerKg(activity.np, weight)}w/kg`
        : `${activity.np}w`
    },
    { key: 'IF', value: activity.intensity },
    { key: 'TSS', value: activity.tss }
  ];

  if (activityId > 0) {
    return (
      <React.Fragment>
        <StreamDialog />
        {/* 요약 정보 */}
        <Box
          sx={{
            display: 'flex',
            overflowX: 'auto',
            whiteSpace: 'nowrap',
            pb: 1
          }}>
          {activitySummary.map(({ key, value }) => (
            <Box
              key={key}
              sx={{
                flex: '0 0 auto',
                px: 1.5, // padding만으로 간격 조절
                textAlign: 'center'
              }}>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{ fontSize: '0.75rem' }}>
                {key}
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: '0.9rem', fontWeight: 500 }}>
                {value}
              </Typography>
            </Box>
          ))}

          {/* 버튼도 같이 줄에 표시하고 싶다면 */}
          <Box
            sx={{
              flex: '0 0 auto',
              px: 1.5,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={handleStravaDescriptionDialogOpen}
              sx={{ whiteSpace: 'nowrap' }}>
              {t('Activity.analysisSummary')}
            </Button>
          </Box>
        </Box>
        <Divider className={classes.chartDivider} />
        {/* 차트 */}
        <ChartContainer
          options={ChartOptions}
          altitudeMax={altitudeMax}
          streamData={streamData}
          movingDistance={movingDistance}
          movingTime={movingTime}
        />

        {/* strava description */}
        <Dialog
          open={stravaDescriptionDialog}
          onClose={handleStravaDescriptionDialogClose}>
          <DialogTitle>{t('Dashboard.stravaDesc')}</DialogTitle>
          <Divider />
          <DialogContent className={classes.stravaDesc}>
            <div>
              <pre>{stravaDescription}</pre>
            </div>
          </DialogContent>
          <DialogActions className={classes.stravaDescButtonContainer}>
            <IconButton onClick={handlePopoverOpen} size="large">
              <FileCopyOutlinedIcon />
            </IconButton>
          </DialogActions>
          <Popover
            open={popoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}>
            <Typography className={classes.copyText}>
              {t('Dashboard.stravaDescCopy')}
            </Typography>
          </Popover>
        </Dialog>
      </React.Fragment>
    );
  } else {
    return <React.Fragment></React.Fragment>;
  }
};

ActivityStream.propTypes = {
  className: PropTypes.string
};

export default withRouter(ActivityStream);
