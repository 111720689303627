import { makeStyles, useTheme, withStyles } from '@mui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Box,
  Button,
  Divider,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from '@mui/material';

import StravaIcon from '@iconify/icons-mdi/strava';
import { Icon } from '@iconify/react';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import i18n from 'i18next';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

//Chart 추가
import CanvasJSReact from '../../../../utils/canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  // root: {
  //   flexGrow: 1,
  //   width: '100%'
  // },
  tableContainer: {
    height: 300,
    fontSize: '13px',
    margin: '12px 0px 12px 0px',
    padding: '4px',
    overflow: 'auto',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px',
      fontSize: '12px',
      height: 240
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 8px',
      fontSize: '12px',
      height: 200
    }
  },
  tableHeadCell: {
    padding: '6px 8px',
    color: '#4db6ac',
    fontSize: '13px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 4px',
      fontSize: '12px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '2px 4px',
      fontSize: '12px'
    }
  },
  tableCell: {
    fontSize: '13px',
    padding: '6px 8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 4px',
      fontSize: '12px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '2px 4px',
      fontSize: '12px'
    }
  },
  stravaButton: {
    minWidth: 0,
    margin: '2px 2px 2px 0'
  },
  stravaIcon: {
    color: '#FC4C02',
    fontSize: '16px',
    opacity: '0.7',
    marginTop: '-2px'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px'
  },
  gridContainer: {
    color: '#999999',
    fontWeight: 300,
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    }
  },
  descTitle: {
    marginBottom: '8px',
    fontWeight: '600'
  },
  ftpDatumPower: {
    textAlign: 'right',
    fontWeight: '600'
  },
  stravaLink: {
    fontSize: 12
    //    textDecoration: "underline",
    //    color: "#4DB6AC",
  },
  switchWarp: {
    color: theme.palette.text.forth,
    fontSize: 12,
    margin: '10px 0 0 0',
    justifyContent: 'center',
    alignItems: 'baseline'
  },
  activityName: {
    [theme.breakpoints.down('xl')]: {
      maxWidth: 250
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: 150
    }
  },
  paginationSpacer: {
    flex: '1 1 0%',
    padding: '0 8px 8px 8px'
  }
}));

// 토글 스위치 커스터마이징
const GraphTypeSwitch = withStyles(theme => ({
  switchBase: {
    color: '#4db6ac',
    '&$checked': {
      color: '#f06292'
    },
    '&$checked + $track': {
      backgroundColor: '#ffabc7'
    }
  },
  checked: {},
  track: {
    backgroundColor: '#a0d8d3'
  }
}))(Switch);

const PowerProfile = props => {
  const { activityData, pdcData, ftpArray, wkg, weight, wkgSelect } = props;
  const classes = useStyles();
  const theme = useTheme();

  //다국어적용
  const { t } = useTranslation(['page']);
  const language = i18n.language;

  const ftp = ftpArray.ftp;
  const tte = ftpArray.tte;

  const bestIndex = ftpArray.best_index;

  const [powerCurveOptions, setPowerCurveOptions] = React.useState(null);

  // page params
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const mmp_all = pdcData['best_data']['power_all'];
  const stereo_all = pdcData['best_data']['stereo_all'];
  const outdoor_all = pdcData['best_data']['outdoor_all'];
  const indoor_all = pdcData['best_data']['indoor_all'];
  const peak_activity_ids = pdcData['best_data']['peak_activity_ids'];

  const mmp_arr = [];
  const stereo_arr = [];
  const outdoor_arr = [];
  const indoor_arr = [];
  const activity_arr = [];
  const powerCurveArray = [];

  // 그래프에 표시할 액티비티 id
  const [graphActivityData, setGraphActivityData] = React.useState(null);
  // 그래프 타입 스위치
  const [graphTypeValue, setGraphTypeValue] = React.useState('all');

  let key = 0;

  const containerCurve = {
    //    maxWidth: '1200px',
    minWidth: '300px',
    height: window.innerHeight > 800 ? '360px' : '240px',
    margin: '0 auto 10px auto'
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  function TablePaginationActions(props) {
    const classes = useStyles();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = event => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = event => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = event => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          size="large">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
          size="large">
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  //평균 구하기
  function averageArray(arr) {
    const result = arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);

    return result / arr.length;
  }

  useEffect(() => {
    const stripLineX = [];
    const stripLineY = [];
    let graphActivityDataName = '';

    function onItemMouseOver(e) {
      e.chart.data[0].set('visible', true);
      e.chart.data[1].set('visible', false);
      if (graphTypeValue === 'all') {
        e.chart.data[2].set('visible', false);
        e.chart.data[3].set('visible', false);
      }

      e.chart.data[e.dataSeriesIndex].set('visible', true);
    }

    function onItemMouseOut(e) {
      e.chart.data[0].set('visible', true);
      e.chart.data[1].set('visible', true);
      if (graphTypeValue === 'all') {
        e.chart.data[2].set('visible', false);
        e.chart.data[3].set('visible', false);
      }
    }

    // 피크, 필드, 실내 파워 데이터
    _.each(mmp_all, function(power, duration) {
      if (
        duration > 0 &&
        duration !== '250' &&
        duration !== '1350' &&
        duration !== '470'
      ) {
        let mmp = wkgSelect
          ? Math.round((10 * mmp_all[duration]) / weight) / 10
          : Math.round(mmp_all[duration]);
        let outdoor = wkgSelect
          ? Math.round((10 * outdoor_all[duration]) / weight) / 10
          : Math.round(outdoor_all[duration]);
        let indoor = wkgSelect
          ? Math.round((10 * indoor_all[duration]) / weight) / 10
          : Math.round(indoor_all[duration]);

        mmp_arr.push({
          x: Math.round(duration),
          y: mmp,
          value: mmp,
          label: t('Dashboard.peak')
        });

        if (outdoor_all !== null) {
          outdoor_arr.push({
            x: Math.round(duration),
            y: outdoor,
            value: outdoor,
            label: t('Dashboard.outdoor')
          });
        }

        if (indoor_all !== null) {
          indoor_arr.push({
            x: Math.round(duration),
            y: indoor,
            value: indoor,
            label: t('Dashboard.indoor')
          });
        }

        // 액티비티 파워 데이터
        if (graphTypeValue === 'activity' && !!graphActivityData) {
          // console.log(graphActivityData)
          // console.log(duration +":"+ graphActivityData[`watts_${duration}`])
          let activity = 0;
          if (duration == 1) {
            activity = wkgSelect
              ? Math.round((10 * graphActivityData['max_watts']) / weight) / 10
              : Math.round(graphActivityData['max_watts']);
          } else {
            activity = wkgSelect
              ? Math.round(
                  (10 * graphActivityData[`watts_${duration}`]) / weight
                ) / 10
              : Math.round(graphActivityData[`watts_${duration}`]);
          }
          graphActivityDataName = graphActivityData.activity_name;
          activity_arr.push({
            x: Math.round(duration),
            y: activity,
            value: activity,
            label: graphActivityDataName
          });
        }
      }
    });

    // 기대 파워 데이터 계산
    let tte_strip = false;
    _.each(stereo_all, function(power, duration) {
      if (
        duration > 0 &&
        duration !== '250' &&
        duration !== '1350' &&
        duration !== '470'
      ) {
        let label = t('Dashboard.expect');
        let value = wkgSelect ? Math.round((10 * power) / weight) / 10 : power;
        let stereo = wkgSelect
          ? Math.round((10 * stereo_all[duration]) / weight) / 10
          : Math.round(stereo_all[duration]);
        let a_ftp = wkgSelect ? Math.round((10 * ftp) / weight) / 10 : ftp;

        if (duration > tte * 60 && tte_strip === false) {
          stereo_arr.push({
            x: tte * 60,
            y: a_ftp,
            value: a_ftp,
            label: 'FTP'
          });
          tte_strip = true;
        }

        stereo_arr.push({
          x: Math.round(duration),
          y: stereo,
          value: value,
          label: label
        });
      }
    });

    // 데이터 추가
    let newDataSet = [];
    if (graphTypeValue === 'all') {
      // 기대파워
      newDataSet.push({
        name: t('Dashboard.expect'),
        type: 'area',
        showInLegend: true,
        legendMarkerType: 'circle',
        connectNullData: true,
        color: '#4db6ac',
        indexLabelFontColor: '#ff7e75',
        indexLabelBackgroundColor: '#CCCCCC',
        lineColor: '#4db6ac',
        markerSize: 0,
        lineThickness: 2,
        fillOpacity: '0.2',
        dataPoints: stereo_arr
      });
      // 피크파워
      newDataSet.push({
        name: t('Dashboard.peak'),
        type: 'area',
        showInLegend: true,
        legendMarkerType: 'circle',
        connectNullData: true,
        color: '#f06292',
        indexLabelFontColor: '#f06292',
        indexLabelBackgroundColor: '#CCCCCC',
        lineColor: '#f06292',
        lineThickness: 2,
        fillOpacity: '0.7',
        markerSize: 6,
        markerColor: '#f06292',
        markerBorderColor: '#ffffff',
        markerBorderThickness: 1,
        dataPoints: mmp_arr
      });
      // 필드파워
      newDataSet.push({
        name: t('Dashboard.outdoor'),
        type: 'area',
        visible: false,
        showInLegend: true,
        legendMarkerType: 'circle',
        connectNullData: true,
        color: '#76b5e8',
        indexLabelFontColor: '#76b5e8',
        indexLabelBackgroundColor: '#CCCCCC',
        lineColor: '#76b5e8',
        lineThickness: 2,
        fillOpacity: '0.6',
        markerSize: 0,
        markerColor: '#76b5e8',
        markerBorderColor: '#ffffff',
        markerBorderThickness: 1,
        dataPoints: outdoor_arr
      });
      // 실내파워
      newDataSet.push({
        name: t('Dashboard.indoor'),
        type: 'area',
        visible: false,
        showInLegend: true,
        legendMarkerType: 'circle',
        connectNullData: true,
        color: '#ffa75c',
        indexLabelFontColor: '#ffa75c',
        indexLabelBackgroundColor: '#CCCCCC',
        lineColor: '#ffa75c',
        lineThickness: 2,
        fillOpacity: '0.6',
        markerSize: 0,
        markerColor: '#ffa75c',
        markerBorderColor: '#ffffff',
        markerBorderThickness: 1,
        dataPoints: indoor_arr
      });
    } else {
      // 피크파워
      newDataSet.push({
        name: t('Dashboard.peak'),
        type: 'area',
        showInLegend: true,
        legendMarkerType: 'circle',
        connectNullData: true,
        color: '#f06292',
        indexLabelFontColor: '#ff7e75',
        indexLabelBackgroundColor: '#CCCCCC',
        lineColor: '#f06292',
        markerSize: 0,
        lineThickness: 2,
        fillOpacity: '0.2',
        dataPoints: mmp_arr
      });
      // 활동
      newDataSet.push({
        name: graphActivityDataName,
        type: 'area',
        showInLegend: true,
        legendMarkerType: 'circle',
        connectNullData: true,
        color: '#4db6ac',
        indexLabelFontColor: '#4db6ac',
        indexLabelBackgroundColor: '#CCCCCC',
        lineColor: '#4db6ac',
        lineThickness: 2,
        fillOpacity: '0.7',
        markerSize: 6,
        markerColor: '#4db6ac',
        markerBorderColor: '#ffffff',
        markerBorderThickness: 1,
        dataPoints: activity_arr
      });
    }

    let newPowerCurveOptions = {
      backgroundColor: theme.palette.chart.background,
      animationEnabled: false,
      theme: 'light2',
      title: false,
      zoomEnabled: false,
      dataPointMaxWidth: 16,
      toolTip: {
        shared: true,
        contentFormatter: function(e) {
          let content = secondsToHms(e.entries[0].dataPoint.x) + '<br />';
          if (e.entries.length > 0)
            for (let i = 0; i < e.entries.length; i++) {
              content +=
                "<div style='color:" +
                e.entries[i].dataSeries.color +
                "'>" +
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                e.entries[i].dataPoint.value +
                '</strong>' +
                '</div>';
            }
          return content;
        }
      },
      axisX: {
        stripLines: [
          {
            showOnTop: true,
            value: tte * 60,
            label: 'TTE ' + tte + t('Common.min'),
            labelPlacement: 'inside',
            labelFontSize: 12,
            labelAlign: 'far',
            labelFontColor: '#76b5e8',
            thickness: 1,
            color: '#76b5e8',
            lineDashType: 'dash'
          }
        ],
        maximum: 3600,
        logarithmic: true,
        logarithmBase: 2,
        dataPointMaxWidth: 20,
        labelAutoFit: false,
        suffix: '',
        lineThickness: 1,
        lineColor: '#aaaaaa',
        gridThickness: 1,
        gridColor: theme.palette.chart.secondary.axis.grid,
        labelFontSize: 12,
        labelFontColor: '#aaaaaa',
        labelFormatter: function(e) {
          return secondsToHms(e.value);
        },
        labelAngle: 0,
        crosshair: {
          enabled: true,
          snapToDataPoint: true,
          lineColor: '#999999',
          labelFormatter: function(e) {
            return secondsToHms(e.value);
          }
        }
      },
      axisY: {
        stripLines: [
          {
            showOnTop: true,
            value: wkgSelect ? Math.round((10 * ftp) / weight) / 10 : ftp,
            label: wkgSelect
              ? 'FTP ' + Math.round((10 * ftp) / weight) / 10 + ' w/kg'
              : 'FTP ' + ftp + ' W',
            labelPlacement: 'inside',
            labelFontSize: 12,
            labelAlign: 'far',
            labelFontColor: theme.palette.chart.text.secondary,
            thickness: 1,
            color: theme.palette.chart.text.secondary,
            lineDashType: 'dash'
          }
        ],
        margin: 10,
        labelFontSize: 12,
        labelFontColor: '#aaaaaa',
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        suffix: wkgSelect ? ' w/kg' : ' W',
        gridThickness: 1,
        gridColor: theme.palette.chart.secondary.axis.grid,
        minimum: 0
      },
      legend: {
        horizontalAlign: 'center',
        verticalAlign: 'bottom',
        fontSize: 12,
        fontColor: theme.palette.chart.text.primary,
        itemmouseover: onItemMouseOver,
        itemmouseout: onItemMouseOut
      },
      data: newDataSet
    };

    setPowerCurveOptions(newPowerCurveOptions);
    stripLineY.push({
      type: 'dash',
      value: 0,
      color: '#000000',
      lineDashType: 'dash',
      lineThickness: 2,
      lineColor: '#333333'
    });
  }, [wkgSelect, graphActivityData, graphTypeValue]);

  let timestamp = new Date().getTime();

  _.each(peak_activity_ids, function(peak_activity_id, index) {
    _.each(activityData, function(activity, order) {
      let mmp = Math.round(mmp_all[index]);

      if (Number(activity.activity_id) === peak_activity_id && mmp > 0) {
        let duration_power = '';
        let activity_name = activity.activity_name.substr(0, 32);
        let activity_url =
          'https://www.strava.com/activities/' + activity.activity_id;
        let activity_id = activity.activity_id;
        let start_date = activity.start_date.substr(0, 8);
        let remain_date =
          85 -
          Math.round((timestamp / 1000 - activity.timestamp) / (3600 * 24));
        let allout = 'N';

        if (Number(index) === 250) {
          duration_power = 'RAMP TEST';
        } else if (Number(index) === 470) {
          duration_power = 'MAP TEST';
        } else if (Number(index) === 1350) {
          duration_power = 'FTP TEST';
        } else {
          duration_power = secondsToHms(index) + ' ' + t('Common.power');
        }

        let row = {
          index: index,
          peak_power: duration_power,
          power: mmp,
          activity_name: activity_name,
          activity_url: activity_url,
          activity_id: activity_id,
          start_date: start_date,
          remain_date: remain_date,
          ftp_datum: Number(bestIndex) == Number(index) ? '📌' : '',
          all_out: allout
        };

        if (
          Number(index) === 250 ||
          Number(index) === 1350 ||
          Number(index) === 470
        )
          powerCurveArray.unshift(row);
        else powerCurveArray.push(row);
      }
    });
  });

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + t('Common.hour') + ' ' : '';
      let md = m > 0 ? m + t('Common.min') : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + t('Common.min') + ' ' : '';
      let sd = s > 0 ? s + t('Common.sec') : '';

      return md + sd;
    }
  }

  function secondsToHmsLabel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '';
    } else if (h > 0) {
      let hd = h > 0 ? h + ':' : '00';
      let md = m > 0 ? m : '00';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + ':' : '';
      let sd = s > 0 ? s : '00';

      return md + sd;
    }
  }

  const handleGraphTypeValue = event => {
    let checked = event.target.checked;
    if (checked) {
      setGraphTypeValue('activity');
    } else {
      setGraphTypeValue('all');
    }
  };

  function PowerTable(props) {
    const powerCurveArray = props.powerCurveArray;
    const wkgSelect = props.wkgSelect;
    let ftp_datum = ''; // FTP 기준점 설명

    return (
      <React.Fragment>
        <TableContainer
          component={Paper}
          id={'activityTable'}
          className={classes.tableContainer}>
          <Table className={classes.table} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell} align="center">
                  {t('Powerprofile.peak')}
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  {t('Powerprofile.power')}
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  {t('Powerprofile.activity')}
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  {t('Powerprofile.date')}
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="center">
                  {t('Powerprofile.remainDate')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ maxHeight: '400px' }}>
              {powerCurveArray.map(activity => {
                key++;

                let table_power = wkgSelect
                  ? Math.round((10 * activity.power) / weight) / 10 + ' w/kg'
                  : activity.power + ' W';

                if (!!activity.ftp_datum) {
                  ftp_datum = activity.peak_power;
                }

                return (
                  <TableRow
                    key={key}
                    id={activity.activity_id}
                    style={{
                      backgroundColor: !!activity.ftp_datum
                        ? '#F0629220'
                        : theme.palette.background.paper
                    }}>
                    <TableCell className={classes.tableCell} align="center">
                      {activity.peak_power}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {table_power}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row">
                      <Button
                        color="secondary"
                        size="small"
                        variant="text"
                        target="_blank"
                        href={activity.activity_url}
                        className={classes.stravaButton}>
                        <Icon
                          icon={StravaIcon}
                          className={classes.stravaIcon}
                        />
                      </Button>
                      <Button
                        color="secondary"
                        size="small"
                        variant="text"
                        target="_blank"
                        href={activity.activity_url}
                        className={classes.stravaButton}>
                        <Typography
                          className={classes.stravaLink}
                          component="span">
                          {activity.activity_name}
                        </Typography>
                      </Button>
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {activity.start_date}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="center">
                      {activity.remain_date} {t('Common.daysLeft')}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Box style={{ padding: '12px 0px' }}>
          <Typography
            className={classes.ftpDatumPower}
            component="div">{`📌 ${t(
            'Powerprofile.ftpDatumPower'
          )}: ${ftp_datum}`}</Typography>
        </Box>
      </React.Fragment>
    );
  }

  function ActivityTable(props) {
    const wkgSelect = props.wkgSelect;
    const activityData = props.activityData;

    return (
      <Box>
        <TableContainer
          component={Paper}
          id={'activityTable'}
          className={classes.tableContainer}
          style={{ height: 'auto' }}>
          <Table className={classes.table} aria-label="table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeadCell} align="center">
                  {t('Powerprofile.activity')}
                </TableCell>
                <TableCell className={classes.tableHeadCell} align="left">
                  {t('Powerprofile.avgPower')}
                </TableCell>
                <Hidden lgDown>
                  <TableCell className={classes.tableHeadCell} align="left">
                    {t('Powerprofile.distance')}
                  </TableCell>
                  <TableCell className={classes.tableHeadCell} align="left">
                    {t('Powerprofile.duration')}
                  </TableCell>
                  <TableCell className={classes.tableHeadCell} align="left">
                    {t('Powerprofile.score3')}
                  </TableCell>
                </Hidden>
                <TableCell className={classes.tableHeadCell} align="center">
                  {t('Powerprofile.date')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {activityData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, i) => {
                  let watts_str = item.average_watts + 'W ';
                  if (wkgSelect)
                    watts_str =
                      Math.round((item.average_watts / item.weight) * 10) / 10 +
                      ' w/kg ';

                  if (item.max_watts > 0 && item.exclude == 0) {
                    watts_str += '⚡';
                  } else if (item.max_watts > 0 && item.exclude == 1) {
                    watts_str += '❔';
                  }

                  let distance = item.distance + 'km';
                  let moving_time = secondsToHms(item.moving_time);
                  let tss = item.tss == 0 ? 1 : item.tss;
                  let intensity = item.intensity;
                  let np = item.np;
                  let tss_text =
                    '📊 ' +
                    t('Dashboard.np') +
                    ' ' +
                    np +
                    ', ' +
                    t('Dashboard.if') +
                    ' ' +
                    intensity +
                    ', ' +
                    t('Dashboard.tss') +
                    ' ' +
                    tss;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={item.activity_id}
                      selected={
                        item.activity_id === graphActivityData?.activity_id
                      }
                      classes={{ selected: classes.selected }}
                      className={classes.tableRow}
                      onClick={() => {
                        setGraphActivityData(item);
                      }}>
                      <TableCell
                        className={classes.tableCell}
                        component="th"
                        scope="row"
                        align="left">
                        <Button
                          color="secondary"
                          size="small"
                          variant="text"
                          target="_blank"
                          href={item.activity_url}
                          className={classes.stravaButton}>
                          <Icon
                            icon={StravaIcon}
                            className={classes.stravaIcon}
                          />
                        </Button>
                        <Typography
                          className={clsx(
                            classes.tableCell,
                            classes.activityName
                          )}
                          component="span">
                          {item.activity_name}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.tableCell} align="left">
                        {watts_str}
                      </TableCell>
                      <Hidden lgDown>
                        <TableCell className={classes.tableCell} align="left">
                          {distance}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {moving_time}
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {tss_text}
                        </TableCell>
                      </Hidden>
                      <TableCell className={classes.tableCell} align="center">
                        {item.start_date}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          classes={{ spacer: classes.paginationSpacer }}
          rowsPerPageOptions={[]}
          component="div"
          count={activityData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Box>
    );
  }

  return (
    <React.Fragment>
      <Box className={classes.tabPanel}>
        <CanvasJSChart
          containerProps={containerCurve}
          options={powerCurveOptions}
        />

        <Divider />
        <Grid component="label" container className={classes.switchWarp}>
          <Grid item>{t('Common.all')}</Grid>
          <Grid item>
            <GraphTypeSwitch
              checked={graphTypeValue === 'activity'}
              onChange={handleGraphTypeValue}
              name="graphType"
            />
          </Grid>
          <Grid item>{t('Common.byActivity')}</Grid>
        </Grid>

        {graphTypeValue === 'all' ? (
          <PowerTable powerCurveArray={powerCurveArray} wkgSelect={wkgSelect} />
        ) : (
          <ActivityTable
            powerCurveArray={powerCurveArray}
            wkgSelect={wkgSelect}
            activityData={activityData}
          />
        )}

        <Divider />

        <Box style={{ padding: '12px 0px' }}>
          <Typography className={classes.descTitle} component="div">
            {t('Powerprofile.PowerProfileIs')}
          </Typography>
          <Typography className={classes.desc} component="div">
            {t('Powerprofile.PowerProfileIsDesc')}
          </Typography>
        </Box>
        <Box style={{ padding: '12px 0px' }}>
          <Typography className={classes.descTitle} component="div">
            {t('Powerprofile.UtilizeExpectPower')}
          </Typography>
          <Typography className={classes.desc} component="div">
            {t('Powerprofile.UtilizeExpectPowerDesc')}
          </Typography>
        </Box>

        <Divider />
      </Box>
    </React.Fragment>
  );
};

PowerProfile.propTypes = {
  history: PropTypes.object
};

export default withRouter(PowerProfile);
