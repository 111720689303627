import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import validate from 'validate.js';
import { makeStyles, styled } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  TextField,
  Grid,
  MenuItem,
  Typography,
  Checkbox,
  InputAdornment,
  Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  FormLabel,
  FormControl,
  Avatar
} from '@mui/material';

import _, { set } from 'lodash';

import itemList from '../../../../utils/SettingOptionItems.js';
import axios from 'axios';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

// icon
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  selectBox: {
    width: 'calc(100% - 8px)',
    marginBottom: '8px'
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    marginBottom: '16px'
  },
  cardContentBox: {
    margin: '0'
  },
  cardContentBoxHidden: {
    display: 'none',
    margin: '12px 0 4px 0'
  },
  rowReverse: {
    marginTop: '-8px',
    paddingBottom: '10px',
    flexDirection: 'row-reverse'
  },
  subTitle: {
    fontSize: '16px',
    margin: '16px 0 0 16px'
  },
  avatarWarp: {
    gap: 10,
    display: 'flex',
    minHeight: 'fit-content',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20
  },
  avatar: {
    width: 60,
    height: 60,
    border: 'none',
    fontSize: '36px',
    lineHeight: '40px',
    textAlign: 'center'
  },
  nameBox: {
    display: 'flex',
    gap: 5,
    alignItems: 'center'
  },
  name: {
    fontSize: '24px',
    lineHeight: '36px',
    textAlign: 'center',
    marginLeft: '8px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '16px'
    }
  },
  editIcon: {
    fontSize: '20px',
    cursor: 'pointer',
    marginLeft: '8px'
  },
  saveButton: {
    height: 36,
    marginLeft: '8px'
  },
  nameInput: {
    '& > div > input': {
      padding: '10.5px 14px'
    },
    '& > label': {
      transform: 'translate(14px, 13px) scale(1)'
    },
    '& > p': {
      position: 'absolute',
      bottom: -15,
      color: '#f44336'
    }
  }
}));

const ProfileOptions = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { auth, extraInfo, handleSnackOpen, updateOptions } = props;
  const classes = useStyles();
  const userInfo = auth.getUserInfo();

  // const [updateAvail, setUpdateAvail] = useState(Boolean(extraInfo.updateAvail));
  const [nationId, setNationId] = useState(extraInfo.nation);

  const [isRiderAddinfoChange, setIsRiderAddinfoChange] = useState(false);
  const [isRiderSettingChange, setIsRiderSettingChange] = useState(false);
  const [isEditName, setIsEditName] = useState(false);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const schema = {
    nickname: {
      presence: {
        allowEmpty: false,
        message: '^' + t('SignUp.nickname') + ' is required'
      },
      length: {
        maximum: 32
      }
    }
  };

  const [RiderAddinfo, setRiderAddinfo] = useState({
    height: extraInfo.height,
    weight: extraInfo.weight,
    birth: extraInfo.birth >= 1920 ? extraInfo.birth : 1990,
    ridingYear: extraInfo.riding_year
  });

  const [RiderSetting, setRiderSetting] = useState({
    nation: extraInfo.nation,
    language: extraInfo.language,
    timezone: extraInfo.timezone,
    unit: extraInfo.unit
  });

  //Nation 함수
  const NationRange = nation_id => {
    let range = [];

    _.each(itemList.countryList, function(item, id) {
      if (nation_id === id)
        range.push(
          <MenuItem key={id} value={id} selected={true}>
            {item}
          </MenuItem>
        );
      else
        range.push(
          <MenuItem key={id} value={id}>
            {item}
          </MenuItem>
        );
    });

    return range;
  };

  //Range menuitem 함수
  const LanguageRange = language_id => {
    let range = [];

    _.each(itemList.languageList, function(item, id) {
      if (language_id === id)
        range.push(
          <MenuItem key={id} value={id} selected={true}>
            {item}
          </MenuItem>
        );
      else
        range.push(
          <MenuItem key={id} value={id}>
            {item}
          </MenuItem>
        );
    });

    return range;
  };

  const TimezoneRange = timezone_str => {
    let range = [];

    _.each(itemList.timezoneList, function(item, id) {
      if (timezone_str === item)
        range.push(
          <MenuItem key={id} value={item} selected={true}>
            {item}
          </MenuItem>
        );
      else
        range.push(
          <MenuItem key={id} value={item}>
            {item}
          </MenuItem>
        );
    });

    return range;
  };

  const UnitRange = unit_str => {
    let range = [];

    range.push(
      <MenuItem
        key={0}
        value={'imperial'}
        selected={unit_str === 'imperial' ? true : false}>
        Imperial
      </MenuItem>
    );
    range.push(
      <MenuItem
        key={1}
        value={'metric'}
        selected={unit_str !== 'imperial' ? true : false}>
        Metric
      </MenuItem>
    );

    return range;
  };

  const ridingYearRange = () => {
    let range = [];
    let d = new Date();
    let this_year = d.getFullYear();

    for (var i = 1920; i <= this_year; i++) {
      range.push(
        <MenuItem key={i} value={i}>
          {i}
          {t('Procedure.year')}
        </MenuItem>
      );
    }
    return range;
  };

  const heightRange = height => {
    let range = [];

    for (var i = 140; i <= 220; i++) {
      range.push(
        <MenuItem key={i} value={i} selected={height === i ? true : false}>
          {i}cm
        </MenuItem>
      );
    }
    return range;
  };

  const weightRange = weight => {
    let range = [];

    for (let i = weight - 5; i <= weight + 5; i++) {
      range.push(
        <MenuItem key={i} value={i} selected={weight === i ? true : false}>
          {i}kg
        </MenuItem>
      );
    }
    return range;
  };

  const birthRange = birth_year => {
    let range = [];
    let d = new Date();
    let this_year = d.getFullYear();
    let birth_id = birth_year > 1920 ? birth_year : this_year - birth_year;

    for (let i = 1920; i <= this_year - 7; i++) {
      range.push(
        <MenuItem key={i} value={i} selected={birth_id === i ? true : false}>
          {i}
          {t('Procedure.year')}
        </MenuItem>
      );
    }
    return range;
  };

  // const handleChangeUser = props => event => {
  //   setUserSetting({ ...UserSetting, [props]: event.target.value });
  // }

  const handleChangeAddinfo = props => event => {
    setRiderAddinfo({ ...RiderAddinfo, [props]: event.target.value });
    setIsRiderAddinfoChange(true);
  };

  const handleEditName = isEdit => {
    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        ['nickname']: userInfo.display_name
      },
      touched: {
        ...formState.touched,
        ['nickname']: true
      }
    }));
    setIsEditName(isEdit);
  };

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleChangeSetting = props => event => {
    if (props === 'nation') {
      console.log('nation', event.target.value);
      setNationId(event.target.value);
    } else if (props === 'language') {
      i18n.changeLanguage(event.target.value);
    }
    setRiderSetting({ ...RiderSetting, [props]: event.target.value });
    setIsRiderSettingChange(true);
  };

  function hadleUpdateOptions(params) {
    const auth_str = 'Bearer '.concat(auth.getToken().token);

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
        params,
        {
          headers: { Authorization: auth_str }
        }
      )
      .then(response => {
        if (response.data.success) {
          let exInfo = auth.getExtraInfo();
          if (params.order === 'update_addinfo') {
            exInfo.height = Number(response.data.new_height);
            exInfo.weight = Number(response.data.new_weight);
            exInfo.birth = Number(response.data.new_birth);
            exInfo.riding_year = Number(response.data.new_riding_year);
          } else if (params.order === 'update_setting_options') {
            exInfo.nation = response.data.new_nation;
            exInfo.language = response.data.new_language;
            exInfo.timezone = response.data.new_timezone;
            exInfo.unit = response.data.new_unit;
          }

          // 토스트 알림.
          handleSnackOpen();

          // updateOptions('on');
          // auth.clearExtraInfo();
          // // auth.reload('extraInfo');
          // window.location.reload();

          // exInfo.updateAvail = false;
          // auth.setExtraInfo(exInfo, true);

          // setUpdateAvail(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  function hadleUpdateUserNickname() {
    const auth_str = 'Bearer '.concat(auth.getToken().token);

    const params = {
      order: 'user_update',
      new_display_name: formState.values.nickname
    };

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-user.php`,
        params,
        {
          headers: { Authorization: auth_str }
        }
      )
      .then(response => {
        console.log(response.data);
        if (response.data.success) {
          handleSnackOpen();

          // 새로고침.
          auth.clearExtraInfo();
          window.location.reload();
        } else {
          if (response.data.message === 'display_name_duplicate') {
            handleSnackOpen(t('SignUp.nickname') + ' is duplicate');
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  useEffect(() => {
    const params = {
      order: 'update_addinfo',
      new_height: RiderAddinfo.height,
      new_weight: RiderAddinfo.weight,
      new_birth: RiderAddinfo.birth,
      new_riding_year: RiderAddinfo.ridingYear
    };
    if (isRiderAddinfoChange) {
      hadleUpdateOptions(params);
    }
  }, [RiderAddinfo]);

  useEffect(() => {
    const params = {
      order: 'update_setting_options',
      new_nation: RiderSetting.nation,
      new_language: RiderSetting.language,
      new_timezone: RiderSetting.timezone,
      new_unit: RiderSetting.unit
    };
    if (isRiderSettingChange) {
      hadleUpdateOptions(params);
    }
  }, [RiderSetting]);

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Typography className={classes.subTitle} component="div" align="left">
          {t('Options.personalInfo')}
        </Typography>

        {/* {
          (!updateAvail && extraInfo.updateAvailDate) &&
          <Typography component="div" variant="caption" align="center" style={{margin:'8px 0 0'}}>
            {extraInfo.updateAvailDate} {t('Options.afterModify')}
          </Typography>
        } */}

        <CardContent>
          <Grid container spacing={1} className={classes.cardContentBox}>
            <Grid item md={12} xs={12} className={classes.avatarWarp}>
              <Avatar
                alt={userInfo.display_name}
                className={classes.avatar}
                src={extraInfo.profile_image}
              />
              {isEditName ? (
                <Box className={classes.nameBox}>
                  <TextField
                    className={classes.nameInput}
                    fullWidth
                    label={t('SignUp.nickname')}
                    name={'nickname'}
                    onChange={handleChange}
                    type="text"
                    value={formState.values.nickname}
                    variant="outlined"
                    error={hasError('')}
                    helperText={
                      hasError('nickname') ? formState.errors.nickname[0] : null
                    }
                  />
                  <Button
                    variant="outlined"
                    color="inherit"
                    className={classes.saveButton}
                    onClick={e => hadleUpdateUserNickname()}>
                    {t('Options.saveButton')}
                  </Button>
                  <CloseIcon
                    className={classes.editIcon}
                    onClick={() => handleEditName(false)}
                  />
                </Box>
              ) : (
                <Box className={classes.nameBox}>
                  <Typography className={classes.name}>
                    {userInfo.display_name}
                  </Typography>
                  <EditIcon
                    className={classes.editIcon}
                    onClick={() => handleEditName(true)}
                  />
                </Box>
              )}
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Procedure.height')}
                size="small"
                value={RiderAddinfo.height}
                // disabled={!updateAvail}
                onChange={handleChangeAddinfo('height')}
                InputProps={{
                  classes: { input: classes.inputStyle }
                }}>
                {heightRange(Number(RiderAddinfo.height))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Procedure.weight')}
                size="small"
                value={RiderAddinfo.weight}
                // disabled={!updateAvail}
                onChange={handleChangeAddinfo('weight')}>
                {weightRange(Number(RiderAddinfo.weight))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Procedure.birthYear')}
                size="small"
                value={RiderAddinfo.birth}
                // disabled={!updateAvail}
                onChange={handleChangeAddinfo('birth')}>
                {birthRange(Number(RiderAddinfo.birth))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Procedure.ridingYear')}
                size="small"
                value={RiderAddinfo.ridingYear}
                // disabled={!updateAvail}
                onChange={handleChangeAddinfo('ridingYear')}>
                {ridingYearRange(Number(RiderAddinfo.ridingYear))}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <Typography className={classes.subTitle} component="div" align="left">
          {t('Options.preferences')}
        </Typography>

        <CardContent>
          <Grid container spacing={1} className={classes.cardContentBox}>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Options.nation')}
                size="small"
                value={nationId}
                // disabled={!updateAvail}
                onChange={handleChangeSetting('nation')}
                InputProps={{
                  classes: { input: classes.inputStyle }
                }}>
                {NationRange(nationId)}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Options.language')}
                size="small"
                value={RiderSetting.language}
                // disabled={!updateAvail}
                onChange={handleChangeSetting('language')}
                InputProps={{
                  classes: { input: classes.inputStyle }
                }}>
                {LanguageRange(RiderSetting.language)}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Options.timezone')}
                size="small"
                value={RiderSetting.timezone}
                // disabled={!updateAvail}
                onChange={handleChangeSetting('timezone')}>
                {TimezoneRange(RiderSetting.timezone)}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Options.unit')}
                size="small"
                value={RiderSetting.unit}
                // disabled={!updateAvail}
                onChange={handleChangeSetting('unit')}>
                {UnitRange(RiderSetting.unit)}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

ProfileOptions.propTypes = {
  className: PropTypes.string
};

export default ProfileOptions;
