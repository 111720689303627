import React, { useRef, useEffect } from 'react';
import Lottie from 'lottie-web';

const LottieComponent = ({ animationData, ...props }) => {
  // Lottie 애니메이션 컨테이너에 대한 ref
  const animationContainer = useRef(null);

  // Lottie 애니메이션 로드 및 초기화
  useEffect(() => {
    // Lottie 애니메이션 옵션 설정
    const animationOptions = {
      container: animationContainer.current,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData
    };

    // Lottie 애니메이션 로드
    const animation = Lottie.loadAnimation(animationOptions);

    //  컴포넌트 unmount 시 애니메이션 제거
    return () => {
      animation.destroy();
    };
  }, [animationData]);

  return <div ref={animationContainer} {...props} />;
};

export default LottieComponent;
