import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, withRouter, Redirect } from 'react-router-dom';
//
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import auth from '../../utils/auth';
import request from '../../utils/request';
//
import {
  Link,
  Button,
  Switch,
  TextField,
  InputAdornment,
  FormControlLabel,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
      minimum: 2
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

function SignInRenew() {
  const [formState, setFormState] = useState({
    isValid: false,
    values: { rememberMe: true },
    touched: {},
    isFilled: false,
    errors: {}
  });
  // 아이디 화면 -> 비밀번호 화면의 UI 분기를 위해 만든 State
  const [isIDFilled, setIsIDFilled] = useState(false);
  // 로그인 요청에서 생긴 에러메시지를 TextField Label에 HelperText로 적용해주기 위함.
  const [resCode, setResCode] = useState(true);

  /**
   *  @desc : 코드 작동에 필요한 변수 선언들
   */

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  const { t } = useTranslation(['page']);
  const classes = useStyles();
  // const waiting = auth.getToken();

  /**
   *  @desc : formData에 들어오는 value의 validation
   */
  useEffect(() => {
    const errors = validate(formState.values, schema);
    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  /**
   *  @desc : 이벤트 로직 (Change Handler, Submit Handler)
   */
  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      isFilled: true,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignInLocal = event => {
    event.preventDefault();

    // formState로 추합한 로그인 정보
    const req = formState.values;
    req.issueJWT = true;

    const requestURL = `${process.env.REACT_APP_RESTAPI_BASE_URL}/api/aam/v2/authenticate`;

    if (req.username && req.password) {
      // request.js -> 서버 요청 작업을 위해 만들어진 라이브러리
      request(requestURL, { method: 'POST', body: req })
        .then(response => {
          // auth.js -> 토큰 관련 로직이 모여있는 라이브러리
          auth.setToken(response.jwt, true);
          auth.setUserInfo(response.user.data, true);
          console.log(response);
          window.location.reload();
        })
        .catch(err => {
          setResCode(false);
          console.log(err);
        });
    }
  };

  /**
   *  @desc : enter키의 의도 외적인 작동 통제
   */
  const handleKeyPress = event => {
    const nameValue = formState.values.username;
    const pwValue = formState.values.password;

    if (event.key === 'Enter') {
      if (!isIDFilled && (nameValue && nameValue.length >= 2)) {
        setIsIDFilled(true);
      } else if (pwValue && pwValue.length > 0) {
        event.preventDefault();
        handleSignInLocal(event);
      }
    }
  };

  /**
   *  @desc : 로그인.아이디 -> 로그인.비밀번호로 UI 이동 시켜주는 함수
   */
  const textAreaValidHandler = event => {
    event.preventDefault();

    if (formState.isFilled || formState.values.username >= 2) {
      setIsIDFilled(true);
    }
  };

  /**
   * @desc : 입력창 Focus && 비밀번호 보기 on/off 토글 로직
   */
  const [isShowPwChecked, setShowPwChecked] = useState(false);
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (!isIDFilled) {
      usernameRef.current.focus();
    } else {
      passwordRef.current.focus();
    }
  }, []);

  const handleShowPwChecked = () => {
    const password = passwordRef.current;
    if (password === null) return;
    setShowPwChecked(!isShowPwChecked);
    password.type = isShowPwChecked ? 'password' : 'text';
  };

  // function handleProviderResponseClose(value) {
  //   setProviderResponseOpen(false);
  //   setProviderResponse(null);
  // };

  /*
    const handleClickSignOut = event => {
      event.preventDefault();
      auth.clearToken();
      window.location.reload();
    }
  */

  // 소셜로그인 하기
  // const [providerResponse, setProviderResponse] = useState(null);
  // const [providerResponseOpen, setProviderResponseOpen] = useState(false);

  // proper 로그인 실패 경고
  // const [popoverOpen, setPopoverOpen] = React.useState(false);
  // const [anchorEl, setAnchorEl] = React.useState(null);

  // 로그인 페이스북
  /*
    const responseFacebook = (res) => {	
      const requestURL = './auth/facebook/callback?access_token=' + res.accessToken;
      request(requestURL, { method: 'GET' })
        .then((response) => {
  
          auth.setToken(response.jwt, true);
          auth.setUserInfo(response.user, true);
  
          window.location.reload();
      }).catch((err) => {
        console.log(err);
      });
    }
  */

  //로그인 구글
  /*
    const responseGoogle = (res) => {
  //	console.log(res.accessToken);
  
      const requestURL = './auth/google/callback?access_token=' + res.accessToken;
      request(requestURL, { method: 'GET' })
        .then((response) => {
  //				console.log(response);
  
          auth.setToken(response.jwt, true);
          auth.setUserInfo(response.user, true);
  
          window.location.reload();
      }).catch((err) => {
  //      console.log(err);
      });
    }
  */

  return (
    <>
      <div>
        <img
          alt="Logo"
          src="/images/logos/logo.png"
          className={classes.logoImage}
        />
      </div>
      <div className={classes.subtitle}>
        <Typography
          align="center"
          color="textPrimary"
          style={{ fontWeight: '700' }}
          variant="h4">
          {t('SignIn.loginByRiduck2')}
        </Typography>
        {!isIDFilled ? (
          <>
            <Typography align="center" color="textPrimary" variant="body1">
              {t('SignIn.id2')}
            </Typography>
          </>
        ) : (
          <>
            <Typography align="center" color="textPrimary" variant="body1">
              {t('SignIn.password2')}
            </Typography>
          </>
        )}
      </div>
      <form className={classes.textArea} onSubmit={handleSignInLocal}>
        {!isIDFilled ? (
          <>
            <TextField
              inputRef={usernameRef}
              className={classes.textField}
              error={hasError('username')}
              fullWidth
              helperText={
                hasError('username') ? formState.errors.username[0] : null
              }
              label={t('SignIn.id')}
              name="username"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="text"
              value={formState.values.username || ''}
              variant="outlined"
            />
          </>
        ) : (
          <>
            <TextField
              inputRef={passwordRef}
              className={classes.textField}
              error={hasError('password') || !resCode}
              fullWidth
              helperText={
                hasError('password')
                  ? formState.errors.password[0]
                  : !resCode && formState.values.password
                  ? t('SignIn.wrongPassword')
                  : null
              }
              // helperText={
              //   (hasError('password') && !formState.values.password) ? null :
              //     (hasError('password')) ? formState.errors.password[0] :
              //       (hasError('password') && !resCode) ? t('SignIn.wrongPassword') : null
              // }
              label={t('SignIn.password')}
              name="password"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="password"
              value={formState.values.password || ''}
              variant="outlined"
              InputProps={
                !isShowPwChecked
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <VisibilityRoundedIcon
                            onClick={handleShowPwChecked}
                            className={classes.eyeimage}
                          />
                        </InputAdornment>
                      )
                    }
                  : {
                      endAdornment: (
                        <InputAdornment position="end">
                          <VisibilityOffRoundedIcon
                            onClick={handleShowPwChecked}
                            className={classes.eyeimage}
                          />
                        </InputAdornment>
                      )
                    }
              }
            />
            <div className={classes.switchField}>
              <Typography align="center" color="textSecondary" variant="body1">
                {t('SignIn.continue')}
              </Typography>
              <FormControlLabel
                control={
                  <Switch
                    defaultChecked
                    color="primary"
                    size="medium"
                    onClick={() => {
                      setFormState(prevState => ({
                        ...prevState,
                        values: {
                          ...prevState.values,
                          rememberMe: !formState.values.rememberMe
                        }
                      }));
                    }}
                  />
                }
              />
            </div>
          </>
        )}
        {!isIDFilled ? (
          <>
            <Button
              className={classes.signInButton}
              color="primary"
              disabled={!formState.isFilled || !formState.values.username}
              fullWidth
              size="large"
              onClick={textAreaValidHandler}
              variant="contained">
              {t('SignIn.next')}
            </Button>
          </>
        ) : (
          <>
            <Button
              className={classes.signInButton}
              color="primary"
              disabled={!formState.isFilled || !formState.values.password}
              fullWidth
              size="large"
              type="submit"
              variant="contained">
              {t('SignIn.login')}
            </Button>
          </>
        )}
      </form>
      <div className={classes.footerText}>
        {!isIDFilled ? (
          <>
            <Link
              align="center"
              color="textSecondary"
              component={RouterLink}
              to="/sign-up-renew"
              variant="body1">
              {t('SignIn.signup')}
            </Link>
          </>
        ) : (
          <>
            <Typography
              onClick={() => setIsIDFilled(false)}
              align="center"
              color="textSecondary"
              variant="body1">
              {t('SignIn.back')}
            </Typography>
          </>
        )}
        <Typography align="center" color="textSecondary" variant="body1">
          {t('SignIn.help')}
        </Typography>
      </div>
    </>
  );
}
export default withRouter(SignInRenew);

SignInRenew.propTypes = {
  history: PropTypes.object
};

/**
 *  @desc : MUI V4, useStyles 스타일링
 */
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: '100%'
  },
  logoImage: {
    width: '216px'
  },
  subtitle: {
    marginTop: theme.spacing(4),
    width: '80vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start'
  },
  textArea: {
    width: '80vw',
    height: '30vh'
  },
  textField: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  switchField: {
    marginBottom: theme.spacing(3),
    '& > *': {
      marginRight: '3px'
    },
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    justifyItems: 'center'
  },
  footerText: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2)
    }
  },
  popover: {
    display: 'flex',
    alignItems: 'center'
  },
  popoverText: {
    padding: theme.spacing(2),
    backgroundColor: '#444444',
    color: '#ffffff'
  },
  eyeimage: {
    cursor: 'pointer'
  }
}));
