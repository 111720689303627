export const UNICODE_RANGES = [
  {
    start: 0x0000,
    end: 0x007f,
    font: "'Quicksand', sans-serif"
  },
  {
    start: 0xac00,
    end: 0xd7a3,
    font: "'Mapopeacefull', sans-serif"
  },
  {
    start: 0x4e00,
    end: 0x9fff,
    font: "'Noto Sans SC', sans-serif"
  },
  {
    start: 0x3040,
    end: 0x309f,
    font: "'Noto Sans JP', sans-serif"
  }
];
