import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cookie from 'react-cookies';
import { makeStyles } from '@mui/styles';
import { NavigationBar } from 'components/Header';
import { CookieBanner, NewsSnackbar, StravaIcon } from 'components/Common';
import { Footer as NewFooter } from 'components/Footer';
import Topbar from './components/Topbar';
import { Box } from '@mui/material';
// import Footer from '../Footer';

const useStyles = makeStyles(theme => ({
  '@global': { body: { backgroundColor: theme.palette.background.default } },
  root: {
    paddingTop: 64,
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    height: '100%',
    flexGrow: 1
  },
  stack: {
    height: '80px',
    [theme.breakpoints.down('sm')]: {
      height: '200px'
    }
  }
}));

const Minimal = props => {
  const { children } = props;
  const { pathname } = useLocation();
  const { t } = useTranslation(['landing']);

  const classes = useStyles();
  const isLanding =
    pathname === '/landing' ||
    pathname === '/terms' ||
    pathname === '/privacy' ||
    pathname === '/news' ||
    pathname === '/blog';
  const hasCookiePolicy = cookie.load('cookie_policy') ? true : false;

  return (
    <div className={classes.root}>
      {isLanding ? <NavigationBar /> : <Topbar />}

      <main className={classes.content}>{children}</main>
      <Box sx={{ pb: 3 }}>
        <StravaIcon />
      </Box>
      <NewFooter />
      {/* {isLanding ? <NewFooter /> : <Footer />} */}

      {/* 쿠키 정책 배너 */}
      {!hasCookiePolicy ? <CookieBanner /> : null}
      {/* <NewsSnackbar message={t('Snackbar.message')} url={'/news'} /> */}
    </div>
  );
};

Minimal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default Minimal;
