import landing from './landing.json';
import privacy from './privacy.json';
import terms from './terms.json';
import page from './page.json';

export default {
  landing,
  privacy,
  terms,
  page,
  SideBar: page.SideBar,
  SignIn: page.SignIn,
  SignUp: page.SignUp
};
