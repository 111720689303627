import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DynamicFontTypography } from 'components/Typography';
import { fetchBlogs } from 'hooks/useBlogs';
import { fetchAnnouncements } from 'hooks/useAnnouncements';

export default function RelatedSection() {
  const theme = useTheme();
  const { pathname } = useLocation();

  const sectionMatch = pathname.match(/^\/(.*?)\//);
  const section = sectionMatch ? sectionMatch[1] : null;

  const slugMatch = pathname.match(/\/([^/]+)$/);
  const currentSlug = slugMatch ? slugMatch[1] : null;

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const result =
        section === 'blog' ? await fetchBlogs() : await fetchAnnouncements();
      setDataList(result || []);
    };

    fetchData();
  }, [section]);

  if (!dataList.length) return null;

  return (
    <Stack width="100%" mb={2}>
      <DynamicFontTypography
        mb={2}
        sx={{ fontSize: '18px', fontWeight: 500, alignSelf: 'center' }}>
        Related Articles
      </DynamicFontTypography>
      <Stack spacing={2} direction="column" alignItems={{ md: 'center' }}>
        {dataList
          .filter(post => post.slug !== currentSlug)
          .slice(0, 3)
          .map(related => (
            <Box
              key={related.slug}
              gap={2}
              component={Link}
              to={`/${section}/${related.slug}`}
              display="flex"
              alignItems="center"
              width={{ xs: '100%', md: '50%' }}
              borderRadius="4px"
              boxShadow={2}
              bgcolor={theme.palette.background.paper}
              overflow="hidden"
              sx={{ textDecoration: 'none' }}>
              <Box
                component="img"
                src={related.img}
                width={100}
                height={80}
                borderRadius="4px 0px 0px 4px"
                boxShadow={2}
                sx={{
                  objectFit: 'cover',
                  bgcolor: '#F8FAFB'
                }}
              />
              <DynamicFontTypography
                variant="body2"
                paddingRight={4}
                sx={{
                  color: 'primary',
                  fontSize: { xs: '12px', md: '14px' },
                  textDecoration: 'none'
                }}>
                {related.title}
              </DynamicFontTypography>
            </Box>
          ))}
      </Stack>
    </Stack>
  );
}
