import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Typography
} from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BackspaceIcon from '@mui/icons-material/Backspace';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import cloneDeep from 'lodash/cloneDeep';

//jQuery 추가
import $ from 'jquery';

//Chart 추가
import CanvasJSReact from '../../../utils/canvasjs.stock.react';
import { fillMissingData } from 'utils/activity';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  root: {},
  powerContainer: {
    height: '240px',
    padding: '4px',
    [theme.breakpoints.down('xl')]: {
      height: '200px'
    },
    [theme.breakpoints.down('md')]: {
      height: '160px'
    }
  },
  tableContainer: {
    height: 300,
    overflow: 'y-auto',
    margin: '12px 0px 0px 0px',
    padding: '4px',
    [theme.breakpoints.down('xl')]: {
      height: 240
    },
    [theme.breakpoints.down('md')]: {
      height: 200
    }
  },
  streamWrap: {
    padding: '8px 4px',
    marginBottom: '12px',
    borderBottom: '1px #dddddd solid'
  },
  streamSummary: {
    background: '#f5f5f5',
    borderRadius: '4px',
    marginTop: '16px',
    padding: '6px 12px'
  },
  summaryTitle: {
    lineHeight: '32px',
    fontWeight: 500,
    fontSize: '15px'
  },
  tabs: {
    width: '300px',
    border: '1px #dddddd solid',
    borderRadius: '4px 4px 0 0'
  },
  tabContent: {
    width: '300px',
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px'
  },
  listItem: {
    padding: '2px 12px'
  },
  tabTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  xaxisLabel: {
    color: '#888888',
    fontSize: '11px'
  },
  intervalGrid: {
    border: '1px #dddddd solid',
    borderRadius: '4px',
    padding: '8px'
  },
  tableHeadCell: {
    padding: '10px 16px',
    color: '#4db6ac',
    fontSize: '13px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    // backgroundColor: '#f9f9f9',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 4px',
      fontSize: '11px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 4px',
      fontSize: '9px'
    }
  },
  tableCell: {
    fontSize: '13px',
    padding: '10px 16px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    [theme.breakpoints.down('lg')]: {
      padding: '4px 4px',
      fontSize: '11px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 4px',
      fontSize: '9px'
    }
  },
  infoContainer: { padding: '8px 12px' },
  columnHeader: { fontWeight: 500, fontSize: '13px' },
  dataField: { fontSize: '12px' },
  chartDivider: {
    padding: '4px 4px',
    borderBottom: '1px #dddddd solid',
    marginBottom: '12px'
  }
}));

//const showText = useMediaQuery('(min-width:600px)');
//console.log (showText) ;

const IntervalChart = props => {
  //다국어적용
  const { t } = useTranslation(['page']);
  const theme = useTheme();

  const extraInfo = props.extraInfo;

  //unit default
  const unit = extraInfo.unit ? extraInfo.unit : 'metric';
  const kmToMile = 0.621371;
  const mToFeet = 3.28084;
  const kgToLbs = 2.20462;

  const {
    pdcData,
    activityId,
    ftp,
    maxHr,
    streamDataJson,
    powerzoneData,
    wkg,
    weight
  } = props;
  const [stripLines, setStripLines] = useState([]);
  const [pdGuideDialogOpen, setPdGuideDialogOpen] = React.useState(false);
  const [intervalChartOptions, setIntervalChartOptions] = useState({});
  const [wattsChartOptions, setWattsChartOptions] = useState({});
  const [hrChartOptions, setHrChartOptions] = useState({});
  const [altitudeChartOptions, setAltitudeChartOptions] = useState({});

  const [intervalChart, setIntervalChart] = useState(null);
  const [powerChart, setPowerChart] = useState(null);
  const [hrChart, setHrChart] = useState(null);
  const [altitudeChart, setAltitudeChart] = useState(null);

  const streamOrg = streamDataJson.stream;
  const streamData = streamDataJson.stream_json;

  const activityInterval =
    powerzoneData.powerzone_activities[String(activityId)];
  //	const zonePer = activityInterval.per;
  //	const zoneTime = activityInterval.time;
  //	const calories = activityInterval.calorie.calories;
  //	const fatPer = activityInterval.calorie.fat_per;
  //	const carbPer = activityInterval.calorie.carb_per;
  //	const workoutTime = activityInterval.total;
  //	const trainingType = activityInterval.type;

  const wattsArray = streamOrg.watts;
  const watts0Array = streamOrg.watts0;
  const awcArray = streamOrg.awc;
  const heartrateArray = streamOrg.heartrate;
  const distanceArray = streamOrg.distance;

  //console.log(awcArray);

  const wattsPoints = streamData.watts;
  const heartratePoints = streamData.heartrate;
  const awcPoints = streamData.awc;

  const altitudePoints = streamData.altitude;
  const distancePoints = streamData.distance;
  const durationPoints = streamData.duration;

  const wattsMax = streamData.watts_max,
    wattsMaxIndex = streamData.watts_max_index;

  const activityFTP = streamDataJson.ftp;
  const activityName = streamDataJson.activity_name;
  const altitudeMax = streamDataJson.altitude_max;
  const stravaDescription = streamDataJson.strava_description;
  const streamLength = streamDataJson.obj_length;
  const movingDistance = streamDataJson.distance;
  const movingTime = streamDataJson.moving_time;
  const rideType = streamDataJson.ride_type;

  //	console.log("ww " + window.innerHeight);

  const containerPower = {
    width: '100%',
    height: window.innerHeight > 800 ? '240px' : '180px',
    margin: 'auto'
  };

  const containerHr = {
    width: '100%',
    height: window.innerHeight > 800 ? '120px' : '90px',
    margin: 'auto'
  };

  const containerAltitude = {
    width: '100%',
    height: window.innerHeight > 800 ? '120px' : '90px',
    margin: 'auto'
  };

  function getKeyByValue(object, value) {
    return Object.keys(object).find(key => object[key] === value);
  }

  function findKeyByValue(Array, value) {
    for (let l = 0; l < Array.length; l++) {
      if (Array[l] >= value) return l;
    }
  }

  useEffect(() => {
    const {
      moving10Points,
      stripLineArray,
      bubbleArray,
      intentionalInterval,
      intentionalIntervalList,
      ftpCheck
    } = IntervalTime(wattsArray, awcArray, wattsPoints, 10);
    const { hrZoneArr, hrZonePoints } = ArrangeHrZone(
      heartrateArray,
      heartratePoints,
      0
    );

    /*
    if (ftpCheck) {
      console.log("✅ FTP 측정에 적합한 인터벌이 있습니다!");
    } else {
      console.log("❌ FTP 측정에 적합한 인터벌이 없어용");   
    }
    
    intentionalIntervalList.forEach(i => {
      console.log(`▶ ${i.zone}: ${i.duration}초, ${i.avgPower}W, AWC ${i.usage}% ${i.usage.toFixed(1)}kJ`);
    });
*/

    setStripLines(stripLineArray);

    /******* watts Chart  *******/
    setWattsChartOptions({
      backgroundColor: theme.palette.chart.background,
      animationEnabled: false,
      theme: 'light2',
      title: false,
      zoomEnabled: true,
      zoomType: 'x',
      toolTip: {
        shared: true,
        contentFormatter: function(e) {
          let content = ' ';
          for (let i = 0; i < e.entries.length; i++) {
            //배터리
            if (i == 0) {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                Math.round(
                  (100 * e.entries[i].dataPoint.y) / Math.round(wattsMax)
                ) +
                '</strong> %';
              content += '<br/>';
            } else if (i == 1) {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                e.entries[i].dataPoint.y +
                '</strong> W';
              content += '<br/>';
              content +=
                'FTP ' +
                '<strong>' +
                Math.round(
                  (100 * e.entries[i].dataPoint.y) / Math.round(activityFTP)
                ) +
                '</strong> %';
              content += '<br/>';
            }
          }

          return content;
        }
      },
      axisX: {
        gridThickness: 0,
        tickLength: 0,
        lineThickness: 1,
        lineColor: '#dddddd',
        labelFontColor: '#ffffff',
        valueFormatString: '.',
        labelAngle: 0,
        stripLines: stripLineArray,
        crosshair: {
          enabled: false,
          lineColor: '#999999',
          labelFormatter: function(e) {
            if (rideType === 'Ride') {
              let navDistance =
                unit === 'metric'
                  ? Math.round(e.value / 100) / 10 + 'km'
                  : Math.round((e.value / 100) * kmToMile) / 10 + 'mi';

              return navDistance;
            } else if (rideType === 'VirtualRide') {
              return secondsToHmsLabel(e.value);
            }
          }
        }
      },
      axisY: {
        margin: 10,
        maximum: Math.round(wattsMax) + 150,
        labelFontSize: 11,
        labelFontColor: theme.palette.chart.text.primary,
        labelPlacement: 'inside',
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        labelFormatter: function(e) {
          if (e.value > 999) {
            return e.value;
          } else {
            return e.value + 'w';
          }
        },
        gridThickness: 1,
        gridColor: theme.palette.chart.secondary.axis.grid,
        minimum: 1
      },
      data: [
        {
          type: 'area',
          fillOpacity: 0.2,
          name: t('Dashboard.battery'),
          yValueFormatString: '# %',
          lineThickness: 1,
          color: '#dddddd',
          legendMarkerType: 'square',
          dataPoints: awcPoints
        },
        {
          type: 'area',
          fillOpacity: 1.0,
          name: t('Dashboard.watts'),
          yValueFormatString: '# W',
          lineThickness: 0,
          fillOpacity: 0.8,
          color: '#4db6ac',
          legendMarkerType: 'square',
          dataPoints:
            streamDataJson.ride_type === 'Ride'
              ? wattsPoints
              : fillMissingData(wattsPoints)
        },
        {
          type: 'column',
          fillOpacity: 0.8,
          name: t('Dashboard.watts'),
          yValueFormatString: '# W',
          lineThickness: 1,
          color: '#f5f5f5',
          click: onMouseoverInterval,
          legendMarkerType: 'square',
          dataPoints: moving10Points
        },
        {
          type: 'bubble',
          name: 'INTERVAL',
          fillOpacity: 0.8,
          indexLabelFontColor: '#ffffff',
          toolTipContent: '<b>{name}</b><br/>x: {x}<br/>y: {y}<br/> z: {z}',
          click: onMouseoverInterval,
          dataPoints: bubbleArray
        }
      ]
    });

    function onMouseoverInterval(e) {
      let hash = '#interval_' + e.dataPoint.id;
      let color = e.dataPoint.color;

      $('.MuiTableRow-root').css({ 'background-color': '#ffffff' });

      $(hash).css({ 'background-color': color });

      if ($(hash).position()) {
        $('#intervalTable').animate({ scrollTop: $(hash).position().top }, 200);
      }
    }

    function onMouseoutInterval(e) {
      $('.MuiTableRow-root').css({ 'background-color': '#ffffff' });
    }

    function onMouseover(e) {
      let hash = '#interval_' + e.dataPoint.id;
      let color = e.dataPoint.color;

      $('.MuiTableRow-root').css({ 'background-color': '#ffffff' });

      $(hash).css({ 'background-color': color, color: '#ffffff' });

      if ($(hash).position()) {
        $('#intervalTable').animate({ scrollTop: $(hash).position().top }, 200);
      }
    }

    /******* hr Chart  *******/
    setHrChartOptions({
      backgroundColor: theme.palette.chart.background,
      animationEnabled: false,
      theme: 'light2',
      title: false,
      toolTip: {
        shared: true,
        contentFormatter: function(e) {
          let content = ' ';
          for (let i = 0; i < e.entries.length; i++) {
            //배터리
            if (i == 0) {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                Math.round(e.entries[i].dataPoint.y) +
                '</strong> bpm';
              content += '<br/>';
              content +=
                t('Activity.maxHr') +
                ' ' +
                '<strong>' +
                Math.round(
                  (100 * e.entries[i].dataPoint.y) / Math.round(maxHr)
                ) +
                '</strong> %';
              content += '<br/>';
            }
          }

          return content;
        }
      },
      axisX: {
        gridThickness: 0,
        tickLength: 0,
        lineThickness: 1,
        lineColor: '#dddddd',
        labelFontColor: '#ffffff',
        valueFormatString: '.',
        labelAngle: 0,
        stripLines: stripLineArray
      },
      axisY: {
        margin: 10,
        labelFontSize: 11,
        labelFontColor: theme.palette.chart.text.primary,
        labelPlacement: 'inside',
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        labelFormatter: function(e) {
          return e.value + 'bpm';
        },
        gridThickness: 1,
        gridColor: theme.palette.chart.secondary.axis.grid,
        minimum: 60
      },
      data: [
        {
          type: 'column',
          fillOpacity: 0.35,
          name: t('Dashboard.hrm'),
          yValueFormatString: '# bpm',
          lineThickness: 1,
          color: '#4db6ac',
          legendMarkerType: 'square',
          dataPoints: hrZonePoints
        }
      ]
    });

    /******* altitude Chart  *******/
    setAltitudeChartOptions({
      backgroundColor: theme.palette.chart.background,
      animationEnabled: false,
      theme: 'light2',
      title: false,
      zoomEnabled: true,
      zoomType: 'x',
      toolTip: {
        shared: true,
        contentFormatter: function(e) {
          let content = ' ';
          for (let i = 0; i < e.entries.length; i++) {
            if (i == 0) {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                e.entries[i].dataPoint.y +
                '</strong>m';
              content += '<br/>';

              if (rideType === 'Ride') {
                content +=
                  t('Dashboard.distance') +
                  ' ' +
                  '<strong>' +
                  Math.round(e.entries[i].dataPoint.x / 100) / 10 +
                  '</strong>km';
                content += '<br/>';
              } else {
                content +=
                  t('Dashboard.distance') +
                  ' ' +
                  '<strong>' +
                  Math.round(distanceArray[e.entries[i].dataPoint.x] / 100) /
                    10 +
                  '</strong>km';
                content += '<br/>';
              }
            } else {
              content +=
                e.entries[i].dataSeries.name +
                ' ' +
                '<strong>' +
                secondsToHmsLabel(e.entries[i].dataPoint.y) +
                '</strong>';
              content += '<br/>';
            }
          }
          return content;
        }
      },
      axisX: {
        gridThickness: 1,
        tickLength: 1,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        labelFontColor: theme.palette.chart.text.primary,
        gridColor: theme.palette.chart.secondary.axis.grid,
        labelAngle: 0,
        stripLines: stripLineArray,
        labelFormatter: function(e) {
          if (rideType === 'Ride') {
            let navDistance =
              unit === 'metric'
                ? Math.round(e.value / 100) / 10 + 'km'
                : Math.round((e.value / 100) * kmToMile) / 10 + 'mi';

            return navDistance;
          } else if (rideType === 'VirtualRide') {
            return secondsToHmsLabel(e.value);
          }
        },
        crosshair: {
          enabled: true,
          lineColor: '#999999',
          labelFormatter: function(e) {
            if (rideType === 'Ride') {
              let navDistance =
                unit === 'metric'
                  ? Math.round(e.value / 100) / 10 + 'km'
                  : Math.round((e.value / 100) * kmToMile) / 10 + 'mi';

              return navDistance;
            } else if (rideType === 'VirtualRide') {
              return secondsToHmsLabel(e.value);
            }
          }
        }
      },
      axisY: {
        maximum: altitudeMax < 100 ? 100 : altitudeMax,
        margin: 10,
        labelFontSize: 11,
        labelFontColor: theme.palette.chart.text.primary,
        labelPlacement: 'inside',
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        gridThickness: 1,
        gridColor: theme.palette.chart.secondary.axis.grid,
        labelFormatter: function(e) {
          return '';
          /*
					if(altitudeMax > 999) {
						return Math.round(e.value);
					} else {
						return Math.round(e.value) + "m";
					}
					*/
        }
      },
      data: [
        {
          type: 'area',
          fillOpacity: 0.4,
          name: t('Dashboard.altitude'),
          lineThickness: 1,
          color: '#cccccc',
          legendMarkerType: 'square',
          dataPoints: altitudePoints
        },
        {
          visible: false,
          type: 'line',
          fillOpacity: 0.4,
          name: t('Dashboard.duration'),
          lineThickness: 1,
          color: '#cccccc',
          legendMarkerType: 'square',
          dataPoints: durationPoints
        }
      ]
    });
  }, []);

  const classes = useStyles();

  function handlePdGuideDialogOpen() {
    setPdGuideDialogOpen(true);
  }

  function handlePdGuideDialogClose() {
    setPdGuideDialogOpen(false);
  }

  function IntervalTable(props) {
    const { stripLines } = props;

    const classes = useStyles();

    /*
		"z7":"스프린트",
		"z6":"무산소",
		"z5":"Vo2Max",
		"z4":"젖산역치",
		"z3":"템포",
		"z2":"지구력",
		"z1":"활성회복",
*/

    let key = 0;
    return (
      <TableContainer
        component={Paper}
        id={'intervalTable'}
        className={classes.tableContainer}>
        <Table className={classes.table} aria-label="table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeadCell}>
                {t('Common.intervalName')}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                {t('Dashboard.avgPower')}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                {t('Common.intervalIntensity')}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                {t('Common.intervalDuration')}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                {t('Common.batteryConsumption')}
              </TableCell>
              <TableCell className={classes.tableHeadCell} align="right">
                {t('Common.kJouls')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ maxHeight: '400px' }}>
            {stripLines.map(interval => {
              if (interval.type === 'range') {
                key++;

                return (
                  <TableRow
                    key={interval.startValue}
                    id={'interval_' + interval.startValue}>
                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      scope="row">
                      {interval.zoneAnimal +
                        ' ' +
                        interval.name +
                        ' ' +
                        t('Common.interval') +
                        ' (' +
                        interval.Label +
                        ')'}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {interval.power} W
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {Math.round((100 * interval.power) / activityFTP)} %
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {secondsToHms(interval.duration)}
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {Math.round(10 * interval.usage) / 10} %
                    </TableCell>
                    <TableCell className={classes.tableCell} align="right">
                      {Math.round(0.01 * interval.power * interval.duration) /
                        10}{' '}
                      kJ
                    </TableCell>
                  </TableRow>
                );
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  function IntervalList(props) {
    const { stripLines } = props;
    let key = 0;

    return (
      <div
        style={{ overflowX: 'hidden', overflowY: 'auto', maxHeight: '320px' }}>
        {stripLines.map(interval => {
          key++;

          if (interval.type === 'range') {
            return (
              <Box className={classes.intervalGrid}>
                <div>color : {interval.color}</div>
                <div>
                  time : {secondsToHms(interval.endValue - interval.startValue)}
                </div>
                <div>power : {interval.power}</div>
              </Box>
            );
          }
        })}
      </div>
    );
  }

  /******* powerzone Chart  *******/
  const toolTip = {
    shared: true,
    contentFormatter: function(e) {
      let content = secondsToHms(e.entries[0].dataPoint.x);
      content += ' ' + t('Activity.peakPower') + '<br/>';

      for (let i = 0; i < e.entries.length; i++) {
        if (i == 0) {
          content +=
            e.entries[i].dataSeries.name +
            ' <strong>' +
            e.entries[i].dataPoint.y +
            '</strong>W';
          content += '<br/>';
        } else {
          content +=
            e.entries[i].dataSeries.name +
            ' <strong>' +
            e.entries[i].dataPoint.y +
            '</strong>W';
          content += '<br/>';
        }
      }
      return content;
    }
  };

  //awc % 포함하기
  let toolTipWatts = {
    shared: true,
    contentFormatter: function(e) {
      let content = ' ';
      for (let i = 0; i < e.entries.length; i++) {
        content +=
          e.entries[i].dataSeries.name +
          ' ' +
          '<strong>' +
          e.entries[i].dataPoint.y +
          '</strong>w';
        content += '<br/>';
        content +=
          'ZONE ' + '<strong>' + e.entries[i].dataPoint.zone + '</strong>';
        content += '<br/>';
      }
      return content;
    }
  };

  const summary = {
    threshold: { count: 0, duration: 0 },
    vo2max: { count: 0, duration: 0 },
    anaerobic: { count: 0, duration: 0 },
    sprint: { count: 0, duration: 0 },
    all: { count: 0, duration: 0 }
  };

  stripLines.forEach(stripLine => {
    if (stripLine.type === 'range') {
      if (stripLine.zone === 'Threshold') {
        summary.threshold.count += 1;
        summary.threshold.duration += stripLine.duration;
      }
      if (stripLine.zone === 'Vo2max') {
        summary.vo2max.count += 1;
        summary.vo2max.duration += stripLine.duration;
      }
      if (stripLine.zone === 'Anaerobic') {
        summary.anaerobic.count += 1;
        summary.anaerobic.duration += stripLine.duration;
      }
      if (stripLine.zone === 'Sprint') {
        summary.sprint.count += 1;
        summary.sprint.duration += stripLine.duration;
      }
      summary.all.count += 1;
      summary.all.duration += stripLine.duration;
    }
  });

  function secondsToHms(time) {
    const d = Number(time);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    const s = Math.floor((d % 3600) % 60);
    if (h > 0)
      return `${h}${t('Common.hour')} ${m > 0 ? m + t('Common.min') : ''}`;
    return `${m > 0 ? m + t('Common.min') + ' ' : ''}${
      s > 0 ? s + t('Common.sec') : 0 + t('Common.sec')
    }`;
  }

  const intervalSummary = [
    {
      key: t('Powerzone.countMet'),
      value: `${summary.all.count} ${t(`Powerzone.time`)}`
    },
    {
      key: t('Common.z4'),
      value: `${summary.threshold.count} ${t(
        `Powerzone.time`
      )} / ${secondsToHms(summary.threshold.duration)}`
    },
    {
      key: t('Common.z5'),
      value: `${summary.vo2max.count} ${t(`Powerzone.time`)} / ${secondsToHms(
        summary.vo2max.duration
      )}`
    },
    {
      key: t('Common.z6'),
      value: `${summary.anaerobic.count} ${t(
        `Powerzone.time`
      )} / ${secondsToHms(summary.anaerobic.duration)}`
    },
    {
      key: t('Common.z7'),
      value: `${summary.sprint.count} ${t(`Powerzone.time`)} / ${secondsToHms(
        summary.sprint.duration
      )}`
    }
    // { key: '성냥개비', value: '없음' },
    // { key: '무산소 최대방전', value: '없음' }
  ];

  return (
    <React.Fragment>
      <div className={classes.streamWrap}></div>
      {/* 요약 정보 */}
      <Box
        sx={{
          display: 'flex',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          pb: 1
        }}>
        {intervalSummary.map(({ key, value }) => (
          <Box
            key={key}
            sx={{
              flex: '0 0 auto',
              px: 1.5,
              textAlign: 'center'
            }}>
            <Typography
              variant="body2"
              color="textSecondary"
              sx={{ fontSize: '0.75rem' }}>
              {key}
            </Typography>
            <Typography
              variant="body1"
              sx={{ fontSize: '0.9rem', fontWeight: 500 }}>
              {value}
            </Typography>
          </Box>
        ))}
      </Box>
      <Divider className={classes.chartDivider} />

      <CanvasJSChart
        className={classes.powerContainer}
        containerProps={containerPower}
        options={wattsChartOptions}
        onRef={ref => setPowerChart(ref)}
      />
      <CanvasJSChart
        containerProps={containerHr}
        options={hrChartOptions}
        onRef={ref => setHrChart(ref)}
      />
      <CanvasJSChart
        containerProps={containerAltitude}
        options={altitudeChartOptions}
        onRef={ref => setAltitudeChart(ref)}
      />

      <IntervalTable stripLines={stripLines} style={{ marginTop: '12px' }} />

      <Dialog open={pdGuideDialogOpen} onClose={handlePdGuideDialogClose}>
        <DialogTitle>{t('Dashboard.powerProfile')}</DialogTitle>
        <Divider />
        <DialogContent style={{ padding: '8px' }}></DialogContent>
      </Dialog>
    </React.Fragment>
  );

  // function secondsToHms(time) {
  //   let d = Number(time);
  //   let h = Math.floor(d / 3600);
  //   let m = Math.floor((d % 3600) / 60);
  //   let s = Math.floor((d % 3600) % 60);

  //   if (h > 0) {
  //     let hd = h > 0 ? h + t('Common.hour') + ' ' : '';
  //     let md = m > 0 ? m + t('Common.min') : '';

  //     return hd + md;
  //   } else if (h <= 0) {
  //     let md = m > 0 ? m + t('Common.min') + ' ' : '';
  //     let sd = s > 0 ? s + t('Common.sec') : '';

  //     return md + sd;
  //   }
  // }

  function secondsToHmsLabel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '';
    } else if (h > 0) {
      let hd = h > 0 ? h + ':' : '00';
      let md = m > 0 ? m : '00';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + ':' : '';
      let sd = s > 0 ? s : '00';

      return md + sd;
    }
  }

  function sortJSON(data, key, type) {
    if (type == undefined) {
      type = 'asc';
    }
    return data.sort(function(a, b) {
      let x = a[key];
      let y = b[key];
      if (type == 'desc') {
        return x > y ? -1 : x < y ? 1 : 0;
      } else if (type == 'asc') {
        return x < y ? -1 : x > y ? 1 : 0;
      }
    });
  }

  //인터벌 이동평균선 기반으로 분석하여 인터벌 표시
  function IntervalTime(wattsArray, awcArray, wattsPoints, maWindow = 10) {
    const maHalf = Math.floor(maWindow / 2); // 이동 평균 구간의 절반 (중심 기준)
    const movingPoints = []; // 시각화용 개별 파워 포인트
    const bubbles = []; // 인터벌 요약 표시용 버블 데이터
    const strips = []; // stripLine 범위 및 인터벌 정보
    let movingSum = _.sum(wattsArray.slice(0, maWindow)); // 초기 MA 합
    let index = maHalf; // 현재 파워 인덱스 (MA 기준 중앙)
    let intervalOn = false; // 인터벌 시작 여부 플래그
    let intervalStart = 0,
      intervalEnd = 0; // 거리 기준 시작/종료
    let intervalStartRest = 0,
      intervalEndRest = 0; // 배열 인덱스 기준 시작/종료
    const awcBaseline = 100;

    // 평균 파워와 지속 시간에 따라 인터벌 Zone 판단
    function calcZone(avgPower, duration) {
      const ftp = activityFTP;
      const zones = [
        {
          min: 1.0,
          max: 1.05,
          minDur: 60,
          color: '#ffd357',
          zone: 'Threshold',
          label: 'z4',
          emoji: '🐗'
        },
        {
          min: 1.05,
          max: 1.2,
          minDur: 15,
          color: '#ffa75c',
          zone: 'Vo2max',
          label: 'z5',
          emoji: '🐺'
        },
        {
          min: 1.2,
          max: 1.5,
          minDur: 15,
          color: '#ff7e75',
          zone: 'Anaerobic',
          label: 'z6',
          emoji: '🐆'
        },
        {
          min: 1.5,
          max: 2.0,
          minDur: 10,
          color: '#ff7e75',
          zone: 'Sprint',
          label: 'z7',
          emoji: '🐯'
        },
        {
          min: 2.0,
          max: Infinity,
          minDur: 5,
          color: '#f06292',
          zone: 'Allout',
          label: 'z7',
          emoji: '🦁'
        }
      ];

      return zones.find(
        z =>
          avgPower >= ftp * z.min &&
          avgPower < ftp * z.max &&
          duration >= z.minDur
      );
    }

    // 하나의 인터벌 종료 후 strip 및 버블 요소 생성
    function processInterval() {
      const startKey = findKeyByValue(distanceArray, intervalStart);
      const endKey = findKeyByValue(distanceArray, intervalEnd);
      const isRide = rideType === 'Ride';

      const sKey = isRide ? startKey : intervalStartRest;
      const eKey = isRide ? endKey : intervalEndRest;

      const usage = awcArray[sKey] - awcArray[eKey]; // AWC 소비량
      const duration = eKey - sKey; // 시간 또는 인덱스 길이
      const powers = wattsArray.slice(sKey, eKey).filter(v => v > 0); // 유효 파워만
      const avgPower = Math.round(_.sum(powers) / powers.length); // 평균 파워

      const zone = calcZone(avgPower, duration); // zone 판별
      if (!zone) return; // 유효하지 않으면 무시

      const isIntentional = duration >= 15 && usage > 30.0; //의도적인 인터벌 15초 이상 && usage

      // 구간 스트립 추가
      strips.push(
        {
          type: 'range',
          name: t('Common.' + zone.label),
          showOnTop: true,
          startValue: intervalStart,
          endValue: intervalEnd,
          duration,
          color: zone.color,
          opacity: 0.2,
          power: avgPower,
          usage,
          zoneAnimal: zone.emoji,
          Label: zone.label,
          zone: zone.zone,
          intentional: isIntentional
        },
        {
          type: 'dash',
          value: intervalStart,
          color: zone.color,
          lineDashType: 'dash'
        },
        {
          type: 'dash',
          value: intervalEnd,
          color: zone.color,
          lineDashType: 'dash'
        }
      );

      // 버블 차트용 정보 추가
      bubbles.push({
        x: Math.round((intervalStart + intervalEnd) / 2),
        y: avgPower + 160,
        z: usage > 10 ? usage : 5,
        id: intervalStart,
        name: avgPower,
        color: zone.color,
        indexLabel: zone.label,
        zone: zone.zone,
        intentional: isIntentional
      });
    }

    // 전체 파워 포인트 반복
    _.each(wattsPoints, function(point, i) {
      // MA 범위 밖이면 계산 안 함
      if (i < maHalf || i >= wattsArray.length - maHalf) return;

      const xKey = point.x - 1; // 1초 이전 위치 (시작위치 대략 맞추는 목적)
      const thisAvg = Math.round(movingSum / maWindow); // 현재 MA 평균
      const thisPower = wattsArray[index]; // 현재 파워
      let color = 'transparent',
        zone = '';

      // MA 평균 파워가 FTP 75% 이하일 때 → 인터벌 종료 조건
      if (thisAvg <= activityFTP * 0.75) {
        if (intervalOn) {
          intervalEnd = xKey - 2; //이미 파워는 평균을 깎아먹고 있으므로 감안하여 3초(원래 1초) 이전에 끝났다고 추정
          intervalEndRest = i;
          processInterval(); // 인터벌 처리
          intervalOn = false;
        }
      }
      // 평균 파워가 FTP 95% 초과 → 인터벌 진행 중
      else if (thisAvg > activityFTP * 0.95) {
        if (!intervalOn) {
          intervalStart = xKey;
          intervalStartRest = i;
          intervalOn = true;
        }

        // 현재 zone에 따라 색상 지정
        if (thisAvg < activityFTP * 1.05) {
          color = '#ffd357';
          zone = 'Threshold';
        } else if (thisAvg < activityFTP * 1.2) {
          color = '#ffa75c';
          zone = 'Vo2max';
        } else if (thisAvg < activityFTP * 1.5) {
          color = '#ff7e75';
          zone = 'Anaerobic';
        } else {
          color = '#f06292';
          zone = 'Sprint';
        }

        // Threshold 이상일 때만 막대 그래프용 포인트 저장
        movingPoints.push({
          x: point.x,
          y: thisPower,
          color,
          zone,
          id: intervalStart
        });
      }

      // 다음 이동 평균을 위해 슬라이딩 윈도우 갱신
      if (i + maHalf + 1 < wattsArray.length) {
        movingSum =
          movingSum - wattsArray[i - maHalf] + wattsArray[i + maHalf + 1];
      }

      index++;
    });

    // 기본값 추가 (차트 오류 방지용)
    bubbles.push({ x: 0, y: 0, z: 0, color: 'transparent' });
    sortJSON(strips, 'power', 'asc'); // 강도 순으로 정렬

    // 🎯 추가: 의도적인 인터벌 필터링 + ftpCheck 판단
    const intentionalIntervals = strips
      .filter(s => s.type === 'range' && s.intentional)
      .map(s => ({
        start: s.startValue,
        end: s.endValue,
        duration: s.duration,
        avgPower: s.power,
        usage: s.usage,
        zone: s.zone,
        color: s.color,
        zoneAnimal: s.zoneAnimal
      }));

    const sortedIntentional = intentionalIntervals.sort(
      (a, b) => b.usage - a.usage
    );

    const ftpCheck = sortedIntentional.some(
      i => i.duration >= 240 && i.usage >= awcBaseline * 0.7
    );

    return {
      moving10Points: movingPoints,
      stripLineArray: strips,
      bubbleArray: bubbles,
      intentionalInterval: sortedIntentional.length > 0,
      intentionalIntervalList: sortedIntentional,
      ftpCheck
    };
  }

  function ArrangeHrZone(heartrateArray, heartratePoints, zoneType) {
    let hrZoneArr = [];
    let hrZonePoints = [];

    let z1_sum = 0;
    let z2_sum = 0;
    let z3_sum = 0;
    let z4_sum = 0;
    let z5_sum = 0;

    //zoneType 마다 다르게
    if (zoneType === 0) {
      //			console.log(maxHr);

      _.each(heartratePoints, function(point, index) {
        let color = '#f5f5f5';
        let zone = 0;

        if (point.y > 0 && point.y <= maxHr * 0.6) {
          color = '#cccccc';
          zone = 1;
          z1_sum++;
        } else if (point.y > maxHr * 0.6 && point.y <= maxHr * 0.7) {
          color = '#76b5e8';
          zone = 2;
          z2_sum++;
        } else if (point.y > maxHr * 0.7 && point.y <= maxHr * 0.8) {
          color = '#b0d36d';
          zone = 3;
          z3_sum++;
        } else if (point.y > maxHr * 0.8 && point.y <= maxHr * 0.9) {
          color = '#ffd357';
          zone = 4;
          z4_sum++;
        } else if (point.y > maxHr * 0.9) {
          color = '#ffa75c';
          zone = 5;
          z5_sum++;
        }

        hrZonePoints.push({ x: point.x, y: point.y, color: color, zone: zone });
      });
    }

    let total_sum = 1 + (z1_sum + z2_sum + z3_sum + z4_sum + z5_sum);

    let z1p = Math.round((1000 * z1_sum) / total_sum) / 10;
    let z2p = Math.round((1000 * z2_sum) / total_sum) / 10;
    let z3p = Math.round((1000 * z3_sum) / total_sum) / 10;
    let z4p = Math.round((1000 * z4_sum) / total_sum) / 10;
    let z5p = Math.round((1000 * z5_sum) / total_sum) / 10;

    let zone_sum_arr = Array(z5_sum, z4_sum, z3_sum, z2_sum, z1_sum);
    let zone_per_arr = Array(z5p, z4p, z3p, z2p, z1p);

    //		console.log(total_sum);

    _.each(zone_per_arr, function(val, index) {
      let color = '#ffffff';

      if (5 - index === 1) {
        color = '#cccccc';
      } else if (5 - index === 2) {
        color = '#76b5e8';
      } else if (5 - index === 3) {
        color = '#b0d36d';
      } else if (5 - index === 4) {
        color = '#ffd357';
      } else if (5 - index === 5) {
        color = '#ffa75c';
      }

      hrZoneArr.push({ y: val, label: 'ZONE ' + (5 - index), color: color });
    });

    return { hrZoneArr: hrZoneArr, hrZonePoints: hrZonePoints };
  }
};

IntervalChart.propTypes = {
  className: PropTypes.string
};

export default withRouter(IntervalChart);
