import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import i18n from 'i18n';
import { MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { DynamicFontTypography } from 'components/Typography';

import axios from 'axios';
import auth from 'utils/auth';

const languageMap = {
  en: { label: 'English', flag: 'gb' },
  ko: { label: '한국어', flag: 'kr' },
  zhCn: { label: '简体中文', flag: 'cn' },
  zhHk: { label: '繁体中文', flag: 'hk' },
  es: { label: 'Español', flag: 'es' },
  jp: { label: '日本語', flag: 'jp' }
};

export default function LanguageDialog() {
  const [currentLang, setCurrentLang] = useState(i18n.language || 'en');
  const { t } = useTranslation('landing');

  console.log(i18n.language);

  const handleChange = event => {
    const selectedLang = event.target.value;
    i18n.changeLanguage(selectedLang);
    setCurrentLang(selectedLang);

    // TODO: Send selectedLang to backend when API is ready
    if (auth.getToken()) {
      const auth_str = 'Bearer '.concat(auth.getToken().token);

      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/json-api/set-extrainfo.php`,
          {
            order: 'language_update',
            language: selectedLang
          },
          {
            headers: { Authorization: auth_str }
          }
        )
        .then(response => {
          if (response.data.success) {
            return (
              <React.Fragment>
                <Redirect from="/" to="/dashboard" />
              </React.Fragment>
            );
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  return (
    <FormControl sx={{ maxWidth: 120, minWidth: 100, mt: 2 }} size="small">
      <InputLabel margin="dense">
        <DynamicFontTypography sx={{ fontSize: '12px' }}>
          {t('Footer.language')}
        </DynamicFontTypography>
      </InputLabel>
      <Select
        labelId="select-language-menu-label"
        id="select-language-menu"
        value={currentLang}
        label="Language"
        onChange={handleChange}>
        {Object.entries(languageMap).map(([code, { label }]) => (
          <MenuItem key={code} value={code}>
            <DynamicFontTypography sx={{ fontSize: '12px' }}>
              {label}
            </DynamicFontTypography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
