export const meterPerSecondToMilePerHour = (meterPerSecond) => {
  return 2.236936 * meterPerSecond;
};

export const kiloPerHourToMilePerHour = (kiloPerHour) => {
  return kiloPerHour * 0.621371;
};

export const kilometerToMile = (kilometer) => {
  return 0.62137 * kilometer;
};

export const meterToFeet = (meter) => {
  return 3.280839895 * meter;
};

export const celciusToFahrenheit = (celcius) => {
  return (celcius * 9) / 5 + 32;
};

export const wattsToWPerKg = (watts, weight) => {
  return Math.round((watts / weight) * 10) / 10;
};

export const secondsToHms = (time) => {
  let d = Number(time);
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);

  if (h > 0) {
    let hd = h > 0 ? h + 'h ' : '';
    let md = m > 0 ? m + 'm' : '';

    return hd + md;
  } else if (h <= 0) {
    if (m === 0 && s === 0) {
      return '0s';
    }

    let md = m > 0 ? m + 'm ' : '';
    let sd = s > 0 ? s + 's' : '';

    return md + sd;
  }
};
