import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';

export default function DeleteModal({
  openState,
  setOpen,
  contentText,
  handleDeleteSession
}) {
  const { t } = useTranslation(['page']);

  return (
    <>
      {openState && (
        <Box>
          <Dialog
            open={openState}
            // onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <>
              <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  text="body1"
                  color="textPrimary">
                  {contentText}
                </DialogContentText>
                <DialogContentText
                  id="alert-dialog-description"
                  text="small"
                  color="textSecondary"></DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => setOpen(false)}
                  size="medium"
                  state="enabled"
                  variant="text"
                  color="primary">
                  {t('Notice.cancel')}
                </Button>
                <Button
                  onClick={handleDeleteSession}
                  size="medium"
                  state="enabled"
                  variant="text"
                  color="secondary">
                  {t('Notice.delete')}
                </Button>
              </DialogActions>
            </>
          </Dialog>
        </Box>
      )}
    </>
  );
}
