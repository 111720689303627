import React from 'react';
import { Stack, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Logo from '../Logo';
import Menu from '../Menu';
import { MobileMenu } from '../MobileMenu';
import { SocialMediaHandleBar } from 'components/Header';
import { LoginButton } from 'components/Common';

const headerHeight = 60;

// deleted many lines for initial integration -- must compare with original file
export default function MobileMenuLayout({ isOpen, setIsOpen }) {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Box
        role="banner"
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: `${headerHeight}px`,
          px: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: theme.zIndex.appBar,
          backgroundColor: theme.palette.background.paper,
          boxShadow: isOpen ? 'none' : theme.boxShadow.secondary
        }}>
        <Logo />
        <MobileMenu isOpen={isOpen} setIsOpen={setIsOpen} />
      </Box>

      {isOpen && (
        <Box
          key="mobile-menu"
          sx={{
            marginTop: `${headerHeight}px`,
            overflow: 'hidden',
            width: '100%'
          }}>
          <Stack
            gap={4}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              backgroundColor: theme.palette.background.paper,
              boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.1)',
              borderBottom: isOpen
                ? 'none'
                : `1px solid ${theme.palette.grey[300]}`
            }}>
            <Menu isOpen={isOpen} setIsOpen={setIsOpen} />
            <Box margin="0 auto">
              <LoginButton />
            </Box>
            <SocialMediaHandleBar isOpen={isOpen} />
          </Stack>
        </Box>
      )}
    </React.Fragment>
  );
}
