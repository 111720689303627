import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import auth from '../../utils/auth';
import { Box, Typography } from '@mui/material';

import { default as AnalyzerTab } from './AnalyzerTab.js';

const useStyles = makeStyles(theme => ({
  root: {
    //    padding: theme.spacing(2)
  },
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    fontWeight: '500',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  }
}));

const Analyzer = () => {
  const classes = useStyles();

  //다국어적용
  const { t } = useTranslation(['page']);

  //const [popoverOpenInvite, setPopoverOpenInvite] = React.useState(false);
  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <Box className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          {t('SideBar.simulator')}
        </Typography>
      </Box>
      <div className={classes.whiteLine}></div>
      <AnalyzerTab />
    </div>
  );
};

export default Analyzer;
