import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  Switch,
  Slider
} from '@mui/material';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  textBold: {
    fontSize: '14px',
    margin: '0 4px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 3px 0 3px'
    }
  },
  ftpTypeSwitchWrap: {
    margin: '12px auto 0',
    fontSize: '13px'
  },
  ftpSetBox: {
    marginBottom: '8px',
    fontSize: '12px',
    textAlign: 'center'
  },
  spaceAround: {
    padding: '0px 0px 16px 0px',
    justifyContent: 'space-around'
  }
}));

const FtpTypeSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    margin: '4px',
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  checked: {}
}))(Switch);

const PdSlider = withStyles({
  root: {
    color: '#8cc1ec'
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -4,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  mark: {
    backgroundColor: '#ffffff',
    height: 8,
    width: 1,
    marginTop: 0
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

const FTPOptions = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const {
    extraInfo,
    auth,
    ftpType,
    ftp,
    tte,
    wftp,
    wtte,
    updateOptions
  } = props;

  //	const [ updateAvail, setUpdateAvail ] = useState(Boolean(extraInfo.updateAvail));
  const [ftpTypeValue, setFtpTypeValue] = React.useState(ftpType);
  const [customFTP, setCustomFTP] = useState(Math.round(ftp));
  const [customTTE, setCustomTTE] = useState(Math.round(tte));

  const [ftpSetDialog, setFtpSetDialog] = useState(false);
  const stereoCurve = JSON.parse(extraInfo.pdc_json).stereo_all;

  // console.log(stereoCurve);

  const classes = useStyles();

  const tteArray = [
    {
      value: 20,
      label: '20' + t('Common.min')
    },
    {
      value: 40,
      label: '40' + t('Common.min')
    },
    {
      value: 60,
      label: '60' + t('Common.min')
    },
    {
      value: 80,
      label: '80' + t('Common.min')
    }
  ];

  function TteFtpCalc(props) {
    let tte_v = props.tte;
    let ftp_v = props.ftp;
    let stereoCurve = props.stereoCurve;

    let preSec = 0;
    let nextSec = 0;
    let secArr = [1200, 1800, 2400, 3600, 7200];

    for (let i = 0; i < 5; i++) {
      if (tte_v * 60 >= secArr[i - 1] && tte_v * 60 <= secArr[i]) {
        preSec = secArr[i - 1];
        nextSec = secArr[i];
        break;
      }
    }

    let rate = (tte_v * 60 - preSec) / (nextSec - preSec);

    ftp_v =
      stereoCurve[preSec] + (stereoCurve[nextSec] - stereoCurve[preSec]) * rate;

    setCustomFTP(ftp_v);
    setCustomTTE(tte_v);

    return (
      <Box className={classes.ftpSetBox}>
        FTP{' '}
        <Typography
          className={classes.textBold}
          color="secondary"
          component="span">
          {Math.round(ftp_v)}
        </Typography>
        W / TTE{' '}
        <Typography
          className={classes.textBold}
          color="secondary"
          component="span">
          {Math.round(tte_v)}
        </Typography>
        분
      </Box>
    );
  }

  function updateFtpSet() {
    const auth_str = 'Bearer '.concat(auth.getToken().token);
    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
        {
          order: 'update_ftp_set',
          custom_ftp: customFTP,
          custom_tte: customTTE,
          ftp_type: ftpTypeValue
        },
        {
          headers: { Authorization: auth_str }
        }
      )
      .then(response => {
        if (response.data.success === true) {
          updateOptions('on');
          //				setUpdateAvail(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleFtpSetDialogOpen = () => {
    setFtpSetDialog(true);
  };

  const handleFtpSetDialogClose = () => {
    setFtpSetDialog(false);
  };

  const handleFtpTypeValue = event => {
    let checked = event.target.checked;

    if (checked) {
      setFtpTypeValue('wftp');
    } else {
      setFtpTypeValue('cftp');
    }
  };

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Typography
          component="div"
          variant="caption"
          align="center"
          style={{ margin: '12px 8px 0px 8px' }}>
          {t('Options.pleaseAutoFtp')}
        </Typography>

        <CardContent>
          <Typography component="div" className={classes.ftpTypeSwitchWrap}>
            <Grid
              component="label"
              container
              style={{ width: '132px', margin: '0 auto' }}>
              <Grid item>{t('Options.manual')}</Grid>
              <Grid item>
                <FtpTypeSwitch
                  checked={ftpTypeValue === 'wftp'}
                  onChange={handleFtpTypeValue}
                  name="ftpType"
                />
              </Grid>
              <Grid item>{t('Options.auto')}</Grid>
            </Grid>
          </Typography>

          <Box style={{ width: '180px', margin: '16px auto 0 auto' }}>
            {ftpTypeValue === 'cftp' ? (
              <React.Fragment>
                <TteFtpCalc
                  tte={customTTE}
                  ftp={customFTP}
                  stereoCurve={stereoCurve}
                />
                <PdSlider
                  color="primary"
                  defaultValue={Math.round(tte)}
                  min={20}
                  step={1}
                  max={80}
                  marks={tteArray}
                  onChange={(event, customTTE) =>
                    setCustomTTE(Math.round(customTTE))
                  }
                  valueLabelDisplay="off"
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Box className={classes.ftpSetBox}>
                  FTP{' '}
                  <Typography
                    className={classes.textBold}
                    color="secondary"
                    component="span">
                    {Math.round(wftp)}
                  </Typography>
                  W / TTE{' '}
                  <Typography
                    className={classes.textBold}
                    color="secondary"
                    component="span">
                    {Math.round(wtte)}
                  </Typography>
                  분
                </Box>
              </React.Fragment>
            )}
          </Box>
        </CardContent>

        <CardActions className={classes.spaceAround}>
          <Button
            className={classes.setButton}
            onClick={updateFtpSet}
            variant="contained"
            color="primary">
            {t('Options.modify')}
          </Button>
        </CardActions>

        <Dialog open={ftpSetDialog} onClose={handleFtpSetDialogClose}>
          <DialogTitle style={{ cursor: 'move' }}>
            {t('Options.alertManual')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography component="pre">
                {t('Options.alertManualDesc')}
              </Typography>
              <Typography>{t('Options.alertManualConfirm')}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleFtpSetDialogClose}
              disabled={false /*!updateAvail*/}>
              {t('Options.confirm')}
            </Button>
            <Button onClick={handleFtpSetDialogClose}>
              {t('Options.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </React.Fragment>
  );
};

FTPOptions.propTypes = {
  className: PropTypes.string
};

export default FTPOptions;
