import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import {
  Modal,
  Box,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  TextField,
  Card,
  CardContent,
  CardMedia,
  Divider,
  MenuItem,
  Checkbox,
  Radio,
  RadioGroup,
  FormGroup,
  FormControl,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';

//import Strava from './Strava';
import _ from 'lodash';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import auth from '../../utils/auth';
import itemList from '../../utils/SettingOptionItems.js';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1)
  },
  backButton: {
    marginRight: theme.spacing(1)
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center'
  },
  stepper: {
    padding: theme.spacing(3, 1)
  },
  paper: {
    width: '95%',
    height: '95%',
    maxWidth: 600,
    maxHeight: 800,
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 2),
    textAlign: 'center'
  },
  card: {
    width: '100%'
  },
  cardContent: {
    padding: theme.spacing(3, 1, 1),
    lineHeight: '70px'
  },
  cardContentTitle: {
    marginBottom: 20
  },
  cardContentBox: {
    border: '1px #dddddd solid',
    borderRadius: '4px',
    margin: theme.spacing(2, 1, 2, 1),
    padding: theme.spacing(2, 1, 1, 1),
    textAlign: 'center'
  },
  divider: {
    background: '#dddddd',
    margin: '12px 0 8px 0'
  },
  descDiv: {
    margin: '5px'
  },
  selectBox: {
    width: 100
  },
  media: {
    backgroundSize: 'auto 100%',
    height: 150,
    maxHeight: 200
  },
  desc: {
    maxWidth: 600,
    fontWeight: 300,
    lineHeight: '25px',
    color: '#ff9900',
    margin: '10px auto 0 auto',
    padding: theme.spacing(1, 1, 1),
    backgroundColor: '#f5f5f5',
    borderRadius: '4px'
  },
  button: {
    margin: theme.spacing(2)
  },
  back: {
    background: '#eeeeee'
  },
  formControl: {},
  group: {
    width: 300,
    margin: '12px auto'
  }
}));

const Procedure = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  function getSteps() {
    return [
      t('Procedure.tab1'),
      t('Procedure.tab2'),
      t('Procedure.tab3'),
      t('Procedure.tab4')
    ];
  }

  const classes = useStyles();
  const userInfo = auth.getUserInfo();

  const [Rider, setRider] = useState({
    height: 170,
    weight: 60,
    nation: '',
    gender: 'M',
    year: 1980,
    bikeType: [],
    ridingYear: 2010
  });

  const [BikeCheck, setBikeCheck] = React.useState({
    road: false,
    mtb: false,
    sharebike: false,
    minivelo: false,
    electric: false,
    hybrid: false
  });

  /*
	const [RidingCheck, setRidingCheck] = React.useState({
    commute: false,
    solo: false,
    group: false,
    indoor: false,
    job: false,
    hardly: false,
	});
*/
  const [RidingType, setRidingType] = React.useState('');
  const [PurposeCheck, setPurposeCheck] = React.useState({
    fitness: false,
    challenge: false,
    travel: false,
    compete: false,
    social: false
  });

  //	const [strava, setStrava] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(-1);
  const [nextStep, setNextStep] = React.useState(true);
  const [awaiters, setAwaiters] = React.useState(false);
  const [backButtonText, setBackButtonText] = React.useState(
    t('Procedure.back')
  );
  const [signOutDialog, setSignOutDialog] = React.useState(false);
  const steps = getSteps();

  //핸들링 함수
  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
    setNextStep(false);
  };

  const handleBack = () => {
    if (activeStep === -1) {
      setSignOutDialog(true);
    } else {
      setActiveStep(prevActiveStep => prevActiveStep - 1);
      setNextStep(true);
    }
  };

  const handleReset = () => {
    setActiveStep(-1);
  };

  const handleChangeRider = prop => event => {
    setRider({ ...Rider, [prop]: event.target.value });
  };

  const handleChangeRidingType = event => {
    setRidingType(event.target.value);
  };

  const handleOpenSignOutDialog = () => {
    setSignOutDialog(true);
  };

  const handleCloseSignOutDialog = () => {
    setSignOutDialog(false);
  };

  //next button avtivation 체크
  useEffect(() => {
    /*
		axios.get(
			`${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-awaiter.php`, 
			{
				params: {
					order: 'init'
				}
			}
		).then(function (response) {
			if (response) {
				let res = response.data;

				if(res.awaiters < 100) { 	
					setAwaiters(false);
				} else {
					setAwaiters(true);
				}
			}
		}).catch((err) => {
			console.log(err);
		});
*/
    setBackButtonText(t('Procedure.back'));

    if (activeStep === -1) {
      setBackButtonText(t('Procedure.cancel'));
      setNextStep(true);
    } else if (activeStep === 0) {
      if (
        Rider.height > 0 &&
        Rider.weight > 0 &&
        Rider.gender &&
        Rider.year > 0
      ) {
        setNextStep(true);
      } else {
        setNextStep(false);
      }
    } else if (activeStep === 1) {
      let { road, mtb, sharebike, minivelo, electric, hybrid } = BikeCheck;
      let checkedBike = [
        road,
        mtb,
        sharebike,
        minivelo,
        electric,
        hybrid
      ].filter(v => v).length;

      if (Rider.ridingYear !== '' && checkedBike > 0) {
        setNextStep(true);
      } else {
        setNextStep(false);
      }
    } else if (activeStep === 2) {
      let { fitness, challenge, travel, compete, social } = PurposeCheck;
      let checkedPurpose = [fitness, challenge, travel, compete, social].filter(
        v => v
      ).length;

      if (RidingType !== '' && checkedPurpose > 0) {
        setNextStep(true);
      } else {
        setNextStep(false);
      }
    }
  });

  //2개까지 체크
  const handleChangeBikeCheck = type => event => {
    let { road, mtb, sharebike, minivelo, electric, hybrid } = BikeCheck;
    let checked2 = [road, mtb, sharebike, minivelo, electric, hybrid].filter(
      v => v
    ).length;

    if (checked2 < 2) {
      setBikeCheck({ ...BikeCheck, [type]: event.target.checked });
    } else if (checked2 == 2 && event.target.checked === false) {
      setBikeCheck({ ...BikeCheck, [type]: event.target.checked });
    }
  };

  const handleChangePurposeCheck = type => event => {
    let { fitness, challenge, compete, travel, social } = PurposeCheck;
    let checked2 = [fitness, challenge, compete, travel, social].filter(v => v)
      .length;

    if (checked2 < 2) {
      setPurposeCheck({ ...PurposeCheck, [type]: event.target.checked });
    } else if (checked2 == 2 && event.target.checked === false) {
      setPurposeCheck({ ...PurposeCheck, [type]: event.target.checked });
      setNextStep(true);
    }
  };

  //Range menuitem 함수들
  const heightRange = () => {
    let range = [];
    for (var i = 140; i <= 220; i++) {
      range.push(
        <MenuItem key={i} value={i}>
          {i}cm
        </MenuItem>
      );
    }
    return range;
  };

  const weightRange = () => {
    let range = [];
    for (var i = 20; i <= 160; i++) {
      range.push(
        <MenuItem key={i} value={i}>
          {i}kg
        </MenuItem>
      );
    }
    return range;
  };

  const genderRange = () => {
    let range = [];
    range.push(
      <MenuItem key={0} value={'M'}>
        {t('Procedure.male')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={1} value={'F'}>
        {t('Procedure.female')}
      </MenuItem>
    );
    return range;
  };

  //국가 리스트 함수
  const NationRange = () => {
    let range = [];

    _.each(itemList.countryList, function(item, id) {
      range.push(
        <MenuItem key={id} value={id}>
          {item}
        </MenuItem>
      );
    });

    return range;
  };

  const yearRange = () => {
    /*
		let range = [];
		for (var i=1940; i <= 2021; i++) {
			range.push(<MenuItem key={i} value={i}>{i}{t('Procedure.year')}</MenuItem>);
		}
		return range;
*/
    let range = [];
    let d = new Date();
    let this_year = d.getFullYear();

    for (let i = 1920; i <= this_year - 7; i++) {
      range.push(
        <MenuItem key={i} value={i}>
          {i}
          {t('Procedure.year')}
        </MenuItem>
      );
    }
    return range;
  };

  const ridingYearRange = () => {
    let range = [];
    let d = new Date();
    let this_year = d.getFullYear();

    for (var i = 1920; i <= this_year; i++) {
      range.push(
        <MenuItem key={i} value={i}>
          {i}
          {t('Procedure.year')}
        </MenuItem>
      );
    }
    return range;
  };

  const bikeTypeRange = () => {
    let range = [];
    range.push(
      <MenuItem key={0} value={'road_allround'}>
        로드(올라운드)
      </MenuItem>
    );
    range.push(
      <MenuItem key={1} value={'road_aero'}>
        로드(에어로)
      </MenuItem>
    );
    range.push(
      <MenuItem key={2} value={'road_endurance'}>
        로드(엔듀런스)
      </MenuItem>
    );
    return range;
  };

  const modalOpener = props => {
    if (userInfo.user_service == 1) return true;
    else return false;
  };

  //extrainfo 등록 및 스트라바 이동
  const handleFinishProcedure = props => event => {
    let bikes = [];
    let purposes = [];

    for (let [key, value] of Object.entries(BikeCheck)) {
      if (value) bikes.push(key);
    }

    for (let [key, value] of Object.entries(PurposeCheck)) {
      if (value) purposes.push(key);
    }

    const auth_str = 'Bearer '.concat(auth.getToken().token);
    const params = {
      order: 'register',
      height: Rider.height,
      weight: Rider.weight,
      nation: Rider.nation,
      gender: Rider.gender,
      year: Rider.year,
      year: Rider.year,
      riding_year: Rider.ridingYear,
      riding_type: RidingType,
      bikes: bikes.join(','),
      purposes: purposes.join(',')
    };

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
        params,
        { headers: { Authorization: auth_str } }
      )
      .then(response => {
        if (response.data.success) {
          auth.clearExtraInfo();
          window.location.reload();
          /*
				let client_id = 39781;

console.log(response.data.user_id);

				if(response.data.user_id == 2) {
					client_id = 18928;	
				} 

//				let URL = "https://www.strava.com/oauth/authorize?client_id=" + client_id + "&response_type=code&redirect_uri=https://" + window.location.hostname + "/strava/?approval_prompt=force&scope=activity:read_all,activity:write";
//				window.location = URL; 

//"https://www.strava.com/oauth/authorize?client_id=39781&response_type=code&redirect_uri=https:///strava/?approval_prompt=force&scope=activity:read_all,activity:write";
//				window.location.reload();		
*/
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const rootRef = React.useRef(null);

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return (
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                variant="h4"
                component="h2"
                className={classes.cardContentTitle}>
                {t('Procedure.inputTab1')}
              </Typography>
              <div>
                <TextField
                  className={classes.selectBox}
                  variant="outlined"
                  select
                  label={t('Procedure.height')}
                  value={Rider.height}
                  onChange={handleChangeRider('height')}>
                  {heightRange()}
                </TextField>
              </div>
              <div>
                <TextField
                  className={classes.selectBox}
                  variant="outlined"
                  select
                  label={t('Procedure.weight')}
                  value={Rider.weight}
                  onChange={handleChangeRider('weight')}>
                  {weightRange()}
                </TextField>
              </div>
              {/*
								<div>							
									<TextField className={classes.selectBox}
										variant="outlined"
										select
										label="국적"
										value={Rider.nation}
										onChange={handleChangeRider('nation')}
									>
									{nationRange()}
									</TextField>
								</div>
*/}
              <div>
                <TextField
                  className={classes.selectBox}
                  variant="outlined"
                  select
                  label={t('Procedure.gender')}
                  value={Rider.gender}
                  onChange={handleChangeRider('gender')}>
                  {genderRange()}
                </TextField>
              </div>
              <div>
                <TextField
                  className={classes.selectBox}
                  variant="outlined"
                  select
                  label={t('Procedure.birthYear')}
                  value={Rider.year}
                  onChange={handleChangeRider('year')}>
                  {yearRange()}
                </TextField>
              </div>
              <div>
                <TextField
                  className={classes.selectBox}
                  variant="outlined"
                  select
                  label={t('Procedure.nation')}
                  value={Rider.nation}
                  onChange={handleChangeRider('nation')}>
                  {NationRange()}
                </TextField>
              </div>
            </CardContent>
          </Card>
        );
      case 1:
        return (
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography
                gutterBottom
                variant="h4"
                component="h2"
                className={classes.cardContentTitle}>
                {t('Procedure.inputTab2')}
              </Typography>
              <div>
                <TextField
                  className={classes.selectBox}
                  variant="outlined"
                  select
                  label={t('Procedure.ridingYear')}
                  value={Rider.ridingYear}
                  onChange={handleChangeRider('ridingYear')}>
                  {ridingYearRange()}
                </TextField>
              </div>
              <div>
                <Box className={classes.cardContentBox}>
                  <Typography gutterBottom variant="h6" component="h2">
                    {t('Procedure.bikeType')}
                  </Typography>
                  <FormGroup row className={classes.group}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={BikeCheck.road}
                          onChange={handleChangeBikeCheck('road')}
                          value="road"
                        />
                      }
                      label={t('Procedure.road')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={BikeCheck.mtb}
                          onChange={handleChangeBikeCheck('mtb')}
                          value="mtb"
                        />
                      }
                      label={t('Procedure.mtb')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={BikeCheck.sharebike}
                          onChange={handleChangeBikeCheck('sharebike')}
                          value="sharebike"
                        />
                      }
                      label={t('Procedure.sharebike')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={BikeCheck.electric}
                          onChange={handleChangeBikeCheck('electric')}
                          value="ebike"
                        />
                      }
                      label={t('Procedure.ebike')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={BikeCheck.minivelo}
                          onChange={handleChangeBikeCheck('minivelo')}
                          value="minivelo"
                        />
                      }
                      label={t('Procedure.minivelo')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={BikeCheck.hybrid}
                          onChange={handleChangeBikeCheck('hybrid')}
                          value="hybrid"
                        />
                      }
                      label={t('Procedure.hybrid')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={BikeCheck.etc}
                          onChange={handleChangeBikeCheck('etc')}
                          value="etc"
                        />
                      }
                      label={t('Procedure.etc')}
                    />
                  </FormGroup>
                </Box>
              </div>
            </CardContent>
          </Card>
        );

      case 2:
        return (
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div>
                <Typography gutterBottom variant="h4" component="h1">
                  {t('Procedure.inputTab3')}
                </Typography>
              </div>
              <div>
                <Box className={classes.cardContentBox}>
                  <Typography gutterBottom variant="h6" component="h2">
                    {t('Procedure.rideWhat')}
                  </Typography>
                  <FormControl
                    component="fieldset"
                    className={classes.formControl}>
                    <RadioGroup
                      aria-label="BikeCheck"
                      name="BikeCheck"
                      className={classes.group}
                      value={RidingType}
                      onChange={handleChangeRidingType}>
                      <FormControlLabel
                        value="commute"
                        control={<Radio />}
                        label={t('Procedure.commute')}
                      />
                      <FormControlLabel
                        value="solo"
                        control={<Radio />}
                        label={t('Procedure.solo')}
                      />
                      <FormControlLabel
                        value="group"
                        control={<Radio />}
                        label={t('Procedure.group')}
                      />
                      <FormControlLabel
                        value="indoor"
                        control={<Radio />}
                        label={t('Procedure.indoor')}
                      />
                      <FormControlLabel
                        value="job"
                        control={<Radio />}
                        label={t('Procedure.job')}
                      />
                      <FormControlLabel
                        value="hardly"
                        control={<Radio />}
                        label={t('Procedure.hardly')}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </div>
              <div>
                <Box className={classes.cardContentBox}>
                  <Typography gutterBottom variant="h6" component="h2">
                    {t('Procedure.rideWhy')}
                  </Typography>
                  <FormGroup className={classes.group}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={PurposeCheck.fitness}
                          onChange={handleChangePurposeCheck('fitness')}
                          value="fitness"
                        />
                      }
                      label={t('Procedure.fitness')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={PurposeCheck.challenge}
                          onChange={handleChangePurposeCheck('challenge')}
                          value="challenge"
                        />
                      }
                      label={t('Procedure.challenge')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={PurposeCheck.travel}
                          onChange={handleChangePurposeCheck('travel')}
                          value="travel"
                        />
                      }
                      label={t('Procedure.travel')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={PurposeCheck.compete}
                          onChange={handleChangePurposeCheck('compete')}
                          value="compete"
                        />
                      }
                      label={t('Procedure.compete')}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={PurposeCheck.social}
                          onChange={handleChangePurposeCheck('social')}
                          value="social"
                        />
                      }
                      label={t('Procedure.social')}
                    />
                  </FormGroup>
                </Box>
              </div>
            </CardContent>
          </Card>
        );
      case 3:
        return (
          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <div>
                <Typography gutterBottom variant="h4" component="h1">
                  {t('Procedure.inputTab4')}
                </Typography>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  onClick={handleFinishProcedure(props)}>
                  {t('Procedure.gotoNext')}
                </Button>
              </div>
            </CardContent>
          </Card>
        );
      case 4:
        return (
          <div>
            <Box>{t('Procedure.finish')}</Box>
          </div>
        );

      default:
        return (
          <Card className={classes.card}>
            <CardMedia
              className={classes.media}
              image="/images/procedure/procedure.png"
              title="Riduck Procedure"
            />
            <CardContent>
              <Typography gutterBottom variant="h4" component="h1">
                {t('Procedure.pleaseAddInfo')}
              </Typography>
              <Box className={classes.cardContentBox}>
                <Typography color="primary" style={{ fontWeight: '500' }}>
                  {t('Procedure.usedAs')}
                </Typography>
                <Divider className={classes.divider} />
                <Typography component="pre" className={classes.descDiv}>
                  {t('Procedure.purposes')}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        );
    }
  };

  return (
    <div className={classes.root}>
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open={modalOpener(props)}
        aria-labelledby="server-modal-title"
        aria-describedby="server-modal-description"
        className={classes.modal}
        container={() => rootRef.current}>
        <div className={classes.paper}>
          {awaiters ? (
            <Box className={classes.instructions}>
              죄송합니다.<div></div>
              현재 대기자가 100명이 넘어 인증과정을 진행할 수 없습니다.
              <div></div>
              다음에 다시 시도해주시기 바랍니다.
            </Box>
          ) : (
            <div>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                className={classes.stepper}>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              {activeStep === steps.length - 1 ? (
                <div>
                  <Box className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </Box>
                  <div>
                    <Button variant="contained" onClick={handleReset} className={classes.button}>
                      {t('Procedure.gotoFirst')}
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <Box className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </Box>
                  <div>
                    <Button variant="contained" onClick={handleBack} className={classes.button}>
                      {backButtonText}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                      disabled={nextStep === false}>
                      {t('Procedure.next')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Modal>

      <Dialog
        open={signOutDialog}
        onClose={handleOpenSignOutDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {t('Procedure.logoutTitle')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Procedure.pleaseComplete')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Link to="/sign-out">
            <Button>{t('Procedure.doLogout')}</Button>
          </Link>
          <Button
            onClick={handleCloseSignOutDialog}
            color="primary"
            variant="contained"
            autoFocus>
            {t('Procedure.return')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Procedure;
