import React, { useState, useEffect } from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { RouteWithLayout } from './components';
import {
  Main as MainLayout,
  Minimal as MinimalLayout,
  User as UserLayout,
  Peloton as PelotonLayout
} from './layouts';
import axios from 'axios';
import auth from './utils/auth';

import {
  //	LoadData as LoadDataView,
  //  UserList as UserListView,
  //  Typography as TypographyView,
  //  Icons as IconsView,
  Intro as IntroView,
  Training as TrainingView,
  RideOn as RideOnView,
  Analyzer as AnalyzerView,
  Dashboard as DashboardView,
  FAQs as FAQsView,
  Landing as LandingView,
  SignUp as SignUpView,
  SignIn as SignInView,
  SignInRenew,
  SignUpRenew,
  SignOut as SignOutView,
  Settings as SettingsView,
  Strava as StravaView,
  Procedure as ProcedureView,
  Simulation as SimulationView,
  NotFound as NotFoundView,
  Maintenance as MaintenanceView,
  //
  Peloton as PelotonView,
  List as ListView,
  Create as CreatePelotonView,
  Edit as EditPelotonView,
  Club as ClubView,
  TabView,
  CreatePost as CreatePostView,
  EditPost as EditPostView,
  ReadPost as ReadPostView,
  CreateEvent as CreateEventView,
  ReadEvent as ReadEventView,
  PelotonMap as PelotonMapView
} from './views';

import {
  Activity as ActivityView,
  Activities as ActivitiesView,
  PMC as PMCView,
  PowerProfile as PowerProfileView,
  PowerZone as PowerZoneView,
  Metabolism as MetabolismView,
  HeartRate as HeartrateView,
  Phenotype as PhenotypeView,
  PersonalRecord as PersonalRecordView,
  Aerodynamics as AerodynamicsView
} from './views/Dashboard/Pages';
import { BlogView, ContentView, NewsView, TermsView } from 'pages';
import PrivacyView from 'pages/PrivacyView';

const ProcedureRoutes = [
  {
    path: '/procedure',
    component: ProcedureView,
    layout: MinimalLayout
  },
  {
    path: '/sign-out',
    component: SignOutView,
    layout: MinimalLayout
  },
  {
    path: '/faqs',
    component: FAQsView,
    layout: MainLayout
  }
];

const BasicRoutes = [
  {
    path: '/analyzer',
    component: AnalyzerView,
    layout: MainLayout
  },
  {
    path: '/dashboard',
    component: DashboardView,
    layout: MainLayout
  },
  {
    path: '/activity/:activityId',
    component: ActivityView,
    layout: MinimalLayout
  },
  {
    path: '/activities',
    component: ActivitiesView,
    layout: MainLayout
  },
  {
    path: '/pmc',
    component: PMCView,
    layout: MainLayout
  },
  {
    path: '/powerprofile',
    component: PowerProfileView,
    layout: MainLayout
  },
  {
    path: '/powerzone',
    component: PowerZoneView,
    layout: MainLayout
  },
  {
    path: '/metabolism',
    component: MetabolismView,
    layout: MainLayout
  },
  {
    path: '/training',
    component: TrainingView,
    layout: MainLayout
  },
  {
    path: '/settings',
    component: SettingsView,
    layout: MainLayout
  },
  {
    path: '/settings/:page',
    component: SettingsView,
    layout: MainLayout
  },
  {
    path: '/strava',
    component: StravaView,
    layout: MinimalLayout
  },
  {
    path: '/sign-out',
    component: SignOutView,
    layout: MinimalLayout
  },
  {
    path: '/heartrate',
    component: HeartrateView,
    layout: MainLayout
  },
  {
    path: '/news',
    component: NewsView,
    layout: MinimalLayout
  },
  {
    path: '/news/:id',
    component: ContentView,
    layout: MinimalLayout
  },
  {
    path: '/blog',
    component: BlogView,
    layout: MinimalLayout
  },
  {
    path: '/blog/:id',
    component: ContentView,
    layout: MinimalLayout
  }
  // 펠로톤 중단 (라우터 및 메뉴 주석처리)

  // {
  //   path: "/pelotons",
  //   component: ListView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/pelotons/create",
  //   component: CreatePelotonView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:pelotonId/edit",
  //   component: EditPelotonView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName",
  //   component: ClubView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/stream",
  //   component: ClubView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/event",
  //   component: ClubView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/member",
  //   component: ClubView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/post",
  //   component: ClubView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/ranking",
  //   component: ClubView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/post/create",
  //   component: CreatePostView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/post/:postId/edit",
  //   component: EditPostView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/post/:postId",
  //   component: ReadPostView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/event/create",
  //   component: CreateEventView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/event/:eventId/edit",
  //   component: CreateEventView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/peloton/:uriName/event/:eventId",
  //   component: ReadEventView,
  //   layout: MainLayout
  // },
  // {
  //   path: "/pelotonMap",
  //   component: PelotonMapView,
  //   layout: MainLayout
  // },
];

const SubscribeRoutes = [
  ...BasicRoutes,
  {
    path: '/ride-on',
    component: RideOnView,
    layout: MainLayout
  },
  {
    path: '/activity/:activityId',
    component: ActivityView,
    layout: MinimalLayout
  },
  {
    path: '/activities',
    component: ActivitiesView,
    layout: MainLayout
  },
  {
    path: '/powerprofile',
    component: PowerProfileView,
    layout: MainLayout
  },
  {
    path: '/powerzone',
    component: PowerZoneView,
    layout: MainLayout
  },
  {
    path: '/metabolism',
    component: MetabolismView,
    layout: MainLayout
  },
  {
    path: '/phenotype',
    component: PhenotypeView,
    layout: MainLayout
  },
  {
    path: '/pmc',
    component: PMCView,
    layout: MainLayout
  },
  {
    path: '/personal-record',
    component: PersonalRecordView,
    layout: MainLayout
  },
  {
    path: '/aerodynamics',
    component: AerodynamicsView,
    layout: MainLayout
  }
];

const guestRoutes = [
  {
    path: '/intro',
    component: IntroView,
    layout: MinimalLayout
  },
  {
    path: '/landing',
    component: LandingView,
    layout: MinimalLayout
  },
  {
    path: '/sign-in',
    component: SignInView,
    layout: MinimalLayout
  },
  {
    path: '/sign-in-renew',
    component: SignInRenew,
    layout: UserLayout
  },
  {
    path: '/sign-up-renew',
    component: SignUpRenew,
    layout: UserLayout
  },
  {
    path: '/sign-up',
    component: SignUpView,
    layout: MinimalLayout
  },
  {
    path: '/sign-out',
    component: SignOutView,
    layout: MinimalLayout
  },
  {
    path: '/news',
    component: NewsView,
    layout: MinimalLayout
  },
  {
    path: '/news/:id',
    component: ContentView,
    layout: MinimalLayout
  },
  {
    path: '/blog',
    component: BlogView,
    layout: MinimalLayout
  },
  {
    path: '/blog/:id',
    component: ContentView,
    layout: MinimalLayout
  },
  {
    path: '/activity/:activityId',
    component: ActivityView,
    layout: MinimalLayout
  },
  {
    path: '/faqs',
    component: FAQsView,
    layout: MainLayout
  },
  {
    path: '/terms',
    component: TermsView,
    layout: MinimalLayout
  },
  {
    path: '/privacy',
    component: PrivacyView,
    layout: MinimalLayout
  },
  {
    path: '/simulation',
    component: SimulationView,
    layout: MinimalLayout
  },
  {
    path: '/provider/facebook',
    component: SignInView,
    layout: MinimalLayout
  },
  {
    path: '/provider/google',
    component: SignInView,
    layout: MinimalLayout
  },
  {
    path: '/provider/strava',
    component: SignInView,
    layout: MinimalLayout
  }
];

const pelotonRoutes = [
  // ...BasicRoutes,
  // Routes 접근 권한을 이유로 임시로 guestRoutes에서 개발을 진행합니다.
];

/*
console.log(hasToken);
console.log(userInfo);
token 저장 상태를 체크
1)token이 저장되어 있으면 expires 체크 개인정보 불러오기 
2)token이 저장되어 있지 않으면 로그인창으로 이동 
*/

//auth.clearToken();
//route를 분기시켜준다.
auth.clearUserInfo();
auth.clearDashboardInfo();

const getUserStatus = async () => {
  const hasToken = auth.getToken();

  if (hasToken !== null) {
    const currentTime = Math.floor(+new Date() / 1000);

    //	console.log("ex : " + hasToken.token_expires);
    //	console.log("ct : " + currentTime);

    const expires = hasToken.token_expires;
    const auth_str = 'Bearer '.concat(auth.getToken().token);

    if (expires < currentTime) {
      await axios
        .post(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/api/aam/v2/jwt/refresh`,
          {
            headers: { Authorization: auth_str }
          }
        )
        .then(function(response) {
          let json = response.data;
          auth.setToken(json, true);
          window.location.reload();
        })
        .catch(function(error) {
          auth.clearUserInfo();
          auth.clearToken();
          window.location.reload();
        });
    } else if (expires > currentTime) {
      const auth_str = 'Bearer '.concat(auth.getToken().token);

      await axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-user-info.php`,
          {
            headers: { Authorization: auth_str },
            params: { order: 'get_user_info' }
          }
        )
        .then(function(response) {
          let json = response.data;
          if (json.success) {
            auth.setUserInfo(json.user_info, true);
          } else {
            auth.clearUserInfo();
            auth.clearToken();
            window.location.reload();
          }
        })
        .catch(function(error) {
          auth.clearUserInfo();
          auth.clearToken();
          window.location.reload();
        });

      if (!auth.exists('extra_info')) {
        await axios
          .get(
            `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-user-info.php`,
            {
              headers: { Authorization: auth_str },
              params: { order: 'get_extra_info' }
            }
          )
          .then(function(response) {
            let json = response.data;
            if (json.success) {
              auth.setExtraInfo(json.extra_info, true);
            } else {
              auth.clearExtraInfo();
              auth.clearDashboardInfo();
            }
          })
          .catch(function(error) {
            auth.clearUserInfo();
            auth.clearExtraInfo();
            auth.clearDashboardInfo();
            auth.clearToken();
          });
      }
    }
  }
};

const Routes = () => {
  const [routeState, setRouteState] = useState('');

  useEffect(() => {
    getUserStatus().then(function() {
      let user = auth.getUserInfo();

      try {
        if (user !== null) {
          if (user.user_service === 1) {
            setRouteState('procedure');
          } else if (user.user_service === 2) {
            if (
              user.ID === 2 ||
              user.ID === 46 ||
              user.ID === 17495 ||
              user.ID === 21956
            ) {
              setRouteState('subscribe');
            } else {
              setRouteState('basic');
            }
            // const auth_str = 'Bearer '.concat((auth.getToken().token));
            // if(routeState !== '') {
            // 	axios.get(
            // 		`${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-user-info.php`,
            // 		{
            // 			headers: { Authorization: auth_str },
            // 			params: { order: 'get_extra_info' }
            // 		}
            // 	).then(function (response) {
            // 		let json = response.data;
            // 		if(json.success) {
            // 			auth.setExtraInfo(json.extra_info, true);
            // 		} else {
            // 			auth.clearExtraInfo();
            // 			auth.clearDashboardInfo();
            // 		}
            // 	}).catch(function (error) {
            // 		auth.clearUserInfo();
            // 		auth.clearExtraInfo();
            // 		auth.clearDashboardInfo();
            // 		auth.clearToken();
            // 	});
            // }
          }
        } else {
          setRouteState('guest');
        }
      } catch (err) {
        console.log('err');
      }
    });
  }, [routeState]);

  //접근 가능한 메뉴 및 페이지 세팅
  function RoutesDiv() {
    if (routeState === 'procedure') {
      //sign in but procedure before
      return ProcedureRoutes.map((route, i) => (
        <RouteWithLayout key={i} exact {...route} />
      ));
    } else if (routeState === 'basic') {
      //sign in procedure after
      return BasicRoutes.map((route, i) => (
        <RouteWithLayout key={i} exact {...route} />
      ));
    } else if (routeState === 'subscribe') {
      //SubscribeRoutes
      return SubscribeRoutes.map((route, i) => (
        <RouteWithLayout key={i} exact {...route} />
      ));
    } else if (routeState === 'guest') {
      //sign in procedure after
      return guestRoutes.map((route, i) => (
        <RouteWithLayout key={i} exact {...route} />
      ));
    }
    // else if (routeState === "peloton") {
    //   //sign in procedure after
    //   return (
    //     pelotonRoutes.map((route, i) => (
    //       <RouteWithLayout key={i} exact {...route} />
    //     ))
    //   );
    // }
  }

  //로그인 또는 접속시 첫화면
  function RedirectDiv() {
    if (routeState === 'procedure') {
      return (
        <React.Fragment>
          <Redirect from="/activity" to="/activity" />
          <Redirect from="/sign-in" to="/procedure" />
          <Redirect from="/landing" to="/procedure" />
          <Redirect from="/" to="/procedure" />
        </React.Fragment>
      );
    } else if (
      routeState === 'basic' ||
      routeState === 'subscribe' ||
      routeState === 'peloton'
    ) {
      return (
        <React.Fragment>
          <Redirect from="/activity" to="/activity" />
          <Redirect from="/" to="/dashboard" />
        </React.Fragment>
      );
    } else if (routeState === 'guest') {
      return (
        <React.Fragment>
          <Redirect from="/activity" to="/activity" />
          <Redirect from="/" to="/landing" />
        </React.Fragment>
      );
    }
  }

  return (
    <Switch>
      {RoutesDiv()}
      {RedirectDiv()}

      <RouteWithLayout
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />

      {/* 점검중 페이지:s */}
      {/* <RouteWithLayout
				component={MaintenanceView}
				exact
				layout={MinimalLayout}
				path="/*"
      /> */}
      {/* 점검중 페이지:e */}
    </Switch>
  );
};

export default withRouter(Routes);
