export const countMonthDays = (date) => {
  const targetDate = new Date(date);
  targetDate.setDate(1);
  targetDate.setMonth(targetDate.getMonth() + 1);
  targetDate.setDate(0);

  return targetDate.getDate();
};

/**
 * 월 달력에 몇 주 있는지 체크
 * (월요일이 한 주의 시작)
 * */
export const countWeeksInMonth = (date) => {
  const target = new Date(date);

  target.setDate(1);
  const dayOfFirstDate = target.getDay();

  const isDayOfFirstDateSunday = dayOfFirstDate === 0 ? true : false;

  const currentMonthDaysCount = countMonthDays(target);

  return (
    Math.ceil((currentMonthDaysCount + dayOfFirstDate - 1) / 7) +
    (isDayOfFirstDateSunday ? 1 : 0)
  );
};

/**
 * 해당 달의 몇번째 주인지 확인 (첫번째 주의 경우 값은 0, 월요일이 주의 시작일)
 */
export const getWeekOfMonth = (date) => {
  const target = new Date(date);

  target.setDate(1);
  const dayOfFirstDate = target.getDay();
  const countDates =
    dayOfFirstDate === 0
      ? 6 + date.getDate() - 1
      : dayOfFirstDate - 1 + date.getDate() - 1;

  return Math.floor(countDates / 7);
};

/**
 * 해당 날짜의 주가 지난 달의 마지막 주 혹은 다음달의 첫째 주와 겹치는지 확인
 */
export const isOverlapMonths = (date) => {
  // 첫번째 주에 존재함
  if (getWeekOfMonth(date) === 0) {
    const target = new Date(date);

    target.setDate(1);
    const dayOfFirstDate = target.getDay();

    if (dayOfFirstDate === 1) {
      return { overlap: false };
    } else {
      // 첫째 주가 지난달의 마지막 주와 겹침
      target.setDate(0);
      const year = target.getFullYear();
      const month = target.getMonth();
      const week = getWeekOfMonth(target);

      return { overlap: true, year, month, week };
    }
    // 마지막 주에 존재함
  } else if (getWeekOfMonth(date) === countWeeksInMonth(date) - 1) {
    const target = new Date(date);

    target.setDate(1);
    const currentMonth = target.getMonth();
    target.setMonth(currentMonth + 1);
    target.setDate(0);

    const dayOfLastDate = target.getDay();

    if (dayOfLastDate === 0) {
      return { overlap: false };
    } else {
      // 마지막 주가 다음달의 첫째 주와 겹침
      target.setDate(1);
      target.setMonth(currentMonth + 1);

      const year = target.getFullYear();
      const month = target.getMonth();
      const week = getWeekOfMonth(target);

      return { overlap: true, year, month, week };
    }
  } else {
    return { overlap: false };
  }
};

/**
 * 해당 주의 월요일을 받아온다.
 * 다만 일요일의 경우 지난주의 월요일을 받아온다.
 */
export const getTargetWeekMonday = (dateObject) => {
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth();
  const date = dateObject.getDate();

  const targetDate = new Date(year, month, date);

  const day = targetDate.getDay();

  if (day === 0) {
    return getTargetWeekMonday(new Date(year, month, date - 1));
  } else {
    const time = targetDate.getTime() - 24 * 60 * 60 * 1000 * (day - 1);

    return new Date(time);
  }
};

/**
 * 해당 날짜가 어느 연도의 몇번째 주인지 리턴한다.
 * 첫째 주의 시작은 월요일.
 */
export const getWeekNumber = (date) => {
  const currentDate = new Date(date.getTime());

  // Set to nearest Thursday: current date + 4 - current day number
  // Make Sunday's day number 7
  currentDate.setDate(currentDate.getDate() + 4 - (currentDate.getDay() || 7));

  // Get first day of year
  const yearStart = new Date(currentDate.getFullYear(), 0, 1);

  // Calculate full weeks to nearest Thursday
  const weekNumber = Math.ceil(((currentDate - yearStart) / 86400000 + 1) / 7);

  return {
    year: currentDate.getFullYear(),
    week: weekNumber
  };
};
