import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';

export default function BikeDeleteDialog({
  openState,
  setOpen,
  handleDeleteSession
}) {
  const { t } = useTranslation(['page']);

  return (
    <>
      {openState && (
        <Dialog
          open={openState}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <>
            <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
            <DialogContent>
              <DialogContentText
                id="alert-dialog-description"
                text="body1"
                color="textPrimary">
                {t('BikeUI.deleteBike')}
              </DialogContentText>
              <DialogContentText
                id="alert-dialog-description"
                text="small"
                color="textSecondary"></DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setOpen(false)}
                size="medium"
                state="enabled"
                variant="text"
                color="primary">
                {t('BikeUI.cancel')}
              </Button>
              <Button
                onClick={handleDeleteSession}
                size="medium"
                state="enabled"
                variant="text"
                color="secondary">
                {t('BikeUI.delete')}
              </Button>
            </DialogActions>
          </>
        </Dialog>
      )}
    </>
  );
}
