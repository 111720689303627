import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';

import { Box, ButtonGroup, Button, Typography } from '@mui/material';

import auth from 'utils/auth';
import { SimulationSegment, SimulationCourseSegment } from './Segments';

const useStyles = makeStyles(theme => ({
  bottomTabButton: {
    fontSize: '14px',
    lineHeight: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      lineHeight: '14px'
    }
  }
}));

const Simulation = ({
  rider,
  bike,
  crrVal,
  cda,
  rimHeight,
  tireWidth,
  cadence,
  riderPose,
  roadSurface
}) => {
  const { t } = useTranslation(['page']);
  const classes = useStyles();
  const extraInfo = auth.getExtraInfo();
  const userInfo = auth.getUserInfo();

  //시뮬레이션 3가지 토글버튼
  const [simulationActive, setSimulationActive] = useState(
    extraInfo.club_id === 112 ? 'course' : 'segment'
  );

  const clickSimulation = value => {
    setSimulationActive(value);
  };
  return (
    <>
      <div style={{ textAlign: 'center', margin: '24px 0 24px 0' }}>
        <ButtonGroup>
          <Button
            className={classes.bottomTabButton}
            size="large"
            color={simulationActive === 'segment' ? 'secondary' : 'default'}
            onClick={() => clickSimulation('segment')}>
            {t('BikeUI.segmentTab')}
          </Button>
          <Button
            className={classes.bottomTabButton}
            size="large"
            color={simulationActive === 'course' ? 'secondary' : 'default'}
            onClick={() => clickSimulation('course')}>
            {t('BikeUI.courseTab')}
          </Button>
          <Button
            className={classes.bottomTabButton}
            size="large"
            color={simulationActive === 'powerdata' ? 'secondary' : 'default'}
            onClick={() => clickSimulation('powerdata')}>
            {t('BikeUI.powerdataTab')}
          </Button>
        </ButtonGroup>
      </div>

      <Box
        style={
          simulationActive === 'segment'
            ? { display: 'block' }
            : { display: 'none' }
        }>
        <SimulationSegment
          userInfo={userInfo}
          rider={rider}
          bike={bike}
          crrVal={crrVal}
          cda={cda}
          rimHeight={rimHeight}
          tireWidth={tireWidth}
          cadence={cadence}
          riderPose={riderPose}
          roadSurface={roadSurface}
        />
      </Box>
      <Box
        style={
          simulationActive === 'course'
            ? { display: 'block' }
            : { display: 'none' }
        }>
        {extraInfo.club_id == 112 ? (
          <SimulationCourseSegment />
        ) : (
          <Typography style={{ textAlign: 'center' }}>
            {t('BikeUI.premium')}
          </Typography>
        )}
      </Box>

      <Box
        style={
          simulationActive === 'powerdata'
            ? { display: 'block' }
            : { display: 'none' }
        }>
        <Typography style={{ textAlign: 'center' }}>
          {t('BikeUI.premium')}
        </Typography>
      </Box>
    </>
  );
};

export default Simulation;
