import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import DynamicFontTypography from 'components/Typography/DynamicFontTypography';

export default function PrivacyView() {
  const { t } = useTranslation('privacy');

  const content = t('Content', { returnObjects: true });

  return (
    <Box
      id="main"
      component="main"
      minWidth="100vw"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center">
      <Stack
        component="section"
        width="100vw"
        maxWidth="1200px"
        padding={{
          xs: '100px 24px 80px',
          lg: '100px 0px 80px'
        }}
        position="relative"
        gap={{ xs: 4, lg: 6 }}
        direction="column"
        justifyContent="flex-start"
        alignItems="center">
        <header>
          <DynamicFontTypography sx={{ fontSize: '24px' }}>
            {t('title')}
          </DynamicFontTypography>
        </header>
        <DynamicFontTypography variant="body1">
          {t('intro')}
        </DynamicFontTypography>

        {content.map((item, index) => (
          <Box
            key={index}
            sx={{
              marginTop: 2,
              width: '100%'
            }}>
            {item.title && (
              <DynamicFontTypography variant="h5" paddingBottom={2}>
                {item.title}
              </DynamicFontTypography>
            )}

            {item.subheading && (
              <DynamicFontTypography
                variant="subtitle1"
                fontWeight="bold"
                paddingBottom={1}>
                {item.subheading}
              </DynamicFontTypography>
            )}

            {item.paragraph && (
              <DynamicFontTypography
                sx={{
                  variant: 'content1',
                  whiteSpace: 'pre-line'
                }}>
                {item.paragraph}
              </DynamicFontTypography>
            )}

            {item.subsections && (
              <Box>
                {item.subsections.map((sub, subIndex) => (
                  <Box key={subIndex} paddingTop={2}>
                    <DynamicFontTypography
                      variant="h6"
                      fontWeight="medium"
                      paddingBottom={1}>
                      {sub.subheading}
                    </DynamicFontTypography>
                    <DynamicFontTypography
                      sx={{
                        variant: 'content1',
                        whiteSpace: 'pre-line'
                      }}>
                      {sub.paragraph}
                    </DynamicFontTypography>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
