import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Stack } from '@mui/material';
import {
  HeroArticle,
  ContentPreviewCard,
  LoadMoreButton
} from 'components/Common';
import { DynamicFontTypography } from 'components/Typography';
import { fetchBlogs } from 'hooks/useBlogs';
import { makeStyles } from '@mui/styles';
// import { blogList } from 'data';

const useStyles = makeStyles(theme => ({
  root: {},
  heading: {
    fontSize: 'clamp(24px, 3.0vw, 32px)',
    lineHeight: 'clamp(32px, 3.4vw, 40px)',
    fontWeight: 500
  },
  sub: {
    fontSize: 'clamp(16px, 1.3vw, 22px)',
    lineHeight: 'clamp(22px, 1.5vw, 30px)',
    fontWeight: 300
  }
}));

export default function BlogView() {
  const { t } = useTranslation('landing');
  const [blogs, setBlogs] = useState(null);
  const [visiblePosts, setVisiblePosts] = useState(3);
  const classes = useStyles();

  useEffect(() => {
    const getData = async () => {
      const data = await fetchBlogs();
      setBlogs(data);
    };
    getData();
  }, []);

  const handleLoadMore = () => {
    setVisiblePosts(prev => prev + 3);
  };

  if (!blogs) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <CircularProgress
          size={24}
          sx={{ color: 'primary.main' }}
          thickness={4}
        />
      </Box>
    );
  }

  return (
    <Box
      component="main"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        overflow: 'hidden'
      }}>
      <Stack
        component="section"
        width="100%"
        height="100%"
        maxWidth="1200px"
        padding={{ xs: '19.2px 24px 50px', lg: '19.2px 0px 50px' }}
        position="relative"
        gap={{ xs: 2, lg: 3 }}
        direction={{ md: 'column' }}
        justifyContent="flex-start">
        <header>
          <DynamicFontTypography className={classes.heading} fontWeight={500}>
            {t('Blog.title')}
          </DynamicFontTypography>
        </header>
        <HeroArticle
          title={blogs[0].title}
          desc={blogs[0].desc}
          img={blogs[0].img}
          url={blogs[0].url}
        />
        <DynamicFontTypography className={classes.heading} fontWeight={500}>
          {t('Blog.category')}
        </DynamicFontTypography>
        <Stack direction="column" gap={3}>
          {blogs
            .slice(0, visiblePosts)
            .map(({ title, desc, url, img }, index) => (
              <ContentPreviewCard
                key={index}
                title={title}
                desc={desc}
                url={url}
                img={img}
              />
            ))}
        </Stack>

        {visiblePosts < blogs.length && (
          <Box margin="32px auto">
            <LoadMoreButton onLoadMore={handleLoadMore} />
          </Box>
        )}
      </Stack>
    </Box>
  );
}
