import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Box,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Tab,
  Tabs,
  IconButton
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import CloseIcon from '@mui/icons-material/Close';

import $ from 'jquery';
import axios from 'axios';
import auth from 'utils/auth';
import { useTranslation } from 'react-i18next';

import {
  ActivityStream,
  PowerCurve,
  PowerZone,
  HeartrateZone,
  Interval,
  Lap,
  Metabolism
} from 'views/Dashboard/Chart';
import CustomCircularProgress from 'views/Peloton/Common/CustomCircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px 16px',
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  },
  timeTableCell: {
    height: '150px',
    [theme.breakpoints.down('lg')]: {
      height: '100px'
    }
  },
  appointment: {
    color: '#ffffff',
    fontSize: '14px',
    lineHeight: '18px',
    borderRadius: '4px',
    padding: '6px 8px',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      borderRadius: '2px'
    }
  },
  tableContainer: {
    width: 'auto',
    height: 'auto',
    [theme.breakpoints.down('xl')]: {
      marginTop: '-2px',
      height: 'auto'
    }
  },
  tableRow: {
    '&$selected:selected': {
      background: '#d8efed'
    }
  },
  tableHeadCell: {
    padding: '10px 16px',
    color: '#4db6ac',
    fontSize: '13px',
    fontWeight: '400',
    backgroundColor: '#f9f9f9',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px',
      fontSize: '11px'
    }
  },
  tableCell: {
    fontSize: '13px',
    padding: '10px 16px',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px',
      fontSize: '12px'
    }
  },
  tableCellActivity: {
    display: 'inline-block',
    fontSize: '13px',
    padding: '10px 16px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: 'fill-available',
    overflow: 'hidden',
    [theme.breakpoints.down('xl')]: {
      padding: '4px 8px',
      fontSize: '12px',
      maxWidth: '650px'
    },
    [theme.breakpoints.down('md')]: {
      padding: '4px 8px',
      fontSize: '12px',
      maxWidth: '240px'
    }
  },
  dialogActivityName: {
    width: '100%',
    textAlign: 'left',
    fontSize: '18px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px'
    }
  },
  dialogTabs: {
    background: theme.palette.background.colored.main,
    borderRadius: '4px'
  },
  smallButton: {
    minWidth: '30px',
    padding: '0px',
    fontSize: '13px',
    color: '#cccccc'
  },
  stravaButton: {
    minWidth: 0,
    margin: '2px 2px 2px 0'
  },
  stravaIcon: {
    color: '#FC4C02',
    fontSize: '16px',
    opacity: '0.7',
    marginTop: '-2px'
  },
  descTitle: {
    fontSize: '12px',
    marginTop: '10px',
    textAlign: 'center'
  },
  paginationSpacer: {
    flex: '1 1 0%',
    padding: '0 8px 8px 8px'
  },
  modifyButton: {
    border: '1px #dddddd solid',
    borderRadius: '4px',
    color: '#4db6ac',
    margin: '8px 0'
  },
  activityTitleBlock: {
    position: 'relative',
    zIndex: 10,
    width: 'calc(100% + 16px)',
    flexGrow: 1,
    height: '36px',
    margin: '0px 0 -12px -2px'
  },
  activityTitleCloseButton: {
    width: '32px',
    height: '32px',
    marginLeft: '8px',
    padding: '4px'
  },

  dialogAction: {
    background: '#f5f5f5'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px'
  },
  dialogTitle: {
    fontSize: '15px !important',
    flexGrow: 1,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    padding: '4px 0 6px 0',
    textAlign: 'center',
    [theme.breakpoints.down('lg')]: {
      textAlign: 'left',
      fontSize: '13px !important',
      height: '32px',
      padding: '4px 0 6px 0'
    }
    // width: 'calc(100% - 40px)'
  }
}));

const Activity = props => {
  //다국어적용 {t('page.key')}
  //특수한 훈련을 했을 때, 상세분석탭이 추가로 뜨게 된다.
  //FTP테스트분석
  //FATMAX 최적화 훈련
  //로우케이던스 훈련
  //고강도 최적화 훈련 = 사일러이펙트
  //Break Away 훈련 = 성냥개비
  //에어로분석 (ITT) = 공기저항값
  //힐리핏트레이닝
  //구간PR시도

  const { t } = useTranslation(['page']);

  const { wkgSelect, handleCloseDialog } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const [activityId, setActivityId] = React.useState(props.activityId);
  const [activityName, setActivityName] = React.useState('');

  const [publicExtraInfo, setPublicExtraInfo] = React.useState('');

  const [chartType, setChartType] = React.useState('stream');
  const [activityDialog, setActivityDialog] = React.useState(true);
  const [activityDesc, setActivityDesc] = React.useState(null);
  const [streamDataJson, setStreamDataJson] = useState([]);
  const [circle, setCircle] = useState(true);

  useEffect(() => {
    const getActivityStream = () => {
      setCircle(true);
      setStreamDataJson([]);

      let url = `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-activity-url-stream`;

      let params = {
        order: 'get_stream',
        activity_id: activityId
      };

      if (auth.getUserInfo() !== null) {
        params = {
          order: 'get_stream',
          activity_id: activityId,
          user_id: auth.getUserInfo().ID
        };
      }

      axios
        .get(url, {
          params: params
        })
        .then(async function(response) {
          setCircle(false);

          const data = response.data;
          if (data != false) {
            setStreamDataJson(data);
            setActivityName(data.activity_name);
          } else {
            setStreamDataJson('false');
            setActivityName('접근불가능');
          }
        });
    };

    if (activityId > 0 && chartType === 'stream') {
      getActivityStream();
    }
  }, [activityId]);

  const classes = useStyles();

  function TablePaginationActions(props) {
    const classes = useStyles();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = event => {
      onChangePage(event, 0);
    };

    const handleBackButtonClick = event => {
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = event => {
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = event => {
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
          size="large">
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
          size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
          size="large">
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
          size="large">
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  function ChartContent(props) {
    const streamDataJson = props.streamDataJson;

    $('#chartContainer').remove();

    if (streamDataJson.length === 0) {
      //stream 불러올 때 로딩써클
      return (
        <React.Fragment>
          <Box
            style={{
              minHeight: '436px',
              textAlign: 'center',
              padding: '100px'
            }}>
            <CustomCircularProgress />
          </Box>
        </React.Fragment>
      );
    } else if (streamDataJson === 'false') {
      //stream 실패시

      return (
        <React.Fragment>
          <Box
            style={{ minHeight: '20px', textAlign: 'center', padding: '20px' }}>
            {t('Dashboard.noActivities')}
          </Box>
        </React.Fragment>
      );
    } else if (chartType === 'powercurve') {
      if (0) {
        return <React.Fragment></React.Fragment>;
      } else {
        return (
          <React.Fragment>
            <PowerCurve
              extraInfo={publicExtraInfo}
              activityId={activityId}
              activityName={activityName}
              activityDesc={activityDesc}
              ftp={Math.round(streamDataJson.ftp)}
              pdcData={streamDataJson.pdc_list}
              streamDataJson={streamDataJson}
              wkg={wkgSelect}
              weight={streamDataJson.weight}
            />
          </React.Fragment>
        );
      }
    } else if (chartType === 'powerzone') {
      return (
        <React.Fragment>
          <PowerZone
            extraInfo={publicExtraInfo}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(streamDataJson.ftp)}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
            powerzoneData={streamDataJson.powerzone_data}
          />
        </React.Fragment>
      );
    } else if (chartType === 'hrzone') {
      return (
        <React.Fragment>
          <HeartrateZone
            extraInfo={publicExtraInfo}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(streamDataJson.ftp)}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
            maxHr={streamDataJson.mean_max_hr}
            powerzoneData={streamDataJson.powerzone_data}
          />
        </React.Fragment>
      );
    } else if (chartType === 'interval') {
      return (
        <React.Fragment>
          <Interval
            extraInfo={publicExtraInfo}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(streamDataJson.ftp)}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
            maxHr={streamDataJson.mean_max_hr}
            powerzoneData={streamDataJson.powerzone_data}
          />
        </React.Fragment>
      );
    } else if (chartType === 'lap') {
      return (
        <React.Fragment>
          <Lap
            extraInfo={publicExtraInfo}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(streamDataJson.ftp)}
            streamDataJson={streamDataJson}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
            maxHr={streamDataJson.mean_max_hr}
          />
        </React.Fragment>
      );
    } else if (chartType === 'metabolism') {
      return (
        <React.Fragment>
          <Metabolism
            extraInfo={publicExtraInfo}
            activityId={activityId}
            activityName={activityName}
            activityDesc={activityDesc}
            ftp={Math.round(streamDataJson.ftp)}
            streamDataJson={streamDataJson}
            metabolismArray={streamDataJson.metabolism_array}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
            maxHr={streamDataJson.mean_max_hr}
          />
        </React.Fragment>
      );
    } else {
      //activity stream
      return (
        <React.Fragment>
          <ActivityStream
            extraInfo={publicExtraInfo}
            ftp={Math.round(streamDataJson.ftp)}
            summaryData={streamDataJson.summary_data}
            streamDataJson={streamDataJson}
            pdcData={streamDataJson.pdc_data}
            powerzoneData={streamDataJson.powerzone_data}
            wkg={wkgSelect}
            weight={streamDataJson.weight}
          />
        </React.Fragment>
      );
    }
  }

  const handleTabChart = (event, value) => {
    setChartType(value);
  };

  return (
    <div className={classes.root}>
      <Dialog
        fullWidth
        fullScreen={fullScreen}
        maxWidth="xl"
        open={activityDialog}
        onBackdropClick={handleCloseDialog}
        scroll={'body'}
        PaperProps={{
          style: {
            padding: '0px'
          }
        }}>
        <Box className={classes.titleContainer}>
          <DialogTitle className={classes.dialogTitle}>
            {'🚴 ' + activityName}
          </DialogTitle>
          <IconButton onClick={() => handleCloseDialog()} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider />

        <DialogContent
          style={
            window.innerWidth < 960 ? { padding: '8px' } : { padding: '16px' }
          }>
          <Tabs
            className={classes.dialogTabs}
            value={chartType}
            variant="scrollable"
            scrollButtons="auto"
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChart}>
            {/* 조건에 따라 차트 메뉴들이 보이고 안보이도록  */}
            {streamDataJson.activity_id > 0 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.summary')}
                value={'stream'}
              />
            ) : (
              ''
            )}
            {streamDataJson.watts_5 > 1 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.powercurve')}
                value={'powercurve'}
              />
            ) : (
              ''
            )}
            {streamDataJson.watts_5 > 1 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.powerzone')}
                value={'powerzone'}
              />
            ) : (
              ''
            )}
            {streamDataJson.max_hr > 50 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.hrzone')}
                value={'hrzone'}
              />
            ) : (
              ''
            )}
            {streamDataJson.watts_60 > streamDataJson.ftp ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.interval')}
                value={'interval'}
              />
            ) : (
              ''
            )}
            {
              //(streamDataJson.lap !== null) ? <Tab style={{minWidth:'0px'}} label={t('Activity.lap')} value={'lap'} /> : ""
            }
            {streamDataJson.watts_5 > 10000 ? (
              <Tab
                style={{ minWidth: '0px' }}
                label={t('Activity.metabolism')}
                value={'metabolism'}
              />
            ) : (
              ''
            )}
          </Tabs>
          <ChartContent streamDataJson={streamDataJson} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Activity;
