import React, { useState, useEffect } from 'react';
import axios from 'axios';

import {
  Box,
  CircularProgress,
  Typography,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Paper
} from '@mui/material';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import BoltIcon from '@mui/icons-material/Bolt';
import RouteIcon from '@mui/icons-material/Route';
import MovingIcon from '@mui/icons-material/Moving';

import { makeStyles, useTheme } from '@mui/styles';

import CanvasJSReact from 'utils/canvasjs.stock.react';
import auth from 'utils/auth';

const CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  titleBox: {
    backgroundColor: theme.palette.primary.main,
    padding: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: '8px'
    }
  },
  accessTimeIcon: { fontSize: '36px', color: 'white', marginRight: '8px' },
  title: {
    fontSize: '24px',
    color: 'white',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px'
    }
  },
  infoBox: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1)
  },
  dataBox: { display: 'flex', gap: '4px' },
  infoText: {
    fontWeight: 'bold'
  },
  subTitle: { fontWeight: 'bold', fontSize: '16px' },
  iconBox: { display: 'flex', alignItems: 'center' },
  info: { marginRight: '4px' },
  timeTableTitle: {
    fontWeight: 'bold',
    marginBottom: '8px',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px'
    },
    breakBefore: 'page'
  },
  timeTableText: { marginBottom: '16px', textAlign: 'center' },
  rowHead: {
    backgroundColor: '#242424',
    color: 'white'
  },
  time: { padding: '0px', textAlign: 'center', fontWeight: 'bold' },
  image: { maxHeight: '40px' },
  tableWrap: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  timeTableBox: {
    width: '100%',
    marginBottom: '16px',
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    border: '1px dashed black',
    position: 'relative',
    '@media print': {
      width: '200px'
    }
  },
  snackTableBox: {
    width: '100%',
    marginBottom: '16px',
    [theme.breakpoints.up('md')]: {
      width: '50%'
    },
    '@media print': {
      display: 'none !important'
    }
  },
  scissors: {
    position: 'absolute',
    top: 0,
    left: '-10px'
  }
}));

const getDashBoardData = async userId => {
  let url =
    'https://rest.riduck.com/json-api/cache/dashboard_85_' + userId + '.json';

  try {
    const response = await axios.post(url);
    return response;
  } catch (err) {
    console.error(err);
  }
};

const getAlphaData = async (userId, activityId, ftpType) => {
  try {
    const auth_str = 'Bearer '.concat(auth.getToken().token);
    const response = await axios.get(
      `${process.env.REACT_APP_RESTAPI_BASE_URL}/test-api/alpha_ride/alpha-stream.php`,
      {
        headers: { Authorization: auth_str },
        params: {
          order: 'get_stream',
          ftp_type: ftpType
        }
      }
    );

    //    const response = await axios.post(url);
    return response;
  } catch (err) {
    console.error(err);
  }
};

function secondsToHms(time) {
  let d = Number(time);
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  let s = Math.floor((d % 3600) % 60);

  if (h > 0) {
    let hd = h > 0 ? h + '시간 ' : '';
    let md = m > 0 ? m + '분' : '';

    return hd + md;
  } else if (h <= 0) {
    let md = m > 0 ? m + '분 ' : '';
    let sd = s > 0 ? s + '초' : '';

    return md + sd;
  }
}

const SeorakGranfondo = ({ simulationStatus, ftpType }) => {
  const USER_ID = auth.getUserInfo().ID;
  const ACTIVITY_ID = 0;
  const classes = useStyles();
  const theme = useTheme();

  const [ftp, setFtp] = useState(
    ftpType == 'sftp'
      ? auth.getDashboardInfo()?.ftpArray?.submax_ftp
      : auth.getDashboardInfo()?.ftpArray?.ftp
  );
  const [alphaData, setAlphaData] = useState();
  const [options, setOptions] = useState();
  const [timeTable, setTimeTable] = useState();

  const handleAlphaData = async () => {
    const response = await getAlphaData(USER_ID, ACTIVITY_ID, ftpType);
    setAlphaData(response.data);
  };

  const handleWftpData = async () => {
    const response = await getDashBoardData(USER_ID);
    if (ftpType == 'sftp') {
      setFtp(response.data.submax_ftp);
    } else {
      setFtp(response.data.ftp_array.wftp);
    }
  };

  // 데이터 받아오기
  useEffect(() => {
    if (!ftp) handleWftpData();
    handleAlphaData();
  }, [ftp]);

  useEffect(() => {
    if (!ftp) return;
    if (!alphaData) return;

    const {
      alpha_velocity,
      distance,
      alpha_power_avg,
      stream_json: {
        velocity,
        watts,
        altitude,
        duration,
        fat_cal_arr,
        gly_cal_arr,
        fuel_arr
      }
    } = alphaData;

    const crosshair = {
      enabled: true,
      lineColor: '#999999',
      labelFormatter: function(e) {
        let distance;

        if (e.value < 0) distance = 0;
        else distance = Math.round(e.value) / 1000;

        return distance + 'km';
      },
      labelFontSize: '12'
    };

    const powerzonePoints = [];

    // 파워존 계산
    watts.forEach(point => {
      let color = '#f5f5f5';
      let zone = '';

      if (point.y > 0 && point.y <= ftp * 0.55) {
        color = '#cccccc';
        zone = 1;
      } else if (point.y > ftp * 0.55 && point.y <= ftp * 0.75) {
        color = '#76b5e8';
        zone = 2;
      } else if (point.y > ftp * 0.75 && point.y <= ftp * 0.9) {
        color = '#b0d36d';
        zone = 3;
      } else if (point.y > ftp * 0.9 && point.y <= ftp * 1.05) {
        color = '#ffd357';
        zone = 4;
      } else if (point.y > ftp * 1.05 && point.y <= ftp * 1.2) {
        color = '#ffa75c';
        zone = 5;
      } else if (point.y > ftp * 1.2 && point.y <= ftp * 1.5) {
        color = '#ff7e75';
        zone = 6;
      } else if (point.y > ftp * 1.5) {
        color = '#f06292';
        zone = 7;
      }

      powerzonePoints.push({
        x: point.x,
        y: point.y,
        color: color,
        zone: zone
      });
    });

    // 차트 옵션
    const option = {
      backgroundColor: theme.palette.chart.background,
      animationEnabled: false,
      zoomEnabled: true,
      title: {
        text: ''
      },
      rangeSelector: {
        enabled: false
      },
      navigator: {
        enabled: false
      },
      charts: [
        {
          // 전체 차트
          height: 320,
          axisX: {
            valueFormatString: ' ',
            tickLength: 0,
            stripLines: [],
            crosshair
          },
          axisY: {
            valueFormatString: ' ',
            tickLength: 0,
            gridThickness: 0,
            maximum: 1800
          },
          legend: {
            verticalAlign: 'top',
            fontSize: 16,
            dockInsidePlotArea: true
          },
          toolTip: {
            shared: true,
            contentFormatter: function(e) {
              let content = ' ';
              for (let i = 0; i < e.entries.length; i++) {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  e.entries[i].dataPoint.y +
                  '</strong>m';
                content += '<br/>';
              }
              return content;
            }
          },
          data: [
            {
              name: '고도',
              type: 'area',
              indexLabelPlacement: 'inside',
              lineColor: '#1E9E8F',
              color: '#319F93',
              lineThickness: 2,
              fillOpacity: '0.6',
              markerSize: 0,
              dataPoints: altitude
            }
          ]
        },
        {
          // velocity chart
          height: 100,
          axisX: {
            crosshair,
            labelFormatter: function() {
              return '';
            }
          },
          axisY: {
            labelFontColor: theme.palette.chart.text.secondary,
            gridThickness: 0,
            lineThickness: 1,
            stripLines: [
              {
                showOnTop: true,
                value: alpha_velocity,
                label: 'avg velocity ' + alpha_velocity + ' km/h',
                labelPlacement: 'inside',
                labelFontSize: 12,
                labelAlign: 'far',
                labelFontColor: theme.palette.text.third,
                thickness: 1,
                color: theme.palette.text.third,
                lineDashType: 'dash'
              }
            ]
          },
          legend: {
            verticalAlign: 'top',
            fontSize: 16,
            dockInsidePlotArea: true
          },
          toolTip: {
            shared: true,
            contentFormatter: function(e) {
              let content = ' ';
              for (let i = 0; i < e.entries.length; i++) {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  e.entries[i].dataPoint.y +
                  '</strong>km/h';
                content += '<br/>';
              }
              return content;
            }
          },
          subtitles: [
            {
              text: `속도 평균 ${alpha_velocity} kph`,
              fontFamily: 'tahoma',
              fontSize: 12,
              fontColor: theme.palette.chart.text.primary,
              margin: 0
            }
          ],
          data: [
            {
              name: '속도',
              type: 'area',
              indexLabelPlacement: 'inside',
              lineColor: '#ffd357',
              color: '#ffd357',
              lineThickness: 1,
              fillOpacity: '0.6',
              markerSize: 0,
              dataPoints: velocity
            }
          ]
        },
        {
          // watts chart
          height: 100,
          axisX: {
            crosshair,
            labelFormatter: function() {
              return '';
            },
            stripLines: []
          },
          axisY: {
            labelFontColor: theme.palette.chart.text.secondary,
            gridThickness: 0,
            lineThickness: 1,
            stripLines: [
              {
                value: ftp,
                label: 'FTP ' + ftp + ' W',
                labelPlacement: 'inside',
                labelFontSize: 12,
                labelAlign: 'far',
                labelFontColor: '#f06292',
                thickness: 1,
                color: '#f06292',
                lineDashType: 'dash'
              }
            ]
          },
          legend: {
            verticalAlign: 'top',
            fontSize: 16,
            dockInsidePlotArea: true
          },
          toolTip: {
            shared: true,
            contentFormatter: function(e) {
              let content = ' ';
              for (let i = 0; i < e.entries.length; i++) {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  e.entries[i].dataPoint.y +
                  '</strong>W';
                content += '<br/>';
              }
              return content;
            }
          },
          subtitles: [
            {
              text: `파워 평균 ${alpha_power_avg} W`,
              fontFamily: 'tahoma',
              fontSize: 12,
              fontColor: theme.palette.chart.text.primary,
              margin: 0
            }
          ],
          dataPointWidth: 3,
          data: [
            {
              name: '파워',
              type: 'area',
              color: '#ff7e75',
              indexLabelPlacement: 'inside',
              lineColor: '#ff7e75',
              lineThickness: 2,
              fillOpacity: '0.6',
              markerSize: 0,
              dataPoints: watts
            }
          ]
        },
        {
          // powerzone chart
          height: 100,
          axisX: {
            crosshair,
            labelFormatter: function() {
              return '';
            },
            stripLines: []
          },
          axisY: {
            labelFontColor: theme.palette.chart.text.secondary,
            gridThickness: 0,
            lineThickness: 1,
            stripLines: [
              {
                value: ftp,
                label: 'FTP ' + ftp + ' W',
                labelPlacement: 'inside',
                labelFontSize: 12,
                labelAlign: 'far',
                labelFontColor: '#f06292',
                thickness: 1,
                color: '#f06292',
                lineDashType: 'dash'
              }
            ]
          },
          legend: {
            verticalAlign: 'top',
            fontSize: 16,
            dockInsidePlotArea: true
          },
          subtitles: [
            {
              text: `파워존`,
              fontFamily: 'tahoma',
              fontSize: 12,
              fontColor: theme.palette.chart.text.primary,
              margin: 0
            }
          ],
          toolTip: {
            shared: true
          },
          dataPointWidth: 3,
          data: [
            {
              name: '파워',
              type: 'column',
              fillOpacity: '1.0',
              markerSize: 0,
              toolTipContent: '{name} <strong>{y}</strong>',
              dataPoints: powerzonePoints,
              yValueFormatString: '# W'
            },
            {
              name: '',
              type: 'area',
              color: '#f5f5f5',
              indexLabelPlacement: 'inside',
              toolTipContent: null,
              lineColor: '#f5f5f5',
              lineThickness: 0,
              fillOpacity: '0.2',
              markerSize: 0,
              dataPoints: powerzonePoints
            }
          ]
        },
        {
          // metabolism chart
          height: 100,
          axisX: {
            crosshair,
            labelFormatter: function() {
              return '';
            },
            stripLines: []
          },
          axisY: {
            labelFontColor: theme.palette.chart.text.secondary,
            gridThickness: 0,
            lineThickness: 1
          },
          legend: {
            verticalAlign: 'top',
            fontSize: 16,
            dockInsidePlotArea: true
          },
          subtitles: [
            {
              text: `지방/탄수화물 대사비율`,
              fontFamily: 'tahoma',
              fontSize: 12,
              fontColor: theme.palette.chart.text.primary,
              margin: 0
            }
          ],
          toolTip: {
            shared: true,
            contentFormatter: function(e) {
              let content = '';
              for (let i = 0; i < e.entries.length; i++) {
                content += `<span>${e.entries[i].dataSeries.name}</span> <strong>${e.entries[i].dataPoint.y}</strong>Cal/h`;
                if (i === 0) {
                  content += ` (${(
                    (e.entries[i].dataPoint.y * 1000) /
                    4184
                  ).toFixed(2)}g/h)`;
                }
                content += '<br/>';
              }

              return content;
            }
          },
          dataPointWidth: 3,
          data: [
            {
              name: '탄수화물',
              type: 'stackedArea100',
              markerSize: 0,
              dataPoints: gly_cal_arr
            },
            {
              name: '지방',
              type: 'stackedArea100',
              color: '#FAC33B',
              markerSize: 0,
              dataPoints: fat_cal_arr
            }
          ]
        },
        {
          // 파워젤 차트
          height: 100,
          axisX: {
            crosshair,
            labelFormatter: function() {
              return '';
            },
            stripLines: []
          },
          axisY: {
            labelFontColor: theme.palette.chart.text.secondary,
            gridThickness: 0,
            lineThickness: 1
          },
          legend: {
            verticalAlign: 'top',
            fontSize: 16,
            dockInsidePlotArea: true
          },
          subtitles: [
            {
              text: `탄수화물 소모량 및 보급 (120Cal)`,
              fontFamily: 'tahoma',
              fontSize: 12,
              fontColor: theme.palette.chart.text.primary,
              margin: 0
            }
          ],
          toolTip: {
            shared: true
          },
          dataPointWidth: 3,
          data: [
            {
              name: '탄수화물 소모량',
              type: 'area',
              indexLabelPlacement: 'inside',
              lineThickness: 1,
              fillOpacity: '0.3',
              markerSize: 0,
              color: '#76b5e8',
              toolTipContent: '{name} <strong>{y}</strong>',
              dataPoints: fuel_arr,
              yValueFormatString: '# Cal'
            }
          ]
        },
        {
          // altitude
          height: 150,
          axisX: {
            labelFontColor: theme.palette.chart.text.secondary,
            crosshair,
            suffix: ' km',
            stripLines: [],
            labelFormatter: function(e) {
              return Math.round(e.value) / 1000;
            }
          },
          axisY: {
            labelFontColor: theme.palette.chart.text.secondary,
            gridThickness: 0,
            lineThickness: 1
          },
          legend: {
            verticalAlign: 'top',
            fontSize: 16,
            dockInsidePlotArea: true
          },
          toolTip: {
            shared: true,
            contentFormatter: function(e) {
              let content = ' ';
              for (let i = 0; i < e.entries.length; i++) {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  e.entries[i].dataPoint.y +
                  '</strong>m';
                content += '<br/>';
              }
              return content;
            }
          },
          subtitles: [
            {
              text: `이동거리 ${distance / 1000} km`,
              fontFamily: 'tahoma',
              fontSize: 12,
              fontColor: theme.palette.chart.text.primary,
              margin: 0
            }
          ],
          data: [
            {
              name: '고도',
              type: 'area',
              indexLabelPlacement: 'inside',
              lineColor: '#b0d36d',
              color: '#b0d36d',
              lineThickness: 2,
              fillOpacity: '0.6',
              markerSize: 0,
              dataPoints: altitude,
              yValueFormatString: '# m'
            }
          ]
        }
      ]
    };

    // 살둔재 18144, 19308
    const saldunjae = { name: '살둔재', start: 18144, end: 19308, power: 0 };

    // 구룡령 36832 43552
    const guryongryung = { name: '구룡령', start: 36832, end: 43552, power: 0 };

    // 조참령 63014 69018
    const jochamryung = { name: '조참령', start: 63014, end: 69018, power: 0 };

    // 쓰리재 80991 87331
    const serijae = { name: '쓰리재', start: 80991, end: 87331, power: 0 };

    // 한계령 104376 108585
    const hangaeryung = {
      name: '한계령',
      start: 104376,
      end: 108585,
      power: 0
    };

    // 구룡령역 147306 161660
    const guryongryungyuk = {
      name: '구룡령역',
      start: 147306,
      end: 161660,
      power: 0
    };

    const peakPoints = [
      saldunjae,
      guryongryung,
      jochamryung,
      serijae,
      hangaeryung,
      guryongryungyuk
    ];

    peakPoints.forEach(({ start, end, name }, index) => {
      let valueSum = 0;
      let indexCount = 0;

      // 권장 파워 계산
      watts.forEach(point => {
        if (point.x >= start && point.x <= end) {
          valueSum += point.y;
          indexCount += 1;
        }
      });
      const powerSegAvg = Math.round(valueSum / indexCount);
      peakPoints[index].power = powerSegAvg;

      // peakPoint 이름 표시
      let flag = false;
      for (let index in option.charts[0].data[0].dataPoints) {
        const point = option.charts[0].data[0].dataPoints[index];
        if (point.x >= (start + end) / 2) {
          option.charts[0].data[0].dataPoints[index].indexLabelOrientation =
            'vertical';
          option.charts[0].data[0].dataPoints[index].indexLabel = name;
          option.charts[0].data[0].dataPoints[index].indexLabelPlacement =
            'inside';
          option.charts[0].data[0].dataPoints[index].indexLabelTextAlign =
            'center';
          option.charts[0].data[0].dataPoints[index].indexLabelFontColor =
            theme.palette.chart.text.primary;

          flag = true;
        }
        if (flag) break;
      }
    });

    // 파워젤 계산
    let fuelCount = 1;
    fuel_arr.forEach(({ x, y }) => {
      if (y >= 120 * fuelCount) {
        fuelCount += 1;
        option.charts[5].axisX.stripLines.push({
          value: x,
          labelPlacement: 'inside',
          labelFontSize: 12,
          labelAlign: 'far',
          thickness: 1,
          color: '#f06292',
          lineDashType: 'dash'
        });
      }
    });

    // altitude
    peakPoints.forEach(({ start, end }) => {
      option.charts[6].axisX.stripLines.push({
        showOnTop: false,
        startValue: start,
        endValue: end,
        color: '#4cb1a6',
        opacity: 0.4
      });
    });

    // 전체 차트 & watts
    peakPoints.forEach(({ start, end, power }) => {
      // 권장 파워 표시
      option.charts[0].axisX.stripLines.push({
        showOnTop: true,
        startValue: start,
        endValue: end,
        label: `권장파워 ${power} W`,
        labelFontColor: theme.palette.chart.text.secondary,
        labelFontSize: 14,
        labelFontWeight: 'bold',
        labelBackgroundColor: 'transparent',
        color: '#FFB600',
        opacity: 0.4
      });

      // 권장 파워 표시
      option.charts[2].axisX.stripLines.push({
        showOnTop: true,
        startValue: start,
        endValue: end,
        color: '#f06292',
        label: power + ' W',
        labelAlign: 'center',
        labelFontSize: 14,
        labelBackgroundColor: 'transparent',
        labelFontFamily: 'Tahoma',
        labelFontColor: theme.palette.chart.text.secondary,
        opacity: 0.4
      });
    });

    /**
     * 타임테이블
     * TODO: 간식 계산 로직 필요.
     */
    const timeTable = [
      {
        name: '살둔재',
        distance: 19.3,
        time: 0,
        end: 19308
      },
      {
        name: '구룡령',
        distance: 43.5,
        time: 0,
        end: 43552
      },
      { name: '조침령', distance: 69, time: 0, end: 69018 },
      { name: '진동고개', distance: 76, time: 0, end: 75796 },
      { name: '쓰리재', distance: 87.3, time: 0, end: 87331 },
      {
        name: '보급3',
        distance: 103.1,
        time: 0,
        end: 104376
      },
      {
        name: '필례한계령',
        distance: 108.6,
        time: 0,
        end: 108585
      },
      { name: '보급4', distance: 147.3, time: 0, end: 147306 },
      {
        name: '역구룡령',
        distance: 161.6,
        time: 0,
        end: 161660
      },
      {
        name: '역살둔재',
        distance: 185.7,
        time: 0,
        end: 185671
      }
    ];

    // 최적화 시간 계산
    timeTable.forEach(({ end }, index, array) => {
      duration.forEach(value => {
        if (value.x === end) {
          const arriveTime = secondsToHms(value.y);
          array[index].time = arriveTime;
        }
      });
    });

    setOptions(option);
    setTimeTable(timeTable);
  }, [ftp, alphaData]);

  const containerProps = {
    width: '100%',
    height: '1000px',
    margin: 'auto'
  };

  const snacks = [
    {
      name: 'banana',
      desc:
        '개당 약 105칼로리, 빠르게 에너지를 공급해주는 과일, 대회 전반에 걸쳐 섭취 가능'
    },
    {
      name: 'grape',
      desc:
        '100G당 69칼로리, 당도가 높으며 빠른 에너지 공급 가능, 대회 전반에 걸쳐 섭취 가능'
    },
    {
      name: 'digest',
      desc:
        '100G당 500칼로리 내외로 칼로리가 매우 높으나 흡수가 느림. 출발전 섭취 권장'
    },
    {
      name: 'chocolate_bar',
      desc:
        '개당 약 200칼로리, 당분과 지방함량 높으나 많이 먹을 수 없음. 출발전과 초반 섭취'
    },
    {
      name: 'energy_bar',
      desc:
        '개당 약 200~300칼로리 내외, 균형있는 고열량 에너지. 출발전 섭취 권장'
    },
    {
      name: 'yeonyang',
      desc: '개당 약 70~100칼로리 내외, 당분함량 매우 높음. 초중반부 섭취 권장'
    },
    {
      name: 'powerade',
      desc:
        '100ml당 4~5칼로리 정도로 신체 수분 균형과 소량 에너지 충전. 후반부 주력 보급원'
    },
    {
      name: 'drink',
      desc:
        '개당 120칼로리로 정량의 탄수화물 에너지원 공급 가능, 중후반부에 걸쳐 물과 함께 섭취'
    },
    {
      name: 'present_box',
      desc:
        '스페셜보급, 기록달성과 완주를 목표로 한다면, 바나나만 들고 빠르게 패스'
    }
  ];

  return (
    <div className={classes.root}>
      {simulationStatus === 'loading' ? (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '152px'
          }}>
          <CircularProgress size={128} />
        </Box>
      ) : null}
      {simulationStatus === 'mount' && options && timeTable ? (
        <>
          <Box className={classes.titleBox}>
            <Typography variant="h1" className={classes.title}>
              설악 그란폰도 시뮬레이션
            </Typography>
          </Box>
          <Box className={classes.infoBox}>
            <Box className={classes.dataBox}>
              <RouteIcon />
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.infoText}>
                거리: {alphaData.distance / 1000}km
              </Typography>
            </Box>
            <Box className={classes.dataBox}>
              <MovingIcon />
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.infoText}>
                상승고도: {alphaData.total_elevation_gain}m
              </Typography>
            </Box>
            <Box className={classes.dataBox}>
              <AccessTimeIcon />
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.infoText}>
                예상 기록: {secondsToHms(alphaData.alpha_total_time)}
              </Typography>
            </Box>
            <Box className={classes.dataBox}>
              <BoltIcon />
              <Typography
                variant="body1"
                color="textPrimary"
                className={classes.infoText}>
                예상 평균파워: {alphaData.alpha_power_avg}W
              </Typography>
            </Box>
          </Box>
          <CanvasJSStockChart
            containerProps={containerProps}
            options={options}
          />
          <Typography
            variant="h2"
            color="primary"
            className={classes.timeTableTitle}>
            최적화된 타임테이블
          </Typography>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.timeTableText}>
            예상 기록을 토대로 최적화 되었습니다. 프린트하여 활용해보세요!{' '}
            <br />
            속도계의 주행시간으로 활용 가능합니다! (자동 멈춤기능 OFF)
          </Typography>
          <Box className={classes.tableWrap}>
            <Box className={classes.timeTableBox}>
              <TableContainer component={Paper}>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">위치</TableCell>
                      <TableCell align="center">주파시간</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {timeTable.map(({ name, distance, time }) => (
                      <TableRow key={time}>
                        <TableCell align="center" className={classes.rowHead}>
                          <Box>{name}</Box>
                          <Box style={{ fontWeight: 'bold' }}>{distance}km</Box>
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          align="center"
                          style={{ paddingRight: '0px' }}
                          className={classes.time}>
                          {time}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box className={classes.scissors}>
                <span role="img">✂️</span>
              </Box>
            </Box>
            <TableContainer component={Paper} className={classes.snackTableBox}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">보급</TableCell>
                    <TableCell align="center">설명</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {snacks.map(({ name, desc }) => (
                    <TableRow key={name}>
                      <TableCell align="center">
                        <img
                          className={classes.image}
                          src={`/images/snacks/${name}.png`}
                          alt="snack"
                        />
                      </TableCell>
                      <TableCell align="center">{desc}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : null}
    </div>
  );
};

export default SeorakGranfondo;
