import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Divider,
  FormGroup,
  FormControlLabel,
  TextField,
  Checkbox,
  MenuItem,
  Switch,
  CardActions,
  Typography
} from '@mui/material';

import axios from 'axios';
import auth from '../../../../utils/auth';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px',
    marginBottom: '16px'
  },
  cardContentBox: {
    margin: '0'
  },
  cardContentBoxHidden: {
    display: 'none',
    margin: '12px 0 4px 0'
  },
  rowReverse: {
    marginTop: '-8px',
    paddingBottom: '10px',
    flexDirection: 'row-reverse'
  },
  subTitle: {
    fontSize: '16px',
    margin: '16px 0 0 16px'
  },
  rowReverse: {
    marginTop: '-8px',
    paddingBottom: '10px',
    flexDirection: 'row-reverse'
  },
  cardWrapper: {
    boxShadow: 'none',
    width: '100%',
    margin: '0px'
  },
  cardHeader: {
    padding: '12px 16px 8px 16px',
    fontSize: '15px',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px'
    }
  },
  box: {
    padding: '8px 16px',
    justifyContent: 'space-around'
  },
  item: {
    padding: '8px 16px',
    // justifyContent:'space-around',
    margin: '0px auto',
    width: 'fit-content'
  },
  spaceAround: {
    justifyContent: 'space-around'
  },
  selectBox: {
    minWidth: '160px'
  }
}));

const ConnectOption = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { className, extraInfo, stravaToken, webhookJson } = props;
  const [redirect, setRedirect] = React.useState(false);
  const [stravaConnectDialogOpen, setStravaConnectDialogOpen] = React.useState(
    false
  );

  const [stravaConnectSwitch, setStravaConnectSwitch] = React.useState(
    stravaToken
  );
  const [stravaWebhook, setStravaWebhook] = React.useState({
    power: webhookJson.power,
    detailLevel: webhookJson.hasOwnProperty('detailLevel')
      ? webhookJson.detailLevel
      : 0
  });

  useEffect(() => {
    setStravaConnectSwitch(stravaToken);
  });

  const handleSwitchChange = () => {
    let ch = !stravaConnectSwitch;
    setStravaConnectSwitch(ch);
    setStravaConnectDialogOpen(true);
  };

  const handleCloseDialog = event => {
    setStravaConnectDialogOpen(false);
    setStravaConnectSwitch(false);
    // console.log('stravaConnectSwitch', stravaConnectSwitch);
  };

  const handleDisconnectStrava = event => {
    //연동->연동취소
    if (extraInfo.strava_token !== '') {
      //연동취소 + strava_id strava_token refresh_token 삭제
      axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/strava/register-strava.php`,
          {
            params: {
              jwt: auth.getToken().token,
              exchange_type: 'disconnect'
            }
          }
        )
        .then(function(response) {
          let res = response.data;
          if (res.disconnected === true) {
            //        setRedirect(true);
            window.location.reload();
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  };

  const handleConnectStrava = event => {
    //연동취소->연동
    if (extraInfo.strava_token === '') {
      const clientId = Number(extraInfo.strava_clientId);

      console.log(extraInfo.strava_clientId);

      if (clientId === 0) {
        window.location =
          'https://www.strava.com/oauth/authorize?client_id=39781&response_type=code&redirect_uri=https://' +
          window.location.hostname +
          '/settings/?approval_prompt=force&scope=activity:read_all,activity:write';
        console.log('connect');
      } else if (clientId !== 0) {
        window.location =
          'https://www.strava.com/oauth/authorize?client_id=' +
          clientId +
          '&response_type=code&redirect_uri=https://' +
          window.location.hostname +
          '/settings/?approval_prompt=force&scope=activity:read_all,activity:write';
        console.log('connect');
      }
    }
  };

  //Nation 함수
  const DetailLevelRange = level => {
    let range = [];

    range.push(
      <MenuItem key={0} value={0} selected={level === 0 ? true : false}>
        {t('Options.viewAll')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={1} value={1} selected={level === 1 ? true : false}>
        {t('Options.simply')}
      </MenuItem>
    );

    return range;
  };

  const handleChangeWebhook = props => event => {
    //axios => set 사용자의 정보 변경
    let power = stravaWebhook.power;
    let detailLevel = stravaWebhook.detailLevel;

    if (props === 'power') {
      power = event.target.checked;
      setStravaWebhook({ ...stravaWebhook, ['power']: power });
    } else if (props === 'detailLevel') {
      detailLevel = event.target.value;
      setStravaWebhook({ ...stravaWebhook, ['detailLevel']: detailLevel });
    }

    const auth_str = 'Bearer '.concat(auth.getToken().token);
    const params = {
      order: 'webhook_update',
      power: power,
      detailLevel: detailLevel
    };

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
        params,
        { headers: { Authorization: auth_str } }
      )
      .then(response => {
        if (response.data.success) {
          auth.clearExtraInfo();
          auth.setExtraInfo({
            ...extraInfo,
            ['webhook_json']: response.data.webhook_json
          });
          console.log(extraInfo);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Typography className={classes.subTitle} component="div" align="left">
          {t('Options.stravaWebhook')}
        </Typography>
        <CardContent>
          <Typography
            component="div"
            variant="caption"
            align="center"
            style={{
              margin: '12px 8px 0px 8px',
              whiteSpace: 'pre-line',
              fontSize: 14,
              lineHeight: '22px'
            }}>
            {t('Options.connectStravaDesc')}
          </Typography>

          <Box
            style={{
              textAlign: 'center',
              margin: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
            <Grid item style={{ fontSize: '14px', color: '#aaaaaa' }}>
              {t('Options.disconnectStravaButton')}
            </Grid>
            <Grid item>
              <Switch
                checked={stravaConnectSwitch}
                onChange={handleSwitchChange}
                color="primary"
                name="strava"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </Grid>
            <Grid item style={{ fontSize: '14px', color: '#aaaaaa' }}>
              {t('Options.connectStravaButton')}
            </Grid>
          </Box>
          <Box display={stravaConnectSwitch ? 'block' : 'none'}>
            <div className={classes.item}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="power"
                    checked={stravaWebhook.power}
                    onChange={handleChangeWebhook('power')}
                  />
                }
                label={t('Options.stravaDescCheck')}
              />
            </div>
            <div
              className={classes.item}
              style={{ display: stravaWebhook.power ? 'block' : 'none' }}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Options.detailLevel')}
                size="small"
                disabled={!stravaWebhook.power}
                value={stravaWebhook.detailLevel}
                onChange={handleChangeWebhook('detailLevel')}
                InputProps={{
                  classes: { input: classes.inputStyle }
                }}>
                {DetailLevelRange(stravaWebhook.detailLevel)}
              </TextField>
            </div>
          </Box>
        </CardContent>
      </Card>

      {/* <Card className={classes.card}>
        <CardHeader
          title={t('Options.stravaWebhook')}
          className={classes.cardHeader}
          action={
            <Switch
              checked={stravaConnectSwitch}
              onChange={handleSwitchChange}
              color="primary"
              name="strava"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
        />
        <Divider />
        <CardContent style={{padding:'0px', marginBottom:'8px'}}>
          <Box display={(stravaConnectSwitch)?'block':'none'}>
            <div className={classes.item}>
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name="power"
                    checked={stravaWebhook.power} 
                    onChange={handleChangeWebhook('power')}
                  />
                }
                label={t('Options.stravaDescCheck')}
              />
            </div>
            <div className={classes.item} style={{'display':(stravaWebhook.power)?'block':'none'}}>
              <TextField className={classes.selectBox}
                variant="outlined" 
                select 
                label={t('Options.detailLevel')} 
                size="small" 
                disabled={!stravaWebhook.power}             
                value={stravaWebhook.detailLevel} 
                onChange={handleChangeWebhook('detailLevel')} 
                InputProps={{
                  classes: { input: classes.inputStyle },
                }}
              >		
                {DetailLevelRange(stravaWebhook.detailLevel)}							
              </TextField>
            </div>
          </Box>
        </CardContent>
      </Card> */}

      <Dialog
        open={stravaConnectDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="strava-dialog-title"
        aria-describedby="strava-dialog-description">
        <DialogTitle id="strava-dialog-title">
          {extraInfo.strava_token !== ''
            ? t('Options.disconnectStrava')
            : t('Options.connectStrava')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="strava-dialog-description">
            {extraInfo.strava_token !== ''
              ? t('Options.alertDisconnectStrava')
              : t('Options.alertReconnectStrava')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            {t('Options.closeAlert')}
          </Button>
          {extraInfo.strava_token !== '' ? (
            <Button
              onClick={handleDisconnectStrava}
              color="primary"
              style={{ margin: '10px 10px 10px 50px', color: '#dddddd' }}>
              {t('Options.disconnectStravaButton')}
            </Button>
          ) : (
            <Button
              onClick={handleConnectStrava}
              color="secondary"
              style={{ margin: '10px 10px 10px 50px' }}>
              {t('Options.connectStravaButton')}
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* <CardActions className={classes.rowReverse}>			
        <Button 
          className={classes.setButton} 
          // onClick={updateActivityOptions} 
          variant="contained" 
          color="primary">
          {t('Options.modify')}
          </Button>
      </CardActions> */}
    </React.Fragment>
  );
};

ConnectOption.propTypes = {
  className: PropTypes.string
};

export default ConnectOption;
