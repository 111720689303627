import React from 'react';
import { makeStyles } from '@mui/styles';

import { Box, Divider, Typography, Paper, Tabs, Tab } from '@mui/material';

import { useTranslation } from 'react-i18next';

import ApexChart from 'react-apexcharts';
import cloneDeep from 'lodash/cloneDeep';
import rankOptions from './rankOptions.js';

const useStyles = makeStyles(theme => ({
  box: {
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px'
  },
  tabs: {
    width: '300px',
    border: '1px #dddddd solid',
    borderRadius: '4px 4px 0 0'
  },
  tabTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  rankText: {
    marginBottom: '12px',
    lineHeight: '24px',
    textAlign: 'center',
    fontSize: '13px'
  },
  rankTextBold: {
    fontSize: '15px',
    margin: '0 4px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 3px 0 3px'
    }
  },
  rankDesc: {
    margin: '0px 10px 8px 10px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left'
  },
  tabContent: {
    width: '300px',
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px'
  }
}));

const RankDialog = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { wkg, weight, p300, p2400, rankArray } = props;
  const [rankTab, setRankTab] = React.useState(0);
  const classes = useStyles();

  function handleDialogClose() {}

  const handleRankChange = (event, newtab) => {
    setRankTab(newtab);
  };

  const raceSeries = [
    {
      name: '5' + t('Common.min') + ' w/kg',
      data: [
        3,
        18,
        35,
        58,
        94,
        138,
        151,
        145,
        119,
        94,
        59,
        38,
        23,
        11,
        7,
        3,
        2,
        1,
        1
      ],
      type: 'area'
    },
    {
      name: '5' + t('Common.min') + ' watts',
      data: [
        -14,
        -29,
        -54,
        -73,
        -88,
        -105,
        -119,
        -125,
        -117,
        -97,
        -71,
        -47,
        -28,
        -16,
        -8,
        -4,
        -2,
        -1,
        -1
      ],
      //data: [-7,-25,-51,-79,-100,-120,-140,-141,-121,-91,-58,-33,-18,-8,-4,-2,-1,-1,-1],
      type: 'area'
    }
  ];

  const ittSeries = [
    {
      name: '40' + t('Common.min') + ' w/kg',
      data: [
        3,
        21,
        43,
        73,
        127,
        153,
        172,
        145,
        107,
        67,
        42,
        24,
        11,
        6,
        3,
        2,
        1,
        1,
        1
      ],
      type: 'area'
    },
    {
      name: '40' + t('Common.min') + ' watts',
      data: [
        -7,
        -26,
        -54,
        -88,
        -117,
        -139,
        -148,
        -138,
        -110,
        -78,
        -47,
        -24,
        -13,
        -6,
        -3,
        -1,
        -1,
        -1,
        -1
      ],
      //			data: [-6,-33,-60,-96,-117,-153,-133,-120,-98,-69,-43,-25,-14,-7,-4,-2,-1,-1,-1],
      type: 'area'
    }
  ];

  /*
	w/kg FTP 6.31
	97.5,93.3,86,73.3,57.9,40.7,26.2,15.5,8.8,4.6,2.1,1.1,0.5,0.2,0.1,0,0,0,

	watts FTP 504
	95.5,90,81.6,70.8,58.2,44.8,31.8,20.9,12.6,7.1,3.8,1.9,0.9,0.4,0.2,0.1,0,0,
	*/
  const wkg300 = Math.round((10 * p300) / weight) / 10;
  const wkg2400 = Math.round((10 * p2400) / weight) / 10;
  const wkg300Array = [
    1.5,
    1.8,
    2.1,
    2.4,
    2.7,
    3.0,
    3.3,
    3.6,
    3.9,
    4.2,
    4.5,
    4.8,
    5.1,
    5.4,
    5.7,
    6.0,
    6.3,
    6.6,
    6.9
  ];
  const p300Array = [
    100,
    125,
    150,
    175,
    200,
    225,
    250,
    275,
    300,
    325,
    350,
    375,
    400,
    425,
    450,
    475,
    500,
    525,
    550
  ];
  const wkg2400Array = [
    0.7,
    1,
    1.3,
    1.6,
    1.9,
    2.2,
    2.5,
    2.8,
    3.1,
    3.4,
    3.7,
    4,
    4.3,
    4.6,
    4.9,
    5.2,
    5.5,
    5.8,
    6.1
  ];
  const pFtpArray = [
    50,
    70,
    90,
    110,
    130,
    150,
    170,
    190,
    210,
    230,
    250,
    270,
    290,
    310,
    330,
    350,
    370,
    390,
    410
  ];
  const itt_rank = rankArray.itt_rank;
  const race_rank = rankArray.race_rank;
  const itt_count = rankArray.itt_count;
  const race_count = rankArray.race_count;
  const wkg300_index = rankArray.wkg300_index;
  const p300_index = rankArray.p300_index;
  const wkg2400_index = rankArray.wkg2400_index;
  const pFtp_index = rankArray.p2400_index;
  const itt_rank_index = rankArray.itt_rank_index;
  const race_rank_index = rankArray.race_rank_index;
  const r_wkg300 = rankArray.r_wkg300;
  const r_wkg2400 = rankArray.r_wkg2400;

  //annotation x 위치
  const raceXWidth = 276.5 / 18;
  const ittXWidth = 276.5 / 18;

  //race 차트 그리기
  const raceOption = cloneDeep(rankOptions.race);
  raceOption.tooltip.custom = function({
    series,
    seriesIndex,
    dataPointIndex,
    w
  }) {
    let dHtml = '';
    if (dataPointIndex == 0) {
      dHtml =
        '<div>' +
        wkg300Array[dataPointIndex] +
        ' w/kg ' +
        t('Common.under') +
        '</div>' +
        '<div>' +
        Math.round(wkg300Array[dataPointIndex] * 73.6) +
        ' w ' +
        t('Common.under') +
        '</div>';
    } else if (dataPointIndex === wkg300Array.length - 1) {
      dHtml =
        '<div>' +
        wkg300Array[dataPointIndex - 1] +
        ' w/kg ' +
        t('Common.over') +
        '</div>' +
        '<div>' +
        Math.round(wkg300Array[dataPointIndex - 1] * 73.6 + 1) +
        ' w ' +
        t('Common.over') +
        '</div>';
    } else {
      dHtml =
        '<div>' +
        Math.round(wkg300Array[dataPointIndex - 1] * 10 + 1) / 10 +
        '~' +
        wkg300Array[dataPointIndex] +
        ' w/kg</div>' +
        '<div>' +
        Math.round(wkg300Array[dataPointIndex - 1] * 73.6 + 1) +
        '~' +
        Math.round(wkg300Array[dataPointIndex] * 73.6) +
        ' w</div>';
    }
    return (
      '<div style="font-size:11px;color:#73838b;padding:4px;">' +
      dHtml +
      '</div>'
    );
  };
  raceOption.annotations.xaxis[0].x = (race_rank_index + 0.5) * raceXWidth;
  raceOption.annotations.xaxis[0].x2 = (race_rank_index + 0.5) * raceXWidth + 1;
  raceOption.annotations.xaxis[0].label.text = race_rank + ' %';
  raceOption.dataLabels.formatter = function(val, opt) {
    if (opt.dataPointIndex === wkg300_index && val > 0) return wkg300 + ' w/kg';
    if (opt.dataPointIndex === p300_index && val < 0) return p300 + ' w';
    return '';
  };

  //ITT 차트 그리기
  const ittOption = cloneDeep(rankOptions.itt);
  ittOption.tooltip.custom = function({
    series,
    seriesIndex,
    dataPointIndex,
    w
  }) {
    let dHtml = '';
    if (dataPointIndex == 0) {
      dHtml =
        '<div>' +
        wkg2400Array[dataPointIndex] +
        ' w/kg ' +
        t('Common.under') +
        '</div>' +
        '<div>' +
        Math.round(wkg2400Array[dataPointIndex] * 73.6) +
        ' w ' +
        t('Common.under') +
        '</div>';
    } else if (dataPointIndex === wkg2400Array.length - 1) {
      dHtml =
        '<div>' +
        wkg2400Array[dataPointIndex - 1] +
        ' w/kg ' +
        t('Common.over') +
        '</div>' +
        '<div>' +
        Math.round(wkg2400Array[dataPointIndex - 1] * 73.6 + 1) +
        ' w ' +
        t('Common.over') +
        '</div>';
    } else {
      dHtml =
        '<div>' +
        Math.round(wkg2400Array[dataPointIndex - 1] * 10 + 1) / 10 +
        '~' +
        wkg2400Array[dataPointIndex] +
        ' w/kg</div>' +
        '<div>' +
        Math.round(wkg2400Array[dataPointIndex - 1] * 73.6 + 1) +
        '~' +
        Math.round(wkg2400Array[dataPointIndex] * 73.6) +
        ' w</div>';
    }

    return (
      '<div style="font-size:11px;color:#73838b;padding:4px;">' +
      dHtml +
      '</div>'
    );
  };
  ittOption.annotations.xaxis[0].x = (itt_rank_index + 0.5) * ittXWidth;
  ittOption.annotations.xaxis[0].x2 = (itt_rank_index + 0.5) * ittXWidth + 1;
  ittOption.annotations.xaxis[0].label.text = itt_rank + ' %';
  ittOption.dataLabels.formatter = function(val, opt) {
    if (opt.dataPointIndex === wkg2400_index && val > 0)
      return wkg2400 + ' w/kg';
    if (opt.dataPointIndex === pFtp_index && val < 0) return p2400 + ' w';
    return '';
  };

  return (
    <React.Fragment>
      <Box>
        <Paper elevation={0} className={classes.tabs}>
          <Tabs
            value={rankTab}
            onChange={handleRankChange}
            indicatorColor="primary"
            textColor="primary"
            centered>
            <Tab label={t('Dashboard.raceRankTab')} />
            <Tab label={t('Dashboard.ittRankTab')} />
          </Tabs>
        </Paper>
        <Box className={classes.tabContent}>
          {rankTab === 0 ? (
            <React.Fragment>
              <Typography
                className={classes.tabTitle}
                style={{ marginBottom: '-20px' }}
                component="div">
                {t('Dashboard.raceWorldData')}
              </Typography>
              <ApexChart
                options={raceOption}
                series={raceSeries}
                height={'240px'}
              />
              <Divider />
              <Box className={classes.rankText}>
                <Typography className={classes.tabTitle} component="div">
                  {t('Dashboard.raceMyRank')}
                </Typography>
                <div>
                  {t('Dashboard.raceExpPower')}{' '}
                  <Typography className={classes.rankTextBold} component="span">
                    {p300}
                  </Typography>
                  w
                </div>
                <div>
                  {t('Dashboard.raceExpWkg')}{' '}
                  <Typography className={classes.rankTextBold} component="span">
                    {wkg300}
                  </Typography>
                  w/kg
                </div>
                <div>
                  {t('Dashboard.race100000')}{' '}
                  <Typography className={classes.rankTextBold} component="span">
                    {race_count}
                  </Typography>
                  {t('Common.th')}
                </div>
                <div style={{ fontSize: '12px', color: '#83ccc5' }}>
                  ({t('Dashboard.raceWeightedRank')} = {r_wkg300} w/kg)
                </div>
              </Box>
              <Divider />
              <Box>
                <Typography className={classes.tabTitle} component="div">
                  {t('Dashboard.whatIsRaceRank')}
                </Typography>
                <Typography className={classes.rankDesc} component="div">
                  {t('Dashboard.raceRankDesc')}
                </Typography>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography
                className={classes.tabTitle}
                style={{ marginBottom: '-20px' }}
                component="div">
                {t('Dashboard.ittWorldData')}
              </Typography>
              <ApexChart
                options={ittOption}
                series={ittSeries}
                height={'240px'}
              />
              <Divider />
              <Box className={classes.rankText}>
                <Typography className={classes.tabTitle} component="div">
                  {t('Dashboard.ittMyRank')}
                </Typography>
                <div>
                  {t('Dashboard.ittExpPower')}{' '}
                  <Typography className={classes.rankTextBold} component="span">
                    {p2400}
                  </Typography>
                  w
                </div>
                <div>
                  {t('Dashboard.ittExpWkg')}{' '}
                  <Typography className={classes.rankTextBold} component="span">
                    {wkg2400}
                  </Typography>
                  w/kg
                </div>
                <div>
                  {t('Dashboard.itt100000')}
                  <Typography className={classes.rankTextBold} component="span">
                    {itt_count}
                  </Typography>
                  {t('Common.th')}
                </div>
                <div style={{ fontSize: '12px', color: '#83ccc5' }}>
                  ({t('Dashboard.ittWeightedRank')} = {r_wkg2400} w/kg)
                </div>
              </Box>
              <Divider />
              <Box>
                <Typography className={classes.tabTitle} component="div">
                  {t('Dashboard.whatIsIttRank')}
                </Typography>
                <Typography className={classes.rankDesc} component="div">
                  {t('Dashboard.ittRankDesc')}
                </Typography>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default RankDialog;
