import React, { useEffect, useState } from 'react';
import AdminModal from 'views/Peloton/Common/AdminModal';
import CodeMaker from './CodeMaker';
import { invitationCodeListApi } from 'utils/clientApis';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Box,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  Paper
} from '@mui/material';

export default function Invitation() {
  const [codes, setCodes] = useState();
  const classes = useStyles();

  useEffect(() => {
    handleGetInvitationCode();
  }, []);

  const handleGetInvitationCode = async () => {
    try {
      const response = await invitationCodeListApi();
      if (response) {
        setCodes(response.data.response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center" style={{ minWidth: '80px' }}>
                ID
              </TableCell>
              <TableCell align="center" style={{ minWidth: '80px' }}>
                초대 코드
              </TableCell>
              <TableCell align="center" style={{ minWidth: '80px' }}>
                사용 여부
              </TableCell>
            </TableRow>
          </TableHead>
          {/*  */}
          <TableBody>
            {codes &&
              codes.map((code, idx) => (
                <TableRow key={idx}>
                  <TableCell
                    align="center"
                    style={{ minWidth: '80px' }}
                    component="th"
                    scope="row">
                    {code.id}
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ minWidth: '80px', color: '#4DB6AC' }}>
                    {code.inviteCode}
                  </TableCell>
                  <TableCell align="center" style={{ minWidth: '80px' }}>
                    {code.used ? '완료' : '사용가능'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.createBox}>
        <CodeMaker />
      </Box>
    </React.Fragment>
  );
}

const useStyles = makeStyles(theme => ({
  createBox: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0',
    marginTop: '16px',
    '& > *': {
      padding: '8px 8px'
    }
  }
}));
