import React, { useState } from 'react';
import { AppBar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { MobileMenuLayout } from './MobileMenu';
import MenuLayout from './MenuLayout';

export default function NavigationBar() {
  const [isOpen, setIsOpen] = useState(false);

  const theme = useTheme();
  const isCompact = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <AppBar
      position="fixed"
      enableColorOnDark
      sx={{
        top: 0,
        left: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.boxShadow.secondary
      }}>
      {isCompact ? (
        <MobileMenuLayout isOpen={isOpen} setIsOpen={setIsOpen} />
      ) : (
        <MenuLayout />
      )}
    </AppBar>
  );
}
