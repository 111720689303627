import React, { useState } from 'react';
import { Redirect, Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';
import {
  AppBar,
  Card,
  CardContent,
  Toolbar,
  Typography,
  Box,
  Button,
  Badge,
  Drawer,
  Hidden,
  IconButton
} from '@mui/material';

// icons
import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';

import auth from '../../../../utils/auth';
//import i18n from "i18next";
import { useTranslation } from 'react-i18next';
import { Profile } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 1px 5px #999999'
  },
  headerBar: {
    textAlign: 'center',
    padding: '6px'
  },
  appBar: {
    height: 'auto !important',
    display: 'block',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.boxShadow.secondary,
    [theme.breakpoints.up('lg')]: {
      zIndex: 1300
    }
  },
  card: {
    width: '100%',
    padding: '16px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: '1200px',
    padding: 0,
    margin: '0 auto',
    minHeight: '48px !important',
    [theme.breakpoints.up('lg')]: {
      justifyContent: 'space-between',
      minHeight: '60px !important'
    }
  },
  flexLeft: {
    flexBasis: '20%',
    textAlign: 'left'
  },
  flexRight: {
    flexBasis: '20%',
    textAlign: 'right',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'right'
    }
  },
  logoFlex: {
    flexBasis: '60%',
    backgroundColor: theme.palette.background.paper,
    textAlign: 'center',
    [theme.breakpoints.up('lg')]: {
      textAlign: 'left'
    }
  },
  logoImage: {
    width: '120px !important'
    // [theme.breakpoints.up('lg')]: {
    //   width: 136
    // }
  },
  iconButton: {
    color: 'primary'
  },
  drawerWrap: {
    width: 420,
    [theme.breakpoints.down('md')]: {
      width: 300
    }
  },
  logout: {
    color: '#999999',
    fontSize: '13px',
    background: '#f5f5f5'
  }
}));

const Topbar = props => {
  //다국어적용 {t('page.key')}
  const { t } = useTranslation(['page']);

  const { className, onSidebarOpen, ...rest } = props;
  const classes = useStyles();
  const [notifications] = useState([]);
  const [customOptionsOpen, setCustomOptionsOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setCustomOptionsOpen(!customOptionsOpen);
  };

  const extraInfo = auth.getExtraInfo();

  return (
    <AppBar {...rest} className={classes.appBar} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <Hidden only={['lg', 'xl']}>
          <Box className={classes.flexLeft}>
            <IconButton
              color="primary"
              className={classes.iconButton}
              onClick={onSidebarOpen}
              size="large">
              <MenuIcon htmlColor="primary" />
            </IconButton>
          </Box>
        </Hidden>
        <RouterLink to="/" className={classes.logoFlex}>
          <img
            alt="Logo"
            src="/images/logos/logo.png"
            className={classes.logoImage}
          />
        </RouterLink>

        {extraInfo !== null && (
          <Box className={classes.flexRight}>
            <Profile />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

export default Topbar;
