import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ViewState } from '@devexpress/dx-react-scheduler';

import {
  Scheduler,
  MonthView,
  Toolbar,
  DateNavigator,
  TodayButton
} from '@devexpress/dx-react-scheduler-material-ui';

import { makeStyles, useTheme } from '@mui/styles';
import { Box, Typography, FormControl, Select, MenuItem } from '@mui/material';

import { PolylineToSVG } from 'utils/polyline';
import auth from 'utils/auth';
import { kilometerToMile, meterToFeet } from 'utils/unitCalculation';

import { getWeekOfMonth } from 'utils/calendar';

const useStyles = makeStyles(theme => ({
  timeTableContainer: {
    width: '100%'
  },
  itemsContainer: {
    width: '100%'
  },
  weekAccumulateInfoSm: {
    color: theme.palette.text.primary,
    display: 'flex',
    marginRight: '8px'
  },
  icon: {
    fontSize: '12px'
  },
  weekAccumulateIconSm: {
    marginRight: '2px',
    fontSize: '12px'
  },
  weekAccumulateInfoTextSm: {
    fontWeight: 'bold',
    fontSize: '12px'
  },
  selector: {
    '&> div': {
      padding: '8px 12px',
      fontSize: '0.75rem'
    }
  },
  info: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0
    }
  },
  cardData: {
    color: theme.palette.text.primary,
    display: 'flex',
    gap: '4px'
  },
  cardDataText: {
    fontSize: '12px'
  },
  dateContainer: {
    display: 'flex'
  },
  dateText: {
    marginRight: '4px'
  },
  weatherContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  iconColor: {
    color: theme.palette.text.primary
  },
  weatherIcon: {
    width: '24px',
    height: '24px'
  },
  temperatureText: {
    marginRight: '4px'
  },
  itemContainer: {
    padding: '8px 16px',
    borderTop: `1px solid ${theme.palette.background.colored.dark}`
  },
  itemTitleContainer: {
    display: 'flex'
  },
  polylineBox: {
    width: '24px',
    height: '24px'
  },
  textTitle: {
    marginLeft: '8px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  complexContainer: {
    marginTop: '16px'
  },
  weeklyContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.background.colored.dark}`,
    padding: '8px',
    background: theme.palette.background.colored.light
  },
  itemHead: {
    display: 'flex',
    padding: '8px',
    borderTop: `1px solid ${theme.palette.background.colored.dark}`,
    justifyContent: 'space-between'
  }
}));

export default function CalendarSmall({
  locale,
  monthData,
  schedulerData,
  currentDate,
  isWkg,
  setMonthCalendarDate,
  handleOpenActivityStatus
}) {
  const theme = useTheme();
  const classes = useStyles();

  const { unit } = auth.getExtraInfo();
  const { t } = useTranslation(['page']);

  const [type, setType] = useState('default');

  const days = {
    'kr-KR': ['일', '월', '화', '수', '목', '금', '토'],
    'en-US': ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    'ja-JA': ['月', '火', '水', '木', '金', '土', '日']
  };

  const dateUnit = {
    'kr-KR': '일',
    'en-US': null,
    'ja-JA': '日'
  };

  const getMonthCharacter = month => {
    if (locale === 'kr-KR') return `${month}월`;
    if (locale === 'ja-JA') return `${month}月`;

    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    return months[month - 1];
  };

  const handleTypeChange = event => {
    setType(event.target.value);
  };

  const getFontColor = (today, otherMonth) => {
    if (otherMonth) return theme.palette.text.secondary;
    if (today) return theme.palette.primary.main;
    return theme.palette.text.primary;
  };

  const TimeTableLayout = ({ cellsData }) => {
    return (
      <>
        {cellsData.map(week => {
          const firstDateOfWeek = week[0].startDate;

          let weekData;

          if (
            monthData.has(
              `${firstDateOfWeek.getFullYear()}-${firstDateOfWeek.getMonth()}`
            )
          )
            weekData = monthData.get(
              `${firstDateOfWeek.getFullYear()}-${firstDateOfWeek.getMonth()}`
            ).weeks[getWeekOfMonth(firstDateOfWeek)];

          return (
            <React.Fragment key={'week' + firstDateOfWeek}>
              {week.map(({ startDate, today, otherMonth }) => {
                const targetDate = new Date(startDate);

                const year = targetDate.getFullYear();
                const month = targetDate.getMonth() + 1;
                const date = targetDate.getDate();
                const day = targetDate.getDay();

                const dateItem = schedulerData.filter(({ startDate }) => {
                  const filterDataDate = new Date(startDate);

                  return filterDataDate.getTime() === targetDate.getTime();
                });

                return (
                  <>
                    {date === 1 && (
                      <Box
                        sx={{
                          borderTop: '1px solid #eeeeee',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                        <Typography component="span" role="img">
                          🗓️
                        </Typography>
                        <Typography
                          sx={{
                            color: otherMonth
                              ? theme.palette.text.secondary
                              : theme.palette.text.primary,
                            padding: '8px',
                            textAlign: 'center',
                            fontWeight: 600
                          }}>
                          {getMonthCharacter(month)}
                        </Typography>
                      </Box>
                    )}
                    <Box key={startDate} className={classes.timeTableContainer}>
                      <Box className={classes.itemHead}>
                        {/* 날짜 */}
                        <Box className={classes.dateContainer}>
                          <Typography
                            className={classes.dateText}
                            style={{
                              color: getFontColor(today, otherMonth)
                            }}>
                            {days[locale][day]}
                          </Typography>
                          {date === 1 ? (
                            <Typography
                              className={classes.dateText}
                              style={{
                                color: getFontColor(today, otherMonth)
                              }}>
                              {getMonthCharacter(month)}
                            </Typography>
                          ) : null}
                          <Typography
                            style={{
                              color: getFontColor(today, otherMonth)
                            }}>
                            {date.toString().padStart(2, '0')}
                            {dateUnit[locale]}
                          </Typography>
                        </Box>
                      </Box>
                      {/* 아이템들 */}
                      <Box className={classes.itemsContainer}>
                        {dateItem.length > 0
                          ? dateItem[0].items.map(
                              ({
                                id,
                                title,
                                summaryPolyline,
                                backgroundColor,
                                activityId,
                                averageWatts,
                                wPerKg,
                                distance,
                                intensity,
                                movingTime,
                                tss,
                                np
                              }) => {
                                return (
                                  <Box
                                    key={id}
                                    className={classes.itemContainer}
                                    onClick={() =>
                                      handleOpenActivityStatus(
                                        activityId,
                                        title
                                      )
                                    }>
                                    <Box className={classes.itemTitleContainer}>
                                      <Box className={classes.polylineBox}>
                                        <PolylineToSVG
                                          width={24}
                                          height={24}
                                          polyline={summaryPolyline}
                                          backgroundColor={backgroundColor}
                                        />
                                      </Box>
                                      <Typography className={classes.textTitle}>
                                        {title}
                                      </Typography>
                                    </Box>
                                    {type === 'complex' ? (
                                      <Box
                                        display="grid"
                                        gridTemplateColumns="repeat(2, 1fr)"
                                        className={classes.complexContainer}>
                                        <Box
                                          gridColumn="span 1"
                                          className={classes.cardData}>
                                          <span
                                            role="img"
                                            aria-label="distance"
                                            className={classes.icon}>
                                            📍
                                          </span>
                                          <Typography
                                            variant="body1"
                                            color="textPrimary"
                                            className={classes.cardDataText}>
                                            {`${t('Dashboard.distance')}: ${
                                              unit === 'metric'
                                                ? `${distance}km`
                                                : `${kilometerToMile(
                                                    distance
                                                  ).toFixed(2)}mi`
                                            }`}
                                          </Typography>
                                        </Box>
                                        <Box
                                          gridColumn="span 1"
                                          className={classes.cardData}>
                                          <span
                                            role="img"
                                            aria-label="moving time"
                                            className={classes.icon}>
                                            ⏱️
                                          </span>
                                          <Typography
                                            variant="body1"
                                            color="textPrimary"
                                            className={classes.cardDataText}>
                                            {`${t('Dashboard.duration')}: ${(
                                              movingTime / 60
                                            ).toFixed(0)}min`}
                                          </Typography>
                                        </Box>
                                        <Box
                                          gridColumn="span 1"
                                          className={classes.cardData}>
                                          <span
                                            role="img"
                                            aria-label="intensity"
                                            className={classes.icon}>
                                            🔥
                                          </span>
                                          <Typography
                                            variant="body1"
                                            color="textPrimary"
                                            className={classes.cardDataText}>
                                            {`${t(
                                              'Dashboard.if'
                                            )}: ${intensity}`}
                                          </Typography>
                                        </Box>
                                        <Box
                                          gridColumn="span 1"
                                          className={classes.cardData}>
                                          <span
                                            role="img"
                                            aria-label="tss"
                                            className={classes.icon}>
                                            📊
                                          </span>
                                          <Typography
                                            variant="body1"
                                            color="textPrimary"
                                            className={classes.cardDataText}>
                                            {`${t('Dashboard.tss')}: ${tss}`}
                                          </Typography>
                                        </Box>
                                        <Box
                                          gridColumn="span 1"
                                          className={classes.cardData}>
                                          <span
                                            role="img"
                                            aria-label="average watts"
                                            className={classes.icon}>
                                            ⚡
                                          </span>
                                          <Typography
                                            variant="body1"
                                            color="textPrimary"
                                            className={classes.cardDataText}>
                                            {isWkg
                                              ? `${t(
                                                  'Dashboard.avgPower'
                                                )}: ${wPerKg}w/kg`
                                              : `${t(
                                                  'Dashboard.avgPower'
                                                )}: ${averageWatts}w`}
                                          </Typography>
                                        </Box>
                                        <Box
                                          gridColumn="span 1"
                                          className={classes.cardData}>
                                          <span
                                            role="img"
                                            aria-label="RP"
                                            className={classes.icon}>
                                            ⚡
                                          </span>
                                          <Typography
                                            variant="body1"
                                            color="textPrimary"
                                            className={classes.cardDataText}>
                                            {`${t('Dashboard.np')}: ${np}`}
                                          </Typography>
                                        </Box>
                                      </Box>
                                    ) : null}
                                  </Box>
                                );
                              }
                            )
                          : null}
                      </Box>
                    </Box>
                  </>
                );
              })}
              {/* 주간 종합 */}
              <Box className={classes.weeklyContainer}>
                <Box className={classes.weekAccumulateInfoSm}>
                  <span
                    role="img"
                    aria-label="tss"
                    className={classes.weekAccumulateIconSm}>
                    📊
                  </span>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.weekAccumulateInfoTextSm}>
                    {weekData && weekData.movingTime > 0
                      ? `${weekData.tss}tss`
                      : '0tss'}
                  </Typography>
                </Box>

                <Box className={classes.weekAccumulateInfoSm}>
                  <span
                    role="img"
                    aria-label="distance"
                    className={classes.weekAccumulateIconSm}>
                    📍
                  </span>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.weekAccumulateInfoTextSm}>
                    {weekData && weekData.movingTime > 0
                      ? unit === 'metric'
                        ? `${weekData.distance.toFixed(2)}km`
                        : `${kilometerToMile(weekData.distance).toFixed(2)}mi`
                      : unit === 'metric'
                      ? '0km'
                      : '0mi'}
                  </Typography>
                </Box>

                <Box className={classes.weekAccumulateInfoSm}>
                  <span
                    role="img"
                    aria-label="moving time"
                    className={classes.weekAccumulateIconSm}>
                    ⏱️
                  </span>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.weekAccumulateInfoTextSm}>
                    {weekData && weekData.movingTime > 0
                      ? `${Math.floor(weekData.movingTime / 60)}min`
                      : '0min'}
                  </Typography>
                </Box>

                <Box className={classes.weekAccumulateInfoSm}>
                  <span
                    role="img"
                    aria-label="elevation gain"
                    className={classes.weekAccumulateIconSm}>
                    ⛰️
                  </span>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    className={classes.weekAccumulateInfoTextSm}>
                    {weekData && weekData.movingTime > 0
                      ? unit === 'metric'
                        ? `${weekData.elevation}m`
                        : `${meterToFeet(weekData.elevation).toFixed(0)}ft`
                      : unit === 'metric'
                      ? '0m'
                      : '0ft'}
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          );
        })}
      </>
    );
  };

  const Layout = ({ timeTableComponent: TimeTable }) => {
    return (
      <Box>
        <TimeTable />
      </Box>
    );
  };

  const ToolbarRoot = ({ children }) => {
    return (
      <Toolbar.Root>
        {children}
        <Box>
          <FormControl>
            <Select
              className={classes.selector}
              labelId="select-type"
              id="select-type"
              value={type}
              onChange={handleTypeChange}
              MenuProps={{ MenuListProps: { style: { padding: 0 } } }}>
              <MenuItem value="default">
                <Typography>{t('Dashboard.default')}</Typography>
              </MenuItem>
              <MenuItem value="complex">
                <Typography>{t('Dashboard.complex')}</Typography>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Toolbar.Root>
    );
  };

  return (
    <>
      <Scheduler
        currentView="agenda"
        data={schedulerData}
        firstDayOfWeek={1}
        locale={locale}>
        <ViewState
          defaultCurrentDate={currentDate}
          onCurrentDateChange={date => setMonthCalendarDate(date)}
        />

        <MonthView
          timeTableLayoutComponent={TimeTableLayout}
          layoutComponent={Layout}
        />

        <Toolbar rootComponent={ToolbarRoot} />
        <DateNavigator />
        <TodayButton />
      </Scheduler>
    </>
  );
}
