export const useCopyLink = () => {
  const copyLinkToClipboard = async () => {
    const currentUrl = window.location.href;
    let state = false;

    await navigator.clipboard
      .writeText(currentUrl)
      .then(() => {
        state = true;
      })
      .catch(error => {
        console.error(
          '문제가 발생 했습니다. 잠시 후 다시 시도해주세요.',
          error
        );
        state = false;
      });

    return state;
  };

  return { copyLinkToClipboard };
};
