import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { Card, CardHeader, CardContent } from '@mui/material';

import { useTranslation } from 'react-i18next';
import ApexChart from 'react-apexcharts';

const useStyles = makeStyles(theme => ({
  root: {
    height: 408,
    [theme.breakpoints.down('xl')]: {
      height: 344
    },
    [theme.breakpoints.down('lg')]: {
      height: 340
    },
    [theme.breakpoints.down('md')]: {
      height: 300
    }
  },
  cardHeader: {
    color: '#90a4ae',
    fontSize: '15px',
    [theme.breakpoints.down('md')]: {
      marginTop: '-4px',
      fontSize: '14px'
    }
  },
  chartContainer: {
    margin: '0 auto',
    paddingTop: '0px',
    textAlign: 'center',
    width: 380,
    height: 380,
    [theme.breakpoints.down('xl')]: {
      width: 320,
      height: 320
    },
    [theme.breakpoints.down('md')]: {
      width: 280,
      height: 280
    }
  },
  stats: {
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const RiderType = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const options = {
    chart: {
      type: 'radar',
      dropShadow: {
        enabled: false
      },
      toolbar: {
        show: false
      },
      offsetY: -10
    },
    colors: ['#76b5e8', '#ffd357'],
    tooltip: {
      x: {
        show: true,
        formatter: function(val) {
          let type = '';
          switch (val) {
            case 'ALLR':
              type = t('Dashboard.allr');
              break;
            case 'CLMB':
              type = t('Dashboard.clmb');
              break;
            case 'TTST':
              type = t('Dashboard.ttst');
              break;
            case 'PCH':
              type = t('Dashboard.pch');
              break;
            case 'RSPT':
              type = t('Dashboard.rspt');
              break;
            case 'TSPT':
              type = t('Dashboard.tspt');
              break;
          }
          return type;
        }
      },
      y: {
        formatter: function(val) {
          return val + 'pt';
        },
        title: {
          formatter: seriesName => seriesName
        }
      }
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColors: '#e9e9e9',
          fill: {
            colors: ['#f8f8f8', '#fff']
          }
        }
      }
    },
    markers: {
      size: 5,
      colors: ['#fff'],
      strokeColor: ['#76b5e8', '#ffd357'],
      strokeWidth: 2
    },
    legend: {
      show: true,
      offsetY: -10
    },
    stroke: {
      width: 2
    },
    fill: {
      opacity: [0.2, 0.3]
    },
    xaxis: {
      categories: ['ALLR', 'CLMB', 'TTST', 'PCH', 'TSPT', 'RSPT']
    },
    yaxis: {
      tickAmount: 5,
      min: 0,
      max: 10
    }
  };

  const { className, rider_type, rider_ability, ...rest } = props;

  const classes = useStyles();

  let riderType = [];

  //colors={[ '#76b5e8', '#F06292', '#ffd357' ]}
  //color #4db6ac #76b5e8 #F06292
  // climber, sprinter, allrounder,
  if (rider_type && rider_ability) {
    riderType = [
      {
        name: t('Dashboard.ability'),
        data: [
          rider_ability.allr,
          rider_ability.clmb,
          rider_ability.ttst,
          rider_ability.pch,
          rider_ability.tspt,
          rider_ability.rspt
        ]
      },
      {
        name: t('Dashboard.tendency'),
        data: [
          rider_type.allr,
          rider_type.clmb,
          rider_type.ttst,
          rider_type.pch,
          rider_type.tspt,
          rider_type.rspt
        ]
      }
    ];
  } else {
    riderType = [
      { name: t('Dashboard.ability'), data: [0, 0, 0, 0, 0, 0] },
      { name: t('Dashboard.tendency'), data: [0, 0, 0, 0, 0, 0] }
    ];
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          false
          /*
          <IconButton size="small">
            <RefreshIcon />
          </IconButton>
          */
        }
        classes={{ title: classes.cardHeader }}
        title={t('Dashboard.ability') + ' & ' + t('Dashboard.tendency')}
      />
      <CardContent className={classes.chartContainer}>
        <ApexChart
          type="radar"
          className={classes.radar}
          options={options}
          series={riderType}
          height={'100%'}
        />
      </CardContent>
    </Card>
  );
};

RiderType.propTypes = {
  className: PropTypes.string
};

export default RiderType;
