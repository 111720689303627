import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  IconButton,
  Menu,
  Box,
  MenuItem,
  Typography,
  Container
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import { editPostCommentApi, deletePostCommentApi } from 'utils/clientApis';
import { useFormatDate } from 'utils/useFormatDate';
import auth from 'utils/auth';

import DeleteModal from 'views/Peloton/Common/DeleteModal';
import CommentWrite from 'views/Peloton/Common/CommentWrite';

const useStyles = makeStyles(theme => ({
  body: {
    '& > *': {
      marginBottom: '16px'
    }
  },
  commentView: {
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    padding: '8px 12px',
    justifyContent: 'space-between',
    borderBottom: '1px #dddddd solid',
    '& > *': {
      marginRight: '0px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '16px 24px'
    }
  },
  commentContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    width: '100%',
    marginBottom: '8px'
  },
  replyButton: {
    whiteSpace: 'nowrap'
  }
}));

function Comment({ comment, postId, handleGetComments }) {
  const ITEM_HEIGHT = 48;

  const { ID: userId } = auth.getUserInfo();

  const classes = useStyles();
  const { t } = useTranslation(['page']);
  const { formatDateWithTime } = useFormatDate();

  const [isEditMode, setIsEditMode] = useState(false);
  const [commentState, setCommentState] = useState({
    data: {
      contents: comment.contents
    },
    errors: {},
    touched: {},
    isValid: false
  });
  const [commentMenuState, setCommentMenuState] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);

  const handleEditChangeComment = event => {
    const { name, value } = event.target;
    setCommentState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value
      },
      touched: {
        ...prevState.touched,
        [name]: true
      }
    }));
  };

  // comment 수정
  const submitEditComment = async (commentState, commentId) => {
    try {
      const response = await editPostCommentApi(
        postId,
        commentId,
        commentState.data
      );

      if (response.data.success) {
        handleGetComments();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const resetCommentState = () => {
    setCommentState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        contents: comment.contents
      }
    }));
  };

  // 코멘트 메뉴 클릭 핸들러
  const handleCommentMenuOpen = event => {
    // 현재 클릭된 코멘트에 대한 메뉴 상태만 업데이트
    setCommentMenuState({
      anchorEl: event.currentTarget,
      open: true // 메뉴를 열 상태로 설정
    });
  };

  // 코멘트 메뉴 닫기 핸들러
  const handleCommentMenuClose = () => {
    // 현재 클릭된 코멘트에 대한 메뉴 상태를 닫힘 상태로 업데이트
    setCommentMenuState(prev => ({
      ...prev,
      open: false // 메뉴를 닫는 상태로 설정
    }));
  };

  const handleClickDeleteModalOpen = () => {
    setDeleteOpen(true);
  };

  const handleDeleteSession = async () => {
    const response = await deletePostCommentApi(postId, comment.id);
    if (response) {
      handleGetComments();
      setDeleteOpen(false);
    }
  };

  function FormatCommentDate({ dateData }) {
    const formattedDate = formatDateWithTime(dateData, 'Asia/Seoul');
    return <>{formattedDate}</>;
  }

  const isWriter = userId === comment.commentUserId;

  return (
    <Container className={classes.commentView}>
      <Box style={{ width: '100%' }}>
        <div className={classes.commentContainer}>
          {/* 댓글 작성자 정보  */}
          <div style={{ display: 'flex', width: '100%' }}>
            <Avatar
              src={comment?.commentUserProfileImage}
              variant="circular"
              style={{
                width: '36px',
                height: '36px',
                marginRight: '8px',
                cursor: 'pointer'
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}>
              {comment?.commentUserDisplayName && (
                <Typography variant="h6" color="textPrimary">
                  {!comment?.commentUserDisplayName
                    ? '탈퇴한 회원'
                    : comment?.commentUserDisplayName}
                </Typography>
              )}
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ cursor: 'default' }}>
                <FormatCommentDate dateData={comment?.regDate} />
              </Typography>
            </div>
          </div>
          {/* 메뉴 아이콘 */}
          {comment.used && isWriter && (
            <>
              {/* 아이콘 */}
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={event => handleCommentMenuOpen(event)}
                size="large">
                <MoreVertIcon />
              </IconButton>

              {/* 메뉴 */}
              {commentMenuState?.open && (
                <Menu
                  id="long-menu"
                  anchorEl={commentMenuState?.anchorEl}
                  keepMounted
                  open={!!commentMenuState?.open}
                  onClose={() => handleCommentMenuClose()}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 4.5,
                      width: '15ch',
                      borderRadius: '24px'
                    }
                  }}>
                  {/* 작성자인 경우 수정 메뉴 아이템 */}
                  {isWriter ? (
                    <MenuItem
                      onClick={() => {
                        setIsEditMode(true);
                        handleCommentMenuClose();
                      }}>
                      {t('Notice.modify')}
                    </MenuItem>
                  ) : null}

                  {/* 작성자인 경우 삭제 메뉴 아이템, 관리자인 경우 삭제(관리자) 메뉴 아이템 */}
                  {isWriter ? (
                    <MenuItem onClick={() => handleClickDeleteModalOpen()}>
                      {t('Notice.delete')}
                    </MenuItem>
                  ) : null}
                </Menu>
              )}
            </>
          )}
        </div>

        {/* 댓글 내용 */}
        <Box className={classes.body}>
          {comment.used ? (
            isEditMode ? (
              <CommentWrite
                type="edit"
                value={commentState.data.contents}
                onEditChange={handleEditChangeComment}
                onButtonClick={() => {
                  submitEditComment(commentState, comment.id);
                  setIsEditMode(false);
                }}
                onEditCancel={() => {
                  resetCommentState();
                  setIsEditMode(false);
                }}
              />
            ) : (
              <>
                <Typography variant="body1" color="textPrimary">
                  {comment.contents?.split('\n').map((paragraph, index) => (
                    <React.Fragment key={index}>
                      {paragraph}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              </>
            )
          ) : (
            <Typography variant="body1" color="textPrimary">
              {t('Notice.deletedComments')}
            </Typography>
          )}
        </Box>
      </Box>

      {deleteOpen && (
        <DeleteModal
          handleDeleteSession={handleDeleteSession}
          openState={deleteOpen}
          contentText={t('Notice.deleteCommentCheck')}
          setOpen={setDeleteOpen}
        />
      )}
    </Container>
  );
}

export default Comment;
