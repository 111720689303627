//import React from "react";
//import ReactDOM from "react-dom";
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './locales/en';
import ko from './locales/ko';
import jp from './locales/jp';
import es from './locales/es';
import zhCn from './locales/zh-cn';
import zhHk from './locales/zh-hk';

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: en,
      ko: ko,
      jp: jp,
      es: es,
      zhCn: zhCn,
      zhHk: zhHk
    },
    //lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',
    ns: ['SideBar', 'SignIn', 'SignUp', 'landing', 'privacy', 'terms'],
    interpolation: {
      escapeValue: true
    }
  });

export default i18n;
