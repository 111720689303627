import React, { useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import RideOnTab from './RideOnTab.js';

//import RideOnTab from '../Workout/index.js';

import { Box, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  },
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '16px',
    color: '#73838b',
    fontWeight: '500',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '20px'
    }
  }
}));

const RideOn = () => {
  const classes = useStyles();

  //다국어적용
  const { t } = useTranslation(['page']);

  //const [popoverOpenInvite, setPopoverOpenInvite] = React.useState(false);
  useEffect(() => {}, []);

  return (
    <div className={classes.root}>
      <Box className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          Ride On
        </Typography>
      </Box>
      <div className={classes.whiteLine}></div>
      <RideOnTab />
    </div>
  );
};

RideOn.propTypes = {
  history: PropTypes.object
};

export default withRouter(RideOn);
