import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, CircularProgress, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CTAButton from '../Common/CTAButton';
import { fetchBlogs } from 'hooks/useBlogs';
import { useTranslation } from 'react-i18next';
import { DynamicFontTypography } from 'components/Typography';
import { makeStyles } from '@mui/styles';
// import { blogList } from 'data';

const useStyles = makeStyles(theme => ({
  sub: {
    fontSize: 'clamp(16px, 1.3vw, 18px)',
    lineHeight: 'clamp(22px, 1.5vw, 24px)'
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'visible',
    [theme.breakpoints.up(0)]: {
      flexDirection: 'column',
      gap: 16
    },
    [theme.breakpoints.between(520, 1200)]: {
      flexDirection: 'row',
      gap: 18
    },
    [theme.breakpoints.up(1100)]: {
      flexDirection: 'row',
      gap: 24
    }
  },
  cardStack: {
    flexGrow: 1,
    [theme.breakpoints.up(0)]: {
      minHeight: '200px'
    },
    [theme.breakpoints.between(520, 1200)]: {
      minHeight: '320px'
    }
  },
  cardImage: {
    display: 'flex',
    width: '100%',
    borderRadius: '4px',
    objectFit: 'cover',
    objectPosition: 'center',
    bgcolor: theme.palette.background.paper,
    [theme.breakpoints.up('xs')]: {
      height: '160px'
    },
    [theme.breakpoints.between(520, 1200)]: {
      flexDirection: 'row',
      gap: 18
    },
    [theme.breakpoints.up('lg')]: {
      width: '360px',
      height: '215px'
    }
  }
}));

export default function Stories() {
  const [blogs, setBlogs] = useState(null);
  const [count, setCount] = useState(3);

  const { t } = useTranslation('landing');
  const theme = useTheme();
  const classes = useStyles();
  const isBelowLg = useMediaQuery(theme.breakpoints.down('lg'));

  useEffect(() => {
    if (isBelowLg) setCount(2);
    else setCount(3);
  }, [isBelowLg]);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchBlogs();
      setBlogs(data);
    };
    getData();
  }, []);

  if (!blogs) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '150px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <CircularProgress
          size={24}
          sx={{ color: 'primary.main' }}
          thickness={4}
        />
      </Box>
    );
  }

  return (
    // Card Container
    <Box
      gap={{ xs: 7, lg: 8 }}
      height="min-content"
      width="100%"
      maxWidth="1200px"
      minWidth="320px"
      display="flex"
      flexDirection="column"
      flexWrap="nowrap"
      justifyContent="start"
      alignItems="start"
      margin="0 auto"
      padding={{ xs: '0px 24px', lg: '0px' }}
      position="relative"
      overflow="visible">
      {/* Cards */}
      <Stack className={classes.cardContainer}>
        {blogs.slice(0, count).map(({ title, desc, img, url }, index) => {
          return (
            <Box key={index} component="article" width="100%">
              <Link
                to={url}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                style={{ textDecoration: 'none' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textDecoration: 'none',
                    color: 'inherit',
                    minHeight: '300px'
                  }}>
                  <Stack
                    className={classes.cardStack}
                    gap={{ xs: 3, lg: 1 }}
                    direction={{ xs: 'column', md: 'column', lg: 'column' }}
                    width={{ xs: '100%', lg: '100%' }}
                    height={{ xs: '100%', lg: '360px' }}
                    padding={{
                      xs: '8px 8px 12px',
                      md: '12px 12px 20px'
                    }}
                    bgcolor={theme.palette.background.paper}
                    justifyContent={{ xs: 'start', md: 'start' }}
                    alignItems={{ xs: 'center', md: 'start' }}
                    boxShadow={1}
                    borderRadius="4px">
                    <Box
                      className={classes.cardImage}
                      component="img"
                      src={img}
                    />
                    <Box
                      display="flex"
                      width={{ xs: '100%', md: '350px' }}
                      flexDirection="column"
                      justifyContent={{ xs: 'center' }}
                      alignItems="start">
                      <DynamicFontTypography
                        className={classes.sub}
                        sx={{
                          fontWeight: 600,
                          mb: 2
                        }}>
                        {title}
                      </DynamicFontTypography>
                      <DynamicFontTypography
                        className={classes.sub}
                        sx={{
                          fontWeight: 300,
                          overflow: 'ellipsis'
                        }}>
                        {desc}
                      </DynamicFontTypography>
                    </Box>
                  </Stack>
                </Box>
              </Link>
            </Box>
          );
        })}
      </Stack>
      <Box margin="0px auto" marginBottom="100px">
        <CTAButton cta={t('Buttons.seeBlogs')} url="/blog" />
      </Box>
    </Box>
  );
}
