import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';

import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
  Switch,
  Tabs,
  Tab
} from '@mui/material';

import ApexChart from 'react-apexcharts';
import chartOptions from './chartOptions.js';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  box: {
    width: '300px',
    marginBottom: '8px'
  },
  boxTop: {
    width: '300px',
    border: 'none'
  },
  boxBottom: {
    width: '300px',
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px'
  },
  boxMid: {
    width: '300px',
    padding: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 0 0'
  },
  boxText: {
    lineHeight: '24px',
    textAlign: 'center'
  },
  boxTextBold: {
    fontSize: '15px',
    margin: '0 4px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 3px 0 3px'
    }
  },
  setButton: {
    margin: '4px 0 16px 0',
    lienHeight: '160%'
  },
  ftpTextBold: {
    fontSize: '15px',
    color: '#73838b'
  },
  tabs: {
    border: '1px #dddddd solid',
    borderRadius: '4px 4px 0 0'
  },
  tabTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  tabDesc: {
    margin: '0px 10px 4px 10px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left'
  },
  sliderWrap: {
    padding: '0px 24px 0px 24px',
    marginBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  ftpSetDesc: {
    marginTop: '8px',
    fontSize: '12px',
    lineHeight: '16px'
  },
  ftpInOutSwitchWrap: {
    padding: '16px 0 8px 0',
    fontSize: '12px'
  },
  ftpSetBox: {
    fontSize: '12px',
    marginBottom: '12px'
  },
  chartContainer: {
    height: '160px',
    margin: '-24px 0 0 0'
  },
  list: {
    marginTop: '0px',
    //    border:'1px #dddddd solid',
    border: 'none'
  },
  listItem: {
    padding: '0px 12px'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  guideFtp: {
    fontSize: '12px',
    color: '#999999'
  },
  z1: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#aaaaaa',
    color: '#ffffff',
    fontSize: '14px'
  },
  z2: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#76b5e8',
    color: '#ffffff',
    fontSize: '14px'
  },
  z3: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#b0d36d',
    color: '#ffffff',
    fontSize: '14px'
  },
  z4: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#ffd357',
    color: '#ffffff',
    fontSize: '14px'
  },
  z5: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#ffa75c',
    color: '#ffffff',
    fontSize: '14px'
  },
  z6: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#ff7e75',
    color: '#ffffff',
    fontSize: '14px'
  },
  z7: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#f06292',
    color: '#ffffff',
    fontSize: '14px'
  }
}));

const FtpInOutSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    margin: '4px',
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  checked: {}
}))(Switch);

const FtpDialog = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const {
    ftpWeeks,
    ftp,
    wftp,
    tftp,
    ftp_type,
    wkg,
    weight,
    tte,
    w_prime,
    p3600,
    best_index
  } = props;
  const [inOutValue, setInOutValue] = React.useState('out');
  const [zoneFtp, setZoneFtp] = React.useState(ftp);
  const [ftpTab, setFtpTab] = React.useState(0);

  const classes = useStyles();

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + t('Common.hour') + ' ' : '';
      let md = m > 0 ? m + t('Common.min') + ' ' : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + t('Common.min') + ' ' : '';
      let sd = s > 0 ? s + t('Common.sec') : '';

      return md + sd;
    }
  }

  function ftpMethod(second) {
    if (second == 250) return t('Dashboard.rampTest');
    else if (second == 470) return t('Dashboard.mapTest');
    else if (second == 1350) return t('Dashboard.20minTest');
    else return Math.floor(best_index / 60) + t('Common.min');
  }

  const handleTabChange = (event, tab) => {
    setFtpTab(tab);
  };

  function FlowChart(props) {
    const options = props.chartOptions.ftp;
    const chartData = props.chartData;

    options.yaxis.min = Math.min(...chartData) - 5;
    options.yaxis.max = Math.max(...chartData) + 5;

    const series = [{ name: 'FTP', data: chartData, type: 'column' }];

    return (
      <React.Fragment>
        <Box className={classes.chartContainer}>
          <ApexChart options={options} series={series} height={'100%'} />
        </Box>
      </React.Fragment>
    );
  }

  const handleInOutFtpValue = event => {
    let checked = event.target.checked;

    if (checked) {
      setInOutValue('out');
      setZoneFtp(ftp);
    } else {
      setInOutValue('in');
      setZoneFtp(tftp);
    }
  };

  return (
    <React.Fragment>
      <Box className={classes.boxTop}>
        <Paper elevation={0} className={classes.tabs}>
          <Tabs
            value={ftpTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered>
            <Tab label={t('Dashboard.ftpTab')} />
            <Tab label={t('Dashboard.zoneTab')} />
          </Tabs>
        </Paper>
      </Box>

      <Box className={classes.boxMid} style={{ textAlign: 'center' }}>
        {ftpTab === 1 ? (
          <React.Fragment>
            <Typography component="div" className={classes.ftpInOutSwitchWrap}>
              <Grid
                component="label"
                container
                style={{ width: '118px', margin: '0 auto' }}>
                <Grid item>{t('Common.interval')}</Grid>
                <Grid item>
                  <FtpInOutSwitch
                    checked={inOutValue == 'out'}
                    onChange={handleInOutFtpValue}
                    name="ftpInOut"
                  />
                </Grid>
                <Grid item>{t('Common.steady')}</Grid>
              </Grid>
            </Typography>

            <Typography className={classes.guideFtp}>
              {inOutValue === 'out' ? (
                <div>
                  {t('Common.steady')} FTP{' '}
                  <span style={{ color: '#4db6ac' }}>{zoneFtp}</span> W
                </div>
              ) : (
                <div>
                  {t('Common.interval')} FTP{' '}
                  <span style={{ color: '#4db6ac' }}>{zoneFtp}</span> W
                </div>
              )}
            </Typography>

            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z1}>Z1</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z1')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(zoneFtp * 0.55)} W {t('Common.under')}
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.z1desc')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z2}>Z2</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z2')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(zoneFtp * 0.55) + 1} ~{' '}
                        {Math.round(zoneFtp * 0.75)} W
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.z2desc')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z3}>Z3</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z3')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(zoneFtp * 0.75) + 1} ~{' '}
                        {Math.round(zoneFtp * 0.9)} W
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.z3desc')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z4}>Z4</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z4')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(zoneFtp * 0.9) + 1} ~{' '}
                        {Math.round(zoneFtp * 1.05)} W
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.z4desc')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z5}>Z5</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z5')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(zoneFtp * 1.05) + 1} ~{' '}
                        {Math.round(zoneFtp * 1.2)} W
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.z5desc')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z6}>Z6</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z6')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(zoneFtp * 1.2) + 1} ~{' '}
                        {Math.round(zoneFtp * 1.5)} W
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.z6desc')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z7}>Z7</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z7')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(zoneFtp * 1.5) + 1} W {t('Common.over')}
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.z7desc')}
                />
              </ListItem>
            </List>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Box component="div">
              <Typography className={classes.tabTitle}>
                {t('Dashboard.ftpTabTitle')}
              </Typography>
            </Box>

            <FlowChart chartOptions={chartOptions} chartData={ftpWeeks} />

            <Box className={classes.ftpSetBox}>
              <Typography className={classes.boxText}>
                ⚡ FTP
                <Typography className={classes.boxTextBold} component="span">
                  {Math.round(ftp)}
                </Typography>
                W
              </Typography>
              <Typography className={classes.boxText}>
                ⌛ TTE
                <Typography className={classes.boxTextBold} component="span">
                  {tte}
                </Typography>
                {t('Common.min')}
              </Typography>
              <Typography className={classes.boxText}>
                ⏱️ 1H Power
                <Typography className={classes.boxTextBold} component="span">
                  {p3600}
                </Typography>
                W
              </Typography>
              <Typography className={classes.boxText}>
                🔋 AWC
                <Typography className={classes.boxTextBold} component="span">
                  {Math.round(w_prime / 100) / 10}
                </Typography>
                kJ
              </Typography>
              <Typography className={classes.boxText}>
                📌
                <Typography className={classes.boxTextBold} component="span">
                  {ftpMethod(best_index)}
                </Typography>
                {t('Common.power')}
              </Typography>
            </Box>
          </React.Fragment>
        )}
      </Box>
      <Box className={classes.boxBottom}>
        {ftpTab === 0 ? (
          <React.Fragment>
            <Typography className={classes.tabTitle} component="div">
              {t('Dashboard.whatIsFtp')}
            </Typography>
            <Typography className={classes.tabDesc} component="div">
              {t('Dashboard.ftpIs')}
            </Typography>
            <Typography className={classes.tabDesc} component="div">
              {t('Dashboard.hourPeak')}
            </Typography>
            <Typography
              className={classes.tabDesc}
              component="div"></Typography>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Typography className={classes.tabTitle} component="div">
              {t('Dashboard.whatIs7zone')}
            </Typography>
            <Typography className={classes.tabDesc} component="div">
              {t('Dashboard.7zoneDesc')}
            </Typography>
            <Typography className={classes.tabDesc} component="div">
              {t('Dashboard.2wayFtp')}
            </Typography>
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};

export default FtpDialog;
