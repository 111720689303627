import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

const useStyles = makeStyles(theme => ({
  root: {},
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  }
}));

const Layout = ({ children }) => {
  const classes = useStyles();

  const { t } = useTranslation(['page']);

  return (
    <div className={classes.root}>
      <Box className={classes.topBar}>
        <Typography variant="h3" className={classes.title}>
          {t('SideBar.simulator')}
        </Typography>
      </Box>
      <div className={classes.whiteLine} />
      {children}
    </div>
  );
};

export default Layout;
