import * as React from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles(() => ({
  root: {}
}));

export default function CreateModal(openState, setOpen) {
  const history = useHistory();
  const classes = useStyles();

  const handleRouteAndClose = () => {
    history.push('/pelotons');
  };

  return (
    <>
      {openState && (
        <Box>
          <Dialog
            open={openState}
            // onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <>
              <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  text="body1"
                  color="textPrimary">
                  {`참가 신청이 완료되었습니다. 승인을 기다려주세요.`}
                </DialogContentText>
                <DialogContentText
                  id="alert-dialog-description"
                  text="small"
                  color="textSecondary"></DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleRouteAndClose}
                  Size="medium"
                  State="enabled"
                  variant="text"
                  color="primary">
                  닫기
                </Button>
              </DialogActions>
            </>
          </Dialog>
        </Box>
      )}
    </>
  );
}
