import React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';

const LinkCopyDialog = ({ copyModalOpen, handleCloseCopyModal }) => {
  return (
    <Dialog
      open={copyModalOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <>
        <DialogTitle id="alert-dialog-title" text="h6">
          링크 공유하기
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            text="body1"
            color="textPrimary">
            주소가 성공적으로 복사 되었습니다!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCopyModal} color="primary">
            확인
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default LinkCopyDialog;
