import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import DynamicFontTypography from 'components/Typography/DynamicFontTypography';

// TODO: 누락된 정책 항목 추가하기

const terms = [
  {
    id: 'announcement',
    title: 'sections.announcement.title',
    content: 'sections.announcement.content'
  },
  {
    id: 'article-1-purpose',
    title: 'sections.article-1-purpose.title',
    content: 'sections.article-1-purpose.content'
  },
  {
    id: 'article-2-definitions',
    title: 'sections.article-2-definitions.title',
    content: 'sections.article-2-definitions.content'
  },
  {
    id: 'article-3-specification',
    title: 'sections.article-3-specification.title',
    content: 'sections.article-3-specification.content'
  },
  {
    id: 'article-4-use-agreement',
    title: 'sections.article-4-use-agreement.title',
    content: 'sections.article-4-use-agreement.content'
  },
  {
    id: 'article-5-service-fees',
    title: 'sections.article-5-service-fees.title',
    content: 'sections.article-5-service-fees.content'
  },
  {
    id: 'article-6-member-withdrawal',
    title: 'sections.article-6-member-withdrawal.title',
    content: 'sections.article-6-member-withdrawal.content'
  },
  {
    id: 'article-7-prohibition',
    title: 'sections.article-7-prohibition.title',
    content: 'sections.article-7-prohibition.content'
  }
];

export default function TermsView() {
  const { t } = useTranslation('terms');

  return (
    <Box
      id="main"
      component="main"
      minWidth="100vw"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center">
      <Stack
        component="section"
        width="100vw"
        maxWidth="1200px"
        padding={{
          xs: '100px 24px 80px',
          lg: '100px 0px 80px'
        }}
        position="relative"
        gap={{ xs: 4, lg: 6 }}
        direction={{ md: 'column' }}
        justifyContent="flex-start"
        alignItems="center">
        <header>
          <DynamicFontTypography sx={{ fontSize: '24px' }}>
            {t('title')}
          </DynamicFontTypography>
        </header>
        {terms.map(({ id, title, content }) => (
          <Box
            key={id}
            id={id}
            sx={{
              marginTop: 2,
              width: '100%'
            }}>
            <DynamicFontTypography variant="h5" paddingBottom={2}>
              {t(title)}
            </DynamicFontTypography>
            {Array.isArray(t(content, { returnObjects: true })) ? (
              <ul>
                {t(content, { returnObjects: true }).map((paragraph, index) => (
                  <li key={index}>
                    <DynamicFontTypography
                      sx={{
                        variant: 'content1'
                      }}>
                      {paragraph}
                    </DynamicFontTypography>
                  </li>
                ))}
              </ul>
            ) : (
              <DynamicFontTypography
                sx={{
                  variant: 'content1'
                }}>
                {t(content)}
              </DynamicFontTypography>
            )}
          </Box>
        ))}
      </Stack>
    </Box>
  );
}
