import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';

import {
  AppBar,
  Box,
  Divider,
  Grid,
  Typography,
  Tabs,
  Tab
} from '@mui/material';

import i18n from 'i18next';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';

/*
  z1: {backgroundColor:'#aaaaaa',color:'#ffffff'},
  z2: {backgroundColor:'#76b5e8',color:'#ffffff'},
  z3: {backgroundColor:'#b0d36d',color:'#ffffff'},
  z4: {backgroundColor:'#ffd357',color:'#ffffff'},
  z5: {backgroundColor:'#ffa75c',color:'#ffffff'},
  z6: {backgroundColor:'#ff7e75',color:'#ffffff'},
  z7: {backgroundColor:'#f06292',color:'#ffffff'},

        "longTermAnalysis":"장기훈련분석",
        "shortTermAnalysis":"단기훈련분석",
        "ctlGuide":"체력 가이드 Fitness Guide",
        "tsbGuide":"균형 가이드 Balance Guide",
        "decay3days":"3일 뒤 감쇠영향",
        "overCtlGuide1":"상대적으로 적은 훈련량으로 현재 기량이 유지되고 있습니다. 성장할 수 있는 잠재력이 있으니 훈련량을 좀 더 늘려보세요.",
        "overCtlGuide2":"상대적으로 적은 훈련량으로 현재 기량이 유지되고 있습니다. 본인 기량에 비해 높은 FTP가 설정되어 있을 수 있습니다.",
        "underCtlGuide1":"상대적으로 많은 훈련에 비해 기량 향상이 이뤄지지 않고 있습니다. 본인 FTP에 비해 낮은 FTP 설정이 되어 있을 수 있습니다. FTP 테스트를 해보세요.",
        "underCtlGuide2":"상대적으로 많은 훈련에 비해 기량 향상이 이뤄지지 않고 있습니다. 훈련의 질이 떨어질 수 있으니 훈련 방향을 바꿔보시는 것이 어떨까요?",
        "fitCtlGuide":"현재 기량 대비 적정 훈련량을 소화하고 있습니다. 현재 훈련량을 꾸준하게 유지하시면 점진적으로 퍼포먼스가 향상될 수 있습니다.",
        "over10TsbGuide":"너무 오랜기간 휴식을 하셨습니다. 기량이 떨어질 수 있습니다.",
        "overTsbGuide":"충분히 회복하고 계신가요? 베스트 컨디션을 위해서 며칠간의 휴식이 필요합니다.",
        "underTsbGuide":"단기적으로 많은 피로가 쌓였습니다. 휴식이 필요합니다.",
        "fitTsbGuide":"적정한 수준의 훈련량을 수행하고 있습니다."

*/
//['#BCDBF3','#4db6ac','#f8bbd0','#f06292'],

//jQuery 추가
import $ from 'jquery';

//Chart 추가
import CanvasJSReact from '../../../../utils/canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px'
  },
  gridContainer: {
    color: '#999999',
    fontWeight: 300,
    fontSize: '13px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px'
    }
  },
  descTitle: {
    marginBottom: '8px'
  },
  desc: {}
}));

const PMC = props => {
  const classes = useStyles();
  const theme = useTheme();

  //다국어적용
  const { t } = useTranslation(['page']);
  const language = i18n.language;

  const summaryData = props.summaryData;
  const tssTable = summaryData.tss_table;

  const lastATL = summaryData.last_atl;
  const lastCTL = summaryData.last_ctl;
  const lastTSB = summaryData.last_tsb;
  const ftp = summaryData.wftp;
  const tte = summaryData.wtte;

  const lastWeekTss = tssTable.last_week_tss;
  const advice = tssTable.advice;

  const [pmcChartOptions, setPmcChartOptions] = useState(null);
  const [circle, setCircle] = useState(false);
  const [pmcChart, setPmcChart] = React.useState(null);

  const [ctlAnalysis, setCtlAnalysis] = React.useState('');
  const [tsbAnalysis, setTsbAnalysis] = React.useState('');

  const [tabSelect, setTabSelect] = React.useState(0);
  const [weeks, setWeeks] = React.useState(12); //기본 12주

  const ctlGuide = Math.round(summaryData['min_week_tss'] / 7);

  function getDateYmd(date) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    if (language === 'en') {
      return (
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
      );
    } else {
      return (
        date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
      );
    }
  }

  function getDateMd(date) {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ];

    if (language === 'en') {
      return date.getDate() + ' ' + months[date.getMonth()];
    } else {
      return date.getMonth() + 1 + '/' + date.getDate();
    }
  }

  //평균 구하기
  function averageArray(arr) {
    const result = arr.reduce(function add(sum, currValue) {
      return sum + currValue;
    }, 0);

    return result / arr.length;
  }

  function arrayToChartPoints(arr, dayTss = false) {
    let point_arr = Array();
    //날짜
    let nowDate = new Date();
    let rate = 1;

    //
    if (dayTss) {
      rate =
        Math.max.apply(null, arr) /
        Math.max.apply(null, summaryData['ctl_arr']);
    }

    _.each(arr, function(value, index) {
      //3일이 추가되어서 -4가 된다
      let day_ago = arr.length - 4 - index;

      let date = new Date(nowDate.getTime() - day_ago * 24 * 60 * 60 * 1000);
      let label = getDateYmd(date);
      let xLabel = getDateMd(date);
      let y = value;

      if (day_ago === 0) {
        label = t('Common.today');
      }

      point_arr.push({
        x: index,
        y: Math.round(value / rate),
        value: value,
        label: xLabel
      });
    });

    return point_arr;
  }

  //3일치 예상
  function arrayPMC(summaryData) {
    let atlArr = summaryData['atl_arr'];
    let ctlArr = summaryData['ctl_arr'];
    let tsbArr = summaryData['tsb_arr'];
    let dayTssArr = summaryData['day_tss_arr'];
    let ftpArr = summaryData['ftpArr'];

    let atlAvg = averageArray(atlArr);
    let ctlAvg = averageArray(ctlArr);
    let currentTsb = tsbArr.slice(-1)[0];

    //예측 3일 감쇠 추가
    for (let i = 1; i < 4; i++) {
      let lastValue = atlArr.slice(-1)[0];
      atlArr.push(Math.round(lastValue - lastValue / 7));
    }

    for (let i = 1; i < 4; i++) {
      let lastValue = ctlArr.slice(-1)[0];
      ctlArr.push(Math.round(lastValue - lastValue / 42));
    }

    for (let i = -3; i < 0; i++) {
      let lastValue = ctlArr.slice(i)[0] - atlArr.slice(i)[0];
      tsbArr.push(lastValue);
    }

    for (let i = 1; i < 4; i++) {
      dayTssArr.push(0);
    }

    return {
      atlArr: atlArr,
      ctlArr: ctlArr,
      tsbArr: tsbArr,
      atlAvg: atlAvg,
      ctlAvg: ctlAvg,
      currentTsb: currentTsb,
      dayTssArr: dayTssArr,
      ftpArr: ftpArr
    };
  }

  const containerPmc = {
    minWidth: '300px',
    height: window.innerHeight > 800 ? '460px' : '320px',
    margin: 'auto'
  };

  useEffect(() => {
    const stripLineX = [];
    const stripLineY = [];

    const {
      atlAvg,
      ctlAvg,
      currentTsb,
      atlArr,
      ctlArr,
      tsbArr,
      ftpArr,
      dayTssArr
    } = arrayPMC(summaryData);

    const yMax = Math.round(Math.max.apply(null, atlArr) / 10) * 10;
    const yMin = Math.round(Math.min.apply(null, tsbArr) / 10) * 10;

    const atlPoints = arrayToChartPoints(atlArr);
    const ctlPoints = arrayToChartPoints(ctlArr);
    const tsbPoints = arrayToChartPoints(tsbArr);
    const dayTssPoints = arrayToChartPoints(dayTssArr, true);

    //현재 CTL
    const latestCtl = ctlArr[ctlArr.length - 4];

    //analysis
    if (latestCtl > ctlGuide * 0.8 && latestCtl <= ctlGuide * 1.2) {
      setCtlAnalysis(t('TssGuide.ctlGuideDesc1'));
    } else if (latestCtl <= ctlGuide * 0.8) {
      setCtlAnalysis(t('TssGuide.ctlGuideDesc2'));
    } else if (latestCtl > ctlGuide * 1.2) {
      setCtlAnalysis(t('TssGuide.ctlGuideDesc3'));
    }

    if (currentTsb > -30 && currentTsb <= -10) {
      setTsbAnalysis(t('TssGuide.tsbGuideDesc1'));
    } else if (currentTsb > -10 && currentTsb <= 10) {
      setTsbAnalysis(t('TssGuide.tsbGuideDesc2'));
    } else if (currentTsb > 10) {
      setTsbAnalysis(t('TssGuide.tsbGuideDesc3'));
    } else if (currentTsb <= -30) {
      setTsbAnalysis(t('TssGuide.tsbGuideDesc4'));
    }

    //오늘의 인덱스는 원래 -1인데 예측 3일 더해져서 -4
    stripLineX.push({
      type: 'range',
      showOnTop: true,
      startValue: atlArr.length - 4,
      endValue: atlArr.length - 1,
      color: '#ffffff',
      opacity: 0.6,
      label: t('TssGuide.decay3days'),
      labelFontSize: 13,
      labelFontColor: '#76b5e8',
      labelBackgroundColor: 'transparent',
      labelAlign: 'center',
      labelPlacement: 'inside'
    });
    stripLineX.push({
      type: 'dash',
      value: atlArr.length - 4,
      lineDashType: 'dash',
      lineThickness: 2,
      lineColor: '#333333'
    });

    //fitness zone
    stripLineY.push({
      type: 'range',
      name: 'GUIDE FITNESS',
      showOnTop: true,
      startValue: Math.round(ctlGuide * 0.8),
      endValue: Math.round(ctlGuide * 1.2),
      color: '#76b5e8',
      opacity: 0.2,
      label: Math.round(ctlGuide * 0.8) + ' ~ ' + Math.round(ctlGuide * 1.2),
      labelFontSize: 13,
      labelFontColor: '#76b5e8',
      labelBackgroundColor: 'transparent',
      labelFormatter: function(e) {
        return t('TssGuide.ctlGuide') + ' : ' + e.stripLine.label;
      },
      labelAlign: 'near',
      labelPlacement: 'inside'
    });

    stripLineY.push({
      type: 'range',
      name: 'GUIDE BALANCE',
      showOnTop: true,
      startValue: -10,
      endValue: -30,
      color: '#b0d36d',
      opacity: 0.2,
      label: '-10 ~ -30',
      labelFontSize: 13,
      labelFontColor: '#b0d36d',
      labelBackgroundColor: 'transparent',
      labelFormatter: function(e) {
        return t('TssGuide.tsbGuide') + ' : ' + e.stripLine.label;
      },
      labelAlign: 'near',
      labelPlacement: 'inside'
    });

    stripLineY.push({
      type: 'dash',
      value: 0,
      color: '#000000',
      lineDashType: 'dash',
      lineThickness: 2,
      lineColor: '#333333'
    });

    /******* watts Chart  *******/
    setPmcChartOptions({
      backgroundColor: theme.palette.background.paper,
      animationEnabled: false,
      theme: 'light2',
      title: false,
      zoomEnabled: false,
      dataPointMaxWidth: 16,
      toolTip: {
        shared: true,
        contentFormatter: function(e) {
          let content = e.entries[0].dataPoint.label + '<br />';
          for (let i = 0; i < e.entries.length; i++) {
            content +=
              "<div style='color:" +
              e.entries[i].dataSeries.color +
              "'>" +
              e.entries[i].dataSeries.name +
              ' ' +
              '<strong>' +
              e.entries[i].dataPoint.value +
              '</strong>' +
              '</div>';
          }
          return content;
        }
      },
      axisX: {
        labelFontColor: theme.palette.chart.text.primary,
        gridThickness: 0,
        tickLength: 0,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        stripLines: stripLineX,
        valueFormatString: '.',
        labelAngle: 0,
        crosshair: {
          enabled: false,
          lineColor: '#999999',
          labelFormatter: function(e) {
            return '';
          }
        },
        labelFormatter: function(e) {
          return e.label;
        }
      },
      axisY: {
        maximum: yMax + 5,
        minimum: yMin > -30 ? -30 : yMin - 5,
        margin: 10,
        labelFontSize: 11,
        labelFontColor: '#aaaaaa',
        stripLines: stripLineY,
        tickLength: 2,
        lineThickness: 1,
        lineColor: '#aaaaaa',
        labelFormatter: function(e) {
          return e.value;
        },
        gridThickness: 1,
        gridColor: theme.palette.chart.secondary.axis.grid
      },
      data: [
        {
          type: 'area',
          fillOpacity: 0.6,
          name: t('Dashboard.ctl'),
          yValueFormatString: '#',
          lineThickness: 2,
          markerSize: 1,
          color: '#7BB8E7',
          legendMarkerType: 'square',
          dataPoints: ctlPoints
        },
        {
          type: 'line',
          fillOpacity: 1.0,
          name: t('Dashboard.atl'),
          yValueFormatString: '#',
          lineThickness: 2,
          markerSize: 1,
          fillOpacity: 1.0,
          color: '#ff7e75',
          legendMarkerType: 'square',
          dataPoints: atlPoints
        },
        {
          type: 'line',
          fillOpacity: 1.0,
          name: t('Dashboard.tsb'),
          yValueFormatString: '#',
          lineThickness: 2,
          markerSize: 1,
          color: '#b0d36d',
          legendMarkerType: 'square',
          dataPoints: tsbPoints
        },
        {
          type: 'column',
          fillOpacity: 0.8,
          name: t('Dashboard.dTss'),
          yValueFormatString: '#',
          color: '#ffd357',
          dataPoints: dayTssPoints
        }
      ]
    });
  }, [summaryData]);

  const handleTab = (event, newTab) => {
    setTabSelect(newTab);
  };

  function TabList(props) {
    return (
      <Tabs
        value={tabSelect}
        onChange={handleTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">
        <Tab key={0} label={'PMC'} />
      </Tabs>
    );
  }

  return (
    <React.Fragment>
      <AppBar position="static" color="default" className={classes.tab}>
        <TabList />
      </AppBar>
      <Box className={classes.tabPanel}>
        <CanvasJSChart
          containerProps={containerPmc}
          options={pmcChartOptions}
          onRef={ref => setPmcChart(ref)}
        />
        <Box style={{ padding: '12px 0px' }}>
          <Typography className={classes.descTitle} component="div">
            {t('Dashboard.currentTraining')}
          </Typography>
          <Grid container style={{ textAlign: 'center', fontSize: '12px' }}>
            <Grid item xs={4}>
              <Typography component="span">
                🚴 {t('Dashboard.ctl')} {lastCTL}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="span">
                💤 {t('Dashboard.atl')} {lastATL}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography component="span">
                ⚖️ {t('Dashboard.tsb')} {lastTSB}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ textAlign: 'center', fontSize: '12px', display: 'none' }}>
            <Grid item xs={4}>
              <Typography component="span">
                ⚡ {t('Dashboard.ftp')} {ftp}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Divider />

        <Box style={{ padding: '12px 0px' }}>
          <Typography className={classes.descTitle} component="div">
            {t('TssGuide.longTermAnalysis')}
          </Typography>
          <Typography className={classes.desc} component="div">
            {ctlAnalysis}
          </Typography>

          <Typography
            className={classes.desc}
            style={{ color: '#76b5e8', marginTop: '8px', textAlign: 'center' }}
            component="div">
            {t('TssGuide.ctlGuide') +
              ' : ' +
              Math.round(ctlGuide * 0.8) +
              ' ~ ' +
              Math.round(ctlGuide * 1.2)}
          </Typography>
        </Box>

        <Divider />

        <Box style={{ padding: '12px 0px' }}>
          <Typography className={classes.descTitle} component="div">
            {t('TssGuide.shortTermAnalysis')}
          </Typography>
          <Typography className={classes.desc} component="div">
            {tsbAnalysis}
          </Typography>
          <Typography
            className={classes.desc}
            style={{ color: '#b0d36d', marginTop: '8px', textAlign: 'center' }}
            component="div">
            {t('TssGuide.tsbGuide') + ' : -10 ~ -30'}
          </Typography>
        </Box>

        <Divider />

        <Box style={{ padding: '12px 0px' }}>
          <Typography className={classes.descTitle} component="div">
            {t('Common.PmcIs')}
          </Typography>
          <Typography className={classes.desc} component="div">
            {t('Common.PmcIsDesc')}
          </Typography>
        </Box>

        <Divider />
      </Box>
    </React.Fragment>
  );
};

PMC.propTypes = {
  history: PropTypes.object
};

export default withRouter(PMC);
