//import React from 'react';
//import uuid from 'uuid/v1';
//#ffd357 노
//f491b2 빨 
//8cc1ec 파랑
//4db6ac #71c5bd 녹
import { useTranslation } from 'react-i18next'


export default {
  summary: {
    chart: {
      type: 'line',
      offsetY: 0,
      offsetX: 0,
      animations: {
        enabled: false,
      },
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 0,
        left: 0,
        blur: 5,
        opacity: 0.2
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      }
    },
    markers: {
      size: 0,
      hover: {
        size: 0 
      }
    }, 
    fill : {
      opacity: [1.0, 1.0, 0.8, 0.6]
    }, 
    dataLabels: {
      enabled: true,
      enabledOnSeries: [3],
      style: {
        fontSize: '11px',
        fontWeight: 'normal',
        colors: undefined
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 3,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#ffc41c',
        opacity: 0.9,
      }, 
    },	
    colors: ['#8cc1ec','#f491b2','#4db6ac','#ffd357'],
    stroke: {
        width: [2, 2, 1, 1],
        curve: ['straight', 'smooth', 'smooth', 'smooth'],
        dashArray: [3, 0, 0, 0],     
    },
    grid: {
      borderColor: '#f5f5f5',
      row: {
        colors: ['#ffffff', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
    },
    legend: {
      fontSize: '11px',
      itemMargin: {
        horizontal: 5,
        vertical: 0
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', 
        },
        endingShape: "rounded",
        columnWidth: "60%",
      }
    },
    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        formatter: function(value) {
          let week = 12-value;

          if(week === 0) return "this week";
          else return week + "weeks ago";
        }
      },
      axisTicks: {
        show: false,
      },
      tooltip :{
        enabled: false
      } 
    },
    yaxis: [
      {
        seriesName: 'FTP',
        show: false,
        min: 0,
        max: 500,
        tickAmount: 10,
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
          formatter: (value) => { return Math.round(value) + ' w' },
        },
      },
      {
        seriesName: 'AVG NP',
        show: false,
        min: 0,
        max: 500,
        tickAmount: 10,
        axisBorder: {
          show: false,
        },        
        labels: {
          show: false,
          formatter: (value) => { return Math.round(value) + ' w' },
        },	
      },	
      {
        seriesName: 'DISTANCE',
        show: false,
        min: 0,
        tickAmount: 10,
        axisBorder: {
          show: false,
        },        
        labels: {
          show: false,
          formatter: (value) => { return Math.round(value) + ' km'},
        },	
      },	
      {
        seriesName: 'SUM TSS',
        show: false,
        min: 0,
        tickAmount: 10,
        axisBorder: {
          show: false,
        },        
        labels: {
          show: false,
          formatter: (value) => { return Math.round(value) },
        },	
      },	 
/*
      {
        seriesName: 'Week Distance',
        show: false,
        min: 0,
        tickAmount: 10,
        axisBorder: {
          show: false,
        },        
        labels: {
          show: false,
          formatter: (value) => { return Math.round(value) + ' km' },
        },	
      },	
*/      
    ],
    tooltip: {
      x: {
        format: 'yyyy MMM dd'
      }
    },
  },


  pmc: {
    chart: {
      type: 'line',
      offsetY:0,
      offsetX:0,
      animations: {
        enabled: true,
      },
      dropShadow: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      },
    },
    markers: {
      size: 0,
      hover: {
        size: 0 
      }
    }, 
    fill : {
      opacity: [0.8, 1.0, 1.0]
    }, 
    dataLabels: {
      enabled: true,
      enabledOnSeries: [3],
      style: {
        fontSize: '11px',
        fontWeight: 'normal',
        colors: undefined
      },
      background: {
        enabled: true,
        foreColor: '#fff',
        padding: 3,
        borderRadius: 2,
        borderWidth: 1,
        borderColor: '#ffc41c',
        opacity: 0.9,
      }, 
    },	
    colors: ['#8cc1ec','#f491b2','#ffd357'],
    stroke: {
        width: [2, 2, 2],
        dashArray: [0, 0, 0],     
    },
    grid: {
      borderColor: '#f5f5f5',
      row: {
        colors: ['#ffffff', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
    },
    legend: {
      fontSize: '11px',
      itemMargin: {
        horizontal: 5,
        vertical: 0
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', 
        },
        endingShape: "rounded",
        columnWidth: "60%",
      }
    },
    xaxis: {
      type: 'numeric',
      labels: {
        show: false,
        formatter: function(value) {
          let ago = 42-value;

          if(ago === 0) return "today";
          else return ago + "days ago";
        }
      },
      axisTicks: {
        show: false,
      },
      tooltip :{
        enabled: false
      } 
    },
    yaxis: {
      seriesName: 'CTL',
      show: false,
      tickAmount: 10,
      axisBorder: {
        show: false,
      },
      labels: {
        show: false,
        formatter: (value) => { return Math.round(value) },
      },
    },
  },


  altitude : {
    chart: {
      id: 'brush',
      type: 'area',
      group: 'stream',
      toolbar: {
        show: false
        /*
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | 'RE',
          customIcons: []  
        } 
        */     
      },
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
      },
      selection: {
        enabled: false,
        type: 'x',
      },
      height: 'auto',
    },
    grid: {
      show: false,
      padding: {
        top:0,
      }
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      width: [1, 0, 0, 0],
    },
    colors: ['#4db6ac', '#ffffff'],
    fill: {
      colors: ['#4db6ac','#ffffff'],
      type: 'solid',
      opacity : [0.4, 0.0, 0.0, 0.0],
    },
    plotOptions: {
      area: {
        fillTo: 'end',
      },
    },
    dataLabels: {
      enabled: false
    },
    markers: {
      size: 0,
      hover: {
        size: 0 
      }
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          colors: '#83ccc5',
        },
        maxHeight:20, 
        offsetY: 0,
        offsetX:-5,
        formatter: function(val) {
          return Math.round(val*10)/10 + "km";
        }
      },
      tickAmount: 5, 
      tooltip: {
        enabled: false,
      }
    },
    yaxis: {      
      min : 0,
      tickAmount: 3, 
      tooltip: {
        enabled: false,
      },
      labels: {
        show: true,
        style: {
          fontSize: '10px',
          colors: '#83ccc5',
        },
        minWidth:30, 
        maxWidth:30, 
      }
    },
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        function secondsToHms(d) {
          d = Number(d);
          var h = Math.floor(d / 3600);
          var m = Math.floor(d % 3600 / 60);
          var s = Math.floor(d % 3600 % 60);
      
          if(h == 0 && m == 0 && s == 0) {
            return "00:00"; 
          } else if(h > 0) {
            let hd = (h > 0) ? ((h<10) ? "0" + h + ":": h + ":") : "00";
            let md = (m > 0) ? ((m<10) ? "0" + m : m) : "00";

            return hd + md; 
          } else if(h <= 0 && m > 0) {
            let md = (m > 0) ? ((m<10) ? "0" + m + ":": m + ":") : "00";
            let sd = (s > 0) ? ((s<10) ? "0" + s : s) : "00";
            
            return md + sd; 
          } else if(m <= 0 && s > 0) {
            let md = "00:";
            let sd = (s > 0) ? ((s<10) ? "0" + s : s) : "00";

            return md + sd; 
          } else {
            return "00:00";           
          }
      
//          var hDisplay = h > 0 ? h + (h == 1 ? ":" : ":") : "";
//          var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "";
//          var sDisplay = s > 0 ? s + (s == 1 ? "" : "") : "";
//          return hDisplay + mDisplay + sDisplay; 
        }

        let altitude_data = series[0][dataPointIndex];
        let slope_data = series[1][dataPointIndex];
        let duration_data = secondsToHms(series[2][dataPointIndex]);
        let distance_data = 0;

        if(series[3].length == 0) {
          distance_data = Math.round(10*w.globals.seriesX[0][dataPointIndex])/10;        
        } else {
          distance_data = Math.round(series[3][dataPointIndex]/100)/10;        
        }

        return (
          '<div style="font-size:11px;color:#73838b;padding:4px;">' +
            "<div>" +
              "distance " + (distance_data ? distance_data : 0) + "km" + 
            "</div>" +
            "<div>" +
              "altitude " + (altitude_data ? altitude_data : 0) + "m" + 
            "</div>" +
            "<div>" +
              "slope " + (slope_data ? slope_data : 0) + "%" +
            "</div>" +
            "<div>" +
              "duration " + (duration_data ? duration_data : "") + 
            "</div>" +
          "</div>"
        );
      },
    }
  },
  content : {
    chart: {
      id: 'chart1',
      type: 'area',
      group: 'stream',
      zoom: {
        enabled: true,
      },
      selection: {
        enabled: true,
        type: 'x',
      },
      toolbar: {
        show: false
      },
      animations: {
        enabled: false,
      },
      height: 'auto',
    },
    grid: {
      show: false,
      padding: {
        top:0,
      }
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      curve: 'smooth',
      width: [1, 0],
    },
    colors: ['#76b5e8'],
    fill: {
      colors: ['#d1e6f7'],
      type: 'solid',
      opacity : 1.0,
    },
    plotOptions: {
      area: {
          fillTo: 'end',
      },
    },
    markers: {
      size: 0,
      hover: {
        size: 0 
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          colors: '#ffffff',
        },
        maxHeight:1, 
        offsetY:-30,
        formatter: function(val) {
          return Math.round(val*10)/10 + "km";
        }
      },
      tickAmount: 5, 
      tooltip: {
        enabled: false,
      }
    },
    yaxis: {      
      labels: {
        show: true,
        style: {
          fontSize: '10px',
          colors: '#aaaaaa',
        },
        minWidth:30, 
        maxWidth:30, 
        offsetY:3,
      },
      axisBorder: {
        show:false,
      },
      tickAmount: 3, 
      tooltip: {
        enabled: false,
      },     
    },
    annotations : {
      yaxis: [],  
      xaxis: [],  
    },
    tooltip: {
      custom: function({ series, seriesIndex, dataPointIndex, w }) {
        return (
          "<div style='font-size:11px;color:#73838b;padding:3px 4px;'>" +
            w.globals.seriesNames[0] + 
            " " +
            series[0][dataPointIndex] + " " + w.config.series[0].label + 
          "</div>"
        );
      },
    }
  },
};
