import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Stack } from '@mui/material';
import DynamicFontTypography from 'components/Typography/DynamicFontTypography';
import HeroArticle from 'components/Common/HeroArticle';
import ContentPreviewCard from 'components/Common/ContentPreviewCard';
import LoadMoreButton from 'components/Common/LoadMoreButton';
import { fetchAnnouncements } from '../hooks/useAnnouncements';
// import newsList from 'data/newsList';

export default function NewsView() {
  const { t } = useTranslation('landing');
  const [news, setNews] = useState(null);
  const [visiblePosts, setVisiblePosts] = useState(3);

  useEffect(() => {
    const getData = async () => {
      const data = await fetchAnnouncements();
      setNews(data);
    };
    getData();
  }, []);

  const handleLoadMore = () => {
    setVisiblePosts(prev => prev + 3);
  };

  if (!news) {
    return (
      <Box
        sx={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <CircularProgress
          size={24}
          sx={{ color: 'primary.main' }}
          thickness={4}
        />
      </Box>
    );
  }

  return (
    <Box
      component="main"
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        overflow: 'hidden'
      }}>
      <Stack
        component="section"
        width="100%"
        height="100%"
        maxWidth="1200px"
        padding={{ xs: '19.2px 24px 50px', lg: '19.2px 0px 50px' }}
        position="relative"
        gap={{ xs: 2, lg: 3 }}
        direction={{ md: 'column' }}
        justifyContent="flex-start">
        <header>
          <DynamicFontTypography fontSize="26px" fontWeight={500}>
            {t('News.title')}
          </DynamicFontTypography>
        </header>
        <HeroArticle
          title={news[0].title}
          desc={news[0].desc}
          img={news[0].img}
          url={news[0].url}
        />
        <DynamicFontTypography fontSize="20px" fontWeight={500}>
          {t('News.category')}
        </DynamicFontTypography>
        <Stack direction="column" gap={3}>
          {news
            .slice(0, visiblePosts)
            .map(({ title, desc, url, img }, index) => (
              <ContentPreviewCard
                key={index}
                title={title}
                desc={desc}
                url={url}
                img={img}
              />
            ))}
        </Stack>
        {visiblePosts < news.length && (
          <Box margin="32px auto">
            <LoadMoreButton onLoadMore={handleLoadMore} />
          </Box>
        )}
      </Stack>
    </Box>
  );
}
