import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Stack } from '@mui/material';
import { DynamicFontTypography } from 'components/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  sub: {
    fontSize: 'clamp(16px, 1.3vw, 14px)',
    lineHeight: 'clamp(22px, 1.5vw, 30px)'
  }
}));

export default function Menu({ isOpen, setIsOpen }) {
  const location = useLocation();
  const { t } = useTranslation('landing');
  const classes = useStyles();

  const menuList = [
    {
      name: t('Header.whatsnew'),
      url: '/news'
    },
    {
      name: t('Header.blog'),
      url: '/blog'
    }
  ];

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.pathname === '/') scrollToTop();
  }, [location]);

  return (
    <Stack
      direction={isOpen ? 'column' : 'row'}
      position={isOpen ? 'relative' : 'absolute'}
      spacing={1.5}
      sx={{
        left: { lg: '50%' },
        transform: { lg: 'translateX(-50%)' },
        justifyContent: 'center',
        maxHeight: isOpen ? '100%' : '0px',
        alignItems: 'center',
        display: {
          xs: isOpen ? 'flex' : 'none',
          lg: 'flex'
        },
        transition: 'max-height 0.5s ease-in-out'
      }}>
      {menuList.map(({ name, url }) => {
        return (
          <Button
            key={name}
            disableRipple
            disableFocusRipple
            onClick={() => setIsOpen && setIsOpen(!isOpen)}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}>
            <Link to={url} style={{ textDecoration: 'none' }}>
              <DynamicFontTypography
                className={classes.sub}
                sx={{ textAlign: 'center' }}>
                {name}
              </DynamicFontTypography>
            </Link>
          </Button>
        );
      })}
    </Stack>
  );
}
