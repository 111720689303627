import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import axios from 'axios';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Slider,
  ButtonGroup,
  Button,
  Divider,
  MenuItem,
  Select,
  Typography,
  InputAdornment,
  TextField,
  Grid
} from '@mui/material';

import { useTranslation } from 'react-i18next';

import $ from 'jquery';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {
  SimulationSegment,
  BikeCompanyDialog,
  TireDialog,
  RiderAndBike
} from './Contents';

import auth from '../../../../utils/auth';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    marginBottom: theme.spacing(2)
  },
  infoTitle: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#333333',
    marginTop: 10,
    marginBottom: 10,
    borderRadius: '4px'
  },
  resultDesc: {
    color: '#cccccc',
    margin: '0px'
  },
  content: { padding: 0 },
  margin: { margin: 0 },
  textField: {
    width: '100%',
    '& input:disabled': {
      color: '#eeeeee'
    }
  },
  textFieldLast: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  inputStyle: {
    [theme.breakpoints.down('md')]: {
      height: '16px !important',
      fontSize: '12px !important',
      padding: '14px 8px 10px 8px !important'
    }
  },
  image: { height: 48, width: 48 },
  actions: { justifyContent: 'flex-end' },
  input: { marginLeft: 8, flex: 1 },
  smallGrid: {
    [theme.breakpoints.down('md')]: {
      padding: '4px !important'
    },
    textAlign: 'center'
  },
  smallInputLabel: {
    width: 'auto !important',
    background: '#f4f4f4 !important',
    padding: '6px 1px 4px 6px !important',
    borderRadius: '4px 0 0 4px !important',
    fontSize: '14px !important',
    color: '#aaa !important',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px !important',
      padding: '6px 2px 4px 0px !important'
    }
  },
  smallInput: {
    width: '80px !important',
    fontSize: '14px !important',
    padding: '4px !important',
    border: 'none !important',
    color: '#4db6ac !important',
    textAlign: 'center !important',
    background: '#f4f4f4 !important',
    borderRadius: '0 4px 4px 0 !important',
    [theme.breakpoints.down('md')]: {
      width: '42px !important',
      fontSize: '11px !important',
      padding: '2px 0px 2px 2px !important'
    }
  },
  selectLine: {
    fontSize: '14px !important',
    color: '#4db6ac !important',
    background: '#f4f4f4 !important',
    '& div': {
      width: '100px !important',
      padding: '4px 22px 6px 6px !important'
    },
    '&:before': {
      border: 'none !important'
    },
    '&:after': {
      background: '#ffffff !important'
    },
    [theme.breakpoints.down('md')]: {
      '& div': {
        width: '56px !important',
        padding: '4px 12px 6px 0px !important'
      },
      fontSize: '11px !important'
    }
  },
  formLabel: {
    '& span': {
      fontSize: '13px'
    }
  },
  iconButton: { padding: 10 },
  divider: {
    background: '#f4f4f4 !important',
    margin: '20px 0 20px 0 !important'
  },
  vLine: {
    width: '6px',
    height: '50px',
    marginLeft: '-3px',
    borderLeft: '6px solid green'
  },
  item1: {
    order: 1,
    [theme.breakpoints.up('lg')]: {
      order: 1,
      marginTop: '0px !important'
    },
    [theme.breakpoints.down('md')]: {
      order: 2
    }
  },

  item2: {
    order: 3,
    marginTop: '-16px',
    [theme.breakpoints.up('lg')]: {
      order: 2,
      marginTop: '0px !important'
    },
    [theme.breakpoints.down('md')]: {
      order: 1,
      marginTop: '-10px !important'
    }
  },

  item3: {
    order: 2,
    [theme.breakpoints.up('lg')]: {
      order: 3
    },
    [theme.breakpoints.down('md')]: {
      order: 3,
      marginTop: '-15px !important'
    }
  },

  cardHeader: {
    color: '#90a4ae',
    fontSize: '15px',
    '& span': {
      [theme.breakpoints.down('md')]: {
        fontSize: '12px'
      }
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px'
    }
  },

  segmentSelectButton: {
    '& span': {
      fontSize: '13px !important'
    },
    padding: '0 4px 0 4px !important',
    margin: '6px 4px 0 0 !important'
  },

  bikeBrandSelectButton: {
    '& span': {
      fontSize: '13px !important'
    },
    padding: '0 4px 0 4px !important',
    margin: '6px 4px 0 0 !important'
  },

  powerDeviceSelectButton: {
    '& span': {
      fontSize: '13px !important'
    },
    padding: '0 4px 0 4px !important',
    margin: '6px 4px 0 0 !important'
  },

  box: {
    boxShadow: 'inset 0 0 0 1px #c4c4c4',
    boxSizing: 'border-box',
    borderRadius: 4,
    width: '100%',
    height: '52px',
    padding: '10px 24px 0px 24px',
    marginTop: 0,
    marginBottom: 18,
    '&:last-child': {
      marginTop: '-1px !important',
      marginBottom: 0
    },
    '& span.MuiSlider-root': {
      padding: '10px 0'
    },
    '& span.MuiSlider-markLabel': {
      fontSize: '11px',
      top: '22px'
    }
  },
  smallBox: {
    height: '40px !important',
    padding: '0px 18px 0px 18px !important',
    marginTop: 0,
    marginBottom: 0
  },
  lineBox: {
    maxHeight: '800px',
    padding: '0px',
    border: '1px #c4c4c4 solid',
    borderRadius: 3,
    margin: '0px'
  },
  boxLegend: {
    position: 'absolute !important',
    margin: '-10px 0 0 -30px !important',
    padding: '0 5px !important',
    display: 'inline !important',
    background: '#ffffff !important'
  },
  animationBox: {
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    backgroundColor: '#f4f4f4',
    margin: '0px',
    borderRadius: '0 0 2px 2px'
  },
  bottomTabButton: {
    fontSize: '14px',
    lineHeight: '24px',
    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
      lineHeight: '14px'
    }
  }
}));

const BikeUI = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { className, staticContext, ...rest } = props;
  const classes = useStyles();
  const userInfo = auth.getUserInfo();
  const extraInfo = auth.getExtraInfo();

  const gearInfo = props.gearinfo;
  const bikeKit = JSON.parse(gearInfo.bike_kit_json);

  const [tireList, setTireList] = useState([]);
  const [bikeCompanyList, setBikeCompanyList] = useState([]);
  const [powermeterList, setPowermeterList] = useState([]);

  //	console.log(bikeKit);

  //사용자 정보에서 config 정보 불러오기
  let initRider = {
    gender: 'M',
    height: 172,
    weight: '62.0',
    helmet: 'road'
  };

  let initBike = {
    bikeType: 'road_allround',
    bikeWeight: '8.0',
    tireProduct: 'Clincher mid (butyl)',
    drivetrain: 'ultegra',
    companyName: '',
    powermeter: ''
  };

  let initCrrVal = 0.005;
  let initCda = 0.4;
  let initTireWidth = 2;
  let initRimHeight = 1;
  let initCadence = 90;
  let initRiderPose = 2;
  let initSurface = 2;

  if (bikeKit !== null) {
    //		console.log("read bikeKit");

    initRider = {
      gender: 'M',
      height: '175',
      weight: '65',
      helmet: 'road'
    };

    //		console.log("bikeKit");
    //		console.log(bikeKit);

    initBike = {
      bikeType: bikeKit.bike_type,
      bikeWeight: bikeKit.bike_weight,
      tireProduct: bikeKit.tire_product,
      drivetrain: bikeKit.drivetrain,
      //bikeKit에서 집어넣어야 한다
      companyName: bikeKit.company_name,
      powermeter: bikeKit.powermeter
    };

    //		console.log(initBike);

    initTireWidth = bikeKit.tire_width;
    initRimHeight = bikeKit.rim_height;
    initCadence = bikeKit.cadence;
    initRiderPose = bikeKit.rider_pose;
    initSurface = bikeKit.surface_select;

    initCrrVal = bikeKit.crr;
    initCda = bikeKit.cda;
  }

  const [tireCrr, setTireCrr] = useState(0.005);
  const [crrVal, setCrrVal] = useState(initCrrVal);
  const [roadSurface, setRoadSurface] = useState(Number(initSurface));
  const [cda, setCda] = useState(initCda);
  const [rider, setRider] = useState(initRider);
  const [bike, setBike] = useState(initBike);

  const [tireListOpen, setTireListOpen] = useState(false);
  const [bikeCompanyListOpen, setBikeCompanyListOpen] = useState(false);
  const [powermeterListOpen, setPowermeterListOpen] = useState(false);

  const [riderPose, setRiderPose] = useState(Number(initRiderPose));
  const [rimHeight, setRimHeight] = useState(Number(initRimHeight));
  const [tireWidth, setTireWidth] = useState(Number(initTireWidth));
  const [cadence, setCadence] = useState(Number(initCadence));

  useEffect(() => {}, []);

  /*
	const surfaces = [
		{	value: 0, label: 'Velodrome' },
		{	value: 1, label: 'Concrete track' },
		{	value: 2, label: 'Asphalt road' },
		{	value: 3, label: 'Bike road' },
		{	value: 4, label: 'Rough tarmac' },
	];
*/

  const tires = [
    { value: 0, label: '23c' },
    { value: 1, label: '24c' },
    { value: 2, label: '25c' },
    { value: 3, label: '26c' },
    { value: 4, label: '27c' },
    { value: 5, label: '28c' }
  ];

  const rims = [
    { value: 0, label: '25mm' },
    { value: 1, label: '35mm' },
    { value: 2, label: '50mm' },
    { value: 3, label: '65mm' },
    { value: 4, label: '80mm' },
    { value: 5, label: 'Disc' }
  ];

  const grips = [
    { value: 0, label: t('BikeUI.position.top') },
    { value: 1, label: t('BikeUI.position.hood1') },
    { value: 2, label: t('BikeUI.position.hood2') },
    { value: 3, label: t('BikeUI.position.hood3') },
    { value: 4, label: t('BikeUI.position.drop') },
    { value: 5, label: t('BikeUI.position.lever') },
    { value: 6, label: t('BikeUI.position.aero') }
  ];

  const cadenceSpeed = [
    { value: 50, label: '50' },
    { value: 60, label: '' },
    { value: 70, label: '70' },
    { value: 80, label: '' },
    { value: 90, label: '90' },
    { value: 100, label: '' },
    { value: 110, label: '110' },
    { value: 120, label: '' },
    { value: 130, label: '130' }
  ];

  function valueLabelFormat(value) {
    return grips.findIndex(grips => grips.value === value);
  }

  const handleChangeCda = event => {
    if (event.target.value.match(/^-?[0-9]\d*\.?\d*$/) != null) {
      setCda(event.target.value);
    }
  };
  const handleChangeCrrVal = event => {
    if (event.target.value.match(/^-?[0-9]\d*\.?\d*$/) != null) {
      setCrrVal(event.target.value);
    }
  };

  const handleChangeBike = prop => event => {
    if (prop === 'bikeType') {
      setBike({ ...bike, [prop]: event.target.value });
    } else {
      setBike({ ...bike, [prop]: event.target.value });
    }

    CdaResult(prop, event.target.value);
  };

  const handleChangeRider = prop => event => {
    //	console.log(prop + " " + event.target.value);

    setRider({ ...rider, [prop]: event.target.value });
    CdaResult(prop, event.target.value);
  };

  const CdaResult = (prop, newValue) => {
    let bikeType = bike.bikeType;
    let riderHeight = rider.height;
    let riderWeight = rider.weight;
    let helmet = rider.helmet;
    let tire = tireWidth + 0;
    let rim = rimHeight + 0;
    let pose = riderPose + 0;

    //자전거 종류가 변하면 다 변함
    if (prop === 'bikeType') {
      bikeType = newValue;

      if (bikeType === 'road_allround') {
        setBike({
          ...bike,
          ['bikeType']: bikeType,
          ['bikeWeight']: 8,
          ['drivetrain']: 'ultegra'
        });
        setRider({ ...rider, ['helmet']: 'road' });
        setRimHeight(1);
        setRiderPose(2);
        setCrrVal(0.005);
        setTireWidth(2);
        tire = 2;
        rim = 1;
        pose = 2;
      } else if (bikeType === 'road_aero') {
        setBike({
          ...bike,
          ['bikeType']: bikeType,
          ['bikeWeight']: 8.5,
          ['drivetrain']: 'ultegra'
        });
        setRider({ ...rider, ['helmet']: 'aero' });
        setRimHeight(3);
        setRiderPose(3);
        setCrrVal(0.005);
        setTireWidth(2);
        tire = 2;
        rim = 3;
        pose = 2;
      } else if (bikeType === 'road_endurance') {
        setBike({
          ...bike,
          ['bikeType']: bikeType,
          ['bikeWeight']: 8.5,
          ['drivetrain']: 'ultegra'
        });
        setRider({ ...rider, ['helmet']: 'road' });
        setRimHeight(1);
        setRiderPose(2);
        setCrrVal(0.005);
        setTireWidth(2);
        tire = 2;
        rim = 1;
        pose = 2;
      } else if (bikeType === 'road_tt') {
        setBike({
          ...bike,
          ['bikeType']: bikeType,
          ['bikeWeight']: 9,
          ['drivetrain']: 'ultegra'
        });
        setRider({ ...rider, ['helmet']: 'tt' });
        setRimHeight(5);
        setRiderPose(6);
        setCrrVal(0.0045);
        setTireWidth(2);
        tire = 2;
        rim = 5;
        pose = 6;
      } else if (bikeType === 'hybrid') {
        setBike({
          ...bike,
          ['bikeType']: bikeType,
          ['bikeWeight']: 9,
          ['drivetrain']: '105'
        });
        setRider({ ...rider, ['helmet']: 'road' });
        setRimHeight(0);
        setRiderPose(0);
        setTireWidth(2);
        setCrrVal(0.006);
        setTireWidth(2);
        tire = 2;
        rim = 0;
        pose = 1;
      } else if (bikeType === 'mtb') {
        setBike({
          ...bike,
          ['bikeType']: bikeType,
          ['bikeWeight']: 11,
          ['drivetrain']: '105'
        });
        setRider({ ...rider, ['helmet']: 'road' });
        setRimHeight(0);
        setRiderPose(0);
        setCrrVal(0.009);
        setTireWidth(11);
        tire = 11;
        rim = 0;
        pose = 1;
      } else if (bikeType === 'minivelo') {
        setBike({
          ...bike,
          ['bikeType']: bikeType,
          ['bikeWeight']: 11,
          ['drivetrain']: 'sora'
        });
        setRider({ ...rider, ['helmet']: 'road' });
        setRimHeight(2);
        setRiderPose(1);
        setCrrVal(0.006);
        setTireWidth(16);
        tire = 16;
        rim = 0;
        pose = 0;
      } else if (bikeType === 'cruisor') {
        setBike({
          ...bike,
          ['bikeType']: bikeType,
          ['bikeWeight']: 18,
          ['drivetrain']: 'sis'
        });
        setRider({ ...rider, ['helmet']: 'road' });
        setRimHeight(0);
        setRiderPose(0);
        setCrrVal(0.01);
        setTireWidth(11);
        tire = 11;
        rim = 0;
        pose = 0;
      } else {
        setBike({
          ...bike,
          ['bikeType']: bikeType,
          ['bikeWeight']: 8,
          ['drivetrain']: 'ultegra'
        });
        setRider({ ...rider, ['helmet']: 'road' });
        setRimHeight(1);
        setRiderPose(2);
        setCrrVal(0.005);
        setTireWidth(2);
        tire = 2;
        rim = 1;
        pose = 2;
      }
    } else if (prop === 'height') {
      riderHeight = newValue;
    } else if (prop === 'weight') {
      riderWeight = newValue;
    } else if (prop === 'helmet') {
      helmet = newValue;
    } else if (prop === 'tireWidth') {
      tire = newValue;
    } else if (prop === 'rimHeight') {
      rim = newValue;
    } else if (prop === 'riderPose') {
      pose = newValue;
    }

    //바이크 벤지가 0.0583 타막이 0.0658 일반 로드 0.073
    let bikeVal = 0;

    switch (bikeType) {
      case 'road_allround':
        bikeVal = 0.1619;
        break;
      case 'road_aero':
        bikeVal = 0.1568;
        break;
      case 'road_endurance':
        bikeVal = 0.1647;
        break;
      case 'road_tt':
        bikeVal = 0.1532;
        break;
      case 'hybrid':
        bikeVal = 0.1647;
        break;
      case 'mtb':
        bikeVal = 0.1647;
        break;
      case 'cruisor':
        bikeVal = 0.1647;
        break;
      case 'minivelo':
        bikeVal = 0.1647;
        break;
      default:
        bikeVal = 0.1619;
        break;
    }

    //바이크 벤지가 0.0583 타막이 0.0658 일반 로드 0.073
    let helmetVal = 0;

    switch (helmet) {
      case 'road':
        helmetVal = 0.0;
        break;
      case 'aero':
        helmetVal = -0.0052;
        break;
      case 'tt':
        helmetVal = -0.0105;
        break;
      default:
        helmetVal = 0;
        break;
    }

    //타이어너비 바이크에 더함
    let tireVal = 0;
    switch (tire) {
      case 0:
        tireVal = 0;
        break;
      case 1:
        tireVal = 0.0005;
        break;
      case 2:
        tireVal = 0.001;
        break;
      case 3:
        tireVal = 0.0015;
        break;
      case 4:
        tireVal = 0.002;
        break;
      case 5:
        tireVal = 0.0025;
        break;
      default:
        tireVal = 0;
        break;
    }

    //림높이 바이크에 더함
    let rimVal = 0;
    switch (rim) {
      case 0:
        rimVal = 1.0;
        break;
      case 1:
        rimVal = 0.9142;
        break; //rimVal = 0.9742; break;
      case 2:
        rimVal = 0.7625;
        break; //rimVal = 0.9225; break; 0.75~0.76
      case 3:
        rimVal = 0.6967;
        break; //rimVal = 0.8967; break;
      case 4:
        rimVal = 0.6308;
        break; //rimVal = 0.8708; break;
      case 5:
        rimVal = 0.5955;
        break; //rimVal = 0.8455; break;
      default:
        rimVal = 1.0;
        break;
    }

    //추후에 포지션 전체값에 곱하지 말고, rimValue는 간섭이 일어나면 안되니 향후 더해야 함
    let totalCda =
      0.0276 *
        Math.pow(riderHeight / 100, 0.725) *
        Math.pow(riderWeight, 0.425) +
      helmetVal +
      (bikeVal + tireVal) * rimVal;

    //포즈변수 전체수에 곱하기
    let poseVal = 0.95;
    switch (pose) {
      case 0:
        poseVal = 0.95;
        break;
      case 1:
        poseVal = 0.9;
        break;
      case 2:
        poseVal = 0.85;
        break;
      case 3:
        poseVal = 0.8;
        break;
      case 4:
        poseVal = 0.77;
        break;
      case 5:
        poseVal = 0.748;
        break;
      case 6:
        poseVal = 0.71;
        break;
      default:
        poseVal = 0.95;
        break;
    }
    //표준체형 172 62 (top 1.0) (hs4 0.925) (hs2 0.875) (hs0 0.825) (drop 0.775) (le 0.748) (aero 0.71)
    //표준체형 172 62 (top 1.0) (hs4 0.925) (hs2 0.875) (hs0 0.825) (drop 0.775) (le 0.74) (aero 0.7)

    setCda(Number(poseVal * totalCda).toFixed(6));
  };

  const CrrUpdate = (tire, roadSurface) => {
    let correction = 1.0;
    let surfaceVal = 1.0;

    switch (roadSurface) {
      case 0:
        surfaceVal = 0.5;
        break;
      case 1:
        surfaceVal = 0.75;
        break;
      case 2:
        surfaceVal = 1.0;
        break;
      case 3:
        surfaceVal = 1.25;
        break;
      case 4:
        surfaceVal = 1.75;
        break;
      default:
        surfaceVal = 1.0;
        break;
    }

    switch (tire) {
      case 0:
        correction = 1.053;
        break;
      case 1:
        correction = 1.025;
        break;
      case 2:
        correction = 1.0;
        break;
      case 3:
        correction = 0.972;
        break;
      case 4:
        correction = 0.944;
        break;
      case 5:
        correction = 0.914;
        break;
      default:
        correction = 1.0;
        break;
    }

    setCrrVal(Number(tireCrr * surfaceVal * correction).toFixed(6));
  };

  const rimHeightChange = (event, selectedRim) => {
    setRimHeight(selectedRim);
    CdaResult('rimHeight', selectedRim);
  };
  const tireWidthChange = (event, selectedTire) => {
    setTireWidth(selectedTire);
    CdaResult('tireWidth', selectedTire);
    CrrUpdate(selectedTire, roadSurface);
  };
  const surfaceChange = event => {
    console.log(event.target.value);
    setRoadSurface(event.target.value);
    CrrUpdate(tireWidth, event.target.value);
  };

  const poseChange = (event, selectedPose) => {
    setRiderPose(selectedPose);
    CdaResult('riderPose', selectedPose);
  };
  const cadenceChange = (event, selectedCadence) => {
    setCadence(selectedCadence);
  };

  const handleClickSetting = event => {
    alert(t('BikeUI.saveBikeKit'));

    const auth_str = 'Bearer '.concat(auth.getToken().token);

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/bikekit.php`,
        {
          order: 'update_bike_kit',
          gear_id: gearInfo.gear_id,

          bike_type: bike.bikeType,
          bike_weight: bike.bikeWeight,
          company_name: bike.companyName,
          powermeter_name: bike.powermeter,

          drivetrain: bike.drivetrain,

          crr: crrVal,
          cda: cda,
          rim_height: rimHeight,

          tire_product: bike.tireProduct,
          tire_width: tireWidth,
          cadence: cadence,
          rider_pose: riderPose,
          surface_select: roadSurface
        },
        {
          headers: { Authorization: auth_str }
        }
      )
      .then(response => {
        if (response.data.success) {
          //				let exInfo = auth.getExtraInfo();
          //				exInfo.weight = Number(response.data.new_weight);
          //				exInfo.updateAvail = false;
          //				auth.setExtraInfo(exInfo, true);
          //				setUpdateAvail(false);
        }
      })
      .catch(err => {
        console.log(err);
      });

    //    cookie.remove('initCookie', { path: '/' });
    //	  event.preventDefault();
  };

  const genderRange = () => {
    let range = [];
    range.push(
      <MenuItem key={0} value={'M'}>
        {t('BikeUI.male')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={1} value={'F'}>
        {t('BikeUI.female')}
      </MenuItem>
    );
    return range;
  };

  const heightRange = () => {
    let range = [];
    for (var i = 140; i <= 200; i++) {
      range.push(
        <MenuItem key={i} value={i}>
          {i}cm
        </MenuItem>
      );
    }
    return range;
  };

  /*
	const weightRange = () => {
	  let range = [];
		for (var i=30; i <= 120; i++) {
			range.push(<MenuItem key={i} value={i}>{i}kg</MenuItem>);
		}
		return range;
	}
*/

  const helmetRange = () => {
    let range = [];
    range.push(
      <MenuItem key={0} value={'road'}>
        {t('BikeUI.road')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={1} value={'aero'}>
        {t('BikeUI.aero')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={2} value={'tt'}>
        {t('BikeUI.tt')}
      </MenuItem>
    );

    return range;
  };

  const bikeTypeRange = () => {
    let range = [];
    range.push(
      <MenuItem key={0} value={'road_allround'}>
        {t('BikeUI.road_allround')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={1} value={'road_aero'}>
        {t('BikeUI.road_aero')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={2} value={'road_endurance'}>
        {t('BikeUI.road_endurance')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={3} value={'road_tt'}>
        {t('BikeUI.road_tt')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={4} value={'hybrid'}>
        {t('BikeUI.hybrid')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={5} value={'mtb'}>
        {t('BikeUI.mtb')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={6} value={'cruisor'}>
        {t('BikeUI.cruisor')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={7} value={'minivelo'}>
        {t('BikeUI.minivelo')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={8} value={'trainer'}>
        {t('BikeUI.trainer')}
      </MenuItem>
    );

    /*				
		range.push(<MenuItem key={7} value={"fixed"}>픽시바이크</MenuItem>);
*/
    return range;
  };

  const powermeterRange = () => {
    let range = [];

    range.push(
      <MenuItem key={0} value={'none'}>
        NONE
      </MenuItem>
    );
    range.push(
      <MenuItem key={1} value={'Favero Assioma'}>
        Favero Assioma
      </MenuItem>
    );
    range.push(
      <MenuItem key={2} value={'SRAM Quarq'}>
        SRAM Quarq
      </MenuItem>
    );
    range.push(
      <MenuItem key={3} value={'SRM Original'}>
        SRM Original
      </MenuItem>
    );
    range.push(
      <MenuItem key={4} value={'SRM Exakt'}>
        SRM Exakt
      </MenuItem>
    );
    range.push(
      <MenuItem key={5} value={'Infocrank'}>
        Infocrank
      </MenuItem>
    );
    range.push(
      <MenuItem key={6} value={'Rotor Inpower'}>
        Rotor Inpower
      </MenuItem>
    );
    range.push(
      <MenuItem key={7} value={'Rotor 2INpower'}>
        Rotor 2INpower
      </MenuItem>
    );
    range.push(
      <MenuItem key={8} value={'Rotor INspider'}>
        Rotor INspider
      </MenuItem>
    );
    range.push(
      <MenuItem key={9} value={'Power2max NG'}>
        Power2max NG
      </MenuItem>
    );
    range.push(
      <MenuItem key={10} value={'Power2max NGeco'}>
        Power2max NGeco
      </MenuItem>
    );
    range.push(
      <MenuItem key={11} value={'4iiii Podiiiium'}>
        4iiii Podiiiium
      </MenuItem>
    );
    range.push(
      <MenuItem key={12} value={'4iiii Precision'}>
        4iiii Precision
      </MenuItem>
    );
    range.push(
      <MenuItem key={13} value={'Stages Single'}>
        Stages Single
      </MenuItem>
    );
    range.push(
      <MenuItem key={14} value={'Stages Dual'}>
        Stages Dual
      </MenuItem>
    );
    range.push(
      <MenuItem key={15} value={'Garmin Vector'}>
        Garmin Vector
      </MenuItem>
    );
    range.push(
      <MenuItem key={16} value={'Garmin Rally'}>
        Garmin Rally
      </MenuItem>
    );
    range.push(
      <MenuItem key={17} value={'Giant Power Pro'}>
        Giant Power Pro
      </MenuItem>
    );
    range.push(
      <MenuItem key={18} value={'Shimano Powermeter'}>
        Shimano Powermeter
      </MenuItem>
    );
    range.push(
      <MenuItem key={19} value={'Velocomp Powerpod'}>
        Velocomp Powerpod
      </MenuItem>
    );
    range.push(
      <MenuItem key={20} value={'Chinese Powermeter'}>
        Chinese Powermeter
      </MenuItem>
    );
    return range;
  };

  const drivetrainRange = () => {
    let range = [];
    range.push(
      <MenuItem key={1} value={'duraAce'}>
        DuraAce 11s
      </MenuItem>
    );
    range.push(
      <MenuItem key={2} value={'ultegra'}>
        Ultegra 11s
      </MenuItem>
    );
    range.push(
      <MenuItem key={3} value={'105'}>
        105 11s
      </MenuItem>
    );
    range.push(
      <MenuItem key={4} value={'tiagra'}>
        Tiagra 10s
      </MenuItem>
    );
    range.push(
      <MenuItem key={5} value={'sora'}>
        Sora 9s
      </MenuItem>
    );
    range.push(
      <MenuItem key={6} value={'claris'}>
        Claris 8s
      </MenuItem>
    );
    range.push(
      <MenuItem key={7} value={'sis'}>
        SIS 7s
      </MenuItem>
    );

    range.push(
      <MenuItem key={11} value={'redAxs'}>
        Red 11,12s
      </MenuItem>
    );
    range.push(
      <MenuItem key={12} value={'forceAxs'}>
        Force 11,12s
      </MenuItem>
    );
    range.push(
      <MenuItem key={13} value={'rival'}>
        Rival 11,12s
      </MenuItem>
    );
    range.push(
      <MenuItem key={14} value={'apex'}>
        Apex 10s
      </MenuItem>
    );

    range.push(
      <MenuItem key={21} value={'superRecord'}>
        SuperRecord 11,12s
      </MenuItem>
    );
    range.push(
      <MenuItem key={22} value={'Record'}>
        Record 11,12s
      </MenuItem>
    );
    range.push(
      <MenuItem key={23} value={'Chorus'}>
        Chorus 11s
      </MenuItem>
    );
    range.push(
      <MenuItem key={24} value={'Potenza'}>
        Potenza 11s
      </MenuItem>
    );
    range.push(
      <MenuItem key={25} value={'Athena'}>
        Athena 11s
      </MenuItem>
    );
    range.push(
      <MenuItem key={26} value={'Veloce'}>
        Veloce 10s
      </MenuItem>
    );
    range.push(
      <MenuItem key={27} value={'Centaur'}>
        Centaur 10s
      </MenuItem>
    );

    range.push(
      <MenuItem key={31} value={'kForce'}>
        K-Force 11s
      </MenuItem>
    );

    return range;
  };

  const surfaceRange = () => {
    let range = [];
    range.push(
      <MenuItem key={0} value={0}>
        Velodrome
      </MenuItem>
    );
    range.push(
      <MenuItem key={1} value={1}>
        Concrete track
      </MenuItem>
    );
    range.push(
      <MenuItem key={2} value={2}>
        Asphalt road
      </MenuItem>
    );
    range.push(
      <MenuItem key={3} value={3}>
        Bike road
      </MenuItem>
    );
    range.push(
      <MenuItem key={4} value={4}>
        Rough tarmac
      </MenuItem>
    );
    return range;
  };

  const handleClickTireListOpen = async () => {
    try {
      const auth_str = 'Bearer '.concat(auth.getToken().token);

      await axios
        .get(`${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-crr.php`, {
          headers: { Authorization: auth_str },
          params: {
            order: 'get_tire_list'
          }
        })
        .then(response => {
          if (response.data) {
            setTireList(response.data.tire_list);
            setTireListOpen(true);
          }
        });
    } catch (err) {
      console.error(err);
    }
  };

  function handleClickTireListClose(value) {
    setTireListOpen(false);

    let crrVal = 0.005;
    let tireWidth = 2;
    let tireProduct = 'Clincher (Butyl tube)';

    if (value > 1000) {
      switch (value) {
        case 1001:
          crrVal = 0.003 * 1.125;
          tireProduct = 'Tubular high';
          break;
        case 1002:
          crrVal = 0.0035 * 1.125;
          tireProduct = 'Tubular mid';
          break;
        case 1011:
          crrVal = 0.003 * 1.125;
          tireProduct = 'Tubeless high';
          break;
        case 1012:
          crrVal = 0.004 * 1.125;
          tireProduct = 'Tubeless mid';
          break;
        case 1021:
          crrVal = 0.004 * 1.125;
          tireProduct = 'Clincher high (butyl)';
          break;
        case 1022:
          crrVal = 0.005 * 1.125;
          tireProduct = 'Clincher mid (butyl)';
          break;
        case 1023:
          crrVal = 0.006 * 1.125;
          tireProduct = 'Clincher low (butyl)';
          break;
        case 1031:
          crrVal = 0.0036 * 1.125;
          tireProduct = 'Clincher high (latex)';
          break;
        case 1032:
          crrVal = 0.0046 * 1.125;
          tireProduct = 'Clincher mid (latex)';
          break;
        case 1033:
          crrVal = 0.0056 * 1.125;
          tireProduct = 'Clincher low (latex)';
          break;
        default:
          crrVal = 0.005 * 1.125;
          tireProduct = 'Clincher mid (butyl)';
          break;
      }
    } else {
      let selectedItem = tireList.filter(function(item) {
        return item.tire_id === value;
      });

      if (Object.keys(selectedItem).length > 0) {
        //				let psi_120 = Number(selectedItem[0].psi_120);
        //				let psi_100 = Number(selectedItem[0].psi_100);
        let psi_80 = Number(selectedItem[0].psi_80);
        let psi_60 = Number(selectedItem[0].psi_60);
        let psi_70 = Number((psi_80 + psi_60) / 2);
        let width_s = Number(selectedItem[0].width_s);
        //				let width_r = Number(selectedItem[0].width_r);

        let brand = selectedItem[0].brand;
        let product = selectedItem[0].product;

        crrVal = Number(psi_70).toFixed(6);
        tireWidth = width_s - 23;
        tireProduct = brand + ' ' + product + ' (' + width_s + 'c)';
      }
    }

    setTireCrr(crrVal);
    setCrrVal(crrVal);
    setRoadSurface(2);

    bike.tireSelect = value;
    bike.tireProduct = tireProduct;
    setTireWidth(tireWidth);
  }

  const handleClickPowerDevicesOpen = async () => {
    //		setSegments(null);
    //    setSegmentListOpen(true);
  };

  const handleClickBikeCompanyOpen = async () => {
    setBikeCompanyList(null);
    setBikeCompanyListOpen(true);
  };

  function handleClickBikeCompanyClose(obj) {
    setBikeCompanyListOpen(false);

    if (typeof obj === 'object') {
      setBike({ ...bike, ['companyName']: obj.name });
    } else if (obj === 0) {
      bike.company_id = 0;
      setBike({ ...bike, ['companyName']: 'Other brands' });
    }
  }

  //시뮬레이션 3가지 토글버튼
  const [simulationActive, setSimulationActive] = useState('segment');
  const clickSimulation = value => {
    setSimulationActive(value);
  };

  //mm 추가
  function mmtext(value) {
    return `${value}mm`;
  }

  //자전거종류에 따른 슬라이더 비활성화
  function bikeTypeFixedPose(pose) {
    if (
      bike.bikeType === 'road_allround' ||
      bike.bikeType === 'road_aero' ||
      bike.bikeType === 'road_endurance'
    ) {
      return pose;
    } else if (bike.bikeType === 'road_tt') {
      return 6;
    } else {
      return 0;
    }
  }

  function bikeTypeDisabledPose() {
    if (
      bike.bikeType === 'road_allround' ||
      bike.bikeType === 'road_aero' ||
      bike.bikeType === 'road_endurance'
    ) {
      return false;
    } else {
      return true;
    }
  }

  function tireWidthFixed(tireWidth) {
    if (bike.bikeType === 'mtb') {
      return 11;
    } else if (bike.bikeType === 'minivelo') {
      return 20;
    } else {
      return tireWidth;
    }
  }

  function tireWidthDisabled() {
    if (
      bike.bikeType === 'mtb' ||
      bike.bikeType === 'cruisor' ||
      bike.bikeType === 'minivelo' ||
      bike.bikeType === 'trainer'
    ) {
      return true;
    } else {
      return false;
    }
  }

  function tireWidthDisabled() {
    if (
      bike.bikeType === 'mtb' ||
      bike.bikeType === 'cruisor' ||
      bike.bikeType === 'minivelo' ||
      bike.bikeType === 'trainer'
    ) {
      return true;
    } else {
      return false;
    }
  }

  function rimHeightFixed(rimHeight) {
    if (
      bike.bikeType === 'mtb' ||
      bike.bikeType === 'cruisor' ||
      bike.bikeType === 'minivelo' ||
      bike.bikeType === 'trainer'
    ) {
      return 1;
    } else {
      return rimHeight;
    }
  }

  function rimHeightDisabled() {
    if (
      bike.bikeType === 'mtb' ||
      bike.bikeType === 'cruisor' ||
      bike.bikeType === 'minivelo' ||
      bike.bikeType === 'trainer'
    ) {
      return true;
    } else {
      return false;
    }
  }

  //스마트트레이너는 포함 안함
  function drivetrainDisabled() {
    if (
      bike.bikeType === 'mtb' ||
      bike.bikeType === 'cruisor' ||
      bike.bikeType === 'minivelo'
    ) {
      return true;
    } else {
      return false;
    }
  }

  function tireProductDisabled() {
    if (
      bike.bikeType === 'mtb' ||
      bike.bikeType === 'cruisor' ||
      bike.bikeType === 'minivelo' ||
      bike.bikeType === 'trainer'
    ) {
      return true;
    } else {
      return false;
    }
  }

  function bikeWeightDisabled() {
    if (bike.bikeType === 'trainer') {
      return true;
    } else {
      return false;
    }
  }

  function bikeCompanyDisabled() {
    if (bike.bikeType === 'trainer') {
      return true;
    } else {
      return false;
    }
  }

  function powermeterDisabled() {
    if (bike.bikeType === 'trainer') {
      return true;
    } else {
      return false;
    }
  }

  return (
    <div className={classes.root}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader
          title={t('BikeUI.headerPosition')}
          style={{ padding: '12px 16px 6px 16px' }}
          classes={{ title: classes.cardHeader }}
        />
        <CardContent style={{ paddingBottom: '16px' }}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={6} xl={3} sm={6} xs={6}>
              <TextField
                variant="outlined"
                select
                className={clsx(classes.margin, classes.textField)}
                label={t('BikeUI.label.gender')}
                value={rider.gender}
                onChange={handleChangeRider('gender')}
                InputProps={{ classes: { input: classes.inputStyle } }}
                disabled>
                {genderRange()}
              </TextField>
            </Grid>
            <Grid item lg={3} md={6} xl={3} sm={6} xs={6}>
              <TextField
                variant="outlined"
                select
                className={clsx(classes.margin, classes.textField)}
                label={t('BikeUI.label.height')}
                value={rider.height}
                onChange={handleChangeRider('height')}
                InputProps={{ classes: { input: classes.inputStyle } }}>
                {heightRange()}
              </TextField>
            </Grid>
            <Grid item lg={3} md={6} xl={3} sm={6} xs={6}>
              <TextField
                variant="outlined"
                className={clsx(classes.margin, classes.textField)}
                label={t('BikeUI.label.weight')}
                value={rider.weight}
                onChange={handleChangeRider('weight')}
                InputProps={{
                  classes: { input: classes.inputStyle },
                  endAdornment: (
                    <InputAdornment position="start">kg</InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item lg={3} md={6} xl={3} sm={6} xs={6}>
              <TextField
                variant="outlined"
                select
                className={clsx(classes.margin, classes.textField)}
                label={t('BikeUI.label.helmet')}
                value={rider.helmet}
                onChange={handleChangeRider('helmet')}
                InputProps={{ classes: { input: classes.inputStyle } }}>
                {helmetRange()}
              </TextField>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Grid container spacing={2}>
            <Grid
              item
              lg={3}
              md={6}
              xl={3}
              sm={12}
              xs={12}
              className={classes.item1}>
              <Grid container spacing={2}>
                <Grid item lg={12} md={12} xl={12} sm={6} xs={6}>
                  <TextField
                    variant="outlined"
                    select
                    className={clsx(classes.margin, classes.textField)}
                    label={t('BikeUI.label.bikeType')}
                    value={bike.bikeType}
                    onChange={handleChangeBike('bikeType')}
                    InputProps={{
                      classes: { input: classes.inputStyle }
                    }}>
                    {bikeTypeRange()}
                  </TextField>
                </Grid>
                <Grid item lg={12} md={12} xl={12} sm={6} xs={6}>
                  <TextField
                    variant="outlined"
                    className={clsx(classes.margin, classes.textField)}
                    label={t('BikeUI.label.bikeWeight')}
                    value={bike.bikeWeight}
                    disabled={bikeWeightDisabled()}
                    onChange={handleChangeBike('bikeWeight')}
                    InputProps={{
                      classes: { input: classes.inputStyle },
                      endAdornment: (
                        <InputAdornment position="start">kg</InputAdornment>
                      )
                    }}
                  />
                </Grid>
                <Grid item lg={12} md={12} xl={12} sm={6} xs={6}>
                  <TextField
                    variant="outlined"
                    className={clsx(classes.margin, classes.textField)}
                    label={t('BikeUI.label.tireProduct')}
                    value={bike.tireProduct}
                    disabled={tireProductDisabled()}
                    onClick={handleClickTireListOpen}
                    InputProps={{
                      classes: { input: classes.inputStyle },
                      readOnly: true
                    }}
                  />
                  <div>
                    <TireDialog
                      selectedValue={bike.tireProduct}
                      open={tireListOpen}
                      onClose={handleClickTireListClose}
                      tireList={tireList}
                    />
                  </div>
                </Grid>
                <Grid item lg={12} md={12} xl={12} sm={6} xs={6}>
                  <TextField
                    variant="outlined"
                    select
                    className={clsx(classes.margin, classes.textFieldLast)}
                    label={t('BikeUI.label.drivetrain')}
                    value={bike.drivetrain}
                    disabled={drivetrainDisabled()}
                    onChange={handleChangeBike('drivetrain')}
                    InputProps={{ classes: { input: classes.inputStyle } }}>
                    {drivetrainRange()}
                  </TextField>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              lg={6}
              md={12}
              xl={6}
              sm={12}
              xs={12}
              className={classes.item2}>
              <div className={classes.lineBox}>
                <RiderAndBike
                  bikeType={bike.bikeType}
                  helmetType={rider.helmet}
                  tireWidth={tireWidth}
                  rimHeight={rimHeight}
                  riderPose={riderPose}
                  cadence={cadence}
                  roadSurface={roadSurface}
                />
                <Grid container spacing={2} className={classes.animationBox}>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xl={4}
                    sm={4}
                    xs={4}
                    className={classes.smallGrid}>
                    <span className={classes.smallInputLabel}>CRR</span>
                    <input
                      maxLength="8"
                      className={classes.smallInput}
                      onChange={handleChangeCrrVal}
                      disabled={bikeWeightDisabled()}
                      value={crrVal}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xl={4}
                    sm={4}
                    xs={4}
                    className={classes.smallGrid}>
                    <span className={classes.smallInputLabel}>CdA</span>
                    <input
                      maxLength="8"
                      className={classes.smallInput}
                      onChange={handleChangeCda}
                      disabled={bikeWeightDisabled()}
                      value={cda}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xl={4}
                    sm={4}
                    xs={4}
                    className={classes.smallGrid}>
                    <Select
                      value={roadSurface}
                      onChange={surfaceChange}
                      inputProps={{
                        name: t('BikeUI.label.surface'),
                        id: 'road-surface'
                      }}
                      disabled={bikeWeightDisabled()}
                      className={classes.selectLine}>
                      {surfaceRange()}
                    </Select>
                  </Grid>
                </Grid>
              </div>
            </Grid>

            <Grid
              item
              lg={3}
              md={6}
              xl={3}
              sm={12}
              xs={12}
              className={classes.item3}>
              <Box className={classes.box}>
                <Typography
                  id="discrete-slider-always"
                  className={
                    'MuiFormLabel-root MuiInputLabel-outlined MuiInputLabel-shrink ' +
                    classes.boxLegend
                  }>
                  {t('BikeUI.label.tireWidth')}
                </Typography>
                <Slider
                  defaultValue={2}
                  getAriaValueText={mmtext}
                  aria-labelledby="discrete-slider-restrict"
                  marks={tires}
                  min={0}
                  max={5}
                  valueLabelDisplay="off"
                  value={tireWidthFixed(tireWidth)}
                  disabled={tireWidthDisabled()}
                  onChange={tireWidthChange}
                />
              </Box>

              <Box className={classes.box}>
                <Typography
                  id="discrete-slider-always"
                  className={
                    'MuiFormLabel-root MuiInputLabel-outlined MuiInputLabel-shrink ' +
                    classes.boxLegend
                  }>
                  {t('BikeUI.label.rimHeight')}
                </Typography>
                <Slider
                  defaultValue={1}
                  getAriaValueText={mmtext}
                  aria-labelledby="discrete-slider-restrict"
                  marks={rims}
                  min={0}
                  max={5}
                  valueLabelDisplay="off"
                  value={rimHeightFixed(rimHeight)}
                  disabled={rimHeightDisabled()}
                  onChange={rimHeightChange}
                />
              </Box>
              <Box className={classes.box}>
                <Typography
                  id="discrete-slider-always"
                  className={
                    'MuiFormLabel-root MuiInputLabel-outlined MuiInputLabel-shrink ' +
                    classes.boxLegend
                  }>
                  {t('BikeUI.label.cadence')}
                </Typography>
                <Slider
                  defaultValue={2}
                  valueLabelFormat={valueLabelFormat}
                  step={null}
                  marks={cadenceSpeed}
                  min={50}
                  max={130}
                  valueLabelDisplay="off"
                  value={cadence}
                  onChange={cadenceChange}
                />
              </Box>
              <Box className={classes.box}>
                <Typography
                  id="discrete-slider-always"
                  className={
                    'MuiFormLabel-root MuiInputLabel-outlined MuiInputLabel-shrink ' +
                    classes.boxLegend
                  }>
                  {t('BikeUI.label.position')}
                </Typography>
                <Slider
                  defaultValue={2}
                  valueLabelFormat={valueLabelFormat}
                  aria-labelledby="discrete-slider-restrict"
                  marks={grips}
                  min={0}
                  max={6}
                  valueLabelDisplay="off"
                  value={bikeTypeFixedPose(riderPose)}
                  disabled={bikeTypeDisabledPose()}
                  onChange={poseChange}
                />
              </Box>
            </Grid>
          </Grid>

          <Divider className={classes.divider} />

          <Grid container spacing={2}>
            <Grid item lg={3} md={6} xl={3} sm={6} xs={6}>
              <TextField
                variant="outlined"
                className={clsx(classes.margin, classes.textField)}
                value={bike.companyName}
                label={t('BikeUI.label.bikeCompany')}
                disabled={bikeCompanyDisabled()}
                onClick={handleClickBikeCompanyOpen}
                InputProps={{
                  classes: { input: classes.inputStyle },
                  readOnly: true
                }}></TextField>
              <div>
                <BikeCompanyDialog
                  selectedValue={bike.companyName}
                  open={bikeCompanyListOpen}
                  onClose={handleClickBikeCompanyClose}
                  bikeCompanyList={bikeCompanyList}
                />
              </div>
            </Grid>

            <Grid item lg={3} md={6} xl={3} sm={6} xs={6}>
              <TextField
                variant="outlined"
                select
                className={clsx(classes.margin, classes.textField)}
                label={t('BikeUI.label.powermeter')}
                value={bike.powermeter}
                disabled={powermeterDisabled()}
                onChange={handleChangeBike('powermeter')}
                InputProps={{
                  classes: { input: classes.inputStyle }
                }}>
                {powermeterRange()}
              </TextField>
            </Grid>

            <Grid item lg={3} md={6} xl={3} sm={6} xs={6}></Grid>

            <Grid item lg={3} md={6} xl={3} sm={6} xs={6}></Grid>
          </Grid>

          <Divider className={classes.divider} />
        </CardContent>
      </Card>

      <div
        style={{
          display: 'none',
          textAlign: 'center',
          margin: '24px 0 24px 0'
        }}>
        <ButtonGroup>
          <Button
            className={classes.bottomTabButton}
            size="large"
            color={simulationActive === 'segment' ? 'secondary' : 'default'}
            onClick={() => clickSimulation('segment')}>
            {t('BikeUI.segmentTab')}
          </Button>
          <Button
            className={classes.bottomTabButton}
            size="large"
            color={simulationActive === 'course' ? 'secondary' : 'default'}
            onClick={() => clickSimulation('course')}>
            {t('BikeUI.courseTab')}
          </Button>
          <Button
            className={classes.bottomTabButton}
            size="large"
            color={simulationActive === 'powerdata' ? 'secondary' : 'default'}
            onClick={() => clickSimulation('powerdata')}>
            {t('BikeUI.powerdataTab')}
          </Button>
        </ButtonGroup>
      </div>

      <Box
        style={
          simulationActive === 'segment'
            ? { display: 'block' }
            : { display: 'none' }
        }>
        <SimulationSegment
          userInfo={userInfo}
          rider={rider}
          bike={bike}
          crrVal={crrVal}
          cda={cda}
          rimHeight={rimHeight}
          tireWidth={tireWidth}
          cadence={cadence}
          riderPose={riderPose}
          roadSurface={roadSurface}
        />
      </Box>
      <Box
        style={
          simulationActive === 'course'
            ? { display: 'block' }
            : { display: 'none' }
        }>
        <Typography style={{ textAlign: 'center' }}>
          {t('BikeUI.premium')}
        </Typography>
      </Box>

      <Box
        style={
          simulationActive === 'powerdata'
            ? { display: 'block' }
            : { display: 'none' }
        }>
        <Typography style={{ textAlign: 'center' }}>
          {t('BikeUI.premium')}
        </Typography>
      </Box>
    </div>
  );
};

BikeUI.propTypes = {
  className: PropTypes.string
};

export default withRouter(BikeUI);
