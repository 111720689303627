// import React, { useState } from 'react';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useEmblaCarousel from 'embla-carousel-react';
import { Box, IconButton, Stack } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { CTAButton, ContentDialog } from 'components/Common';
import { DynamicFontTypography } from 'components/Typography';
import { makeStyles } from '@mui/styles';

import AnimalsStyles from '../../views/Landing/components/Animals/animals.module.scss';
import LottieComponent from '../../views/Landing/components/Animals/LottieComponent';
import Cow from '../../views/Landing/components/asset/2024_cow.json';
import Camel from '../../views/Landing/components/asset/2024_camel.json';
import Pig from '../../views/Landing/components/asset/2024_pig.json';
import Lion from '../../views/Landing/components/asset/2024_lion.json';
import Wolf from '../../views/Landing/components/asset/2024_wolf.json';

import Tiger from '../../views/Landing/components/asset/2024_tiger.json';
import Dog from '../../views/Landing/components/asset/2024_dog.json';
import Deer from '../../views/Landing/components/asset/2024_deer.json';
import Leopard from '../../views/Landing/components/asset/2024_leopard.json';
import Horse from '../../views/Landing/components/asset/2024_horse.json';
import Elephant from '../../views/Landing/components/asset/2024_elephant.json';

const useStyles = makeStyles(theme => ({
  root: {},
  heading: {
    fontSize: 'clamp(24px, 3.0vw, 28px)',
    lineHeight: 'clamp(32px, 3.4vw, 36px)',
    fontWeight: 500,
    maxWidth: '600px',
    whiteSpace: 'normal'
  },
  sub: {
    fontSize: 'clamp(16px, 1.3vw, 18px)',
    lineHeight: 'clamp(22px, 1.5vw, 24px)',
    fontWeight: 300,
    marginBottom: 'min(36px, 3.6vw)',
    whiteSpace: 'normal'
  }
}));

function WorkoutCard({
  img,
  url,
  title,
  style,
  desc,
  fullDesc,
  cta,
  bgcolor,
  open,
  setOpen,
  setDialogContent
}) {
  const classes = useStyles();

  return (
    <Box
      flexShrink={0}
      width={{ xs: '100%', sm: '300px', md: '340px', lg: '350px' }}
      height="min-content"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap={1}>
      <Box
        bgcolor={bgcolor}
        width="100%"
        height="340px"
        borderRadius="4px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative">
        <Box
          width={{ xs: '240px', sm: '260px' }}
          height={{ xs: '240px', sm: '260px' }}
          borderRadius="4px"
          sx={{ objectFit: 'cover', position: 'relative' }}>
          <IconButton
            sx={{ position: 'absolute', right: { xs: -6, sm: -4, md: 0 } }}
            onClick={() => {
              setDialogContent({ title, content: fullDesc, url });
              setOpen(!open);
            }}>
            <InfoIcon color="action" />
          </IconButton>
          <LottieComponent
            style={{
              ...style,
              width: '100%',
              height: '100%',
              pointerEvents: 'none'
            }}
            animationData={img}
          />
        </Box>
      </Box>
      <Box
        gap="4px"
        display="block"
        minHeight="130px"
        height="fit-content"
        maxHeight="150px"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        textAlign="center">
        <DynamicFontTypography className={classes.heading}>
          {title}
        </DynamicFontTypography>
        <DynamicFontTypography className={classes.sub}>
          {desc}
        </DynamicFontTypography>
      </Box>
      <CTAButton cta={cta} url={url} />
    </Box>
  );
}

export default function Workouts() {
  const { t } = useTranslation('landing');
  const [workoutList, setWorkoutList] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: '',
    content: '',
    url: ''
  });

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: 'start',
    loop: false
  });
  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);
  const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi]);

  const updateScrollButtons = useCallback(() => {
    if (!emblaApi) return;
    setCanScrollPrev(emblaApi.canScrollPrev());
    setCanScrollNext(emblaApi.canScrollNext());
  }, [emblaApi]);

  useEffect(() => {
    if (!emblaApi) return;
    updateScrollButtons();
    emblaApi.on('select', updateScrollButtons);
    emblaApi.on('reInit', updateScrollButtons);
  }, [emblaApi, updateScrollButtons]);

  useEffect(() => {
    const WorkoutList = [
      {
        img: Camel,
        style: AnimalsStyles.Camel,
        title: t('Workouts.camel.title'),
        desc: t('Workouts.camel.desc'),
        fullDesc: t('Workouts.camel.fullDesc'),
        bgcolor: '#76B5E8',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#fatmax-camel'
      },
      {
        img: Cow,
        style: AnimalsStyles.Cow,
        title: t('Workouts.cow.title'),
        desc: t('Workouts.cow.desc'),
        fullDesc: t('Workouts.cow.fullDesc'),
        bgcolor: '#91cfff',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#base-cow'
      },
      {
        img: Deer,
        style: AnimalsStyles.Deer,
        title: t('Workouts.deer.title'),
        desc: t('Workouts.deer.desc'),
        fullDesc: t('Workouts.deer.fullDesc'),
        bgcolor: '#ffe18e',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#prmd-reindeer'
      },
      {
        img: Dog,
        style: AnimalsStyles.Dog,
        title: t('Workouts.dog.title'),
        desc: t('Workouts.dog.desc'),
        fullDesc: t('Workouts.dog.fullDesc'),
        bgcolor: '#d8e8d9',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#balance-dog'
      },
      {
        img: Elephant,
        style: AnimalsStyles.Elephant,
        title: t('Workouts.elephant.title'),
        desc: t('Workouts.elephant.desc'),
        fullDesc: t('Workouts.elephant.fullDesc'),
        bgcolor: '#96c6ea',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#polarization-elephant'
      },
      {
        img: Horse,
        style: AnimalsStyles.Horse,
        title: t('Workouts.horse.title'),
        desc: t('Workouts.horse.desc'),
        fullDesc: t('Workouts.horse.fullDesc'),
        bgcolor: '#fcdd88',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#race-pace-horse'
      },
      {
        img: Leopard,
        style: AnimalsStyles.Leopard,
        title: t('Workouts.leopard.title'),
        desc: t('Workouts.leopard.desc'),
        fullDesc: t('Workouts.leopard.fullDesc'),
        bgcolor: '#f2c5b5',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#hiit-leopard'
      },
      {
        img: Lion,
        style: AnimalsStyles.Lion,
        title: t('Workouts.lion.title'),
        desc: t('Workouts.lion.desc'),
        fullDesc: t('Workouts.lion.fullDesc'),
        bgcolor: '#f0e0e8',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#sprint-lion'
      },
      {
        img: Pig,
        style: AnimalsStyles.Pig,
        title: t('Workouts.pig.title'),
        desc: t('Workouts.pig.desc'),
        fullDesc: t('Workouts.pig.fullDesc'),
        bgcolor: '#cae29a',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#tempo-pig'
      },
      {
        img: Tiger,
        style: AnimalsStyles.Tiger,
        title: t('Workouts.tiger.title'),
        desc: t('Workouts.tiger.desc'),
        fullDesc: t('Workouts.tiger.fullDesc'),
        bgcolor: '#e8c2d6',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#tabata-tiger'
      },
      {
        img: Wolf,
        style: AnimalsStyles.Wolf,
        title: t('Workouts.wolf.title'),
        desc: t('Workouts.wolf.desc'),
        fullDesc: t('Workouts.wolf.fullDesc'),
        bgcolor: '#ffe396',
        url:
          'https://riduck.gitbook.io/riduck-guide/english/use/riduck-with-animals#vo2max-wolf'
      }
    ];

    function shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      while (currentIndex !== 0) {
        // Pick a remaining element
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // Swap it with the current element
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex]
        ];
      }

      return array;
    }

    setWorkoutList(shuffle([...WorkoutList]));
  }, []);

  return (
    <Stack
      width="100%"
      maxWidth="1200px"
      paddingRight={{ xs: 0, sm: '24px', lg: 0 }}
      position="relative"
      justifyContent="center">
      <ContentDialog
        open={open}
        setOpen={setOpen}
        dialogContent={dialogContent}
      />
      {/* Arrow Icons */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        width="100%"
        marginX="auto"
        padding={{ xs: '0 24px', lg: 0 }}
        top={{ xs: '30%', lg: '30%' }}
        zIndex={2}>
        <IconButton
          onClick={scrollPrev}
          disabled={!canScrollPrev}
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            backgroundColor: '#2d2b2a',
            opacity: canScrollPrev ? 1 : 0.2,
            '&:hover': { backgroundColor: '#2d2b2a' }
          }}>
          <Box component="img" src="/images/left-arrow-icon.svg" />
        </IconButton>
        <IconButton
          onClick={scrollNext}
          disabled={!canScrollNext}
          sx={{
            width: '40px',
            height: '40px',
            borderRadius: '4px',
            backgroundColor: '#2d2b2a',
            opacity: canScrollNext ? 1 : 0.2,
            '&:hover': { backgroundColor: '#2d2b2a' }
          }}>
          <Box component="img" src="/images/right-arrow-icon.svg" />
        </IconButton>
      </Box>
      {/* Workout Cards */}
      <Box
        ref={emblaRef}
        overflow="hidden"
        width="100%"
        paddingX={{ xs: '24px', lg: 0 }}
        borderRadius="4px"
        maxWidth="1200px">
        <Box display="flex" gap="24px">
          {workoutList.map((item, index) => (
            <WorkoutCard
              key={index}
              {...item}
              open={open}
              setOpen={setOpen}
              setDialogContent={setDialogContent}
              cta={t('Workouts.cta')}
            />
          ))}
        </Box>
      </Box>
    </Stack>
  );
}
