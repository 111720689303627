import React, { useState } from 'react';
import clsx from 'clsx';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import auth from '../../../../utils/auth';

import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormControl
} from '@mui/material';

import { makeStyles } from '@mui/styles';

import EqualizerIcon from '@mui/icons-material/Equalizer';
import { SegmentDialog, ResultDialog } from '../../Legacy/Contents';

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.background.colored.light,
    border: '1px #dddddd solid',
    height: '100%',
    marginBottom: theme.spacing(2)
  },
  infoTitle: {
    fontSize: '18px',
    fontWeight: 700,
    color: '#333333',
    marginTop: 10,
    marginBottom: 10,
    borderRadius: '4px'
  },
  resultDesc: {
    color: '#cccccc',
    margin: '0px',
    fontSize: '12px'
  },
  content: { padding: 0 },
  margin: { margin: 0 },
  textField: {
    width: '100%',
    '& input:disabled': {
      color: '#eeeeee'
    }
  },
  textFieldLast: {
    width: '100%',
    marginBottom: theme.spacing(2)
  },
  inputStyle: {
    [theme.breakpoints.down('md')]: {
      height: '16px !important',
      fontSize: '12px !important',
      padding: '14px 8px 10px 8px !important'
    }
  },
  buttonStyle: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  image: { height: 48, width: 48 },
  actions: { justifyContent: 'flex-end' },
  input: { marginLeft: 8, flex: 1 },
  smallGrid: {
    [theme.breakpoints.down('md')]: {
      padding: '4px !important'
    },
    textAlign: 'center'
  },
  formLabel: {
    '& span': {
      fontSize: '12px'
    }
  },
  iconButton: { padding: 10 },
  divider: {
    background: '#f4f4f4 !important',
    margin: '20px 0 20px 0 !important'
  },
  divider2: {
    background: '#dddddd !important',
    margin: '20px 0 12px 0 !important'
  },
  cardHeader: {
    color: '#90a4ae',
    fontSize: '15px',
    '& span': {
      [theme.breakpoints.down('md')]: {
        fontSize: '12px'
      }
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '13px'
    }
  },
  segmentSelectButton: {
    fontSize: '13px !important',
    padding: '2px 8px 2px 8px !important',
    margin: '6px 8px 0 0 !important'
  },

  bikeBrandSelectButton: {
    '& span': {
      fontSize: '13px !important'
    },
    padding: '0 4px 0 4px !important',
    margin: '6px 4px 0 0 !important'
  },

  powerDeviceSelectButton: {
    '& span': {
      fontSize: '13px !important'
    },
    padding: '0 4px 0 4px !important',
    margin: '6px 4px 0 0 !important'
  },

  box: {
    boxShadow: 'inset 0 0 0 1px #c4c4c4',
    boxSizing: 'border-box',
    borderRadius: 4,
    width: '100%',
    height: '52px',
    padding: '10px 24px 0px 24px',
    marginTop: 0,
    marginBottom: 18,
    '&:last-child': {
      marginTop: '-1px !important',
      marginBottom: 0
    },
    '& span.MuiSlider-root': {
      padding: '10px 0'
    },
    '& span.MuiSlider-markLabel': {
      fontSize: '11px',
      top: '22px'
    }
  }
}));

const SimulationSegment = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const {
    className,
    userInfo,
    rider,
    bike,
    crrVal,
    cda,
    rimHeight,
    tireWidth,
    cadence,
    riderPose,
    roadSurface
  } = props;
  const classes = useStyles();
  const [segments, setSegments] = useState([]);
  const [resultList, setResultList] = useState([]);

  const [course, setCourse] = useState({
    distance: 1.822,
    elevation: 119,
    altitude: 173.9,
    segmentName: t('BikeUI.findSegments'),
    temperature: 20.0
  });

  const [segmentListOpen, setSegmentListOpen] = useState(false);

  const [resultOpen, setResultOpen] = useState(false);
  const [avgPower, setAvgPower] = useState(0);
  const [avgSpeed, setAvgSpeed] = useState(0);
  const [resultSelect, setResultSelect] = useState('speedToPower');

  const handleChangeAvgPower = event => {
    setAvgPower(event.target.value);
  };
  const handleChangeAvgSpeed = event => {
    setAvgSpeed(event.target.value);
  };
  const handleChangeResultSelect = event => {
    setResultSelect(event.target.value);
  };

  const handleClickSegmentListOpen = async () => {
    setSegments(null);
    setSegmentListOpen(true);
  };

  function handleClickSegmentListClose(obj) {
    setSegmentListOpen(false);

    if (typeof obj === 'object') {
      let elevation = (
        Number(obj.elevation_high) - Number(obj.elevation_low)
      ).toFixed(2);
      let altitude = (
        (Number(obj.elevation_high) + Number(obj.elevation_low)) /
        2
      ).toFixed(2);
      let distance = (Number(obj.distance) / 1000).toFixed(2);
      course.distance = distance;
      course.elevation = elevation;
      course.altitude = altitude;
      course.segmentName = obj.name;
    } else if (obj === 0) {
      course.distance = 0;
      course.elevation = 0;
      course.segmentName = t('BikeUI.findSegments');
    }
  }

  const handleChangeCourse = prop => event => {
    setCourse({ ...course, [prop]: event.target.value });
    //    bikeWeightChange(event.target.value);
  };

  const getResult = async () => {
    try {
      if (resultSelect === 'speedToPower') {
        setAvgPower(0);
      } else if (resultSelect === 'powerToSpeed') {
        setAvgSpeed(0);
      } else if (resultSelect === 'estimatePR') {
        setAvgPower(0);
        setAvgSpeed(0);
      } else if (resultSelect === 'estimateCdA') {
      } else {
        setAvgPower(0);
        setAvgSpeed(0);
      }

      let ts = Date.now();

      const auth_str = 'Bearer '.concat(auth.getToken().token);

      return await axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-simulation.php`,
          {
            headers: { Authorization: auth_str },
            params: {
              user: userInfo.id,
              updateConfig: typeof userInfo.bikeKit !== 'undefined' ? 1 : 0,
              time_stamp: ts,
              gender: rider.gender,
              rider_height: rider.height,
              rider_weight: rider.weight,

              bike_type: bike.bikeType,
              bike_weight: bike.bikeWeight,
              powermeter: bike.powermeter,

              drivetrain: bike.drivetrain,
              distance: course.distance,
              elevation: course.elevation,
              altitude: course.altitude,
              temperature: course.temperature,

              crr: crrVal,
              cda: cda,
              rim_height: rimHeight,

              tire_product: bike.tireProduct,
              tire_width: tireWidth,
              cadence: cadence,
              rider_pose: riderPose,
              surface_select: roadSurface,

              avg_power: avgPower,
              avg_speed: avgSpeed,
              //          tire_select : tireSelect,
              result_select: resultSelect
            }
          }
        )
        .then(response => {
          if (response.data) {
            setResultList([response.data]);

            //userinfo refresh
            let userInfo = auth.getUserInfo();
            userInfo.bikeKit = response.data.bikeKit;
            auth.setUserInfo(userInfo, true);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleClickResultOpen = () => {
    if (!rider.height) {
      alert(t('BikeUI.alert1'));
      return false;
    } else if (!rider.weight) {
      alert(t('BikeUI.alert2'));
      return false;
    } else if (!course.distance) {
      alert(t('BikeUI.alert3'));
      return false;
    } else if (!course.elevation) {
      alert(t('BikeUI.alert4'));
      return false;
    } else if (!course.altitude) {
      alert(t('BikeUI.alert5'));
      return false;
    } else if (!course.temperature) {
      alert(t('BikeUI.alert6'));
      return false;
    } else if (!avgSpeed && resultSelect === 'speedToPower') {
      alert(t('BikeUI.alert7'));
      return false;
    } else if (!avgPower && resultSelect === 'powerToSpeed') {
      alert(t('BikeUI.alert8'));
      return false;
    } else if (!isNum(rider.height)) {
      alert(t('BikeUI.alert9'));
      return false;
    } else if (!isNum(rider.weight)) {
      alert(t('BikeUI.alert10'));
      return false;
    } else if (!isNum(course.distance)) {
      alert(t('BikeUI.alert11'));
      return false;
    } else if (!isNum(course.elevation)) {
      alert(t('BikeUI.alert12'));
      return false;
    } else if (!isNum(course.temperature)) {
      alert(t('BikeUI.alert13'));
      return false;
    } else {
      setResultOpen(true);
      getResult();
    }
  };

  function handleClickResultClose(value) {
    setResultOpen(false);
    setResultList([]);
  }

  const isNum = s => {
    s += ''; // 문자열로 변환
    s = s.replace(/^\s*|\s*$/g, ''); // 좌우 공백 제거
    if (s === '' || isNaN(s)) return false;
    return true;
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <CardHeader
        title={t('BikeUI.segmentTab')}
        action={
          <div>
            <Button
              variant="outlined"
              color="secondary"
              onClick={handleClickSegmentListOpen}
              className={classes.segmentSelectButton}>
              {course.segmentName}
            </Button>
            <SegmentDialog
              selectedValue={course.segmentName}
              open={segmentListOpen}
              onClose={handleClickSegmentListClose}
              segments={segments}
            />
          </div>
        }
        style={{ padding: '12px 16px 6px 16px' }}
        classes={{ title: classes.cardHeader }}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item lg={3} md={6} xl={3} sm={6} xs={6}>
            <TextField
              variant="outlined"
              id="outlined-adornment-distance"
              className={clsx(classes.margin, classes.textField)}
              label={t('BikeUI.distance')}
              value={course.distance}
              onChange={handleChangeCourse('distance')}
              InputProps={{
                classes: { input: classes.inputStyle },
                endAdornment: (
                  <InputAdornment position="start">km</InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item lg={3} md={6} xl={3} sm={6} xs={6}>
            <TextField
              variant="outlined"
              id="outlined-adornment-elevation"
              className={clsx(classes.margin, classes.textField)}
              label={t('BikeUI.elevation')}
              value={course.elevation}
              onChange={handleChangeCourse('elevation')}
              InputProps={{
                classes: { input: classes.inputStyle },
                endAdornment: (
                  <InputAdornment position="start">m</InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item lg={3} md={6} xl={3} sm={6} xs={6}>
            <TextField
              variant="outlined"
              id="outlined-adornment-altitude"
              className={clsx(classes.margin, classes.textField)}
              label={t('BikeUI.avgElev')}
              value={course.altitude}
              onChange={handleChangeCourse('altitude')}
              InputProps={{
                classes: { input: classes.inputStyle },
                endAdornment: (
                  <InputAdornment position="start">m</InputAdornment>
                )
              }}
            />
          </Grid>

          <Grid item lg={3} md={6} xl={3} sm={6} xs={6}>
            <TextField
              variant="outlined"
              id="outlined-adornment-temperature"
              className={clsx(classes.margin, classes.textField)}
              label={t('BikeUI.temperature')}
              value={course.temperature}
              onChange={handleChangeCourse('temperature')}
              InputProps={{
                classes: { input: classes.inputStyle },
                endAdornment: (
                  <InputAdornment position="start">°C</InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>

        <Divider className={classes.divider2} />

        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ textAlign: 'center' }}>
          <Grid item lg={12} md={12}>
            <FormControl component="fieldset" className={classes.formControl}>
              <FormLabel component="legend"></FormLabel>
              <RadioGroup
                aria-label="speedPowerRadioButtons"
                value={resultSelect}
                onChange={handleChangeResultSelect}
                row>
                <FormControlLabel
                  className={classes.formLabel}
                  value="speedToPower"
                  control={<Radio color="secondary" />}
                  label={t('BikeUI.toPower')}
                />
                <FormControlLabel
                  className={classes.formLabel}
                  value="powerToSpeed"
                  control={<Radio color="secondary" />}
                  label={t('BikeUI.toVelocity')}
                />
                <FormControlLabel
                  className={classes.formLabel}
                  value="estimatePR"
                  control={<Radio color="secondary" />}
                  label={t('BikeUI.toExpPR')}
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xl={12}
            sm={12}
            xs={12}
            style={{ paddingTop: '0px' }}>
            <Typography
              className={classes.resultDesc}
              hidden={resultSelect === 'speedToPower' ? false : true}>
              {t('BikeUI.toPowerDesc')}
            </Typography>
            <Typography
              className={classes.resultDesc}
              hidden={resultSelect === 'powerToSpeed' ? false : true}>
              {t('BikeUI.toVelocityDesc')}
            </Typography>
            <Typography
              className={classes.resultDesc}
              hidden={resultSelect === 'estimatePR' ? false : true}>
              {t('BikeUI.toExpPRDesc')}
            </Typography>
          </Grid>

          <Grid item lg={3} md={4} xl={3} sm={6} xs={6}>
            <TextField
              variant="outlined"
              id="outlined-adornment-avgSpeed"
              className={clsx(classes.margin, classes.textField)}
              disabled={
                resultSelect !== 'powerToSpeed' && resultSelect !== 'estimatePR'
                  ? false
                  : true
              }
              label={t('BikeUI.avgSpeed')}
              value={avgSpeed}
              onChange={handleChangeAvgSpeed}
              InputProps={{
                classes: { input: classes.inputStyle },
                endAdornment: (
                  <InputAdornment position="start">Km/h</InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item lg={3} md={4} xl={3} sm={6} xs={6}>
            <TextField
              id="outlined-adornment-avgPower"
              className={clsx(classes.margin, classes.textField)}
              disabled={
                resultSelect !== 'speedToPower' && resultSelect !== 'estimatePR'
                  ? false
                  : true
              }
              variant="outlined"
              label={t('BikeUI.avgPower')}
              value={avgPower}
              onChange={handleChangeAvgPower}
              InputProps={{
                classes: { input: classes.inputStyle },
                endAdornment: (
                  <InputAdornment position="start">Watts</InputAdornment>
                )
              }}
            />
          </Grid>
        </Grid>
        <Divider className={classes.divider2} />
        <Grid
          container
          spacing={2}
          justifyContent="center"
          style={{ marginTop: '12px', textAlign: 'center' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClickResultOpen}>
            <EqualizerIcon
              className={clsx(classes.leftIcon, classes.iconSmall)}
            />
            &nbsp;{t('BikeUI.viewResult')}
          </Button>
        </Grid>

        {resultList.length === 0 ? (
          ''
        ) : (
          <ResultDialog
            open={resultOpen}
            onClose={handleClickResultClose}
            resultList={resultList}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default SimulationSegment;
