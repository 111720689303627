import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Avatar from '@mui/material/Avatar';

import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Dialog,
  DialogTitle,
  Divider
} from '@mui/material';

import ImageIcon from '@mui/icons-material/Image';

const useStyles = makeStyles({
  dialog: { marginTop: '5px' }
});

const TireDialog = props => {
  const classes = useStyles();
  const { onClose, selectedValue, open, tireList } = props;

  function handleClose() {
    onClose(selectedValue);
  }

  function handleListItemClick(value) {
    onClose(value);
  }

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialog}>
      <DialogTitle id="simple-dialog-title">타이어를 선택하세요</DialogTitle>
      <List>
        <ListItem
          button
          onClick={() => handleListItemClick(1001)}
          style={{ fontWeight: 'bold' }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Tubular high'} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick(1002)}
          style={{ fontWeight: 'bold' }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Tubular mid'} />
        </ListItem>

        <ListItem
          button
          onClick={() => handleListItemClick(1011)}
          style={{ fontWeight: 'bold' }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Tubeless high'} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick(1012)}
          style={{ fontWeight: 'bold' }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Tubeless mid'} />
        </ListItem>

        <ListItem
          button
          onClick={() => handleListItemClick(1021)}
          style={{ fontWeight: 'bold' }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Clincher high (butyl)'} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick(1022)}
          style={{ fontWeight: 'bold' }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Clincher mid (butyl)'} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick(1023)}
          style={{ fontWeight: 'bold' }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Clincher low (butyl)'} />
        </ListItem>

        <ListItem
          button
          onClick={() => handleListItemClick(1031)}
          style={{ fontWeight: 'bold' }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Clincher high (latex)'} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick(1032)}
          style={{ fontWeight: 'bold' }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Clincher mid (latex)'} />
        </ListItem>
        <ListItem
          button
          onClick={() => handleListItemClick(1033)}
          style={{ fontWeight: 'bold' }}>
          <ListItemAvatar>
            <Avatar>
              <ImageIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary={'Clincher low (latex)'} />
        </ListItem>

        <Divider />

        {tireList.map((tire, i) => (
          <ListItem
            button
            onClick={() => handleListItemClick(tire.tire_id)}
            key={tire.tire_id}
            style={{ fontWeight: 'bold' }}>
            <ListItemAvatar>
              <Avatar>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                tire.brand + ' ' + tire.product + ' (' + tire.width_s + 'c)'
              }
            />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

TireDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
};

export default TireDialog;
