import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router-dom';
import { AppBar, Box, Typography, Tabs, Tab } from '@mui/material';

import auth from '../../utils/auth';
import { useTranslation } from 'react-i18next';
import {
  ProfileOption,
  AccountOption,
  ActivityOption,
  ConnectOption
} from './components';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}>
      <Box p={2}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    spacing: 2,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px'
  },
  itemBox: {
    padding: '8px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    marginBottom: '16px'
  }
}));

const SettingsTab = () => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [strava, setStrava] = React.useState(0);

  const [settingsSubTitle, setSettingsSubTitle] = React.useState('');
  const [refreshTopbar, setRefresh] = React.useState('off');

  const location = useLocation();

  const userInfo = auth.getUserInfo();
  const extraInfo = auth.getExtraInfo();

  const [modalOpen, setModalOpen] = React.useState(false);
  const [snackopen, setSnackopen] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState('');
  const [transition, setTransition] = React.useState(undefined);

  if (!extraInfo) {
    setTimeout(() => {
      window.location = 'https://' + window.location.hostname;
    }, 1000);
  }

  const updateOptions = value => {
    console.log(value + ' ' + refreshTopbar);
    setRefresh(value);
    window.location.reload();
    // auth.clearExtraInfo();
    // auth.getExtraInfo();
  };

  const handleSnackOpen = msg => {
    msg ? setSnackMsg(msg) : setSnackMsg('');
    setSnackopen(true);
  };

  const handleSnackClose = () => {
    setSnackopen(false);
  };

  function handleChange(event, newValue) {
    setValue(newValue);
    if (userInfo.extrainfo) setStrava(userInfo.extrainfo.strava_json);
  }
  //	console.log(auth.getToken());

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.tab}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example">
          <Tab label={t('Options.settingsAccount')} {...a11yProps(0)} />
          <Tab label={t('Options.settingsConnect')} {...a11yProps(1)} />
          {extraInfo.pdc_json ? (
            <Tab label={t('Options.settingsActivity')} {...a11yProps(2)} />
          ) : null}
          {/* (userInfo.ID === 2) ? <Tab label={t('Options.addInfo')} {...a11yProps(3)} />*/}
        </Tabs>
      </AppBar>

      <TabPanel value={value} index={0} className={classes.tabPanel}>
        {/*
			<Profile userInfo={userInfo} extraInfo={extraInfo} auth={auth} />
*/}
        <ProfileOption
          auth={auth}
          extraInfo={extraInfo}
          updateOptions={updateOptions}
          handleSnackOpen={handleSnackOpen}
        />
        <AccountOption
          auth={auth}
          extraIsnfo={extraInfo}
          updateOptions={updateOptions}
          handleSnackOpen={handleSnackOpen}
        />
      </TabPanel>

      <TabPanel value={value} index={1} className={classes.tabPanel}>
        {/*
        <Interlock 
          extraInfo={extraInfo} 
          webhookJson={JSON.parse(extraInfo.webhook_json)} 
          stravaToken={Boolean(extraInfo.strava_token)} 
        />
*/}
        <ConnectOption
          extraInfo={extraInfo}
          webhookJson={JSON.parse(extraInfo.webhook_json)}
          stravaToken={Boolean(extraInfo.strava_token)}
        />
      </TabPanel>

      {/**
       * 스트라마와 연동을 해도 데이터가 없는 경우 extraInfo에 pdc_json 데이터가 존재하지 않는다.
       * 만약에 extraInfo.pdc_json 데이터가 없는 경우 앱이 패널에 오류가 발생한다.
       */}
      {extraInfo.pdc_json ? (
        <TabPanel value={value} index={2} className={classes.tabPanel}>
          <ActivityOption
            auth={auth}
            updateOptions={updateOptions}
            extraInfo={extraInfo}
            ftpType={extraInfo.ftp_type}
            ftp={Math.round(extraInfo.ftp)}
            tte={Math.round(extraInfo.tte)}
            wftp={Math.round(extraInfo.wftp)}
            wtte={Math.round(extraInfo.wtte)}
            maxHr={Math.round(extraInfo.max_hr)}
            customMaxHr={Math.round(extraInfo.custom_max_hr)}
            adjustWeekTss={Math.round(extraInfo.adjust_week_tss)}
            handleSnackOpen={handleSnackOpen}
          />
        </TabPanel>
      ) : null}

      {/*}
			<TabPanel value={value} index={1} className={classes.tabPanel}>
				<AddInfo extraInfo={extraInfo} strava={strava} />
			</TabPanel>
      */}
    </div>
  );
};

export default SettingsTab;
