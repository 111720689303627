import axios from 'axios';
import { transformApiData } from 'utils/transformApiData';

export const fetchAnnouncements = async () => {
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const boardType = 'notice';

  if (!API_BASE_URL) {
    throw new Error('API_BASE_URL is missing');
  }

  try {
    const response = await axios.get(
      `${API_BASE_URL}/api/v1/boards/${boardType}/writes`
    );

    return transformApiData(response.data.writes, boardType);
  } catch (err) {
    console.error('Failed to fetch blogs:', err);
    return null;
  }
};
