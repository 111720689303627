import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import LinearProgress from '@mui/material/LinearProgress';

import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  //	DialogTitle,
  Dialog,
  Divider,
  Paper,
  InputBase,
  IconButton,
  Typography
} from '@mui/material';

import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';

const useStyles = makeStyles({
  search: {
    margin: 10,
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: 'auto'
  },
  input: {
    marginLeft: 4,
    flex: 1
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  },
  dialog: { marginTop: '5px' },
  inline: {
    display: 'inline'
  },
  progress: {
    alignItems: 'center'
  }
});

/*
const gradeAvatar = (grade) => {
	if(grade === "4") return { margin: 0, color: '#fff', backgroundColor: colors.pink[100]};
	else if(grade === "3") return { margin: 0, color: '#fff', backgroundColor: colors.pink[200]};
	else if(grade === "2") return { margin: 0, color: '#fff', backgroundColor: colors.pink[300]};
	else if(grade === "1") return { margin: 0, color: '#fff', backgroundColor: colors.pink[500]};
	else if(grade === "HC") return { margin: 0, color: '#fff', backgroundColor: colors.pink[700]};
}
*/

const BikeCompanyDialog = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const [refresh, setRefresh] = useState(0);
  const [init, setInit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [bikeCompanyList, setBikeCompanyList] = useState(null);
  const [keyword, setKeyword] = useState('');

  function handleClose() {
    onClose(selectedValue);
  }

  function handleListItemClick(obj) {
    onClose(obj);
  }

  const initList = async () => {
    try {
      //https://rest.riduck.com/json-api/get-BikeCompany.php?order=get_BikeCompany_list&climb_category=5
      await axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-bike-company.php`,
          {
            params: {
              order: 'get_bike_company_list'
            }
          }
        )
        .then(response => {
          if (response.data) {
            setRefresh(refresh + 1);
            setBikeCompanyList(response.data.bike_company_list);
            setInit(response.data.bike_company_list);
            setLoading(false);
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const handleBikeCompanyFind = async () => {
    setLoading(true);
    try {
      await axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-bike-company.php`,
          {
            params: {
              order: 'get_bike_company_list',
              name: keyword
              //					climb_category : 5
            }
          }
        )
        .then(response => {
          if (response.data) {
            setRefresh(refresh + 1);
            setBikeCompanyList(response.data.bike_company_list);
            console.log(refresh);
          }
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  function handleEmptyList() {
    console.log(init);
    setBikeCompanyList(init);
    setKeyword('');
  }

  const handleChange = event => {
    setKeyword(event.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleBikeCompanyFind();
    }
  };

  const getBikeCompanyList = bikeCompanyList => {
    if (bikeCompanyList) {
      return (
        <div>
          {bikeCompanyList.map((company, i) => (
            <ListItem
              button
              onClick={() => handleListItemClick(company)}
              key={company.company_id}
              style={{ fontWeight: 'bold' }}>
              <ListItemAvatar>
                <Avatar>{company.climb_category_name}</Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={company.name}
                secondary={
                  <React.Fragment>
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="textPrimary">
                      {company.name_kr + ', ' + company.location}
                    </Typography>
                  </React.Fragment>
                }
              />
            </ListItem>
          ))}
        </div>
      );
    } else {
      initList();
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      className={classes.dialog}>
      <Paper className={classes.search}>
        <IconButton className={classes.iconButton} aria-label="menu" size="large">
          <MenuIcon />
        </IconButton>
        <InputBase
          className={classes.input}
          placeholder={t('BikeUI.findBikeCompany')}
          value={keyword}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          inputProps={{ 'aria-label': t('BikeUI.findBikeCompany') }}
        />
        <IconButton
          className={classes.iconButton}
          onClick={handleBikeCompanyFind}
          aria-label="search"
          size="large">
          <SearchIcon />
        </IconButton>
        <Divider className={classes.divider} orientation="vertical" />
        <IconButton
          color="primary"
          onClick={handleEmptyList}
          className={classes.iconButton}
          aria-label="directions"
          size="large">
          <DirectionsIcon />
        </IconButton>
      </Paper>

      <List>
        <ListItem
          button
          onClick={() => handleListItemClick(0)}
          key={0}
          style={{ fontWeight: 'bold' }}>
          <ListItemText primary="ETC brands" />
        </ListItem>
        {open && getBikeCompanyList(bikeCompanyList)}
      </List>
      {loading && <LinearProgress />}
    </Dialog>
  );
};

BikeCompanyDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired
};

export default BikeCompanyDialog;
