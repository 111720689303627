import * as React from 'react';
import {
  submitPelotonMemberApi,
  quitPelotonMemberApi,
  expelUserApi,
  promoteUserApi,
  acceptJoinMemberApi,
  rejectJoinMemberApi
} from 'utils/clientApis';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider
} from '@mui/material';

/**
 * 
 * @prop : {
 *      label : 페이지 컴포넌트의 핸들러를 통해 넘어오는 라벨입니다. 모달 컴포넌트의 내용을 분기하는 조건이 됩니다.
        openState : 모달의 on/off를 감독하는 boolean State 입니다.
        setOpen : 모달을 닫는 유저플로우를 구현하기 위해 전달된 setState 함수입니다.
        pelotonId : 접속 중인 펠로톤 클럽의 아이디입니다.
        clubName : 접속 중인 펠로톤 클럽의 이름입니다.
        guideText : 회원 가입 신청 시 보여줄 가입 안내 텍스트입니다.
        uriName : 접속 중인 펠로톤 클럽의 주소입니다.
        userData : 클릭 이벤트가 발생한 User의 정보가 들어있는 객체형 State입니다.
 * }
 * 
 */

export default function MemberModal({
  label,
  openState,
  setOpen,
  pelotonId,
  clubName,
  guideText,
  uriName,
  userData
}) {
  const [isRegister, setIsRegister] = React.useState(false);

  /**
   * @return : 모달의 버튼과 연결되어 있는 모든 핸들러 함수.
   */

  // 펠로톤 가입신청 핸들러
  const handleSubmitMember = async () => {
    try {
      const response = await submitPelotonMemberApi(pelotonId);
      response && setIsRegister(true);
      window.location.href = `/peloton/${uriName}`;
    } catch (err) {
      setOpen(false);
      alert('회원 가입 기능에 문제가 생겼습니다.');
      console.error(err);
    }
  };

  // 펠로톤 회원탈퇴 핸들러
  const handleQuitPeloton = async () => {
    try {
      const response = await quitPelotonMemberApi(pelotonId);
      if (response) {
        window.location.href = '/pelotons';
      }
    } catch (err) {
      setOpen(false);
      alert('회원 탈퇴 기능에 문제가 생겼습니다.');
      console.error(err);
    }
  };

  // 펠로톤 회원 권한 수정 핸들러
  const handleUserPromote = async () => {
    try {
      let userRoleForward;

      if (userData.userRole === 'MANAGER') {
        userRoleForward = 'MEMBER';
      } else if (userData.userRole === 'MEMBER') {
        userRoleForward = 'MANAGER';
      }

      if (userRoleForward) {
        const response = await promoteUserApi(
          pelotonId,
          userData.clubUserId,
          userRoleForward
        );

        if (response) {
          // window.location.href = `/peloton/${uriName}`
          window.location.reload();
        }
      }
    } catch (err) {
      setOpen(false);
      alert('등급 변경 기능에 문제가 생겼습니다.');
      console.error(err);
    }
  };

  // 회원 강퇴 핸들러
  const handleExpelUsers = async () => {
    try {
      const response = await expelUserApi(pelotonId, userData.clubUserId);

      if (response) {
        window.location.reload();
      }
    } catch (err) {
      setOpen(false);
      alert('회원 강퇴 기능에 문제가 생겼습니다.');
      console.error(err);
    }
  };

  // 펠로톤 가입신청 승락, 거부 핸들러
  const handleAcceptandReject = async () => {
    try {
      if (label === 'accept') {
        const response = await acceptJoinMemberApi(
          pelotonId,
          userData.clubUserId
        );
        response && window.location.reload();
      } else if (label === 'reject') {
        const response = await rejectJoinMemberApi(
          pelotonId,
          userData.clubUserId
        );
        response && window.location.reload();
      }
    } catch (err) {
      setOpen(false);
      alert('회원 가입 신청 승락 기능에 문제가 생겼습니다.');
      console.error(err);
    }
  };

  return (
    <>
      {openState && (
        <Box>
          {label && label === 'newbie' && (
            <Dialog
              open={openState}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5">
                  가입안내
                </DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="body1"
                    color="textPrimary"
                    style={{ whiteSpace: 'pre-line' }}>
                    {guideText}
                  </DialogContentText>
                  <Divider />
                  <br />
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary">
                    이 펠로톤에서 활동하는 동안 닉네임, FTP (W/Kg), wTSS, 라이딩
                    활동기록이 이 펠로톤의 멤버들에게 공개됩니다.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpen(false)} color="secondary">
                    취소
                  </Button>
                  <Button
                    onClick={handleSubmitMember}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="primary">
                    가입신청
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
          {label && label === 'wait' && (
            <Dialog
              open={openState}
              // onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="body1"
                    color="textPrimary">
                    가입신청이 완료 되었습니다. 펠로톤 관리자의 승인을
                    기다려주세요.
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpen(false)}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="primary">
                    확인
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
          {label && label === 'quit' && (
            <Dialog
              open={openState}
              // onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="body1"
                    color="textPrimary">
                    {`정말로 '${clubName}'에서 탈퇴 하시겠습니까?`}
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleQuitPeloton}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="secondary">
                    탈퇴하기
                  </Button>
                  <Button
                    onClick={() => setOpen(false)}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="primary">
                    취소
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
          {label && label === 'accept' && (
            <Dialog
              open={openState}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="body1"
                    color="textPrimary">
                    {`'${userData.userNickname}'님의 가입을 승인하시겠습니까?`}
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpen(false)}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="secondary">
                    취소
                  </Button>
                  <Button
                    onClick={handleAcceptandReject}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="primary">
                    승인
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
          {label && label === 'reject' && (
            <Dialog
              open={openState}
              // onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="body1"
                    color="textPrimary">
                    {`'${userData.userNickname}'님의 가입을 거절하시겠습니까?`}
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleAcceptandReject}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="secondary">
                    거절
                  </Button>
                  <Button
                    onClick={() => setOpen(false)}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="primary">
                    취소
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
          {label && label === 'promote' && (
            <Dialog
              open={openState}
              // onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
                <DialogContent>
                  {userData.userRole === 'MEMBER' ? (
                    <DialogContentText
                      id="alert-dialog-description"
                      text="body1"
                      color="textPrimary">
                      {`'${userData.userNickname}'님을 관리자로 지정하시겠습니까?`}
                    </DialogContentText>
                  ) : (
                    <DialogContentText
                      id="alert-dialog-description"
                      text="body1"
                      color="textPrimary">
                      {`'${userData.userNickname}'님을 관리자에서 일반 등급으로 변경하시겠습니까?`}
                    </DialogContentText>
                  )}
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => setOpen(false)}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="secondary">
                    취소
                  </Button>
                  <Button
                    onClick={handleUserPromote}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="primary">
                    변경
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
          {label && label === 'expel' && (
            <Dialog
              open={openState}
              // onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="body1"
                    color="textPrimary">
                    {`정말로 '${userData.userNickname}'님을 내보내겠습니까?`}
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleExpelUsers}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="secondary">
                    내보내기
                  </Button>
                  <Button
                    onClick={() => setOpen(false)}
                    size="medium"
                    state="enabled"
                    variant="text"
                    color="primary">
                    취소
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
        </Box>
      )}
    </>
  );
}
