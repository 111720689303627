import React, { memo } from 'react';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

function Logo() {
  return (
    <Link
      to="/"
      onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      style={{ textDecoration: 'none' }}>
      <Box sx={{ cursor: 'pointer', flexShrink: 0 }}>
        <Box
          component="img"
          src="/images/logos/logo.png"
          alt="Logo"
          width="120px"
          margin={0}
        />
      </Box>
    </Link>
  );
}

export default memo(Logo);
