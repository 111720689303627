import React, { useEffect, useRef, useState } from 'react';

import { Box } from '@mui/material';
import ActivityCard from './ActivityCard';

export default function ListSmall({
  rows,
  wkgSelect,
  handleDialogOpen,
  handleChartOpen
}) {
  const CARDS_PER_PAGE = 20;

  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [streamData, setStreamData] = useState(
    rows.slice(0, currentPage * CARDS_PER_PAGE)
  );

  // wkg 값 변경에 대응
  useEffect(() => {
    setStreamData(rows.slice(0, currentPage * CARDS_PER_PAGE));
  }, [wkgSelect]);

  // 무한스크롤
  const intersectionObserver = useRef(null);

  const handleFetchStream = () => {
    if (rows.length <= (currentPage + 1) * CARDS_PER_PAGE) {
      setHasNextPage(false);
    }
    setStreamData(rows.slice(0, (currentPage + 1) * CARDS_PER_PAGE));
    setCurrentPage(prev => prev + 1);
    setIsFetching(false);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsFetching(true);
      }
    });

    if (intersectionObserver.current) {
      observer.observe(intersectionObserver.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [intersectionObserver.current]);

  useEffect(() => {
    if (isFetching && hasNextPage) handleFetchStream();
    else if (!hasNextPage) setIsFetching(false);
  }, [isFetching, hasNextPage]);

  return (
    <Box>
      {streamData.map((row, index) => {
        return (
          <ActivityCard
            key={row.activity_id}
            index={index}
            id={row.activity_id}
            name={row.activity_name}
            description={row.activity_desc}
            rideData={row.ride_data}
            averageWatts={row.average_watts}
            distance={row.distance}
            movingTime={row.moving_time}
            tssText={row.tss_text}
            url={row.activity_url}
            handleChartOpen={handleChartOpen}
            handleDialogOpen={handleDialogOpen}
          />
        );
      })}
      <div ref={intersectionObserver}></div>
    </Box>
  );
}
