import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import BurgerCloseIcon from 'components/Header/MobileMenu/BurgerCloseIcon';
import { DynamicFontTypography } from 'components/Typography';

export default function ContentDialog({ open, setOpen, dialogContent }) {
  const { title, content, url } = dialogContent;
  const { t } = useTranslation('landing');

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 99 }}
      open={open}
      onClick={handleClose}>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <DynamicFontTypography>{title}</DynamicFontTypography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#9e9e9e'
          }}>
          <BurgerCloseIcon />
        </IconButton>
        <DialogContent dividers>
          <DynamicFontTypography
            sx={{
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '24px',
              whiteSpace: 'pre-line'
            }}>
            {content}
          </DynamicFontTypography>
        </DialogContent>
        <DialogActions>
          <Button
            component="a"
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            autoFocus
            onClick={handleClose}
            sx={{
              textDecoration: 'none'
            }}>
            <DynamicFontTypography
              variant="mobilemenu"
              sx={{
                '&:hover': {
                  color: ' #009688'
                }
              }}>
              {t('Buttons.readMore')}
            </DynamicFontTypography>
          </Button>
        </DialogActions>
      </Dialog>
    </Backdrop>
  );
}
