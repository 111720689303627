import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { withStyles, makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Paper,
  Slider,
  Tabs,
  Tab,
  IconButton
} from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BackspaceIcon from '@mui/icons-material/Backspace';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import optionPowerCurve from './Options/OptionPowerCurve.js';

import ApexChart from 'react-apexcharts';
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles(theme => ({
  root: {},
  floatChip: {
    position: 'absolute',
    zIndex: '1',
    right: 0,
    opacity: '0.9',
    margin: '12px 10px 0 0',
    padding: '4px 8px 4px 8px',
    fontSize: '12px',
    fontWeight: 400,
    height: '24px',
    [theme.breakpoints.down('md')]: {
      margin: '12px 10px 0 0'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '12px 10px 0 0'
    }
  },
  activityDescBox: {
    width: '240px',
    height: '150px',
    background: '#f5f5f5',
    border: '2px #eeeeee solid',
    position: 'absolute',
    zIndex: '1',
    right: 0,
    opacity: '0.9',
    margin: '48px 11px 0 0',
    padding: '4px 8px',
    fontSize: '12px',
    whiteSpace: 'pre-line',
    overflowY: 'auto',
    borderRadius: '6px',
    [theme.breakpoints.down('md')]: {
      width: '33%',
      height: '135px',
      margin: '40px 10px 0 0',
      fontSize: '11px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '60%',
      height: '102px',
      fontSize: '11px',
      margin: '40px 10px 0 0'
    },
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f5f5f5',
      borderRadius: '0 4px 4px 0'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#4db6ac',
      borderRadius: '3px',
      border: 'none'
    }
  },
  descTitle: {
    fontSize: '12px',
    fontWeight: 500,
    marginTop: '10px',
    textAlign: 'center'
  },
  chartContainer: {
    height: 436,
    overflow: 'visible',
    margin: '0px -2px 0px 0px',
    padding: '10px 0 24px 0',
    [theme.breakpoints.down('xl')]: {
      height: 368
    },
    [theme.breakpoints.down('lg')]: {
      height: 276
    },
    [theme.breakpoints.down('sm')]: {
      height: 256
    }
  },
  pdGuideBlock: {
    position: 'relative',
    zIndex: 10,
    width: 'calc(100% + 16px)',
    flexGrow: 1,
    height: '36px',
    margin: '0px 0 -12px -2px'
  },
  pdLeftButton: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
    padding: '4px'
  },
  pdGuideButton: {
    width: 'calc(100% - 58px)',
    boxSizing: 'border-box',
    fontSize: '15px',
    color: '#455a64',
    background: '#f5f5f5',
    opacity: '0.9',
    padding: '4px 0 6px 0',
    textAlign: 'center',
    borderRadius: '6px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      borderRadius: '4px',
      height: '32px',
      padding: '4px 0 6px 0'
    }
  },
  pdGuideBold: {
    fontSize: '15px',
    margin: '0 16px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 12px 0 3px'
    }
  },
  chartButton: {
    marginTop: '-6px',
    textTransform: 'none',
    padding: '0px',
    color: '#90a4ae'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tooltip: {
    fontSize: '13px',
    color: '#ffffff',
    padding: '8px 16px',
    backgroundColor: '#333333',
    borderRadius: '3px',
    opacity: '0.8'
  },
  tabs: {
    width: '300px',
    border: '1px #dddddd solid',
    borderRadius: '4px 4px 0 0'
  },
  tabContent: {
    width: '300px',
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px'
  },
  listItem: {
    padding: '2px 12px'
  },
  tabTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  pdText: {
    marginBottom: '12px',
    lineHeight: '24px',
    textAlign: 'center',
    fontSize: '13px'
  },
  pdTextBold: {
    fontSize: '15px',
    margin: '0 4px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 3px 0 3px'
    }
  },
  pdTextBox: {
    margin: '0px auto',
    background: '#f5f5f5',
    lineHeight: '24px',
    padding: '16px',
    borderRadius: '4px'
  },
  pdDesc: {
    margin: '0px 10px 8px 10px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left'
  },
  pdDescItem: {
    margin: '0px 16px 4px 16px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'center'
  },
  xaxisLabel: {
    color: '#888888',
    fontSize: '11px'
  }
}));

const PdSlider = withStyles({
  root: {
    color: '#8cc1ec',
    height: 12,
    padding: '16px 0'
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

const PowerCurveChart = props => {
  //다국어적용
  const { t } = useTranslation(['page']);

  const {
    pdcData,
    ftp,
    wPrime,
    forte,
    yScaleMax,
    activityName,
    activityDesc,
    wkg,
    weight,
    handlePeakActivity
  } = props;
  const [activityId, setActivityId] = useState(props.activityId);
  const [pcChartOptions, setPcChartOptions] = useState({});
  const [pcChartSeries, setPcChartSeries] = useState([]);
  const [pdGuideDialogOpen, setPdGuideDialogOpen] = React.useState(false);

  let exclude_arr = Array();
  let dst_arr = Array();
  let tss_arr = Array();
  let if_arr = Array();
  let desc_arr = Array();

  const stereoCurve = pdcData['best_data']['stereo_all'];
  const outdoorMatch = pdcData['best_data']['outdoor_match'];

  useEffect(() => {
    function PowerCurveDraw() {
      let pdc_array = Array();
      let pdOptionsObj = cloneDeep(optionPowerCurve);

      _.each(pdcData, function(item, i) {
        if (i == 'best_data') {
          //최대파워 필드파워는 기본 푸시
          let outdoorData = {
            name: t('Dashboard.outdoor'),
            type: 'area',
            data: wkg ? item.outdoor_wkg : item.outdoor_power,
            dataLabels: {
              enabled: false
            }
          };
          let peakData = {
            name: t('Dashboard.peak'),
            type: 'area',
            data: wkg ? item.wkg : item.power,
            dataLabels: {
              enabled: false
            }
          };

          if (wkg) {
            let wkgScale = Math.round((yScaleMax / weight + 1.0) * 10) / 10;

            pdOptionsObj.init.yaxis[1].max = wkgScale;
            pdOptionsObj.init.yaxis[2].max = wkgScale;
            pdOptionsObj.init.yaxis[3].max = wkgScale;

            pdOptionsObj.init.yaxis[1].tickAmount = wkgScale;
            pdOptionsObj.init.yaxis[2].tickAmount = wkgScale;
            pdOptionsObj.init.yaxis[3].tickAmount = wkgScale;

            pdOptionsObj.init.annotations.yaxis[0].y =
              Math.round((ftp / weight) * 100) / 100;
            pdOptionsObj.init.annotations.yaxis[0].label.text =
              'FTP ' + Math.round((ftp / weight) * 100) / 100;
            pdOptionsObj.init.annotations.xaxis[0].x = forte;
          } else {
            pdOptionsObj.init.yaxis[1].max = yScaleMax;
            pdOptionsObj.init.yaxis[2].max = yScaleMax;
            pdOptionsObj.init.yaxis[3].max = yScaleMax;

            pdOptionsObj.init.yaxis[1].tickAmount = yScaleMax / 100;
            pdOptionsObj.init.yaxis[2].tickAmount = yScaleMax / 100;
            pdOptionsObj.init.yaxis[3].tickAmount = yScaleMax / 100;

            pdOptionsObj.init.annotations.yaxis[0].y = ftp;
            pdOptionsObj.init.annotations.yaxis[0].label.text = 'FTP ' + ftp;
            pdOptionsObj.init.annotations.xaxis[0].x = forte;
          }

          if (window.innerWidth < 400) {
            pdOptionsObj.init.yaxis[0].show = false;
            pdOptionsObj.init.yaxis[1].labels.offsetX = -11;
          }

          //클릭시 최대파워 찍은 활동으로 이동
          const clickCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

          pdOptionsObj.init.chart.events.markerClick = function(
            event,
            chartContext,
            { seriesIndex, dataPointIndex, config }
          ) {
            let aid = Number(item.peak_activities[dataPointIndex]);

            setTimeout(function() {
              clickCount[dataPointIndex] = 0;
            }, 400);

            clickCount[dataPointIndex]++;

            if (clickCount[dataPointIndex] % 2 === 0) {
              handlePeakActivity(aid);
              clickCount[dataPointIndex] = 0;
            }
          };

          pdc_array.unshift(peakData);

          if (activityId == 0) {
            let perData = {
              name: t('Dashboard.ability'),
              type: 'area',
              data: wkg ? item.wkg_per : item.power_per,
              dataLabels: {
                enabled: true,
                offsetY: -6,
                formatter: function(val) {
                  return 100 - val + '%';
                }
              }
            };

            let stereoData = {
              name: t('Dashboard.expect'),
              type: 'area',
              data: wkg ? item.stereo_wkg : item.stereo_power,
              dataLabels: {
                enabled: false
              }
            };

            pdc_array.unshift(outdoorData);
            pdc_array.unshift(stereoData);
            pdc_array.unshift(perData);

            setPcChartSeries(pdc_array);
            setPcChartOptions(pdOptionsObj.init);

            return false;
          }
        } else if (i == activityId) {
          if (wkg) {
            let wkgScale = Math.round((yScaleMax / weight + 1.0) * 10) / 10;

            pdOptionsObj.activity.yaxis[0].max = wkgScale;
            pdOptionsObj.activity.yaxis[1].max = wkgScale;
            pdOptionsObj.activity.yaxis[0].tickAmount = wkgScale;
            pdOptionsObj.activity.yaxis[1].tickAmount = wkgScale;

            pdOptionsObj.activity.annotations.yaxis[0].y =
              Math.round((ftp / weight) * 100) / 100;
            pdOptionsObj.activity.annotations.yaxis[0].label.text =
              'FTP ' + Math.round((ftp / weight) * 100) / 100;
          } else {
            pdOptionsObj.activity.yaxis[0].max = yScaleMax;
            pdOptionsObj.activity.yaxis[1].max = yScaleMax;
            pdOptionsObj.activity.yaxis[0].tickAmount = yScaleMax / 100;
            pdOptionsObj.activity.yaxis[1].tickAmount = yScaleMax / 100;

            pdOptionsObj.activity.annotations.yaxis[0].y = ftp;
            pdOptionsObj.activity.annotations.yaxis[0].label.text =
              'FTP ' + ftp;
          }

          let pdData = {
            name: activityName,
            type: 'area',
            data: wkg ? item.wkg : item.power,
            dataLabels: {
              enabled: true,
              offsetY: -6,
              formatter: function(val) {
                if (val > 50) {
                  return val + 'w';
                } else {
                  return val + 'w/kg';
                }
              }
            }
          };
          pdc_array.push(pdData);

          setPcChartSeries(pdc_array);
          setPcChartOptions(pdOptionsObj.activity);
        }
      });
    }

    PowerCurveDraw();
  }, [activityId]);

  const classes = useStyles();

  function handlePdGuideDialogOpen() {
    setPdGuideDialogOpen(true);
  }

  function handlePdGuideDialogClose() {
    setPdGuideDialogOpen(false);
  }

  function InOutMatch(props) {
    const outdoorMatch = props.outdoorMatch;

    if (outdoorMatch.power_match !== 0) {
      return (
        <React.Fragment>
          {'📐 ' + t('Dashboard.powerMatch')}
          <Typography
            className={classes.pdGuideBold}
            style={{ marginRight: 0 }}
            component="span">
            {Math.round(outdoorMatch.match_def * 100)}%{' '}
          </Typography>
        </React.Fragment>
      );
    } else if (outdoorMatch.power_match === 0) {
      return (
        <React.Fragment>{'🔌 ' + t('Dashboard.plugPower')}</React.Fragment>
      );
    } else {
      return (
        <React.Fragment>{'🔌 ' + t('Dashboard.plugPower')}</React.Fragment>
      );
    }
  }

  function PdGuideDialogContent(props) {
    const pdGuides = null;
    const [pdGuideTab, setPdGuideTab] = React.useState(0);
    const [duration, setDuration] = React.useState(60);

    const handleDurationChange = (event, newDuration) => {
      setDuration(newDuration);
    };

    const handlePdGuideTab = (event, newIndex) => {
      setPdGuideTab(newIndex);
    };

    function PeakPowerCalc(props) {
      const duration = props.duration;
      const secArr = [
        1,
        5,
        15,
        30,
        60,
        120,
        240,
        300,
        480,
        600,
        720,
        900,
        1200,
        1800,
        2400,
        3600,
        7200,
        14400
      ];
      let stereoArr = props.stereoCurve;

      stereoArr[7200] = Math.round(
        (stereoArr[3600] / stereoArr[1200]) * stereoArr[3600]
      );
      stereoArr[14400] = Math.round(
        (stereoArr[3600] / stereoArr[1200]) * stereoArr[7200]
      );

      let preSec = 0;
      let nextSec = 0;

      for (let i = 1; i < 18; i++) {
        if (duration * 60 >= secArr[i - 1] && duration * 60 <= secArr[i]) {
          preSec = secArr[i - 1];
          nextSec = secArr[i];
          break;
        }
      }

      let rate = (duration * 60 - preSec) / (nextSec - preSec);
      let power =
        stereoArr[preSec] + (stereoArr[nextSec] - stereoArr[preSec]) * rate;

      return (
        <Box>
          <div>
            {'⏱️ ' + t('Dashboard.expectDuration')}{' '}
            <Typography className={classes.pdTextBold} component="span">
              {secondsToHms(duration * 60)}
            </Typography>
          </div>
          <div>
            {'⚡ ' + t('Dashboard.expectPower')}{' '}
            <Typography className={classes.pdTextBold} component="span">
              {Math.round(power)}
            </Typography>
            {'W (' + Math.round((100 * power) / weight) / 100 + ' w/kg)'}
          </div>
          <div style={{ fontSize: '13px', color: '#83ccc5' }}>
            (FTP {Math.round((100 * power) / ftp)}%)
          </div>
        </Box>
      );
    }

    return (
      <React.Fragment>
        <Box>
          <Paper elevation={0} className={classes.tabs}>
            <Tabs
              value={pdGuideTab}
              onChange={handlePdGuideTab}
              indicatorColor="primary"
              textColor="primary"
              centered>
              <Tab label={t('Dashboard.expectPower')} />
              <Tab label={t('Dashboard.powerMatch')} />
            </Tabs>
          </Paper>

          {pdGuideTab === 1 ? (
            <Box className={classes.tabContent}>
              <Typography className={classes.tabTitle} component="div">
                {t('Dashboard.powerMatchTitle')}
              </Typography>
              <Box className={classes.pdText} style={{ padding: '0 16px' }}>
                <Typography className={classes.pdTextBox} component="div">
                  <div>
                    <InOutMatch outdoorMatch={outdoorMatch} />
                  </div>
                  {outdoorMatch.match_def < 1.0 ? (
                    <div style={{ fontSize: '12px', color: '#73838b' }}>
                      {outdoorMatch.match_def < 0.086
                        ? t('Dashboard.powerErrIn')
                        : t('Dashboard.powerErrOut')}
                    </div>
                  ) : (
                    <div style={{ fontSize: '12px', color: '#aaaaaa' }}>
                      {t('Dashboard.noOutdoor')}
                    </div>
                  )}
                </Typography>
              </Box>
              <Divider />
              <Box>
                <Typography className={classes.tabTitle} component="div">
                  {t('Dashboard.multiPowerDevices')}
                </Typography>
                <Typography className={classes.pdDesc} component="div">
                  {t('Dashboard.deviceErrDesc1')}
                </Typography>
                <Typography
                  className={classes.pdDesc}
                  component="div"
                  style={{ textDecoration: 'underline' }}>
                  {t('Dashboard.deviceErrDesc2')}
                </Typography>
                <Typography
                  className={classes.pdDescItem}
                  style={{ marginBottom: '12px' }}
                  component="div">
                  {t('Dashboard.deviceErrDefault')}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box className={classes.tabContent}>
              <Typography className={classes.tabTitle} component="div">
                {t('Dashboard.expectPowerTitle')}
              </Typography>
              <Box className={classes.pdText} style={{ padding: '0 16px' }}>
                <PdSlider
                  color="secondary"
                  value={duration}
                  min={1}
                  step={1}
                  max={240}
                  scale={x => x * 1}
                  onChange={handleDurationChange}
                  valueLabelDisplay="off"
                />
                <PeakPowerCalc duration={duration} stereoCurve={stereoCurve} />
              </Box>
              <Divider />
              <Box>
                <Typography className={classes.tabTitle} component="div">
                  {t('Dashboard.expectPowerUseCase')}
                </Typography>
                <Typography className={classes.pdDesc} component="div">
                  {t('Dashboard.expectPowerUseCaseDesc1')}
                </Typography>
                <Typography className={classes.pdDesc} component="div">
                  {t('Dashboard.expectPowerUseCaseDesc2')}
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </React.Fragment>
    );
  }

  function PowerCurveGuide() {
    const classes = useStyles();

    ////#ffd357 노
    //f491b2 빨
    //8cc1ec 파랑
    //4db6ac 녹
    //						onClick={handleZoneGuideDialogOpen}
    //⛔ 실외기록 없음
    //🚫 데이터 불일치
    //✔️ 검증된 데이터

    if (activityId > 0) {
      return (
        <div className={classes.pdGuideBlock}>
          <IconButton
            className={classes.pdLeftButton}
            onClick={() => {
              setActivityId(0);
            }}
            size="large">
            <BackspaceIcon />
          </IconButton>
          <Button variant="contained" className={classes.pdGuideButton}>
            {'📝 ' + activityName}
          </Button>
        </div>
      );
    } else {
      return (
        <div className={classes.pdGuideBlock}>
          <IconButton className={classes.pdLeftButton} size="large">
            <InfoOutlinedIcon />
          </IconButton>
          <Button
            onClick={handlePdGuideDialogOpen}
            variant="contained"
            color="inherit"
            className={classes.pdGuideButton}
            display="block">
            ⚡ FTP{' '}
            <Typography className={classes.pdGuideBold} component="span">
              {ftp}
            </Typography>
            🔋 AWC{' '}
            <Typography className={classes.pdGuideBold} component="span">
              {Math.round(wPrime / 100) / 10}
            </Typography>
            <Box display={{ xs: 'none', sm: 'inline' }}>
              <InOutMatch outdoorMatch={outdoorMatch} />
            </Box>
          </Button>
        </div>
      );
    }
  }

  return (
    <React.Fragment>
      <Box className={classes.chartContainer}>
        <PowerCurveGuide activityId={activityId} />
        <ApexChart
          options={pcChartOptions}
          series={pcChartSeries}
          height={'100%'}
          style={{ margin: '0px -6px 0 -20px' }}
        />
      </Box>

      <Dialog open={pdGuideDialogOpen} onClose={handlePdGuideDialogClose}>
        <DialogTitle>{t('Dashboard.powerProfile')}</DialogTitle>
        <Divider />
        <DialogContent style={{ padding: '8px' }}>
          <PdGuideDialogContent />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + t('Common.hour') + ' ' : '';
      let md = m > 0 ? m + t('Common.min') : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + t('Common.min') + ' ' : '';
      let sd = s > 0 ? s + t('Common.sec') : '';

      return md + sd;
    }
  }

  function secondsToHmsLabel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '';
    } else if (h > 0) {
      let hd = h > 0 ? h + ':' : '00';
      let md = m > 0 ? m : '00';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + ':' : '00';
      let sd = s > 0 ? s : '00';

      return md + sd;
    }
  }
};

PowerCurveChart.propTypes = {
  className: PropTypes.string
};

export default withRouter(PowerCurveChart);
