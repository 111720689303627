import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Avatar,
  Box,
  Divider,
  MenuItem,
  Paper,
  TextField,
  Typography
} from '@mui/material';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const useStyles = makeStyles(theme => ({
  tabPanel: {
    padding: '16px',
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px'
    }
  },
  descTitle: {
    marginBottom: '8px',
    fontWeight: 600,
    fontSize: '14px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px'
    }
  },
  desc: {
    margin: '8px 0',
    fontSize: '14px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  },
  desc2: {
    display: 'list-item',
    fontSize: '14px',
    margin: '8px 0 8px 20px',
    [theme.breakpoints.down('xl')]: {
      fontSize: '12px',
      lineHeight: '16px'
    }
  },
  guideFtp: {
    float: 'left',
    height: '30px',
    lineHeight: '30px',
    margin: '8px 0 12px 8px',
    fontSize: '16px',
    color: '#999999',
    fontWeight: 800,
    [theme.breakpoints.down('lg')]: {
      margin: '4px 0 8px 4px',
      fontSize: '14px'
    }
  },
  textField: {
    fontSize: '16px',
    float: 'right',
    height: '30px',
    margin: '8px 0 12px 8px',
    [theme.breakpoints.down('lg')]: {
      margin: '4px 0 8px 4px',
      fontSize: '14px'
    }
  },
  tableHeader: {
    fontSize: '14px',
    padding: '8px 16px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '12px',
      padding: '4px 8px'
    }
  },
  tableCellPowerzone: {
    width: 'clamp(160px, 10vw, 200px)',
    [theme.breakpoints.down('md')]: {
      padding: '16px 0px 16px 8px'
    }
  },
  tableCellPowerzoneChar: {
    display: 'inline-block',
    width: 30
  },
  tableCellPowerzoneDesc: {
    fontSize: '12px',
    wordBreak: 'keep-all',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px'
    }
  },
  tableCellAchievements: {
    width: 'clamp(220px, 40vw, 400px)',
    padding: '16px 60px 16px 0',
    [theme.breakpoints.down('md')]: {
      paddingRight: '40px'
    }
  },
  tableCellGraph: {
    position: 'relative',
    height: '40px'
  },
  tableCellGraphText: {
    position: 'absolute',
    width: 'calc(100% + 40px)',
    lineHeight: '40px'
  },
  tableCellGraphTextPercent: {
    float: 'left',
    paddingLeft: '12px',
    color: '#455a64'
  },
  tableCellGraphTextWatt: {
    float: 'right',
    paddingRight: '20px',
    fontSize: '12px',
    color: '#455a64',
    [theme.breakpoints.down('md')]: {
      fontSize: '10px'
    }
  },
  tableCellWeekText: {
    fontSize: '14px',
    width: '0px'
  },
  tableCellWeekTextDesc: {
    width: 'clamp(150px, 20vw, 200px)'
  },
  tableCellWeekStatusDesc: {
    fontSize: '14px',
    width: 'clamp(150px, 40vw, 500px)',
    wordBreak: 'keep-all'
  },
  tableCellWeekDownText: {
    margin: '10px auto',
    fontWeight: 600
  },
  tableCellWeekStatusDownDesc: {
    fontSize: '14px',
    margin: '10px auto'
  },
  tableTextDesc: {
    whiteSpace: 'pre'
  },
  z1: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#aaaaaa',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z2: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#76b5e8',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z3: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#b0d36d',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z4: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#ffd357',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z5: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#ffa75c',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z6: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#ff7e75',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },
  z7: {
    opacity: 0.8,
    border: '2px #ddd solid',
    backgroundColor: '#f06292',
    color: '#ffffff',
    fontSize: '11px',
    width: '25px',
    height: '25px'
  },

  z0Bar: {
    width: '100%',
    borderRadius: '20px',
    backgroundColor: '#eeeeee',
    color: '#ffffff',
    height: '40px',
    fontSize: '12px',
    position: 'absolute'
  },
  z1Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#aaaaaa',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z2Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#76b5e8',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z3Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#b0d36d',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z4Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#ffd357',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z5Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#ffa75c',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z6Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#ff7e75',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  },
  z7Bar: {
    width: '100%',
    minWidth: '20px',
    borderRadius: '16px',
    backgroundColor: '#f06292',
    color: '#ffffff',
    height: '32px',
    fontSize: '12px',
    position: 'absolute',
    top: '4px',
    left: '4px'
  }
}));

const PowerZone = props => {
  const { activityData, pdcData, powerzoneData, ftpArray, wkg, weight } = props;
  const classes = useStyles();

  const ftp = ftpArray.wftp;

  const [zoneFtp, setZoneFtp] = React.useState(ftp);
  // 존별 평균 시간
  const [zoneAvg, setZoneAvg] = React.useState([]);
  const [zoneAvgWeeks, setZoneAvgWeeks] = React.useState([]);
  const [weekId, setWeekId] = React.useState(-1);
  const [
    previousWeekComparisonDescription,
    setPreviousWeekComparisonDescription
  ] = React.useState([]);

  //다국어적용
  const { t } = useTranslation(['page']);
  const language = i18n.language;

  // 설명 토글
  const [isDescOpen, setIsDescOpen] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]);

  const weeksTypeRange = () => {
    let range = [];
    range.push(
      <MenuItem key={-1} value={-1}>
        {getWeekName(-1)}
      </MenuItem>
    );
    _.each(powerzoneData.powerzone_weeks, function(item, id) {
      range.push(
        <MenuItem key={id} value={id}>
          {getWeekName(Number(id))}
        </MenuItem>
      );
    });
    return range;
  };

  function getWeekName(weekId) {
    let weekName = '';

    if (weekId > -1) {
      if (weekId === 0) {
        weekName = '📅 ' + t('Common.thisWeek');
      } else {
        weekName = '📅 ' + weekId + t('Common.weeksAgo');
      }
      weekName += ' (' + getMonToSun(weekId) + ')';
    } else {
      weekName = '📅 ' + t('Common.all');
      weekName += ' (' + getMonToToday(11) + ')';
    }

    return weekName;
  }

  function getMonToSun(weekAgo) {
    let d = new Date();
    let dayOfMonth = d.getDate();
    d.setDate(dayOfMonth - 7 * weekAgo);

    let currentDay = d;
    let theYear = currentDay.getFullYear();
    let theMonth = currentDay.getMonth();
    let theDate = currentDay.getDate();
    let theDayOfWeek = currentDay.getDay();

    let thisWeek = [];

    for (let i = 1; i < 8; i++) {
      let resultDay = new Date(theYear, theMonth, theDate + (i - theDayOfWeek));
      let yyyy = resultDay.getFullYear();
      let mm = Number(resultDay.getMonth()) + 1;
      let dd = resultDay.getDate();

      mm = String(mm).length === 1 ? '0' + mm : mm;
      dd = String(dd).length === 1 ? '0' + dd : dd;

      thisWeek[i - 1] = mm + '/' + dd;
    }

    return thisWeek[0] + ' ~ ' + thisWeek[6];
  }

  function getMonToToday(weekAgo) {
    let d = new Date();
    let dayOfMonth = d.getDate();
    d.setDate(dayOfMonth - 7 * weekAgo);

    let currentDay = d;
    let theYear = currentDay.getFullYear();
    let theMonth = currentDay.getMonth();
    let theDate = currentDay.getDate();
    let theDayOfWeek = currentDay.getDay();

    let resultDay = new Date(theYear, theMonth, theDate + (1 - theDayOfWeek));
    let mm = Number(resultDay.getMonth()) + 1;
    let dd = resultDay.getDate();

    mm = String(mm).length === 1 ? '0' + mm : mm;
    dd = String(dd).length === 1 ? '0' + dd : dd;

    let td = new Date();

    let mm2 = Number(td.getMonth()) + 1;
    let dd2 = td.getDate();

    mm2 = String(mm2).length === 1 ? '0' + mm2 : mm2;
    dd2 = String(dd2).length === 1 ? '0' + dd2 : dd2;

    return mm + '/' + dd + ' ~ ' + (mm2 + '/' + dd2);
  }

  function getDateYmd(date) {
    if (language === 'en') {
      return (
        date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear()
      );
    } else {
      return (
        date.getFullYear() + '/' + (date.getMonth() + 1) + '/' + date.getDate()
      );
    }
  }

  // 창 토글
  function toggleDesc(number) {
    let newIsdescOpenArray = [...isDescOpen];
    newIsdescOpenArray.splice(number, 1, !isDescOpen[number]);
    setIsDescOpen(newIsdescOpenArray);
  }

  useEffect(() => {
    // 주별 파워존 설정
    let newZoneAvgWeeks = [];
    let i = 0;
    while (true) {
      if (!!!powerzoneData.powerzone_weeks[Number(i)]) {
        break;
      }
      newZoneAvgWeeks.push(
        _.reverse(powerzoneData.powerzone_weeks[Number(i)].per)
      );
      i++;
    }
    setZoneAvgWeeks(newZoneAvgWeeks);
  }, []);

  //
  useEffect(() => {
    if (weekId > -1) {
      setZoneAvg(zoneAvgWeeks[Number(weekId)]);
      // 이전주 대비 증감률 설명
      let newPreviousWeekComparisonDescription = [];
      if (!!zoneAvgWeeks[Number(weekId) + 1]) {
        for (let i = 0; i < 7; i++) {
          let percent,
            lastWeekPercent,
            thisWeekPercent = 0;
          thisWeekPercent = zoneAvgWeeks[Number(weekId)][i];
          lastWeekPercent = zoneAvgWeeks[Number(weekId) + 1][i];

          // percent = Math.round((thisWeekPercent - lastWeekPercent) / lastWeekPercent * 100);
          percent = Math.round(thisWeekPercent - lastWeekPercent);

          newPreviousWeekComparisonDescription.push(
            t('Common.lastWeek') +
              ' (' +
              getMonToSun(Number(weekId) + 1) +
              ') ' +
              t('Common.compared') +
              '\n' +
              percent +
              '%'
          );
        }
      }

      setPreviousWeekComparisonDescription(
        newPreviousWeekComparisonDescription
      );
    } else {
      const zoneAvg = powerzoneData.powerzone_avg.zone_avg;
      const maxValue = Math.max(...zoneAvg);

      const percentageZoneAvg = zoneAvg.map(value =>
        maxValue === 0 ? 0 : Math.round((value / maxValue) * 100)
      );

      setZoneAvg(percentageZoneAvg);
      setPreviousWeekComparisonDescription([]);
    }
  }, [weekId]);

  const ZoneRow = props => {
    let number = props.number;
    let graphTextWatt = '';
    switch (number) {
      case 1:
        graphTextWatt = Math.round(zoneFtp * 0.55) + ' W ' + t('Common.under');
        break;
      case 2:
        graphTextWatt =
          Math.round(zoneFtp * 0.55) +
          1 +
          ' ~ ' +
          Math.round(zoneFtp * 0.75) +
          ' W';
        break;
      case 3:
        graphTextWatt =
          Math.round(zoneFtp * 0.75) +
          1 +
          ' ~ ' +
          Math.round(zoneFtp * 0.9) +
          ' W';
        break;
      case 4:
        graphTextWatt =
          Math.round(zoneFtp * 0.9) +
          1 +
          ' ~ ' +
          Math.round(zoneFtp * 1.05) +
          ' W';
        break;
      case 5:
        graphTextWatt =
          Math.round(zoneFtp * 1.05) +
          1 +
          ' ~ ' +
          Math.round(zoneFtp * 1.2) +
          ' W';
        break;
      case 6:
        graphTextWatt =
          Math.round(zoneFtp * 1.2) +
          1 +
          ' ~ ' +
          Math.round(zoneFtp * 1.5) +
          ' W';
        break;
      case 7:
        graphTextWatt =
          Math.round(zoneFtp * 1.5) + 1 + ' W ' + t('Common.over');
        break;
    }

    return (
      <React.Fragment>
        <TableRow>
          <TableCell align="left" className={classes.tableCellPowerzone}>
            <div className={classes.tableCellPowerzoneChar}>
              <Avatar className={eval(`classes.z${number}`)}>Z{number}</Avatar>
            </div>
            <span>{t(`Common.z${number}`)}</span>
            <div className={classes.tableCellPowerzoneDesc}>
              {t(`Common.z${number}desc`)}
            </div>
          </TableCell>
          <TableCell align="center" className={classes.tableCellAchievements}>
            <div className={classes.tableCellGraph}>
              <Box
                className={classes.z0Bar}
                style={{ width: 'calc(100% + 40px)' }}></Box>
              <Box
                className={eval(`classes.z${number}Bar`)}
                style={{ width: `calc(${zoneAvg[number - 1]}% + 30px)` }}></Box>
              <div className={classes.tableCellGraphText}>
                <div className={classes.tableCellGraphTextPercent}>
                  {powerzoneData.powerzone_avg.zone_avg[number - 1]}%
                </div>
                <div className={classes.tableCellGraphTextWatt}>
                  {graphTextWatt}
                </div>
              </div>
            </div>
          </TableCell>
          <TableCell align="center" padding="none" style={{ width: '20px' }}>
            <ExpandMoreIcon
              onClick={() => {
                toggleDesc(number - 1);
              }}
              style={{
                cursor: 'pointer',
                transform: isDescOpen[number - 1]
                  ? 'rotate(180deg)'
                  : 'rotate(0deg)',
                margin: '5px'
              }}
            />
          </TableCell>
          <TableCell
            align="center"
            padding="none"
            className={classes.tableCellWeekText}>
            {!!previousWeekComparisonDescription[number - 1] && (
              <Box className={classes.tableCellWeekTextDesc}>
                <span className={classes.tableTextDesc}>
                  {previousWeekComparisonDescription[number - 1]}
                </span>
              </Box>
            )}
          </TableCell>
        </TableRow>
        {isDescOpen[number - 1] && (
          <TableRow>
            <TableCell colSpan={5}>
              <Box>
                <Box className={classes.tableCellWeekDownText} align="center">
                  <span className={classes.tableTextDesc}>
                    {previousWeekComparisonDescription[number - 1]}
                  </span>
                </Box>
                <Box className={classes.tableCellWeekStatusDownDesc}>
                  {t(`Powerzone.statusDescription.z${number}`)}
                </Box>
                <Box
                  className={classes.tableCellWeekStatusDownDesc}
                  style={{ whiteSpace: 'break-spaces' }}>
                  {t(`Powerzone.statusDescriptionAdv.z${number}`)}
                </Box>
              </Box>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Box className={classes.tabPanel}>
        <Box>
          <Box className={classes.guideFtp}>
            <div>
              {' '}
              FTP{' '}
              <span style={{ color: '#4db6ac', fontWeight: '600' }}>
                {zoneFtp}
              </span>{' '}
              W
            </div>
          </Box>
          <TextField
            select
            className={classes.textField}
            defaultValue={'-1'}
            onChange={event => setWeekId(event.target.value)}
            variant="standard"
            InputProps={{
              readOnly: true,
              style: {
                pointerEvents: 'none'
              }
            }}
            SelectProps={{
              style: {
                pointerEvents: 'none'
              }
            }}
            sx={{
              '& .MuiInputBase-root.Mui-disabled': {
                backgroundColor: 'transparent',
                color: 'inherit'
              },
              '& .MuiSelect-select.Mui-disabled': {
                backgroundColor: 'transparent'
              },
              '& .MuiSelect-icon': {
                display: 'none'
              }
            }}>
            {weeksTypeRange()}
          </TextField>
        </Box>

        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="center" className={classes.tableHeader}>
                  {t('Powerzone.powerzone')}
                </TableCell>
                <TableCell
                  align="center"
                  colSpan={3}
                  className={classes.tableHeader}>
                  {t('Powerzone.achievementsPowerZones')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <ZoneRow number={1} />
              <ZoneRow number={2} />
              <ZoneRow number={3} />
              <ZoneRow number={4} />
              <ZoneRow number={5} />
              <ZoneRow number={6} />
              <ZoneRow number={7} />
            </TableBody>
          </Table>
        </TableContainer>

        <Divider />

        <Box style={{ padding: '12px 0px' }}>
          <Typography className={classes.descTitle} component="div">
            {t('Common.PowerzoneIs')}
          </Typography>
          <Typography className={classes.desc} component="div">
            {t('Common.PowerzoneIsDesc')}
          </Typography>
        </Box>

        <Divider />
      </Box>
    </React.Fragment>
  );
};

PowerZone.propTypes = {
  history: PropTypes.object
};

export default withRouter(PowerZone);
