import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Divider,
  Typography
} from '@mui/material';

import { Icon } from '@iconify/react';
import stravaIcon from '@iconify/icons-mdi/strava';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import auth from '../../../../utils/auth';

// icon
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const useStyles = makeStyles({
  root: {},
  cardWrapper: {
    margin: '0 auto'
  },
  cardHeader: {},
  box: {
    textAlign: 'center',
    padding: '16px 8px',
    justifyContent: 'space-around'
  },
  loadDesc: {
    fontSize: '13px'
  },
  loadDescError: {
    color: '#f06292',
    fontSize: '16px'
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  list: {
    maxWidth: '720px',
    height: '548px',
    overflowY: 'auto',
    border: '1px #dddddd solid',
    margin: '12px auto',
    borderRadius: '4px'
  },
  stravaIcon: {
    color: '#FC4C02',
    fontSize: '24px',
    opacity: '0.7',
    margin: '-2px 0 0 -12px'
  },
  averageWatts: {
    float: 'right',
    width: '100px'
  },
  distance: {
    float: 'right',
    width: '100px'
  },
  waitNotice: {
    maxWidth: '720px',
    padding: '12px',
    margin: '0px auto 12px auto',
    borderRadius: '4px',
    background: '#ffffff',
    border: '1px #76b5e8 solid'
  },
  noticeDesc: {
    fontSize: '13px',
    color: '#76b5e8'
  },
  spaceAround: {
    justifyContent: 'space-around'
  },
  stravaImage: {
    width: '200px'
  },
  noDataDesc: {
    fontSize: '13px',
    padding: '4px 12px',
    '& svg': {
      fontSize: '8px',
      marginRight: '8px',
      color: '#666666'
    }
  },
  noDataTitle: {
    fontSize: '16px',
    fontWeight: '700'
  }
});

const LoadData = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { extraInfo, StravaId } = props;
  const [items, setItems] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(false);
  const [stravaConnect, setStravaConnect] = React.useState('');

  const classes = useStyles();

  const auth_str = 'Bearer '.concat(auth.getToken().token);

  useEffect(() => {
    /*
    if(StravaId > 0 && extraInfo.pretest == 2) {
//      auth.clearExtraInfo();
      setTimeout(() => {
			}, 1000);
    }
*/
  }, [StravaId]);

  const handleCloseDialog = event => {
    //    setStravaConnectDialogOpen(false);
    console.log('dialog close');
  };

  const handleLoadData = event => {
    setLoading(true);
    //연동취소->연동
    axios
      .get(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/strava/load-activities.php`,
        {
          headers: { Authorization: auth_str },
          params: {
            order: 'init_data'
          }
        }
      )
      .then(function(response) {
        setLoading(false);

        const result = response.data;

        console.log(result);

        if (result === 'strava_connect_error') {
          console.log('Strava Connect Error');
          setStravaConnect(result);
        } else if (result.count > 0) {
          setItems({
            activityList: result.activity_list,
            activityCount: result.count,
            error: result.error
          });
        } else if (result.count === 0) {
          setItems({
            activityList: result.activity_list,
            activityCount: result.count,
            error: result.error
          });
        } else if (result.error === 'to_procedure') {
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch(function(err) {
        console.log(err);
      });
  };

  function handleStartRiduck() {
    auth.clearExtraInfo();
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  }

  function DataResult(props) {
    const loading = props.loading;

    const items = props.items;
    let activityList = [];
    let activityCount = 0;
    let error = '';

    if (items !== null) {
      activityList = items.activityList;
      activityCount = items.activityCount;
      error = items.error;
    }

    //    console.log(items);

    //새로고침 해야 한다
    //auth.clearExtraInfo();

    if (loading === false && items === null) {
      return (
        <Button variant="contained" color="primary" onClick={handleLoadData}>
          {t('LoadData.loadStravaData')}
        </Button>
      );
    } else if (loading === true && items === null) {
      return <CircularProgress size={64} />;
    } else if (error === 'no_activity' || error === 'auth_error') {
      return (
        <React.Fragment>
          <Typography component="div" className={classes.loadDescError}>
            {t('LoadData.errorData')}
          </Typography>
        </React.Fragment>
      );
    } else if (error === 'no_power') {
      return (
        <React.Fragment>
          <Typography component="div" className={classes.loadDescError}>
            {t('LoadData.errorDevice')}
          </Typography>
        </React.Fragment>
      );
    } else if (loading === false && activityCount > 0) {
      if (activityList.length > 0 && error === null) {
        setLoadingData(true);
        return (
          <React.Fragment>
            <Box className={classes.waitNotice}>
              <Typography component="div" className={classes.noticeDesc}>
                {activityList.length + t('LoadData.underAnalysis')}
              </Typography>
              <div style={{ margin: '12px 0 12px 0' }}></div>
              <Divider />
              <Button
                style={{ marginTop: '12px' }}
                onClick={handleStartRiduck}
                variant="contained"
                color="primary">
                라이덕 시작하기
              </Button>
            </Box>
            <List className={classes.list}>
              {activityList.map((activity, index) => (
                <ListItem className={classes.activity} key={index}>
                  <ListItemAvatar>
                    <Button
                      color="secondary"
                      size="small"
                      variant="text"
                      target="_blank"
                      href={activity.activity_url}>
                      <Icon icon={stravaIcon} className={classes.stravaIcon} />
                    </Button>
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography component="span">
                          {activity.activity_name}
                        </Typography>
                        <Typography component="span">
                          {' '}
                          - {activity.power_device ? '⚡' : ''}{' '}
                          {activity.average_watts} W
                        </Typography>
                      </React.Fragment>
                    }
                    secondary={activity.start_date}
                  />

                  <ListItemSecondaryAction className={classes.distance}>
                    {Math.round(activity.distance / 100) / 10} km
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </React.Fragment>
        );
      }
    }
  }

  return (
    <React.Fragment>
      <Card className={classes.cardWrapper}>
        <CardContent style={{ padding: '0px' }}>
          <Box className={classes.box}>
            {stravaConnect === 'strava_connect_error' ? (
              <React.Fragment>
                <ErrorOutlineIcon fontSize="large" />
                <div style={{ marginBottom: '24px' }}>
                  {t('LoadData.stravaConnectError')}
                </div>
                <a href="./settings">
                  <Button color="secondary" variant="contained">
                    {t('LoadData.accountReset')}
                  </Button>
                </a>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {loadingData === false && (
                  <React.Fragment>
                    <ErrorOutlineIcon fontSize="large" />
                    <Box className={classes.box}>
                      <Typography className={classes.noDataTitle}>
                        {t('LoadData.noDataTitle')}
                      </Typography>
                      <List>
                        <ListItem className={classes.noDataDesc}>
                          <FiberManualRecordIcon />
                          {t('LoadData.noDataDesc1')}
                        </ListItem>
                        <ListItem className={classes.noDataDesc}>
                          <FiberManualRecordIcon />
                          {t('LoadData.noDataDesc2')}
                        </ListItem>
                        <ListItem className={classes.noDataDesc}>
                          <FiberManualRecordIcon />
                          {t('LoadData.noDataDesc3')}
                        </ListItem>
                        <ListItem className={classes.noDataDesc}>
                          <FiberManualRecordIcon />
                          {t('LoadData.noDataDesc4')}
                        </ListItem>
                        <ListItem className={classes.noDataDesc}>
                          <FiberManualRecordIcon />
                          {t('LoadData.noDataDesc5')}
                        </ListItem>
                      </List>
                    </Box>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </Box>
          <Divider />
          <Box className={classes.box}>
            <DataResult items={items} loading={loading} />
          </Box>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

LoadData.propTypes = {
  className: PropTypes.string
};

export default LoadData;
