import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import {
  useLocation,
  useParams
} from 'react-router-dom/cjs/react-router-dom.min';
//
import PropTypes from 'prop-types';
import validate from 'validate.js';
//
import { editClubApi } from 'utils/clientApis';
import { getImageUrl } from 'utils/getImageUrl';
//
import { makeStyles } from '@mui/styles';
import {
  Box,
  Fab,
  Button,
  Link,
  TextField,
  Typography,
  Container,
  FormHelperText
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PelotonLayout from 'views/Peloton/Common/PelotonLayout';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
      minimum: 2
    }
  },
  description: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
      minimum: 10
    }
  },
  guideText: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128,
      minimum: 10
    }
  },
  uriName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
      minimum: 3
    },
    format: {
      pattern: /^[a-z0-9-,_]+$/
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {},
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid',
    display: 'flex',
    alignItems: 'center'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  canvas: {
    backgroundColor: 'white',
    padding: '16px 16px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    marginTop: '12px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    lineHeight: '28px',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  createForm: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textField: {
    margin: '8px 0',
    width: 'calc(100% - 16px)'
  },
  buttonWrap: {
    display: 'flex',
    width: '100%',
    padding: '16px'
  },
  button: {},
  uploadBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    borderRadius: '4px',
    border: '1px dashed var(--divider, rgba(0, 0, 0, 0.12))',
    padding: '24px 16px',
    cursor: 'pointer'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  image: {
    borderRadius: '6px',
    width: '100%',
    overflow: 'hidden'
  },
  imagePreviews: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%'
  },
  xButton: {
    color: 'white',
    position: 'absolute',
    right: '4px',
    top: '4px',
    height: '16px',
    cursor: 'pointer'
  },
  input: {
    fontSize: '16px'
  }
}));

function EditPeloton() {
  const fileInputRef = useRef(null);
  const classes = useStyles();
  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const location = useLocation();
  const {
    pelotonName,
    uriName,
    description,
    guideText,
    images
  } = location.state;
  const { pelotonId } = useParams();

  // 이미지 미리보기를 위한 상태
  const [imageThumbs, setImageThumbs] = useState([]);
  const [formState, setFormState] = useState({
    data: {
      name: pelotonName,
      description: description,
      country: 'KOR',
      city: '',
      guideText: guideText,
      uriName: uriName,
      deleteImageIds: []
    },
    files: images,
    errors: {},
    touched: {},
    isValid: false
  });

  // 게시글 본문에서 prop으로 기존 게시글 내용을 받아옵니다.
  useEffect(() => {
    if (images) {
      // 이미지 파일은 보통 3가지 사이즈로 등록 되어있습니다.
      // 이 항목은, 해당 파일들을 originId로 그룹화 하고, 필요한 "SMALL" 사이즈를 반환합니다.
      const groupedImages = groupByOriginId(images);
      Object.entries(groupedImages).forEach(([originId, images]) => {
        const filePath = getImageUrl(images, 'SMALL');
        setImageThumbs(currentFiles => [
          ...currentFiles,
          {
            filePath: filePath,
            originId: originId
          }
        ]);
      });
    }
  }, [location.state]);

  // originId를 key로 하여, prop으로 들어오는 이미지 배열을 그룹화 합니다.
  const groupByOriginId = imageArray => {
    return imageArray.reduce((acc, image) => {
      const key = image.originId;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(image);
      return acc;
    }, {});
  };

  useEffect(() => {
    const errors = validate(formState.data, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.data]);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const inputFields = ['name', 'uriName'];
      const currentIndex = inputFields.findIndex(
        field => field === event.target.name
      );
      if (currentIndex < inputFields.length - 1) {
        const nextField = inputFields[currentIndex + 1];
        document.getElementsByName(nextField)[0].focus();
      } else {
        handleEditPeloton(event);
      }
    }
  };

  const handleChange = event => {
    event.persist();

    setFormState({
      ...formState,
      data: {
        ...formState.data,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    });
  };

  // 이미지 업로드 핸들러
  const handleImageUpload = async event => {
    const uploadedFiles = Array.from(event.target.files);
    // "sample.jpeg"의 형태로는 사용할 수 없어, URI 형태로 변환해주는 작업을 진행합니다. imagePreviewMaker()
    const newImageThumbs = await imagePreviewMaker(uploadedFiles);

    // formState의 files 배열을 업데이트합니다.
    setFormState(prevState => {
      const updatedFiles =
        prevState.files && Array.isArray(prevState.files)
          ? [...prevState.files, ...uploadedFiles]
          : uploadedFiles;

      return {
        ...prevState,
        files: updatedFiles
      };
    });
    setImageThumbs(currentThumbs => [
      ...currentThumbs,
      ...newImageThumbs.map(uri => ({ filePath: uri, originId: null }))
    ]);
  };

  // 사용할 수 있는 URI로 이미지 file path를 변환해줍니다.
  const imagePreviewMaker = async files => {
    try {
      const newImageURIs = await Promise.all(
        files.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
          });
        })
      );
      return newImageURIs;
    } catch (error) {
      console.error('이미지를 불러오는 중 오류가 발생했습니다:', error);
    }
  };

  // 이미지 삭제 핸들러
  const handleRemoveImage = (originId, index) => {
    // formState에 담겨 서버로 보내지는 '삭제된 이미지의 id'를 setState합니다.
    setFormState(prevState => {
      const deleteImageIds = Array.isArray(prevState.data?.deleteImageIds)
        ? prevState.data?.deleteImageIds
        : [];
      if (originId) {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            // 이전 deleteImageIds 배열에 originId 추가
            deleteImageIds: [...deleteImageIds, originId]
          }
        };
      } else {
        return prevState;
      }
    });
    // originId가 존재하는 기존 게시글의 이미지를 삭제할 경우
    if (originId) {
      setImageThumbs(currentThumbs =>
        currentThumbs.filter(image => image.originId !== originId)
      );
    }
    // 신규 이미지는 대상의 index를 key로 하여 삭제를 진행
    else {
      setImageThumbs(currentThumbs =>
        currentThumbs.filter((_, i) => i !== index)
      );
    }
  };

  const handleEditPeloton = async event => {
    event.preventDefault();

    try {
      const fixedImages = formState?.files.filter(
        file =>
          !formState.data.deleteImageIds.includes(String(file.originId)) ||
          !file.originId
      );

      const response = await editClubApi(
        formState?.data,
        fixedImages,
        pelotonId
      );
      if (response.data.success) {
        window.history.back();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <PelotonLayout>
      <>
        <Box className={classes.canvas}>
          <form className={classes.createForm} onSubmit={handleEditPeloton}>
            <TextField
              className={classes.textField}
              error={hasError('name')}
              fullWidth
              helperText={
                hasError('name')
                  ? formState.errors.name[0]
                  : '사용할 펠로톤 이름을 입력하세요.'
              }
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              label={'펠로톤 이름'}
              name="name"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="text"
              value={formState.data?.name}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={hasError('uriName')}
              fullWidth
              helperText={
                hasError('uriName')
                  ? formState.errors.uriName[0]
                  : '영문 소문자, 숫자 사용 가능하며 3글자 이상으로 결정해주세요.'
              }
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              label={'펠로톤 주소'}
              name="uriName"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="text"
              value={formState.data?.uriName || ''}
              variant="outlined"
            />
            <TextField
              className={classes.textField}
              error={hasError('description')}
              fullWidth
              helperText={
                hasError('description')
                  ? formState.errors.description[0]
                  : '펠로톤에 대한 소개글을 입력하세요.'
              }
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              label={'소개'}
              name="description"
              multiline
              rows={4}
              onChange={handleChange}
              type="text"
              value={formState.data?.description || ''}
              variant="outlined"
            />
            {imageThumbs.length === 0 ? (
              <>
                <Container className={classes.uploadBox}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none">
                    <path
                      d="M14.5 2H6.5C5.4 2 4.51 2.9 4.51 4L4.5 20C4.5 21.1 5.39 22 6.49 22H18.5C19.6 22 20.5 21.1 20.5 20V8L14.5 2ZM18.5 20H6.5V4H13.5V9H18.5V20ZM8.5 15.01L9.91 16.42L11.5 14.84V19H13.5V14.84L15.09 16.43L16.5 15.01L12.51 11L8.5 15.01Z"
                      fill="#4DB6AC"
                    />
                  </svg>
                  <div
                    className={classes.textContainer}
                    onClick={() => fileInputRef.current.click()}>
                    <Typography
                      align="center"
                      color="primary"
                      variant="body1"
                      style={{
                        textDecoration: 'underline',
                        marginRight: '2px'
                      }}>
                      클릭하여
                    </Typography>
                    <input
                      id="upload-input"
                      type="file"
                      accept="image/.JPG, .JPEG, .PNG"
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={handleImageUpload}
                    />
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="body1">
                      펠로톤 이미지 업로드
                    </Typography>
                  </div>
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1">
                    PNG, JPG, JPEG (max. 3MB)
                  </Typography>
                </Container>
                <FormHelperText style={{ textAlign: 'start' }}>
                  펠로톤의 대표이미지를 등록하세요. (1280*720px 권장)
                </FormHelperText>
              </>
            ) : Array.isArray(imageThumbs) ? (
              <>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '100%',
                    padding: '8px'
                  }}>
                  {imageThumbs &&
                    imageThumbs.length > 0 &&
                    imageThumbs.map((image, idx) => {
                      return (
                        <Box
                          key={image.originId}
                          style={{
                            position: 'relative',
                            marginRight: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                            width: '23%',
                            alignItems: 'center',
                            overflow: 'hidden',
                            height: '100%'
                          }}>
                          <img
                            className={classes.image}
                            src={image.filePath}
                            alt={`imagePreview ${idx}`}
                          />
                          <HighlightOffIcon
                            onClick={() =>
                              handleRemoveImage(image.originId, idx)
                            } // originId와 idx(인덱스)를 전달
                            className={classes.xButton}
                          />
                        </Box>
                      );
                    })}
                </Box>
                <Box
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    alignItems: 'center'
                  }}
                  className={classes.imagePreviews}>
                  <Fab
                    color="primary"
                    size="small"
                    style={{
                      display: 'flex',
                      marginLeft: '8px'
                    }}
                    onClick={() => fileInputRef.current.click()}>
                    <input
                      id="upload-input"
                      type="file"
                      accept="image/*"
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={handleImageUpload}
                    />
                    <AddCircleIcon style={{ color: 'white' }} />
                  </Fab>
                </Box>
              </>
            ) : null}
            <TextField
              className={classes.textField}
              error={hasError('guideText')}
              fullWidth
              helperText={
                hasError('guideText')
                  ? formState.errors.guideText[0]
                  : '다른 회원이 펠로톤에 가입신청하면 표시될 안내 문구를 입력하세요. (회칙, 공지사항 등)'
              }
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              label={'가입안내'}
              name="guideText"
              multiline
              rows={4}
              onChange={handleChange}
              type="text"
              value={formState.data?.guideText || ''}
              variant="outlined"
            />
            <Typography
              align="center"
              color="textSecondary"
              variant="body2"
              style={{ marginTop: '16px' }}>
              계속하면{' '}
              <Link
                href="https://riduck.gitbook.io/riduck-guide/etc/community-guideline"
                underline="always"
                target="_blank">
                라이덕 커뮤니티 가이드라인
              </Link>{' '}
              및 <br />
              <Link
                href="https://riduck.gitbook.io/riduck-guide/etc/privacy"
                underline="always"
                target="_blank">
                개인정보 처리방침
              </Link>
              을 읽고 동의한 것으로 간주합니다. <br />
              접수된 신청은 1영업일 이내에 라이덕 운영진 검토 후, <br />
              승인처리 해드립니다.
            </Typography>
            <div className={classes.buttonWrap}>
              <Button
                className={classes.button}
                color="primary"
                fullWidth
                size="large"
                onClick={() => window.history.back()}
                variant="text">
                취소
              </Button>
              <Button
                className={classes.button}
                color="primary"
                disabled={!formState?.isValid}
                fullWidth
                size="large"
                type="submit"
                variant="contained">
                수정신청
              </Button>
            </div>
          </form>
        </Box>
      </>
    </PelotonLayout>
  );
}

EditPeloton.propTypes = {
  history: PropTypes.object
};
export default withRouter(EditPeloton);
