import React, { useEffect } from 'react';
import { Box, Stack, Divider, CircularProgress } from '@mui/material';
import { DynamicFontTypography } from 'components/Typography';
import { BreadcrumbBar, RelatedSection } from 'components/Common';
import { useContentMeta } from 'hooks/useContentMeta';
// import NotFoundPage from './NotFound';
// import CommentsSection from '../..components/Common/CommentsSection';

export default function ContentView() {
  const { section, slug, dataList } = useContentMeta();

  const article = dataList?.find(item => item.slug === slug);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  if (!article) {
    return (
      <Box
        sx={{
          width: '100%',
          height: '150px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <CircularProgress
          size={24}
          sx={{ color: 'primary.main' }}
          thickness={4}
        />
      </Box>
    );
  }

  const { title, date, content } = article;

  const labelMap = { blog: 'Blog', news: 'News' };
  const label = labelMap[section];

  return (
    <Box
      id={slug || undefined}
      component="main"
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center">
      <Stack
        component="section"
        width="100vw"
        maxWidth="1200px"
        px={{ xs: 3, lg: 0 }}
        pt="19.2px"
        position="relative"
        direction="column"
        justifyContent="flex-start"
        alignItems="center">
        {/* Breadcrumb and Metadata */}
        <Stack
          gap={{ xs: 2, md: 0 }}
          width="100%"
          direction="row"
          // direction={{ xs: 'column', md: 'row' }}
          alignItems="center"
          justifyContent="space-between"
          mb="19.2px">
          <BreadcrumbBar section={label} title={title} />
          <DynamicFontTypography fontSize={{ xs: 10, md: 14 }}>
            {date}
          </DynamicFontTypography>
        </Stack>

        <Divider flexItem />

        {/* Title */}
        <DynamicFontTypography
          variant="h4"
          textAlign="center"
          my={{ xs: 6, lg: 8 }}
          sx={{ fontSize: { xs: '24px', lg: '32px' }, fontWeight: 400 }}>
          {title}
        </DynamicFontTypography>

        {/* Article Body + Related */}
        <Stack gap={{ xs: 8, lg: 10 }} width="100%">
          <Box width="100%" maxWidth="1200px" pb="100px">
            {content}
          </Box>

          {/* Future: Comments */}
          {/* <CommentsSection commentCount={commentCount} comments={comments} /> */}

          <RelatedSection />
        </Stack>
      </Stack>
    </Box>
  );
}
