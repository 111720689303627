import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
//
import { useTranslation } from 'react-i18next';
import { fetchPostsApi } from 'utils/clientApis';
//
import BoardCard from 'views/Peloton/Club/Menu/PostList/PostCard';
//
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import Pagination from '@mui/material/Pagination';
//
import CustomCircularProgress from 'views/Peloton/Common/CustomCircularProgress';
import BoardWrite from './PostWrite';
import NoData from 'views/Peloton/Common/NoData';
import scrollToTop from 'views/Peloton/Utils/scrollToTop';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontSize: '16px',
    color: theme.palette.text.primary,
    fontWeight: '700',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  }
}));

const PostList = prop => {
  const classes = useStyles();
  const { t } = useTranslation(['page']);
  const history = useHistory();
  const location = useLocation();

  const { data } = prop;
  const pelotonId = data?.id;
  const uriName = data?.uriName;

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageTotal, setPageTotal] = useState(1);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const postsArr = Array.isArray(posts) ? posts : [posts];
  const tabChecker = JSON.parse(localStorage.getItem('tab'));
  const istabPost = tabChecker.tabName === 'post';

  const ITEMS_PER_PAGE = 9;

  useEffect(() => {
    handleGetPostData();
  }, []);

  const handleGetPostData = async () => {
    setIsDataLoading(true);
    try {
      if (currentPage) {
        const response = await fetchPostsApi(
          pelotonId,
          currentPage - 1,
          ITEMS_PER_PAGE
        );
        setPosts(response.data.response.content);
        setPageTotal(response.data.response.totalPages);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsDataLoading(false);
    }
  };

  /**
   * @return : 페이지네이션 구현부.
   */
  useEffect(() => {
    // screenRef.current.scrollIntoView({ behavior: 'auto', block: 'start' });
    // 페이지 번호가 변경될 때마다 새로운 페이지 데이터를 불러옵니다.
    handleGetPostData();
  }, [currentPage]);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
    if (istabPost) {
      history.push(`?page=${newPage}`);
      scrollToTop();
    }
  };

  // 뒤로 가기 감지
  useEffect(() => {
    let unlisten = history.listen(location => {
      const qs = location.search.replace('?', '');
      const pairs = qs.split('&');
      const items = {};

      for (let i = 0; i < pairs.length; i++) {
        items[pairs[i].split('=')[0]] = pairs[i].split('=')[1];
      }

      if (history.action === 'PUSH') {
      }
      if (history.action === 'POP') {
        const page = items.page ? Number(items.page) : 1;
        setCurrentPage(page);
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);

  // search 파라미터 변경시 스크롤 최상단으로 이동
  useEffect(() => {
    scrollToTop();
  }, [location.search]);

  return (
    <div className={classes.root}>
      {/* <div ref={screenRef} style={{ scrollMarginTop: '140px' }} /> */}
      {!isDataLoading ? (
        <>
          <Typography
            className={classes.title}
            style={{ marginBottom: '16px' }}>
            게시판
          </Typography>
          <BoardWrite
            handleGetPostData={handleGetPostData}
            profileImage={data.myClubUserResponse.profileImage}
          />
          {posts.length > 0 ? (
            <>
              {postsArr.map((post, idx) => {
                return (
                  <BoardCard
                    key={idx}
                    post={post}
                    idx={idx}
                    uriName={uriName}
                    clubProp={prop}
                    page={currentPage}
                  />
                );
              })}
              <Box my={2} display="flex" justifyContent="center">
                <Pagination
                  count={pageTotal}
                  page={currentPage}
                  onChange={handleChangePage}
                  shape="rounded"
                  hidePrevButton
                  hideNextButton
                />
              </Box>
            </>
          ) : (
            <NoData title="작성된 게시물이 없습니다." />
          )}
        </>
      ) : (
        <CustomCircularProgress />
      )}
    </div>
  );
};
export default PostList;
