import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Chip, Grid } from '@mui/material';
// utils
import { statusTracker, paceTracker } from 'utils/helper';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useFormatDate } from 'utils/useFormatDate';
import MiniDate from 'views/Peloton/Common/MiniDate';

const useStyles = makeStyles(theme => ({
  calendarGrid: {
    display: 'flex',
    alignItems: 'center'
  },
  eventInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around'
  },
  eventInfoItem: {
    padding: '4px 0'
  },
  eventInfoLocation: {
    padding: '4px 0',
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px'
  },
  chipWrap: {
    alignItems: 'center',
    display: 'flex',
    '& > *': {
      marginRight: '4px'
    }
  },
  chip: {}
}));

/**
 *
 * 이벤트 주요 정보를 담고 있는 컴포넌트
 */
function EventInfo({ event, locationData }) {
  const classes = useStyles();

  const today = new Date();
  const { meetingTime } = event;

  const { getDateSubtract } = useFormatDate();

  const dayLeft = getDateSubtract(meetingTime, today.toString());

  const dateInfoText = dayLeft => {
    if (dayLeft > 0) return `${dayLeft}일 남았습니다.`;
    else if (dayLeft === 0) return `오늘입니다.`;
    else return `${statusTracker('CLOSED')}된 이벤트입니다.`;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item className={classes.calendarGrid}>
          <MiniDate meetingTime={meetingTime} />
        </Grid>
        <Grid item className={classes.eventInfoContainer}>
          {/* 남은 시간 */}
          <Box className={classes.eventInfoItem}>
            <Typography variant="body1" color="secondary">
              {event.status == 'OPEN'
                ? dateInfoText(dayLeft)
                : `${statusTracker(event.status)}된 이벤트입니다.`}
            </Typography>
          </Box>
          <Box className={classes.eventInfoItem}>
            {/* 이벤트 태그  */}
            <Box className={classes.chipWrap}>
              <Box className={classes.chipWrap}>
                <Chip
                  label={statusTracker(event.status)}
                  className={classes.chip}
                  size="small"
                  color={event.status === 'OPEN' ? 'primary' : 'secondary'}
                  variant="outlined"
                />
                <Chip
                  label={paceTracker(event.pace)}
                  className={classes.chip}
                  size="small"
                  color="primary"
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* 약속 장소 */}
      <Box className={classes.eventInfoLocation}>
        <LocationOnIcon style={{ marginRight: '4px' }} />
        <Typography
          variant="body1"
          style={{
            alignItems: 'center',
            justifyContent: 'start'
          }}>
          <span>{locationData?.name}</span>
          {locationData.locationString ? (
            <span> ({locationData?.locationString})</span>
          ) : null}
        </Typography>
      </Box>
    </>
  );
}

export default EventInfo;
