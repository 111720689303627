import React, { useState } from 'react';
import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { DynamicFontTypography } from 'components/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  sub: {
    fontSize: 'clamp(16px, 1.3vw, 14px)',
    lineHeight: 'clamp(22px, 1.5vw, 30px)'
  }
}));

export default function CTAButton({ cta, url, size }) {
  const [hover, setHover] = useState(false);
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      disableRipple
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        bgcolor: '#009688',
        width: size || 'fit-content',
        height: '42px',
        borderRadius: '4px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        '&:hover': {
          backgroundColor: '#333333'
        }
      }}>
      <DynamicFontTypography
        component="span"
        className={classes.sub}
        fontWeight={500}
        sx={{ color: 'white' }}>
        {cta}
      </DynamicFontTypography>
      <DynamicFontTypography
        component="span"
        className={classes.sub}
        fontWeight={500}>
        {hover && (
          <ChevronRightIcon
            fontSize="small"
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              marginLeft: '4px'
            }}
          />
        )}
      </DynamicFontTypography>
    </Button>
  );
}
