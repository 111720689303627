import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import Carousel from 'views/Peloton/Common/Carousel';
// utils
import { statusTracker } from 'utils/helper';
import organizeImagesByOriginId from 'views/Peloton/Utils/organizeImagesByOriginId';

const useStyles = makeStyles(theme => ({
  imageDoneContainer: {
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    background: 'rgb(255 255 255 / 50%)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute'
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '8px'
  },
  image: {
    width: '100%',
    objectFit: 'cover',
    marginBottom: '16px'
  }
}));

/* 이미지 캐러셀 */
function EventImageCarousel({ event }) {
  const classes = useStyles();

  const organizedData = organizeImagesByOriginId(event.eventThumbFiles);

  return (
    <Box className={classes.imageContainer}>
      {Array.isArray(organizedData) && <Carousel data={organizedData} />}
      {event.eventThumbFiles.length &&
      event.status &&
      event.status !== 'OPEN' ? (
        <Box className={classes.imageDoneContainer}>
          <Typography
            variant="h4"
            style={{
              backgroundColor: '#ffffffaa',
              color: '#000000',
              padding: 5,
              borderRadius: 8
            }}>
            {`${statusTracker(event.status)}된 이벤트입니다.`}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
}

export default EventImageCarousel;
