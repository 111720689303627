import React, { useRef } from 'react';
import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { DynamicFontTypography } from 'components/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  sub: {
    fontSize: 'clamp(16px, 1.3vw, 18px)',
    lineHeight: 'clamp(22px, 1.5vw, 24px)',
    fontWeight: 300
  }
}));

const ReviewCard = ({ user, country, review }) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box
      sx={{
        flex: { xs: '0 0 230px', lg: '0 0 320px' },
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'start',
        p: '20px 16px 30px',
        borderRadius: '4px',
        bgcolor: theme.palette.background.paper
      }}>
      <DynamicFontTypography
        className={classes.sub}
        sx={{
          color: 'primary',
          mb: 2
        }}>
        {`${user} | ${country}`}
      </DynamicFontTypography>
      <DynamicFontTypography
        className={classes.sub}
        sx={{
          color: 'secondary'
        }}>
        "{review}"
      </DynamicFontTypography>
    </Box>
  );
};

export default function Reviews() {
  const { t } = useTranslation('landing');
  const reviews = t('Reviews.items', { returnObjects: true });

  const autoplay = useRef(
    Autoplay({
      delay: 3000,
      stopOnInteraction: false,
      stopOnMouseEnter: true,
      rootNode: emblaRoot => emblaRoot?.parentElement
    })
  );

  const options = {
    loop: true,
    align: 'start'
  };

  const [emblaRef] = useEmblaCarousel(options, [autoplay.current]);

  return (
    <section id="reviews" style={{ width: '100%', scrollMarginTop: '80px' }}>
      <Stack
        gap={{ xs: 5, md: 10 }}
        direction="column"
        alignItems="center"
        px={{ xs: 3, lg: 0 }}
        maxWidth="1200px"
        mx="auto">
        <Box
          position="relative"
          width="100%"
          height={{ xs: '400px', lg: '500px' }}
          sx={{ borderRadius: '4px', overflow: 'hidden' }}>
          {/* Background */}
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%'
            }}>
            <Box
              component="img"
              src="/images/review-bg.png"
              alt="Background"
              sx={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center'
              }}
            />
          </Box>

          {/* Foreground carousel */}
          <Box
            position="relative"
            zIndex={1}
            width="100%"
            height="100%"
            display="flex"
            alignItems="center">
            <Box
              ref={emblaRef}
              sx={{
                overflow: 'hidden',
                width: '100%',
                px: '20px',
                transition: 'transform 0.5s ease'
              }}>
              <Box
                sx={{
                  pl: '32px',
                  width: 'fit-content',
                  display: 'flex',
                  gap: '32px',
                  userSelect: 'none'
                }}>
                {reviews.map((review, index) => (
                  <ReviewCard key={index} {...review} />
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
    </section>
  );
}
