import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Breadcrumbs } from '@mui/material';
import { DynamicFontTypography } from 'components/Typography';

export default function BreadcrumbBar({ section, title }) {
  const sharedTextStyles = {
    fontSize: '14px',
    lineHeight: 1.5,
    display: 'inline-flex',
    alignItems: 'center'
  };

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      <Link
        to={`/${section}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        style={{ textDecoration: 'none' }}>
        <Box
          sx={{
            ...sharedTextStyles,
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': { color: '#009688' }
          }}>
          <DynamicFontTypography>{section}</DynamicFontTypography>
        </Box>
      </Link>
      {/* <DynamicFontTypography
        color="text.primary"
        sx={{
          ...sharedTextStyles,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          maxWidth: { xs: '120px', sm: '160px', md: '100%' } // adjust width as needed
        }}>
        {title}
      </DynamicFontTypography> */}
    </Breadcrumbs>
  );
}
