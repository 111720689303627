import React from 'react';
import { Box } from '@mui/material';

export default function BurgerMenuIcon() {
  return (
    <Box
      component="img"
      src="/images/burger.svg"
      alt="Menu"
      sx={{
        display: { xs: 'flex', lg: 'none' },
        width: '28px',
        height: '28px'
      }}
    />
  );
}
