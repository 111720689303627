import React from 'react';
//
import { makeStyles, useTheme } from '@mui/styles';
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Box
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function PopUpDialog({ openPop, title, handleClose, children }) {
  const classes = useStyles();
  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      {openPop && (
        <Dialog
          open={openPop}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="md"
          fullScreen={fullScreen}
          scroll="body">
          <Box className={classes.titleContainer}>
            <DialogTitle text="h6">{title}</DialogTitle>
            <CloseIcon
              onClick={() => handleClose()}
              style={{ cursor: 'pointer' }}
            />
          </Box>
          <Divider />
          <div className={classes.whiteLine} />
          <DialogContent alt="post" className={classes.contentContainer}>
            {children}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

const useStyles = makeStyles(theme => ({
  whiteLine: {
    borderBottom: '1px #ffffff solid'
  },
  contentContainer: {
    backgroundColor: theme.palette.background.paper,
    padding: '8px 8px',
    marginBottom: '8px'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: theme.palette.background.paper,
    zIndex: '999',
    width: '100%',
    padding: '0px 8px'
  }
}));
