import React from 'react';
import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';

import { useFormatDate } from 'utils/useFormatDate';

function MiniDate({ meetingTime, type = 'default' }) {
  const classes = useStyles();

  const { getTimeUnits, useFormatTime } = useFormatDate();

  const { month, date, hour, minute } = getTimeUnits(meetingTime);
  const timeText = useFormatTime(hour, minute);

  return (
    <Box className={classes.container}>
      <Box className={classes.calendarContainer}>
        <Box
          className={type === 'badge' ? classes.paddingBadge : classes.padding}>
          <span className={type === 'badge' ? classes.dateBadge : classes.date}>
            {month}월{' '}
          </span>
          <span className={type === 'badge' ? classes.dateBadge : classes.date}>
            {date}일
          </span>
          <span
            className={
              type === 'badge' ? classes.dayWeekBadge : classes.dayWeek
            }>
            {` (${
              ['일', '월', '화', '수', '목', '금', '토'][
                new Date(meetingTime).getDay()
              ]
            })`}
          </span>
        </Box>
      </Box>
      {/**
       * 약속 시간
       */}
      <Box
        className={type === 'badge' ? classes.paddingBadge : classes.padding}>
        <Typography
          variant="body1"
          className={
            type === 'badge' ? classes.timeTextBadge : classes.timeText
          }>
          {timeText}
        </Typography>
      </Box>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  container: {
    border: 'solid',
    borderColor: theme.palette.cancel.main,
    borderWidth: '1px',
    borderRadius: '3px'
  },
  calendarContainer: {
    borderBottom: 'solid',
    borderBottomColor: theme.palette.cancel.main,
    borderBottomWidth: '1px',
    width: '100%',
    textAlign: 'center'
  },
  calendarMonth: {
    fontWeight: 'bold',
    fontSize: '16px',
    paddingBottom: '4px'
  },
  calendarDate: {
    fontWeight: 'bold',
    fontSize: '20px',
    color: theme.palette.primary.main
  },
  paddingBadge: {
    padding: '4px'
  },
  padding: {
    padding: '4px 8px'
  },
  dateBadge: {
    fontWeight: 'bold',
    color: theme.palette.text.third
  },
  dayWeekBadge: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
  timeTextBadge: {
    fontWeight: 'bold',
    color: theme.palette.text.third,
    padding: '4px',
    textAlign: 'center'
  },
  date: {
    fontWeight: 'bold',
    color: theme.palette.text.third,
    fontSize: '20px'
  },
  dayWeek: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    fontSize: '20px'
  },
  timeText: {
    fontWeight: 'bold',
    color: theme.palette.text.third,
    padding: '4px',
    textAlign: 'center',
    fontSize: '16px'
  }
}));

export default MiniDate;
