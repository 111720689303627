import React, { useEffect, useState } from 'react';

import { Typography, Container, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';

import validate from 'validate.js';

import { AddClubPostParentCommentApi, EditCommentApi } from 'utils/clientApis';
import Comment from './Comment';
import CommentWrite from 'views/Peloton/Common/CommentWrite';

const schema = {
  comment: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 500,
      minimum: 3
    }
  }
};

function Comments({
  commentHistory,
  userInfoNow,
  pelotonId,
  postId,
  handleGetComments
}) {
  const classes = useStyles();

  // 코멘트 생성을 위한 formState
  const [commentState, setCommentState] = useState({
    data: {
      contents: ''
    },
    errors: {},
    touched: {},
    isValid: false
  });
  // 코멘트 생성 상황에서 리렌더링을
  const [stateChanger, setStateChanger] = useState(false);

  useEffect(() => {
    handleGetComments();
  }, [stateChanger]);

  /**
   * @return : 코멘트 생성의 Change Handling.
   */
  useEffect(() => {
    const errors = validate(commentState.data, schema);
    setCommentState(prevState => ({
      ...prevState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [commentState.data, schema]);

  // comment 생성
  const handleAddComment = async () => {
    try {
      const response = await AddClubPostParentCommentApi(
        pelotonId,
        postId,
        commentState.data
      );

      // response &&
      //     console.log(response)
      setStateChanger(prev => !prev);
      setCommentState({
        data: {
          contents: ''
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  // comment 수정
  const submitEditComment = async (commentState, commentId) => {
    try {
      const response = await EditCommentApi(
        pelotonId,
        postId,
        commentState.data,
        commentId
      );

      if (response.data.success) {
        handleGetComments();
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditChange = event => {
    event.persist();

    const { name, value } = event.target;
    setCommentState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value
      },
      touched: {
        ...prevState.touched,
        [name]: true
      }
    }));
  };

  return (
    <>
      {/* 댓글 */}
      {commentHistory
        ? commentHistory.map(comment => {
            return (
              <div key={comment.id}>
                <Comment
                  comment={comment}
                  type="parent"
                  userInfoNow={userInfoNow}
                  pelotonId={pelotonId}
                  postId={postId}
                  handleGetComments={handleGetComments}
                  submitEditComment={submitEditComment}
                />

                {/* 대댓글 */}
                {/* TODO: 현재 대댓글 기능 비활성화. 디자인 수정 필요 */}
                {/* {comment.childCommunityComments.length ? (
                  <Box className={classes.commentChildren}>
                    {comment.childCommunityComments.map(childComment => (
                      <Comment
                        key={childComment.id}
                        type="child"
                        comment={childComment}
                        userInfoNow={userInfoNow}
                        pelotonId={pelotonId}
                        postId={postId}
                        handleGetComments={handleGetComments}
                        submitEditComment={submitEditComment}
                      />
                    ))}
                  </Box>
                ) : null} */}
              </div>
            );
          })
        : null}
      <Divider />
      <Container className={classes.comment}>
        {/* 댓글 작성 */}
        <Typography
          variant="h6"
          color="textPrimary"
          style={{ marginBottom: '16px' }}>
          댓글 작성
        </Typography>
        <CommentWrite
          value={commentState.data.contents || ''}
          onEditChange={handleEditChange}
          onButtonClick={handleAddComment}
        />
      </Container>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  comment: {
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    justifyContent: 'center',
    alignItem: 'center',
    flexDirection: 'column',
    padding: '16px 16px',
    marginTop: '8px'
  },
  commentChildren: {
    marginLeft: '48px'
  },
  returnIconContainer: {
    textAlign: 'right'
  }
}));

export default Comments;
