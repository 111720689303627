import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import cookie from 'react-cookies';
import { Fade, Paper, Stack, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { DynamicFontTypography } from 'components/Typography';

export default function CookieBanner() {
  const [bannerOpen, setBannerOpen] = useState(true);
  const theme = useTheme();
  const { t } = useTranslation('landing');

  const closeBanner = () => {
    setBannerOpen(false);
    cookie.save('cookie_policy', 'y', { path: '/' });
  };

  const bannerStyles = {
    zIndex: 1500,
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    paddingInline: { sm: '16px', md: '32px' },
    paddingBlock: { xs: '16px', md: '24px' },
    borderTop: `1px solid ${theme.palette.divider}`,
    bgcolor: '#FEE7AA',
    overflowX: 'hidden'
  };

  const typographyStyles = {
    textAlign: { xs: 'center', md: 'left' },
    fontSize: '14px',
    lineHeight: '23px'
  };

  return (
    <Fade appear={false} in={bannerOpen}>
      <Paper
        role="dialog"
        aria-label="Cookie banner"
        aria-hidden={!bannerOpen}
        square
        variant="outlined"
        tabIndex={-1}
        sx={bannerStyles}>
        <Stack
          direction={{ sm: 'column', md: 'row' }}
          maxWidth="1200px"
          margin="0px auto"
          padding={{ xs: '0 24px', sm: 0 }}
          alignItems="center"
          justifyContent="center"
          gap={2}>
          {/* Text Section */}
          <Box sx={{ flexShrink: 1, alignSelf: 'center', display: 'inline' }}>
            <DynamicFontTypography component="span" sx={typographyStyles}>
              {t('Cookie.message1')}{' '}
            </DynamicFontTypography>
            <Box
              component="a"
              href={t('Cookie.url')}
              target="_blank"
              rel="noopener noreferrer"
              sx={{
                textDecoration: 'underline',
                color: 'inherit',
                cursor: 'pointer',
                '&:hover': { color: 'primary.main' }
              }}>
              <DynamicFontTypography component="span" sx={typographyStyles}>
                {t('Cookie.policy')}
              </DynamicFontTypography>
            </Box>
            <DynamicFontTypography component="span" sx={typographyStyles}>
              {t('Cookie.message2')}
            </DynamicFontTypography>
          </Box>

          {/* Confirm Button */}
          <Button
            onClick={closeBanner}
            variant="contained"
            disableRipple
            sx={{
              bgcolor: '#333333',
              display: 'flex',
              width: '80px',
              height: '40px',
              '&:hover': { backgroundColor: '#009688' }
            }}>
            <DynamicFontTypography
              fontWeight={600}
              sx={{ ...typographyStyles, color: 'white' }}>
              {t('Cookie.button')}
            </DynamicFontTypography>
          </Button>
        </Stack>
      </Paper>
    </Fade>
  );
}
