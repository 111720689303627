import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { fetchPelotonStreamApi } from 'utils/clientApis';
//
import { makeStyles } from '@mui/styles';
import { Box, Button, Typography } from '@mui/material';
import CustomCircularProgress from 'views/Peloton/Common/CustomCircularProgress';
import StreamCard from './StreamCard';
import { PolylineToSVG } from 'utils/polyline';

const useStyles = makeStyles(theme => ({
  root: {},
  title: {
    fontSize: '16px',
    color: theme.palette.text.primary,
    fontWeight: '700',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  seeMoreButton: {
    width: '100%',
    marginBottom: '8px'
  }
}));

const Stream = ({ data }) => {
  const classes = useStyles();
  const { t } = useTranslation(['page']);

  const [streamData, setStreamData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [isThreePagesPer, setIsThreePagesPer] = useState(false);
  const [
    isPossibleExcellentReaction,
    setIsPossibleExcellentReaction
  ] = useState(data.myClubUserResponse?.isPossibleExcellentReaction);
  const [myClubUserId, setMyClubUserId] = useState(data.myClubUserResponse?.id);

  const intersectionObserver = useRef(null);

  // 무한스크롤
  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsFetching(true);
      }
    });

    if (intersectionObserver.current) {
      observer.observe(intersectionObserver.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [intersectionObserver.current]);

  const handleFetchStream = async () => {
    try {
      const response = await fetchPelotonStreamApi(data.id, currentPage);
      if (response.data.success) {
        setStreamData(prev => [...prev, ...response.data?.response.content]);
        setCurrentPage(prev => prev + 1);
        setHasNextPage(!response.data.response.last);
        setIsFetching(false);
        setIsThreePagesPer(currentPage !== 0 && currentPage % 3 === 0);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (isFetching && hasNextPage && !isThreePagesPer) handleFetchStream();
    else if (!hasNextPage) setIsFetching(false);
  }, [isFetching, isThreePagesPer]);

  return (
    <div className={classes.root}>
      {streamData ? (
        <>
          <Typography className={classes.title}>활동 스트림</Typography>
          <Box>
            {streamData.map(card => {
              return (
                <StreamCard
                  key={card.id}
                  clubId={data?.id}
                  card={card}
                  PolylineToSVG={PolylineToSVG}
                  isPossibleExcellentReaction={isPossibleExcellentReaction}
                  setIsPossibleExcellentReaction={
                    setIsPossibleExcellentReaction
                  }
                  myClubUserId={myClubUserId}
                />
              );
            })}
            {isFetching && !isThreePagesPer ? <CustomCircularProgress /> : null}
            {isFetching && isThreePagesPer ? (
              <Button
                className={classes.seeMoreButton}
                color="primary"
                variant="contained"
                onClick={() => setIsThreePagesPer(false)}>
                더보기
              </Button>
            ) : null}
            <div ref={intersectionObserver}></div>
          </Box>
        </>
      ) : (
        <CustomCircularProgress />
      )}
    </div>
  );
};
export default Stream;
