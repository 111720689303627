import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Button, TextField } from '@mui/material';

const useStyles = makeStyles(theme => ({
  btnWrap: {
    display: 'flex',
    justifyContent: 'end',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('lg')]: {}
  },
  button: {
    width: '30vw',
    [theme.breakpoints.up('md')]: {
      width: '16vw'
    }
  },
  textField: {},
  editButton: {
    width: '30vw',
    [theme.breakpoints.up('md')]: {
      width: '16vw'
    },
    marginRight: '8px'
  },
  input: {
    fontSize: '16px'
  }
}));

function CommentWrite({
  value,
  onEditChange: handleEditChange,
  onButtonClick: handleButtonClick,
  onEditCancel: handleEditCancel,
  type = 'default'
}) {
  const classes = useStyles();
  const { t } = useTranslation(['page']);

  return (
    <form>
      <TextField
        className={classes.textField}
        rows={3}
        multiline
        fullWidth
        name="contents"
        onChange={handleEditChange}
        type="text"
        value={value}
        variant="outlined"
        InputProps={{
          classes: {
            input: classes.input
          }
        }}
      />
      <div className={classes.btnWrap}>
        {type === 'default' && (
          <Button
            className={classes.button}
            color="primary"
            fullWidth
            size="small"
            variant="contained"
            onClick={handleButtonClick}>
            {t('Notice.addComment')}
          </Button>
        )}
        {type === 'edit' && (
          <>
            <Button
              className={classes.editButton}
              color="secondary"
              fullWidth
              size="small"
              variant="contained"
              onClick={handleEditCancel}>
              {t('Notice.cancel')}
            </Button>
            <Button
              className={classes.button}
              color="primary"
              fullWidth
              size="small"
              variant="contained"
              onClick={handleButtonClick}>
              {t('Notice.modifyComment')}
            </Button>
          </>
        )}
      </div>
    </form>
  );
}

export default CommentWrite;
