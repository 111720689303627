import React, { useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import validate from 'validate.js';
import cookie from 'react-cookies';

import './i18n';
import 'react-perfect-scrollbar/dist/css/styles.css';
import validators from './common/validators';
import Routes from './Routes';
import i18n from 'i18next';
import axios from 'axios';
import auth from './utils/auth';

import 'mapbox-gl/dist/mapbox-gl.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import useTheme from './theme';
import { ModeContext } from 'context/modeProvider';

const browserHistory = createBrowserHistory();

if (auth.getToken() !== null) {
  let auth_str = 'Bearer '.concat(auth.getToken().token);

  axios
    .get(
      `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/get-preferences.php`,
      {
        headers: { Authorization: auth_str },
        params: {
          order: 'preferences'
        }
      }
    )
    .then(function(response) {
      const preferences = response.data;
      //    console.log(preferences);
      i18n.changeLanguage(preferences.lang);
    })
    .catch(function(err) {
      console.log(err);
    });
}

validate.validators = {
  ...validate.validators,
  ...validators
};

export default function App() {
  let modeSelect = cookie.load('riduck_mode');

  const [mode, setMode] = useState(modeSelect || 'light');

  const theme = useTheme(mode);

  const handleMode = () => {
    if (mode === 'dark') {
      setMode('light');
      cookie.save('riduck_mode', 'light', { path: '/' });
    } else {
      setMode('dark');
      cookie.save('riduck_mode', 'dark', { path: '/' });
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <ModeContext.Provider value={{ mode, handleMode }}>
          <Router history={browserHistory}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Routes />
            </LocalizationProvider>
          </Router>
        </ModeContext.Provider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
