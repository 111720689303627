import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Box,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    height: '0px',
    '& .MuiCardContent-root': {
      padding: '12px 16px',
      [theme.breakpoints.down('lg')]: {
        maxHeight: '56px',
        padding: '6px 10px'
      }
    },
    '& .MuiTypography-h3': {
      [theme.breakpoints.down('lg')]: {
        lineHeight: '12px'
      }
    }
  },
  category: {
    color: '#8cc1ec',
    marginRight: '12px'
  },
  legendText: {
    marginLeft: '10px',
    paddingTop: '6px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '11px',
      marginLeft: '6px',
      marginTop: '-3px',
      paddingTop: '0px'
    }
  },
  avatar: {
    backgroundColor: '#f5f5f5',
    height: 60,
    width: 60,
    marginTop: '2px',
    [theme.breakpoints.down('lg')]: {
      height: 40,
      width: 40
    }
  },
  icon: {
    height: 40,
    width: 40,
    [theme.breakpoints.down('lg')]: {
      height: 32,
      width: 32
    }
  }
}));

const Dialogs = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { className, extraInfo } = props;
  const [statusDialogOpen, setStatusDialogOpen] = React.useState(false);
  const [selectDialog, setSelectDialog] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('');
  const classes = useStyles();

  //console.log(status + " " + maxHrActivity);
  // console.log(extraInfo);

  let themeColor = { color: '#aaaaaa' };
  let cardText = 0.0;
  let legendText = '';

  function handleDialogClose() {
    setStatusDialogOpen(false);
  }

  let loading = false;

  return (
    <Box className={clsx(classes.root, className)}>
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>{' 이름 ' + t('Dashboard.modifyActivity')}</DialogTitle>
        <Divider />
        <DialogContent>
          {loading === 'loading' ? (
            <React.Fragment>
              <Box
                className={classes.circularProgress}
                style={{
                  minWidth: '300px',
                  padding: '100px 0 100px 0',
                  textAlign: 'center'
                }}>
                <CircularProgress size={64} />
              </Box>
            </React.Fragment>
          ) : (
            <List>
              <ListItem
                className={classes.modifyButton}
                button
                onClick={() => null}
                key={1}>
                <ListItemIcon></ListItemIcon>
                <ListItemText
                  primary={t('Dashboard.stravaUpdate')}
                  secondary={t('Dashboard.stravaUpdateDesc')}
                />
              </ListItem>
            </List>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

Dialogs.propTypes = {
  className: PropTypes.string
};

export default Dialogs;
