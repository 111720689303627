import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { StravaIcon } from 'components/Common';
import { DynamicFontTypography } from 'components/Typography';

const useStyles = makeStyles(theme => ({
  root: {},
  heroSection: {
    width: '100%',
    height: '100%',
    position: 'relative',
    margin: '24px auto',
    direction: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 99
  },
  heroStack: {
    width: '100%',
    display: 'flex',
    position: 'relative',
    zIndex: 99,
    [theme.breakpoints.up('xs')]: {
      height: 'fit-content',
      gap: '24px',
      flexDirection: 'column-reverse',
      alignItems: 'center',
      justifyContent: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      gap: '12px',
      flexDirection: 'row',
      alignItems: 'center'
    },
    [theme.breakpoints.between('740px', 'md')]: {
      height: '100%'
    },
    [theme.breakpoints.up('md')]: {
      height: '100%'
    },
    [theme.breakpoints.up('lg')]: {}
  },
  heroContainer: {
    width: '100%',
    maxWidth: '1200px',
    height: 'min-content',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    [theme.breakpoints.up('xs')]: {
      direction: 'column',
      padding: '0px 24px'
    },
    [theme.breakpoints.up('sm')]: {},
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {
      direction: 'row',
      padding: 0
    },
    [theme.breakpoints.up('xl')]: {}
  },
  heroText: {
    width: '100%',
    height: '100%',
    direction: 'column',
    overflow: 'visible',
    [theme.breakpoints.up('xs')]: {
      gap: '14px',
      justifyContent: 'center',
      alignItems: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      alignItems: 'start'
    },
    [theme.breakpoints.between('740px', 'md')]: {
      height: '100%',
      gap: '12px'
    },
    [theme.breakpoints.up('md')]: {
      gap: '12px'
    },
    [theme.breakpoints.up('lg')]: {
      gap: '10px'
    }
  },
  heroTextTitle: {
    maxWidth: '100%',
    fontWeight: 600,
    fontSize: 'clamp(24px, 4.4vw, 40px)',
    lineHeight: 'clamp(26px, 5.2vw, 48px)',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.up('xs')]: {
      textAlign: 'center'
    },
    [theme.breakpoints.up('sm')]: {
      wordBreak: 'keep-all',
      textAlign: 'start'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
    [theme.breakpoints.up('xl')]: {
      whiteSpace: 'normal'
    }
  },
  heroTextBody: {
    color: theme.palette.primary.main,
    fontSize: 'clamp(16px, 1.3vw, 22px)',
    lineHeight: 'clamp(22px, 1.5vw, 30px)',
    maxHeight: 'clamp(60px, 6.0vw, 100px)',
    [theme.breakpoints.up('xs')]: {
      fontWeight: 300,
      textAlign: 'center',
      whiteSpace: 'pre-wrap'
    },
    [theme.breakpoints.up('sm')]: {
      textAlign: 'start',
      lineHeight: 'min(28px, 3.2vw)'
    },
    [theme.breakpoints.up('md')]: {},
    [theme.breakpoints.up('lg')]: {},
    [theme.breakpoints.up('xl')]: {
      whiteSpace: 'normal'
    }
  },
  heroImage: {
    [theme.breakpoints.up('xs')]: {
      width: '110%',
      height: 'auto',
      alignSelf: 'flex-end'
    },
    [theme.breakpoints.up('sm')]: {
      width: '300px',
      height: 'auto',
      alignSelf: 'center'
    },
    [theme.breakpoints.between(700, 'md')]: {
      width: '400px',
      height: 'auto'
    },
    [theme.breakpoints.up('md')]: {
      width: '500px',
      height: '500px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '700px',
      height: '700px'
    }
  }
}));

function HeroTextContent() {
  const { t } = useTranslation('landing');
  const classes = useStyles();

  return (
    <Stack className={classes.heroText}>
      <DynamicFontTypography component="div" className={classes.heroTextTitle}>
        {t('Hero.message1')}
      </DynamicFontTypography>
      <DynamicFontTypography className={classes.heroTextBody}>
        {t('Hero.message2')}
      </DynamicFontTypography>
      <Box>
        <StravaIcon />
      </Box>
    </Stack>
  );
}

export default function Hero() {
  const classes = useStyles();

  return (
    <Stack id="hero" className={classes.heroSection} component="section">
      <Stack className={classes.heroContainer}>
        <Stack className={classes.heroStack}>
          {/* Hero Text */}
          <HeroTextContent />
          {/* Hero Image */}
          <Box
            className={classes.heroImage}
            component="img"
            src="/images/landing-hero.png"
          />
        </Stack>
      </Stack>
    </Stack>
  );
}
