import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  contentText: {
    margin: 0
  }
}));

export default function SimpleDialog({ openState, setOpen, contentText }) {
  const classes = useStyles();
  const { t } = useTranslation(['page']);

  return (
    <>
      {openState && (
        <Dialog
          open={openState}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              text="body1"
              color="textPrimary"
              className={classes.contentText}>
              {contentText}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpen(false)}
              size="medium"
              state="enabled"
              variant="text"
              color="primary">
              {t('BikeUI.close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
