import React, { useState, useEffect } from 'react';

import validate from 'validate.js';
import { useTranslation } from 'react-i18next';
import {
  AddPostParentCommentApi,
  fetchPostApi,
  fetchPostListApi,
  fetchPostCommentsApi
} from 'utils/clientApis';
import { makeStyles, useTheme } from '@mui/styles';
import {
  Avatar,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import Comment from './Comment';

const schema = {
  comment: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 500,
      minimum: 3
    }
  }
};

const useStyles = makeStyles(theme => ({
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  }
}));

export default function NoticeBoard({ postId, openNotice, handleCloseNotice }) {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
  const { t } = useTranslation(['page']);

  const [noticeData, setNoticeData] = useState();
  const [noticeCommentData, setNoticeCommentData] = useState();

  const [commentState, setCommentState] = useState({
    data: {
      contents: ''
    },
    errors: {},
    touched: {},
    isValid: false
  });

  useEffect(() => {
    handleGetNotice();
  }, []);

  useEffect(() => {
    const errors = validate(commentState.data, schema);
    setCommentState(prevState => ({
      ...prevState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [commentState.data]);

  // 공지글 코멘트 조회를 위한 핸들러
  const handleGetNotiComment = async postId => {
    try {
      const response = await fetchPostCommentsApi(postId);
      if (response) {
        setNoticeCommentData(response.data.response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // 공지글 조회를 위한 핸들러
  const handleGetNotice = async () => {
    try {
      if (postId === 'latest') {
        const response = await fetchPostListApi(1); // 공지글은 1번 카테고리
        if (response) {
          // 펠로톤 하드코딩
          setNoticeData(response.data.response.content[1]);
          handleGetNotiComment(response.data.response.content[1].id);
        }
      } else {
        const response = await fetchPostApi(postId);
        if (response) {
          setNoticeData(response.data.response);
          handleGetNotiComment(postId);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // 공지글 코멘트 생성 핸들러
  const handlePostNotiComment = async () => {
    try {
      if (noticeData) {
        const response = await AddPostParentCommentApi(
          noticeData.id,
          commentState.data
        );
        if (response) {
          setCommentState({
            data: {
              contents: ''
            }
          });
          handleGetNotiComment(noticeData.id);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCommentChange = event => {
    event.persist();
    const { name, value } = event.target;

    setCommentState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [name]: value
      },
      touched: {
        ...prevState.touched,
        [name]: true
      }
    }));
  };

  return <>
    <Dialog
      open={openNotice}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
      fullScreen={fullScreen}
      scroll="body">
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            backgroundColor: 'white',
            zIndex: '999',
            width: '100%',
            padding: '0px 8px',
            marginLeft: '-8px'
          }}>
          <DialogTitle text="h6">
            <Typography color="primary" style={{ fontWeight: '700' }}>
              {t('Notice.notice')}
            </Typography>
          </DialogTitle>
          <CloseIcon
            onClick={() => handleCloseNotice()}
            style={{ cursor: 'pointer' }}
          />
        </div>
        <Divider />
        <div className={classes.whiteLine} />
        <DialogContent
          alt="post"
          style={{ padding: '24px 24px', marginBottom: '24px' }}>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginBottom: '16px'
            }}>
            <Avatar
              src={'/images/logo_duck.png'}
              variant="circular"
              style={{
                width: '40px',
                height: '40px',
                marginRight: '8px'
              }}
            />
            <Typography color="primary" style={{ fontWeight: '700' }}>
              {t('Notice.riduck')}
            </Typography>
          </Box>
          {noticeData && (
            <>
              <Typography variant="h4">{noticeData.title}</Typography>
              <Box style={{ padding: '8px 0px' }}>
                <Typography variant="body1" color="textPrimary">
                  {noticeData.content?.split('\n').map((paragraph, index) => (
                    <React.Fragment key={index}>
                      {paragraph}
                      <br />
                    </React.Fragment>
                  ))}
                </Typography>
              </Box>
            </>
          )}
        </DialogContent>
        <Divider />
        {noticeCommentData &&
          noticeCommentData.map(comment => {
            return (
              <Comment
                key={comment.id}
                comment={comment}
                postId={noticeData.id}
                handleGetComments={() => handleGetNotiComment(noticeData.id)}
              />
            );
          })}
        <DialogContent
          alt="writeComment"
          style={{ padding: '16px 16px', marginBottom: '16px' }}>
          <TextField
            fullWidth
            label={t('Notice.writeComment')}
            name="contents"
            type="text"
            multiline
            rows={3}
            onChange={handleCommentChange}
            value={commentState.data.contents}
            variant="outlined"
          />
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={handlePostNotiComment}>
              {t('Notice.addComment')}
            </Button>
          </DialogActions>
        </DialogContent>
      </>
    </Dialog>
  </>;
}
