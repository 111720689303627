import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  contentBox: {
    padding: '20px 24px 0'
  }
}));

const ExcellentDoneDialog = ({ isOpen, handleCloseDialog }) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <>
        <DialogContent className={classes.contentBox}>
          <DialogContentText
            id="alert-dialog-description"
            text="body1"
            color="textPrimary">
            찬사를 이미 사용하셨습니다!
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            text="small"
            color="textSecondary"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleCloseDialog();
            }}
            size="medium"
            state="enabled"
            variant="text"
            color="primary">
            닫기
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default ExcellentDoneDialog;
