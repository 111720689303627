import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import validate from 'validate.js';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import auth from '../../utils/auth';
import request from '../../utils/request';

import { makeStyles } from '@mui/styles';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Tabs,
  Tab,
  Grid,
  Popover,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Typography
} from '@mui/material';

const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 32
    }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  },
  // invitecode: {
  //   presence: { allowEmpty: false, message: 'is required' },
  //   length: {
  //     maximum: 10
  //   }
  // },
  policy: {
    presence: { allowEmpty: false, message: 'is required' },
    checked: true
  }
};

const helperTextStyles = makeStyles(theme => ({
  root: {
    margin: 4,
    color: '#F06292',
    fontSize: 12
  },
  error: {
    '&.MuiFormHelperText-root.Mui-error': {
      color: '#F06292'
    }
  }
}));

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: '100%'
  },
  gridContainer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: '100%'
  },
  grid: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    height: '100%'
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: '4px 4px 0 0'
  },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    },
    flexBasis: 500
  },
  form: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    color: '#009586',
    fontSize: '20px',
    fontWeight: 700,
    textAlign: 'center',
    marginTop: '12px',
    marginBottom: '16px'
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  policy: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  policyCheckbox: {
    marginLeft: '-14px'
  },
  signUpButton: {
    margin: theme.spacing(2, 0)
  },
  popoverWrap: {},
  popoverText: {
    padding: theme.spacing(2),
    backgroundColor: '#444444',
    color: '#ffffff'
  },
  inviteBox: {
    background: theme.palette.background.colored.light,
    border: '1px #dddddd solid',
    borderRadius: '4px',
    textAlign: 'center',
    margin: '12px 0 0 0',
    padding: '12px'
  },
  desc: {
    fontSize: 14,
    textAlign: 'left',
    display: 'list-item',
    marginLeft: 20
  },
  invitecode: {
    marginTop: '0px',
    background: '#ffffff'
  },
  divider: {
    margin: '12px 0'
  }
}));

const SignUp = props => {
  //초대코드
  const hasInviteCode = props.location.search;

  const classes = useStyles();
  const helperTextClasses = helperTextStyles();

  //다국어적용
  const { t } = useTranslation(['page']);

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const [popoverOpenInvite, setPopoverOpenInvite] = React.useState(false);
  const [popoverOpenEmail, setPopoverOpenEmail] = React.useState(false);
  const [popoverOpenId, setPopoverOpenId] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    if (hasInviteCode.length === 11) {
      formState.values.invitecode = hasInviteCode.substr(1);
    }

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverOpenInvite(false);
    setPopoverOpenEmail(false);
    setPopoverOpenId(false);
  };

  const afterLogin = req => {
    const requestURL = `${process.env.REACT_APP_RESTAPI_BASE_URL}/api/aam/v2/authenticate`;

    request(requestURL, { method: 'POST', body: req })
      .then(response => {
        auth.setToken(response.jwt, true);
        auth.setUserInfo(response.user.data, true);
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleSignUp = event => {
    event.persist();
    event.preventDefault();

    setAnchorEl(event.currentTarget);

    const req = formState.values;
    const requestURL = `${process.env.REACT_APP_RESTAPI_BASE_URL}/api/wp/v2/users/register`;
    //rest wp-content/plugins/wp-rest-user/public/class-wp-rest-user-public.php

    request(requestURL, { method: 'POST', body: req })
      .then(response => {
        //				console.log(response);
        if (response.code === 397) {
          setPopoverOpenInvite(true);
        } else if (response.code === 398) {
          setPopoverOpenEmail(true);
        } else if (response.code === 399) {
          setPopoverOpenId(true);
        } else {
          req.issueJWT = true;
          afterLogin(req);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleClickSignOut = event => {
    event.preventDefault();
    auth.clearToken();
    window.location.reload();
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;
  const hasPasswordNotSameError = () =>
    formState.values.password !== formState.values.confirmPassword
      ? true
      : false;

  return (
    <div className={classes.root}>
      {auth.getToken() !== null ? (
        <div>
          <Button
            className={classes.signOutButton}
            color="secondary"
            fullWidth
            size="large"
            variant="contained"
            onClick={handleClickSignOut}>
            Log-Out
          </Button>
        </div>
      ) : (
        <React.Fragment>
          <AppBar position="static" color="default" className={classes.tab}>
            <Tabs
              value={0}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example">
              <Tab label={t('SignUp.signup')} />
            </Tabs>
          </AppBar>

          <Grid
            className={classes.gridContainer}
            container
            justifyContent="center"
            alignItems="center">
            <Grid className={classes.content} item>
              <div className={classes.content}>
                <div className={classes.contentBody}>
                  <form className={classes.form} onSubmit={handleSignUp}>
                    <Typography className={classes.title} variant="h5">
                      {t('SignUp.betaTesting')}
                    </Typography>

                    <Box className={classes.inviteBox}>
                      <Typography component="div" className={classes.desc}>
                        {t('SignUp.betaTestingDesc1')}
                      </Typography>
                      <Typography component="div" className={classes.desc}>
                        {t('SignUp.betaTestingDesc2')}
                      </Typography>

                      {/* <Divider className={classes.divider} />
                  <TextField
                    className={classes.invitecode}
                    error={hasError('invitecode')}
                    helperText={
                      hasError('invitecode') ? formState.errors.invitecode[0] : null
                    }
                    label={t('SignUp.inviteCode')}
                    name="invitecode"
                    onChange={handleChange}
                    type="text"
                    size="small"
                    value={formState.values.invitecode || ''}
                    variant="outlined"
                  /> */}
                    </Box>

                    <TextField
                      className={classes.textField}
                      error={hasError('username')}
                      fullWidth
                      helperText={
                        hasError('username')
                          ? formState.errors.username[0]
                          : null
                      }
                      label={t('SignUp.id')}
                      name="username"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.username || ''}
                      variant="outlined"
                    />

                    <TextField
                      className={classes.textField}
                      error={hasError('display_name')}
                      fullWidth
                      helperText={
                        hasError('display_name')
                          ? formState.errors.display_name[0]
                          : null
                      }
                      label={t('SignUp.nickname')}
                      name="display_name"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.display_name || ''}
                      variant="outlined"
                    />

                    <TextField
                      className={classes.textField}
                      error={hasError('email')}
                      fullWidth
                      helperText={
                        hasError('email') ? formState.errors.email[0] : null
                      }
                      label={t('SignUp.email')}
                      name="email"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.email || ''}
                      variant="outlined"
                    />

                    <TextField
                      className={classes.textField}
                      error={hasError('password')}
                      fullWidth
                      helperText={
                        hasError('password')
                          ? formState.errors.password[0]
                          : null
                      }
                      label={t('SignUp.password')}
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.password || ''}
                      variant="outlined"
                    />
                    <TextField
                      className={classes.textField}
                      error={hasPasswordNotSameError()}
                      fullWidth
                      helperText={
                        hasPasswordNotSameError()
                          ? t('SignUp.errorPasswordNotSame')
                          : null
                      }
                      label={t('SignUp.confirmPassword')}
                      name="confirmPassword"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.confirmPassword || ''}
                      variant="outlined"
                      FormHelperTextProps={{ classes: helperTextClasses }}
                    />

                    <div className={classes.policy}>
                      <Checkbox
                        checked={formState.values.policy || false}
                        className={classes.policyCheckbox}
                        color="primary"
                        name="policy"
                        onChange={handleChange}
                      />
                      <Typography
                        className={classes.policyText}
                        color="textSecondary"
                        variant="body1">
                        {t('SignUp.agree')}{' '}
                        <Link
                          color="primary"
                          underline="always"
                          variant="h6"
                          href={`${process.env.REACT_APP_RESTAPI_BASE_URL}/personal.html`}
                          target="_blank">
                          {t('SignUp.term')}
                        </Link>
                      </Typography>
                    </div>

                    {hasError('policy') && (
                      <FormHelperText error>
                        {formState.errors.policy[0]}
                      </FormHelperText>
                    )}

                    <Button
                      className={classes.signUpButton}
                      color="primary"
                      disabled={!formState.isValid}
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained">
                      {t('SignUp.signup')}
                    </Button>

                    <Popover
                      className={classes.popoverWrap}
                      open={popoverOpenEmail}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}>
                      <Typography className={classes.popoverText}>
                        {t('SignUp.ErrorEmail')}
                      </Typography>
                    </Popover>

                    <Popover
                      className={classes.popoverWrap}
                      open={popoverOpenId}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}>
                      <Typography className={classes.popoverText}>
                        {t('SignUp.ErrorId')}
                      </Typography>
                    </Popover>

                    <Popover
                      className={classes.popoverWrap}
                      open={popoverOpenInvite}
                      anchorEl={anchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}>
                      <Typography className={classes.popoverText}>
                        {t('SignUp.ErrorInvite')}
                      </Typography>
                    </Popover>

                    <Typography color="textSecondary" variant="body1">
                      {t('SignUp.already')}{' '}
                      <Link component={RouterLink} to="/sign-in" variant="h6">
                        {t('SignUp.login')}
                      </Link>
                    </Typography>
                  </form>
                </div>
              </div>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </div>
  );
};

SignUp.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignUp);
