import React from 'react';
import { Box } from '@mui/material';

export default function StravaIcon() {
  return (
    <Box
      display="flex"
      component="img"
      src="/images/strava.png"
      alt="Strava image"
      width="120px"
      marginX="auto"
    />
  );
}
