import * as React from 'react';
import {
  applyEventApi,
  cancelEventApi,
  forceCancelEventApi
} from 'utils/clientApis';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText
} from '@mui/material';
import { makeStyles } from '@mui/styles';

export default function EventModal({
  selectedLabel,
  openState,
  setOpen,
  uriName,
  eventId
}) {
  const classes = useStyles();

  const handleJoinSession = async () => {
    try {
      if (selectedLabel && selectedLabel === 'join') {
        const response = await applyEventApi(eventId);
        if (response) {
          setOpen(false);
          window.location.href = `/peloton/${uriName}/event/${eventId}`;
        }
      }
      if (selectedLabel && selectedLabel === 'cancel') {
        const response = await cancelEventApi(eventId);
        if (response) {
          setOpen(false);
          window.location.href = `/peloton/${uriName}/event/${eventId}`;
        }
      }
      if (selectedLabel && selectedLabel === 'cancelEvent') {
        const response = await forceCancelEventApi(eventId);
        if (response) {
          setOpen(false);
          window.location.href = `/peloton/${uriName}/event/${eventId}`;
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      {openState && selectedLabel && (
        <Box>
          {selectedLabel === 'join' && (
            <Dialog
              open={openState}
              // onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="body1"
                    color="textPrimary">
                    {`이벤트에 참가 하시겠습니까?`}
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleJoinSession}
                    Size="medium"
                    State="enabled"
                    variant="text"
                    color="primary">
                    신청하기
                  </Button>
                  <Button
                    onClick={() => setOpen(false)}
                    Size="medium"
                    State="enabled"
                    variant="text"
                    color="secondary">
                    취소
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
          {selectedLabel === 'cancel' && (
            <Dialog
              open={openState}
              // onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="body1"
                    color="textPrimary">
                    {`참가 신청을 취소 하시겠습니까?`}
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleJoinSession}
                    Size="medium"
                    State="enabled"
                    variant="text"
                    color="secondary">
                    참가 취소하기
                  </Button>
                  <Button
                    onClick={() => setOpen(false)}
                    Size="medium"
                    State="enabled"
                    variant="text"
                    color="primary">
                    닫기
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
          {selectedLabel === 'cancelEvent' && (
            <Dialog
              open={openState}
              // onClose={() => setOpen(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <>
                <DialogTitle id="alert-dialog-title" text="h5"></DialogTitle>
                <DialogContent>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="body1"
                    color="textPrimary">
                    {`개설된 이벤트를 취소 하시겠습니까?`}
                  </DialogContentText>
                  <DialogContentText
                    id="alert-dialog-description"
                    text="small"
                    color="textSecondary"></DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleJoinSession}
                    Size="medium"
                    State="enabled"
                    variant="text"
                    color="secondary">
                    이벤트 취소하기(폭파)
                  </Button>
                  <Button
                    onClick={() => setOpen(false)}
                    Size="medium"
                    State="enabled"
                    variant="text"
                    color="primary">
                    닫기
                  </Button>
                </DialogActions>
              </>
            </Dialog>
          )}
        </Box>
      )}
    </>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    //    padding: theme.spacing(2)
  }
}));
