import React, { useState, useEffect } from 'react';
import MapGL, {
  Source,
  Layer,
  NavigationControl,
  GeolocateControl,
  Marker
} from 'react-map-gl';

import RoomIcon from '@mui/icons-material/Room'; // 이 예시에서 마커 아이콘으로 사용합니다.

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

function CourseMap({ geoJson, initialMarker }) {
  const [viewport, setViewport] = useState();

  useEffect(() => {
    if (!geoJson || !geoJson.features || geoJson.features.length === 0) return;

    // GeoJSON의 첫 번째 포인트를 중심점으로 사용
    const firstFeature = geoJson.features[0];
    const [longitude, latitude] = firstFeature.geometry.coordinates[0];

    // 이 부분에서는 추가적인 로직을 통해 줌 레벨을 계산해야 할 수 있음
    const newViewport = {
      ...viewport,
      latitude,
      longitude,
      zoom: 10 // 예시 줌 레벨, 실제 적용 시 적절한 값으로 조정 필요
    };

    setViewport(newViewport);
  }, [geoJson]);

  const layerStyle = {
    id: 'Afreeca',
    type: 'line',
    paint: {
      'line-color': '#F06292',
      'line-width': 5,
      'line-opacity': 0.9
    },
    'line-cap': 'round',
    visibility: 'visible'
  };

  return (
    <div style={{ borderRadius: '8px', overflow: 'hidden' }}>
      <MapGL
        {...viewport}
        width="100%"
        height="33vh"
        mapStyle="mapbox://styles/mapbox/streets-v12"
        onViewportChange={setViewport}
        mapboxApiAccessToken={MAPBOX_TOKEN}>
        <Source id="my-data" type="geojson" data={geoJson}>
          <Layer {...layerStyle} />
        </Source>

        {initialMarker && (
          <Marker
            latitude={initialMarker.latitude}
            longitude={initialMarker.longitude}>
            <RoomIcon
              style={{ color: '#F06292', transform: 'translate(-50%, -100%)' }}
            />
          </Marker>
        )}

        <div className="navi-control">
          <NavigationControl
            style={{ position: 'absolute', top: '6px', left: '6px' }}
          />
        </div>
        <div className="current-location-control">
          <GeolocateControl
            style={{ position: 'absolute', top: '6px', right: '6px' }}
          />
        </div>
      </MapGL>
    </div>
  );
}

export default CourseMap;
