//import React from 'react';
//import uuid from 'uuid/v1';
//#f06292 f06292
//8cc1ec 4db6ac
//76b5e8 d1e6f7

export default {
  init : {
    chart: {
      animations: {
        enabled: false,
      }, 
      height: '100%',
      offsetY: 0,
      offsetX: 0,
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 0,
        left: 0,
        blur: 5,
        opacity: 0.2
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      },
      events: {
        markerClick: null
      }
    },
    dataLabels: {
      enabled: false,
    },	
    colors: ['#BCDBF3','#4db6ac','#f8bbd0','#f06292'],
    stroke: {
        width: [2,2,0,2],
        curve: ['stepline','straight','straight','straight']
    },
    grid: {
      borderColor: '#f5f5f5',
      row: {
        colors: ['#ffffff', 'transparent', 'transparent', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.0
      },
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', 
        },
        columnWidth: "99%",
      }
    },
    markers: {
      size: [1,1,0,4],
      strokeWidth: [0,0,0,2],
      hover: {
        size: 6
      },
/*      
      onClick: function(e, v){
        console.log(e);
        console.log(v);
      },
*/      
    },
    xaxis: {
      categories: ['1s','5s','15s','30s','1m','2m','5m','10m','20m','40m','1h'],
      labels: {
        show: false,
        hideOverlappingLabels: true,
        offsetX: -1,
        style: {
          fontSize: '11px',
          colors: '#999999',
          cssClass: 'xaxisLabel',
        },
      },
      tooltip:{
        enabled : false
      }
    },
    yaxis: [
      {
        seriesName: 'percent',
        min: 0,
        max: 100,
        opposite: false,
        axisBorder: {
          show: false,
          color: '#8cc1ec'
        },
        labels: {
          show: true,
          minWidth: 25,
          maxWidth: 50,
          style: {
            fontSize: '11px',
            colors: '#b8d8f3',
          },
          offsetX: -5,
          formatter: function(val) {
            return val + "%";
          },
        },
      },
      {
        seriesName: 'power',
        min: 0,
        max: 1000,
        tickAmount: 10,
        axisBorder: {
          show: true,
          color: '#f06292'
        },
        labels: {
          show: true,
          minWidth: 25,
          maxWidth: 50, 
          style: {
            colors: '#f06292',
          },
          formatter: function(val) {            
            if(val > 50) {
              return val + "w";
            } else {
              return val + ".0";           
            }
          },
        },	
      },  
      {
        seriesName: 'stereo',
        show: false,
        min: 0,
        max: 1000,
        tickAmount: 10,
        axisBorder: {
          show: false,
        },        
        labels: {
          show: false,
        },	
      },							
      {
        seriesName: 'outdoor',
        show: false,
        min: 0,
        max: 1000,
        tickAmount: 10,
        axisBorder: {
          show: false,
        },        
        labels: {
          show: false,
        },	
      },							
    ],
    annotations: {
      yaxis: [
        {
          yAxisIndex: 1,
          y: 0,
          opacity: 0.5,
          borderColor: '#ffffff',
          strokeDashArray: 2,
          label: {
            text: 'FTP',
            borderColor: '#ffffff',
            position: 'left',
            borderWidth: 1,
            borderRadius: 2,
            offsetX: 52,
            offsetY: 18,
            style: {
                background: '#ffffff',
                fontSize: '11px',
                color: '#f06292',
            },
          }
        }
      ],
      xaxis: [
        {
          x: '1시',
          offsetX: 2,
          borderColor: '#ffd357',
          opacity: 0.0,
          strokeDashArray: 2,
          label: {
            offsetX: 0,
            offsetY: -6,
            borderWidth: 0,
            style: {
              background: "#ffd357",
              opacity: 0.5,
              fontSize: '11px',
              color: "#ffffff",
              cssClass: 'xaxisAnnotationLabel',
            },
            text: "Forte"
          }
        }
      ]
    },
    legend: {
      fontSize: '11px',
      itemMargin: {
        horizontal: 5,
        vertical: 0
      },
      onItemClick: {
        toggleDataSeries: false
      }
    },
    fill: {
      colors: ['#8cc1ec', '#4db6ac', '#f491b2', '#f06292'],
      type: 'solid',
      opacity : [0.5, 0.2, 0.8, 0.2],
    },
    tooltip : {
      y: [{
        formatter: function(val) {
          return (100-val).toFixed(1) + "%";
        },
      }, {
        formatter: function(val) {
          if(val > 50) {
            return val + " w";
          } else {
            return val + " w/kg";           
          }
        },
      }, {
        formatter: function(val) {
          if(val > 50) {
            return val + " w";
          } else {
            return val + " w/kg";           
          }
        },
      }, {
        formatter: function(val) {
          if(val > 50) {
            return val + " w";
          } else {
            return val + " w/kg";           
          }
        },
      }]
    },
  },

  activity : {
    chart: {
      animations: {
        enabled: false,
      }, 
      width: '110%',
      height: '100%',
      offsetY: 0,
      offsetX: 0,
      dropShadow: {
        enabled: false,
        color: '#000',
        top: 0,
        left: 0,
        blur: 5,
        opacity: 0.2
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      }
    },
    dataLabels: {
      enabled: false,
    },	
    colors: ['#f06292', '#4db6ac'],
    stroke: {
        width: [3,3],
        curve: 'straight'
    },
    grid: {
      borderColor: '#f5f5f5',
      row: {
        colors: ['#ffffff', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.0
      },
    },
    markers: {
      size: 4,
      hover: {
        size: 5 
      }
    },
    xaxis: {
      categories: ['1s','5s','15s','30s','1m','2m','5m','10m','20m','40m','1h'],
      labels: {
        show: false,
        hideOverlappingLabels: true,
        style: {
          fontSize: '11px',
          colors: '#999999',
          cssClass: 'xaxisLabel',
        },
      },
      tooltip:{
        enabled : false
      }
    },
    yaxis: [
      {
        seriesName: 'power',
        min: 0,
        max: 1000,
        tickAmount: 10,
        axisBorder: {
          show: true,
          color: '#4db6ac',
        },        
        labels: {
          show: true,
          minWidth: 0,
          maxWidth: 0,
          offsetX: -5,
          style: {
            fontSize: '11px',
            colors: '#4db6ac',
          },
          formatter: function(val) {
            if(val > 50) {
              return val + "w";
            } else {
              return val + ".0";
            }
          },
        },	
      },					
      {
        seriesName: 'power2',
        show: false,
        min: 0,
        max: 1000,
        tickAmount: 10,
        axisBorder: {
          show: false,
        },        
        labels: {
          show: false,
        },	
      },					
    ],
    annotations: {
      xaxis: [{}
      ],
      yaxis: [
        {
          yAxisIndex: 1,
          y: 0,
          opacity: 0.5,
          borderColor: '#ffffff',
          strokeDashArray: 2,
          label: {
            text: 'FTP',
            borderColor: '#ffffff',
            position: 'left',
            borderWidth: 1,
            borderRadius: 2,
            offsetX: 52,
            offsetY: 18,
            style: {
                background: '#ffffff',
                fontSize: '11px',
                color: '#4db6ac',
            },
          }
        }
      ],
    },
    legend: {
      fontSize: '11px',
      itemMargin: {
        horizontal: 5,
        vertical: 0
      },
      onItemClick: {
        toggleDataSeries: false
      }
    },
    fill: {
      colors: ['#f06292','#4db6ac'],
      type: 'solid',
      opacity : [0.2, 0.6],
    },
    tooltip : {
      y: [{
        formatter: function(val) {
          if(val > 50) {
            return val + " w";
          } else {
            return val + " w/kg";           
          }
        },
      },
      {
        formatter: function(val) {
          if(val > 50) {
            return val + " w";
          } else {
            return val + " w/kg";           
          }
        },
      }
      ]
    },
  }

};
