import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Typography,
  Link
} from '@mui/material';

// icon
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

import { useTranslation } from 'react-i18next';
import auth from '../../../../utils/auth';

const useStyles = makeStyles({
  root: {},
  cardWrapper: {
    margin: '0 auto'
  },
  cardHeader: {},
  box: {
    textAlign: 'center',
    padding: '16px 8px',
    justifyContent: 'space-around'
  },
  connectDesc: {
    fontSize: '13px',
    padding: '4px 12px',
    '& svg': {
      fontSize: '8px',
      marginRight: '8px',
      color: '#666666'
    }
  },
  connectDescTitle: {
    fontSize: '16px',
    fontWeight: '700'
  },
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  spaceAround: {
    justifyContent: 'space-around'
  },
  stravaImage: {
    width: '200px'
  }
});

const Initialization = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { extraInfo, StravaId } = props;
  const [stravaConnectDialogOpen, setStravaConnectDialogOpen] = React.useState(
    false
  );

  function checkIsWebView() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS 웹뷰 판단
    const isIOSWebView =
      /iPhone|iPod|iPad/i.test(userAgent) &&
      !/Safari/i.test(userAgent) &&
      !/CriOS/i.test(userAgent);

    // Android 웹뷰 판단
    const isAndroidWebView =
      (/Android/i.test(userAgent) && /wv/.test(userAgent)) ||
      /; wv\)/.test(userAgent);

    // 기타 조건으로 WebView 감지 (예: WebView 앱에서 JS 인터페이스 삽입 여부 등)
    const hasWebViewBridge =
      typeof window.ReactNativeWebView !== 'undefined' ||
      typeof window.webkit?.messageHandlers !== 'undefined';

    return isIOSWebView || isAndroidWebView || hasWebViewBridge;
  }

  const isWebView = checkIsWebView();

  console.log(isWebView);

  const classes = useStyles();

  useEffect(() => {
    if (StravaId > 0 && extraInfo.pretest == 2) {
      auth.clearExtraInfo();
      /*
      setTimeout(() => {
			}, 1000);
*/
    }
  }, [StravaId]);

  const handleStravaDialogOpen = () => {
    setStravaConnectDialogOpen(true);
  };

  const handleCloseDialog = event => {
    setStravaConnectDialogOpen(false);
    console.log('dialog close');
  };

  const handleConnectStrava = event => {
    //연동취소->연동
    if (Number(extraInfo.strava_api) === 18928) {
      window.location =
        'https://www.strava.com/oauth/authorize?client_id=18928&response_type=code&redirect_uri=https://' +
        window.location.hostname +
        '/settings/?approval_prompt=force&scope=activity:read_all,activity:write';
      console.log('connect');
    } else {
      window.location =
        'https://www.strava.com/oauth/authorize?client_id=39781&response_type=code&redirect_uri=https://' +
        window.location.hostname +
        '/settings/?approval_prompt=force&scope=activity:read_all,activity:write';
      console.log('connect');
    }
  };

  return (
    <React.Fragment>
      <Card className={classes.cardWrapper}>
        <CardHeader title="Loading Ride Data" className={classes.cardHeader} />
        <Divider />
        <CardContent style={{ padding: '0px', margin: 'auto', maxWidth: 700 }}>
          <Box className={classes.box}>
            <List>
              <ListItem className={classes.connectDesc}>
                <FiberManualRecordIcon />
                {t('Initialization.initialConnectDesc1')}
              </ListItem>
              <ListItem className={classes.connectDesc}>
                <FiberManualRecordIcon />
                {t('Initialization.initialConnectDesc2')}
              </ListItem>
            </List>
          </Box>
          <Box className={classes.box}>
            <Typography className={classes.connectDescTitle}>
              {t('Initialization.initialConnectCaution')}
            </Typography>
            <List>
              <ListItem className={classes.connectDesc}>
                <FiberManualRecordIcon />
                {t('Initialization.initialConnectCautionDesc1')}
              </ListItem>
              <ListItem className={classes.connectDesc}>
                <FiberManualRecordIcon />
                {t('Initialization.initialConnectCautionDesc2')}
              </ListItem>
              <ListItem className={classes.connectDesc}>
                <FiberManualRecordIcon />
                {t('Initialization.initialConnectCautionDesc3')}
              </ListItem>
              <ListItem className={classes.connectDesc}>
                <FiberManualRecordIcon />
                {t('Initialization.initialConnectCautionDesc4')}
              </ListItem>
              <ListItem className={classes.connectDesc}>
                <FiberManualRecordIcon />
                {t('Initialization.initialConnectCautionDesc5')}
              </ListItem>
              <ListItem className={classes.connectDesc}>
                <FiberManualRecordIcon />
                {t('Initialization.initialConnectCautionDesc6')}
              </ListItem>
              <ListItem className={classes.connectDesc}>
                <FiberManualRecordIcon />
                {t('Initialization.initialConnectCautionDesc7')}
              </ListItem>
            </List>
          </Box>
          <Box className={classes.box}>
            {isWebView ? (
              <Box
                sx={{
                  p: 2,
                  border: '1px solid #eee',
                  borderRadius: 2,
                  backgroundColor: '#fff8f8',
                  maxWidth: 480,
                  margin: '0 auto'
                }}>
                <Typography
                  sx={{
                    fontSize: '15px',
                    color: '#d32f2f',
                    fontWeight: 500,
                    mb: 1
                  }}>
                  {t('Initialization.stravaConnectWebviewWarning')}
                </Typography>

                <Link
                  href="https://riduck.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  underline="always"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#4db6ac'
                  }}>
                  {t('Initialization.moveToWebsite')}
                </Link>
              </Box>
            ) : (
              <Button onClick={handleStravaDialogOpen} disabled={isWebView}>
                <img
                  alt="Logo"
                  src="/images/logos/strava_connect.png"
                  className={classes.stravaImage}
                />
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>

      <Dialog open={stravaConnectDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>{t('Initialization.connectWithStrava')}</DialogTitle>
        <DialogContent>
          <Typography className={classes.connectDesc}>
            {t('Initialization.connectWithStravaNotice')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} variant="contained">
            {t('Initialization.close')}
          </Button>
          <Button
            onClick={handleConnectStrava}
            variant="contained"
            color="primary"
            style={{ margin: '16px 16px 16px 24px' }}
            autoFocus>
            {t('Initialization.connect')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

Initialization.propTypes = {
  className: PropTypes.string
};

export default Initialization;
