import React from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';

const useStyles = makeStyles(theme => ({
  contentBox: {
    padding: '20px 24px 0'
  }
}));

const ExcellentExplainDialog = ({
  isOpen,
  handleCloseDialog,
  handleAddExcellent
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <>
        <DialogContent className={classes.contentBox}>
          <DialogContentText
            id="alert-dialog-description"
            text="body1"
            color="textPrimary">
            찬사는 하루에 한번 사용 가능합니다. <br />
            찬사를 보내시겠습니까?
          </DialogContentText>
          <DialogContentText
            id="alert-dialog-description"
            text="small"
            color="textSecondary"></DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleAddExcellent();
              handleCloseDialog();
            }}
            size="medium"
            state="enabled"
            variant="text"
            color="primary">
            찬사
          </Button>
          <Button
            onClick={handleCloseDialog}
            size="medium"
            state="enabled"
            variant="text"
            color="secondary">
            취소
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
};

export default ExcellentExplainDialog;
