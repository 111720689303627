import React from 'react';

import { makeStyles } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

const NoData = ({ title }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <NewReleasesIcon color="primary" className={classes.icon} />
      <Typography color="textPrimary" variant="h6" className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    padding: '8px',
    height: '250px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  icon: { marginBottom: '8px', fontSize: '50px' },
  title: { marginBottom: '16px' }
}));

export default NoData;
