import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { getFontForText } from './fontDetector';

// Utility to group consecutive characters by font
const splitTextByFont = input => {
  const text =
    typeof input === 'string'
      ? input
      : React.Children.toArray(input)
          .filter(child => typeof child === 'string')
          .join('');

  if (!text) return [];

  const chunks = [];
  let currentFont = getFontForText(text[0]);
  let currentText = text[0];

  for (let i = 1; i < text.length; i++) {
    const char = text[i];
    const font = getFontForText(char);

    if (font === currentFont) {
      currentText += char;
    } else {
      chunks.push({ text: currentText, fontFamily: currentFont });
      currentText = char;
      currentFont = font;
    }
  }

  chunks.push({ text: currentText, fontFamily: currentFont });
  return chunks;
};

const DynamicFontTypography = ({ children, sx, ...props }) => {
  const chunks = useMemo(() => splitTextByFont(children), [children]);

  return (
    <Typography {...props} sx={{ whiteSpace: 'pre-wrap', ...sx }}>
      {chunks.map((chunk, index) => (
        <span key={index} style={{ fontFamily: chunk.fontFamily }}>
          {chunk.text}
        </span>
      ))}
    </Typography>
  );
};

export default React.memo(DynamicFontTypography);
