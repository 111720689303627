import React, { useState } from 'react';
import { makeInvitaionCodeApi } from 'utils/clientApis';
import { makeStyles, useTheme } from '@mui/styles';
import { Box, Button, Typography, TextField } from '@mui/material';

export default function CodeMaker() {
  const [count, setCount] = useState();
  const [memo, setMemo] = useState('');
  const [newCode, setNewCode] = useState();

  const classes = useStyles();

  const handleChangeCount = e => {
    setCount(e.target.value);
  };

  const handleChangeMemo = e => {
    setMemo(e.target.value);
  };

  const handleMakeCode = async () => {
    try {
      if (count && memo) {
        const response = await makeInvitaionCodeApi(count, memo);
        // console.log(response)
        setNewCode(response.data.response);
        window.location.reload();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box className={classes.buttonWrap}>
      <div style={{ display: 'flex', gap: '8px', marginBottom: '8px' }}>
        <TextField
          className={classes.textField}
          // error={hasError('count')}
          fullWidth
          helperText={'생성 코드 개수.'}
          label={'카운트'}
          name="count"
          onChange={e => handleChangeCount(e)}
          type="number"
          value={count || ''}
          variant="outlined"
          style={{ width: '140px' }}
        />
        <TextField
          className={classes.textField}
          // error={hasError('count')}
          fullWidth
          helperText={'별도의 메모가 있다면 작성해주세요.'}
          label={'메모'}
          name="memo"
          onChange={e => handleChangeMemo(e)}
          type="text"
          value={memo || ''}
          variant="outlined"
          style={{ width: '100%' }}
        />
      </div>
      <Button
        variant="contained"
        color="primary"
        size="small"
        onClick={handleMakeCode}>
        <Typography
          variant="body1"
          style={{ color: 'white', fontWeight: '500' }}>
          초대 코드 생성
        </Typography>
      </Button>
    </Box>
  );
}

const useStyles = makeStyles(theme => ({
  buttonWrap: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '8px 4px'
  }
}));
