import React, { useState, useEffect } from 'react';
import { Link as RouterLink, withRouter, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Grid,
  Button,
  Checkbox,
  Popover,
  TextField,
  AppBar,
  Tabs,
  Link,
  FormControlLabel,
  Typography
} from '@mui/material';

//import { Facebook as FacebookIcon, Google as GoogleIcon } from 'icons';
//import { GoogleLogin } from 'react-google-login';
//import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { ProviderDialog } from './ProviderDialog';
import validate from 'validate.js';
import axios from 'axios';
import auth from '../../utils/auth';
import request from '../../utils/request';

const schema = {
  username: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    flexGrow: 1,
    width: '100%'
  },
  progress: {
    textAlign: 'center',
    margin: theme.spacing(2)
  },
  tab: {
    backgroundColor: theme.palette.background.paper,
    // boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0'
  },
  gridContainer: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    height: '100%'
  },
  grid: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('lg')]: {
      display: 'none'
    }
  },
  contentContainer: {},
  content: {
    width: '100%',
    justifyContent: 'center'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('lg')]: {
      justifyContent: 'center'
    },
    flexBasis: 500
  },
  form: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingBottom: 50,
    flexBasis: 500,
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    color: '#4db6ac',
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(1)
  },
  socialButtons: {
    marginTop: theme.spacing(1)
  },
  providerButton: {
    width: '100%',
    padding: theme.spacing(1)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(4)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  signOutButton: {
    margin: theme.spacing(2, 0)
  },
  popoverText: {
    padding: theme.spacing(2),
    backgroundColor: '#444444',
    color: '#ffffff'
  }
}));

const SignIn = props => {
  const [redirect, setRedirect] = React.useState(false);
  //  const { history } = props;

  //다국어적용
  const { t } = useTranslation(['page']);

  if (props.location.search) {
    const query = new URLSearchParams(props.location.search);
    const code = query.get('code');

    if (code) {
      axios
        .get(
          `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/strava/register-strava.php`,
          {
            params: {
              jwt: auth.getToken().token,
              exchange_type: 'connect',
              code: code,
              grant_type: 'authorization_code'
            }
          }
        )
        .then(function(response) {
          console.log(response.data);

          if (response.data) {
            setRedirect(true);
          }
        })
        .catch(function(err) {
          console.log(err);
        });
    }
  }

  const classes = useStyles();

  //폼 useState
  const [formState, setFormState] = useState({
    isValid: false,
    values: { rememberMe: true },
    touched: {},
    errors: {}
  });

  //폼체크
  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  /*
    const handleBack = () => {
      history.goBack();
    };
  */

  //소셜로그인 하기
  const [providerResponse, setProviderResponse] = useState(null);
  const [providerResponseOpen, setProviderResponseOpen] = useState(false);

  //proper 로그인 실패 경고
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  //로그인 페이스북
  /*
    const responseFacebook = (res) => {	
      const requestURL = './auth/facebook/callback?access_token=' + res.accessToken;
      request(requestURL, { method: 'GET' })
        .then((response) => {
  
          auth.setToken(response.jwt, true);
          auth.setUserInfo(response.user, true);
  
          window.location.reload();
      }).catch((err) => {
        console.log(err);
      });
    }
  */

  //로그인 구글
  /*
    const responseGoogle = (res) => {
  //	console.log(res.accessToken);
  
      const requestURL = './auth/google/callback?access_token=' + res.accessToken;
      request(requestURL, { method: 'GET' })
        .then((response) => {
  //				console.log(response);
  
          auth.setToken(response.jwt, true);
          auth.setUserInfo(response.user, true);
  
          window.location.reload();
      }).catch((err) => {
  //      console.log(err);
      });
    }
  */

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignInLocal = event => {
    event.preventDefault();

    setAnchorEl(event.currentTarget);
    const req = formState.values;
    req.issueJWT = true;

    const requestURL = `${process.env.REACT_APP_RESTAPI_BASE_URL}/api/aam/v2/authenticate`;

    request(requestURL, { method: 'POST', body: req })
      .then(response => {
        auth.setToken(response.jwt, true);
        auth.setUserInfo(response.user.data, true);

        // if (window.ReactNativeWebView) {
        //   // 로그인 성공 후, React Native로 token과 유효시간을 postMessage로 전송.
        //   window.ReactNativeWebView.postMessage(JSON.stringify({
        //     type: 'LOGIN',
        //     token: response.jwt.token,
        //     token_expires: response.jwt.token_expires
        //   }));
        // }

        window.location.href = '/dashboard';
      })
      .catch(err => {
        setPopoverOpen(true);
        console.log(err);
      });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setPopoverOpen(false);
  };

  /*
    const handleClickSignOut = event => {
      event.preventDefault();
      auth.clearToken();
      window.location.reload();
    }
  */

  function handleProviderResponseClose(value) {
    setProviderResponseOpen(false);
    setProviderResponse(null);
  }

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  const waiting = auth.getToken();

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        color="default"
        className={classes.tab}
        style={{ display: waiting ? 'none' : 'block' }}>
        <Tabs
          value={0}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"></Tabs>
      </AppBar>
      {providerResponse !== null ? (
        <ProviderDialog
          open={providerResponseOpen}
          onClose={handleProviderResponseClose}
          response={providerResponse}
        />
      ) : (
        ''
      )}

      {redirect === true ? (
        <Redirect to="/dashboard" />
      ) : (
        <Grid
          className={classes.gridContainer}
          container
          justifyContent="center"
          alignItems="center">
          <Grid className={classes.content} item>
            <div className={classes.content}>
              <div className={classes.contentBody}>
                <form className={classes.form} onSubmit={handleSignInLocal}>
                  <Typography className={classes.title} variant="h3">
                    {t('SignIn.pleaseLogin')}
                  </Typography>

                  <Typography
                    align="center"
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1"></Typography>
                  {/*

                <Grid
                  className={classes.socialButtons}
                  container
                  spacing={2}
                >
                  
                  <Grid item lg={6} sm={12} xl={6} xs={12}>
	                  <GoogleLogin
											clientId="502318094296-hh079kahnou7h1k820hdo9ksfuu7bcgt.apps.googleusercontent.com"
											render={renderProps => (
												<Button 
													onClick={renderProps.onClick} 
													disabled={renderProps.disabled}
													variant="contained" 
													color="default" 
													className={classes.providerButton} >
								          <GoogleIcon className={clsx(classes.leftIcon, classes.iconSmall)} />&nbsp;&nbsp;Login with Google
												</Button>
											)}
											onSuccess={responseGoogle}
											onFailure={responseGoogle}
											cookiePolicy={'single_host_origin'}  />  
									</Grid>

                  <Grid item lg={6} sm={12} xl={6} xs={12}>
										<FacebookLogin
											appId="571580150044190"
											render={renderProps => (
												<Button	onClick={renderProps.onClick}
													variant="contained" 
													color="default" 
													className={classes.providerButton} >
								          <FacebookIcon className={clsx(classes.leftIcon, classes.iconSmall)} />&nbsp;&nbsp;Login with Facebook
												</Button>
											)}
											fields="name,email,picture"
											callback={responseFacebook} />                
                  </Grid>
                    
                </Grid>
                 */}

                  <Typography
                    align="center"
                    className={classes.sugestion}
                    color="textSecondary"
                    variant="body1">
                    {t('SignIn.loginByRiduck')}
                  </Typography>

                  <TextField
                    className={classes.textField}
                    error={hasError('username')}
                    fullWidth
                    helperText={
                      hasError('username') ? formState.errors.username[0] : null
                    }
                    label={t('SignIn.id')}
                    name="username"
                    onChange={handleChange}
                    type="text"
                    value={formState.values.username || ''}
                    variant="outlined"
                  />

                  <TextField
                    className={classes.textField}
                    error={hasError('password')}
                    fullWidth
                    helperText={
                      hasError('password') ? formState.errors.password[0] : null
                    }
                    label={t('SignIn.password')}
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={formState.values.password || ''}
                    variant="outlined"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        name="rememberMe"
                        checked={formState.values.rememberMe}
                        value={formState.values.rememberMe || ''}
                        onChange={handleChange}
                        color="primary"
                      />
                    }
                    label={t('SignIn.auto')}
                  />

                  <Button
                    className={classes.signInButton}
                    color="primary"
                    disabled={!formState.isValid}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained">
                    {t('SignIn.login')}
                  </Button>

                  <Popover
                    className={classes.signUpButton}
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}>
                    <Typography className={classes.popoverText}>
                      {t('SignIn.wrongPassword')}
                    </Typography>
                  </Popover>

                  <Typography
                    style={{ marginTop: '12px' }}
                    color="textSecondary"
                    variant="body1">
                    {t('SignIn.notyet')}{' '}
                    <Link component={RouterLink} to="/sign-up" variant="h6">
                      {t('SignIn.signup')}
                    </Link>
                  </Typography>

                  <Typography
                    style={{ marginTop: '12px' }}
                    color="textSecondary"
                    variant="body1">
                    {t('SignIn.forgotten')}{' '}
                    <Link
                      href={`${process.env.REACT_APP_RESTAPI_BASE_URL}/riduck-password.php`}
                      variant="h6">
                      {t('SignIn.findPassword')}
                    </Link>
                  </Typography>
                </form>
              </div>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

SignIn.propTypes = {
  history: PropTypes.object
};

export default withRouter(SignIn);
