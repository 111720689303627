import { UNICODE_RANGES } from './unicodeRanges';

export const createFontDetector = () => {
  const fontCache = new Map();
  const DEFAULT_FONT = "'Quicksand', sans-serif";

  return text => {
    if (!text) return DEFAULT_FONT;

    const charCodes = [...text.slice(0, 5)].map(char => char.charCodeAt(0));

    for (const charCode of charCodes) {
      if (fontCache.has(charCode)) {
        return fontCache.get(charCode);
      }

      for (const range of UNICODE_RANGES) {
        if (charCode >= range.start && charCode <= range.end) {
          fontCache.set(charCode, range.font);
          return range.font;
        }
      }
    }

    return DEFAULT_FONT;
  };
};

export const getFontForText = createFontDetector();
