

export function getImageUrl(images, sizes) {
 // 먼저, sizes에 해당하는 이미지를 필터링합니다.
 const sizedImages = images.filter(image => image.thumbnailSize === sizes);

 // sizedImages가 비어 있지 않다면, 첫 번째 이미지의 filePath를 반환합니다.
 if (sizedImages.length > 0) {
  return sizedImages[0].filePath;
 }
 // sizedImages가 비어 있다면, 'ORIGIN' 사이즈의 첫 번째 이미지 filePath를 반환합니다.
 else {
  const originImages = images.filter(image => image.thumbnailSize === "ORIGIN");
  if (originImages.length > 0) {
   return originImages[0].filePath;
  }
 }
 // 매칭되는 이미지가 없을 경우 undefined 반환
 return undefined;
}

