import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Paper,
  Popover,
  Tabs,
  Tab,
  IconButton
} from '@mui/material';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BackspaceIcon from '@mui/icons-material/Backspace';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import ApexChart from 'react-apexcharts';
import cloneDeep from 'lodash/cloneDeep';
import ChartOptions from './Options/OptionTimeLine.js';

//jQuery 추가
import $ from 'jquery';

//Chart 추가
import CanvasJSReact from '../../../utils/canvasjs.stock.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSStockChart = CanvasJSReact.CanvasJSStockChart;

const useStyles = makeStyles(theme => ({
  root: {},
  smallButton: {
    minWidth: '30px',
    padding: '0px',
    fontSize: '13px',
    color: '#cccccc'
  },
  stravaButton: {
    minWidth: 0,
    margin: '2px 2px 2px 0'
  },
  summaryContainer: {
    height: 436,
    position: 'relative',
    overflow: 'hidden',
    margin: '0px -4px 0px -14px',
    padding: '10px 0 24px 0',
    [theme.breakpoints.down('xl')]: {
      height: 368
    },
    [theme.breakpoints.down('lg')]: {
      height: 276
    },
    [theme.breakpoints.down('sm')]: {
      height: 256
    }
  },
  ToolBarBlock: {
    position: 'relative',
    zIndex: 10,
    width: 'calc(100% - 18px)',
    flexGrow: 1,
    height: '36px',
    margin: '0 0 0px 12px'
  },
  ToolBarLeftButton: {
    width: '32px',
    height: '32px',
    marginRight: '8px',
    padding: '4px'
  },
  ToolBarButton: {
    width: 'calc(100% - 40px)',
    boxSizing: 'border-box',
    fontSize: '15px',
    color: '#455a64',
    background: '#f5f5f5',
    opacity: '0.9',
    padding: '4px 0 6px 0',
    textAlign: 'center',
    borderRadius: '6px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      borderRadius: '4px',
      height: '32px',
      padding: '4px 0 6px 0'
    }
  },
  tssTextBold: {
    fontSize: '15px',
    margin: '0 16px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 12px 0 3px'
    }
  },
  chartController: {
    position: 'relative',
    margin: '-20px -4px -2px -14px',
    padding: '30px 0 0 0',
    height: '64px',
    textAlign: 'center'
  },
  chip: {
    //border:'1px #dddddd solid',
    //borderRadius:'4px',
    marginRight: '4px',
    minWidth: '40px',
    padding: '5px 8px',
    fontSize: '12px',
    fontWeight: 400,
    height: '24px'
  },
  chartContainer: {
    minHeight: 388,
    position: 'relative',
    overflow: 'hidden',
    margin: '12px -24px 0 -8px',
    [theme.breakpoints.down('xl')]: {
      minHeight: 320
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: 'auto'
    }
  },
  chartContentWrap: {
    margin: '-8px -8px 0 0',
    overflow: 'hidden',
    height: '100px',
    [theme.breakpoints.down('xl')]: {
      height: '90px'
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: '100px'
    }
  },
  chartAltitudeWrap: {
    margin: '-32px -8px 0 0',
    height: '120px',
    [theme.breakpoints.down('xl')]: {
      height: '92px'
    },
    [theme.breakpoints.down('lg')]: {
      minHeight: '110px'
    }
  },
  chartContent: {
    height: '135px',
    margin: '-20px 0 0 0'
  },
  chartButton: {
    fontSize: '15px',
    marginTop: '-6px',
    textTransform: 'none',
    padding: '0px',
    color: '#90a4ae'
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tooltip: {
    fontSize: '13px',
    color: '#ffffff',
    padding: '8px 16px',
    backgroundColor: '#333333',
    borderRadius: '3px',
    opacity: '0.8'
  },
  tabs: {
    width: '300px',
    border: '1px #dddddd solid',
    borderRadius: '4px 4px 0 0'
  },
  tabContent: {
    width: '300px',
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px'
  },
  listItem: {
    padding: '2px 12px'
  },
  tabTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  tsbDesc: {
    margin: '0px 10px 8px 10px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left'
  },
  cardContent: {
    [theme.breakpoints.down('md')]: {
      fontSize: '14px'
    }
  },
  legendText: {
    marginLeft: '10px',
    paddingTop: '6px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
      marginLeft: '6px',
      marginTop: '-3px',
      paddingTop: '0px'
    }
  },
  tssEmoji: {
    backgroundColor: '#eeeeee',
    height: 40,
    width: 40,
    marginTop: '2px'
  },
  icon: {
    height: 40,
    width: 40,
    [theme.breakpoints.down('md')]: {
      height: 32,
      width: 32
    }
  },
  stravaDesc: {
    minWidth: '240px',
    padding: '8px',
    fontSize: '13px',
    borderBottom: '1px #eeeeee solid'
  },
  xaxisLabel: {
    color: '#888888',
    fontSize: '11px'
  }
}));

const TimeLine = props => {
  //다국어적용 {t('page.key')}

  const { t } = useTranslation(['page']);

  const {
    extraInfo,
    ftp,
    summaryData,
    streamDataJson,
    wkg,
    weight,
    wPrime
  } = props;
  const [activityId, setActivityId] = useState(streamDataJson.activity_id);
  const activityFTP = streamDataJson.ftp;
  const streamType = streamDataJson.stream_type;

  const activityName = streamDataJson.activity_name;
  const streamData = streamDataJson.stream_json;
  const altitudeMax = streamDataJson.altitude_max;
  const stravaDescription = streamDataJson.strava_description;

  const [tssGuideDialogOpen, setTssGuideDialogOpen] = useState(false);
  const [stravaDescriptionDialog, setStravaDescriptionDialog] = useState(false);

  //	const [ isLoaded, setIsLoaded ] = useState(false);

  //setStreamData(data.stream_json);
  //setAltitudeMax(data.altitude_max);
  /*
axisX:{
        tickColor: "red",
        tickLength: 5,
        tickThickness: 2
      },
      axisY:{
        tickLength: 15,
        tickColor: "DarkSlateBlue" ,
        tickThickness: 5
      },
			markerSize
			markerType: "square"
*/
  //const ChartRead = () => {
  //let CanvasJS = window.CanvasJS;

  const chartsArray = [];

  const toolTip = {
      shared: true,
      contentFormatter: function(e) {
        let content = ' ';
        for (let i = 0; i < e.entries.length; i++) {
          content +=
            e.entries[i].dataSeries.name +
            ' ' +
            '<strong>' +
            e.entries[i].dataPoint.y +
            '</strong>';
          content += '<br/>';
        }
        return content;
      }
    },
    legend = {
      cursor: 'pointer',
      itemclick: function(e) {
        if (
          typeof e.dataSeries.visible === 'undefined' ||
          e.dataSeries.visible
        ) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        e.chart.render();
      }
    };

  //temperatureArray
  //awcArray
  //cdaArray
  //headwindArray
  //energy metabolism Array
  //kCal Array

  useEffect(() => {
    /*
		fetch("https://canvasjs.com/data/gallery/react/btcusd2017-18.json")
		.then(res => res.json())
		.then(
			(data) => {
				var dps = [];
				for (var i = 0; i < data.length; i++) {
					dps.push({
						x: new Date(data[i].date),
						y: Number(data[i].close)
					});
				}
				
				setDataPoints(dps);
				setIsLoaded(true);
			}
		)

		$("#canvasjs_wrap").append(
				"<div id='chartContainer'>" + 
					"<div id='chartTopWrap' style='height:100px; background:#f5f5f5'></div>" +
					"<div id='chartWrap' style='height:480px'></div>" +
				"</div>"
			);								

			$("#chartContainer").show();
			$("#chartContainer").css('margin-top','12px');
			$("#chartContainer").width('100%');
*/
    //			ChartRead();
  }, [activityId]);

  //	if(activityId > 0 && streamType === "org") {
  //	}

  const classes = useStyles();

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + 'h ' : '';
      let md = m > 0 ? m + 'm' : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + 'm ' : '';
      let sd = s > 0 ? s + 's' : '';

      return md + sd;
    }
  }

  function secondsToHmsLabel(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h == 0 && m == 0 && s == 0) {
      return '00:00';
    } else if (h > 0) {
      let hd = h > 0 ? (h < 10 ? '0' + h + ':' : h + ':') : '00';
      let md = m > 0 ? (m < 10 ? '0' + m : m) : '00';

      return hd + md;
    } else if (h <= 0 && m > 0) {
      let md = m > 0 ? (m < 10 ? '0' + m + ':' : m + ':') : '00';
      let sd = s > 0 ? (s < 10 ? '0' + s : s) : '00';

      return md + sd;
    } else if (m <= 0 && s > 0) {
      let md = '00:';
      let sd = s > 0 ? (s < 10 ? '0' + s : s) : '00';

      return md + sd;
    } else {
      return '00:00';
    }
  }

  function Summary(props) {
    const summaryOptions = props.options.summary;
    const pmcOptions = props.options.pmc;
    const summaryData = props.summaryData;
    const tssTable = summaryData.tss_table;

    const lastATL = summaryData.last_atl;
    const lastCTL = summaryData.last_ctl;
    const lastTSB = summaryData.last_tsb;
    const lastWeekTss = tssTable.last_week_tss;
    const advice = tssTable.advice;

    const tssGuides = tssTable.tss_guide;

    const summarySeries = [
      { name: t('Dashboard.ftp'), data: summaryData.ftp, type: 'line' },
      { name: t('Dashboard.np'), data: summaryData.np, type: 'line' },
      { name: t('Dashboard.distance'), data: summaryData.ds, type: 'column' },
      { name: t('Dashboard.wTss'), data: summaryData.tss, type: 'area' }
    ];

    const pmcSeries = [
      { name: t('Dashboard.ctl'), data: summaryData.ctl_arr, type: 'area' },
      { name: t('Dashboard.atl'), data: summaryData.atl_arr, type: 'line' },
      { name: t('Dashboard.tsb'), data: summaryData.tsb_arr, type: 'line' }
    ];

    function handleTssGuideDialogOpen() {
      setTssGuideDialogOpen(true);
    }

    function handleTssGuideDialogClose() {
      setTssGuideDialogOpen(false);
    }

    //훈련트렌드 다이알로그
    function TssGuideDialogContent(props) {
      const tssGuides = props.tssGuides;
      const [tssGuideTab, setTssGuideTab] = React.useState(0);

      const handleTssGuideTab = (event, newIndex) => {
        setTssGuideTab(newIndex);
      };

      return (
        <React.Fragment>
          <Box>
            <Paper elevation={0} className={classes.tabs}>
              <Tabs
                value={tssGuideTab}
                onChange={handleTssGuideTab}
                indicatorColor="primary"
                textColor="primary"
                centered>
                <Tab label={<div>{t('Dashboard.fitnessChart')}</div>} />
                <Tab label={<div>{t('Dashboard.weeklyGuide')}</div>} />
              </Tabs>
            </Paper>
            {tssGuideTab === 1 ? (
              <Box className={classes.tabContent}>
                <List>
                  {tssGuides.map((guide, index) => (
                    <ListItem className={classes.listItem} key={index}>
                      <ListItemAvatar>
                        <Avatar className={classes.tssEmoji}>
                          {guide.emoji}
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <div>
                            <Typography component="span">
                              {guide.guide}
                            </Typography>
                            <Typography
                              component="span"
                              style={{ marginLeft: '8px' }}></Typography>
                          </div>
                        }
                        secondary={guide.tss}
                      />
                    </ListItem>
                  ))}
                </List>
                <Divider />
                <Box>
                  <Typography className={classes.tabTitle} component="div">
                    {t('Dashboard.weeklyGuide')}
                  </Typography>
                  <Typography className={classes.tsbDesc} component="div">
                    {t('Dashboard.weeklyGuideDesc')}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box className={classes.tabContent}>
                <Typography
                  className={classes.tabTitle}
                  style={{ marginBottom: '-20px' }}
                  component="div">
                  {t('Dashboard.6weeksFitness')}
                </Typography>
                <ApexChart
                  options={pmcOptions}
                  series={pmcSeries}
                  height={'200px'}
                />
                <Divider />
                <Box
                  style={{
                    fontSize: '13px',
                    marginBottom: '12px',
                    lineHeight: '24px',
                    textAlign: 'center'
                  }}>
                  <Typography className={classes.tabTitle} component="div">
                    {t('Dashboard.currentTraining')}
                  </Typography>
                  <div>
                    🚴 {t('Dashboard.ctl')}{' '}
                    <Typography
                      className={classes.tssTextBold}
                      component="span">
                      {lastCTL}
                    </Typography>
                  </div>
                  <div>
                    💤 {t('Dashboard.atl')}{' '}
                    <Typography
                      className={classes.tssTextBold}
                      component="span">
                      {lastATL}
                    </Typography>
                  </div>
                  <div>
                    ⚖️ {t('Dashboard.tsb')}{' '}
                    <Typography
                      className={classes.tssTextBold}
                      component="span">
                      {lastTSB}
                    </Typography>
                  </div>
                  <div>
                    {advice} ({lastWeekTss})
                  </div>
                </Box>
                <Divider />
                <Box>
                  <Typography className={classes.tabTitle} component="div">
                    {t('Dashboard.pmcIs')}
                  </Typography>
                  <Typography className={classes.tsbDesc} component="div">
                    {t('Dashboard.pmcIsDesc')}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </React.Fragment>
      );
    }

    //탑바
    function SummaryDialog(props) {
      const classes = useStyles();

      ////#ffd357 노
      //f491b2 빨
      //8cc1ec 파랑
      //4db6ac 녹

      return (
        <div className={classes.ToolBarBlock}>
          <IconButton className={classes.ToolBarLeftButton} size="large">
            <InfoOutlinedIcon />
          </IconButton>
          <Button
            onClick={handleTssGuideDialogOpen}
            variant="contained"
            color="inherit"
            className={classes.ToolBarButton}>
            <Box display={{ xs: 'none', sm: 'none', md: 'inline' }}>
              🚴 {t('Dashboard.ctl')}{' '}
              <Typography className={classes.tssTextBold} component="span">
                {lastCTL}
              </Typography>
              💤 {t('Dashboard.atl')}{' '}
              <Typography className={classes.tssTextBold} component="span">
                {lastATL}
              </Typography>
            </Box>
            {advice}
          </Button>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Box className={classes.summaryContainer}>
          <SummaryDialog />
          <ApexChart
            options={summaryOptions}
            series={summarySeries}
            height={'100%'}
            style={{ marginTop: '-12px' }}
          />
        </Box>

        <Dialog open={tssGuideDialogOpen} onClose={handleTssGuideDialogClose}>
          <DialogTitle>{t('Dashboard.trainingGuide')}</DialogTitle>
          <Divider />
          <DialogContent style={{ padding: '8px' }}>
            <TssGuideDialogContent tssGuides={tssGuides} />
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }

  //차트에 그라데이션
  function Annotations(xy, arr) {
    let result = null;
    if (xy === 'x') {
      result = arr.map(x => {
        return {
          x: x.x,
          x2: x.x2,
          strokeDashArray: 0,
          borderColor: '#ffffff',
          fillColor: '#ffffff',
          opacity: x.opacity,
          label: { show: false }
        };
      });
    } else if (xy === 'y') {
      result = arr.map(y => {
        return {
          y: y.y,
          y2: y.y2,
          strokeDashArray: 0,
          borderColor: '#ffffff',
          fillColor: '#ffffff',
          opacity: y.opacity,
          label: { show: false }
        };
      });
    }

    return result;
  }

  //Activity Stream 상세차트보기
  function ActivityStream(props) {
    const altitudeMax = props.altitudeMax;
    const streamData = props.streamData;
    const options = props.options;

    const rideType = streamDataJson.ride_type;
    const anaerobicJson = JSON.parse(streamDataJson.anaerobic_json);
    const cp = anaerobicJson.cp ? anaerobicJson.cp : ftp;
    const AWC = anaerobicJson.w_prime ? anaerobicJson.w_prime : wPrime;

    options.altitude.yaxis.max = altitudeMax;

    const optionV = cloneDeep(options.content);
    optionV.colors = ['#666666'];
    optionV.fill.colors = ['#666666'];
    optionV.fill.opacity = [0.4];
    optionV.yaxis.labels.style.colors = '#666666';

    let velocityAnnotaions = [
      { y: 0, y2: 10, opacity: 0.2 },
      { y: 0, y2: 20, opacity: 0.2 },
      { y: 0, y2: 30, opacity: 0.2 },
      { y: 0, y2: 40, opacity: 0.2 },
      { y: 0, y2: 50, opacity: 0.2 },
      { y: 0, y2: 200, opacity: 0.0 }
    ];
    optionV.annotations.yaxis = Annotations('y', velocityAnnotaions);

    const optionW = cloneDeep(options.content);
    optionW.colors = ['#ea306d'];
    optionW.fill.colors = ['#f491b2'];
    optionW.fill.opacity = [1.0];
    optionW.yaxis.labels.style.colors = '#f491b2';
    optionW.yaxis.tickAmount = 5;
    //optionW.yaxis.max = streamData.max_watts;

    let wattsAnnotaions = [
      { y: 0, y2: cp * 0.54, opacity: 0.2 },
      { y: 0, y2: cp * 1.0, opacity: 0.2 },
      { y: 0, y2: cp * 1.52, opacity: 0.2 },
      { y: 0, y2: 2500, opacity: 0.0 },
      { y: cp, y2: null, opacity: 0.0 }
    ];
    optionW.annotations.yaxis = Annotations('y', wattsAnnotaions);
    optionW.annotations.yaxis[4].strokeDashArray = 2;
    optionW.annotations.yaxis[4].borderColor = '#f491b2';
    optionW.annotations.yaxis[4].label.text = 'FTP ' + Math.round(cp) + ' w';
    optionW.annotations.yaxis[4].label.style = {
      background: 'transparent',
      fontSize: '10px',
      color: '#ea306d'
    };
    optionW.annotations.yaxis[4].label.position = 'right';
    optionW.annotations.yaxis[4].label.offsetX = -5;
    optionW.annotations.yaxis[4].label.offsetY = 16;
    optionW.annotations.yaxis[4].label.borderColor = 'transparent';

    /*
8cc1ec
ffd357
be8c00
f4a291
*/

    const optionAWC = cloneDeep(options.content);
    optionAWC.colors = ['#ffd357'];
    optionAWC.fill.colors = ['#ffd357'];
    optionAWC.fill.opacity = [0.4];
    optionAWC.yaxis.labels.style.colors = '#ffd357';
    optionAWC.yaxis.max = 100;
    optionAWC.yaxis.min = 0;
    optionAWC.yaxis.tickAmount = 5;

    let awcAnnotaions = [{ y: 100, y2: null, opacity: 0.0 }];
    optionAWC.annotations.yaxis = Annotations('y', awcAnnotaions);
    optionAWC.annotations.yaxis[0].strokeDashArray = 2;
    optionAWC.annotations.yaxis[0].borderColor = '#ffd357';
    optionAWC.annotations.yaxis[0].label.text =
      'AWC ' + Math.round(AWC / 100) / 10 + ' kJ';
    optionAWC.annotations.yaxis[0].label.style = {
      background: 'transparent',
      fontSize: '10px',
      color: '#be8c00'
    };
    optionAWC.annotations.yaxis[0].label.offsetX = -5;
    optionAWC.annotations.yaxis[0].label.offsetY = 16;
    optionAWC.annotations.yaxis[0].label.position = 'right';
    optionAWC.annotations.yaxis[0].label.borderColor = 'transparent';

    const optionC = cloneDeep(options.content);
    optionC.colors = ['#8cc1ec'];
    optionC.fill.colors = ['#8cc1ec'];
    optionC.fill.opacity = [0.4];
    optionC.yaxis.labels.style.colors = '#8cc1ec';

    const optionH = cloneDeep(options.content);
    optionH.colors = ['#f4a291'];
    optionH.fill.colors = ['#f4a291'];
    optionH.fill.opacity = [0.4];
    optionH.yaxis.labels.style.colors = '#f4a291';

    let streamDistance = streamData.distance ? streamData.distance : Array();
    let streamAltitude = streamData.altitude ? streamData.altitude : Array();
    let streamGrade = streamData.grade_smooth
      ? streamData.grade_smooth
      : Array();
    let streamDuration = streamData.duration ? streamData.duration : Array();

    let streamVelocity = streamData.velocity_smooth
      ? streamData.velocity_smooth
      : Array();
    let streamWatts = streamData.watts ? streamData.watts : Array();
    let streamAwc = streamData.awc ? streamData.awc : Array();
    let streamCadence = streamData.cadence ? streamData.cadence : Array();
    let streamHeartrate = streamData.heartrate ? streamData.heartrate : Array();

    const altitude = [
      { name: t('Dashboard.altitude'), data: streamAltitude },
      { name: t('Dashboard.slope'), data: streamGrade },
      { name: t('Dashboard.duration'), data: streamDuration },
      { name: t('Dashboard.distance'), data: streamDistance }
    ];
    const velocity_smooth = [
      { name: t('Dashboard.velocity'), label: 'km/h', data: streamVelocity }
    ];
    //  const latlng = [{ name: "latlng", label:"", data: streamLatlng }];
    const watts = [
      { name: t('Dashboard.watts'), label: 'W', data: streamWatts }
    ];
    const awc = [{ name: t('Dashboard.battery'), label: '%', data: streamAwc }];
    const cadence = [
      { name: t('Dashboard.rpm'), label: '', data: streamCadence }
    ];
    const heartrate = [
      { name: t('Dashboard.hrm'), label: '', data: streamHeartrate }
    ];

    let velocity_smoothShow = true;
    let wattsShow = false;
    let awcShow = false;
    let cadenceShow = false;
    let heartrateShow = false;

    if (streamData.watts) {
      wattsShow = true;
      awcShow = true;
    }

    if (streamData.cadence) {
      cadenceShow = true;
    }

    if (streamData.heartrate) {
      heartrateShow = true;
    }

    //altitude label
    if (rideType === 'VirtualRide') {
      options.altitude.xaxis.labels.formatter = function(val) {
        if (val > 0) return secondsToHmsLabel(val) + ' T';
        else return ' ';
      };
    } else {
      options.altitude.xaxis.labels.formatter = function(val) {
        if (val > 0) return Math.round(val * 10) / 10 + ' km';
        else return ' ';
      };
    }

    function handleStravaDescriptionDialogOpen() {
      setStravaDescriptionDialog(true);
    }

    function handleStravaDescriptionDialogClose() {
      setStravaDescriptionDialog(false);
    }

    //구간 평균 구하기
    function rangeHandler(chart) {
      //range
      let x_min = Number(chart.axisX[0].viewportMinimum);
      let x_max = Number(chart.axisX[0].viewportMaximum);

      //			console.log(chart);
      let gap = 10;

      //여러 차트들의 데이터들
      let a1_data = chart.chart.data[0].dataPoints;
      let a2_data = chart.chart.data[1].dataPoints;

      let p1_sum = 0;
      let p1_total = 0;

      let p2_sum = 0;
      let p2_total = 0;
      //+16

      $.each(a1_data, function(index, item) {
        if (index >= x_min && index < x_max) {
          p1_sum += item.y;
          p1_total++;
        }
      });

      $.each(a2_data, function(index, item) {
        if (index >= x_min + gap && index < x_max + gap) {
          p2_sum += item.y;
          p2_total++;
        }
      });

      console.log('구간평균 p1 : ' + p1_sum / p1_total);
      console.log('구간평균 p2 : ' + p2_sum / p2_total);

      /*
			if(x_min == 0) {
				$("#a1_range_avg").html("");
				$("#a2_range_avg").html("");
			}
*/
    }

    function StreamDialog(props) {
      const classes = useStyles();
      const [anchorEl, setAnchorEl] = React.useState(null);

      const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
        navigator.clipboard.writeText(stravaDescription);
      };

      const handlePopoverClose = () => {
        setAnchorEl(null);
      };

      const popoverOpen = Boolean(anchorEl);

      /*
		{ velocity_smoothShow ? <Button variant="outlined" color="inherit" className={classes.chip} onClick={() => chipDetail()}>속도</Button> : ""}
		{ wattsShow ? <Button variant="outlined" color="inherit" className={classes.chip} onClick={() => chipDetail()}>파워</Button> :  "" }
   	{ awcShow ? <Button variant="outlined" color="inherit" className={classes.chip} onClick={() => chipDetail()}>AWC</Button> : "" }
   	{ heartrateShow ? <Button variant="outlined" color="inherit" className={classes.chip} onClick={() => chipDetail()}>심박</Button> : "" }
   	{ cadenceShow ? <Button variant="outlined" color="inherit" className={classes.chip} onClick={() => chipDetail()}>RPM</Button> : "" }
	*/

      return (
        <div className={classes.ToolBarBlock}>
          <IconButton
            className={classes.ToolBarLeftButton}
            onClick={() => {
              $('#chartContainer').remove();

              setActivityId(0);
            }}
            size="large">
            <BackspaceIcon />
          </IconButton>
          <Button
            onClick={handleStravaDescriptionDialogOpen}
            variant="contained"
            className={classes.ToolBarButton}>
            {'📝 ' + activityName}
          </Button>

          <Dialog
            open={stravaDescriptionDialog}
            onClose={handleStravaDescriptionDialogClose}>
            <DialogTitle>{t('Dashboard.stravaDesc')}</DialogTitle>
            <Divider />
            <DialogContent className={classes.stravaDesc}>
              <div>
                <pre>{stravaDescription}</pre>
              </div>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'center' }}>
              <IconButton onClick={handlePopoverOpen} size="large">
                <FileCopyOutlinedIcon />
              </IconButton>
            </DialogActions>
            <Popover
              open={popoverOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center'
              }}>
              <Typography style={{ margin: '12px' }}>
                {t('Dashboard.stravaDescCopy')}
              </Typography>
            </Popover>
          </Dialog>
        </div>
      );
    }

    if (streamType === 'org') {
      const durationArray = streamData.duration;
      const distanceArray = streamData.distance;
      const velocityArray = streamData.velocity;
      const wattsArray = streamData.watts;
      const awcArray = streamData.awc;
      const cadenceArray = streamData.cadence;
      const heartrateArray = streamData.heartrate;
      const altitudeArray = streamData.altitude;

      const durationPoints = streamData.duration;
      const distancePoints = streamData.distance;
      const velocityPoints = streamData.velocity;
      const wattsPoints = streamData.watts;
      const awcPoints = streamData.awc;
      const cadencePoints = streamData.cadence;
      const heartratePoints = streamData.heartrate;
      const altitudePoints = streamData.altitude;

      // max
      const wattsMax = streamData.watts_max;
      const wattsMaxIndex = streamData.watts_max_index;

      const cadenceMax = streamData.cadence_max;
      const cadenceMaxIndex = streamData.cadence_max_index;

      const velocityMax = streamData.velocity_max;
      const velocityMaxIndex = streamData.velocity_max_index;

      const heartrateMax = streamData.heartrate_max;
      const heartrateMaxIndex = streamData.heartrate_max_index;

      const chartOptions = {
        animationEnabled: false,
        theme: 'light2', // "light1", "light2", "dark1", "dark2"
        title: false,
        toolTip: toolTip,
        //height: 100,
        axisX: {
          gridThickness: 0,
          tickLength: 0,
          lineThickness: 1,
          lineColor: '#dddddd',
          labelFontColor: '#ffffff',
          valueFormatString: '.',
          labelAngle: 0,
          crosshair: {
            enabled: true,
            lineColor: '#999999',
            labelFormatter: function(e) {
              let km = Math.round(e.value / 100) / 10;
              return km + 'km';
            }
          }
        },
        axisY: {
          lineColor: '#dddddd',
          tickColor: '#f5f5f5',
          gridColor: '#f5f5f5',
          labelMaxWidth: 20
        },
        legend: legend
      };

      const distanceChartOptions = {
        ...cloneDeep(chartOptions),
        data: [
          {
            type: 'splineArea',
            fillOpacity: 0.4,
            name: t('Dashboard.altitude'),
            lineThickness: 1,
            color: '#4db6ac',
            legendMarkerType: 'square',
            dataPoints: altitudePoints
          },
          {
            visible: false,
            type: 'line',
            fillOpacity: 0.4,
            name: t('Dashboard.duration'),
            lineThickness: 1,
            color: '#4db6ac',
            legendMarkerType: 'square',
            dataPoints: durationPoints
          }
        ]
      };

      const velocityChartOptions = {
        ...cloneDeep(chartOptions),
        data: [
          {
            type: 'splineArea',
            fillOpacity: 0.4,
            name: t('Dashboard.velocity'),
            yValueFormatString: '# km/h',
            lineThickness: 1,
            color: '#ffd357',
            legendMarkerType: 'square',
            dataPoints: velocityPoints
          }
        ]
      };

      const wattsChartOptions = {
        ...cloneDeep(chartOptions),
        data: [
          {
            type: 'splineArea',
            fillOpacity: 0.4,
            name: t('Dashboard.watts'),
            yValueFormatString: '# W',
            lineThickness: 1,
            color: '#f06292',
            legendMarkerType: 'square',
            dataPoints: wattsPoints
          },
          {
            type: 'splineArea',
            fillOpacity: 0.2,
            name: t('Dashboard.battery'),
            yValueFormatString: '# %',
            lineThickness: 1,
            color: '#dddddd',
            legendMarkerType: 'square',
            dataPoints: awcPoints
          }
        ]
      };

      /*
			let awcChartOptions = {
				...cloneDeep(chartOptions),
				data: [{
				}]
			}
			*/

      const cadenceChartOptions = {
        ...cloneDeep(chartOptions),
        data: [
          {
            type: 'splineArea',
            fillOpacity: 0.4,
            name: t('Dashboard.rpm'),
            color: '#8cc1ec',
            lineThickness: 1,
            legendMarkerType: 'square',
            dataPoints: cadencePoints
          }
        ]
      };

      const heartrateChartOptions = {
        ...cloneDeep(chartOptions),
        data: [
          {
            type: 'splineArea',
            fillOpacity: 0.4,
            name: t('Dashboard.hrm'),
            color: '#ffa75c',
            lineThickness: 1,
            legendMarkerType: 'square',
            dataPoints: heartratePoints
          }
        ]
      };

      //let indexOfCadenceMax = cadenceArray.reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);
      //let indexOfAltitudeMax = altitudeArray.reduce((iMax, x, i, arr) => x > arr[iMax] ? i : iMax, 0);

      if (velocityArray) {
        velocityChartOptions.axisY.maximum = Math.round(velocityMax);

        velocityChartOptions.axisX.stripLines = [
          {
            value: velocityPoints[velocityMaxIndex].x,
            label: velocityMax,
            thickness: 1,
            color: '#ffd357',
            labelAlign: 'near',
            lineDashType: 'dash',
            labelFormatter: function(e) {
              return '';
            }
          }
        ];
        chartsArray.push(velocityChartOptions);

        let toolTipVelocity = {
          shared: true,
          contentFormatter: function(e) {
            let content = ' ';
            for (let i = 0; i < e.entries.length; i++) {
              if (i == 0) {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  e.entries[i].dataPoint.y +
                  '</strong> km/h';
                content += '<br/>';
              }
            }
            return content;
          }
        };

        velocityChartOptions.toolTip = toolTipVelocity;
      }

      if (wattsArray) {
        wattsChartOptions.axisY.maximum = Math.round(wattsMax);
        wattsChartOptions.axisY.stripLines = [
          {
            value: activityFTP,
            label: 'FTP ' + activityFTP + ' W',
            labelPlacement: 'inside',
            labelFontSize: 11,
            labelAlign: 'far',
            labelFontColor: '#f06292',
            thickness: 1,
            color: '#f06292',
            lineDashType: 'dash'
          }
        ];
        wattsChartOptions.axisX.stripLines = [
          {
            value: wattsPoints[wattsMaxIndex].x,
            label: wattsMax,
            thickness: 1,
            color: '#f06292',
            labelAlign: 'near',
            lineDashType: 'dash',
            labelFormatter: function(e) {
              return '';
            }
          }
        ];
        //awc % 포함하기
        let toolTipWatts = {
          shared: true,
          contentFormatter: function(e) {
            let content = ' ';
            for (let i = 0; i < e.entries.length; i++) {
              if (i == 0) {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  e.entries[i].dataPoint.y +
                  '</strong> W';
                content += '<br/>';
              } else {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  Math.round((1000 * e.entries[i].dataPoint.y) / wattsMax) /
                    10 +
                  '</strong> %';
                content += '<br/>';
              }
            }
            return content;
          }
        };

        wattsChartOptions.toolTip = toolTipWatts;
        chartsArray.push(wattsChartOptions);
      }

      if (cadenceArray) {
        cadenceChartOptions.axisY.maximum = Math.round(cadenceMax);
        chartsArray.push(cadenceChartOptions);
      }

      if (heartrateArray) {
        heartrateChartOptions.axisY.maximum = Math.round(heartrateMax);
        heartrateChartOptions.axisX.stripLines = [
          {
            value: heartratePoints[heartrateMaxIndex].x,
            label: heartrateMax,
            thickness: 1,
            color: '#ffa75c',
            labelAlign: 'near',
            lineDashType: 'dash',
            labelFormatter: function(e) {
              return '';
            }
          }
        ];
        chartsArray.push(heartrateChartOptions);
      }

      if (distanceArray && altitudeArray) {
        distanceChartOptions.axisY.maximum = Math.round(altitudeMax);
        distanceChartOptions.axisX.margin = 10;
        chartsArray.push(distanceChartOptions);

        let toolTipAltitude = {
          shared: true,
          contentFormatter: function(e) {
            let content = ' ';
            for (let i = 0; i < e.entries.length; i++) {
              if (i == 0) {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  e.entries[i].dataPoint.y +
                  '</strong> m';
                content += '<br/>';
              } else {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  e.entries[i].dataPoint.y +
                  '</strong> s';
                content += '<br/>';
              }
            }
            return content;
          }
        };

        distanceChartOptions.toolTip = toolTipAltitude;
      } else {
        //altitude
        let altitudeMin = 0;
        let altitudeMax = 50;
        distanceChartOptions.axisY.minimum = Math.round(altitudeMin);
        distanceChartOptions.axisY.maximum = Math.round(altitudeMax);
        distanceChartOptions.axisX.margin = 10;
        chartsArray.push(distanceChartOptions);

        let toolTipAltitude = {
          shared: true,
          contentFormatter: function(e) {
            let content = ' ';
            for (let i = 0; i < e.entries.length; i++) {
              if (i == 0) {
                content +=
                  e.entries[i].dataSeries.name +
                  ' ' +
                  '<strong>' +
                  e.entries[i].dataPoint.y +
                  '</strong> m';
                content += '<br/>';
              }
            }
            return content;
          }
        };

        distanceChartOptions.toolTip = toolTipAltitude;
      }

      const options = {
        title: {
          text: false
        },
        charts: [...chartsArray],
        rangeChanged: function(e) {
          //거리
          console.log(Math.round(e.minimum), Math.round(e.maximum));
        },
        navigator: {
          backgroundColor: '#f5f5f5',
          axisX: {
            labelFontColor: '#666666',
            lineColor: '#dddddd',
            labelFormatter: function(e) {
              let km = Math.round(e.value / 100) / 10;
              return km + 'km';
            },
            labelAngle: 0
          },
          axisY: {
            lineColor: '#dddddd'
          },
          margin: 20,
          height: '60px',
          data: [
            {
              type: 'splineArea',
              color: '#cccccc',
              fillOpacity: 0.4,
              lineThickness: 1,
              dataPoints: distancePoints
            }
          ],
          slider: {}
        },
        rangeSelector: {
          enabled: false
        }
      };

      const containerProps = {
        width: '100%',
        height: '480px',
        margin: 'auto'
      };

      return (
        <React.Fragment>
          <Box className={classes.chartController}>
            <StreamDialog />
          </Box>
          {
            <CanvasJSStockChart
              containerProps={containerProps}
              options={options}
            />
          }
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <Box className={classes.chartController}>
            <StreamDialog />
          </Box>

          <Box className={classes.chartContainer}>
            {velocity_smoothShow ? (
              <div
                className={classes.chartContentWrap}
                style={{ marginTop: '-5px' }}>
                <ApexChart
                  type="area"
                  className={classes.chartContent}
                  options={optionV}
                  series={velocity_smooth}
                  height={'100%'}
                />
              </div>
            ) : (
              ''
            )}

            {wattsShow ? (
              <div className={classes.chartContentWrap}>
                <ApexChart
                  type="area"
                  className={classes.chartContent}
                  options={optionW}
                  series={watts}
                  height={'100%'}
                />
              </div>
            ) : (
              ''
            )}

            {awcShow ? (
              <div className={classes.chartContentWrap}>
                <ApexChart
                  type="area"
                  className={classes.chartContent}
                  options={optionAWC}
                  series={awc}
                  height={'100%'}
                />
              </div>
            ) : (
              ''
            )}

            {cadenceShow ? (
              <div className={classes.chartContentWrap}>
                <ApexChart
                  type="area"
                  className={classes.chartContent}
                  options={optionC}
                  series={cadence}
                  height={'100%'}
                />
              </div>
            ) : (
              ''
            )}

            {heartrateShow ? (
              <div className={classes.chartContentWrap}>
                <ApexChart
                  type="area"
                  className={classes.chartContent}
                  options={optionH}
                  series={heartrate}
                  height={'100%'}
                />
              </div>
            ) : (
              ''
            )}

            {altitude.length > 0 ? (
              <div className={classes.chartAltitudeWrap}>
                <ApexChart
                  type="area"
                  className={classes.chartContent}
                  options={options.altitude}
                  series={altitude}
                  height={'100%'}
                />
              </div>
            ) : (
              ''
            )}
          </Box>
        </React.Fragment>
      );
    }
  }

  if (activityId > 0 && streamType === 'comp') {
    return (
      <React.Fragment>
        <ActivityStream
          options={ChartOptions}
          altitudeMax={altitudeMax}
          streamData={streamData}
        />
      </React.Fragment>
    );
  } else if (activityId > 0 && streamType === 'org') {
    return (
      <React.Fragment>
        <ActivityStream
          options={ChartOptions}
          altitudeMax={altitudeMax}
          streamData={streamData}
        />
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <Summary options={ChartOptions} summaryData={summaryData} />
      </React.Fragment>
    );
  }
};

TimeLine.propTypes = {
  className: PropTypes.string
};

export default withRouter(TimeLine);
