import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
//
import PropTypes from 'prop-types';
//
import { updatePostApi } from 'utils/clientApis';
import { getImageUrl } from 'utils/getImageUrl';
//
import { makeStyles } from '@mui/styles';
import {
  Box,
  Fab,
  Button,
  TextField,
  Typography,
  Container,
  FormHelperText
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const useStyles = makeStyles(theme => ({
  root: {},
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid',
    display: 'flex',
    alignItems: 'center'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    lineHeight: '28px',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  textField: {
    margin: '8px 0'
  },
  uploadBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100% - 16px)',
    gap: '8px',
    borderRadius: '4px',
    border: '1px dashed var(--divider, rgba(0, 0, 0, 0.12))',
    padding: '24px 16px',
    cursor: 'pointer'
  },
  buttonWrap: {
    display: 'flex',
    width: '100%',
    padding: '16px'
  },
  image: {
    borderRadius: '6px',
    width: '100%',
    overflow: 'hidden'
  },
  imagePreviews: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    width: '100%'
    // [theme.breakpoints.down("md")]: {
    //     width: "85vw",
    // },
  },
  xButton: {
    color: 'white',
    position: 'absolute',
    right: '4px',
    top: '4px',
    height: '16px',
    cursor: 'pointer'
  },
  canvas: {
    backgroundColor: theme.palette.background.paper,
    padding: '16px 16px',
    boxShadow: theme.boxShadow.primary,
    borderRadius: '4px',
    marginTop: '12px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  }
}));

function EditPost({
  title,
  content,
  clubPostFiles,
  pelotonId,
  postId,
  handleClosePop,
  handleFetchPost
}) {
  const classes = useStyles();

  // 게시글 본문에서 prop으로 기존 게시글 내용을 받아옵니다.
  useEffect(() => {
    setFormState({
      data: {
        title: title,
        content: content,
        isPublic: true,
        clubPostCategoryId: 1
      }
    });
    // 이미지 파일은 보통 3가지 사이즈로 등록 되어있습니다.
    // 이 항목은, 해당 파일들을 originId로 그룹화 하고, 필요한 "SMALL" 사이즈를 반환합니다.
    const groupedImages = groupByOriginId(clubPostFiles);
    Object.entries(groupedImages).forEach(([originId, images]) => {
      const filePath = getImageUrl(images, 'SMALL');
      setImageThumbs(currentFiles => [
        ...currentFiles,
        {
          filePath: filePath,
          originId: originId
        }
      ]);
    });
  }, []);

  // originId를 key로 하여, prop으로 들어오는 이미지 배열을 그룹화 합니다.
  const groupByOriginId = imageArray => {
    return imageArray.reduce((acc, image) => {
      const key = image.originId;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(image);
      return acc;
    }, {});
  };

  // 실제 이미지 데이터의 핸들링을 위한 상태
  const [formImages, setFormImages] = useState([]);
  // 이미지 미리보기를 위한 상태
  const [imageThumbs, setImageThumbs] = useState([]);
  const [formState, setFormState] = useState({
    data: {
      title: '',
      content: '',
      isPublic: true,
      clubPostCategoryId: 1,
      deleteImageIds: []
    }
  });
  const fileInputRef = useRef(null);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const inputFields = ['title'];
      const currentIndex = inputFields.findIndex(
        field => field === event.target.name
      );
      if (currentIndex < inputFields.length - 1) {
        const nextField = inputFields[currentIndex + 1];
        document.getElementsByName(nextField)[0].focus();
      } else {
        handleEditPost(event);
      }
    }
  };

  const handleChange = event => {
    event.persist();

    setFormState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [event.target.name]: event.target.value
      }
    }));
  };

  // 이미지 업로드 핸들러
  const handleImageUpload = async event => {
    const uploadedFiles = Array.from(event.target.files);
    // "sample.jpeg"의 형태로는 사용할 수 없어, URI 형태로 변환해주는 작업을 진행합니다. imagePreviewMaker()
    const newImageThumbs = await imagePreviewMaker(uploadedFiles);

    setFormImages(currentFiles => {
      const updatedFiles =
        currentFiles && Array.isArray(currentFiles)
          ? [...currentFiles, ...uploadedFiles.map(file => ({ files: file }))]
          : uploadedFiles.map(file => ({ files: file }));
      return updatedFiles;
    });
    setImageThumbs(currentThumbs => [
      ...currentThumbs,
      ...newImageThumbs.map(uri => ({ filePath: uri, originId: null }))
    ]);
  };

  // 사용할 수 있는 URI로 이미지 file path를 변환해줍니다.
  const imagePreviewMaker = async files => {
    try {
      const newImageURIs = await Promise.all(
        files.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
          });
        })
      );
      return newImageURIs;
    } catch (error) {
      console.error('이미지를 불러오는 중 오류가 발생했습니다:', error);
    }
  };

  // 이미지 삭제 핸들러
  const handleRemoveImage = (originId, index) => {
    // formState에 담겨 서버로 보내지는 '삭제된 이미지의 id'를 setState합니다.
    setFormState(prevState => {
      const deleteImageIds = Array.isArray(prevState.data.deleteImageIds)
        ? prevState.data.deleteImageIds
        : [];
      if (originId) {
        return {
          ...prevState,
          data: {
            ...prevState.data,
            // 이전 deleteImageIds 배열에 originId 추가
            deleteImageIds: [...deleteImageIds, originId]
          }
        };
      } else {
        return prevState;
      }
    });
    // originId가 존재하는 기존 게시글의 이미지를 삭제할 경우
    if (originId) {
      setImageThumbs(currentThumbs =>
        currentThumbs.filter(image => image.originId !== originId)
      );
      setFormImages(currentFiles =>
        currentFiles.filter(image => image.originId !== originId)
      );
    }
    // 신규 이미지는 대상의 index를 key로 하여 삭제를 진행
    else {
      setImageThumbs(currentThumbs =>
        currentThumbs.filter((_, i) => i !== index)
      );
      setFormImages(currentFiles => currentFiles.filter((_, i) => i !== index));
    }
  };

  const handleEditPost = async event => {
    event.preventDefault();

    try {
      const response = await updatePostApi(
        formState?.data,
        formImages,
        pelotonId,
        postId
      );
      // response && console.log("성공", response)
      if (response.data.success) {
        handleFetchPost();
        handleClosePop();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box className={classes.canvas}>
      <form className={classes.createForm}>
        <TextField
          className={classes.textField}
          // error={hasError('title')}
          fullWidth
          helperText={'게시글의 제목을 입력하세요.'}
          label={'제목'}
          name="title"
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          type="text"
          value={formState.data?.title || ''}
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.input
            }
          }}
        />
        <TextField
          className={classes.textField}
          // error={hasError('content')}
          fullWidth
          helperText={'게시글의 내용을 입력하세요.'}
          label={'내용'}
          name="content"
          multiline
          rows={4}
          onChange={handleChange}
          type="text"
          value={formState.data?.content || ''}
          variant="outlined"
          InputProps={{
            classes: {
              input: classes.input
            }
          }}
        />
        {!imageThumbs ? (
          <>
            <Container className={classes.uploadBox}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none">
                <path
                  d="M14.5 2H6.5C5.4 2 4.51 2.9 4.51 4L4.5 20C4.5 21.1 5.39 22 6.49 22H18.5C19.6 22 20.5 21.1 20.5 20V8L14.5 2ZM18.5 20H6.5V4H13.5V9H18.5V20ZM8.5 15.01L9.91 16.42L11.5 14.84V19H13.5V14.84L15.09 16.43L16.5 15.01L12.51 11L8.5 15.01Z"
                  fill="#4DB6AC"
                />
              </svg>
              <div
                className={classes.textContainer}
                onClick={() => fileInputRef.current.click()}>
                <Typography
                  align="center"
                  color="primary"
                  variant="body1"
                  style={{
                    textDecoration: 'underline',
                    marginRight: '2px'
                  }}>
                  클릭하여
                </Typography>
                <input
                  id="upload-input"
                  type="file"
                  accept="image/.JPG, .JPEG, .PNG"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                />
                <Typography
                  align="center"
                  color="textSecondary"
                  variant="body1">
                  펠로톤 이미지 업로드
                </Typography>
              </div>
              <Typography align="center" color="textSecondary" variant="body1">
                PNG, JPG, JPEG (max. 3MB)
              </Typography>
            </Container>
            <FormHelperText style={{ textAlign: 'start' }}>
              이미지를 등록하세요. (1280*720px 권장)
            </FormHelperText>
          </>
        ) : (
          <>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'start',
                alignItems: 'center',
                width: '100%'
              }}>
              {imageThumbs &&
                imageThumbs.length > 0 &&
                imageThumbs.map((image, idx) => {
                  return (
                    <Box
                      key={image.originId}
                      style={{
                        position: 'relative',
                        marginRight: '8px',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '25%',
                        alignItems: 'center',
                        overflow: 'hidden',
                        height: '100%'
                      }}>
                      <img
                        className={classes.image}
                        src={image.filePath}
                        alt={`imagePreview ${idx}`}
                      />
                      <HighlightOffIcon
                        onClick={() => handleRemoveImage(image.originId, idx)} // originId와 idx(인덱스)를 전달
                        className={classes.xButton}
                      />
                    </Box>
                  );
                })}
            </Box>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center'
              }}
              className={classes.imagePreviews}>
              <Fab
                color="primary"
                size="small"
                style={{
                  display: 'flex',
                  marginLeft: '8px'
                }}
                onClick={() => fileInputRef.current.click()}>
                <input
                  id="upload-input"
                  type="file"
                  accept="image/*"
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                  onChange={handleImageUpload}
                />
                <AddCircleIcon style={{ color: 'white' }} />
              </Fab>
            </Box>
          </>
        )}
        <div className={classes.buttonWrap}>
          <Button
            className={classes.Button}
            color="primary"
            fullWidth
            size="large"
            onClick={handleClosePop}
            variant="text">
            취소
          </Button>
          <Button
            className={classes.Button}
            color="primary"
            disabled={!formState.data?.title || !formState.data?.content}
            fullWidth
            size="large"
            onClick={handleEditPost}
            variant="contained">
            수정
          </Button>
        </div>
      </form>
    </Box>
  );
}

export default withRouter(EditPost);

EditPost.propTypes = {
  history: PropTypes.object
};
