import React, { useState, useEffect } from 'react';
import { withRouter, useHistory, useParams } from 'react-router-dom';

import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
//
import PropTypes from 'prop-types';

//
import {
  fetchPelotonURI,
  fetchSinglePostApi,
  fetchCommentsApi
} from 'utils/clientApis';
//
import { makeStyles } from '@mui/styles';
import { Box, Divider, Typography } from '@mui/material';
//
import Post from './Post';
import Comments from './Comments';
import PelotonLayout from 'views/Peloton/Common/PelotonLayout';

function Read() {
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();

  const { uriName, postId } = useParams();

  const prop = location.state;

  const loggedInUser = prop?.prop?.myClubUserResponse;
  const pelotonId = prop?.prop?.id;
  const clubName = prop?.prop?.name;
  const pageNum = prop?.pageNum;

  const userInfoNow = {
    role: loggedInUser?.clubRole,
    nickname: loggedInUser?.displayName
  };

  // post 관련 데이터
  const [post, setPost] = useState({});

  // comment 관련 데이터
  const [commentHistory, setCommentHistory] = useState([]);

  /**
   * @return : 게시글 데이터를 가져오기 위한 라이프 사이클.
   */
  useEffect(() => {
    handleFetchPelotonInfo();
    // 브라우저의 뒤로가기, 앞으로가기 이벤트 발생시에 작동
    const onPopState = () => {
      handleFetchPelotonInfo();
    };
    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, []);

  /**
   * 1. 링크로 사용자 접속
   * 2. 사용자가 이 클럽의 회원인 지 확인해야 함
   * 2-1. data의 myClubUserResponse가 데이터 배열인지, null인지로 가입 여부를 판단 가능
   * 3. 확인이 되면 페인팅
   * 4. null이면 리다이렉션
   */
  // Mount시에 작동
  const handleFetchPelotonInfo = async () => {
    try {
      const response = await fetchPelotonURI(uriName);
      if (
        response.myClubUserResponse.join === false ||
        response.myClubUserResponse.blocked === true
      ) {
        history.push(`/peloton/${uriName}`);
      } else {
        handleFetchPost();
        handleGetComments();
      }
    } catch (err) {
      console.error(err);
    }
  };

  // postData 요청
  const handleFetchPost = async () => {
    try {
      if (postId) {
        const response = await fetchSinglePostApi(uriName, postId);
        setPost(response.data.response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // comment 요청
  const handleGetComments = async () => {
    try {
      if (postId) {
        const response = await fetchCommentsApi(uriName, postId);
        setCommentHistory(response.data.response);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <PelotonLayout clubName={clubName}>
      {post && (
        <>
          <Box className={classes.canvas}>
            {/* 게시판 글 */}
            <Post
              post={post}
              userInfoNow={userInfoNow}
              uriName={uriName}
              postId={postId}
              pelotonId={pelotonId}
              handleFetchPost={handleFetchPost}
            />

            <Divider />

            {/* 댓글 및 댓글작성 */}
            <Comments
              commentHistory={commentHistory}
              userInfoNow={userInfoNow}
              pelotonId={pelotonId}
              postId={postId}
              handleGetComments={handleGetComments}
            />
            <Box className={classes.returnButtonContainer}>
              <Typography
                className={classes.footer}
                onClick={() =>
                  history.push(`/peloton/${uriName}/post?page=${pageNum}`, {
                    page: pageNum
                  })
                }
                variant="h6"
                color="primary"
                style={{ cursor: 'pointer', padding: '16px 0px' }}>
                게시글 목록
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </PelotonLayout>
  );
}

Read.propTypes = {
  history: PropTypes.object
};

export default withRouter(Read);

const useStyles = makeStyles(theme => ({
  root: {},
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  canvas: {
    backgroundColor: theme.palette.background.paper,
    padding: '0px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    marginTop: '12px',
    [theme.breakpoints.down('lg')]: {}
  },
  returnButtonContainer: {
    textAlign: 'center',
    paddingBottom: '16px'
  }
}));
