export function fillMissingData(data) {
  let filledData = [];

  for (let i = 0; i < data.length - 1; i++) {
    let current = data[i];
    let next = data[i + 1];

    filledData.push(current);

    let diff = next.x - current.x;
    if (diff > 1) {
      for (let j = current.x + 1; j < next.x; j++) {
        filledData.push({ x: j, y: 0 });
      }
    }
  }

  filledData.push(data[data.length - 1]); // 마지막 값 추가
  return filledData;
}
