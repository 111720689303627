import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { DynamicFontTypography } from 'components/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  heading: {
    fontSize: 'clamp(24px, 3.0vw, 32px)',
    lineHeight: 'clamp(32px, 3.4vw, 40px)',
    fontWeight: 500,
    maxWidth: '600px',
    [theme.breakpoints.down('lg')]: {}
  }
}));

export default function HeroArticle({ title, img, url }) {
  const { t } = useTranslation('landing');
  const classes = useStyles();

  return (
    <Box
      width="100%"
      height={{ xs: 300, md: 400 }}
      maxWidth="1200px"
      borderRadius="4px"
      display="flex"
      position="relative"
      flexDirection="column"
      alignItems="start"
      justifyContent="center"
      marginBottom={4}
      overflow="hidden">
      <Box
        component="img"
        src={img}
        position="absolute"
        width="100%"
        height="100%"
        sx={{
          objectFit: 'cover',
          objectPosition: 'center',
          borderRadius: '4px'
        }}
      />
      <Box
        position="absolute"
        width="100%"
        height="100%"
        bgcolor="rgba(0, 0, 0, 0.5)"
        borderRadius="4px"
      />
      <Box
        position="relative"
        zIndex={1}
        padding={{ xs: 3, md: 6 }}
        width={{ xs: '100%', md: '80%' }}>
        <DynamicFontTypography
          className={classes.heading}
          fontWeight={600}
          color="white"
          paddingBottom={2}>
          {title}
        </DynamicFontTypography>
        <Button
          component={Link}
          to={url}
          target="_blank"
          variant="contained"
          size="small"
          sx={{
            marginTop: '10px',
            bgcolor: '#333333',
            '&:hover': { bgcolor: '#009688' }
          }}>
          <DynamicFontTypography
            color="#FFFFFF"
            sx={{
              fontSize: '14px'
            }}>
            {t('Buttons.readMore')}
          </DynamicFontTypography>
        </Button>
      </Box>
    </Box>
  );
}
