import React, { useState, useEffect } from 'react';
import ReactDOM, { render } from 'react-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

//import './App.css';

/*
import {
//  BikeUI,		
//  Budget,
//  TotalUsers,
//  HowToUse,
//  Segment,
//  TotalProfit,
//  LatestSales,
//  UsersByDevice,
//  LatestProducts,
//  LatestOrders
} from './Tab';
*/

import _ from 'lodash';
import axios from 'axios';
import auth from '../../utils/auth';

import MapGL, {
  Marker,
  Source,
  Layer,
  GeolocateControl,
  NavigationControl,
  FlyToInterpolator
} from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

import decodePolyline from 'decode-google-map-polyline';

import mapboxgl from 'mapbox-gl';
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line
// eslint-disable-next-line import/no-webpack-loader-syntax

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  tab: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px 4px 0 0'
  },
  tabPanel: {
    padding: '16px',
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '0 0 4px 4px'
  }
}));

const RideOnTab = () => {
  const classes = useStyles();

  const extraInfo = auth.getExtraInfo()
    ? auth.getExtraInfo()
    : { pretest: 'none' };
  const gears = JSON.parse(extraInfo.bikes_json);

  //  const [gearinfo, setGearinfo] = React.useState(gears[0]);
  //  const [gearTab, setGearTab ] = React.useState(0);

  useEffect(() => {}, []);

  const handleTab = (event, newValue) => {};

  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

  const [viewport, setViewport] = useState({
    latitude: 37.5326,
    longitude: 126.924612,
    width: '100%',
    height: '500px',
    zoom: 10
  });

  function TabList(props) {
    return (
      <Tabs
        value={0}
        onChange={handleTab}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example">
        <Tab key={0} label={'Field'} />
        <Tab key={1} label={'Indoor'} />
      </Tabs>
    );
  }

  const pData =
    'neuaEejkbUEGc@j@PXl@p@P\\a@f@GHyDtEgC`DoCfDzHbQp@rAbH`JdBtBrCjDn@p@dDbDfIvHfD~CrK~Jo@z@uCrDmJnL}^ld@mVjZmQrTgArAFJ';
  const pData2 = [
    [1.023194, 7.946528],
    [0.93005, 8.554639],
    [1.431726, 8.61838],
    [1.483318, 9.000202],
    [2.005096, 9.002147],
    [2.583486, 9.396939],
    [2.439416, 9.782727],
    [2.718134, 10.336571],
    [2.760431, 11.092622],
    [3.587083, 11.990781],
    [3.544915, 12.64725],
    [3.74451, 13.056562],
    [3.902861, 13.039418],
    [4.411557, 13.622376],
    [4.932224, 13.788399],
    [4.736514, 14.462161],
    [5.25854, 14.90048],
    [5.336759, 15.593429],
    [5.800658, 15.903908],
    [5.753171, 16.280243],
    [6.125972, 17.373351],
    [6.061882, 18.806859],
    [5.773197, 19.505027],
    [5.780191, 20.844999],
    [5.428159, 22.666003],
    [5.713896, 22.944738],
    [5.763205, 23.654738],
    [7.397073, 25.060067],
    [7.352812, 25.732464]
  ];

  let polylineData = [];

  let i = 0;
  _.forEach(decodePolyline(pData), function(l) {
    polylineData.push([Number(l.lng), Number(l.lat)]);
  });

  const geoJson = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [...polylineData]
        }
      }
    ]
  };

  const geoJson2 = {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [...pData2]
        }
      }
    ]
  };

  const layerStyle = {
    id: 'Austrailia',
    type: 'line',
    paint: {
      'line-color': '#007cbf',
      'line-width': 5,
      'line-opacity': 0.7
      //      'line-gradient': []
    },
    'line-cap': 'round',
    visibility: 'visible'
  };

  const layerStyle2 = {
    id: 'Afreeca',
    type: 'line',
    paint: {
      'line-color': '#007cbf',
      'line-width': 5,
      'line-opacity': 0.7
      //      'line-gradient': []
    },
    'line-cap': 'round',
    visibility: 'visible'
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default" className={classes.tab}>
        <TabList />
      </AppBar>
      <Box className={classes.tabPanel}>
        <MapGL
          {...viewport}
          //        transitionDuration={500}
          //        transitionInterpolator={new FlyToInterpolator()}
          onViewportChange={viewport => {
            setViewport(viewport);
          }}
          mapStyle="mapbox://styles/mapbox/streets-v9"
          mapboxApiAccessToken={MAPBOX_TOKEN}>
          <Source id="my-data" type="geojson" data={geoJson}>
            <Layer {...layerStyle} />
          </Source>

          <Source id="my-data2" type="geojson" data={geoJson2}>
            <Layer {...layerStyle2} />
          </Source>

          <div className="navi-control">
            <NavigationControl />
          </div>
          <div className="current-location-control">
            <GeolocateControl style={{ position: 'absolute', right: '0px' }} />
          </div>
          <div className="marker-list">
            <Marker
              latitude={38.5326}
              longitude={127.024612}
              offsetLeft={-21}
              offsetTop={-40}>
              <Typography
                style={{
                  width: '40px',
                  textAlign: 'center',
                  lineHeight: '40px',
                  fontSize: '40px'
                }}>
                📍
              </Typography>
            </Marker>
            <Marker
              latitude={37.5326}
              longitude={127.024612}
              offsetLeft={-21}
              offsetTop={-40}>
              <Typography
                style={{
                  width: '40px',
                  textAlign: 'center',
                  lineHeight: '40px',
                  fontSize: '40px'
                }}>
                📍
              </Typography>
            </Marker>
          </div>
        </MapGL>
      </Box>
    </div>
  );
};

export default RideOnTab;
