import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography,
  Avatar
} from '@mui/material';

import { useTranslation } from 'react-i18next';

//import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import RankIcon from '@mui/icons-material/FormatListNumberedRounded';
import FtpIcon from '@mui/icons-material/OfflineBoltRounded';
import CategoryIcon from '@mui/icons-material/GroupWork';
import BatteryChargingIcon from '@mui/icons-material/BatteryCharging60';
import FavoriteIcon from '@mui/icons-material/Favorite';
import Vo2maxIcon from '@mui/icons-material/NetworkCheckRounded';

import { RankDialog } from './RankDialog';
import { FtpDialog } from './FtpDialog';
import { HrDialog } from './HrDialog';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    '& .MuiCardContent-root': {
      padding: '12px 16px',
      [theme.breakpoints.down('lg')]: {
        maxHeight: '56px',
        padding: '6px 10px'
      }
    },
    '& .MuiTypography-h3': {
      [theme.breakpoints.down('lg')]: {
        lineHeight: '12px'
      }
    }
  },
  cardTitle: {
    [theme.breakpoints.down('md')]: {
      fontSize: '12px'
    }
  },
  cardContent: {
    display: 'flex',
    [theme.breakpoints.down('lg')]: {
      fontSize: '14px'
    }
  },
  category: {
    color: '#8cc1ec',
    marginRight: '12px'
  },
  legendText: {
    marginLeft: '10px',
    paddingTop: '6px',
    [theme.breakpoints.down('lg')]: {
      fontSize: '11px',
      marginLeft: '6px',
      marginTop: '-3px',
      paddingTop: '0px'
    }
  },
  avatar: {
    backgroundColor: '#f5f5f5',
    height: 60,
    width: 60,
    marginTop: '2px',
    [theme.breakpoints.down('lg')]: {
      height: 40,
      width: 40
    }
  },
  icon: {
    height: 40,
    width: 40,
    [theme.breakpoints.down('lg')]: {
      height: 32,
      width: 32
    }
  }
}));

const StatusCard = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const {
    className,
    extraInfo,
    status,
    maxHrActivity,
    cardTitle,
    category,
    cardContent,
    cardDesc,
    rankArray,
    ftpArray,
    wkg,
    weight,
    summaryData
  } = props;
  const [statusDialogOpen, setStatusDialogOpen] = React.useState(false);
  const [selectDialog, setSelectDialog] = React.useState('');
  const [dialogTitle, setDialogTitle] = React.useState('');
  const classes = useStyles();

  //console.log(status + " " + maxHrActivity);

  let themeColor = { color: '#aaaaaa' };
  let cardText = 0.0;
  let legendText = '';

  if (status === 'rank') {
    themeColor = { color: '#76b5e8' };
    cardText = cardContent;
    legendText = '%';
  } else if (status === 'category') {
    themeColor = { color: '#4db6ac' };
    cardText = cardContent;
    legendText = cardDesc;
  } else if (status === 'ftp') {
    themeColor = { color: '#ffd357' };
    cardText = Math.round(cardContent);
    legendText = 'watts';

    if (wkg) {
      cardText = Math.round((cardText / weight) * 100) / 100;
      legendText = 'w/kg';
    }
  } else if (status === 'awc') {
    themeColor = { color: '#f06292' };
    cardText = Math.round(cardContent / 100) / 10;
    legendText = 'kJ';
  } else if (status === 'vo2max') {
    themeColor = { color: '#4db6ac' };
    cardText = Math.round(cardContent * 10) / 10;
    legendText = 'ml/kg/min';
  } else if (status === 'maxHr') {
    themeColor = { color: '#f06292' };
    cardText = Math.round(cardContent);
    legendText = 'bpm';
  } else {
    themeColor = { color: '#aaaaaa' };
    cardText = '';
  }

  const getIcon = icon => {
    switch (icon) {
      case 'rank':
        return <RankIcon style={themeColor} className={classes.icon} />;
      case 'ftp':
        return <FtpIcon style={themeColor} className={classes.icon} />;
      case 'awc':
        return (
          <BatteryChargingIcon style={themeColor} className={classes.icon} />
        );
      case 'vo2max':
        return <Vo2maxIcon style={themeColor} className={classes.icon} />;
      case 'category':
        return <CategoryIcon style={themeColor} className={classes.icon} />;
      case 'maxHr':
        return <FavoriteIcon style={themeColor} className={classes.icon} />;
    }
  };

  function handleDialogOpen() {
    if (status === 'category' || status === 'awc') return false;

    if (status === 'ftp') {
      setStatusDialogOpen(true);
      setSelectDialog('FtpDialog');
      setDialogTitle(t('Dashboard.ftpDialog'));
    } else if (status === 'rank') {
      setStatusDialogOpen(true);
      setSelectDialog('RankDialog');
      setDialogTitle(t('Dashboard.rankDialog'));
    } else if (status === 'maxHr') {
      console.log(status);

      setStatusDialogOpen(true);
      setSelectDialog('HrDialog');
      setDialogTitle(t('Dashboard.maxHrDialog'));
    }
  }

  function handleDialogClose() {
    setStatusDialogOpen(false);
  }

  function DialogDisplay(props) {
    const { selectDialog } = props;

    //오류제거
    if (!ftpArray) return false;

    const maxHr = extraInfo.max_hr;

    const ftp_type = extraInfo.ftp_type;
    const ftp = extraInfo.ftp;
    const tte = extraInfo.tte;

    const wftp = extraInfo.wftp;
    const wtte = extraInfo.wtte;
    const tftp = extraInfo.cp;

    const w_prime = extraInfo.w_prime;

    const p300 = ftpArray.p300;
    const p2400 = ftpArray.p2400;
    const p3600 = ftpArray.p3600;
    const best_index = ftpArray.best_index;

    if (selectDialog === 'FtpDialog') {
      const ftpWeeks = summaryData.ftp;

      return (
        <FtpDialog
          ftpWeeks={ftpWeeks}
          ftp_type={ftp_type}
          ftp={ftp}
          tte={tte}
          wftp={wftp}
          wtte={wtte}
          weight={weight}
          wkg={wkg}
          tftp={tftp}
          w_prime={w_prime}
          p3600={p3600}
          best_index={best_index}
        />
      );
    } else if (selectDialog === 'HrDialog') {
      return <HrDialog maxHr={maxHr} maxHrActivity={maxHrActivity} />;
    } else if (selectDialog === 'RankDialog') {
      return (
        <RankDialog
          weight={weight}
          wkg={wkg}
          p300={p300}
          p2400={p2400}
          rankArray={rankArray}
        />
      );
    }
  }

  return (
    <Card className={clsx(classes.root, className)}>
      <CardActionArea onClick={handleDialogOpen}>
        <CardContent>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography
                className={classes.cardTitle}
                gutterBottom
                variant="body2">
                {cardTitle}
              </Typography>
              <div style={{ display: 'flex' }}>
                <Typography className={classes.cardContent} variant="h3" noWrap>
                  {category ? (
                    <Typography
                      className={classes.category}
                      variant="inherit"
                      noWrap>
                      {category}
                    </Typography>
                  ) : (
                    ''
                  )}
                  {cardText}
                </Typography>
                <Typography
                  className={classes.legendText}
                  color="textSecondary"
                  noWrap>
                  {legendText}
                </Typography>
              </div>
            </Grid>
            <Grid item>
              <Avatar className={classes.avatar}>{getIcon(status)}</Avatar>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>

      <Dialog open={statusDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <Divider />
        <DialogContent style={{ padding: '8px' }}>
          <DialogDisplay selectDialog={selectDialog} />
        </DialogContent>
      </Dialog>
    </Card>
  );
};

StatusCard.propTypes = {
  className: PropTypes.string
};

export default StatusCard;
