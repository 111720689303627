/**
 * @return : 유지 보수성 개선을 위해 개발중 입니다.
 */

// useAutoSize hooks 사용 시.
export const imageSize = {
  SM: 'SMALL',
  MD: 'MEDIUM',
  LG: 'LARGE',
  OG: 'ORIGIN'
};

export const formatNumber = number => {
  // 4자리 숫자일 경우, 콤마를 삽입
  const numberStr = number.toString();
  if (numberStr.length === 4) {
    return numberStr.substr(0, 1) + ',' + numberStr.substr(1);
  }
  return numberStr;
};

export const statusTracker = status => {
  if (status === 'OPEN') {
    return '접수중';
  } else if (status === 'CLOSED') {
    return '완료';
  } else if (status === 'CANCEL') {
    return '취소';
  }
};

export const paceTracker = pace => {
  if (pace === 'BEGINNER') {
    return '입문';
  } else if (pace === 'RECOVERY') {
    return '리커버리';
  } else if (pace === 'INTERMEDIATE') {
    return '중급';
  } else if (pace === 'ADVANCED') {
    return '상급';
  } else if (pace === 'ELITE') {
    return '최상급';
  }
};
