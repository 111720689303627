import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  IconButton
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  RenderAfterNavermapsLoaded,
  NaverMap,
  Marker,
  Polyline
} from 'react-naver-maps';

import mockData from './data';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const Segment = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [products] = useState(mockData);

  //http://13.125.7.72:1337/segments?name_contains=%EB%82%A8%EC%82%B0&_sort=star_count:DESC

  const MapLoad = ({ naver }) => {
    return (
      <NaverMap
        mapDivId={'react-naver-map'}
        style={{
          width: '100%',
          height: '400px'
        }}
        defaultCenter={{ lat: 37.3595704, lng: 127.105399 }}
        defaultZoom={10}
        zoomControl={true}
        zoomControlOptions={{ position: 1 }}
        mapTypeControl={true}
        mapTypeControlOptions={{ style: 'BUTTON', position: 3 }}>
        <Marker
          position={{ lat: 37.3595704, lng: 127.105399 }}
          animation={2}
          onClick={() => {
            alert('여기는 네이버 입니다.');
          }}
        />

        <Polyline
          path={[
            { lat: 37.365620929135716, lng: 127.1036195755005 },
            { lat: 37.365620929135716, lng: 127.11353302001953 },
            { lat: 37.3606921307849, lng: 127.10452079772949 },
            { lat: 37.36821310838941, lng: 127.10814714431763 },
            { lat: 37.360760351656545, lng: 127.11299657821654 },
            { lat: 37.365620929135716, lng: 127.1036195755005 }
          ]}
          // clickable // 사용자 인터랙션을 받기 위해 clickable을 true로 설정합니다.
          strokeColor={'#5347AA'}
          strokeStyle={'longdash'}
          strokeOpacity={0.5}
          strokeWeight={5}
        />
      </NaverMap>
    );
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        subtitle={`${products.length} in total`}
        title="Latest products"
      />
      <Divider />

      <RenderAfterNavermapsLoaded ncpClientId={'qw36fccm6s'}>
        <MapLoad />
      </RenderAfterNavermapsLoaded>

      <CardContent className={classes.content}>
        <List>
          {products.map((product, i) => (
            <ListItem divider={i < products.length - 1} key={product.id}>
              <ListItemAvatar>
                <img
                  alt="Product"
                  className={classes.image}
                  src={product.imageUrl}
                />
              </ListItemAvatar>
              <ListItemText
                primary={product.name}
                secondary={`Updated ${product.updatedAt.fromNow()}`}
              />
              <IconButton edge="end" size="small">
                <MoreVertIcon />
              </IconButton>
            </ListItem>
          ))}
        </List>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text">
          View all <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  );
};

Segment.propTypes = {
  className: PropTypes.string
};

export default Segment;
