import React, { useEffect, useState } from 'react';
//
import { useTranslation } from 'react-i18next';
//
import { getUsersInfoApi, getUserInfoApi } from 'utils/clientApis';

import { ProfilePop } from '../../../Common/ProfilePopModal';
//
import { makeStyles } from '@mui/styles';
import {
  Avatar,
  Box,
  Button,
  Table,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
  Typography
} from '@mui/material';
//
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
//
import MemberModal from '../../../Common/MemberModal';
import CustomCircularProgress from 'views/Peloton/Common/CustomCircularProgress';

const useStyles = makeStyles(theme => ({
  title: {
    fontSize: '16px',
    color: theme.palette.text.primary,
    fontWeight: '700',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  quitButton: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  memberTable: {
    marginBottom: '28px'
  }
}));

/**
 * @property: loggedInUser는 Club.js에서 저장된 userState라는 State입니다.
 *            펠로톤 클럽 메인화면을 통해 접근한 사용자의 간단한 가입 정보와, 권한이 담긴 데이터 객체입니다.
 *            여기 Members.js에서는 사용자 등급/ 권한에 따라 달라지는 view를 그리기 위해 필요한 요소입니다.
 */

const Members = prop => {
  const classes = useStyles();
  const { t } = useTranslation(['page']);
  const myNickname = prop.data.myClubUserResponse?.displayName;
  const pelotonId = prop.data.id;

  const [userData, setUserData] = useState([]);
  /**
   *  ADMIN 소유자
   *  MANAGER 관리자
   *  MEMBER 일반회원
   *  data.join === false -> 가입신청 미승인 회원
   *  data.join === true -> 가입신청 승락 회원
   */
  const [userRole, setUserRole] = useState('MEMBER');

  // 가입 대기자
  const [joinUsers, setjoinUsers] = useState([]);

  // 차단된 사용자
  const [blockedUsers, setBlockedUsers] = useState([]);

  // 일반 가입자
  const [normalUsers, setNormalUsers] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);

  useEffect(() => {
    setUserRole(prop.data.myClubUserResponse.clubRole);
    handlefetchUser();
    // console.log('prop.data', prop.data)
  }, []);

  // 회원목록에서 가입신청자, 블럭 유저 정보를 별도로 관리.
  const handlefetchUser = async () => {
    setIsDataLoading(true);
    try {
      const response = await getUsersInfoApi(prop.data.id);
      if (response) {
        setUserData(response.data.response.content);

        let newJoinedUsers = [];
        let newBlockedUsers = [];
        let newNormalUsers = [];

        for (let user of response.data.response.content) {
          // console.log('user', user)
          if (user.blocked === true) {
            // 차단된 사용자
            newBlockedUsers.push(user);
          } else if (user.join === false) {
            // 가입신청자
            newJoinedUsers.push(user);
          } else {
            // 일반 가입자
            newNormalUsers.push(user);
          }
        }
        newNormalUsers.sort((a, b) => {
          const rolePriority = {
            ADMIN: 1,
            MANAGER: 2,
            MEMBER: 3
          };

          // 역할에 따른 우선순위 비교
          if (rolePriority[a.clubRole] < rolePriority[b.clubRole]) return -1;
          if (rolePriority[a.clubRole] > rolePriority[b.clubRole]) return 1;

          // 역할이 같을 경우 regDate로 정렬
          if (a.regDate < b.regDate) return -1;
          if (a.regDate > b.regDate) return 1;

          return 0; // 모든 조건이 동일하면 순서를 변경하지 않음
        });
        setjoinUsers(newJoinedUsers);
        setBlockedUsers(newBlockedUsers);
        setNormalUsers(newNormalUsers);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsDataLoading(false);
    }
  };

  /**
   * @return: Modal Controller
   */
  const [open, setOpen] = useState(false);
  const [selectedModal, setSelectedModal] = useState('');
  const [selectedUser, setSelectedUser] = useState();

  const handleClickOpen = (label, prop) => {
    setSelectedModal(label);
    setSelectedUser(prop);
    setOpen(true);
  };

  /**
   * @return: 프로필 이미지 팝업
   */
  const [profileOpen, setProfileOpen] = useState(false);

  const handleClickProfile = userId => {
    setSelectedUser(userId);
    setProfileOpen(true);
  };

  /**
   * @return: 멤버 등급 포매터
   */

  const gradeTracker = userRole => {
    if (userRole === 'MEMBER') {
      return '일반';
    } else if (userRole === 'MANAGER') {
      return '관리자';
    } else if (userRole === 'ADMIN') {
      return '소유자';
    }
  };

  const getUserInfo = () => {
    return getUserInfoApi(pelotonId, selectedUser);
  };

  return (
    <>
      {isDataLoading ? (
        <CustomCircularProgress />
      ) : (
        <>
          {(userRole === 'ADMIN' || userRole === 'MANAGER') &&
            joinUsers &&
            joinUsers.length > 0 && (
              <>
                <Typography
                  className={classes.title}
                  style={{ marginBottom: '16px' }}>
                  가입 신청 회원
                </Typography>
                <TableContainer
                  component={Paper}
                  key={'join'}
                  style={{ marginBottom: '24px' }}>
                  <Table
                    sx={{ minWidth: 390 }}
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">w/kg</TableCell>
                        <TableCell align="center" style={{ minWidth: '80px' }}>
                          승인
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: '80px' }}>
                          거절
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {joinUsers.map(user => (
                        <TableRow
                          key={user.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}>
                            <Avatar
                              src={user.profileImage}
                              onClick={() => handleClickProfile(user.id)}
                              style={{
                                width: '34px',
                                height: '34px',
                                marginRight: '8px',
                                cursor: 'pointer'
                              }}
                            />
                            {user.displayName}
                          </TableCell>
                          <TableCell align="center">{`${user.wkg}.xx`}</TableCell>
                          <TableCell align="center">
                            <CheckCircleOutlineOutlinedIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleClickOpen('accept', {
                                  userNickname: user.displayName,
                                  clubUserId: user.id
                                })
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <DoNotDisturbOnOutlinedIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleClickOpen('reject', {
                                  userNickname: user.displayName,
                                  clubUserId: user.id
                                })
                              }
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
          {normalUsers && normalUsers.length > 0 && (
            <>
              <Typography
                className={classes.title}
                style={{ marginBottom: '16px' }}>
                회원목록
              </Typography>
              <TableContainer
                component={Paper}
                key={'member'}
                className={classes.memberTable}>
                <Table
                  sx={{ minWidth: 390, marginBottom: '16px' }}
                  size="small"
                  aria-label="a dense table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center" style={{ minWidth: '80px' }}>
                        w/kg
                      </TableCell>
                      <TableCell align="center" style={{ minWidth: '80px' }}>
                        등급
                      </TableCell>
                      {userRole === 'ADMIN' && (
                        <TableCell align="center" style={{ minWidth: '100px' }}>
                          등급 변경
                        </TableCell>
                      )}
                      {(userRole === 'MANAGER' || userRole === 'ADMIN') && (
                        <TableCell align="center" style={{ minWidth: '80px' }}>
                          강퇴
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {normalUsers &&
                      normalUsers.map((user, idx) => (
                        <React.Fragment key={user.id}>
                          <TableRow key={idx}>
                            <TableCell
                              component="th"
                              scope="row"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                minWidth: '80px'
                              }}>
                              <Avatar
                                src={user.profileImage}
                                onClick={() => handleClickProfile(user.id)}
                                style={{
                                  width: '34px',
                                  height: '34px',
                                  marginRight: '8px',
                                  cursor: 'pointer'
                                }}
                              />
                              {user.displayName}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ minWidth: '80px' }}>
                              {`${user.wkg}.xx`}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ minWidth: '80px' }}>
                              {gradeTracker(user.clubRole)}
                            </TableCell>
                            {userRole === 'ADMIN' && (
                              <TableCell
                                align="center"
                                style={{ minWidth: '80px' }}>
                                {user.clubRole !== 'ADMIN' &&
                                  user.displayName !== myNickname && (
                                    <>
                                      {user.clubRole === 'MANAGER' && (
                                        <ArrowDownwardIcon
                                          onClick={() =>
                                            handleClickOpen('promote', {
                                              clubUserId: user.id,
                                              userRole: user.clubRole,
                                              userNickname: user.displayName
                                            })
                                          }
                                          style={{ cursor: 'pointer' }}
                                        />
                                      )}
                                      {user.clubRole === 'MEMBER' && (
                                        <ArrowUpwardIcon
                                          onClick={() =>
                                            handleClickOpen('promote', {
                                              clubUserId: user.id,
                                              userRole: user.clubRole,
                                              userNickname: user.displayName
                                            })
                                          }
                                          style={{ cursor: 'pointer' }}
                                        />
                                      )}
                                    </>
                                  )}
                              </TableCell>
                            )}

                            {(userRole === 'MANAGER' ||
                              userRole === 'ADMIN') && (
                              <TableCell
                                align="center"
                                style={{ minWidth: '80px' }}>
                                {user.clubRole !== 'ADMIN' &&
                                  user.displayName !== myNickname && (
                                    <BlockOutlinedIcon
                                      onClick={() =>
                                        handleClickOpen('expel', {
                                          clubUserId: user.id,
                                          userNickname: user.displayName,
                                          pelotonId: pelotonId
                                        })
                                      }
                                      style={{ cursor: 'pointer' }}
                                    />
                                  )}
                              </TableCell>
                            )}
                          </TableRow>
                        </React.Fragment>
                      ))}
                  </TableBody>
                </Table>
                {open && (
                  <MemberModal
                    label={selectedModal}
                    openState={open}
                    setOpen={setOpen}
                    pelotonId={pelotonId}
                    uriName={prop.data?.uriName}
                    userData={selectedUser}
                    clubName={prop.data?.name}
                  />
                )}
                {profileOpen && (
                  <ProfilePop
                    openState={profileOpen}
                    setOpen={setProfileOpen}
                    getUserInfo={getUserInfo}
                  />
                )}
              </TableContainer>

              {userRole && (
                <Box className={classes.quitButton}>
                  <Box />
                  {userRole && userRole !== 'ADMIN' ? (
                    <Button
                      onClick={() => handleClickOpen('quit')}
                      color="primary"
                      variant="text"
                      size="medium">
                      <Typography
                        style={{ color: '#4DB6AC', fontWeight: 'bold' }}>
                        펠로톤 탈퇴
                      </Typography>
                    </Button>
                  ) : null}
                </Box>
              )}
            </>
          )}

          {(userRole === 'ADMIN' || userRole === 'MANAGER') &&
            blockedUsers &&
            blockedUsers.length > 0 && (
              <>
                <Typography
                  className={classes.title}
                  style={{ marginBottom: '16px' }}>
                  차단된 회원
                </Typography>
                <TableContainer
                  component={Paper}
                  key={'join'}
                  style={{ marginBottom: '24px' }}>
                  <Table
                    sx={{ minWidth: 390 }}
                    size="small"
                    aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>
                        <TableCell align="center">w/kg</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {blockedUsers.map(user => (
                        <TableRow
                          key={user.id}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 }
                          }}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center'
                            }}>
                            <Avatar
                              src={user.profileImage}
                              onClick={() => handleClickProfile(user.id)}
                              style={{
                                width: '34px',
                                height: '34px',
                                marginRight: '8px',
                                cursor: 'pointer'
                              }}
                            />
                            {user.displayName}
                          </TableCell>
                          <TableCell align="center">{`${user.wkg}.xx`}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
        </>
      )}
    </>
  );
};
export default Members;
