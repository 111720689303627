import React from 'react';
import { Box } from '@mui/material';
import { LoginButton } from 'components/Common';
import Menu from './Menu';
import Logo from './Logo';

export default function MenuLayout() {
  return (
    <Box
      component="nav"
      role="navigation"
      sx={{
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '60px',
        width: '100%',
        maxWidth: '1200px',
        flexShrink: 1,
        overflow: 'hidden'
      }}>
      <Logo />
      <Menu />
      <LoginButton />
    </Box>
  );
}
