//import React from 'react';
//import uuid from 'uuid/v1';
//#ffd357 노
//f491b2 빨 
//8cc1ec 파랑
//4db6ac 녹

export default {
  race : {
    chart: {
      stacked: false,     
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      },
      offsetY: 0,
      offsetX: 0,
    },  
    stroke: {
      width: [2,2],
      curve: 'stepline'
    },
    fill: {
      opacity: 0.6
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        fontSize:'11px',
        colors: ['#4db6ac','#f491b2'],
      },
      formatter: null,
      offsetX: -7,
      backgroundColor: { 
        enabled: true,
        opacity: 0.2,
      },
    },
    colors: ['#4db6ac','#f491b2'],
    grid: {
      borderColor: '#f5f5f5',
      row: {
        colors: ['#ffffff', '#eeeeee'], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
    },
    legend: {
      fontSize: '11px',
    },
    tooltip: {
      custom: null,
    },
    annotations : {
      xaxis: [
        {
          x: 0,
          x2: 1,
          strokeDashArray: 1,
          borderColor: '#ffd357',
          fillColor: '#ffd357',
          opacity: 0.7,
          offsetX: 0,
          offsetY: 0,
          label: {
            borderColor: '#ffd357',
            text: undefined,
            orientation: 'horizontal',
            style: {
                background: '#ffd357',
                color: '#ffffff',
                fontSize: '11px',
            },
        },
        }
      ]
    },       
    xaxis: {
      show: true,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
        offsetX: 0,
        offsetY: 0
      },      
      tooltip: {
        enabled: false,
      }
    },
    yaxis: {
      show: false,
      min: -180,
      max: 180, 
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    },    
  },

  itt : {
    chart: {
      stacked: false,     
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false
      },
      offsetY: 0,
      offsetX: 0,
    },  
    stroke: {
      width: [2,2],
      curve: 'stepline'
    },
    fill: {
      opacity: 0.6
    },
    plotOptions: {
      bar: {
        barHeight: '100%',
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'start',
      style: {
        fontSize:'11px',
        colors: ['#4db6ac','#f491b2'],
      },
      formatter: null,
      offsetX: -7,
      backgroundColor: { 
        enabled: true,
        opacity: 0.2,
      },
    },
    colors: ['#4db6ac','#f491b2'],
    grid: {
      borderColor: '#f5f5f5',
      row: {
        colors: ['#ffffff', '#eeeeee'], // takes an array which will be repeated on columns
        opacity: 0.2,
      },
    },
    legend: {
      fontSize: '11px',
    },
    tooltip: {
      custom: null,
    },
    annotations : {
      xaxis: [
        {
          x: 0,
          x2: 1,
          strokeDashArray: 1,
          borderColor: '#ffd357',
          fillColor: '#ffd357',
          opacity: 0.7,
          offsetX: 0,
          offsetY: 0,
          label: {
            borderColor: '#ffd357',
            text: undefined,
            orientation: 'horizontal',
            style: {
                background: '#ffd357',
                color: '#ffffff',
                fontSize: '11px',
            },
        },
        }
      ]
    },       
    xaxis: {
      show: true,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
        offsetX: 0,
        offsetY: 0
      },      
      tooltip: {
        enabled: false,
      }
    },
    yaxis: {
      show: false,
      min: -180,
      max: 180, 
      labels: {
        show: false,
      },
      tooltip: {
        enabled: false,
      }
    },
    grid: {
      xaxis: {
        lines: {
          show: true
        }
      }
    },    
  }, 
};
