import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Button, Divider, Drawer, Hidden } from '@mui/material';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import {
  // Profile,
  SidebarNav
} from './components';

import auth from '../../../../utils/auth';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 200,
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    backgroundColor: theme.palette.background.paper,
    minHeight: '300px',
    [theme.breakpoints.up('lg')]: {
      width: 250,
      borderRadius: '8px',
      height: 'fit-content',
      marginTop: 16,
      paddingBottom: 20,
      position: 'relative'
    }
  },
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1),
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(2)
    }
  },
  divider: {
    margin: theme.spacing(6, 0, 3)
  },
  nav: {
    margin: 0,
    padding: 0
  },
  signOut: {
    paddingLeft: 40,
    [theme.breakpoints.down('lg')]: {
      position: 'absolute',
      bottom: 20
    }
  }
}));

const Sidebar = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);
  const { open, variant, onClose, className, ...rest } = props;
  const userInfo = auth.getUserInfo();
  const extraInfo = auth.getExtraInfo();

  const token = auth.getToken().token;

  //unit default

  /*
    "analysis":"상세분석",
    "activities":"마일리지분석",
    "powerProfile":"파워프로파일",
    "powerZone":"훈련영역",
    "heartrate":"심박수분석",
    "phenotype":"역량과성향",
    "personalRecord":"개인최고기록",
    "pmc":"퍼포먼스관리",
    "aerodynamics":"공기저항분석"
  */

  const classes = useStyles();

  let mainMenu = Array();
  let activityMenu = Array();

  //라이덕 관계자
  const adminArr = Array(
    2, // park
    17495, // randy
    21956 // joe
  );
  let coachArr = Array(2);
  let slowtwitchArr = Array(2);

  //CB테스트 클럽아이디 존재하면
  const cbTester = extraInfo.club_id > 0 ? true : false;

  //Array(57,603,690,1110,1235,2543,2590,2755,2977,3925,4140,4324,4403,4708,4765,4793,4876,5308,5536,5672,5753,5772,5896,5999,6203,6226,6278,6280,6687,6819,6860,6937,6940,6944,6949,6959,6979,6980,6999,7047,7102,7104,7252,7366,7367,7447,7469,7470,7477,7487,7499,7531,7569,7581,7585,7591,7594,7599,7612);
  //let tempArr = Array(2, 1119
  // console.log(userInfo.coach_id);

  function coachingOpen() {
    var form = document.createElement('form');
    form.action = 'https://coaching.riduck.com/';
    form.method = 'POST';
    form.target = '_blank';

    var input = document.createElement('input');
    input.name = 'jwt';
    input.value = token;
    form.appendChild(input);
    //.concat((auth.getToken().token))

    form.style.display = 'none';
    document.body.appendChild(form);

    form.submit();
  }

  mainMenu = [
    {
      type: 'topMenu',
      title: t('SideBar.dashboard'),
      href: '/dashboard',
      icon: '',
      new: false,
      isPrivate: false,
      hide: false,
      subMenu: []
    },
    {
      type: 'subMenu',
      title: t('SideBar.allActivities'),
      href: '/activities',
      icon: '',
      new: false,
      isPrivate: false,
      hide: false,
      subMenu: []
    },
    {
      type: 'subMenu',
      title: t('SideBar.pmc'),
      href: '/pmc',
      icon: '',
      new: false,
      isPrivate: false,
      hide: false,
      subMenu: []
    },
    {
      type: 'subMenu',
      title: t('SideBar.powerProfile'),
      href: '/powerprofile',
      icon: '',
      new: false,
      isPrivate: false,
      hide: false,
      subMenu: []
    },
    {
      type: 'subMenu',
      title: t('SideBar.powerzone'),
      href: '/powerzone',
      icon: '',
      new: true,
      isPrivate: false,
      hide: false,
      subMenu: []
    },
    {
      type: 'subMenu',
      title: t('SideBar.metabolism'),
      href: '/metabolism',
      icon: '',
      new: false,
      isPrivate: false,
      hide: false,
      subMenu: []
    },
    {
      type: 'subMenu',
      title: t('SideBar.heartrate'),
      href: '/heartrate',
      icon: '',
      new: true,
      isPrivate: false,
      hide: false,
      subMenu: []
    },
    {
      type: 'divider'
    },
    {
      type: 'topMenu',
      title: t('SideBar.simulator'),
      href: '/analyzer',
      hasSub: false,
      icon: '',
      new: false,
      isPrivate: false,
      hide: false,
      subMenu: []
    },
    {
      type: 'divider'
    },
    {
      type: 'topMenu',
      title: t('SideBar.peloton'),
      href: '/pelotons',
      hasSub: false,
      icon: '',
      new: true,
      isPrivate: true, // true인 경우 admin만 볼 수 있음
      hide: true, // !cbtester (cbtester은 볼 수 있음)에서 true(숨기기)로 변환
      subMenu: []
    },
    /*
        {
          title: t('SideBar.settings'),
          href: '/settings',
          icon: '',
          new: false,
          isPrivate: false,
          subMenu: [
            {
              title: t('SideBar.settingsProfile'),
              href: '/settings/profile',
              icon: '',
              new: false,
              isPrivate: false,
            },
            {
              title: t('SideBar.settingsAccount'),
              href: '/settings/account',
              icon: '',
              new: false,
              isPrivate: false,
            },
            {
              title: t('SideBar.settingsActivity'),
              href: '/settings/activity',
              icon: '',
              new: false,
              isPrivate: false,
            },
            {
              title: t('SideBar.settingsConnect'),
              href: '/settings/connect',
              icon: '',
              new: false,
              isPrivate: false,
            },
          ],
        },
    */
    {
      type: 'subMenu',
      title: t('SideBar.phenotype'),
      href: '/phenotype',
      icon: '',
      new: false,
      isPrivate: true,
      subMenu: []
    },
    {
      type: 'subMenu',
      title: '펠로톤',
      href: '/pelotons',
      icon: '',
      new: false,
      isPrivate: true,
      subMenu: []
    },
    {
      type: 'subMenu',
      title: t('Forum.forum'),
      href: '/forum',
      icon: '',
      new: false,
      isPrivate: true,
      subMenu: []
    },
    {
      type: 'subMenu',
      title: '슬로우트위치',
      href: '/training',
      icon: '',
      new: false,
      isPrivate: true,
      subMenu: []
    }
    // {
    //   type: 'subMenu',
    //   title: "관리자",
    //   href: '/admin',
    //   icon: '',
    //   new: false,
    //   isPrivate: true,
    //   subMenu: [],
    // },
  ];

  return (
    <Drawer
      // anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}>
      <Hidden lgUp>
        <div>
          <KeyboardArrowLeftIcon
            onClick={onClose}
            style={{
              float: 'right',
              margin: '10px 10px 0 0',
              color: '#999999',
              cursor: 'pointer'
            }}
          />
        </div>
      </Hidden>
      <div {...rest} className={clsx(classes.root, className)}>
        <SidebarNav
          className={classes.nav}
          mainMenu={mainMenu}
          isAdmin={adminArr.indexOf(userInfo.ID) > -1}
        />
        <Divider className={classes.divider} />
        {userInfo.coach_id > 0 && (
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={() => coachingOpen()}>
            Coaching
          </Button>
        )}
      </div>
    </Drawer>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired
};

export default Sidebar;
