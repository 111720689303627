import React, { useContext, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@mui/styles';
import { Box, Grid, Typography, Link } from '@mui/material';
import { ModeContext } from 'context/modeProvider';

const useStyles = makeStyles(theme => ({
  cardContainer: {
    padding: '8px',
    boxShadow: theme.boxShadow.primary,
    marginBottom: '16px',
    '&:first-child': {
      marginTop: '8px'
    },
    '&:last-child': {
      marginBottom: '0px'
    },
    '&:hover': {
      backgroundColor: theme.palette.background.colored.light
    },
    borderRadius: '8px',
    [theme.breakpoints.up('md')]: {
      borderRadius: '12px'
    }
  },
  text: {
    fontSize: '12px'
  },
  itemHead: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '8px'
  },
  gridContainer: {
    columnGap: '4px',
    justifyContent: 'space-between'
  },
  itemActionBox: {
    marginTop: '8px',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  stravaLink: { color: '#FC4C02', cursor: 'pointer', marginRight: '16px' },
  modifyLink: {
    color: '#888888',
    cursor: 'pointer'
  }
}));

export default function ActivityCard({
  id,
  index,
  name,
  description,
  rideData,
  averageWatts,
  distance,
  movingTime,
  tssText,
  url,
  handleChartOpen,
  handleDialogOpen
}) {
  const classes = useStyles();
  const { t } = useTranslation(['page']);

  const { mode } = useContext(ModeContext);

  const isEven = index % 2 === 0;

  let bgColor;

  if (mode === 'dark') {
    bgColor = isEven ? '#1e1e1e' : '#333333';
  } else {
    bgColor = isEven ? '#fff' : '#f0f0f0';
  }

  return (
    <Box
      className={classes.cardContainer}
      sx={{ backgroundColor: bgColor }}
      onClick={() => handleChartOpen(id, name, description.synopsis_text)}>
      <Box className={classes.itemHead}>
        <Typography variant="h5">{name}</Typography>
        <Typography className={classes.text}>{rideData}</Typography>
      </Box>
      <Grid columnSpacing={2} container className={classes.gridContainer}>
        <Grid item sm={6}>
          <Box sx={{ display: 'flex' }}>
            <Typography className={classes.text}>
              {t('Dashboard.avgPower')}:
            </Typography>
            <Typography
              sx={{ fontWeight: '500', fontSize: '12px', marginLeft: '4px' }}>
              {averageWatts}
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box sx={{ display: 'flex' }}>
            <Typography className={classes.text}>
              {t('Dashboard.distance')}:
            </Typography>
            <Typography
              sx={{ fontWeight: '500', fontSize: '12px', marginLeft: '4px' }}>
              {distance}
            </Typography>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box sx={{ display: 'flex' }}>
            <Typography className={classes.text}>
              {t('Dashboard.duration')}:
            </Typography>
            <Typography
              sx={{ fontWeight: '500', fontSize: '12px', marginLeft: '4px' }}>
              {movingTime}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box>
        <Typography className={classes.text}>
          {t('Dashboard.score3')}: {tssText}
        </Typography>
      </Box>
      <Box className={classes.itemActionBox}>
        <Link
          target="_blank"
          href={url}
          rel="noopener noreferrer"
          underline="none"
          onClick={e => {
            e.stopPropagation();
          }}>
          <Typography className={clsx(classes.text, classes.stravaLink)}>
            View on Strava
          </Typography>
        </Link>

        <Typography
          className={clsx(classes.text, classes.modifyLink)}
          onClick={e => handleDialogOpen(e, id, name)}>
          Modify
        </Typography>
      </Box>
    </Box>
  );
}
