import React from 'react';

import { formatNumber } from 'utils/helper.js';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Button } from '@mui/material';
//
import RouteIcon from '@mui/icons-material/Route';
import MovingIcon from '@mui/icons-material/Moving';
import DownloadIcon from '@mui/icons-material/Download';
import CourseMapWithMarker from '../Map/CourseMapWithMarker';

const useStyles = makeStyles(theme => ({
  mapDescWrap: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  chipWrap: {
    alignItems: 'center',
    display: 'flex',
    '& > *': {
      marginRight: '4px'
    }
  },
  map: {
    width: '100%',
    margin: '8px 0px'
  },
  reset: {
    padding: '0'
  }
}));

/**
 * 코스와 마커 동시에 갖고 있는 지도
 */
function EventMap({ event, courseData, initialMarker, locationData }) {
  const classes = useStyles();

  // 파일 다운로드를 위한 로직
  function downloadFile(url, originFileName) {
    const link = document.createElement('a');
    link.href = url;
    link.download = originFileName; // 이를 통해 링크를 클릭하는 것으로 파일 다운로드가 가능합니다.
    document.body.appendChild(link); // 필요한 경우에만 추가
    link.click();
    document.body.removeChild(link); // 추가했다면 삭제
  }

  return (
    <>
      {/* 코스와 마커 */}
      <Typography variant="h6" style={{ marginTop: '16px' }}>
        코스정보 및 집결지
      </Typography>
      {(courseData || initialMarker) && (
        <Box>
          <div className={classes.map}>
            <CourseMapWithMarker
              geoJson={
                courseData && courseData.geoJson
                  ? JSON.parse(courseData.geoJson)
                  : null
              }
              initialMarker={initialMarker}
              markerDesc={locationData?.name}
            />
          </div>
        </Box>
      )}

      <Box className={classes.mapDescWrap}>
        <Box className={classes.chipWrap}>
          <RouteIcon />
          <Typography variant="h6">{event.distance}km</Typography>
          <MovingIcon />
          <Typography variant="h6">
            {event.elevation !== undefined && formatNumber(event?.elevation)}m
          </Typography>
        </Box>
        {courseData?.filePath ? (
          <Button
            className={classes.reset}
            onClick={() =>
              downloadFile(courseData.filePath, courseData.originFileName)
            }>
            <DownloadIcon />
            <Typography variant="h6">코스 다운로드</Typography>
          </Button>
        ) : null}
      </Box>
    </>
  );
}

export default EventMap;
