import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import { DynamicFontTypography } from 'components/Typography';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(theme => ({
  root: {},
  sub: {
    fontSize: 'clamp(16px, 1.3vw, 14px)',
    lineHeight: 'clamp(22px, 1.5vw, 30px)'
  }
}));

export default function LoginButton() {
  const { t } = useTranslation('landing');
  const classes = useStyles();

  return (
    <Button
      component="a"
      variant="contained"
      href="/sign-in"
      disableRipple
      disableElevation
      sx={{
        bgcolor: '#009688',
        display: 'flex',
        width: 'fit-content',
        height: '40px',
        flexShrink: 0,
        marginLeft: 'auto',
        '&:hover': { backgroundColor: '#009688' }
      }}>
      <DynamicFontTypography
        className={classes.sub}
        fontWeight={600}
        sx={{ color: '#FFFFFF' }}>
        {t('Header.login')}
      </DynamicFontTypography>
    </Button>
  );
}
