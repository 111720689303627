import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import {
  Hero,
  Stories,
  Reviews,
  WhatsNew,
  Workouts,
  Features
} from 'components/Body';
import { Section } from 'components/Common';

const Landing = props => {
  const { t } = useTranslation(['landing']);

  const contentList = [
    {
      id: 'features',
      // title: t('Landing.features'),
      content: <Features />
    },
    {
      id: 'discover-animal-metrics',
      title: t('Landing.workouts'),
      content: <Workouts />
    },
    // {
    //   id: 'user-reviews',
    //   title: t('Landing.reviews'),
    //   content: <Reviews />
    // },
    {
      id: 'whats-new',
      title: t('Landing.whatsnew'),
      content: <WhatsNew />
    },
    {
      id: 'blog',
      title: t('Landing.stories'),
      content: <Stories />
    }
  ];

  return (
    <Box
      gap={{
        xs: 7,
        lg: 8,
        xl: 9
      }}
      id="main"
      component="main"
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      justifyContent="start"
      alignItems="center">
      <Hero />
      {contentList.map((menuProps, index) => {
        const { id, title, content } = menuProps;
        return <Section key={index} id={id} title={title} content={content} />;
      })}
    </Box>
  );
};

export default Landing;
