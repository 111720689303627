import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchAnnouncements } from './useAnnouncements';
import { fetchBlogs } from './useBlogs';

export function useContentMeta() {
  const { pathname } = useLocation();
  const [dataList, setDataList] = useState(null);

  const sectionMatch = pathname.match(/^\/(.*?)\//);
  const section = sectionMatch ? sectionMatch[1] : null;

  const slugMatch = pathname.match(/\/([^/]+)$/);
  const slug = slugMatch ? slugMatch[1] : null;

  useEffect(() => {
    const fetchData = async () => {
      const data =
        section === 'blog' ? await fetchBlogs() : await fetchAnnouncements();
      setDataList(data);
    };

    fetchData();
  }, [section]);

  return { section, slug, dataList };
}
