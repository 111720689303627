import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles, useTheme, styled } from '@mui/styles';

import {
  Box,
  MenuItem,
  Typography,
  Popper,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { ViewState } from '@devexpress/dx-react-scheduler';

import {
  Scheduler,
  MonthView,
  Toolbar,
  Appointments,
  DateNavigator,
  TodayButton
} from '@devexpress/dx-react-scheduler-material-ui';

import auth from 'utils/auth';
import { PolylineToSVG } from 'utils/polyline';
import { kilometerToMile, meterToFeet } from 'utils/unitCalculation';

import { getWeekOfMonth } from 'utils/calendar';

const useStyles = makeStyles(theme => ({
  dateItem: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    textAlign: 'center'
  },
  dateItemTitleContainer: {
    width: '100%'
  },
  dateItemTitle: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  dateItemCount: {
    color: theme.palette.primary.main
  },
  monthViewTableLayout: {
    minWidth: '300px'
  },
  monthViewTimeTableRow: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  info: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'flex-start',
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    marginBottom: theme.spacing(1),
    '&:last-child': {
      marginBottom: 0
    }
  },
  cardData: {
    display: 'flex',
    gap: '4px'
  },
  cardDataText: {
    fontSize: '12px'
  },
  selector: {
    '&> div': {
      padding: '8px 12px',
      fontSize: '0.75rem'
    }
  },
  weekAccumulateInfoTableCell: {
    padding: '8px',
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.colored.light
  },
  weekAccumulateInfoBox: {
    display: 'flex',
    justifyContent: 'center'
  },
  weekAccumulateInfoElement: {
    display: 'flex',
    marginRight: '8px'
  },
  weekAccumulateIcon: {
    // fill: `${theme.palette.text.forth} !important`,
    fontSize: '12px',
    marginRight: '2px'
  },
  weekAccumulateInfo: {
    margin: '4px 0',
    display: 'flex',
    alignItems: 'center'
  },
  weekAccumulateInfoText: {
    fontWeight: 'bold',
    fontSize: '12px'
  },
  trainingInformationBox: {
    columnGap: '4px'
  },
  tooltipContainer: {
    margin: 'auto',
    padding: 0
  },
  tableRow: {
    height: '160px'
  },
  weatherContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  weatherIcon: {
    width: '48px',
    height: '48px'
  },
  weatherWindDirection: {
    color: theme.palette.text.primary
  },
  weatherInfoBox: {
    display: 'flex',
    alignItems: 'center'
  },
  weatherTemperature: {
    marginRight: '4px'
  },
  menuItemTitle: {
    marginLeft: '8px'
  }
}));

const CalendarItemTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    maxWidth: 360,
    border: '1px solid #dadde9',
    padding: '8px'
  }
}));

export default function CalendarLarge({
  schedulerData,
  locale,
  currentDate,
  monthData,
  setMonthCalendarDate,
  handleOpenActivityStatus,
  isWkg
}) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation(['page']);
  const { unit } = auth.getExtraInfo();

  const TimeTableLayout = props => {
    return (
      <MonthView.TimeTableLayout
        {...props}
        className={classes.monthViewTableLayout}
      />
    );
  };

  const TrainingInformation = ({
    distance,
    movingTime,
    intensity,
    tss,
    averageWatts,
    wPerKg,
    np
  }) => {
    return (
      <Box
        display="grid"
        gridTemplateColumns="repeat(2, 1fr)"
        className={classes.trainingInformationBox}>
        <Box gridColumn="span 1" className={classes.cardData}>
          <span role="img" aria-label="distance">
            📍
          </span>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.cardDataText}>
            {`${t('Dashboard.distance')}: ${
              unit === 'metric'
                ? `${distance}km`
                : `${kilometerToMile(distance).toFixed(2)}mi`
            }`}
          </Typography>
        </Box>
        <Box gridColumn="span 1" className={classes.cardData}>
          <span role="img" aria-label="moving time">
            ⏱️
          </span>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.cardDataText}>
            {`${t('Dashboard.duration')}: ${(movingTime / 60).toFixed(0)}min`}
          </Typography>
        </Box>
        <Box gridColumn="span 1" className={classes.cardData}>
          <span role="img" aria-label="intensity">
            🔥
          </span>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.cardDataText}>
            {`${t('Dashboard.if')}: ${intensity}`}
          </Typography>
        </Box>
        <Box gridColumn="span 1" className={classes.cardData}>
          <span role="img" aria-label="tss">
            📊
          </span>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.cardDataText}>
            {`${t('Dashboard.tss')}: ${tss}`}
          </Typography>
        </Box>
        <Box gridColumn="span 1" className={classes.cardData}>
          <span role="img" aria-label="average watts">
            ⚡
          </span>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.cardDataText}>
            {isWkg
              ? `${t('Dashboard.avgPower')}: ${wPerKg}w/kg`
              : `${t('Dashboard.avgPower')}: ${averageWatts}w`}
          </Typography>
        </Box>
        <Box gridColumn="span 1" className={classes.cardData}>
          <span role="img" aria-label="RP">
            ⚡
          </span>
          <Typography
            variant="body1"
            color="textPrimary"
            className={classes.cardDataText}>
            {`${t('Dashboard.np')}: ${np}`}
          </Typography>
        </Box>
      </Box>
    );
  };

  const TimeTableRow = props => {
    const target = new Date(props.children[0].props.startDate);

    const [weekData, setWeekData] = useState();

    useEffect(() => {
      if (!monthData) return;

      if (monthData.has(`${target.getFullYear()}-${target.getMonth()}`))
        setWeekData(
          monthData.get(`${target.getFullYear()}-${target.getMonth()}`).weeks[
            getWeekOfMonth(target)
          ]
        );
    }, [target]);

    return (
      <>
        <MonthView.TimeTableRow {...props} className={classes.tableRow} />
        <tr>
          {weekData && weekData.movingTime > 0 ? (
            <>
              <td colSpan={7} className={classes.weekAccumulateInfoTableCell}>
                <Box className={classes.weekAccumulateInfoBox}>
                  <Box className={classes.weekAccumulateInfoElement}>
                    <span
                      role="img"
                      aria-label="tss"
                      className={classes.weekAccumulateIcon}>
                      📊
                    </span>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.weekAccumulateInfoText}>
                      {`${weekData.tss}tss`}
                    </Typography>
                  </Box>

                  <Box className={classes.weekAccumulateInfoElement}>
                    <span
                      role="img"
                      aria-label="distance"
                      className={classes.weekAccumulateIcon}>
                      📍
                    </span>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.weekAccumulateInfoText}>
                      {unit === 'metric'
                        ? `${weekData.distance.toFixed(2)}km`
                        : `${kilometerToMile(weekData.distance).toFixed(2)}mi`}
                    </Typography>
                  </Box>

                  <Box className={classes.weekAccumulateInfoElement}>
                    <span
                      role="img"
                      aria-label="moving time"
                      className={classes.weekAccumulateIcon}>
                      ⏱️
                    </span>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.weekAccumulateInfoText}>
                      {`${Math.floor(weekData.movingTime / 60)}min`}
                    </Typography>
                  </Box>

                  <Box className={classes.weekAccumulateInfoElement}>
                    <span
                      role="img"
                      aria-label="elevation gain"
                      className={classes.weekAccumulateIcon}>
                      ⛰️
                    </span>
                    <Typography
                      variant="body1"
                      color="textPrimary"
                      className={classes.weekAccumulateInfoText}>
                      {unit === 'metric'
                        ? `${weekData.elevation}m`
                        : `${meterToFeet(weekData.elevation).toFixed(0)}ft`}
                    </Typography>
                  </Box>
                </Box>
              </td>
            </>
          ) : null}
        </tr>
      </>
    );
  };

  const DayScaleLayout = props => {
    return (
      <MonthView.DayScaleLayout
        {...props}
        className={classes.monthViewTableLayout}
      />
    );
  };

  /* 날짜 아이템 */
  const Appointment = props => {
    const { title, items, weather, startDate } = props.data;

    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1;
    const date = startDate.getDate();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const handleToggle = item => {
      if (items.length === 1) {
        handleOpenActivityStatus(items[0].activityId, items[0].title);
      } else if (items.length > 1) {
        setOpen(prevOpen => !prevOpen);
      }
    };

    const handleClose = event => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return;
      }

      setOpen(false);
    };

    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = useRef(open);
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus();
      }

      prevOpen.current = open;
    }, [open]);

    return (
      <>
        {items.length > 0 ? (
          <Box
            className={classes.dateItem}
            onClick={handleToggle}
            ref={anchorRef}>
            <Box
              style={{
                display: 'flex',
                position: 'relative',
                width:
                  window.innerWidth > theme.breakpoints.values['sm'] ? 60 : 40,
                height:
                  window.innerWidth > theme.breakpoints.values['sm'] ? 60 : 40
              }}>
              {items.length > 1 ? (
                items.slice(0, 2).map((item, index) => (
                  <PolylineToSVG
                    key={item.id}
                    width={
                      window.innerWidth > theme.breakpoints.values['sm']
                        ? 60 - 7
                        : 40 - 7
                    }
                    height={
                      window.innerWidth > theme.breakpoints.values['sm']
                        ? 60 - 7
                        : 40 - 7
                    }
                    polyline={item.summaryPolyline}
                    backgroundColor={item.backgroundColor}
                    style={{
                      position: 'absolute',
                      border: '1px solid white',
                      borderRadius: '5px',
                      top: `${6 * index}px`,
                      left: `${6 * index}px`,
                      zIndex: 100 - index
                    }}
                  />
                ))
              ) : (
                <CalendarItemTooltip
                  className={classes.tooltipContainer}
                  title={
                    <TrainingInformation
                      distance={items[0].distance}
                      movingTime={items[0].movingTime}
                      intensity={items[0].intensity}
                      tss={items[0].tss}
                      averageWatts={items[0].averageWatts}
                      np={items[0].np}
                      wPerKg={items[0].wPerKg}
                    />
                  }
                  PopperProps={{
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, 16]
                        }
                      }
                    ]
                  }}>
                  <div>
                    <PolylineToSVG
                      width={
                        window.innerWidth > theme.breakpoints.values['sm']
                          ? 60
                          : 40
                      }
                      height={
                        window.innerWidth > theme.breakpoints.values['sm']
                          ? 60
                          : 40
                      }
                      polyline={items[0].summaryPolyline}
                      backgroundColor={items[0].backgroundColor}
                    />
                  </div>
                </CalendarItemTooltip>
              )}
            </Box>

            <Box className={classes.dateItemTitleContainer}>
              <Typography className={classes.dateItemTitle}>{title}</Typography>
              {items.length > 1 ? (
                <Typography className={classes.dateItemCount}>{`+${
                  items.length === 1 ? null : items.length - 1
                }`}</Typography>
              ) : null}
            </Box>
          </Box>
        ) : null}

        {/* 기록 두개 이상 메뉴 */}
        {items.length > 1 ? (
          <Popper
            open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            placement="bottom-start"
            transition
            // disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                  marginTop: '4px'
                }}>
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList
                      autoFocusItem={open}
                      id="composition-menu"
                      aria-labelledby="composition-button"
                      onKeyDown={handleListKeyDown}>
                      {items.map((item, index) => (
                        <CalendarItemTooltip
                          key={item.activityId}
                          enterNextDelay={200}
                          placement="top"
                          PopperProps={{
                            modifiers: [
                              {
                                name: 'offset',
                                options: {
                                  offset: [0, index * 36]
                                }
                              }
                            ]
                          }}
                          title={
                            <TrainingInformation
                              distance={item.distance}
                              movingTime={item.movingTime}
                              intensity={item.intensity}
                              tss={item.tss}
                              averageWatts={item.averageWatts}
                              np={item.np}
                              wPerKg={item.wPerKg}
                            />
                          }>
                          <MenuItem
                            onClick={() =>
                              handleOpenActivityStatus(
                                item.activityId,
                                item.title
                              )
                            }>
                            <PolylineToSVG
                              width={24}
                              height={24}
                              polyline={item.summaryPolyline}
                              backgroundColor={item.backgroundColor}
                            />
                            <span className={classes.menuItemTitle}>
                              {item.title}
                            </span>
                          </MenuItem>
                        </CalendarItemTooltip>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        ) : null}
      </>
    );
  };
  return (
    <>
      <Scheduler
        currentView="agenda"
        data={schedulerData}
        firstDayOfWeek={1}
        locale={locale}>
        <ViewState
          defaultCurrentDate={currentDate}
          onCurrentDateChange={date => setMonthCalendarDate(date)}
        />
        <MonthView
          timeTableLayoutComponent={TimeTableLayout}
          dayScaleLayoutComponent={DayScaleLayout}
          timeTableRowComponent={TimeTableRow}
        />

        <Toolbar />
        <DateNavigator />
        <TodayButton />
        <Appointments appointmentComponent={Appointment} />
      </Scheduler>
    </>
  );
}
