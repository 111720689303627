import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
//
import validate from 'validate.js';
import { createClubApi } from 'utils/clientApis';
//
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Link,
  TextField,
  Typography,
  Container,
  FormHelperText
} from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import PelotonLayout from 'views/Peloton/Common/PelotonLayout';
import CreateDoneDialog from './CreateDoneDialog';

const schema = {
  name: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
      minimum: 2
    }
  },
  description: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 1000
    }
  },
  guideText: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 1000
    }
  },
  uriName: {
    presence: { allowEmpty: false, message: 'is required' },
    length: {
      maximum: 64,
      minimum: 3
    },
    format: {
      pattern: /^[a-z0-9-,_]+$/
    }
  },
  inviteCode: {
    presence: { allowEmpty: false, message: 'is required' }
  }
};

//
const useStyles = makeStyles(theme => ({
  root: {},
  topBar: {
    paddingBottom: '8px',
    borderBottom: '1px #dddddd solid',
    display: 'flex',
    alignItems: 'center'
  },
  whiteLine: {
    borderBottom: '1px #ffffff solid',
    marginBottom: '16px'
  },
  canvas: {
    backgroundColor: 'white',
    padding: '16px 16px',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    marginTop: '12px',
    [theme.breakpoints.down('lg')]: {
      padding: '8px 8px'
    }
  },
  title: {
    fontSize: '14px',
    color: '#73838b',
    lineHeight: '28px',
    padding: '0px',
    [theme.breakpoints.up('md')]: {
      fontSize: '16px'
    }
  },
  createForm: {
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  textField: {
    margin: '8px 0',
    width: 'calc(100% - 16px)'
  },
  buttonWrap: {
    display: 'flex',
    width: '100%',
    padding: '12px',
    '& > *': {
      marginRight: '6px'
    }
  },
  button: {},
  uploadBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 'calc(100% - 16px)',
    gap: '8px',
    borderRadius: '4px',
    border: '1px dashed var(--divider, rgba(0, 0, 0, 0.12))',
    padding: '24px 16px'
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  image: {
    borderRadius: '6px',
    width: '100%',
    overflow: 'hidden'
  },
  imagePreviews: {
    // backgroundColor: "beige",
    width: '60vw',
    [theme.breakpoints.down('lg')]: {
      width: '80vw'
    }
  },
  xButton: {
    color: 'white',
    position: 'absolute',
    right: '4px',
    top: '4px',
    height: '16px',
    cursor: 'pointer'
  },
  input: {
    fontSize: '16px'
  }
}));

function CreatePeloton() {
  const imageInputRef = useRef(null);
  const classes = useStyles();

  const [formState, setFormState] = useState({
    values: {
      name: '',
      description: '',
      country: 'KOR',
      city: 'SEOUL',
      guideText: '',
      uriName: '',
      inviteCode: ''
    },
    errors: {},
    touched: {},
    isValid: false
  });
  // 실제 이미지 데이터의 핸들링을 위한 상태
  const [formImages, setFormImages] = useState([]);
  // 이미지 미리보기를 위한 상태
  const [imageThumbs, setImageThumbs] = useState([]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const inputFields = ['name', 'uriName'];
      const currentIndex = inputFields.findIndex(
        field => field === event.target.name
      );
      if (currentIndex < inputFields.length - 1) {
        const nextField = inputFields[currentIndex + 1];
        document.getElementsByName(nextField)[0].focus();
      } else {
        handleCreatePeloton(event);
      }
    }
  };

  const handleChange = event => {
    event.persist();

    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    });
  };

  const handleImageUpload = async event => {
    const uploadedFiles = Array.from(event.target.files);
    // "sample.jpeg"의 형태로는 사용할 수 없어, URI 형태로 변환해주는 작업을 진행합니다. imagePreviewMaker()
    const newImageThumbs = await imagePreviewMaker(uploadedFiles);

    setFormImages(currentFiles => {
      const updatedFiles =
        currentFiles && Array.isArray(currentFiles)
          ? [...currentFiles, ...uploadedFiles.map(file => ({ files: file }))]
          : uploadedFiles.map(file => ({ files: file }));
      return updatedFiles;
    });
    setImageThumbs(currentThumbs => [
      ...currentThumbs,
      ...newImageThumbs.map(uri => ({ filePath: uri, originId: null }))
    ]);
  };

  // 사용할 수 있는 URI로 이미지 file path를 변환해줍니다.
  const imagePreviewMaker = async files => {
    try {
      const newImageURIs = await Promise.all(
        files.map(file => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
            reader.readAsDataURL(file);
          });
        })
      );
      return newImageURIs;
    } catch (error) {
      console.error('이미지를 불러오는 중 오류가 발생했습니다:', error);
    }
  };

  // 이미지 삭제 핸들러
  const handleRemoveImage = (originId, index) => {
    // formState에 담겨 서버로 보내지는 '삭제된 이미지의 id'를 setState합니다.
    setFormState(prevState => {
      const deleteImageIds = Array.isArray(prevState.values.deleteImageIds)
        ? prevState.values.deleteImageIds
        : [];
      if (originId) {
        return {
          ...prevState,
          values: {
            ...prevState.values,
            // 이전 deleteImageIds 배열에 originId 추가
            deleteImageIds: [...deleteImageIds, originId]
          }
        };
      } else {
        return prevState;
      }
    });
    // originId가 존재하는 기존 게시글의 이미지를 삭제할 경우
    if (originId) {
      setImageThumbs(currentThumbs =>
        currentThumbs.filter(image => image.originId !== originId)
      );
      setFormImages(currentFiles =>
        currentFiles.filter(image => image.originId !== originId)
      );
    }
    // 신규 이미지는 대상의 index를 key로 하여 삭제를 진행
    else {
      setImageThumbs(currentThumbs =>
        currentThumbs.filter((_, i) => i !== index)
      );
      setFormImages(currentFiles => currentFiles.filter((_, i) => i !== index));
    }
  };

  const handleCreatePeloton = async event => {
    event.preventDefault();

    try {
      if (formState.isValid) {
        const response = await createClubApi(formState.values, formImages);
        if (response.data.success) {
          handleClickOpen();
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * @return: Modal Controller
   */
  const [openModal, setOpenModal] = useState(false);

  const handleClickOpen = () => {
    setOpenModal(true);
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <PelotonLayout>
      <>
        <Box className={classes.canvas}>
          <form className={classes.createForm} onSubmit={handleCreatePeloton}>
            {/* 펠로톤 이름 */}
            <TextField
              className={classes.textField}
              error={hasError('name')}
              fullWidth
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              helperText={
                hasError('name')
                  ? formState.errors.name[0]
                  : '사용할 펠로톤 이름을 입력하세요.'
              }
              label={'펠로톤 이름'}
              name="name"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="text"
              value={formState.values.name || ''}
              variant="outlined"
            />

            {/* 펠로톤 주소 */}
            <TextField
              className={classes.textField}
              error={hasError('uriName')}
              fullWidth
              helperText={
                hasError('uriName')
                  ? formState.errors.uriName[0]
                  : '영문 소문자, 숫자 사용 가능하며 3글자 이상으로 결정해주세요.'
              }
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              label={'펠로톤 주소'}
              name="uriName"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="text"
              value={formState.values.uriName || ''}
              variant="outlined"
            />

            {/* 소개 */}
            <TextField
              className={classes.textField}
              error={hasError('description')}
              fullWidth
              helperText={
                hasError('description')
                  ? formState.errors.description[0]
                  : '펠로톤에 대한 소개글을 입력하세요.'
              }
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              label={'소개'}
              name="description"
              multiline
              rows={4}
              onChange={handleChange}
              type="text"
              value={formState.values.description || ''}
              variant="outlined"
            />

            {/* 이미지 */}
            {imageThumbs.length === 0 ? (
              <>
                <Container
                  className={classes.uploadBox}
                  onClick={() => imageInputRef.current.click()}
                  style={{ cursor: 'pointer' }}>
                  <UploadFileIcon style={{ color: '#4db6ac' }} />
                  <div className={classes.textContainer}>
                    <Typography
                      align="center"
                      color="primary"
                      variant="body1"
                      style={{
                        textDecoration: 'underline',
                        marginRight: '2px'
                      }}>
                      클릭하여
                    </Typography>
                    <input
                      id="upload-input"
                      type="file"
                      accept="image/.JPG, .JPEG, .PNG"
                      style={{ display: 'none' }}
                      ref={imageInputRef}
                      onChange={handleImageUpload}
                    />
                    <Typography
                      align="center"
                      color="textSecondary"
                      variant="body1">
                      이벤트 이미지 업로드
                    </Typography>
                  </div>
                  <Typography
                    align="center"
                    color="textSecondary"
                    variant="body1">
                    PNG, JPG, JPEG (max. 3MB)
                  </Typography>
                </Container>
                <FormHelperText style={{ width: '100%', padding: '0 24px' }}>
                  이벤트의 대표이미지를 등록하세요. (1280*720px 권장)
                </FormHelperText>
              </>
            ) : (
              <>
                <Box
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'start',
                    alignItems: 'center',
                    width: '100%',
                    padding: '8px'
                  }}>
                  {imageThumbs &&
                    imageThumbs.length > 0 &&
                    imageThumbs.map((image, idx) => {
                      return (
                        <Box
                          key={idx}
                          style={{
                            position: 'relative',
                            marginRight: '8px',
                            display: 'flex',
                            flexDirection: 'row',
                            width: '23%',
                            alignItems: 'center',
                            overflow: 'hidden',
                            height: '100%'
                          }}>
                          <img
                            key={image.id}
                            className={classes.image}
                            src={image.filePath}
                            alt={`imagePreview ${idx}`}
                          />
                          <HighlightOffIcon
                            onClick={() =>
                              handleRemoveImage(image.originId, idx)
                            } // originId와 idx(인덱스)를 전달
                            className={classes.xButton}
                          />
                        </Box>
                      );
                    })}
                </Box>
              </>
            )}

            {/* 가입안내 */}
            <TextField
              className={classes.textField}
              error={hasError('guideText')}
              fullWidth
              helperText={
                hasError('guideText')
                  ? formState.errors.guideText[0]
                  : '다른 회원이 펠로톤에 가입신청하면 표시될 안내 문구를 입력하세요. (회칙, 공지사항 등)'
              }
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              label={'가입안내'}
              name="guideText"
              multiline
              rows={4}
              onChange={handleChange}
              type="text"
              value={formState.values.guideText || ''}
              variant="outlined"
            />

            {/* 프로모션 코드 */}
            <TextField
              className={classes.textField}
              // error={hasError('title')}
              fullWidth
              helperText={
                hasError('inviteCode')
                  ? formState.errors.inviteCode[0]
                  : '프로모션 코드를 입력하세요. (필수)'
              }
              InputProps={{
                classes: {
                  input: classes.input
                }
              }}
              label={'프로모션 코드'}
              name="inviteCode"
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              type="text"
              value={formState.values?.inviteCode}
              variant="outlined"
            />

            {/* 커뮤니티 가이드라인 및 개인정보 처리방침 */}
            <Typography
              align="center"
              color="textSecondary"
              variant="body2"
              style={{ marginTop: '16px' }}>
              계속하면{' '}
              <Link
                href="https://riduck.gitbook.io/riduck-guide/etc/community-guideline"
                underline="always"
                target="_blank">
                라이덕 커뮤니티 가이드라인
              </Link>{' '}
              및 <br />
              <Link
                href="https://riduck.gitbook.io/riduck-guide/etc/privacy"
                underline="always"
                target="_blank">
                개인정보 처리방침
              </Link>
              을 읽고 동의한 것으로 간주합니다. <br />
              접수된 신청은 1영업일 이내에 라이덕 운영진 검토 후, <br />
              승인처리 해드립니다.
            </Typography>

            {/* 개설신청 및 취소 버튼 */}
            <div className={classes.buttonWrap}>
              <Button
                className={classes.button}
                color="primary"
                fullWidth
                size="large"
                onClick={() => window.history.back()}
                variant="text">
                취소
              </Button>
              <Button
                className={classes.button}
                color="primary"
                disabled={!formState.isValid || formImages?.length === 0}
                fullWidth
                size="large"
                type="submit"
                variant="contained">
                개설신청
              </Button>
            </div>
          </form>
        </Box>
        {openModal && (
          <CreateDoneDialog openState={openModal} setOpen={setOpenModal} />
        )}
      </>
    </PelotonLayout>
  );
}

CreatePeloton.propTypes = {
  history: PropTypes.object
};
export default withRouter(CreatePeloton);
