import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@mui/styles';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Grid,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  Switch,
  Slider
} from '@mui/material';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%'
  },
  textBold: {
    fontSize: '14px',
    margin: '0 4px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 3px 0 3px'
    }
  },
  maxHrTypeSwitchWrap: {
    margin: '12px auto 0',
    fontSize: '13px'
  },
  maxHrSetBox: {
    marginBottom: '8px',
    fontSize: '12px',
    textAlign: 'center'
  },
  spaceAround: {
    padding: '0px 0px 16px 0px',
    justifyContent: 'space-around'
  }
}));

const MaxHrTypeSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    margin: '4px',
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  checked: {}
}))(Switch);

const PdSlider = withStyles({
  root: {
    color: '#8cc1ec'
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -4,
    marginLeft: -6,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    }
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)'
  },
  mark: {
    backgroundColor: '#ffffff',
    height: 8,
    width: 1,
    marginTop: 0
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  }
})(Slider);

const MaxHrOptions = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { extraInfo, auth, customMaxHr, maxHr, updateOptions } = props;

  //const [ updateAvail, setUpdateAvail ] = useState(Boolean(extraInfo.updateAvail));
  const [adjustMaxHr, setAdjustMaxHr] = useState(Math.round(maxHr));
  const [adjustSwitch, setAdjustSwitch] = useState(customMaxHr);
  const [maxHrSetDialog, setMaxHrSetDialog] = useState(false);

  const classes = useStyles();

  const bpmArray = [
    {
      value: 160,
      label: '160'
    },
    {
      value: 180,
      label: '180'
    },
    {
      value: 200,
      label: '200'
    },
    {
      value: 220,
      label: '220'
    },
    {
      value: 240,
      label: '240'
    }
  ];

  function updateMaxHrSet() {
    const auth_str = 'Bearer '.concat(auth.getToken().token);

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
        {
          order: 'update_max_hr_set',
          adjust_max_hr: adjustMaxHr,
          custom_max_hr: adjustSwitch
        },
        {
          headers: { Authorization: auth_str }
        }
      )
      .then(response => {
        if (response.data.success === true) {
          updateOptions('on');
          //				setUpdateAvail(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  const handleMaxHrSetDialogOpen = () => {
    setMaxHrSetDialog(true);
  };

  const handleMaxHrSetDialogClose = () => {
    setMaxHrSetDialog(false);
  };

  const handleMaxHrTypeValue = event => {
    let checked = event.target.checked;

    if (checked) {
      setAdjustSwitch(0);
    } else {
      setAdjustSwitch(1);
    }
  };

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Typography
          component="div"
          variant="caption"
          align="center"
          style={{ margin: '12px 8px 0px 8px' }}>
          {t('Options.pleaseAutoFtp')}
        </Typography>

        <CardContent>
          <Typography component="div" className={classes.maxHrTypeSwitchWrap}>
            <Grid
              component="label"
              container
              style={{ width: '132px', margin: '0 auto' }}>
              <Grid item>{t('Options.manual')}</Grid>
              <Grid item>
                <MaxHrTypeSwitch
                  checked={adjustSwitch === 0}
                  onChange={handleMaxHrTypeValue}
                  name="maxHrType"
                />
              </Grid>
              <Grid item>{t('Options.auto')}</Grid>
            </Grid>
          </Typography>

          <Box style={{ width: '180px', margin: '16px auto 0 auto' }}>
            {adjustSwitch === 1 ? (
              <React.Fragment>
                <Box className={classes.maxHrSetBox}>
                  Max HR{' '}
                  <Typography
                    className={classes.textBold}
                    color="secondary"
                    component="span">
                    {Math.round(adjustMaxHr)}
                  </Typography>{' '}
                  bpm
                </Box>

                <PdSlider
                  color="primary"
                  defaultValue={Math.round(adjustMaxHr)}
                  min={160}
                  step={1}
                  max={240}
                  marks={bpmArray}
                  onChange={(event, adjustMaxHr) =>
                    setAdjustMaxHr(Math.round(adjustMaxHr))
                  }
                  valueLabelDisplay="off"
                />
              </React.Fragment>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Box>
        </CardContent>

        <CardActions className={classes.spaceAround}>
          <Button
            className={classes.setButton}
            onClick={updateMaxHrSet}
            variant="contained"
            color="primary">
            {t('Options.modify')}
          </Button>
        </CardActions>

        <Dialog open={maxHrSetDialog} onClose={handleMaxHrSetDialogClose}>
          <DialogTitle style={{ cursor: 'move' }}>
            {t('Options.alertManual')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography component="pre">
                {t('Options.alertManualDesc')}
              </Typography>
              <Typography>{t('Options.alertManualConfirm')}</Typography>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={handleMaxHrSetDialogClose}
              disabled={false /*!updateAvail*/}>
              {t('Options.confirm')}
            </Button>
            <Button onClick={handleMaxHrSetDialogClose}>
              {t('Options.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </Card>
    </React.Fragment>
  );
};

MaxHrOptions.propTypes = {
  className: PropTypes.string
};

export default MaxHrOptions;
