import React from 'react';
import { Box, IconButton, Stack } from '@mui/material';

const socialMediaLinks = [
  {
    id: 'strava',
    alt: 'Strava icon',
    src: '/images/strava.svg',
    href: 'https://www.strava.com/clubs/959584'
  },
  {
    id: 'linkedin',
    alt: 'LinkedIn icon',
    src: '/images/linkedin.svg',
    href: 'https://kr.linkedin.com/company/riduck'
  },
  {
    id: 'instagram',
    alt: 'Instagram icon',
    src: '/images/ig.svg',
    href: 'https://www.instagram.com/riduck.official/'
  }
];

export default function SocialMediaHandleBar({ isOpen = false }) {
  return (
    <Stack
      direction="row"
      spacing={0.6}
      sx={{
        display: { xs: isOpen ? 'flex' : 'none', lg: 'flex' },
        width: 106,
        height: isOpen ? 76 : 60,
        justifyContent: 'space-between',
        alignItems: 'center'
      }}>
      {socialMediaLinks.map(({ id, src, href, alt }) => (
        <IconButton
          key={id}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={alt}
          disableRipple
          sx={{
            p: 0
          }}>
          <Box
            component="img"
            src={src}
            alt={alt}
            sx={{ width: 22, height: 22 }}
          />
        </IconButton>
      ))}
    </Stack>
  );
}
