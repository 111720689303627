import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import auth from '../../../utils/auth';
import request from '../../../utils/request';

//import uuid from 'uuid/v1';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { useTranslation } from 'react-i18next';

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Link,
  Typography,
  Checkbox,
  FormHelperText
} from '@mui/material';

const useStyles = makeStyles({
  card: {
    padding: 10,
    maxWidth: 345
  },
  cardActionArea: {},
  media: {
    width: 96,
    height: 96,
    borderRadius: 48
  },
  title: { backgroundColor: '#f5f5f5' },
  dialog: { width: '100%', borderBottom: '1px #dddddd solid' },
  dialogContent: { padding: 24 },
  dialogAction: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 0,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 24
  },

  inline: { fontWeight: 700, display: 'inline' },
  policy: { display: 'block', width: '100%' },
  policyCheckbox: {
    marginLeft: '-14px'
  }
});

const ProviderDialog = props => {
  //다국어적용
  const { t } = useTranslation(['page']);

  const classes = useStyles();
  const { onClose, open, response } = props;
  const [formState, setFormState] = useState({
    values: {},
    touched: {},
    errors: {}
  });

  const handleChange = event => {
    event.persist();

    setFormState(formState => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  let responseData = response;

  /*
email: "ritten42@gmail.com"
familyName: "P"
givenName: "SH"
googleId: "108869967290803272085"
imageUrl: "https://lh3.googleusercontent.com/a-/AAuE7mBN9AiHeuqxJn-Ntc072CshWlIhmnwD4DdsnAYEKBw=s96-c"
name: "SH P"
*/

  function handleClose() {
    onClose(false);
  }

  const handleSignUp = event => {
    event.preventDefault();

    console.log('req' + responseData.profileObj.email);

    const req = {
      username: responseData.profileObj.name,
      email: responseData.profileObj.email,
      password: responseData.profileObj.googleId,
      provider: 'google',
      profileImage: responseData.profileObj.imageUrl,
      //									"access_token": responseData.access_token,
      userinfo: {
        profile_image: '',
        power_map: {},
        strava_json: {}
      },
      policy: true
    };

    const requestURL = './auth/local/register';

    request(requestURL, { method: 'POST', body: req })
      .then(response => {
        auth.setToken(response.jwt, true);
        auth.setUserInfo(response.user, true);
        window.location.reload();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const hasError = field =>
    formState.touched[field] && formState.errors[field] ? true : false;

  return (
    <Dialog onClose={handleClose} open={open} className={classes.dialog}>
      <DialogTitle className={classes.title}>
        <Typography
          color="textPrimary"
          component="span"
          className={classes.inline}>
          {t('Dashboard.wantSignup')}
        </Typography>
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        {responseData ? (
          <Card className={classes.card}>
            <CardActionArea className={classes.cardActionArea}>
              <CardMedia
                className={classes.media}
                image={responseData.profileObj.imageUrl}
                title="Contemplative Reptile"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  {responseData.profileObj.name}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  {responseData.profileObj.email}
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <div className={classes.policy}>
                <Checkbox
                  checked={formState.values.policy || false}
                  className={classes.policyCheckbox}
                  color="primary"
                  name="policy"
                  onChange={handleChange}
                />
                <Typography
                  className={classes.policyText}
                  color="textSecondary"
                  variant="body1">
                  {t('Dashboard.agree')}{' '}
                  <Link
                    color="primary"
                    component={RouterLink}
                    to="#"
                    underline="always"
                    variant="h6">
                    {t('Dashboard.term')}
                  </Link>
                </Typography>
              </div>
              <div>
                {hasError('policy') && (
                  <FormHelperText error>
                    {formState.errors.policy[0]}
                  </FormHelperText>
                )}
              </div>
            </CardActions>
          </Card>
        ) : (
          ''
        )}
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          className={classes.signUpButton}
          color="primary"
          size="large"
          type="submit"
          variant="contained"
          onClick={handleSignUp}>
          {t('Dashboard.signup')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProviderDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
  //  selectedValue: PropTypes.string.isRequired,
};

export default ProviderDialog;
