import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  TextField,
  Grid,
  MenuItem,
  Typography,
  Checkbox,
  InputAdornment,
  Radio,
  RadioGroup,
  FormGroup,
  FormControlLabel,
  FormLabel,
  FormControl
} from '@mui/material';

import axios from 'axios';
import { useTranslation } from 'react-i18next';

//사용자 프로필 메뉴

const useStyles = makeStyles(theme => ({
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  selectBox: {
    width: 'calc(100% - 8px)',
    marginBottom: '8px'
  },
  card: {
    backgroundColor: 'white',
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
    borderRadius: '4px',
    marginBottom: '16px'
  },
  cardContentBox: {
    margin: '0'
  },
  cardContentBoxHidden: {
    display: 'none',
    margin: '12px 0 4px 0'
  },
  subTitle: {
    fontSize: '16px',
    margin: '16px 0 0 16px',
    color: '#444444'
  },
  spaceAround: {
    marginTop: '-16px',
    paddingBottom: '10px',
    justifyContent: 'space-around'
  }
}));

const CustomOptions = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { auth, extraInfo, strava, updateOptions } = props;
  const classes = useStyles();
  const userInfo = auth.getUserInfo();

  const [updateAvail, setUpdateAvail] = useState(
    Boolean(extraInfo.updateAvail)
  );
  const [Rider, setRider] = useState({
    height: extraInfo.height,
    weight: extraInfo.weight,
    nation: extraInfo.nation,
    gender: extraInfo.gender,
    birth: extraInfo.birth >= 1920 ? extraInfo.birth : 1990,
    ridingYear: extraInfo.riding_year,
    bikeType: []
  });

  const [BikeCheck, setBikeCheck] = React.useState({
    road: false,
    mtb: false,
    sharebike: false,
    minivelo: false,
    electric: false,
    hybrid: false
  });

  /*
	const [RidingCheck, setRidingCheck] = React.useState({
    commute: false,
    solo: false,
    group: false,
    indoor: false,
    job: false,
    hardly: false,
	});
*/
  const [RidingType, setRidingType] = React.useState('');
  const [PurposeCheck, setPurposeCheck] = React.useState({
    fitness: false,
    challenge: false,
    travel: false,
    compete: false,
    social: false
  });

  const [nextStep, setNextStep] = React.useState(true);

  //핸들링 함수
  const handleChangeRider = prop => event => {
    setRider({ ...Rider, [prop]: event.target.value });
  };

  const handleChangeRidingType = event => {
    setRidingType(event.target.value);
  };

  //2개까지 체크
  const handleChangeBikeCheck = type => event => {
    let { road, mtb, sharebike, minivelo, electric, hybrid } = BikeCheck;
    let checked2 = [road, mtb, sharebike, minivelo, electric, hybrid].filter(
      v => v
    ).length;

    if (checked2 < 2) {
      setBikeCheck({ ...BikeCheck, [type]: event.target.checked });
    } else if (checked2 == 2 && event.target.checked === false) {
      setBikeCheck({ ...BikeCheck, [type]: event.target.checked });
    }
  };

  const handleChangePurposeCheck = type => event => {
    let { fitness, challenge, compete, travel, social } = PurposeCheck;
    let checked2 = [fitness, challenge, compete, travel, social].filter(v => v)
      .length;

    if (checked2 < 2) {
      setPurposeCheck({ ...PurposeCheck, [type]: event.target.checked });
    } else if (checked2 == 2 && event.target.checked === false) {
      setPurposeCheck({ ...PurposeCheck, [type]: event.target.checked });
      setNextStep(true);
    }
  };

  //Range menuitem 함수들
  const heightRange = height => {
    let range = [];

    for (var i = 140; i <= 220; i++) {
      range.push(
        <MenuItem key={i} value={i} selected={height === i ? true : false}>
          {i}cm
        </MenuItem>
      );
    }
    return range;
  };

  const weightRange = weight => {
    let range = [];

    for (let i = weight - 5; i <= weight + 5; i++) {
      range.push(
        <MenuItem key={i} value={i} selected={weight === i ? true : false}>
          {i}kg
        </MenuItem>
      );
    }
    return range;
  };

  const genderRange = () => {
    let range = [];
    range.push(
      <MenuItem key={0} value={'M'}>
        {t('Procedure.male')}
      </MenuItem>
    );
    range.push(
      <MenuItem key={1} value={'F'}>
        {t('Procedure.female')}
      </MenuItem>
    );
    return range;
  };

  const birthRange = birth_year => {
    let range = [];
    let d = new Date();
    let this_year = d.getFullYear();
    let birth_id = birth_year > 1920 ? birth_year : this_year - birth_year;

    for (let i = 1920; i <= this_year - 7; i++) {
      range.push(
        <MenuItem key={i} value={i} selected={birth_id === i ? true : false}>
          {i}
          {t('Procedure.year')}
        </MenuItem>
      );
    }
    return range;
  };

  const ridingYearRange = () => {
    let range = [];
    let d = new Date();
    let this_year = d.getFullYear();

    for (var i = 1920; i <= this_year; i++) {
      range.push(
        <MenuItem key={i} value={i}>
          {i}
          {t('Procedure.year')}
        </MenuItem>
      );
    }
    return range;
  };

  const bikeTypeRange = () => {
    let range = [];
    range.push(
      <MenuItem key={0} value={'road_allround'}>
        로드(올라운드)
      </MenuItem>
    );
    range.push(
      <MenuItem key={1} value={'road_aero'}>
        로드(에어로)
      </MenuItem>
    );
    range.push(
      <MenuItem key={2} value={'road_endurance'}>
        로드(엔듀런스)
      </MenuItem>
    );
    return range;
  };

  function updateCustomOptions() {
    const auth_str = 'Bearer '.concat(auth.getToken().token);

    axios
      .post(
        `${process.env.REACT_APP_RESTAPI_BASE_URL}/json-api/set-extrainfo.php`,
        {
          order: 'update_addinfo',
          new_height: Rider.height,
          new_weight: Rider.weight,
          new_birth: Rider.birth,
          new_riding_year: Rider.ridingYear
        },
        {
          headers: { Authorization: auth_str }
        }
      )
      .then(response => {
        if (response.data.success) {
          let exInfo = auth.getExtraInfo();
          exInfo.height = Number(response.data.new_height);
          exInfo.weight = Number(response.data.new_weight);
          exInfo.birth = Number(response.data.new_birth);
          exInfo.riding_year = Number(response.data.new_riding_year);

          exInfo.updateAvail = false;
          auth.setExtraInfo(exInfo, true);

          updateOptions('on');
          setUpdateAvail(false);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }

  return (
    <React.Fragment>
      <Card className={classes.card}>
        <Typography component="div" align="center">
          {t('Options.pleaseCorrectly')}
        </Typography>

        <CardContent>
          <Grid container spacing={1} className={classes.cardContentBox}>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Procedure.height')}
                size="small"
                value={Rider.height}
                disabled={!updateAvail}
                onChange={handleChangeRider('height')}
                InputProps={{
                  classes: { input: classes.inputStyle }
                }}>
                {heightRange(Number(Rider.height))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Procedure.weight')}
                size="small"
                value={Rider.weight}
                disabled={!updateAvail}
                onChange={handleChangeRider('weight')}>
                {weightRange(Number(Rider.weight))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Procedure.birthYear')}
                size="small"
                value={Rider.birth}
                disabled={!updateAvail}
                onChange={handleChangeRider('birth')}>
                {birthRange(Number(Rider.birth))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                className={classes.selectBox}
                variant="outlined"
                select
                label={t('Procedure.ridingYear')}
                size="small"
                value={Rider.ridingYear}
                disabled={!updateAvail}
                onChange={handleChangeRider('ridingYear')}>
                {ridingYearRange(Number(Rider.ridingYear))}
              </TextField>
            </Grid>
          </Grid>

          <Box className={classes.cardContentBoxHidden}>
            <Typography gutterBottom variant="h6" component="h2">
              {t('Procedure.bikeType')}
            </Typography>
            <FormGroup row className={classes.group}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={BikeCheck.road}
                    onChange={handleChangeBikeCheck('road')}
                    value="road"
                  />
                }
                label={t('Procedure.road')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={BikeCheck.mtb}
                    onChange={handleChangeBikeCheck('mtb')}
                    value="mtb"
                  />
                }
                label={t('Procedure.mtb')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={BikeCheck.sharebike}
                    onChange={handleChangeBikeCheck('sharebike')}
                    value="sharebike"
                  />
                }
                label={t('Procedure.sharebike')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={BikeCheck.electric}
                    onChange={handleChangeBikeCheck('electric')}
                    value="ebike"
                  />
                }
                label={t('Procedure.ebike')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={BikeCheck.minivelo}
                    onChange={handleChangeBikeCheck('minivelo')}
                    value="minivelo"
                  />
                }
                label={t('Procedure.minivelo')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={BikeCheck.hybrid}
                    onChange={handleChangeBikeCheck('hybrid')}
                    value="hybrid"
                  />
                }
                label={t('Procedure.hybrid')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={BikeCheck.etc}
                    onChange={handleChangeBikeCheck('etc')}
                    value="etc"
                  />
                }
                label={t('Procedure.etc')}
              />
            </FormGroup>
          </Box>

          <Box className={classes.cardContentBoxHidden}>
            <Typography gutterBottom variant="h6" component="h2">
              {t('Procedure.inputTab3')}
            </Typography>
            <FormControl component="fieldset" className={classes.formControl}>
              <RadioGroup
                aria-label="BikeCheck"
                name="BikeCheck"
                className={classes.group}
                value={RidingType}
                onChange={handleChangeRidingType}>
                <FormControlLabel
                  value="commute"
                  control={<Radio />}
                  label={t('Procedure.commute')}
                />
                <FormControlLabel
                  value="solo"
                  control={<Radio />}
                  label={t('Procedure.solo')}
                />
                <FormControlLabel
                  value="group"
                  control={<Radio />}
                  label={t('Procedure.group')}
                />
                <FormControlLabel
                  value="indoor"
                  control={<Radio />}
                  label={t('Procedure.indoor')}
                />
                <FormControlLabel
                  value="job"
                  control={<Radio />}
                  label={t('Procedure.job')}
                />
                <FormControlLabel
                  value="hardly"
                  control={<Radio />}
                  label={t('Procedure.hardly')}
                />
              </RadioGroup>
            </FormControl>
          </Box>

          <Box className={classes.cardContentBoxHidden}>
            <Typography gutterBottom variant="h6" component="h2">
              {t('Procedure.rideWhy')}
            </Typography>
            <FormGroup className={classes.group}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={PurposeCheck.fitness}
                    onChange={handleChangePurposeCheck('fitness')}
                    value="fitness"
                  />
                }
                label={t('Procedure.fitness')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={PurposeCheck.challenge}
                    onChange={handleChangePurposeCheck('challenge')}
                    value="challenge"
                  />
                }
                label={t('Procedure.challenge')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={PurposeCheck.travel}
                    onChange={handleChangePurposeCheck('travel')}
                    value="travel"
                  />
                }
                label={t('Procedure.travel')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={PurposeCheck.compete}
                    onChange={handleChangePurposeCheck('compete')}
                    value="compete"
                  />
                }
                label={t('Procedure.compete')}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={PurposeCheck.social}
                    onChange={handleChangePurposeCheck('social')}
                    value="social"
                  />
                }
                label={t('Procedure.social')}
              />
            </FormGroup>
          </Box>
        </CardContent>

        <CardActions className={classes.spaceAround}>
          <Button
            color="primary"
            variant="contained"
            onClick={updateCustomOptions}
            disabled={!updateAvail}>
            {t('Options.modify')}
          </Button>
        </CardActions>
        {!updateAvail && extraInfo.updateAvailDate ? (
          <Typography
            component="div"
            variant="caption"
            align="center"
            style={{ margin: '8px 0 16px 0' }}>
            {extraInfo.updateAvailDate} {t('Options.afterModify')}
          </Typography>
        ) : (
          ''
        )}
      </Card>
    </React.Fragment>
  );
};

CustomOptions.propTypes = {
  className: PropTypes.string
};

export default CustomOptions;
