import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';

import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
  Switch,
  Tabs,
  Tab
} from '@mui/material';

import { Icon } from '@iconify/react';
import StravaIcon from '@iconify/icons-mdi/strava';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  box: {
    width: '300px',
    marginBottom: '8px'
  },
  boxTop: {
    width: '300px',
    border: 'none'
  },
  boxBottom: {
    width: '300px',
    marginTop: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 4px 4px'
  },
  boxMid: {
    width: '300px',
    padding: '0px',
    border: '1px #dddddd solid',
    borderTop: 'none',
    borderRadius: '0 0 0 0'
  },
  boxText: {
    lineHeight: '24px',
    textAlign: 'center'
  },
  boxTextBold: {
    fontSize: '15px',
    margin: '0 4px 0 4px',
    color: '#1c998d',
    fontWeight: 500,
    [theme.breakpoints.down('lg')]: {
      fontSize: '13px',
      margin: '0 3px 0 3px'
    }
  },
  setButton: {
    margin: '4px 0 16px 0',
    lienHeight: '160%'
  },
  ftpTextBold: {
    fontSize: '15px',
    color: '#73838b'
  },
  tabs: {
    border: '1px #dddddd solid',
    borderRadius: '4px 4px 0 0'
  },
  tabTitle: {
    textAlign: 'left',
    padding: '8px',
    color: '#73838b'
  },
  tabDesc: {
    margin: '0px 10px 4px 10px',
    fontSize: '12px',
    lineHeight: '16px',
    textAlign: 'left'
  },
  sliderWrap: {
    padding: '0px 24px 0px 24px',
    marginBottom: '8px',
    borderBottom: '1px #dddddd solid'
  },
  ftpSetDesc: {
    marginTop: '8px',
    fontSize: '12px',
    lineHeight: '16px'
  },
  ftpInOutSwitchWrap: {
    padding: '16px 0 8px 0',
    fontSize: '12px'
  },
  ftpSetBox: {
    fontSize: '12px',
    marginBottom: '12px'
  },
  chartContainer: {
    height: '160px',
    margin: '-24px 0 0 0'
  },
  list: {
    marginTop: '0px',
    //    border:'1px #dddddd solid',
    border: 'none'
  },
  listItem: {
    padding: '0px 12px'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  },
  guideFtp: {
    fontSize: '12px',
    color: '#999999'
  },
  stravaButton: {
    minWidth: 0,
    margin: '2px 2px 2px 0'
  },
  stravaIcon: {
    color: '#FC4C02',
    fontSize: '16px',
    opacity: '0.7',
    marginTop: '-2px'
  },
  z1: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#aaaaaa',
    color: '#ffffff',
    fontSize: '14px'
  },
  z2: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#76b5e8',
    color: '#ffffff',
    fontSize: '14px'
  },
  z3: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#b0d36d',
    color: '#ffffff',
    fontSize: '14px'
  },
  z4: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#ffd357',
    color: '#ffffff',
    fontSize: '14px'
  },
  z5: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#ffa75c',
    color: '#ffffff',
    fontSize: '14px'
  },
  z6: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#ff7e75',
    color: '#ffffff',
    fontSize: '14px'
  },
  z7: {
    opacity: 0.8,
    border: '4px #ddd solid',
    backgroundColor: '#f06292',
    color: '#ffffff',
    fontSize: '14px'
  }
}));

const FtpInOutSwitch = withStyles(theme => ({
  root: {
    width: 28,
    height: 16,
    padding: 0,
    margin: '4px',
    display: 'flex'
  },
  switchBase: {
    padding: 2,
    color: theme.palette.common.white,
    '&$checked': {
      transform: 'translateX(12px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
      }
    }
  },
  thumb: {
    width: 12,
    height: 12,
    boxShadow: 'none'
  },
  track: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 16 / 2,
    opacity: 1,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main
  },
  checked: {}
}))(Switch);

const HrDialog = props => {
  //다국어적용 t('component.key')
  const { t } = useTranslation(['page']);

  const { ftpArray, maxHr, maxHrActivity } = props;
  const [inOutValue, setInOutValue] = React.useState('out');
  const [maxHrTab, setMaxHrTab] = React.useState(0);

  const classes = useStyles();

  let activity_url = 'https://www.strava.com/activities/' + maxHrActivity;

  function secondsToHms(time) {
    let d = Number(time);
    let h = Math.floor(d / 3600);
    let m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (h > 0) {
      let hd = h > 0 ? h + t('Common.hour') + ' ' : '';
      let md = m > 0 ? m + t('Common.min') + ' ' : '';

      return hd + md;
    } else if (h <= 0) {
      let md = m > 0 ? m + t('Common.min') + ' ' : '';
      let sd = s > 0 ? s + t('Common.sec') : '';

      return md + sd;
    }
  }

  const handleTabChange = (event, tab) => {
    setMaxHrTab(tab);
  };

  /*	
	const handleInOutFtpValue = (event) => {		
    let checked = event.target.checked;
		
		if(checked) {
			setInOutValue('out');
			setZoneFtp(maxHr);		
		} else {
			setInOutValue('in');
			setZoneFtp(tmaxHr);
		}
	};
*/

  return (
    <React.Fragment>
      <Box className={classes.boxTop}>
        <Paper elevation={0} className={classes.tabs}>
          <Tabs
            value={maxHrTab}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered>
            <Tab label={t('Dashboard.zoneTab')} />
          </Tabs>
        </Paper>
      </Box>

      <Box className={classes.boxMid} style={{ textAlign: 'center' }}>
        {/*
if($hr_stream[$i] > 0.55 && $hr_stream[$i] <= $p_max_hr*0.72) $hr_z1++;
else if($hr_stream[$i] > $p_max_hr*0.72 && $hr_stream[$i] <= $p_max_hr*0.80) $hr_z2++;
else if($hr_stream[$i] > $p_max_hr*0.80 && $hr_stream[$i] <= $p_max_hr*0.87) $hr_z3++;
else if($hr_stream[$i] > $p_max_hr*0.87 && $hr_stream[$i] <= $p_max_hr*0.93) $hr_z4++;
else if($hr_stream[$i] > $p_max_hr*0.93) $z5++;
*/

        maxHrTab === 0 ? (
          <React.Fragment>
            <List className={classes.list}>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z1}>Z1</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z1')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(maxHr * 0.5) + 1} ~{' '}
                        {Math.round(maxHr * 0.6)}
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.hrz1desc')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z2}>Z2</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z2')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(maxHr * 0.6) + 1} ~{' '}
                        {Math.round(maxHr * 0.7)}
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.hrz2desc')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z3}>Z3</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z3')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(maxHr * 0.7) + 1} ~{' '}
                        {Math.round(maxHr * 0.8)}
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.hrz3desc')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z4}>Z4</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z4')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(maxHr * 0.8) + 1} ~{' '}
                        {Math.round(maxHr * 0.9)}
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.hrz4desc')}
                />
              </ListItem>
              <ListItem className={classes.listItem}>
                <ListItemAvatar>
                  <Avatar className={classes.z5}>Z5</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <div>
                      <Typography component="span">{t('Common.z5')}</Typography>
                      <Typography
                        component="span"
                        style={{ marginLeft: '8px' }}>
                        {Math.round(maxHr * 0.9) + 1} ~{' '}
                        {Math.round(maxHr * 1.0)}
                      </Typography>
                    </div>
                  }
                  secondary={t('Common.hrz5desc')}
                />
              </ListItem>
            </List>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}

        <Box style={{ borderTop: '1px #dddddd solid', padding: '8px' }}>
          <Typography className={classes.boxText}>
            ❤️ {t('Dashboard.maxHr')}
            <Typography className={classes.boxTextBold} component="span">
              {Math.round(maxHr)}
            </Typography>
            bpm
          </Typography>
          <Typography className={classes.boxText}>
            <Button
              color="secondary"
              size="small"
              variant="text"
              target="_blank"
              href={activity_url}
              className={classes.stravaButton}>
              <Icon icon={StravaIcon} className={classes.stravaIcon} />
            </Button>
            <a target="_blank" href={activity_url}>
              {t('Dashboard.maxHr') + ' ' + t('Dashboard.activity')}
            </a>
          </Typography>
        </Box>
      </Box>

      <Box className={classes.boxBottom}>
        {maxHrTab === 0 ? (
          <React.Fragment>
            <Typography className={classes.tabTitle} component="div">
              {t('Dashboard.whatIsHrzone')}
            </Typography>
            <Typography className={classes.tabDesc} component="div">
              {t('Dashboard.5zoneDesc')}
            </Typography>
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};

export default HrDialog;

{
  /*
				<Typography component="div" className={classes.ftpInOutSwitchWrap}>
	        <Grid component="label" container style={{width:'118px', margin:'0 auto'}}>
						<Grid item>{t('Common.interval')}</Grid>
						<Grid item>
							<FtpInOutSwitch 
                checked={(inOutValue=='out')} 
                onChange={handleInOutFtpValue} 								
                name="ftpInOut" />
						</Grid>
						<Grid item>{t('Common.steady')}</Grid>
   			 	</Grid>
   			</Typography>
			  <Typography className={classes.guideFtp}>
        {	
					(inOutValue==='out') ? 
					<div>{t('Common.steady')} MaxHR <span style={{color:'#4db6ac'}}>{maxHr}</span> W</div>       
					:
					<div>{t('Common.interval')} THR <span style={{color:'#4db6ac'}}>{maxHr}</span> W</div>       
				}
        </Typography>
*/
}
