import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@mui/styles';
import { Card, CardHeader, CardContent } from '@mui/material';
import RiderAndBike from './Contents/RiderAndBike';

import _ from 'lodash';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  root: {
    height: 408,
    position: 'relative',
    [theme.breakpoints.down('xl')]: {
      height: 344
    },
    [theme.breakpoints.down('lg')]: {
      height: 340
    },
    [theme.breakpoints.down('md')]: {
      height: 300
    }
  },
  cardHeader: {
    color: '#90a4ae',
    fontSize: '15px',
    [theme.breakpoints.down('md')]: {
      marginTop: '-4px',
      fontSize: '14px'
    }
  },
  chartContainer: {
    position: 'relative',
    height: 260,
    [theme.breakpoints.down('xl')]: {
      height: 220
    },
    [theme.breakpoints.down('lg')]: {
      height: 200
    },
    [theme.breakpoints.down('md')]: {
      height: 180
    }
  },
  stats: {
    display: 'flex',
    justifyContent: 'center'
  },
  device: {
    textAlign: 'center',
    padding: theme.spacing(1)
  },
  deviceIcon: {
    color: theme.palette.icon
  }
}));

const BikeKit = props => {
  const { className, bikeKitJson, ...rest } = props;

  //다국어적용 {t('page.key')}
  const { t } = useTranslation(['page']);

  const classes = useStyles();
  const theme = useTheme();

  let data = [
    {
      id: '',
      label: '',
      value: 1,
      color: '#f5f5f5'
    }
  ];

  //  console.log(bikeKitJson.rider_pose);

  //color #4db6ac #76b5e8 #F06292
  // climber, sprinter, allrounder,
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={
          false
          /*
          <IconButton size="small">
            <RefreshIcon />
          </IconButton>
          */
        }
        classes={{ title: classes.cardHeader }}
        title={t('Dashboard.bikeKit')}
      />
      <CardContent>
        {bikeKitJson !== null ? (
          <RiderAndBike
            bikeType={bikeKitJson.bike_type}
            tireWidth={Number(bikeKitJson.tire_width)}
            rimHeight={Number(bikeKitJson.rim_height)}
            riderPose={Number(bikeKitJson.rider_pose)}
            cadence={Number(bikeKitJson.cadence)}
            roadSurface={Number(bikeKitJson.surface_select)}
          />
        ) : (
          <RiderAndBike />
        )}
      </CardContent>
    </Card>
  );
};

BikeKit.propTypes = {
  className: PropTypes.string
};

export default BikeKit;
